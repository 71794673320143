import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Trans } from '@lingui/macro';
import { handleProfileCode } from 'utils';
import NotificationInline from '../NotificationInline';
import ToggleFloatButton from '../ToggleFloatButton';
import { Typography, Avatar, BoxDateDisplay } from 'components';
import { SCHEDULED, ATTENDING } from '../../settings/_scheduleStatusSettings';
import { isScheduleNow } from 'helpers/scheduleHelpers';

function ConsultationCard({
  avatarSize,
  avatarUrl,
  date,
  iconName,
  id,
  planName,
  isTimeUp,
  isToday,
  menuList,
  meetingHash,
  name,
  price,
  sessoinId,
  hasPrice,
  status,
  time,
  startDate,
  endDate,
  professionalCode,
  professionalDocumentType,
  professionalDocument
}) {
  const [show, handleShow] = useState(false);

  useEffect(() => {
    let interval;

    const checkScheduleDate = () => {
      let isNow = isScheduleNow(startDate, endDate);
      if(isNow){
        show === false && handleShow(true);
      }else{
        show === true && handleShow(false);
      }
    }

    if(status === SCHEDULED || status === ATTENDING){
      checkScheduleDate();
      interval = setInterval(checkScheduleDate, 15000);
    }
    
    return () => {
      clearInterval(interval);
    }

  }, [show, status, startDate, endDate]);

  return (
    <div className={`ConsultationCard${isToday ? `--cardColor` : ''}`}>
      <div className='ConsultationCard__BoxDateDisplay'>
        <BoxDateDisplay
          status={status}
          time={time}
          date={date}
        />
      </div>

      <div className='ConsultationCard__Cardcenter'>
        <div className='Cardcenter__Psycologist'>
          <div className='avatar'>
            <Avatar
              imgUrl={avatarUrl}
              avatarSize={avatarSize}
            />
          </div>

          <div className='pinfo'>
            { sessoinId
              ? <Typography>{sessoinId}ª <Trans>consulta com</Trans></Typography>
              :<Typography><Trans>Consulta com</Trans></Typography>
            }
            <Typography bold style={{lineHeight: '1'}} className="Cardcenter__Psycologist__Name">
              {name}
            </Typography>

            <div className="ConsultationCard__proDocument">
              <Typography>
                {handleProfileCode(professionalCode)}
                {professionalDocumentType}
                {professionalDocument}
              </Typography>
            </div>
          </div>
        </div>

        <div className='info'>

          <div className='info__plan'>
            <Typography type='subheading2'>
              {planName}
            </Typography>
          </div>

          <div className='info__id'>
            <Typography
              type='subheading2'>
              ID:{id}
            </Typography>
          </div>

          <div className='info__price'>
            <Typography type='subheading2'>
              {
                hasPrice && (
                  <Fragment>
                    <Trans>Valor:</Trans> <span>{price}</span>
                  </Fragment>
                )
              }
            </Typography>
          </div>
        </div>
      </div>

      {
        show ? (
          <div className='ConsultationCard__Notification'>
            <NotificationInline
              initialTime={moment().diff(startDate) < 0 ? moment().diff(startDate) * -1 : 0}
              meetingHash={meetingHash}
              isTimeUp={isTimeUp}
            />
          </div>
        ) : status === SCHEDULED ? (
          <div className='ConsultationCard__Icon'>
            <ToggleFloatButton
              menuList={menuList}
              iconName={iconName}
            />
          </div>
        ) : (
          <div className='ConsultationCard__NoIcon'></div>
        ) 
      }
    </div>
  );
}

ConsultationCard.propTypes = {
  hasPrice: PropTypes.bool,
  status: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.string,
  sessoinId: PropTypes.any,
  price: PropTypes.string,
  time: PropTypes.string,
  date: PropTypes.string,
  menuList: PropTypes.any,
  avatarUrl: PropTypes.string,
  avatarSize: PropTypes.string,
  iconName: PropTypes.any,
};

export {
  ConsultationCard
};
