import {
  CLEAR_FILTER,
  DATE_FILTER,
  STATUS_FILTER,
  TEXT_FILTER,
  GENDER_STATUS,
  SELECTED_DAY,
  START_HOUR,
  END_HOUR,
  PRICE_RANGE,
  FILTER_DATE_HOURS,
} from '../actions/_actionTypes';

import { updateState } from "../helpers/utilityHelpers";

const initialState = {
  day: null,
  end_hour: null,
  filterData: {},
  gender_status: null,
  isFiltering: false,
  isIndex: null,
  range: null,
  text: null,
  start_hour: null,
  status: null,
  startDate: null,
  endDate: null,
};

const clearFilter = () => {
  return initialState;
};

const filterByStatus = (state, { payload }) => {
  return updateState(state, {
    status: payload.status,
    isFiltering: payload.isFiltering,
  });
};

const genderStatus = (state, { payload }) => {
  return updateState(state, {
    gender_status: payload.gender_status,
    isFiltering: payload.isFiltering,
  });
};

const selectDay = (state, { payload }) => {
  return updateState(state, {
    day: payload.day,
    isFiltering: payload.isFiltering,
  });
};

const startHour = (state, { payload }) => {
  return updateState(state, {
    start_hour: payload.start_hour,
    isFiltering: payload.isFiltering,
  });
};

const endHour = (state, { payload }) => {
  return updateState(state, {
    end_hour: payload.end_hour,
    isFiltering: payload.isFiltering,
  });
};

const getDateTimeFiltersData = (state, { payload }) => {
  return updateState(state, {
    filterData: payload.filterData,
    isFiltering: payload.isFiltering,
    isIndex: payload.isIndex,
  });
};

const getPriceRange = (state, { payload }) => {
  return updateState(state, {
    range: payload.range,
    isFiltering: payload.isFiltering,
  });
};

const filterByText = (state, { payload }) => {
  return updateState(state, {
    text: payload.text,
    isFiltering: payload.isFiltering,
  });
};

const filterByDate = (state, { payload }) => {
  return updateState(state, {
    startDate: payload.startDate,
    endDate: payload.endDate,
    isFiltering: payload.isFiltering,
  });
};

const filtersReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATE_FILTER: return filterByDate(state, action);
    case STATUS_FILTER: return filterByStatus(state, action);
    case TEXT_FILTER: return filterByText(state, action);
    case CLEAR_FILTER: return clearFilter();
    case GENDER_STATUS: return genderStatus(state, action);
    case SELECTED_DAY: return selectDay(state, action);
    case START_HOUR: return startHour(state, action);
    case END_HOUR: return endHour(state, action);
    case FILTER_DATE_HOURS: return getDateTimeFiltersData(state, action);
    case PRICE_RANGE: return getPriceRange(state, action);
    default: return state;
  }
};

export default filtersReducer;
