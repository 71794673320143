export const filterOptions = [
  {
    'code': 'STS21002',
    'name': 'Agendadas',
    'icon_name': 'Scheduled',
  },
  {
    'code': 'STS21005',
    'name': 'Realizadas',
    'icon_name': 'Completed',
  },
  {
    'code': 'STS21009',
    'name': 'Profissional faltou',
    'icon_name': 'NoShow',
  },
  {
    'code': 'STS21010',
    'name': 'Paciente faltou',
    'icon_name': 'NoShow',
  },
  {
    'code': 'STS21003',
    'name': 'Canceladas',
    'icon_name': 'OfflinePatient',
  },
  {
    'code': 'STS21008',
    'name': 'Devolvidas',
    'icon_name': 'Returned',
  },
  {
    'code': 'SCHEDULE_NO_PARTICIPANTS',
    'name': 'Ambos Faltaram',
    'icon_name': 'ScheduleNoParticipants',
  },
]