import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  reason: string
  details: string
  username: string
  planCode: string
}

interface Error {
  response: {
    status: number
  }
}

export const inactivatePlan = ({
  reason,
  details,
  username,
  planCode
}: Payload): Promise<void> => {

  const endpoint = apiEndpoints.PATIENTS_PLANS(username, planCode)

  const params = {
    inactivate: {
      ...(reason && { reason }),
      ...(details && { details }),
    }
  }

  return new Promise<void> ((resolve, reject) => {
    axios.patch(endpoint, params)
      .then(() => resolve())
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}
