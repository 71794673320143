import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// STORE
import { updateInteractions } from 'redux/modules/mypatients';

// STYLE
import './PatientsList.sass';

// COMPONENTS
import { PatientCard } from 'components';

const PatientsList = ({ index, items }) => {
  const dispatch = useDispatch();
  // STATE
  const [fixed, handleFixed] = useState(false);
  // LOCAL
  const id = `${index}-patientsList`;
  // STORE
  const mypatients = useSelector(state => state.mypatients);
  const top = mypatients.interactions.top;
  const topId = mypatients.interactions.topId;
  
  function setTopPosition () {
    return {
      'top': `${top}px`
    }
  }

  function onScroll () {
    if (topId !== id && window.pageYOffset >= document.getElementById('test-' + index).offsetTop && window.pageYOffset <= (document.getElementById('test-' + index).offsetHeight + document.getElementById('test-' + index).offsetTop)) {
      dispatch(updateInteractions('topId', id))
    }
    if (window.pageYOffset >= 0 && window.pageYOffset < document.body.scrollHeight) {
      if (window.pageYOffset < 170) {
        handleFixed(false);
      } else {
        handleFixed(true);
      }
    }
  }

  useEffect(() => {
    // DID MOUNT

    // DID UPDATE
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="PatientsList" id={`test-${index}`}>
      {
        fixed && topId === id
          ? <p className="PatientsList__top PatientsList__top--fixed" id={id} style={setTopPosition()}>{index}</p>
          : null
      }
      <p style={setTopPosition()} className="PatientsList__top">{index}</p>
      <ul className="PatientsList__list">
        {
          !items && items.length < 1
            ? null
            : items.map((item, index) => (<li className="PatientsList__item" key={index}><PatientCard patient={item}/></li>))
        }
      </ul>
    </div>
  )
};

export {
  PatientsList
}