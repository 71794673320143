import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import { Typography } from '@telavita-core/react-design-kit'
import { ReactComponent as ClearIcon } from '../../static/svg/clear.svg'

function ClearSearch(props) {
  const { fn, titleName, titleCustomClassName } = props

  return (
    <div className="ClearSearch">
      <div className="ClearSearch__SideLine" />
      <div className="ClearSearch__TextArea"
        onClick={() => fn()}>
        <ClearIcon />
        <Typography 
          weight='bold'
          customClassName={titleCustomClassName}
          color="primary"
        >
          { titleName }
        </Typography>
      </div>
      <div className="ClearSearch__SideLine" />
    </div>
  )
}


ClearSearch.propTypes = {
  fn: PropTypes.func,
  titleName: PropTypes.string,
}

ClearSearch.defaultProps = {
  titleName: 'Limpar resultados',
}

export { ClearSearch }