import { parse, parseISO } from 'date-fns'

import { createAxiosInstance } from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  slug: string,
  username: string,
  profileRole?: string
  startDate: string,
  endDate: string,
  planCode: string,
  productCode: string,
  isRescheduling?: boolean,
  scheduleID?: number,
  isAuthorized?: boolean
  isParticular?: boolean
}

interface ResponseAvailability {
  start_date: string,
  end_date: string,
}

interface ResponseSchedule {
  start_date: string,
  end_date: string,
}

interface Response {
  data: {
    availabilities: { [key: string]: ResponseAvailability[] }
    patient_schedules: ResponseSchedule[]
  }
}

interface AvailabilityOptions {
  startDate: Date,
  endDate: Date
}

interface Availability {
  day: Date,
  options: AvailabilityOptions[]
}

interface ProfessionalAvailability {
  availabilities: Availability[],
  schedules: Date[],
}

interface Error {
  response: {
    status: number;
  }
}

export const getProfessionalAvailability = ({
  slug,
  username,
  profileRole,
  startDate,
  endDate,
  planCode,
  productCode,
  scheduleID,
  isRescheduling,
  isAuthorized = true,
  isParticular = false
}: Payload): Promise<ProfessionalAvailability> => {

  const SPECIALITY_API_URL = {
    PSIC: apiEndpoints.AGENDA_AVAILABILITY(slug),
    PSIQ: (() => {
      if (isParticular) {
        return apiEndpoints.AGENDA_AVAILABILITY(slug)
      }
      return apiEndpoints.PROFESSIONAL_AGENDA_AVAILABILITY(slug)
    })()
  }

  const endpoint = SPECIALITY_API_URL[profileRole] as string

  const params = {
    username: username,
    group_by: 'date',
    start_date: startDate,
    end_date: endDate,
    plan_code: planCode,
    product_code: productCode,
    profile_role: profileRole,
    ...(username && { patient_username: username }),
    ...(isRescheduling && { is_rescheduling: isRescheduling }),
    ...(scheduleID && { schedule_id: scheduleID }),
  }

  const headers = !isAuthorized ? { 'Authorization': null } : {}

  const axios = createAxiosInstance(headers)

  return new Promise <ProfessionalAvailability> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        const {
          availabilities,
          patient_schedules
        } = response.data
        const schedules = patient_schedules || []

        const filteredAvailabilities = Object.keys(availabilities).filter(date => (
          availabilities[date].length > 0
        ))

        const parsedAvailabilities = filteredAvailabilities.map(date => ({
          day: parse(date, 'yyyy-MM-dd', new Date()),
          options: availabilities[date].map(availability => ({
            startDate: parseISO(availability.start_date),
            endDate: parseISO(availability.end_date),
          }))
        }))

        const parsedSchedules = schedules.map(sch => parseISO(sch.start_date))

        resolve({
          availabilities: parsedAvailabilities,
          schedules: parsedSchedules,
        } as ProfessionalAvailability)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}
