import React from "react";
import PropTypes from 'prop-types';

const Breadcrumb = ({ steps }) => {
  return (
    <div className="Breadcrumb">
      {
        steps.map((step, index) => {
          return (
            <span className="Breadcrumb__item"
              key={index} onClick={step.href}>
              {step.title}
            </span>
          );
        })
      }
    </div>
  );
};

Breadcrumb.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.any,
      href: PropTypes.any,
    })
  ),
};

Breadcrumb.defaultProps = {
  steps: [
    {
      title: 'home',
      // href: '/',
    },
  ],
};

export { Breadcrumb };