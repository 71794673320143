export const CANCELATION_SCHEDULE_REASONS_OPTIONS = [
  {
    label: 'Imprevisto',
    code: 'RES01003',
    id: 1
  },
  {
    label: 'Inelegibilidade',
    code: 'RES01010',
    id: 2
  },
  {
    label: 'Problemas Técnicos',
    code: 'RES01002',
    id: 3
  },
  {
    label: 'Férias',
    code: 'RES01009',
    id: 4
  },
  {
    label: 'Token Inválido',
    code: 'RES01011',
    id: 5
  },
  {
    label: 'Outros',
    code: 'RES01008',
    id: 6
  }
]