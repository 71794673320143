import { Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  width: 210px;
`

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  padding: 15px 20px;
  border-bottom: 1px solid #e2e2e2;
`

export const Label = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  color: #898989;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  background: transparent;
  border: initial;
  font-size: 16px;
  padding: 0;
`

export const ButtonText = styled(Typography)`
  font-size: 15px;
  color: #4b4b4b;
`

export const OptionsContainer = styled.ul`
  display: flex;
  align-items: flex-start;
  padding: 0;
  flex-direction: column;
  overflow: hidden;
  margin: 0;

  li:last-child {
    border-radius: 0 0 5px 5px;
  }
`

export const OptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  height: 48px;
  padding: 16px 20px;
  p {
    padding: 0
  }
`

export const TextWrapper = styled.div``