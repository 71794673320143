import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components';

const Button = ({
  children,
  classModifier,
  data,
  disabled,
  extraClass = "",
  hasData,
  id,
  loading,
  onButtonClick,
  text,
  type,
  ...rest
}) => {
  return (
    <button
      className={classModifier ? `Button--${classModifier} ${extraClass}` : `Button ${extraClass}`}
      disabled={disabled}
      id={id}
      onClick={loading ? null : onButtonClick}
      type={type}
      {...rest}
    >
        {
          loading ? 
            <Spinner height="20px" color="white" />
          :
            <>
              {text}
              {children} 
              {hasData && <span>{data}</span>}
            </>
        }
        
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  classModifier: PropTypes.oneOf([
    'default',
    'false',
    'online',
    'reverse',
    'reverse--grey',
    'reverse--grey--padded',
    'reverse--grey--lighter',
  ]),
  data: PropTypes.string,
  disabled: PropTypes.bool,
  extraClass: PropTypes.string,
  hasData: PropTypes.bool,
  id: PropTypes.string,
  loading: PropTypes.bool,
  onButtonClick: PropTypes.func,
  text: PropTypes.node,
  type: PropTypes.string,
};

Button.defaultProps = {
  data: "aditional data",
  disabled: false,
  hasData: false,
  type: "button",
  loading: false
};

export { Button };