/*
 * Error handlers
*/

const ERRORS_TYPE = {
  'error_field': 'fieldError',
  'error': 'sysError',
  'messages': 'sysError',
  'feedback': 'feedbackError'
}

export const handleApiErrors = (responseData) => {
  const errorMessages = responseData?.messages

  const errorList = errorMessages?.reduce((acc, curr) => {

    const eventTypeKey = ERRORS_TYPE[curr.type]

    acc[eventTypeKey] = [...acc[eventTypeKey], curr]

    return acc
  },{ fieldError: [], sysError: [], feedbackError: [] })

  return errorList
}