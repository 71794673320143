import React from 'react'
import PropTypes from 'prop-types'

import * as routes from '../../settings/_routesSettings'

// COMPONENTS
import ToggleFloatButton from '../ToggleFloatButton'
import { Typography, Avatar, CoreIcons } from 'components'

import { ACTIVE, INACTIVE, INCOMPLETE_ACCOUNT } from '../../settings/_personSettings'

function ProfessionalListItem({
  professionalName,
  friendlyUrl,
  professionalEmail,
  professionalDocuments,
  professionalDocumentValue,
  id,
  professionalStatus,
  avatarUrl,
  avatarSize,
  menuList,
  iconName,

}) {

  let text = ''
  let fill = ''
  
  const isPsychiatrist = professionalDocuments === 'CRM'
  const externalProfessionalProfileUrl = isPsychiatrist 
    ? `${process.env.REACT_APP_WEB_APP_URL}${routes.PROFESSIONAL_PSIQ_PROFILE(friendlyUrl)}` 
    : `${process.env.REACT_APP_WEB_APP_URL}${routes.PROFESSIONAL_PSIC_PROFILE(friendlyUrl)}`

  switch (professionalStatus) {
  case ACTIVE:
    professionalStatus = '--active'
    text = ' Cadastro Ativo'
    fill = '#4AC326'
    break

  case INACTIVE:
    professionalStatus = '--inactive'
    text = ' Cadastro Inativo'
    fill = '#FF4949'
    break

  case INCOMPLETE_ACCOUNT:
    professionalStatus = '--incomplete'
    text = ' Cadastro incompleto'
    fill = '#F9A435'

    break

  default:
    professionalStatus = ''
    break
  }

  function buildMenuList () {
    return menuList
  }

  return (
    <div className='ProfessionalListItem'>

      <div className='ProfessionalListItem__Avatar'>
        <Avatar
          imgUrl={avatarUrl}
          avatarSize={avatarSize}
        />

      </div>

      <div className='ProfessionalListItem__Content'>

        <div className='ProfessionalListItem__Content__name'>
          <a href={externalProfessionalProfileUrl} target="_blank" rel="noopener noreferrer">
            <Typography bold >
              {professionalName}
            </Typography>
          </a>

          <Typography>
            {professionalEmail}
          </Typography>
        </div>

        <div className='ProfessionalListItem__Content__info'>


          <div className='ProfessionalListItem__Content__info__document'>
            <div className='ProfessionalListItem__Content__info__document--text'>
              <Typography >
                {professionalDocuments === 'CRM' ? 'Psiquiatra - ' : 'Psicólogo - '}
                {professionalDocuments ? professionalDocuments : 'CRP'}
              </Typography>
            </div>
            <Typography >
              {professionalDocumentValue ? professionalDocumentValue : '0000000'}
            </Typography>


          </div>
          <div className='ProfessionalListItem--footer'>
            <div className='ProfessionalListItem__Content__info__id'>
              <Typography color="grey-dark">
                ID: {id}
              </Typography>

            </div>
            <div className='ProfessionalListItem__Content__info__status'>
              <div className={`statusIcon${professionalStatus}`}>
                <Typography align='left'>
                  <CoreIcons fill={fill} height='13px' name="StatusIcon" width='13px' />

                  {text}

                </Typography>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='ProfessionalListItem--icon'>
        <ToggleFloatButton menuList={buildMenuList()}
          iconName={iconName}
        />
      </div>
    </div>

  )
}

ProfessionalListItem.propTypes = {
  professionalDocuments: PropTypes.string,
  professionalDocumentValue: PropTypes.string,
  professionalName: PropTypes.any,
  id: PropTypes.any,
  professionalStatus: PropTypes.oneOf([
    'STS24001',
    'STS24002',
    'STS24004',
    'STS24003',
  ]),
  avatarUrl: PropTypes.string,
  avatarSize: PropTypes.string,
}

export default ProfessionalListItem