import React from 'react';
import PropTypes from 'prop-types';

import Timer from 'react-compound-timer';

const TimerContainer = ({
  classModifier,
  initialTime,
  onTimerStop
}) => {
  return (
    <span className={classModifier ? `TimerContainer--${classModifier}` : 'TimerContainer'}>
      <Timer
        direction="backward"
        formatValue={value => `${(value < 10 ? `0${value}` : value)}`}
        initialTime={initialTime}
        onStop={onTimerStop}
      >
        {
          ({ setCheckpoints, stop }) => {
            setCheckpoints([{ time: 0, callback: stop, }])

            return <> <Timer.Minutes />:<Timer.Seconds /> </>
          }
        }
      </Timer>
    </span>
  )
}

TimerContainer.propTypes = {
  classModifier: PropTypes.string,
  initialTime: PropTypes.number,
  onTimerStop: PropTypes.func,
}

export default TimerContainer