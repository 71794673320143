export function customTooltip(tooltipModel) {
  let tooltipEl = document.getElementById('chartjs-tooltip')

  if (!tooltipEl) {
    tooltipEl = document.createElement('div')
    tooltipEl.id = 'chartjs-tooltip'
    tooltipEl.innerHTML = '<div id="tooltip-arrow"></div><table></table>'
    document.body.appendChild(tooltipEl)
  }

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = 0
    tooltipEl.style.display = 'none'
    return
  }

  if (tooltipModel.body) {
    const titleLines = tooltipModel.title || []
    const bodyLines = tooltipModel.body.map(b => b.lines)

    const tableHead = document.createElement('thead')

    titleLines.forEach((title, i) => {
      const tr = document.createElement('tr')
      tr.style.borderWidth = 0

      const th = document.createElement('th')
      th.style.borderWidth = 0
      th.style.lineHeight= 1
      th.style.fontFamily = 'Roboto, sans-serif'
      th.style.fontWeight = 'bold'
      th.style.fontSize = '12px'
      th.style.color = '#4b4b4b'

      const text = document.createTextNode(title)

      const colors = tooltipModel.labelColors[i]

      const span = document.createElement('span')
      span.style.background = colors.backgroundColor
      span.style.borderColor = colors.borderColor
      span.style.borderWidth = 0
      span.style.borderRadius = '50%'
      span.style.marginRight = '5px'
      span.style.height = '10px'
      span.style.width = '10px'
      span.style.display = 'inline-block'

      th.appendChild(span)
      th.appendChild(text)
      tr.appendChild(th)
      tableHead.appendChild(tr)
    })

    const tableBody = document.createElement('tbody')
    bodyLines.forEach(body => {
      const tr = document.createElement('tr')
      tr.style.backgroundColor = 'inherit'
      tr.style.borderWidth = 0
      tr.style.lineHeight = 1
      tr.style.fontFamily = 'Roboto, sans-serif'
      tr.style.fontWeight = 'regular'
      tr.style.fontSize = '12px'

      const td = document.createElement('td')
      td.style.borderWidth = 0

      const span = document.createElement('span')
      span.style.display = 'inline-block'
      span.style.width = '15px'

      const text = document.createTextNode(body)

      td.appendChild(span)
      td.appendChild(text)
      tr.appendChild(td)
      tableBody.appendChild(tr)
    })

    const tableRoot = tooltipEl.querySelector('table')
    const tooltipArrow = tooltipEl.querySelector('#tooltip-arrow')

    tooltipArrow.style.position = 'absolute'
    tooltipArrow.style.bottom = '100%'
    tooltipArrow.style.left = '50%'
    tooltipArrow.style.marginLeft = '-5px'
    tooltipArrow.style.borderWidth = '5px'
    tooltipArrow.style.borderStyle = 'solid'
    tooltipArrow.style.borderColor = 'transparent transparent white transparent'
    
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove()
    }

    tableRoot.appendChild(tableHead)
    tableRoot.appendChild(tableBody)
  }

  const position = this._chart.canvas.getBoundingClientRect()

  tooltipEl.style.opacity = 1
  tooltipEl.style.display = 'block'
  tooltipEl.style.pointerEvents = 'none'
  tooltipEl.style.background = '#fff'
  tooltipEl.style.maxWidth = '150px'
  tooltipEl.style.borderRadius = '5px'
  tooltipEl.style.boxShadow = '0px 1px 10px #00000029'
  tooltipEl.style.position = 'absolute'
  tooltipEl.style.zIndex = '100'
  tooltipEl.style.left = position.left + window.scrollX + tooltipModel.caretX + 'px'
  tooltipEl.style.top = position.top + window.scrollY + tooltipModel.caretY + 'px'
  tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily
  tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px'
  tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle
  tooltipEl.style.transform = 'translate(-50%, 0)'
  tooltipEl.style.transition = 'all .1s ease'
  tooltipEl.style.padding = '10px'
}