import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useProfile } from 'hooks'
import * as permissions from '../settings/_permissionSettings'
import * as Section from '../views/Dashboard/Sections'
import * as routes from '../settings/_routesSettings'
import PermissionRoute from './PermissionRoute'
import { PrivateRouteRole } from './PrivateRouteRole'
import { TVTVPACI, TVTVPSIC, TVTVPSIQ, TVTVADMN } from 'settings/_profileSettings'


const DashboardRouter = props => {
  const userHomeRoute = routes.DASHBOARD_ADMIN_SCHEDULES

  return (
    <Switch>
      <Route
        exact={true}
        from={[routes.DASHBOARD, routes.DASHBOARD_ROUTER]}
        render={() => <Redirect to={userHomeRoute} />}
      />
      <PermissionRoute
        path={routes.DASHBOARD_GENERAL_VIEW}
        component={Section.GeneralView}
        permission={permissions.ADMIN_GENERAL_VIEW}
        exact
      />
      <PermissionRoute
        path={routes.DASHBOARD_AGENDA}
        component={Section.ProfessionalAgenda}
        permission={permissions.USER_VIEW_AGENDA}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PATIENTS}
        component={Section.MyPatients}
        permission={permissions.USER_VIEW_PATIENTS}
      />
      <PermissionRoute
        path={routes.DASHBOARD_ADMIN_AGENDA()}
        component={Section.AdminAgenda}
        permission={permissions.ADMIN_VIEW_AGENDA}
      />
      <PermissionRoute
        path={routes.DASHBOARD_APPOINTMENTS}
        component={Section.AppointmentsList}
        permission={permissions.USER_VIEW_MYAPPOINTMENTS}
      />
      <PermissionRoute
        path={routes.DASHBOARD_CLIENT_LIST}
        component={Section.ClientList}
        permission={permissions.ADMIN_VIEW_PATIENTS}
      />
      <PermissionRoute
        path={routes.DASHBORAD_REPORTS}
        component={Section.Reports}
        permission={permissions.ADMIN_VIEW_REPORTS}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PERMISSIONS}
        component={Section.Permissions}
        permission={permissions.ADMIN_VIEW_PERMISSIONS}
      />
      <PermissionRoute
        path={routes.DASHBOARD_EDIT_USER()}
        component={Section.EditUser}
        permission={permissions.ADMIN_EDIT_PERSON}
      />
      <PermissionRoute
        path={routes.DASHBOARD_NEW_USER()}
        component={Section.NewPatient}
        permission={permissions.ADMIN_ADD_PERSON}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PAYMENT_LIST}
        component={Section.PaymentList}
        permission={permissions.ADMIN_VIEW_PAYMENT}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PROFESSIONAL_LIST()}
        component={Section.ProfessionalListView}
        permission={permissions.ADMIN_VIEW_PROFESSIONALS}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PAYMENT_DETAIL()}
        component={Section.PaymentDetailView}
        permission={permissions.ADMIN_VIEW_PAYMENT}
      />
      <PermissionRoute
        path={routes.DASHBOARD_ADMIN_SCHEDULES}
        component={Section.AdminScheduleList}
        permission={permissions.ADMIN_VIEW_APPOINTMENTS}
      />
      <PermissionRoute
        exact
        path={routes.DASHBOARD_ADD_PROFESSIONAL()}
        component={Section.NewProfessional}
        permission={permissions.ADMIN_ADD_PERSON}
      />
      <PermissionRoute
        exact
        path={routes.DASHBOARD_EDIT_PROFESSIONAL()}
        component={Section.NewProfessional}
        permission={permissions.ADMIN_EDIT_PERSON}
      />
      <PermissionRoute
        path={routes.DASHBOARD_PLANS}
        component={Section.Plans}
        permission={permissions.USER_VIEW_PLANS}
      />
      <PermissionRoute
        path={routes.DASHBORAD_ADMIN_MEDICAL_REQUESTS}
        component={Section.MedicalRequests}
        permission={permissions.ADMIN_VIEW_MEDICAL_REQUEST}
      />
      <PermissionRoute
        path={routes.DASHBORAD_MEDICAL_REQUESTS}
        component={Section.MedicalRequests}
        permission={permissions.USER_VIEW_MEDICAL_REQUEST}
      />
      <PrivateRouteRole
        path={routes.DASHBOARD_PLAN_GROUP_LIST_VIEW}
        useRender
        render={() => {
          if (!props.elastic)
            props.setElastic(true)
          return <Section.PlanGroupsList />
        }}
        allowedRoles={[TVTVADMN]}
        exact
      />
      <PrivateRouteRole
        path={routes.DASHBOARD_PLAN_GROUP_LIST_NEW}
        component={Section.NewPlanGroup}
        allowedRoles={[TVTVADMN]}
      />
      <PrivateRouteRole
        path={'/dashboard/admin/reservation/:professional'}
        component={Section.AdminReservations}
        allowedRoles={[TVTVADMN]}
      />
    </Switch>
  )
}

export default DashboardRouter
