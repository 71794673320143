import React, { Fragment } from 'react';

// COMPONENTS
import { Typography, Container, Button } from 'components';
import Footer from '../../components/Footer';
import HeaderMenu from '../../containers/HeaderMenu';
import * as routes from '../../settings/_routesSettings';
import { ReactComponent as Icon404 } from 'static/svg/icon404.svg';

import viewportHoc from '../../hoc/viewportHoc';

const NotFound = ({ isMobile }) => (
  <Fragment>
    <div>
      <Container>

        <div className="CustomError">

          <div>
            <Icon404 className="CustomError__Img" />
          </div>

          <Typography
            bold
            align="center"
            color="primary"
            type="custom"
            Tag="h1"
            style={{
              fontSize: '30px',
              fontWeight: '500',
              lineHeight: '27px'
            }}
          >
            Página não encontrada!
          </Typography>

          <div className="CustomError__Subtitle">
            <Typography
              align="center"
              variant="custom"
              style={{
                fontSize: '15px',
                lineHeight: '19px'
              }}
            >
              A página não existe mais ou você pode ter digitado o endereço errado.
            </Typography>
          </div>

          <div className="CustomError__ButtonBox">
            <a href={routes.LOGIN}>
              <Button extraClass="CustomError__ButtonBox__Button">
                VOLTAR PARA A PÁGINA INICIAL
              </Button>
            </a>
          </div>

        </div>

      </Container>
    </div>
  </Fragment>
);

export default viewportHoc(NotFound);
