import { AxiosError } from 'axios'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface GetProfessionalDataProps {
  profile_role?: string
  username?: string
}

interface ProfessionalData {
  person_profile: Array<{
   products: Array<{
    appointments: Array<{
      plans: Array<{
        id: number
        enable_new_patients: boolean
        date_updated: string
        user_updated: string
        user_updated_full_name: string
        plan_group_name: string
        plan: {
          id: number
          code: string
          name: string
          active: boolean
        }
      }>
    }>
   }>
  }>
}

interface Response {
  data: ProfessionalData
}

const getProfessionalData = ({
  profile_role,
  username,
}: GetProfessionalDataProps): Promise<ProfessionalData> => {

  const endpoint = apiEndpoints.PERSON_SLUG(username, profile_role)

  return new Promise<ProfessionalData> ((resolve, reject) => {
    axios.get(endpoint)
      .then((response: Response) => resolve(response.data))
      .catch(((err: AxiosError) => reject(err.response.status)))
  })
}

export {
  getProfessionalData
}
