import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface ScheduleInfoProps {
  scheduleDate: string
}

export const ScheduleInfo: React.FC<ScheduleInfoProps> = (scheduleData) => {
  return (
    <S.Container>
      <Typography variant='content2'>Consulta tinha sido agendada para:</Typography>
      <Typography variant="content2">{scheduleData?.scheduleDate}</Typography>
    </S.Container>
  )
}