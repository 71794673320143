import { GET_DOCUMENTS } from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

export const initialState = { documents: []  };

const documentsSucess = (state, { payload }) => {
  return updateState(state, { documents: payload.documents });
};

const documentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCUMENTS: return documentsSucess(state, action);
    default: return state;
  }
};

export default documentsReducer;