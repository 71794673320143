import React from "react";
import PropTypes from "prop-types";
import { Typography, Avatar, Button } from 'components';
import { Trans } from "@lingui/macro";

function SimplePersonItem({
  personId,
  onButtonClick,
  personAvatar,
  personName,
  personEmail
}) {
  return (
    <React.Fragment>
      <div className="SimplePersonItem">
        <div className="SimplePersonItem__PersonData">
          <div className="SimplePersonItem__PersonData__image">
            <Avatar
              avatarSize="50px"
              imgAlt={personName}
              imgUrl={personAvatar}
            />
          </div>
          <div className="SimplePersonItem__PersonData__name">
            <Typography bold>{personName}</Typography>
            <Typography>{personEmail}</Typography>
          </div>
        </div>
        <div className="SimplePersonItem__Button">
          <Button
            classModifier="reverse"
            disabled={false}
            id={`edit-permissions-${personId}`}
            onButtonClick={onButtonClick}
            text={<Trans>EDITAR</Trans>}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

SimplePersonItem.propTypes = {
  personId: PropTypes.number,
  onButtonClick: PropTypes.func,
  personAvatar: PropTypes.string,
  personName: PropTypes.string
};

export default SimplePersonItem;
