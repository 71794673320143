// Especialidades atendidas na Telavitaq
const Specialities = [
    {
        name: "Psicologia",
        code: "PSIC"
    },
    {
        name: "Psiquiatria",
        code: "PSIQ"
    }
]

export { Specialities }