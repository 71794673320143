//Relative path from root folder (src) ../../../

import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BoxBgRounded extends Component {
  render() {
    const { color } = this.props;

    return (
      <div className={`BoxBgRounded ${color}`}>
        {this.props.children}
      </div>
    );
  }
}

BoxBgRounded.propTypes = { color: PropTypes.string  };

BoxBgRounded.defaultProps = { color: ''  };

export { BoxBgRounded };

