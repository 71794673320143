import React, { useCallback, useEffect, useRef, useState } from 'react'
import { LabelList, LineChart, XAxis, Line, ResponsiveContainer } from 'recharts'
import { Badge, Button, Typography } from '@telavita-core/react-design-kit'

import { NpsHelpModal } from 'containers/ModalManager/Modals/NpsHelpModal'
import { NpsHowToImproveModal } from 'containers/ModalManager/Modals/NpsHowToImproveModal'

import { GraphicsCard } from 'components/GraphicsCard'

import HelpFilledIcon from 'components/CoreIcons/Icons/Help'
import ArrowLeftIcon from 'components/CoreIcons/Icons/ArrowLeft'
import ArrowRightIcon from 'components/CoreIcons/Icons/ArrowRight'

import { 
  Arrows, 
  Container, 
  IconButton, 
  Footer, 
  GraphicWrapper, 
  LeftSection,
  LeftSectionText,
  BadgeText 
} from './styles'

type Data = {
  month: string
  year: string
  nps: number
}

interface CustomizedLabelGraphProps {
  x?: number
  y?: number
  value?: number
}

interface CustomizedDotProps {
  value?: number
  cx?: number
  cy?: number
}

const mockData: Data[] = [
  {
    month: 'Jun',
    year: '21',
    nps: 6.9,
  },
  {
    month: 'Jul',
    year: '21',
    nps: 5,
  },
  {
    month: 'Ago',
    year: '21',
    nps: 7,
  },
  {
    month: 'Set',
    year: '21',
    nps: 10,
  },
  {
    month: 'Out',
    year: '21',
    nps: 8.8,
  },
  {
    month: 'Nov',
    year: '21',
    nps: 4,
  },
  {
    month: 'Dez',
    year: '21',
    nps: 9,
  },
  {
    month: 'Jan',
    year: '22',
    nps: 7.5,
  },
  {
    month: 'Fev',
    year: '22',
    nps: 3.5,
  },
  {
    month: 'Mar',
    year: '22',
    nps: 1,
  },
  {
    month: 'Abr',
    year: '22',
    nps: 5,
  },
  {
    month: 'Mai',
    year: '22',
    nps: 8,
  },
  {
    month: 'Jun',
    year: '22',
    nps: 9.3,
  }
]

const npsColors = {
  detractors: '#FD4949',
  neutral: '#FCBD2F',
  promoters: '#3ACB23',
  none: '#898989'
}

function replaceDotWithComma(value: number | string) {
  return String(value)?.replace('.', ',')
}

function handleDataColor(value: number) {
  const detractorsReference = 6.9
  const promotersReference = 9

  if (value <= detractorsReference) {
    return npsColors.detractors
  }

  if (value > detractorsReference && value < promotersReference) {
    return npsColors.neutral
  }

  if (value >= promotersReference) {
    return npsColors.promoters
  }

  return npsColors.none
}

const CustomizedDot = ({ cx, cy, value }: CustomizedDotProps) => {
  const color = handleDataColor(value)

  return (
    <svg x={cx - 4} y={cy - 4} width={100} height={100} fill={color} viewBox="0 0 1024 1024">
      <circle cx="50" cy="50" r="40" stroke={color} fill={color} />
    </svg>
  )
}

function CustomizedLabelGraph({
  value,
  x,
  y,
}: CustomizedLabelGraphProps) {
  return (
    <text
      x={x}
      y={y}
      fill={handleDataColor(value)}
      dy={-10}
      textAnchor='middle'
      fontSize={12}
      fontWeight='bold'
    >
      {replaceDotWithComma(value)}
    </text>
  )
}

const DATA_LENGTH = 6

export const ProfessionalNPS = (): JSX.Element => {
  const [showHelpModal, setShowHelpModal] = useState(false)
  const [showHowToImproveModal, setShowHowToImproveModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [leftArrowDisable, setLeftArrowDisable] = useState(false)
  const [rightArrowDisable, setRightArrowDisable] = useState(false)

  const [npsData, setNpsData] = useState<Data[]>([])
  const [averageNps, setAverageNps] = useState(0)

  const cardRef = useRef<HTMLDivElement>(null)

  const isLeftArrowDisable = useCallback(() => {
    if(npsData.length < mockData.length) {
      const firstValueData = mockData[0]

      return npsData.some(item => (item.month === firstValueData.month && item.year === firstValueData.year))
    }

    return true
  }, [npsData])

  const isRightArrowDisable = useCallback(() => {
    if(npsData.length < mockData.length) {
      const lastValueData = mockData[mockData.length - 1]

      return npsData.some(item => (item.month === lastValueData.month && item.year === lastValueData.year))     
    }

    return true
  }, [npsData])


  function navLeft() {
    const firstValueData = npsData[0]

    const firstValueDataIndex = mockData.findIndex(item => (item.month === firstValueData.month && item.year === firstValueData.year))
    const restData = mockData.slice(0, firstValueDataIndex)

    if (restData.length >= DATA_LENGTH) {
      const newData = mockData.slice((firstValueDataIndex - DATA_LENGTH), firstValueDataIndex)
      setNpsData(newData)
    } else {
      const newData = mockData.slice(0, DATA_LENGTH)
      setNpsData(newData)
    }
  }

  function navRight() {
    const lastValueData = npsData[npsData.length - 1]

    const lastValueDataIndex = mockData.findIndex(item => (item.month === lastValueData.month && item.year === lastValueData.year))
    const restData = mockData.slice((lastValueDataIndex + 1))

    if (restData.length >= DATA_LENGTH) {
      const newData = mockData.slice((lastValueDataIndex + 1), (lastValueDataIndex + DATA_LENGTH + 1))
      setNpsData(newData)
    } else {
      const newData = mockData.slice(-DATA_LENGTH)
      setNpsData(newData)
    }
  }

  useEffect(() => {
    const data = mockData.slice(-DATA_LENGTH)

    setNpsData(data)
    setAverageNps(data[data.length - 1].nps)
    setLoading(false)
  }, [])

  useEffect(() => {
    setLeftArrowDisable(() => isLeftArrowDisable())
    setRightArrowDisable(() => isRightArrowDisable())
  }, [npsData, isRightArrowDisable, isLeftArrowDisable])

  return (
    <>
      <GraphicsCard
        title='Nota NPS'
        headerAction={
          <IconButton onClick={() => setShowHelpModal(true)}>
            <HelpFilledIcon height={24} width={24} fill='#1F35B5'/>
          </IconButton>
        }
      >
        <Container ref={cardRef}>
          <Typography
            italic
            variant='content2'
          >
            Após a consulta, perguntamos ao paciente:<br />“O quanto você recomenda este profissional?”
          </Typography>

          <Arrows
            isLoading={loading}
            isEmpty={false}
          >
            <button
              onClick={navLeft}
              disabled={leftArrowDisable}
            >
              <ArrowLeftIcon 
                fill='#4b4b4b'
                height='24px'
                width='24px'
              />
            </button>
            <button
              onClick={navRight}
              disabled={rightArrowDisable}
            >
              <ArrowRightIcon 
                fill='#4b4b4b'
                height='24px'
                width='24px'
              />
            </button>
          </Arrows>

          <GraphicWrapper
            isLoading={loading}
            isEmpty={false}
          >
                
            <LeftSection>
              <Typography
                weight='bolder'
                inlineStyles={{
                  fontSize: '35px',
                  lineHeight: '35px',
                  color: handleDataColor(averageNps)
                }}
              >
                {replaceDotWithComma(averageNps)}
              </Typography>
              <LeftSectionText>
                Referente<br />
                aos últimos<br />
                6 meses
              </LeftSectionText>
              <Badge
                colorScheme='grey'
              >
                <BadgeText>
                  IDEAL:<br />
                  9 a 10
                </BadgeText>
              </Badge>
            </LeftSection>

            <ResponsiveContainer width='100%' height={130}>
              <LineChart
                margin={{
                  left: 30,
                  right: 30,
                  top: 20,
                }}
                data={npsData}
              >
                <Line
                  dataKey='nps'
                  stroke='#e2e2e2'
                  dot={<CustomizedDot />}
                  animationDuration={500}
                > 
                  <LabelList 
                    content={
                      <CustomizedLabelGraph />
                    }
                  />
                </Line>
                <XAxis
                  dataKey='month'
                  type='category'
                  name='months'
                  axisLine={false}
                  tickLine={false}
                  xAxisId={0}
                  fontSize={12}
                  stroke='#4b4b4b'
                />
                <XAxis
                  dataKey='year'
                  type='category'
                  name='year'
                  axisLine={false}
                  tickLine={false}
                  xAxisId={1}
                  dy={-15}
                  dx={-3}
                  height={5}
                  fontSize={12}
                  stroke='#adadad'
                />
              </LineChart>
            </ResponsiveContainer>
          </GraphicWrapper>

          <Footer>
            <Button
              uppercase={false}
              variant='text'
              onClick={() => setShowHowToImproveModal(true)}
            >
              Saiba como melhorar a sua nota
            </Button>
          </Footer>
        </Container>
      </GraphicsCard>

      {showHelpModal && ( 
        <NpsHelpModal onClose={() => setShowHelpModal(false)}/> 
      )}

      {showHowToImproveModal && (
        <NpsHowToImproveModal onClose={() => setShowHowToImproveModal(false)}/>
      )}
    </>
  )
}