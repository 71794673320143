import styled from 'styled-components'

export const Container = styled.div``

export const FloatButtonOption = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 3px #0000004D;
  padding: 0 10px 0 20px;
  border-radius: 100px;
  background-color: white;
  width: 240px;
  height: 56px;
  &:hover {
    background-color: #ebebeb;
  }      
`

export const FiltersContainer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto;
  @media (max-width: 1096px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`

export const TextInputWrapper = styled.div`
  width: 220px;
  @media (max-width: 1096px) {
    width: 100%;
  }
`

export const HeaderButtonWrapper = styled.div`
  margin-bottom: 10px;
  margin-left: 17px;
`

export const SkeletonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const SkeletonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #F5F5F5;
  height: 30px;
  padding: 10px;
`

export const SkeletonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  padding: 25px 0;
  border-top: 1px solid #E2E2E2;
  > div {
    display: flex;
    align-items: center;
  }
`

export const SkeletonProfessionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 30px;
  > div:nth-child(3) {
    margin-top: 10px;
  }
`

export const SearchPlaceHolderWrapper = styled.div`
  margin-top: 164px;
`