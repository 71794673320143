import { useQuery } from '@tanstack/react-query'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { SCHEDULE } from 'settings/_groupCodeSettings'

interface Status {
  id: number
  code: string
  name: string
  icon_url: string
}

interface GetStatusesResponse {
  status: Status[]
}

interface UseGetStatuses {
  statuses: Status[]
  isLoading: boolean
}

async function getStatuses(): Promise<GetStatusesResponse> {
  const params = {
    group_code: SCHEDULE,
    filter: true
  }
  const response = await axios.get(apiEndpoints.STATUS, { params })
  return response.data
}

export function useGetStatuses(): UseGetStatuses {
  const { data, isLoading } = useQuery({
    queryKey: ['ADMIN_FILTER_GET_STATUSES'],
    queryFn: getStatuses,
    cacheTime: Infinity,
    staleTime: Infinity,
  })

  return { 
    statuses: data ? data.status : [],
    isLoading
  }
}