import React from "react";

const MediaTest = ({
    fill = "#dde1f4",
    width = "190",
    height = "50",
    viewBox = "0 0 190 50",
}) => (
        <svg id="Grupo_5298" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width={width} height={height} viewBox={viewBox}>
            <defs>
                <clipPath id="clip-path">
                    <rect id="Retângulo_1613" width="190" height="50" fill={fill} />
                </clipPath>
            </defs>
            <g id="Grupo_5297" clipPath="url(#clip-path)">
                <path id="Caminho_4254" d="M44.072,31.015H55.839V2.445H9.132v28.57H19.825m45.28,16.9v.215a1.906,1.906,0,0,1-1.925,1.861H1.925A1.906,1.906,0,0,1,0,48.127v-.215l6.561-15.9V1.8a1.913,1.913,0,0,1,2-1.79h47.92A1.936,1.936,0,0,1,58.546,1.8V32.017ZM43.568,43.83v-.215L42.213,40.25v-.071a1.572,1.572,0,0,0-1.64-1.218H23.462a1.631,1.631,0,0,0-1.64,1.288l-1.355,3.365h.072v.215a1.6,1.6,0,0,0,1.627,1.578H41.93a1.649,1.649,0,0,0,1.64-1.577" fill={fill} />
                <path id="Caminho_4255" d="M118.492,40.241V4.249a1.418,1.418,0,0,0-1.4-1.435H96.54a1.42,1.42,0,0,0-1.431,1.408V40.241Zm2.859-3.9v9.356A4.33,4.33,0,0,1,117.061,50H96.539a4.331,4.331,0,0,1-4.289-4.306V4.307A4.331,4.331,0,0,1,96.538,0H117.06a4.33,4.33,0,0,1,4.29,4.306V36.338Zm-12.569,8.787v-.273a1.894,1.894,0,0,0-1.874-1.881h-.273a1.893,1.893,0,0,0-1.874,1.881v.273a1.894,1.894,0,0,0,1.874,1.881h.273a1.871,1.871,0,0,0,1.874-1.865v-.016" fill={fill} />
                <path id="Caminho_4256" d="M172.171,7.1A17.9,17.9,0,1,0,190,25,17.864,17.864,0,0,0,172.171,7.1m-1.616,25.825-8-7.052L164.9,23.13l5.268,4.506,8.78-10.187,2.733,2.354Z" fill={fill} />
                <path id="Caminho_4257" d="M65.186,23.543a1.779,1.779,0,0,1,1.654-1.894c.048,0,.1,0,.145,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
                <path id="Caminho_4258" d="M73.647,23.543A1.779,1.779,0,0,1,75.3,21.649c.049,0,.1,0,.146,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
                <path id="Caminho_4259" d="M82.1,23.543a1.779,1.779,0,0,1,1.654-1.894c.048,0,.1,0,.145,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
                <path id="Caminho_4260" d="M127.853,23.543a1.781,1.781,0,0,1,1.653-1.894c.049,0,.1,0,.146,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
                <path id="Caminho_4261" d="M136.31,23.543a1.779,1.779,0,0,1,1.653-1.894c.049,0,.1,0,.146,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
                <path id="Caminho_4262" d="M144.767,23.543a1.779,1.779,0,0,1,1.653-1.894c.048,0,.1,0,.146,0a1.9,1.9,0,1,1-1.8,1.991c0-.031,0-.063,0-.094" fill={fill} />
            </g>
        </svg>

    );

export default MediaTest;