/* PROFILES */
export const TVTVPACI = 'TVTVPACI'
export const TVTVPSIC = 'TVTVPSIC'
export const TVTVPSIQ = 'TVTVPSIQ'
export const TVTVADMN = 'TVTVADMN'
export const TVTVSECR = 'TVTVSECR'
export const TVCPPACI = 'TVCPPACI'

/* PROFILE ROLES */
export const PATIENT = 'PACI'
export const SECRETARY = 'SECR'
export const PROFESSIONAL = 'PROF'
export const PSYCHOLOGIST = 'PSIC'
export const PSYCHIATRIST = 'PSIQ'
export const ADMINISTRATOR = 'ADMN'