import { AxiosError } from 'axios'

import axios from '../settings/axios'
import { apiEndpoints } from '../settings/_apiSettings'

interface EligiblesGet {
  file: string
}

interface ResponseGet {
  data: {
    csv: string
  }
}

export const updateEligiblesBeneficiaresFile = (
  planGroupCode: string,
  csv: string,
  confirm: boolean
): Promise<void> => {

  const endpoint = apiEndpoints.GROUP_PLAN_ELIGIBLES_FILE(planGroupCode)
  const payload = {
    csv,
    confirm
  }

  return new Promise<void> ((resolve, reject) => {
    axios.post(endpoint, payload)
      .then(() => resolve())
      .catch((err: AxiosError) => reject(err))
  })
}

export const getEligiblesBeneficiaresFile = (
  planGroupCode: string
): Promise<EligiblesGet> => {

  const endpoint = apiEndpoints.GROUP_PLAN_ELIGIBLES_FILE(planGroupCode)

  return new Promise<EligiblesGet> ((resolve, reject) => {
    axios.get(endpoint)
      .then((response: ResponseGet) => {
        resolve({ file: response.data.csv } as EligiblesGet)
      })
      .catch((err: AxiosError) => reject(err))
  })
}