import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  scheduleId: number,
  review: string,
  rating: number,
}

interface Error {
  response: {
    status: number
  }
}

export const createReview = ({
  scheduleId,
  rating,
  review = '',
}: Payload): Promise<void> => {

  const endpoint = apiEndpoints.PROFESSIONAL_REVIEW as string

  const params = {
    schedule_id: scheduleId,
    rating: rating,
    review: review,
  }

  return new Promise<void> ((resolve, reject) => {
    axios.post(endpoint, params)
      .then(() => resolve())
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}
