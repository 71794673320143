import React from 'react'
import PropTypes from 'prop-types'

import { Typography, CoreIcons } from 'components'
import * as scheduleStatus from '../../settings/_scheduleStatusSettings'

function BoxDateDisplay({
  date,
  time,
  status,
}) {
  let iconName = null
  let text = null
  let color = null
  let textColor = null
  let timeColor = null

  switch (status) {
  case scheduleStatus.ATTENDED:
    iconName = 'Check'
    status = '--attended'
    color = '#4ac326'
    text = 'realizada'
    textColor = 'action-online'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.ATTENDING:
    iconName = ''
    status = '--attending'
    color = '#4ac326'
    text = 'atendendo'
    textColor = 'white'
    timeColor = 'white'
    break

  case scheduleStatus.CANCELED:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'cancelado'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.UNATTENDED:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'não realizada'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.RETURNED:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'devolvida'
    timeColor = 'grey-darker'
    break 

  case scheduleStatus.SCHEDULED:
    iconName = ''
    status = '--scheduled'
    text = 'agendada'
    textColor = 'white'
    timeColor = 'white'
    break

  case scheduleStatus.CANCEL_REQUESTED:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'Solicitação de Cancelamento'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.NOT_SCHEDULED:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'não agendado'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.PROFESSIONAL_NO_SHOW:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'profissional faltou'
    timeColor = 'grey-darker'
    break

  case scheduleStatus.PATIENT_NO_SHOW:
    iconName = 'Cancel'
    status = '--cancelled'
    color = '#FF4949'
    textColor = 'false'
    text = 'paciente faltou'
    timeColor = 'grey-darker'
    break

  case 'personal':
    iconName = ''
    status = '--personal'
    color = '#898989'
    text = 'agendada'
    textColor = 'white'
    timeColor = 'white'
    break

  default:
    break
  }

  return (
    <div className={`BoxDateDisplay${status}`}>
      <div className='header'>
        <div className='header__time'>
          <Typography
            color={timeColor}>
            {date}
          </Typography>
        </div>

        <div className='header__time'>
          <Typography
            bold
            type='heading1'
            color={timeColor}>
            {time}
          </Typography>
        </div>
      </div>

      <div className='footer'>
        <div className='footer__icon'>
          {
            iconName ? (
              <CoreIcons
                name={iconName}
                fill={color}
                width='20px'
                height='20px'
              />
            ) : null
          }
        </div>

        <div className='footer__text'>
          <Typography align='center'
            bold
            color={textColor}
            type='subheading2'>
            {text}
          </Typography>
        </div>
      </div>
    </div>
  )
}

BoxDateDisplay.propTypes = {
  status: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func,
}

export { BoxDateDisplay }
