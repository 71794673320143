import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

function Typography({
  align,
  bold,
  className,
  children,
  color,
  id,
  italic,
  gutterBotton,
  onTextClick,
  style,
  Tag,
  type,
  underline,
  uppercase,
}) {

  let compClassName = 'typography'

  if (type) {
    compClassName = `${compClassName} ${type}`
  } else {
    compClassName = `${compClassName} paragraph`
  }

  if (align) compClassName = `${compClassName} ${align}`
  if (bold) compClassName = `${compClassName} bold`
  if (color) compClassName = `${compClassName} ${color}`
  if (italic) compClassName = `${compClassName} italic`
  if (gutterBotton) compClassName = `${compClassName} gutter-bottom`
  if (onTextClick) compClassName = `${compClassName} clickable`
  if (uppercase) compClassName = `${compClassName} uppercase`
  if (underline) compClassName = `${compClassName} underline`
  if (className) compClassName = `${compClassName} ${className}`

  let compBase

  if (Tag) {
    compBase = (
      <Tag id={id}
        className={compClassName}
        onClick={onTextClick}
        style={style}>
        {children}
      </Tag>
    )
  } else {
    compBase = (
      <p id={id}
        className={compClassName}
        onClick={onTextClick}
        style={style}>
        {children}
      </p>
    )
  }

  return (
    <Fragment>
      {compBase}
    </Fragment>
  )
}

Typography.propTypes = {
  align: PropTypes.oneOf([
    'center',
    'inherit',
    'justify',
    'left',
    'mcenter-dleft', //center on mobile & left on desktop
    'mcenter-dright', //center on mobile & right on desktop
    'right',
  ]),
  bold: PropTypes.bool,
  color: PropTypes.oneOf([
    'action-online',
    'black',
    'color-title',
    'grey',
    'grey-dark',
    'grey-darker',
    'grey-light',
    'grey-ten',
    'grey-eleven',
    'grey-twelve',
    'orange',
    'primary',
    'white',
    'false',
    'red-lighter',
    'aqua',
    'clickable',
  ]),
  gutterBotton: PropTypes.bool,
  italic: PropTypes.bool,
  onTextClick: PropTypes.func,
  Tag: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'p',
    'b',
    'a',
    'span',
  ]),
  type: PropTypes.oneOf([
    'custom',
    'title',
    'regular-title',
    'subtitle',
    'paragraph',
    'paragraph-medium',
    'heading1',
    'heading2',
    'heading4',
    'heading5',
    'heading6',
    'subheading1',
    'subheading2',
    'menu-item',
  ]),
  underline: PropTypes.bool,
  uppercase: PropTypes.bool,
}

export { Typography }

