import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Trans } from '@lingui/macro';
import { compareAsc } from 'date-fns'

import MessageWithDate from '../../components/MessageWithDate';
import Row from '../../components/Row';
import { Typography, BoxBgRounded, Button, Column } from 'components';

import { hideModal } from '../../redux/actions';
import { getRoleFromProfile } from '../../utils/textTransformations';
import { getTime, toDayMonthYear } from '../../utils/dateTimeHandlers';

/**
 * This component has the responsibility
 * to checkout an order using the "BY_PASS"
 * payment method, and feedback the user 
 * about it success
 * WARN: It does not checkout if the prop
 * "hasCheckout" is set as false
 */
class ScheduleFeedback extends Component {
  static propTypes = { 
    hasCheckout: PropTypes.bool,
    orderId: PropTypes.number,
    professional: PropTypes.object,
    person: PropTypes.object,
    schedules: PropTypes.arrayOf(PropTypes.string),
  }

  state = { isLoading: true }

  renderSucessSchedules = () => {
    const { professional, schedules } = this.props; 
    const professionalName = professional.person_general.full_name;
    const professionalDocumentValue = professional.person_profile.find(profile => getRoleFromProfile(profile.profile_code)).documents[0].value;
    const professionalDocumentName = professional.person_profile.find(profile => getRoleFromProfile(profile.profile_code)).documents[0].type_name;
    const professionalDocument = `${professionalDocumentName}: ${professionalDocumentValue}`;
    const sortedSchedules = schedules.sort((a, b) => compareAsc(a, b));

    return (
      <div className="ScheduleFeedback__Success">
        <Row margin="0 0 0 0">
          <Column>
            <Typography align="center">
              <Typography Tag='span' color={'primary'}>
                <Trans>Marcado para:</Trans>
              </Typography>
            </Typography>
            {
              sortedSchedules.map(schedule => {
                const time = getTime(schedule);
                const date = toDayMonthYear(schedule);
                return (
                  <MessageWithDate
                    key={time}
                    bold 
                    color="primary" 
                    date={date}
                    time={time}
                  />
                );
              })
            }
          </Column>
        </Row>

        <Row>
          <Column>
            <Typography align='center'>
              <Trans>com</Trans>
            </Typography>
          </Column>
        </Row>

        <Row margin="0 0 2rem 0">
          <Column>
            <Typography align='center' bold>
              {professionalName}
            </Typography>
            <Typography align='center'>
              {professionalDocument}
            </Typography>    
          </Column> 
        </Row>

        <Row margin="2rem 0 0 0">
          <Column>
            <BoxBgRounded color="secondary-lighter">
              <Typography  align='center' bold>
                <Trans>Informe ao paciente que ele receberá um e-mail de confirmação com os próximos passos.</Trans>
              </Typography>
            </BoxBgRounded>
          </Column>
        </Row>
      </div>
    );
  }

  render() {
    const { onClose } = this.props;

    return (
      <div className="ScheduleFeedback">
        {
          this.renderSucessSchedules()
        }
        <Row margin="2rem 0 0 0">
          <Column>
            <Button text="Fechar" disabled={false} onButtonClick={() => onClose()} />
          </Column>
        </Row>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return { 
    onCloseModal: () => dispatch(hideModal()),
  };
};

export default connect(null, mapDispatchToProps)(ScheduleFeedback);