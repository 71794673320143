import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { rootReducer } from './modules/rootReducer';

export const configureStore = preloadedState => {

  const middlewares = [ thunk ];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  // add enhancers
  const storeEnhancers = [ middlewareEnhancer ];

  const composedEnhancer = composeWithDevTools(...storeEnhancers);
  let store;

  // create redux store
  if (process.env.REACT_APP_ENV !== 'production') {
    store = createStore(
      rootReducer,
      preloadedState,
      composedEnhancer
    );
  } else {
    store = createStore(
      rootReducer,
      preloadedState,
      middlewareEnhancer
    );
  }

  // Add HotReload to dev env
  // if (window.env.NODE_ENV !== 'production') {
  //   if (module.hot) {
  //     module.hot.accept('./reducers/rootReducer', () => {
  //       const newRootReducer = require('./reducers/rootReducer').default;
  //       store.replaceReducer(newRootReducer);
  //     });
  //   }
  // }

  return store;
};