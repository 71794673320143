import styled from 'styled-components'

interface BarItemProps {
  width: number
}

export const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  & + div {
    margin-top: 10px;
  }
`

export const BarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const BarItem = styled.div<BarItemProps>`
  width: calc(${({ width }) => width} * 2.35px);
  height: 12px;
  background-color: #1f35b5;

  &:hover {
    opacity: 0.7;
  }
`
