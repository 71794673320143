import React from 'react'

const ArrowClock = ({
  fill,
  height = 24,
  viewBox = '0 0 24 24',
  width = 24,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g id="Icon_Add_Filled" data-name="Icon / Add / Filled" transform="translate(-88 -580)">
      <rect id="Box" width="24" height="24" transform="translate(88 580)" fill="none"/>
      <g id="Grupo_227" data-name="Grupo 227" transform="translate(88 580)">
        <path id="Caminho_2171" data-name="Caminho 2171" d="M0,0H24V24H0Z" fill="none"/>
        <path id="Caminho_4237" data-name="Caminho 4237" d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20ZM11.78,7h-.06a.717.717,0,0,0-.72.72v4.72a.99.99,0,0,0,.49.86l4.15,2.49a.715.715,0,1,0,.73-1.23l-3.87-2.3V7.72A.717.717,0,0,0,11.78,7Z" fill={fill}/>
      </g>
    </g>
  </svg>

)


export default ArrowClock