
import axios from '../../../settings/axios'
import * as actions from 'redux/actions'
import * as unavailabilityActions from './actions'
import { modalTypes } from 'containers/ModalManager/ModalManager'

const postUnavailabilityRequest = (username, body) => async dispatch => {

  const params = {
    ...body
  }

  const url = `/v0/persons/${username}/agenda/events/`
  dispatch(unavailabilityActions.postUnavailability())

  try {
    const response = await axios.post(url, params)
    dispatch(unavailabilityActions.postUnavailabilitySuccess(response))
    dispatch({
      type: 'ADD_SCHEDULE',
      payload: {
        schedule: {
          id: response.data.event_id,
          ...body,

        }
      },
    })
    dispatch(actions.hideModal())
    dispatch(unavailabilityActions.updateEventList())
    dispatch(actions)
  } catch(err) {
    const errorMessage = err?.response?.data?.messages[0]?.info
    dispatch(unavailabilityActions.postUnavailabilityFailure({
      message: errorMessage
    }))
  }
}

const removeUnavailabilityRequest = (username, id) => async dispatch => {

  const url = `/v0/persons/${username}/agenda/event/${id}/`
  dispatch(unavailabilityActions.postUnavailability())

  try {
    const response = await axios.delete(url)
    dispatch(unavailabilityActions.postUnavailabilitySuccess(response))
    dispatch(actions.showModal(modalTypes.SUCCESS_MODAL, { title: 'Indisponibilidade removida com sucesso!' }))
    dispatch({
      type: 'REMOVE_SCHEDULE',
      payload: {
        id
      },
    })
    dispatch(unavailabilityActions.updateEventList())
  } catch(err) {
    const errorMessage = err?.response?.data?.messages[0]?.info
    dispatch(unavailabilityActions.postUnavailabilityFailure({
      message: errorMessage
    }))
  }
}

export { postUnavailabilityRequest, removeUnavailabilityRequest }