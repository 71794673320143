import React from 'react';
import classNames from 'classnames';

// STYLE
import './ProfessionalData.sass'

// COMPONENTS

const ProfessionalData = ({ children, label, spaced }) => {
  return (
    <div className={classNames('ProfessionalData', {
      'ProfessionalData--spaced': spaced
    })}>
      <p className="ProfessionalData__content">{children}</p>
      <p className="ProfessionalData__label">{label}</p>
    </div>
  )
}

export {
  ProfessionalData
}