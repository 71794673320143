import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import Sticky from 'react-sticky-el'

import { Typography, ClearSearch, TextInput, Spinner } from 'components'
import * as actions from '../../../redux/actions'
import { modalTypes } from '../../../containers/ModalManager/ModalManager'
import * as routes from '../../../settings/_routesSettings'
import * as profiles from '../../../settings/_profileSettings'
import * as permissions from '../../../settings/_permissionSettings'
import { ACTIVE } from '../../../settings/_personSettings'
import { history } from '../../../routers/AppRouter'
import { hasPermission } from '../../../utils/permissionHandlers'
import viewportHoc from '../../../hoc/viewportHoc'
import FloatActionButtonMobile from '../../../components/FloatActionButtonMobile'
import FloatActionButtonDesktop from '../../../components/FloatActionButtonDesktop'
import ProfileListItem from '../../../components/ProfileListItem'
import Row from '../../../components/Row'
import SearchPlaceHolder from '../../../components/SearchPlaceholder'
import ModalAdminSchedule from 'containers/ModalManager/Modals/ModalAdminSchedule'

const iconProps = {
  fill: '#1F35B5',
  height: '30px',
  name: 'Search',
  viewBox: '3 2 30 30',
  width: '30px',
}

const stickyFilter = {
  backgroundColor: '#fff',
  padding: '2.5rem 0px 2rem',
  zIndex: '2',
}

class ClientList extends Component {
  state = {
    query: '',
    loading: false,
    isCleaned: true,
    emptySearch: false,
    scheduleModalOpen: false,
  }

  componentDidUpdate(prevProps) {
    const { filterData } = this.props

    if (prevProps.filterData !== filterData) {
      this.setState({ loading: true })
      this.props.onListPersons({
        profile_role: profiles.PATIENT,
        query: filterData.text,
      }).then(() => {
        this.setState({
          loading: false,
          isCleaned: false
        })
      })
    }
  }

  handleSearchInput = e => {
    const inputValue = e.target.value

    this.setState({ query: inputValue })
  }

  closeScheduleModal = () => { this.setState({ scheduleModalOpen: false }) }

  handleSearchClick = () => {
    const { onClearPersons, onSetQuery } = this.props
    const { query } = this.state

    onClearPersons()

    if(query === ''){
      this.setState({ emptySearch: true })
      return
    }else{
      this.setState({ emptySearch: false })
    }

    this.setState({
      loading: true,
      isCleaned: false
    })

    onSetQuery(query)
  }

  handleMenuList = (person, personStatus, email) => {
    const { userPermissions } = this.props
    let menuList = []
    let modalData = {
      person,
      profileRole: profiles.PATIENT,
    }

    if (
      hasPermission(userPermissions, permissions.ADMIN_ADD_SCHEDULE) &&
      personStatus === ACTIVE
    ) {
      menuList.push({
        code: 'add-schedule',
        name: 'Agendar consulta',
        func: () => {
          this.setState({
            modalData,
            scheduleModalOpen: true
          })
        },
      })
    }

    if (hasPermission(userPermissions, permissions.ADMIN_EDIT_PERSON)) {
      menuList.push({
        code: 'edit-profile',
        name: 'Editar perfil',
        func: () =>
          history.push(routes.DASHBOARD_EDIT_USER(email, profiles.PATIENT)),
      })
    }

    if (
      this.props.profileRoleGroup === 'ADMN'
    ) {
      menuList.push({
        code: 'patient-detail',
        name: 'Detalhes do paciente',
        func: () => {
          this.setState({
            modalData,
          })
          this.props.onShowModal(modalTypes.PATIENT_DETAIL, modalData)
        },
      })
    }

    return menuList
  };

  onButtonClick = () => {
    history.push(routes.DASHBOARD_NEW_USER(profiles.PATIENT))
  };

  clearFilters = () => {
    this.props.onClearPersons()
    this.setState({ isCleaned: true })
  }

  render() {
    let { personsList, planGroup } = this.props
    const { loading, isCleaned, emptySearch, modalData, scheduleModalOpen } = this.state

    // Mostrar botão ADICIONAR PACIENTE somente para administradores ou
    // secretários que não possuem lista de elegíveis.
    let addPatientAllowed = true
    if(planGroup && planGroup.plan_group) addPatientAllowed = !planGroup.plan_group.use_eligibles_csv

    let renderList =
      personsList.length <= 0 && !emptySearch ? (
        <div className="dashboard-person-list--empty animation-shake">
          <SearchPlaceHolder
            titleText='Nenhum resultado'
            text='Refaça sua busca e seus filtros'
            iconName="NotFound"
            iconWidth="90"
            fill="#898989"
          />

        </div>
      ) : (
        personsList.length <= 0 && emptySearch ? (
          <div className="dashboard-person-list--empty">
            <SearchPlaceHolder
              titleText='Campo de busca vazio'
              phrase='Digite o nome ou e-mail'
            />

          </div>
        ) : (
          personsList.map(person => {
            const personProfile = person
            return (
              <ProfileListItem
                key={person?.id}
                avatar={person?.picture_url}
                id={person?.id}
                name={person?.full_name}
                email={person?.email}
                userDocument={person?.cpf}
                userId={person?.id}
                userStatus={personProfile}
                menuList={this.handleMenuList(
                  person,
                  personProfile?.status_code,
                  person?.email
                )}
              />
            )
          })
        ))

    return (
      <Fragment>
        {scheduleModalOpen && <ModalAdminSchedule patient={modalData} onClose={this.closeScheduleModal}/>}
        {
          !this.props.isDesktop && addPatientAllowed && (
            <FloatActionButtonMobile
              buttonId="new-professional"
              iconName="Positive"
              onClickFab={() => this.onButtonClick()}
              type="primary"
              title={<Trans>ADICIONAR PACIENTE</Trans>}
            />
          )
        }
        <Sticky stickyStyle={stickyFilter}>
          <Row align='center' justify={this.props.isDesktop ? 'space-between' : 'center'}
            margin={this.props.isDesktop ? '3.9rem 0 2.1rem' : '2.5rem 0 1.5rem'}>
            <Typography align='mcenter-dleft' Tag='h1' type='title'>
              <Trans>Pacientes</Trans>
            </Typography>
          </Row>
          <Row align='center' justify={this.props.isDesktop ? 'space-between' : 'center'}
            margin='2rem 0 1.5rem'>
            <div className='Dashboard-filter'>
              <div className='filter-input'>
                <TextInput
                  addIcon
                  iconProps={iconProps}
                  name="search_client"
                  inputHeight="4.8rem"
                  onInputChange={this.handleSearchInput}
                  onClickIcon={this.handleSearchClick}
                  onKeyPress={e => e.which === 13 && this.handleSearchClick()}
                  placeholder="Buscar nome, e-mail, CPF ou carteirinha"
                  value={this.state.query}
                />
              </div>
            </div>
            {
              this.props.isDesktop && addPatientAllowed && (
                <FloatActionButtonDesktop hasIcon
                  buttonId="new-professional"
                  iconName="AddPerson"
                  iconViewBox="-5 -5 30 30"
                  onClickFab={() => this.onButtonClick()}
                  title={<Trans>ADICIONAR PACIENTE</Trans>}
                  type="primary"
                />
              )
            }
          </Row>
          {!isCleaned && !emptySearch && personsList.length > 0 &&
            <Row>
              <ClearSearch fn={this.clearFilters} titleName='Limpar Resultados' />
            </Row>
          }
        </Sticky>

        {(loading) ?
          <Spinner height="80%" />
          :
          isCleaned && !emptySearch ?
            <div className="dashboard-person-list--empty">
              <SearchPlaceHolder
                titleText='Use a busca acima para'
                phrase='encontrar o paciente'
              />
            </div>
            :
            <div className="dashboard-person-list">{renderList}</div>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  filterData: state.filters,
  personsList: state.persons.persons,
  userPermissions: state.auth.profilePermissions,
  planGroup: state.auth.profileSelected.plans.find(plan => plan.default === true),
  profileRoleGroup: state.auth.userData.session.profile_role_group_active
})

const mapDispatchToProps = dispatch => ({
  onClearPersons: () => dispatch(actions.clearPersons()),
  onListPersons: (getParams) => dispatch(actions.listPatients(getParams)),
  onShowModal: (type, data) => dispatch(actions.showModal(type, data)),
  onSetQuery: query => dispatch(actions.setTextFilter(query)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(ClientList))
