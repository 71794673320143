import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { isScheduleNow } from 'helpers/scheduleHelpers'
import { homeProviders } from 'services'

import { 
  AuthStateSelector,
  IScheduleNotification, 
  Schedule, 
  ScheduleResponse
} from './types'

const formatNextSchedulesData = (schedule: ScheduleResponse) => {
  return schedule.schedules.map(({ 
    start_date, 
    end_date, 
    guests, 
    virtual_room_hash 
  }) => ({
    startDate: start_date,
    endDate: end_date,
    guests: guests.map(({person_profile: {profile_code, person, }}) => ({
      profileCode: profile_code,
      fullName: person?.person_general?.full_name
    })),
    virtualRoomHash: virtual_room_hash,
  }))
} 

export const useScheduleNotification = (): IScheduleNotification => {
  const [nextSchedulesList, setNextSchedulesList] = useState<Schedule[]>([])
  const [scheduleToStart, setScheduleToStart] = useState<Schedule>({} as Schedule)
  const [showNotification, setShowNotification] = useState(false)
  const [timeUp, setTimeUp] = useState(false)

  const username = useSelector((state: AuthStateSelector) => state?.auth?.userData?.session?.username)

  useEffect(() => {
    const timer = Date.now() >= new Date(scheduleToStart.startDate).getTime()
    setTimeUp(timer)
  }, [scheduleToStart.startDate])

  useEffect(() => {
    async function getNextSchedulesList() {
      const response = await homeProviders.getNextSchedules({username}) 
      const formattedNextSchedulesData: Schedule[] = formatNextSchedulesData(response.data)

      setNextSchedulesList(formattedNextSchedulesData)
    }

    void getNextSchedulesList()

  }, [username])

  useEffect(() => {
    const checkScheduleDate = () => {
      return nextSchedulesList.forEach(schedule => {
        if (isScheduleNow(schedule.startDate, schedule.endDate)) {
          setScheduleToStart(schedule)
          setShowNotification(true)
        }
      })
    }

    checkScheduleDate()
    const interval = setInterval(checkScheduleDate, 15000)

    return () => clearInterval(interval)
  }, [nextSchedulesList])

  return {
    scheduleToStart,
    showNotification,
    timeUp,
    setTimeUp
  }
}