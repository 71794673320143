const loadPatients = () => {
  return {
    type: 'LOAD_PATIENTS',
  }
};

const loadPatientsSuccess = (data) => {
  return {
    type: 'LOAD_PATIENTS_SUCCESS',
    payload: data
  }
};

const loadPatientsFailure = (err) => {
  return {
    type: 'LOAD_PATIENTS_FAILURE',
    payload: err
  }
};

const applyFilters = (value) => {
  return {
    type: 'APPLY_PATIENTS_FILTERS',
    payload: value
  }
};

const updateInteractions = (prop, value) => {
  return {
    type: 'UPDATE_INTERACTION',
    payload: {
      prop,
      value
    }
  }
}

export {
  loadPatients,
  loadPatientsSuccess,
  loadPatientsFailure,
  applyFilters,
  updateInteractions
}