import { CLEAN_PRODUCTS, GET_PRODUCTS } from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

export const initialState = { products: [] };

export const cleanProducts = (state) => {
  return updateState(state, initialState);
};

const getProducts = (state, { products }) => {
  return updateState(state, { products });
};

const productsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAN_PRODUCTS: return cleanProducts(state);
    case GET_PRODUCTS: return getProducts(state, action);
    default: return state;
  }
};

export default productsReducer;