import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { CoreIcons } from 'components';

function Toast({ className, icon, message, variant }) {

  let compClassName;
  let fill = "#fff";

  switch (variant) {
    case 'error':
      compClassName = "toast-error";
      fill = "#fff";
      break;

    case 'error_inverted':
      compClassName = "toast-error-inverted";
      fill = "rgba(239, 107, 123,95%)";
      break;

    case 'warn':
      compClassName = "toast-warn";
      fill = "#856404";
      break;

    case 'info':
      compClassName = "toast-info";
      fill = "#0c5460";
      break;

    case 'success':
      compClassName = "toast-success";
      break;

    case 'success-inverse':
      compClassName = "toast-success-inverse";
      break;

    case 'primary':
      compClassName = "toast-primary";
      break;

    default:
      compClassName = "toast-primary";
      break;
  }

  let count = 0;

  return (
    <div className={`${compClassName} ${className}`}>
      {
        icon ? (
          <CoreIcons name={icon} fill={fill} />
        ) : null
      }
      <div className="toast-message">
        {
          Array.isArray(message) ?
            message.map(item => {
              count++;
              return (<span key={count}>{item}</span>);
            }) : <span>{message}</span>
        }
      </div>
    </div>
  );
}

Toast.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  message: PropTypes.any,
  variant: PropTypes.oneOf([
    'error',
    'error_inverted',
    'warn',
    'info',
    'success',
    'success-inverse',
    'primary',
  ]),
};

export default Toast;