import React from 'react'

import { Button, Paper, Typography } from '@telavita-core/react-design-kit'

import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

import * as S from './styles'

import { ReactComponent as Error } from 'static/svg/error-black.svg'
import Toggle from 'components/Toggle'
import { getProfessionalData } from 'services/professionals/getProfessionalData'
import Skeleton from 'components/Skeleton/Skeleton'
import { disableNewPatients } from 'services/professionals/disableNewPatients'
import { format } from 'date-fns'

interface ProfessionalPlan {
  id: number
  enable_new_patients: boolean
  date_updated: string
  user_updated: string
  user_updated_full_name: string
  plan_group_name: string
  plan: {
    id: number
    code: string
    name: string
    active: boolean
  }
}

interface DisableNewPatientsModalProps {
  professionalName: string
  professionalDocumentType: string
  professionalDocumentValue: string
  professionalUsername: string
  professionalRole: string
  onClose: () => void
}

interface PlanData {
  plan_code: string
  accept_new_patients: boolean
}

const PARTICULAR_PLAN_CODE = 'PLN01000'

const TableSkeleton = () => (
  <>
    <S.TableRow>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
    </S.TableRow>
    <S.TableRow>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
    </S.TableRow>
    <S.TableRow>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
      <S.TableCell>
        <Skeleton
          minWidth={20}
          type='text'
          height={15}
        />
      </S.TableCell>
    </S.TableRow>
  </>
)

const DisableNewPatientsModal = ({
  professionalName,
  professionalDocumentType,
  professionalDocumentValue,
  professionalUsername,
  professionalRole,
  onClose
}: DisableNewPatientsModalProps): JSX.Element => {

  const [isLoadingProfessionalData, setIsLoadingProfessionalData] = React.useState(true)
  const [currentPlansLoading, setCurrentPlansLoading] = React.useState<Array<string>>([])
  const [professionalPlans, setProfessionalPlans] = React.useState<ProfessionalPlan[]>([])

  const hasPlans = professionalPlans.filter(pPlan => pPlan.plan.code !== PARTICULAR_PLAN_CODE).length > 0
  const allPlansAreEnabled = professionalPlans.filter(pPlan => pPlan.enable_new_patients).length > 0
  const showToggleButton = professionalPlans.length > 0

  const orderedPlansOptions = professionalPlans.sort((a, b) => {
    const aPlanGroupName = a.plan_group_name.toLowerCase()
    const bplanGroupName = b.plan_group_name.toLowerCase()

    const aPlanisParticular = a.plan.code === PARTICULAR_PLAN_CODE
    const bPlanisParticular = b.plan.code === PARTICULAR_PLAN_CODE

    if (aPlanisParticular && !bPlanisParticular) {
      return 1
    }
    if (!aPlanisParticular && bPlanisParticular) {
      return -1
    }
    if (aPlanGroupName < bplanGroupName) {
      return -1
    }
    if (aPlanGroupName > bplanGroupName) {
      return 1
    }
    return 0
  })

  const updateNewPatientsPlan = (plans: Array<PlanData>) => {

    setCurrentPlansLoading(prevState => [...prevState, ...plans.map(plan => plan.plan_code)])

    void disableNewPatients({
      professionalSlug: professionalUsername,
      plans
    }).then((data) => {

      const newProfessionalPlans = professionalPlans.reduce((acc: ProfessionalPlan[], curr) => {

        const updatedResponsePlan = data.find(plan => plan.plan_code === curr.plan.code)

        if (updatedResponsePlan) {
          const updatedPlan = {
            ...curr,
            date_updated: updatedResponsePlan.date_updated,
            user_updated: updatedResponsePlan.user_updated,
            enable_new_patients: !curr.enable_new_patients,
            user_updated_full_name: updatedResponsePlan.user_updated_full_name,
          }
          acc.push(updatedPlan) 
        } else {
          acc.push({
            ...curr,
          })
        }

        return acc
      },[])

      setProfessionalPlans(newProfessionalPlans)
      setCurrentPlansLoading([])
    }).catch(() => {
      setCurrentPlansLoading([])
    })

  }

  const togglePlan = (planCode: string) => {
    const currentPlan = professionalPlans.find(pPlan => pPlan.plan.code === planCode)
    const plans = [{
      plan_code: planCode,
      accept_new_patients: !currentPlan.enable_new_patients
    }]

    updateNewPatientsPlan(plans)
  }

  const toggleAllPlans = () => {
    const currentEnabledPlans = professionalPlans.filter(pPlan => pPlan.enable_new_patients === allPlansAreEnabled)

    const newDisabledPlans = currentEnabledPlans.map(pPlan => ({
      plan_code: pPlan.plan.code,
      accept_new_patients: !allPlansAreEnabled
    }))
    
    updateNewPatientsPlan(newDisabledPlans)
  }

  React.useEffect(() => {
    const loadProfessionalData = async () => {
      try {
        const data = await getProfessionalData({
          username: professionalUsername,
          profile_role: professionalRole
        })

        const professionalPlansData = data.person_profile[0].products[0].appointments[0].plans

        setProfessionalPlans(professionalPlansData)
        setIsLoadingProfessionalData(false)

      } catch {
        setIsLoadingProfessionalData(false)
      }
    }

    void loadProfessionalData()
  },[])


  return (
    <ModalGeneric
      modalTitle='Agendamento para novos pacientes'
      modalSubtitle={
        <S.SubTitleWrapper>
          <Typography
            variant='content2'
            center
            customClassName='SubTitleWrapper__Text'
          >
            <b>{professionalName}</b> <br />
            {professionalDocumentType} {professionalDocumentValue}
          </Typography>
        </S.SubTitleWrapper>
      }
      customClassName='DisableNewPatientsModal'
      btnText='FECHAR'
      hasButton
      btnOnClick={onClose}
      onCloseModal={onClose}
    >
      <S.Container>
        <Paper variant='warning' customClassName='DisableNewPatientsModal__Paper'>
          <S.IconWrapper>
            <Error />
          </S.IconWrapper>
          <S.PaperContent>
            <Typography variant='content2' weight='bolder'>
                Ao inativar, o profissional deixará de aparecer para novos pacientes.
            </Typography>
            <Typography variant='content3'>
               No entanto, sua agenda continuará disponível para pacientes já atendidos anteriormente, para que eles possam marcar novas consultas.
            </Typography>
          </S.PaperContent>
        </Paper>
        <S.Table>
          <S.TableRow>
            <S.TableHead>
              <Typography variant='content2' weight='bolder'>
                Convênios do profissional <br />
              </Typography>
              <Typography variant='content3' italic>
                * Cadastrados no perfil
              </Typography>
            </S.TableHead>
            <S.TableHead>
              <Typography variant='content2' weight='bolder'>
                Status
              </Typography>
            </S.TableHead>
            <S.TableHead>
              <Typography variant='content2' weight='bolder'>
                Modificado em
              </Typography>
            </S.TableHead>
          </S.TableRow>
          {isLoadingProfessionalData && <TableSkeleton />}
          {!hasPlans && !isLoadingProfessionalData && (
            <S.TableRow>
              <S.TableCell></S.TableCell>
              <S.TableCell>
                <Typography variant='content2' italic>
                  Nenhum convênio cadastrado
                </Typography>
              </S.TableCell>
              <S.TableCell></S.TableCell>
            </S.TableRow>
          )}
          {!isLoadingProfessionalData && orderedPlansOptions.map(pPlan => (
            <S.TableRow>
              <S.TableCell>
                {pPlan.plan_group_name} {pPlan.plan.name}
              </S.TableCell>
              <S.TableCell>
                <Toggle
                  isToggled={pPlan.enable_new_patients}
                  value={pPlan.enable_new_patients}
                  onToggledSwitch={() => togglePlan(pPlan.plan.code)}
                  label={pPlan.enable_new_patients ? 'ativo' : 'inativo'}
                  loading={currentPlansLoading.includes(pPlan.plan.code)}
                />
              </S.TableCell>
              <S.TableCell>
                {pPlan.date_updated ? (
                  <>
                    <Typography variant='content3'>             
                      {format(new Date(pPlan.date_updated), 'dd/MM/yyyy\',\' \'ás\' HH\'h\'mm')} - por {pPlan.user_updated_full_name} < br/>
                    </Typography>
                    <Typography variant='content3' customClassName='DisableNewPatientsModal__AdminEmail'>
                      {pPlan.user_updated}
                    </Typography> 
                  </>
                ) : '–'}
              </S.TableCell>
            </S.TableRow>
          ))}
        </S.Table>
        <S.ActionButtonWrapper>
          {!isLoadingProfessionalData && showToggleButton && (
            <Button 
              variant='text' 
              uppercase={false}
              onClick={toggleAllPlans}
            >
              {allPlansAreEnabled ? 'Inativar todos' : 'Ativar todos'}
            </Button>
          )}
        </S.ActionButtonWrapper>
      </S.Container>
    </ModalGeneric>
  )
}

export {
  DisableNewPatientsModal
}