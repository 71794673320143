import { useInfiniteQuery } from '@tanstack/react-query'

import { AdminSchedule } from '../types'
import { getSchedules } from 'services/schedules'
import { ADMIN_SCHEDULES_QUERY_KEY } from 'settings/_queryKeySettings'
import { useFilter } from '../context/FilterContext'
import { FeedbackTypeMessage } from '../context/filterReducer'

interface UseGetSchedulesParams {
  sort: string
  query: string
  start_date__gte: string
  end_date__lte: string
  status_code: string
  planParams: {
    plan_group_code: string
    plan_code: string
  }
  per_page: number
  shouldFetch: boolean
}

type Pagination = {
  total: number
  pages: number
  page: number
  per_page: number
}

export interface UseGetSchedulesResponse {
  schedules: AdminSchedule[]
  pagination: Pagination
  isFetching: boolean
  fetchSchedules: () => void
  removeResults: () => void
  fetchNextPage: () => void
  hasNextPage: boolean
  isFetched: boolean
}

export function useGetSchedules({
  end_date__lte,
  start_date__gte,
  status_code,
  planParams,
  per_page,
  sort,
  query,
  shouldFetch
}: UseGetSchedulesParams): UseGetSchedulesResponse {
  const { dispatch } = useFilter()

  const { 
    data, 
    isFetching,
    refetch: fetchSchedules, 
    remove, 
    hasNextPage, 
    fetchNextPage, 
    isFetched
  } = useInfiniteQuery({
    queryKey: [ADMIN_SCHEDULES_QUERY_KEY],
    getNextPageParam: lastPage => {
      if (lastPage.pagination.page < lastPage.pagination.pages) {
        return lastPage.pagination.page + 1
      }
    },
    queryFn: ({ pageParam = 1 }: { pageParam?: number }) => getSchedules({
      start_date__gte,
      end_date__lte,
      status_code,
      planParams,
      per_page,
      page: pageParam,
      sort,
      query,
    }),
    enabled: shouldFetch,
    onSuccess: data => {
      if (data.pages[0].data?.schedules.length === 0) {
        dispatch({ type: 'SET_FEEDBACK_MESSAGE', payload: 'NO_RESULTS' as FeedbackTypeMessage})
      }
    }
  })

  return {
    schedules: data?.pages.reduce((acc, page) => [...acc, ...page.data.schedules], [] as AdminSchedule[]) || [],
    pagination: data?.pages.reduce((acc, page) => page.pagination, {} as Pagination) || {
      page: 0,
      per_page: 0,
      total: 0,
      pages: 0
    },
    isFetching,
    fetchSchedules,
    removeResults: remove,
    fetchNextPage,
    hasNextPage,
    isFetched 
  }
}