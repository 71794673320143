/*eslint no-useless-escape: "off"*/
import React, { useState, useEffect, useRef } from 'react'

// Libs
import { useDispatch, useSelector } from 'react-redux'
import { format, isToday, getDay, isTomorrow, isBefore, roundToNearestMinutes, isPast, formatISO, set } from 'date-fns'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import pt from 'date-fns/locale/pt-BR'
import Joyride, { STATUS } from 'react-joyride'

// Components
import {
  Typography,
  useDimensions,
  Button,
  List,
  DateDisplayer,
  Icon,
  DatePickerInput
} from '@telavita-core/react-design-kit'
import { CoreIcons, TextInput, ClearSearch, PageTop, Column } from 'components'
import { ReactComponent as SearchIcon } from 'static/svg/search_black_24dp.svg'
import { ReactComponent as NoSchedule } from 'static/svg/no-schedule.svg'
import { ReactComponent as IconEdit } from 'static/svg/icon_edit.svg'
import { ReactComponent as ClearIcon } from 'static/svg/clear.svg'
import FloatActionButtonDropdown from 'components/FloatActionButtonDropdown/FloatActionButtonDropdown'
import Tooltip from 'components/Tooltip'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import Skeleton from 'components/Skeleton/Skeleton'
import Row from 'components/Row'
import { PatientDetailProfessionalModal } from 'containers/ModalManager/Modals/PatientDetailProfessionalModal'

// Redux
import * as actions from '../../../../redux/actions'
import { setTextFilter } from '../../../../redux/actions/filtersActions'

// Utils
import { modalTypes } from '../../../../containers/ModalManager/ModalManager'
import * as dateHandler from '../../../../utils/dateTimeHandlers'
import InputValidation from '../../../../utils/InputValidation'
import {
  dateToIso,
} from '../../../../utils/dateTimeHandlers'
import ToggleButton from 'components/ToggleButton'
import { filterOptions } from '../../../../settings/filterOptions'
import disableScroll from 'utils/disableScroll'
import { useProfessionalAgenda } from 'hooks'
import { ScheduleEvent } from 'components/ScheduleEvent'
import { EventItem } from 'components/EventItem'
import { formatCpf } from 'utils/textTransformations'

const weekDayName = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado'
]

const ProfessionalAgenda = () => {
  const dispatch = useDispatch()
  const { isMobile } = useDimensions()
  const { schedules, pagination, loading, loaded } = useProfessionalAgenda()
  const firstUpdate = useRef(true)

  // Redux
  const userData = useSelector((state) => state.auth.userData)
  const filters = useSelector((state) => state.filters)
  const reduxSchedules = useSelector(state => state.schedules.schedules)
  const slots = useSelector(state => state.slots.slots)
  const isSlotRemoved = useSelector(state => state.slots.removedSuccess)
  const removeSlotErrorMessage = useSelector(state => state.slots.errorMessage)  

  const startDateInitialState = {
    value: filters.startDate ? format(new Date(filters.startDate), 'dd/MM/yyyy') : '',
    isValid: true,
    message: '',
  }

  const endDateInitialState = {
    value: filters.endDate ? format(new Date(filters.endDate), 'dd/MM/yyyy') : '',
    isValid: true,
    message: '',
  }
  
  // State
  const [searchQuery, setSearchQuery] = useState('')
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openPatientDetailModal, setOpenPatientDetailModal] = useState(false)
  const [openRemoveSingleSlotModal, setOpenRemoveSingleSlotModal] = useState(false)
  const [selectedSingleSlot, setSelectedSingleSlot] = useState()
  const [selectedStatus, setSelectedStatus] = useState(filters.status ? filters.status : 'ALL')
  const [patientData, setPatientData] = useState({
    name: '',
    phone: '',
    email: '',
    cpf: '',
  })
  const [startDate, setStartDate] = useState(startDateInitialState)
  const [endDate, setEndDate] = useState(endDateInitialState)
  const [isDateChecked, setIsDateChecked] = useState(true)
  const [isFloating, setIsFloating] = useState(false)
  const [isOnboarding, setIsOnboarding] = useState(() => {
    const onboarding = !isMobile
      ? localStorage.getItem('telavitaCompletedAgendaOnboarding')
      : false
    return !onboarding
  })
 
  const hasFilterActive = Boolean(
    filters.startDate || filters.endDate || filters.status || filters.text
  )

  const steps = [
    {
      target: '.ProfessionalAgenda__Header__Filters__Input',
      content:
        '<span>Novidade</span>: Busque um paciente por nome ou e-mail para ver o histórico de consultas dele.',
      disableBeacon: true,
      placement: 'bottom-start',
      offset: 0,
      disableCloseOnEsc: true,
      disableOverlayClose: true,
    },
    {
      target: '.ProfessionalAgenda__Header__Filters__Button',
      content:
        'Use os filtros para ver datas específicas ou visualizar um único status de consulta.',
      disableBeacon: true,
      offset: 0,
      placement: 'bottom-end',
      disableCloseOnEsc: true,
      disableOverlayClose: true,
    },
    {
      target: '.ProfessionalAgenda__Onboarding__Header',
      content:
        'Agora todos os dias do seu calendário de consultas aparecem numa única lista, separados por esta faixa. É só deslizar a tela para visualizar os próximos dias.',
      disableBeacon: true,
      offset: 0,
      disableCloseOnEsc: true,
      disableOverlayClose: true,
    },
    {
      target: '.ProfessionalAgenda__List__Item__FloatButton',
      content:
        'Acesse o menu de opções para ver detalhes do paciente, como telefone e e-mail, caso precise entrar em contato com ele.',
      disableBeacon: true,
      offset: 0,
      placement: 'bottom-end',
      disableCloseOnEsc: true,
      disableOverlayClose: true,
    },
    {
      target: '.ProfessionalAgenda__List__Item__PlanData__Reservation',
      content:
        'Agora as reservas dos pacientes também são mostradas em sua agenda. Verifique se o dia da semana e horário agendados correspondem aos da reserva. Se não corresponder, avalie com o paciente se a reserva deve ser mantida ou cancelada.',
      disableBeacon: true,
      placement: 'right-start',
      offset: 0,
      disableCloseOnEsc: true,
      disableOverlayClose: true,
    },
  ]

  const handleScrollTop = () => window.scrollTo(0, 0)

  const handleJoyrideCallback = (data) => {
    if ([STATUS.FINISHED].includes(data.status)) {
      setIsOnboarding(false)

      localStorage.setItem('telavitaCompletedAgendaOnboarding', true)
      disableScroll.off() 
    }
  }


  const onSubmitSearch = () => {
    if (loading || !searchQuery) return
    dispatch(setTextFilter(searchQuery))
    onChangeFilters()
  }

  const updateStartDate = (value) => {
    let validation = new InputValidation(value, 'date').validate()
    setStartDate((oldState) => ({
      ...oldState,
      value: value,
      isValid: value === '' ? true : validation.success,
      message: validation.message,
    }))
  }

  const updateEndState = (value) => {
    let validation = new InputValidation(value, 'date').validate()
    setEndDate((oldState) => ({
      ...oldState,
      value: value,
      isValid: value === '' ? true : validation.success,
      message: validation.message,
    }))
  }

  const handleDateCheck = () => {
    let isValid = true

    if (!startDate.isValid) isValid = false
    if (!endDate.isValid || (!startDate.value && endDate.value)) isValid = false

    if (startDate.isValid || endDate.isValid) {
      if (isBefore(new Date(dateToIso(endDate.value)), new Date(dateToIso(startDate.value)))) isValid = false
    }
    setIsDateChecked(isValid)
  }
  
  const handleSetDateFilter = () => {
    const newStartDate = startDate.value ? formatISO(set(new Date(dateToIso(startDate.value).replace(/-/g, '\/')), { hours: 0, minutes: 0 })) : null
    const newEndDate = endDate.value ? formatISO(set(new Date(dateToIso(endDate.value).replace(/-/g, '\/')), { hours: 23, minutes: 59, seconds: 59 })) : null
    
    dispatch(actions.setDateFilter(newStartDate, newEndDate))
  }

  const handleSubmitFilters = () => {
    if (
      (startDate.value === '' || !startDate.value) &&
      (endDate.value === '' || !endDate.value) &&
      (selectedStatus === '' || !selectedStatus)
    ) {
      handleClearFilter()
    } else {
      handleSetDateFilter()
      dispatch(actions.setStatusFilter(selectedStatus))
    }
    setOpenFiltersModal(false)
  }

  const handleClearFilter = () => {
    dispatch(actions.clearFilter())
    dispatch(actions.cleanSchedules())
    dispatch(setTextFilter(null))
    setSearchQuery(null)
    setStartDate({
      value: '',
      isValid: true,
      message: '',
    })
    setEndDate({
      value: '',
      isValid: true,
      message: '',
    })
    setSelectedStatus('ALL')
  }

  const onChangeFilters = () => {
    dispatch(actions.cleanSchedules())
    handleScrollTop()
    loadEvents()
  }

  const handleRemoveSlot = () => {
    dispatch(actions.removeSlot(userData.username, selectedSingleSlot.id))
  }

  const loadEvents = (page = 1, perPage = 10) => {
    if (!loaded) fetchSlots()

    const username = userData.username
    dispatch(
      actions.getProfessionalEvents({
        username,
        start_date: startDate.value !== '' ? filters.startDate : (!hasFilterActive || (page > 1 && !hasFilterActive)) ? formatISO(set(new Date(), { hours: 0, minutes: 0, seconds: 0 })) : null,
        end_date: endDate.value !== '' ? filters.endDate : null,
        query: searchQuery ? searchQuery : null,
        status_code: filters.status === 'ALL' ? null : filters.status,
        page: page,
        per_page: perPage
      })
    )
  }

  const fetchSlots = () => {
    dispatch(actions.getSlot(userData.username))
  }

  let oldScrollValue = 0
  let newScrollValue = 0

  function onScroll() {
    newScrollValue = window.pageYOffset
    if (oldScrollValue < newScrollValue) {
      setIsFloating(false) 
    } else if (oldScrollValue > newScrollValue) {
      setIsFloating(true)
    }
    oldScrollValue = newScrollValue
  }

  const renderRecurrentSlot = (scheduleDay) => {
    
    if ((!isToday(new Date(scheduleDay)) && isPast(new Date(scheduleDay)))|| filters.text) return

    const weekDay = new Date(scheduleDay).getDay()
    const SUNDAY_REFERENCE_INDEX = 6

    const currentSlot = slots[weekDay === 0 ? SUNDAY_REFERENCE_INDEX : weekDay - 1].filter(slot => slot.repeat_interval)

    return (
      <>
        {currentSlot?.length > 0 && (
          <List.Item
            content={
              <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
                <div className='ProfessionalAgenda__List__Item__SlotData'>
                  <Typography variant='content3' weight='bold'>
                  Disponibilidade recorrente
                  </Typography>
                  <Typography variant='content3' customClassName='ProfessionalAgenda__List__Item__SlotData__RecurrentRange'>
                    {currentSlot.map((range, index) => (
                    <>
                      {format(new Date(range.start_date), 'HH', { locale: pt })}h às {format(roundToNearestMinutes(new Date(range.end_date)), 'HH', { locale: pt })}h&nbsp;&nbsp;<span className='ProfessionalAgenda__List__Item__SlotData__Separator'>{index + 1 < currentSlot.length ? '|' : ''}</span>&nbsp;&nbsp;
                    </>
                    ))}
                  </Typography>
                </div>
                <div>
                  <button
                    onClick={() => dispatch(
                      actions.showModal(modalTypes.NEW_SCHEDULE, {
                        user: userData,
                        weekDay: weekDay === 0 ? SUNDAY_REFERENCE_INDEX : weekDay
                      })
                    )}
                    className='ProfessionalAgenda__List__Item__ActionButton'
                  >
                    <IconEdit />
                  </button>
                </div>
              </div>
            }
          />
        )}
      </>
    )
  }

  const renderEvents = () => {
    const floatingTop = isMobile ? '78px' : '83px'
    const floatingTopFilterActive = isMobile ? '122px' : '127px'

    if (!loaded) return null

    return Object.values(schedules).length > 0 && Object.keys(schedules).map((schedule, index) => {
      const headerTitle = filters.status && filters.status !== 'ALL' ? 
        filterOptions.find(filter => filter.code === filters.status).name : 
        isToday(new Date(schedule)) ? 'HOJE' : isTomorrow(new Date(schedule)) ? 'AMANHÃ' : weekDayName[getDay(new Date(schedule))]
        
      return (
        <List.Container key={index} >
          {(
            <List.Header 
              variant={hasFilterActive ? 'default' : 'warning'} 
              style={{ top: isFloating ? hasFilterActive ? floatingTopFilterActive : floatingTop : 0 }}
            >
              <div className='ProfessionalAgenda__List__Header'>
                <Typography variant='content2' weight='bold'>
                  {headerTitle}
                </Typography>
                <Typography variant='content2'>
                  {format(new Date(schedule), 'dd/MM/yyyy', { locale: pt })}
                </Typography>
              </div>
            </List.Header>
          )}
          {renderRecurrentSlot(schedule)}
          {schedules[schedule].map(event => (
            <EventItem 
              event={event} 
              handleRemoveSingleSlot={() => {
                dispatch(actions.clearSlotStatus())
                setOpenRemoveSingleSlotModal(true)
                setSelectedSingleSlot(event)
              }}
              handleRemoveUnavailability={() => {
                dispatch(actions.showModal(modalTypes.REMOVE_UNAVAILABILITY, { event: {
                  id: event.id,
                  date: format(new Date(event.start_date), 'dd/MM/yyyy'),
                  time: {
                    start: format(new Date(event.start_date), 'HH:mm'),
                    end: format(roundToNearestMinutes(new Date(event.end_date), { nearestTo: 5 }), 'HH:mm')
                  },
                  username: userData.username
                } }))
              }}
              actions={[
                {
                  label: 'Ver detalhes do paciente',
                  icon: 'OptionsHorizontal',
                  onClick: () => {
                    setOpenPatientDetailModal(true)
                    setPatientData({
                      name: event.schedule.patient.full_name,
                      email: event.schedule.patient.email,
                      phone: event.schedule.patient.cellphone_number,
                      cpf: event.schedule.patient.cpf,
                    })
                  }
                }
              ]}
            />
          ))}
        </List.Container>
      )
    })
  }
  
  const renderSkeletonItem = (amount = 0) => {
    return (
      <>
        {new Array(amount).fill(0).map((_) => (
          <div className='ProfessionalAgenda__List__SkeletonWrapper__Item'>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}> 
              <Skeleton 
                height={93}
                width={75}
                borderRadius={10}
              /> 
              <div 
                className='ProfessionalAgenda__List__SkeletonWrapper__Item__Content'
              >
                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                  <Skeleton 
                    height={15}
                    width={108}
                    borderRadius={5}
                  />
                  <Skeleton 
                    height={15}
                    width={isMobile ? 130 : 282}
                    borderRadius={5}
                  />
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                  <Skeleton 
                    height={15}
                    width={42}
                    borderRadius={5}
                  />
                  <Skeleton 
                    height={15}
                    width={98}
                    borderRadius={5}
                  />
                </div>
              </div>
            </div>
            <Skeleton 
              height={24}
              width={24}
              borderRadius={24}
            />
          </div>
        ))}
      </>
    )
  }

  const renderOnboardingEvents = () => {
    const events = [
      {
        'id': 10956,
        'start_date': '2022-02-17T09:00:00-03:00',
        'end_date': '2022-02-17T16:59:57-03:00',
        'event_type': 'AGD21001',
        'title': '',
        'schedule': {
          'id': 9878,
          'status_code': 'STS21005',
          'session_number': 5,
          'virtual_room_hash': '1aa48132-641b-4409-b1ee-e7cef4eb237c',
          'patient': {
            'full_name': 'Juliana Medeiros Maia',
            'email': 'frejat_paciente@yopmail.com',
            'cellphone_number': '5521000101010'
          },
          'plan': {
            'id': 37,
            'name': 'Particular',
            'code': 'PLN01000',
            'full_name': 'Careplus Básico Plus'
          },
          'is_virtual_room_available': false,
          'consultation_evaluation': false,
        }
      },
      {
        'id': 10956,
        'start_date': '2022-02-17T15:00:00-03:00',
        'end_date': '2022-02-17T16:59:57-03:00',
        'event_type': 'AGD21001',
        'title': '',
        'schedule': {
          'id': 4813,
          'status_code': 'STS21002',
          'session_number': 12,
          'virtual_room_hash': '1aa48132-641b-4409-b1ee-e7cef4eb237c',
          'patient': {
            'full_name': 'Felipe Alcantra Machado',
            'email': 'frejat_paciente@yopmail.com',
            'cellphone_number': '5521000101010'
          },
          'plan': {
            'id': 37,
            'name': 'Particular',
            'code': 'PLN01000',
            'full_name': 'Careplus Executivo'
          },
          'is_virtual_room_available': false,
          'consultation_evaluation': false,
          'reservation_date': '2022-02-07T13:00:00-03:00'
        }
      },
      {
        'id': 10956,
        'start_date': '2022-02-17T16:00:00-03:00',
        'end_date': '2022-02-17T16:59:57-03:00',
        'event_type': 'AGD21001',
        'title': '',
        'schedule': {
          'id': 4813,
          'status_code': 'STS21002',
          'session_number': 12,
          'virtual_room_hash': '1aa48132-641b-4409-b1ee-e7cef4eb237c',
          'patient': {
            'full_name': 'Amanda dos Santos Nunes Barudi',
            'email': 'frejat_paciente@yopmail.com',
            'cellphone_number': '5521000101010'
          },
          'plan': {
            'id': 37,
            'name': 'Particular',
            'code': 'PLN01000',
            'full_name': 'Zup'
          },
          'is_virtual_room_available': false,
          'consultation_evaluation': true,
        }
      },
      {
        'id': 10619,
        'start_date': '2022-02-17T18:00:00-03:00',
        'end_date': '2022-02-17T23:59:59-03:00',
        'event_type': 'AGD21002',
        'title': 'Compromisso pessoal'
      },
    ]
    return (
      <List.Container>
        <List.Header variant='warning' className='ProfessionalAgenda__Onboarding__Header'>
          <div className='ProfessionalAgenda__List__Header'>
            <Typography variant='content2' weight='bold'>
                HOJE
            </Typography>
            <Typography variant='content2'>
              {format(new Date(), 'dd/MM/yyyy', { locale: pt })}
            </Typography>
          </div>
        </List.Header>
        <List.Item
          content={
            <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
              <div className='ProfessionalAgenda__List__Item__SlotData'>
                <Typography variant='content3' weight='bold'>
                  Disponibilidade recorrente
                </Typography>
                <Typography variant='content3'>
                    9h às 11h, 14h às 20h
                </Typography>
              </div>
              <div>
                <button
                  onClick={() => {}}
                  className='ProfessionalAgenda__List__Item__ActionButton'
                >
                  <Icon 
                    name='Pen'
                    height={20}
                    width={20}
                  />
                </button>
              </div>
            </div>
          }
        />
        {events.map(event => (
            <>
            {event.schedule && event.event_type === 'AGD21001' ? (
              <>
                <ScheduleEvent event={event}/>
              </>
            ) : (
              <List.Item 
                key={event.id}
                ripple={
                  <div className='ProfessionalAgenda__List__Item__Ripple'>
                    <DateDisplayer 
                      date={new Date(event.start_date)}
                      label='indisponível'
                      variant='default'
                      showDay={false}
                      labelTextColor='#fff'
                      hourTextColor='#fff'
                      customClassName='ProfessionalAgenda__List__Item__Ripple__DateDisplayer'
                    />
                  </div>
                }
                content={
                  <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
                    <div className='ProfessionalAgenda__List__Item__Content'>
                      <div>
                        <Typography variant='content2' weight='bold'>{event.title}</Typography>
                      </div>
                      <div className='ProfessionalAgenda__List__Item__PlanData'>
                        <Typography variant='content3'>Das {format(new Date(event.start_date), 'HH:').replace(':', 'h')} às {format(new Date(event.end_date), 'HH:').replace(':', 'h')}</Typography>
                      </div>
                    </div>
                    <button 
                      className='ProfessionalAgenda__List__Item__ActionButton' 
                    >
                      <Icon 
                        name='Trash'
                        height={20}
                        width={20}
                      />
                    </button>
                  </div>
                }
              />
            )}
            </>       
        ))}
      </List.Container>
    )
  }

  const handleCloseModal = () => {
    setOpenFiltersModal(false)
    setStartDate(startDateInitialState)
    setEndDate(endDateInitialState)
  }

  useEffect(() => {
    handleDateCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {   
    if (firstUpdate.current) {
      if (!isOnboarding || isMobile) loadEvents(pagination.page + 1)
      firstUpdate.current = false
      return
    } 
    onChangeFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.startDate, filters.endDate, filters.status, isOnboarding, filters.text])

  useEffect(() => {
    if (isOnboarding && !isMobile) {
      handleScrollTop()
      disableScroll.on() 
    }

    return () => dispatch(actions.cleanSchedules())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
    {!isMobile && isOnboarding && (
      <Joyride
        callback={handleJoyrideCallback}
        steps={steps}
        continuous
        disableScrolling
        tooltipComponent={Tooltip}
        styles={{
          options: {
            arrowColor: 'transparent',
            overlayColor: 'rgba(0, 0, 0, 0.75)',
          },
        }}
      />
    )}
      <Row>
        <Column colWidth={12} className='ProfessionalAgenda__Header'>
          <PageTop
            id='agenda-view-header'
            customClassName='ProfessionalAgenda__Header__CustomWrapper'
            bottomComponent={
            <>
              {hasFilterActive && (
                <div className='ProfessionalAgenda__Header__ClearFilters'>
                  <ClearSearch
                    fn={handleClearFilter}
                    titleName='Limpar filtros'
                    titleCustomClassName='ProfessionalAgenda__Header__ClearFilters__Text'
                  />
                </div>
              )}
            </>
            }
            toHideOnMobile={
              <Typography className='ProfessionalAgenda__Header__Title'>
              Minha agenda
              </Typography>
            }
            toShowOnMobile={
              <div className='ProfessionalAgenda__Header__Wrapper'>
                <div className='ProfessionalAgenda__Header__Filters'>
                  <div className='ProfessionalAgenda__Header__Filters__Input'>
                    <TextInput
                      addIcon
                      customClassName='ProfessionalAgenda__Header__Filters__Input__Custom'
                      iconProps={{
                        fill: '#1F35B5',
                        height: '30px',
                        name: 'Search',
                        viewBox: '3 2 30 30',
                        width: '30px',
                      }}
                      name='search_client'
                      inputHeight='4.8rem'
                      onInputChange={(e) => setSearchQuery(e.target.value)}
                      onClickIcon={onSubmitSearch}
                      onKeyPress={(e) => e.which === 13 && onSubmitSearch()}
                      placeholder={
                        isMobile ? 'Buscar paciente' : 'Buscar por nome ou e-mail'
                      }
                      value={searchQuery}
                    />
                  </div>
                  <button
                    className='ProfessionalAgenda__Header__Filters__Button'
                    onClick={() => setOpenFiltersModal(true)}
                  >
                    <CoreIcons
                      fill='#1F35B5'
                      height='24px'
                      name='Filters'
                      width='34px'
                      viewBox='0 0 24 24'
                    />
                  </button>
                  <FloatActionButtonDropdown
                    icon='Positive'
                    title='Adicionar'
                    type='primary'
                    position='right'
                    options={[
                      {
                        iconName: 'ArrowClock',
                        id: 'online-schedule',
                        func: () =>
                          dispatch(
                            actions.showModal(modalTypes.NEW_SCHEDULE, {
                              user: userData,
                            })
                          ),
                        title: 'Disponibilidade online',
                      },
                      {
                        iconName: 'UnavailabilitySchedule',
                        id: 'disable-schedule',
                        func: () => {
                          dispatch(
                            actions.showModal(
                              modalTypes.PROFESSIONAL_UNAVAILABILITY,
                              {
                                userData,
                                startDate: dateHandler.toString(
                                  dateHandler.toDatetime(
                                    dateToIso(
                                      startDate.value
                                        ? startDate.value
                                        : new Date().toISOString()
                                    ),
                                    '00:00'
                                  )
                                ),
                                endDate: dateHandler.toString(
                                  dateHandler.toDatetime(
                                    dateToIso(
                                      startDate.value
                                        ? startDate.value
                                        : new Date().toISOString()
                                    ),
                                    '23:59'
                                  )
                                ),
                              }
                            )
                          )
                        },
                        title: 'Indisponibilidade',
                      },
                    ]}
                    renderOption={(option) => {
                      return (
                      <>
                        <div className='FloatActionButtonDropdown__Modal__OptionsList__Option__Text FloatActionButtonDropdown__Modal__OptionsList__Option__Background'>
                          <Typography variant='content2'>{option.title}</Typography>
                        </div>
                        <div className='FloatActionButtonDropdown__Modal__OptionsList__Option__Icon FloatActionButtonDropdown__Modal__OptionsList__Option__Background'>
                          <CoreIcons
                            name={option.iconName}
                            height='24px'
                            width='24px'
                            viewBox='0 0 24 24'
                            fill='#4b4b4b'
                          />
                        </div>
                      </>
                      )
                    }}
                    onSelectOption={(option) => option.func()}
                  />
                </div>
              </div>
            }
          />
        </Column>
      </Row>
      <Row
        className='ProfessionalAgenda__List__Row'
      >
        <Column
          colWidth={12}
          className={clsx({
            'ProfessionalAgenda__List': true,
            'ProfessionalAgenda__List--isFilterActive': hasFilterActive
          })}
        >
          <InfiniteScroll 
            dataLength={reduxSchedules.length}
            style={{
              overflow: 'visible'
            }}
            hasMore={pagination?.pages > pagination.page}
            next={() => loadEvents(pagination.page + 1)}
            loader={
              (isMobile || !isOnboarding) && (
                <div className='ProfessionalAgenda__List__SkeletonWrapper'>
                  <div className='ProfessionalAgenda__List__SkeletonWrapper__Header'>
                    <Skeleton 
                      height={15}
                      width={46}
                      borderRadius={5}
                    />
                    <Skeleton 
                      height={15}
                      width={82}
                      borderRadius={5}
                    />
                  </div>
                  <div className='ProfessionalAgenda__List__SkeletonWrapper__Item'>
                    <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}> 
                      <Skeleton 
                        height={15}
                        width={153}
                        borderRadius={5}
                      /> 
                      <Skeleton 
                        height={15}
                        width={286}
                        borderRadius={5}
                      />
                    </div>
                    <Skeleton 
                      height={24}
                      width={24}
                      borderRadius={24}
                    />
                  </div>
                  {renderSkeletonItem(8)}  
                </div>
              )
            }
          >
            {(isMobile || !isOnboarding) && renderEvents()}
          </InfiniteScroll>
          {!isMobile && isOnboarding && renderOnboardingEvents()}
          {!loading && hasFilterActive && pagination?.total === 0 && (isMobile || !isOnboarding) && (
            <div className='ProfessionalAgenda__List__EmptyWrapper'>
              <SearchIcon />
              <Typography weight='bold' variant='content2' inheritColor>Nenhum resultado</Typography>
              <Typography variant='content2' inheritColor>Refaça sua busca e seus filtros</Typography>
            </div>
          )}
          {!loading && !hasFilterActive && Object.values(schedules).length === 0 && pagination?.total === 0 && (
            <div className='ProfessionalAgenda__List__EmptyWrapper'>
              <NoSchedule />
              <Typography weight='bold' variant='content2' inheritColor>Você ainda não possui nenhuma consulta agendada</Typography>
            </div>    
          )}
        </Column>
      </Row>

      {openPatientDetailModal && (
        <PatientDetailProfessionalModal 
          name={patientData.name}
          email={patientData.email}
          phone={patientData.phone}
          cpf={patientData.cpf}
          onClose={() => setOpenPatientDetailModal(false)}
        />
      )}
      {openRemoveSingleSlotModal && (
        <ModalGeneric
          onCloseModal={() => setOpenRemoveSingleSlotModal(false)}
        >
          <div className='ProfessionalAgenda__RemoveSlotModal'> 
            {isSlotRemoved ? (
              <>
                <CoreIcons 
                  name='Check'
                  width={50}
                  height={50}
                  fill='#49C327'
                />
                <Typography className='ProfessionalAgenda__RemoveSlotModal__SuccessTitle'>
                Disponibilidade avulsa removida <br /> com sucesso!
                </Typography>
              </>
            ) : (
              <>
              <Typography className='ProfessionalAgenda__RemoveSlotModal__Title'>
              Remover disponibilidade avulsa
              </Typography>
            <Typography variant='content2' center>
            Tem certeza que deseja excluir este horário para agendamento?
            </Typography>
            <div className='ProfessionalAgenda__RemoveSlotModal__SlotData'>
              <Typography variant='content2' weight='bold'>
                {format(new Date(selectedSingleSlot.start_date), 'dd/MM/yyyy')}
              </Typography>
              <Typography variant='content2' weight='bold'>
                Das {format(new Date(selectedSingleSlot.start_date), 'HH', { locale: pt })}h às {format(new Date(selectedSingleSlot.end_date), 'HH', { locale: pt })}h
              </Typography>
            </div>
            <div className='ProfessionalAgenda__RemoveSlotModal__ConfirmationMessage'>
              <CoreIcons 
                name='AlertIcon'
                width={22}
                height={22}
                fill='#FF4949'
              />
              <Typography variant='content2' inheritColor inlineStyles={{ width: 260 }}>
                {removeSlotErrorMessage ? removeSlotErrorMessage : 'Ao remover, este horário deixará de aparecer para os pacientes.'}
              </Typography>
            </div>
              </>
            )}
            <Button
              customClassName='ProfessionalAgenda__RemoveSlotModal__Button'
              variant='contained'
              onClick={() => {
                if (isSlotRemoved || removeSlotErrorMessage) {
                  setOpenRemoveSingleSlotModal(false)
                } else {
                  handleRemoveSlot()
                }
              }}
            >
              {(isSlotRemoved || removeSlotErrorMessage) ? 'FECHAR' : 'SIM, QUERO REMOVER'}
            </Button>
          </div>
        </ModalGeneric>
      )}
      {openFiltersModal && (
        <ModalGeneric
          modalTitle='Filtros'
          onCloseModal={handleCloseModal}
        >
          <div className='ProfessionalAgenda__FiltersModal'>
            <div className='ProfessionalAgenda__FiltersModal__Header'>
              <Typography variant='content2'>Visualizar as datas:</Typography>
              <div className='ProfessionalAgenda__FiltersModal__InputWrapper'>
                <DatePickerInput 
                  handleDayChange={(day) => updateStartDate(format(day, 'dd/MM/yyyy'))}
                  selectedDay={startDate.value && set(new Date(dateToIso(startDate.value).replace(/-/g, '\/')), { hours: 0, minutes: 0 })}
                />
                <Typography
                  variant='content2'
                >
                  até
                </Typography>
                <DatePickerInput 
                  handleDayChange={(day) => updateEndState(format(day, 'dd/MM/yyyy'))}
                  selectedDay={endDate.value && set(new Date(dateToIso(endDate.value).replace(/-/g, '\/')), { hours: 0, minutes: 0 })}
                />
              </div>
              {!isDateChecked && (
                <Typography color='danger' variant='content2'>
                  Período inválido
                </Typography>
              )}
            </div>
            <div className='ProfessionalAgenda__FiltersModal__Content'>
              <Typography variant='content2'>Mostrar: </Typography>
              <div className='ProfessionalAgenda__FiltersModal__Content__FiltersWrapper'>
                <ToggleButton
                  isActive={selectedStatus === 'ALL'}
                  itemName={'Todas'}
                  itemTitle={'Todas'}
                  handleClick={() => setSelectedStatus('ALL')}
                  isFilterAll={true}
                  iconName={'AllSchedules'}
                />
                {filterOptions.map((filter) => (
                  <ToggleButton
                    isActive={selectedStatus === filter.code}
                    handleClick={() => setSelectedStatus(filter.code)}
                    itemTitle={filter.name}
                    iconName={filter.icon_name}
                    isFilterAll
                  />
                ))}
              </div>
              <button
                className='ProfessionalAgenda__FiltersModal__ClearFilter'
                onClick={() => {
                  if (hasFilterActive) handleClearFilter()
                }}
              >
                <ClearIcon />
                <Typography variant='content3' color='primary' weight='bold'>
                  LIMPAR FILTROS
                </Typography>
              </button>
              <Button
                disabled={!isDateChecked}
                customClassName='ProfessionalAgenda__FiltersModal__Button'
                variant='contained'
                onClick={handleSubmitFilters}
              >
                APLICAR MODIFICAÇÕES
              </Button>
            </div>
          </div>
        </ModalGeneric>
      )}
    </>
  )
}

export default ProfessionalAgenda
