import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import ParticularPatient from './ParticularPatient'
import PartnerPatient from './PartnerPatient'

import * as profiles from '../../../../settings/_profileSettings'

const NewPatient = props => {
  const [isAdmin, setIsAdmin] = useState(false)

  useEffect(() => {
    const profileActive = props.profileRoleActive
    if (profileActive === profiles.ADMINISTRATOR) setIsAdmin(true)
  }, [props.profileRoleActive])

  return (
    <>
    { isAdmin ? (<ParticularPatient/>) : (<PartnerPatient/>) }
    </>
  )
}

const mapStateToProps = state => ({
  profileRoleActive: state.auth?.userData?.session?.profile_role_active
})

export default connect(mapStateToProps)(NewPatient)
