import React from 'react'
import { Doughnut } from 'react-chartjs-2'
import { customTooltip } from '../customTooltip'

import { 
  Container, 
  TotalLabelWrapper, 
  TotalLabel, 
  TotalLabelSubtitle 
} from './styles'

export const ChartDoughnut = ({ labels, data , colors, empty, percents, total, labelSubtitle }) => {
  const cutoutPercentage = 72

  function renderChart () {
    return (
      <Doughnut 
        height={180}
        data={{
          labels: labels,
          datasets: [{
            data: data,
            backgroundColor: colors,
            borderWidth: 0,
          }]
        }}

        options={{
          tooltips: {
            enabled: false,
            position: 'nearest',
            callbacks: {
              title: function(tooltipItem, data) {
                return data['labels'][tooltipItem[0]['index']]
              },
              label: function(tooltipItem, data) {
                return percents[tooltipItem['index']] + '% (' + data['datasets'][0]['data'][tooltipItem['index']] + ')'
              },
            },
            
            custom: customTooltip
          },
          cutoutPercentage: cutoutPercentage,
          legend: false
        }}
      />
    )
  }

  function renderEmpty () {
    return (
      <Doughnut height={180} data={{
        labels: ['Sem dados'],
        datasets: [{
          data: [1],
          backgroundColor: ['#F5F5F5'],
          borderWidth: 0,
        }]
      }}
      options={{
        tooltips: {
          callbacks: {
            label: function() {
              return 'Sem dados'
            }
          },
        },
        cutoutPercentage: cutoutPercentage,
        legend: false
      }
      }/>
    )
  }

  return (
    <Container>
      <TotalLabelWrapper>
        <TotalLabel>{total}</TotalLabel>
        <TotalLabelSubtitle>{labelSubtitle}</TotalLabelSubtitle>
      </TotalLabelWrapper>
      { empty ? renderEmpty() : renderChart() }
    </Container>
  )
}