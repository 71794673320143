import { Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

interface LoadingProps {
  isLoading: boolean
}

interface EmptyProps {
  isEmpty: boolean
}

interface ArrowsProps extends LoadingProps, EmptyProps {}

interface GraphicWrapperProps extends LoadingProps, EmptyProps {}

export const Container = styled.div`
  position: relative;
  padding: 20px 20px 15px 30px;
`

export const IconButton = styled.button`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  padding: 0;
`

export const Arrows = styled.div<ArrowsProps>`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  opacity: ${({ isLoading, isEmpty }) => (isLoading || isEmpty) && 0};

  > button {
    background-color: initial;
    border: none;
    padding: 0;

    &:disabled {
      opacity: 0.2;
    }
  }
`

export const GraphicWrapper = styled.div<GraphicWrapperProps>`
  display: flex;
  opacity: ${({ isLoading, isEmpty }) => (isLoading || isEmpty) && 0};
`

export const LeftSection = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-right: 1px solid #e2e2e2;

  > div {
    margin-top: 10px;
    padding-right: 7px;
  }
`

export const LeftSectionText = styled(Typography)`
  font-size: 12px;
  line-height: 15px;
`

export const BadgeText = styled(Typography)`
  color: #898989;
  font-size: 12px;
  line-height: 13px;
`

export const Footer = styled.footer`
  margin-top: 15px;

  > button {
    padding: 0;
  }
`