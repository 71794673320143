import styled from 'styled-components'

export const Container = styled.ul`
  li:last-child {
    border-bottom: initial;
    padding-bottom: 0;
  }
`

export const Item = styled.li`
  padding: 20px 0;
  border-bottom: 1px solid #E2E2E2;
`