import {
  CLEAR_FILTER,
  DATE_FILTER,
  STATUS_FILTER,
  TEXT_FILTER,
  GENDER_STATUS,
  SELECTED_DAY,
  START_HOUR,
  END_HOUR,
  PRICE_RANGE,
  FILTER_DATE_HOURS,
} from '../actions/_actionTypes'

export const setStatusFilter = (status) => {
  return {
    type: STATUS_FILTER,
    payload: {
      status,
      isFiltering: true,
    },
  }
}

export const genderStatus =(gender_status)=>{
  return {
    type: GENDER_STATUS,
    payload: {
      gender_status,
      isFiltering: true,
    },
  }
}
export const getDateTimeFiltersData=(filterData)=>{
  return {
    type: FILTER_DATE_HOURS,
    payload: {
      filterData,
      isFiltering: true,
      isIndex: true,
    },
  }
}
export const getPriceRange=(range)=>{
  return {
    type: PRICE_RANGE,
    payload: {
      range,
      isFiltering: true,
    },
  }
}
export const startHour =(start_hour)=>{
  return {
    type: START_HOUR,
    payload: {
      start_hour,
      isFiltering: true,
    },
  }
}
export const endHour =(end_hour)=>{
  return {
    type: END_HOUR,
    payload: {
      end_hour,
      isFiltering: true,
    },
  }
}
export const selectDay =(day)=>{
  return {
    type: SELECTED_DAY,
    payload: {
      day,
      isFiltering: true,
    },
  }
}

export const setDateFilter = (startDate, endDate) => {
  return {
    type: DATE_FILTER,
    payload: {
      startDate,
      endDate,
      isFiltering: true,
    },
  }
}

export const setTextFilter = (text) => {
  return {
    type: TEXT_FILTER,
    payload: {
      text,
      isFiltering: true,
    },
  }
}

export const clearFilter = () => ({ type: CLEAR_FILTER })
