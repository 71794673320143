import React from "react";

const MyPatients = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg id="Icone_Pacientes" data-name="Icone Pacientes" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} >
    <path id="Caminho_2170" data-name="Caminho 2170" d="M0,0H24V24H0Z" fill="none"/>
    <path id="Caminho_2171" data-name="Caminho 2171" d="M16,11a3,3,0,1,0-3-3A2.987,2.987,0,0,0,16,11ZM8,11A3,3,0,1,0,5,8,2.987,2.987,0,0,0,8,11Zm0,2c-2.33,0-7,1.17-7,3.5V19H15V16.5C15,14.17,10.33,13,8,13Zm8,0c-.29,0-.62.02-.97.05A4.22,4.22,0,0,1,17,16.5V19h6V16.5C23,14.17,18.33,13,16,13Z" fill={fill} />
  </svg>
);

export default MyPatients;