import { 
  CLEAR_CHECKOUT,
  CREATE_CHECKOUT,
} from './_actionTypes';

import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';
import { asyncError } from './';

export const createCheckout = (orderId, paymentPayload) => 
  async dispatch => {
    try {
      const response = await axios.post(apiEndpoints.CHECKOUT(orderId), paymentPayload);
      if (response.status === "OK") {
        const successPayload = {
          checkoutStatus: response.status,
          checkoutMessage: response.messages,
        };
        return dispatch(createCheckoutSuccess(successPayload));
      }
    } catch (err) {
      dispatch(asyncError(err));
      const response = err.response.data;
      const successPayload = {
        checkoutStatus: response.status,
        checkoutMessage: response.messages,
      };
      return dispatch(createCheckoutSuccess(successPayload));
    }
  };

export const createCheckoutSuccess = (successPayload) => {
  return {
    type: CREATE_CHECKOUT,
    payload: successPayload,
  };
};

export const clearCheckout = () => dispatch => {
  return dispatch({ type: CLEAR_CHECKOUT  });
};