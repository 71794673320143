import React from "react";

const Done = ({
  width = '50',
  height = '50',
  viewBox = "0 0 50 50",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g id="Grupo_1318" data-name="Grupo 1318" transform="translate(-153 -433)">
      <circle id="Elipse_56" data-name="Elipse 56" cx="25" cy="25" r="25" transform="translate(153 433)" fill="#4ac326"/>
      <path id="Caminho_2355" data-name="Caminho 2355" d="M28.182,21.35,15.87,35.577,8.483,29.284,5.2,33.115l11.217,9.849L32.012,24.633Z" transform="translate(159.317 426.104)" fill="#fff"/>
    </g>
  </svg>
  );

export default Done;
