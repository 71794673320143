import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as actions from 'redux/actions'
import { Persons } from 'models/Persons'
import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'
import { AxiosError } from 'axios'

export enum Status {
  BLOCKED = 'BLOCKED',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR'
}

interface Slot {
  errorMessage: string
  removedSuccess: boolean
}

interface Slots {
  slots: Slot
}

interface UseRemoveSingleAvailability extends Slot {
  handleRemoveSlot: () => void
  status: Status
}

interface SlotRestrictions {
  restrictions: string[]
}

async function getSlotRestriction(professionalUsername: string, slotId: number) {
  return await axios.get<SlotRestrictions>(apiEndpoints.AGENDA_RESTRICTIONS(professionalUsername, slotId))
}

export const useRemoveSingleAvailability = ({ slotId }: { slotId: number }): UseRemoveSingleAvailability => {
  const { errorMessage, removedSuccess } = useSelector((state: Slots) => state.slots)
  const { email: professionalUsername } = useSelector((state: Persons) => state.persons.person)
  const dispatch = useDispatch()

  const [status, setStatus] = useState<Status>(Status.IDLE) 

  useEffect(() => {
    if (removedSuccess) {
      return setStatus(Status.SUCCESS)
    }
  }, [status, removedSuccess])

  useEffect(() => {
    if (errorMessage) {
      return setStatus(Status.ERROR)
    }
  }, [status, errorMessage])

  const handleRemoveSlot = async() => {
    try {
      setStatus(Status.LOADING)
      const response = await getSlotRestriction(professionalUsername, slotId)
      
      if (response.data?.restrictions.length > 0) {
        return setStatus(Status.BLOCKED)
      }

    } catch (error) {
      const axiosError = error as AxiosError
      if (axiosError?.response?.status) {
        dispatch(actions.removeSlot(professionalUsername, slotId))
      }
    } 
  }

  return {
    handleRemoveSlot,
    errorMessage,
    removedSuccess,
    status
  }
}