import React from "react";

const AllMedicalRequests = ({
  fill,
  height,
  viewBox = "0 0 32 40",
  width,
}) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <g id="Grupo_902" data-name="Grupo 902" transform="translate(-4 -1)">
        <path id="Caminho_2522" data-name="Caminho 2522" d="M32,3H30V1H10V3H8A4,4,0,0,0,4,7V37a4,4,0,0,0,4,4H32a4,4,0,0,0,4-4V7A4,4,0,0,0,32,3ZM12,3H28V5H12ZM34,37a2,2,0,0,1-2,2H8a2,2,0,0,1-2-2V7A2,2,0,0,1,8,5h2V7H30V5h2a2,2,0,0,1,2,2ZM10,13H30v2H10Zm0,6H30v2H10Zm0,6H30v2H10Zm0,6H20v2H10Z" fill={fill} />
      </g>
    </svg>
  );

export default AllMedicalRequests;