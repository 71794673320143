import * as scheduleStatus from 'settings/_scheduleStatusSettings'

export const DATE_DISPLAYER_STATUS = {
  [scheduleStatus.SCHEDULED]: {
    variant: 'success',
    icon: false,
    labelTextColor: '#FFFFFF',
    hourTextColor: '#FFFFFF',
    label: 'agendada',
  },
  [scheduleStatus.CANCELED]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'cancelada',
  },
  [scheduleStatus.PATIENT_NO_SHOW]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'paciente faltou',
  },
  [scheduleStatus.PROFESSIONAL_NO_SHOW]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'profissional faltou',
  },
  [scheduleStatus.ATTENDED]: {
    variant: 'default',
    icon: 'Check',
    iconColor: 'success',
    labelTextColor: '#4AC326',
    hourTextColor: '#BBB8B8',
    label: 'realizada',
  },
  [scheduleStatus.RETURNED]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'devolvida',
  },
  [scheduleStatus.UNATTENDED]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'não realizada',
  },
  [scheduleStatus.ATTENDING]: {
    variant: 'warning',
    icon: false,
    labelTextColor: '#FFFFFF',
    hourTextColor: '#FFFFFF',
    label: 'atendendo',
  },
  [scheduleStatus.CANCEL_REQUESTED]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'Solicitação de Cancelamento',
  },
  [scheduleStatus.NOT_SCHEDULED]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'não agendado',
  },
  [scheduleStatus.SCHEDULE_NO_PARTICIPANTS]: {
    variant: 'default',
    icon: 'ErrorIcon',
    iconColor: '#FF4949',
    labelTextColor: '#FF4949',
    hourTextColor: '#BBB8B8',
    label: 'ambos faltaram',
  },
}