import { 
  CLEAR_CHECKOUT,
  CREATE_CHECKOUT,
} from './../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

export const initialState = { checkoutStatus: "", checkoutMessage: []  };

const createCheckout = (state, { payload }) => {
  return updateState(state, { ...payload });
};
const clearCheckout = (state) => {
  return updateState(state, { ...initialState });
};

const checkoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CHECKOUT: return createCheckout(state, action);
    case CLEAR_CHECKOUT: return clearCheckout(state);
    default: return state;
  }
};

export default checkoutReducer;