import React, { useState, useRef } from 'react'

import { useDispatch } from 'react-redux'
import { hideModal } from '../../../../redux/actions'
import { updateEligiblesBeneficiaresFile } from 'services/beneficiariesService'

import { Button, Spinner, Typography } from '@telavita-core/react-design-kit'

import { TextInput } from 'components'
import ModalGeneric from '../ModalGeneric'
import InputValidation from 'utils/InputValidation'

const ModalFileBeneficiaries = ({
  planGroupCode, 
  onSuccessUpdate,
}) => {

  const dispatch = useDispatch()
  
  const inputTitle = 'Anexar lista de beneficiários'

  const hiddenInput = useRef(null)
  
  const [loading, setLoading] = useState(false)
  const [beneficiaryListRemoved, setBeneficiaryListRemoved] = useState([])
  const [duplicateCpfs, setDuplicateCpfs] = useState([])
  const [success, setSuccess] = useState(null)
  const [file, setFile] = useState({
    placeholder: inputTitle,
    value: null,
    isValid: true,
    message: null,
  })
  
  const hasBeneficiaryRemoved = beneficiaryListRemoved?.length > 0
  const hasDuplicateCpfs = duplicateCpfs?.length > 0

  const handleFileInput = async (e) => {
    let getFile = e.target.files
    let imageTitle = getFile[0] ? getFile[0].name : inputTitle

    let validation = new InputValidation(getFile, 'csv', { maxFileSize: 2000000 })
    let fileIsValid = await validation.validate()

    setFile({
      placeholder: imageTitle,
      value: fileIsValid.encodedValue,
      isValid: fileIsValid.success,
      message: fileIsValid.message,
    })
  }

  const submitFile = async (exclusion = false) => {
    try {
      setLoading(true)

      await updateEligiblesBeneficiaresFile(planGroupCode, file.value, exclusion)

      setBeneficiaryListRemoved([])
      setDuplicateCpfs([])
      setSuccess(true)
      setLoading(false)

    } catch(error) {

      const { status } = error.response
            
      if(status === 423){

        const { eligibles } = error.response.data.data 
        setBeneficiaryListRemoved(eligibles)
        
      } else if(status === 406) {
        
        const { field, position } = error.response.data.messages[0].info
                
        setFile((prevFile) => ({
          ...prevFile,
          isValid: false,
          message: `Revise o ${field} da linha ${position} de sua planilha ${field === 'CPF' ? '(ele deve conter 11 dígitos e ser válido)' : ''}.`
        }))

        setSuccess(false)

        hiddenInput.current.value = null

      } else if(status === 400) {
                
        setFile((prevFile) => ({
          ...prevFile,
          isValid: false,
          message: 'A lista anexada não está no padrão, verifique e tente novamente.'
        }))

        setSuccess(false)

        hiddenInput.current.value = null
      } else if (status === 409) {
        const repeatedCpfs = error.response.data.messages[0].info.repeated_cpfs
        
        setSuccess(false)
        setDuplicateCpfs(repeatedCpfs)
        setFile((prevFile) => ({
          ...prevFile,
          isValid: false,
        }))
      }

      setLoading(false)      
    }

  }

  return (
    <ModalGeneric
      hasButton={!hasBeneficiaryRemoved}
      btnDisabled={!file.isValid || file.value === null}
      btnLoading={loading}
      btnText="ENVIAR LISTA DE BENEFICIÁRIOS"
      modalTitle="Inserir lista de beneficiários"
      btnOnClick={submitFile}
      onSuccessClose={() => { 
        onSuccessUpdate()
      }}
      feedbackShow={success}
      feedbackType="success"
      feedbackMessage="Lista de beneficiários enviada!"
      feedbackContent={
        <div className="ModalFileBeneficiaries__FeedbackContent">
          Uma vez que os dados dos seus beneficiários constem na lista, eles já podem se cadastrar no nosso site e agendar uma consulta.
        </div>
      }
    >
      <div className="ModalFileBeneficiaries__WarningTag">
        <b>IMPORTANTE:</b> todos os dados do beneficiário devem estar devidamente preenchidos
          na planilha (formato .CSV). <b>Ao inserir uma nova planilha de atualização, tenha
          certeza de que os beneficiários anteriores continuem listados.</b>
      </div>

      <div className="ModalFileBeneficiaries--image-wrapper">
        <TextInput
          addIcon
          iconProps={{ name: 'Clip', fill: '#dedede', height: '24px', width: '24px' }}
          hasError={!file.isValid || hasBeneficiaryRemoved || hasDuplicateCpfs}
          messageError={file.message}
          placeholder={file.placeholder}
        />
        <input
          ref={hiddenInput}
          id="hiddenInput-file"
          type='file'
          onChange={(e) => handleFileInput(e)}
        />
      </div>
      {hasDuplicateCpfs && (
        <div className='ModalFileBeneficiaries__duplicate'>
          <Typography variant='content2' color='danger'>*Há duplicação nos CPFs abaixo. Revise-os:</Typography>
          <ul className='ModalFileBeneficiaries__duplicate__list'>
              {duplicateCpfs.map((duplicateCpf) => (
                <li className='ModalFileBeneficiaries__duplicate__list__item'>
                  <Typography variant='content2' color='danger'>
                    {duplicateCpf.cpf}: linhas {duplicateCpf.positions.map((cpfPosition, cpfPositionIndex) => `${cpfPosition}${cpfPositionIndex + 1 < duplicateCpf.positions.length ? ', ' : ''}`)}
                  </Typography>
                </li>
              ))}
          </ul>
        </div>
      )}
      {hasBeneficiaryRemoved &&
        <div className="ModalFileBeneficiaries__removed">
          <h6 className="ModalFileBeneficiaries__removed__title"><strong>Atenção: {beneficiaryListRemoved.length} beneficiários</strong> foram removidos nesta nova lista:</h6>
          
          <ul className="ModalFileBeneficiaries__removed__list">
            {beneficiaryListRemoved.map(beneficiary => {
              const { cpf, full_name, email } = beneficiary
              return (
                <li className="ModalFileBeneficiaries__removed__list--beneficiary" key={cpf}>
                  <span>{!!full_name ? full_name : 'Nome não especificado'}</span>
                  <p>{`CPF ${cpf}, ${!!email ? email : 'e-mail não especificado'}`}</p>
                </li>
              )
            })
            }
          </ul>
          <div className="ModalFileBeneficiaries__removed__warning">
            <span>Tem certeza que deseja remover os beneficiários listados acima?</span>
            <p>Ao confirmar o envio, estes nomes se tornarão inelegíveis e terão suas próximas consultas (caso haja) automaticamente canceladas.</p>
          </div>

          <div className="ModalFileBeneficiaries__removed__buttons">
            <Button
              variant='contained'
              color='danger'
              customClassName='ModalFileBeneficiaries__removed__buttons--confirm'
              onClick={() => submitFile(true)}
            >{loading ? <Spinner customClassName='SpinnerContainer' /> : 'Sim, confirmar envio'}</Button>
            <Button
              variant='outlined'
              color='danger'
              customClassName='ModalFileBeneficiaries__removed__buttons--cancel'
              onClick={() => dispatch(hideModal())}
            >Cancelar</Button>
          </div>
        </div>
      }
    </ModalGeneric>
  )
}

export default ModalFileBeneficiaries
