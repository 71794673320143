const INITIAL_STATE = {
  baseUrl: '',
  title: ''
}

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
      case 'SET_INFO':
          return {
              ...state,
              [action.payload.prop]: action.payload.value,
          };

      default:
          return state;
  }
}

export { reducer };