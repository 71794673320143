//User Permissions
export const USER_VIEW_MYACCOUNT              = "10000000";
export const USER_VIEW_MYAPPOINTMENTS         = "10000001";
export const USER_VIEW_AGENDA                 = "10000006";
export const USER_VIEW_PATIENTS               = "10000008"; // matheus -> verify with backend
export const USER_VIEW_HOMEPAGE               = "10000013";
export const USER_VIEW_PROFESSIONAL_PROFILE   = "10000014";
export const USER_CANCEL_SCHEDULE             = "10400009";
export const USER_CHANGE_SCHEDULE             = "10200009";
export const USER_VIEW_QUESTIONNAIRE          = "10000015";
export const USER_VIEW_PLANS                  = "10000016";
export const USER_VIEW_MEDICAL_REQUEST        = "10000018";
//Admin Permissions
export const ADMIN_GENERAL_VIEW               = "00000020";
export const ADMIN_VIEW_APPOINTMENTS          = "00000001";
export const ADMIN_VIEW_PATIENTS              = "00000002";
export const ADMIN_VIEW_PROFESSIONALS         = "00000003";
export const ADMIN_VIEW_PAYMENT               = "00000004";
export const ADMIN_VIEW_REPORTS               = "00000005";
export const ADMIN_VIEW_AGENDA                = "00000006";
export const ADMIN_VIEW_PERMISSIONS           = "00000007";
export const ADMIN_EDIT_PERSON                = "00200002";
export const ADMIN_ADD_PERSON                 = "00100002";
export const ADMIN_ADD_SCHEDULE               = "00100009";
export const ADMIN_CANCEL_SCHEDULE            = "00400009";
export const ADMIN_CHANGE_SCHEDULE            = "00200009";
export const ADMIN_ADD_MEDICAL_REQUEST        = "00100019";
export const ADMIN_VIEW_MEDICAL_REQUEST       = "00000018";
export const ADMIN_VIEW_SCHEDULE_LOGS         = "00000021";
export const ADMIN_APPROVE_VALIDITY           = "00600019";
export const ADMIN_REPPROVE_REQUEST           = "00700019";
export const ADMIN_RESCHEDULE                 = "00950001";
export const ADMIN_ENABLE_NEW_PATIENTS        = "00500022";
