import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";

import { formatFloatToReal } from "../../../utils/orderHandlers";
import { getTime, toSimpleDayMonthYear } from "../../../utils/dateTimeHandlers";
import * as actions from "../../../redux/actions";
import viewportHoc from "../../../hoc/viewportHoc";

import PaymentDetails from "../../../components/PaymentDetails";
import Row from "../../../components/Row";
import StickyIndex from "../../../components/StickyIndex/StickyIndex";
import { Typography, ConsultationListPro } from 'components';

class PaymentDetailView extends Component {
  componentDidMount = () => {
    this.props.onGetOrders(this.props.match.params.id);
  };

  handleOrderList = () => {
    const item = this.props.order.items;

    const sessionDetail = item.map(item => {
      const id = item.id;
      const porfessionalName =
        item.seller_person_profile.person_general.full_name;
      const total = item.price;
      const price = formatFloatToReal(total);
      const statusCode = item.status_code;
      const statusName = item.status_name;
      const session = item.schedule.session;
      const scheduleData = item.schedule;
      const scheduleId = item.schedule.id;
      const date = toSimpleDayMonthYear(item.schedule.start_date);
      const time = getTime(item.schedule.start_date)
        .split(":")
        .join("h");

      return {
        id,
        porfessionalName,
        statusCode,
        statusName,
        price,
        session,
        scheduleData,
        scheduleId,
        date,
        time,
      };
    });
    return sessionDetail;
  };

  handleOrder = () => {
    const detailList = this.props.order;
    const id = detailList.id;
    let detail = detailList.orderList.filter(el => el.id === id);
    const oredrDetail = detail.map(item => {
      const id = item.id;
      const paymentStatus = item.status_code;
      const name = item.buyer_person_profile.person_general.full_name;
      const total = item.total;
      const itemsCount = item.items_count;
      const paymentMethod = item.payment_method.name;
      const date = toSimpleDayMonthYear(item.date_created);
      const time = getTime(item.date_created)
        .split(":")
        .join("h");

      const paymentStatusName = item.status_name;
      const price = formatFloatToReal(total);

      return {
        id,
        name,
        paymentStatus,
        itemsCount,
        price,
        paymentMethod,
        paymentStatusName,
        time,
        date,
      };
    });
    return oredrDetail;
  };

  renderOrderList = () => {
    const list = this.handleOrderList();
    return list.map(item => {
      const professionalProfileCode = item.scheduleData.guests.find( guest => guest.person_profile.profile_code !== 'TVTVPACI').person_profile.profile_code
      return (
        <div key={item.id} className=" MyConsultations">
          <ConsultationListPro
            cardColor="#FFFFB5"
            paymentStatus={item.statusCode}
            paymentStatusName={item.statusName}
            iconName="MoreOptions"
            id={item.id}
            name={item.porfessionalName}
            sessoinId={item.session}
            price={item.price}
            time={item.time}
            date={item.date}
            menuList={this.menuList}
            specialty={professionalProfileCode}
          />
        </div>
      );
    });
  };
  renderDetailList = () => {
    const data = this.handleOrder();
    return data.map(item => {
      return (
        <div key={item.id}>
          <PaymentDetails
            paymentStatus={item.paymentStatus}
            id={item.id}
            paymentStatusName={item.paymentStatusName}
            menuList={this.menuList}
            patientName={item.name}
            sessionId={item.itemsCount}
            price={item.price}
            paymentMethod={item.paymentMethod}
          />
          <Row margin="1.5rem 0">
            <StickyIndex
              barColor="#ffffff"
              textPosition="flex-start"
              barContent={`Compra Realizada em ${item.date}, às  ${item.time}`}
            />
          </Row>
        </div>
      );
    });
  };
  render() {
    return (
      <div className="PaymentDetail">
        <Row
          align="center"
          justify={this.props.isDesktop ? "space-between" : "center"}
          margin={this.props.isDesktop ? "3.9rem 0 2.1rem" : "2.5rem 0 1.5rem"}>
          <Typography align="mcenter-dleft" Tag="h1" type="title">
            <Trans>Detalhes do Pedido</Trans>
          </Typography>

          <Row direction="column">{this.renderDetailList()}</Row>

          <Row direction="column">{this.renderOrderList()}</Row>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    order: state.orders,
    isAuth: state.auth.isAuth,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOrders: orderId => dispatch(actions.getOrders(orderId)),
    onShowModal: (modalType, modalData) =>
      dispatch(actions.showModal(modalType, modalData)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(PaymentDetailView));
