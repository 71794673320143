import React from "react";

const ResponseEmoji = ({
  viewBox = "0 0 69.831 69.832",
  width="69.831", 
  height="69.832",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <defs>
    </defs>
    <g transform="translate(-633.429 -98.507)">
      <circle cx="34.916" cy="34.916" r="34.916" transform="translate(633.429 98.507)"
        fill="#4ac326"
      />
      <path fill='#14761c' d="M661.721 125.649a4.665 4.665 0 1 1-4.665-4.665 4.665 4.665 0 0 1 4.665 4.665zM684.3 125.649a4.665 4.665 0 1 1-4.665-4.665 4.665 4.665 0 0 1 4.665 4.665zM682.549 142.356s-3.409 8.169-14.52 8.169-13.888-8.169-13.888-8.169a41.089 41.089 0 0 0 28.408 0z"/>
    </g>
  </svg>
);

export default ResponseEmoji;
