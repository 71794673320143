import React, { Component } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, Card, CoreIcons } from 'components';

class Accordion extends Component {

  state = { isOpen: true  }

  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.any,
  }

  handleToogle = (e) => {
    e.preventDefault();

    this.setState({ isOpen: !this.state.isOpen  });
  }

  render() {
    const {
      children,
      title,
    } = this.props;

    return (
      <div className="Accordion">
        <Card styleModfier={{ padding: '17px 15px 17px' }}>
          <div className="Accordion__header"
            onClick={this.handleToogle}>
            <div className="Accordion__header__text">
              <Typography type="subtitle">
                {title}
              </Typography>
            </div>
            <div className="Accordion__header__icon">
              {
                this.state.isOpen ?
                  <CoreIcons name="ExpandLess"
                    fill="#9F9F9F"
                    height="22px"
                    width="22px"
                    viewBox="9 6 20 20"
                  /> :
                  <CoreIcons name="ExpandMore"
                    fill="#9F9F9F"
                    height="22px"
                    width="22px"
                    viewBox="9 6 20 20"
                  />
              }
            </div>
          </div>
          {
            this.state.isOpen &&
              <div className="Accordion__body">
                {children}
              </div>
          }
        </Card>
      </div>
    );
  }
}

export { Accordion };

