export const getRescheduleData = (schedule) => {
  const scheduleData = {
    duration: schedule.productDuration,
    id: schedule.id,
    plan_code: schedule.planCode,
    session: schedule.session,
    status: schedule.status,
  }

  const clientData = schedule.client.person_profile

  const client = {
    name: clientData.person.person_general.full_name,
    username: clientData.person.username,
    profileCode: clientData.profile_code,
  }

  const professionalData = schedule.professional.person_profile

  const professional = professionalData ? {
    name: professionalData.person.person_general.full_name,
    document: schedule.professionalDocument,
    username: professionalData.person.username,
    profileCode: professionalData.profile_code,
  } : {}

  const productData = { 
    id: schedule.productId,
    code: schedule.productCode,
  }

  return {
    client,
    professional,
    scheduleData,
    productData,
  }
}