import React from "react";

const Buildings = ({
    fill = "#4b4b4b",
    height = "70",
    viewBox = "0 0 120 70",
    width = "120",
}) => (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <g id="icon_no-results-convenio" transform="translate(1.253 -54.02)">
                <path id="Caminho_4148" data-name="Caminho 4148" d="M71.1,54.02H34.96a8.053,8.053,0,0,0-8.045,8.045V87.784h-11.7a8.054,8.054,0,0,0-8.045,8.045v24.693H.209a1.463,1.463,0,0,0,0,2.926h9.883V95.829a5.124,5.124,0,0,1,5.119-5.119h11.7v31.823a1.463,1.463,0,1,0,2.926,0V62.065a5.125,5.125,0,0,1,5.119-5.12H71.264C71.258,55.963,71.2,54.988,71.1,54.02Z" fill={fill} />
                <path id="Caminho_4149" data-name="Caminho 4149" d="M117.25,120.522h-8.421V95.851a8.054,8.054,0,0,0-8.045-8.045H89.08V62.065a8.053,8.053,0,0,0-8.045-8.045H69.742q.036,1.461.016,2.924H81.035a5.124,5.124,0,0,1,5.12,5.119v60.469a1.463,1.463,0,1,0,2.925,0v-31.8h11.7a5.124,5.124,0,0,1,5.119,5.119v27.6H117.25a1.463,1.463,0,1,0,0-2.926Z" transform="translate(0.034)" fill={fill} />
                <path id="Caminho_4150" data-name="Caminho 4150" d="M40.809,69.692H35.69a1.467,1.467,0,0,0-1.463,1.462v4.389A1.467,1.467,0,0,0,35.69,77h5.119a1.467,1.467,0,0,0,1.463-1.462V71.154a1.467,1.467,0,0,0-1.463-1.462Z" transform="translate(0.011)" fill={fill} />
                <path id="Caminho_4151" data-name="Caminho 4151" d="M40.809,82.856H35.69a1.467,1.467,0,0,0-1.463,1.463v5.12A1.467,1.467,0,0,0,35.69,90.9h5.119a1.467,1.467,0,0,0,1.463-1.462v-5.12a1.467,1.467,0,0,0-1.463-1.463Z" transform="translate(0.011)" fill={fill} />
                <path id="Caminho_4152" data-name="Caminho 4152" d="M40.809,96.021H35.69a1.466,1.466,0,0,0-1.463,1.463V102.6a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463V97.484a1.466,1.466,0,0,0-1.463-1.463Z" transform="translate(0.011)" fill={fill} />
                <path id="Caminho_4153" data-name="Caminho 4153" d="M40.809,109H35.69a1.466,1.466,0,0,0-1.463,1.463v4.388a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463v-4.388A1.466,1.466,0,0,0,40.809,109Z" transform="translate(0.011)" fill={fill} />
                <path id="Caminho_4154" data-name="Caminho 4154" d="M21.062,95.838H15.943A1.466,1.466,0,0,0,14.48,97.3v5.12a1.467,1.467,0,0,0,1.463,1.462h5.119a1.467,1.467,0,0,0,1.463-1.462V97.3a1.466,1.466,0,0,0-1.463-1.463Z" fill={fill} />
                <path id="Caminho_4155" data-name="Caminho 4155" d="M21.062,108.82H15.943a1.466,1.466,0,0,0-1.463,1.463v4.388a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463v-4.388a1.466,1.466,0,0,0-1.463-1.463Z" fill={fill} />
                <path id="Caminho_4156" data-name="Caminho 4156" d="M100.052,108.82H94.933a1.466,1.466,0,0,0-1.463,1.463v4.388a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463v-4.388a1.466,1.466,0,0,0-1.463-1.463Z" transform="translate(0.034)" fill={fill} />
                <path id="Caminho_4157" data-name="Caminho 4157" d="M53.974,69.692H48.855a1.467,1.467,0,0,0-1.463,1.462v4.389A1.467,1.467,0,0,0,48.855,77h5.119a1.467,1.467,0,0,0,1.463-1.462V71.154a1.467,1.467,0,0,0-1.463-1.462Z" transform="translate(0.015)" fill={fill} />
                <path id="Caminho_4158" data-name="Caminho 4158" d="M53.974,82.856H48.855a1.467,1.467,0,0,0-1.463,1.463v5.12A1.467,1.467,0,0,0,48.855,90.9h5.119a1.467,1.467,0,0,0,1.463-1.462v-5.12a1.467,1.467,0,0,0-1.463-1.463Z" transform="translate(0.015)" fill={fill} />
                <path id="Caminho_4159" data-name="Caminho 4159" d="M53.974,96.021H48.855a1.466,1.466,0,0,0-1.463,1.463V102.6a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463V97.484a1.466,1.466,0,0,0-1.463-1.463Z" transform="translate(0.015)" fill={fill} />
                <path id="Caminho_4160" data-name="Caminho 4160" d="M67.139,69.692h-5.12a1.466,1.466,0,0,0-1.462,1.462v4.389A1.466,1.466,0,0,0,62.019,77h5.12A1.467,1.467,0,0,0,68.6,75.543V71.154a1.467,1.467,0,0,0-1.463-1.462Z" transform="translate(0.019)" fill={fill} />
                <path id="Caminho_4161" data-name="Caminho 4161" d="M67.139,82.856h-5.12a1.467,1.467,0,0,0-1.462,1.463v5.12A1.466,1.466,0,0,0,62.019,90.9h5.12A1.467,1.467,0,0,0,68.6,89.439v-5.12a1.468,1.468,0,0,0-1.463-1.463Z" transform="translate(0.019)" fill={fill} />
                <path id="Caminho_4162" data-name="Caminho 4162" d="M67.139,96.021h-5.12a1.466,1.466,0,0,0-1.462,1.463V102.6a1.467,1.467,0,0,0,1.462,1.463h5.12A1.467,1.467,0,0,0,68.6,102.6V97.484a1.467,1.467,0,0,0-1.463-1.463Z" transform="translate(0.019)" fill={fill} />
                <path id="Caminho_4163" data-name="Caminho 4163" d="M53.974,109H48.855a1.466,1.466,0,0,0-1.463,1.463v5.119a1.467,1.467,0,0,0,1.463,1.463h5.119a1.467,1.467,0,0,0,1.463-1.463v-5.119A1.466,1.466,0,0,0,53.974,109Z" transform="translate(0.015)" fill={fill} />
                <path id="Caminho_4164" data-name="Caminho 4164" d="M67.139,109H48.855a1.466,1.466,0,0,0-1.463,1.463v12.091a1.466,1.466,0,0,0,1.463,1.463H67.139a1.467,1.467,0,0,0,1.463-1.463V110.466A1.467,1.467,0,0,0,67.139,109Z" transform="translate(0.017)" fill={fill} />
                <path id="Caminho_4165" data-name="Caminho 4165" d="M80.3,69.509h-5.12a1.467,1.467,0,0,0-1.463,1.462V75.36a1.467,1.467,0,0,0,1.463,1.462H80.3a1.466,1.466,0,0,0,1.462-1.462V70.971A1.466,1.466,0,0,0,80.3,69.509Z" transform="translate(0.023)" fill={fill} />
                <path id="Caminho_4166" data-name="Caminho 4166" d="M80.3,82.673h-5.12a1.468,1.468,0,0,0-1.463,1.463v5.12a1.468,1.468,0,0,0,1.463,1.463H80.3a1.467,1.467,0,0,0,1.462-1.463v-5.12A1.467,1.467,0,0,0,80.3,82.673Z" transform="translate(0.023)" fill={fill} />
                <path id="Caminho_4167" data-name="Caminho 4167" d="M80.3,95.838h-5.12A1.467,1.467,0,0,0,73.721,97.3v5.12a1.467,1.467,0,0,0,1.463,1.462H80.3a1.466,1.466,0,0,0,1.462-1.462V97.3A1.466,1.466,0,0,0,80.3,95.838Z" transform="translate(0.023)" fill={fill} />
                <path id="Caminho_4168" data-name="Caminho 4168" d="M99.862,95.838H94.743A1.466,1.466,0,0,0,93.28,97.3v5.12a1.467,1.467,0,0,0,1.463,1.462h5.119a1.467,1.467,0,0,0,1.463-1.462V97.3a1.466,1.466,0,0,0-1.463-1.463Z" transform="translate(0.034)" fill={fill} />
                <path id="Caminho_4169" data-name="Caminho 4169" d="M80.3,109h-5.12a1.467,1.467,0,0,0-1.463,1.463v5.119a1.467,1.467,0,0,0,1.463,1.463H80.3a1.467,1.467,0,0,0,1.462-1.463v-5.119A1.466,1.466,0,0,0,80.3,109Z" transform="translate(0.023)" fill={fill} />
            </g>
        </svg>

    );

export default Buildings;