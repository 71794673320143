import React, { useRef } from 'react'
import { createPortal } from 'react-dom'
import PropTypes from 'prop-types'

import { Typography, Button, CoreIcons } from 'components'
import Row from 'components/Row'

const PortalModal = ({
  children,
  subtitle,
  onClose,
  btnDisabled,
  btnId,
  btnText,
  isCentered,
  handleClick,
  hasButton,
  title,
  titleColor,
  classModifier,
  extraClassName,
  scrollable = false
}) => {
  const self = useRef(null)

  const onCloseModal = e => {
    document.body.classList.remove('overflow-hidden')
    onClose(e)
  }

  return createPortal(
    <div className={`PortalModal${isCentered ? '-align-center' : ''} PortalModal${extraClassName ? '-isFixed' : ''}`}>
      <div className="Modal__backdrop" />

      <div
        className={`PortalModal__container${scrollable ? '--scrollable' : ''}`}
        ref={self}
      >
        <div className="PortalModal__container__close" onClick={onCloseModal}>
          <CoreIcons name='Close' />
        </div>

        <div className={`PortalModal__container__content${scrollable ? '--scrollable' : ''}`}>
          <Row align='center' justify='center'>
            <Typography align='center' type="heading4" color={titleColor}>
              {title}
            </Typography>
          </Row>
          <div className="ModalGeneric__Modal__Title__Subtitle">
            {subtitle}
          </div>

          {children}

          {
            hasButton &&
                        <Row margin='2.8rem 0 0 0'>
                          <Button
                            disabled={btnDisabled}
                            id={btnId ? btnId : 'modal-btn'}
                            onButtonClick={() => handleClick()}
                            text={btnText}
                            classModifier={classModifier}
                          />
                        </Row>
          }
        </div>
      </div>
    </div>
    , document.querySelector('#modal'))
}

PortalModal.propTypes = {
  btnDisabled: PropTypes.bool,
  btnId: PropTypes.string,
  btnText: PropTypes.any,
  children: PropTypes.any,
  handleClick: PropTypes.func,
  hasButton: PropTypes.bool,
  modalActive: PropTypes.bool,
  title: PropTypes.any,
  classModifier: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  extraClassName: PropTypes.bool,
  isCentered: PropTypes.bool,
  scrollable: PropTypes.bool
}

export { PortalModal }
