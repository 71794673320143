import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 0 5px;
`

export const Image = styled.div`
  width: 100%;
`

export const InformationList = styled.ul`
  margin-top: 30px;

  li + li {
    padding-top: 30px;
  }

  li:nth-child(3) {
    display: flex;
    align-items: center;
  }
`

export const InformationItem = styled.li`
  display: flex;
  gap: 15px;
`

export const Icon = styled.div`
  max-width: 50px;
  min-width: 50px;
  width: 100%;
`