import styled, { keyframes } from 'styled-components'

interface CircleProps {
  color: string
}

interface GraphicProps {
  animate: 'left' | 'right'
}

const slideLeftAnimation = keyframes`
  0% { right: calc(100% - 100px); }
  100% { right: 0 }
`

const slideRightAnimation = keyframes`
  0% { left: calc(100% + 100px); }
  100% { left: 0 }
`

export const Container = styled.div``

export const Arrows = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;

  > button {
    background-color: initial;
    border: none;
    padding: 0;

    &:disabled {
      opacity: 0.2;
    }
  }
`

export const Graphic = styled.div<GraphicProps>`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  padding-right: 10px;
  position: absolute;

  animation-name: ${({ animate }) => 
    animate === 'left' 
      ? slideLeftAnimation
      : animate === 'right' 
        && slideRightAnimation };
  animation-duration: 0.3s;
`

export const GraphicWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 170px;
`

export const Legend = styled.div`
  display: flex;
  margin-top: 20px;
  gap: 30px;
`

export const LegendItem = styled.div`
  display: flex;
  gap: 10px;
`

export const Circle = styled.div<CircleProps>`
  width: 15px;
  height: 15px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
`