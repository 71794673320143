import React, { useState } from 'react'

import { Button, Icon, Select, Spinner, Typography } from '@telavita-core/react-design-kit'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import { useMakePatientEligible } from './useMakePatientEligible'

import * as S from './styles'

export interface MakePatientEligibleModalProps {
  handleClose: () => void;
  username: string;
  planCode: string;
  onSuccess: () => void;
}

export const MakePatientEligibleModal: React.FC<MakePatientEligibleModalProps> = ({ 
  handleClose,
  username,
  planCode,
  onSuccess
}) => {
  const { mutate, isLoading, isSuccess, isError } = useMakePatientEligible()
  const [selectedOption, setSelectedOption] = useState('')

  const options = [
    { label: '1 dia', id: 'ONE_DAY' },
    { label: '3 dias', id: 'THREE_DAYS' },
    { label: '7 dias', id: 'ONE_WEEK' }
  ]

  function handleMakePatientEligible() {
    return mutate({
      username,
      planCode,
      durationEligibility: selectedOption
    })
  }

  function handleOnSuccessClose() {
    handleClose()
    onSuccess()
  }

  return (
    <ModalGeneric
      modalTitle='Tornar elegível'
      onCloseModal={handleClose}
      feedbackShow={isSuccess}
      feedbackType='success'
      feedbackMessage='Elegibilidade atribuída com sucesso!'
      onSuccessClose={handleOnSuccessClose}
      shouldDispatchHideModalWhenClose={false}
    >
      <S.Container>
        <Typography variant="content2" center>
          Defina abaixo a quantidade de dias em que o paciente deve permanecer elegível.
        </Typography>

        <Select 
          label=''
          onSelect={(data) => setSelectedOption(String(data.id))}
          options={options} 
          selected={options.find(option => option.id === selectedOption)}
          position='fixed'
        />

        {isError && (
          <>
            <Icon name='ErrorIcon' color='danger' />
            <Typography variant="content2" color='danger' center>
              Ocorreu um erro ao tentar tornar o paciente elegível. Tente novamente.
            </Typography>
          </>
        )}

        <Button 
          variant='contained' 
          uppercase={false}
          disabled={!selectedOption}
          onClick={isLoading ? null : () => handleMakePatientEligible()}
        >
          {isLoading 
            ? (
              <S.SpinnerWrapper>
                <Spinner />
              </S.SpinnerWrapper>
            ) 
            : 'Confirmar elegibilidade'
          }
        </Button>
      </S.Container>
    </ModalGeneric>
  )
}