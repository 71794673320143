import React from 'react';

import './PlanGroupItem.sass';

export const PlanGroupItem = ({ planGroup, isLast }) => {
  const { logo_url, name } = planGroup
  const inlineStyles = isLast ? { borderBottom: '1px solid transparent' } : {}

  return (
    <div className="PlanGroupItem__Container" style={inlineStyles}>
      <div className="PlanGroupItem__LogoContainer">
        <img alt={name} src={logo_url} />
      </div>
      <div className="PlanGroupItem__NameContainer">
        {name}
      </div>
      <div style={{ flexGrow: 1 }} />
    </div>
  )
}

/*
code: "GPL01001"
credit_renew: true
id: 1
logo_url: "https://telavita-dev-assets.s3-sa-east-1.amazonaws.com/plans/logo-careplus.jpg"
max_appointment: 3
name: "Care Plus"
plans: (6) [{…}, {…}, {…}, {…}, {…}, {…}]
self_register: true
use_atena: false
validity_expiry_days: 30
validity_renew: false
*/