import React, { useState } from 'react'
import { Typography, TextBox, Button, Spinner, Icon, Paper } from '@telavita-core/react-design-kit'
import ReservationService from 'services/reservation'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

const CancelReservation = ({
  open,
  setOpen,
  onRefresh,
  isProfessional,
  reservationUUID,
  reservationHour,
  reservationWeekday,
  patientUsername,
  patientFullName,
  professionalUsername,
  professionalFullName,
}) => {
  const [reason, setReason] = useState('')
  const [submiting, setSubmiting] = useState(false)
  const [success, setSuccess] = useState(false)

  const onChange = e => {
    setReason(e.target.value)
  }

  const onSubmit = () => {
    setSubmiting(true)

    ReservationService.deleteAllReservation({
      uuid: reservationUUID,
      professional_username: professionalUsername,
      patient_username: patientUsername,
      reason: reason,
    })
      .then(() => {
        setSubmiting(false)
        setSuccess(true)
        setReason('')
      })
      .catch(() => {
        setSubmiting(false)
        setReason('')
      })
  }

  const handleClose = () => {
    setOpen(false)
    if (success) {
      onRefresh(reservationUUID)
    }
  }

  const getTitle = () => {
    if (success || submiting) return null

    return (
      <Typography variant='header4'>
        {isProfessional
          ? <>Cancelar reserva</>
          : <>
            Cancelar toda a reserva:
            <br />
            <span style={{ color: '#FD4949' }}>
              <b>{reservationWeekday}, {reservationHour}</b>
            </span>
          </>
        }
      </Typography>
    )
  }

  const getSubtitle = () => {
    if (success || submiting) return null

    return (
      <Typography
        variant='content2'
        inheritColor
        inlineStyles={{ color: '#898989' }}
        center
      >
        {isProfessional
          ? <>Paciente <b>{patientFullName}</b></>
          : <>com Psicólogo <b>{professionalFullName}</b></>
        }
      </Typography>
    )

  }

  if (!open) return null

  return (
    <ModalGeneric modalTitle={getTitle()} modalSubtitle={getSubtitle()} onCloseModal={handleClose}>
      {
        (!submiting && !success) &&
        <>
          <Typography customClassName='PatientHome__CancelModal__warning' variant='content2' color='danger'>
            {isProfessional
              ? <><b>Atenção:</b> ao prosseguir, você cancelará a reserva deste paciente em todas(os) as(os)<br/><b>{reservationWeekday.toLowerCase()}, {reservationHour}</b>.</>
              : <><b>Atenção:</b> ao prosseguir, você perderá sua recorrência de horário em todas(os) as(os)<br/><b>{reservationWeekday.toLowerCase()}, {reservationHour}</b>.</>
            }
          </Typography>
          <div className='PatientHome__CancelModal__reason'>
            <Typography variant='content2' weight='bold'>
              Motivo do cancelamento*
            </Typography>
            <Typography variant='content3' italic>
              {
                isProfessional ?
                  'O paciente receberá esta mensagem como justificativa. Escreva uma mensagem amigável, como se estivesse falando com o paciente.' :
                  'O psicólogo receberá esta mensagem como justificativa. Escreva uma mensagem amigável, como se estivesse falando com o psicólogo.'
              }
            </Typography>
            <TextBox
              maxLength={1000}
              placeholder='Digite o motivo'
              value={reason}
              onChange={onChange}
              customClassName='PatientHome__CancelModal__textbox'
            />
            {
              isProfessional &&
              <Paper variant='warning' customClassName='PatientHome__CancelModal__professionalWarning'>
                <Typography variant='content2'>
                  <b>Antes de cancelar, ligue para o paciente para confirmar.</b><br/>
                  Ao cancelar a reserva, o horário voltará a ser disponibilizado para outros pacientes agendarem (caso esteja cadastrado em sua disponibilidade).
                </Typography>
              </Paper>
            }
            <Typography
              variant='content2'
              italic
              inheritColor
              customClassName='PatientHome__CancelModal__required'
            >
              *Preenchimento obrigatório
            </Typography>
            <Button
              variant='contained'
              disabled={reason.length < 5}
              color='danger'
              customClassName='PatientHome__CancelModal__button'
              onClick={onSubmit}
            >
              CONFIRMAR CANCELAMENTO
            </Button>
          </div>
        </>
      }
      {
        submiting &&
        <div className='PatientHome__CancelModal__spinner'>
          <Spinner />
        </div>
      }
      {
        (!submiting && success) &&
        <div className='PatientHome__CancelModal__success'>
          <Icon
            name='Check'
            color='success'
            height={50}
            width={50}
            customClassName='PatientHome__CancelModal__successIcon'
          />
          <Typography variant='header4' weight='bold' center>
            Cancelamento de reserva <br/> efetuado com sucesso!
          </Typography>
          {isProfessional && (
            <Typography variant='content2' center customClassName='PatientHome__CancelModal__success__subTitle'>
              O paciente receberá email avisando <br/> que a reserva foi cancelada.
            </Typography>
          )}
          <Paper variant='warning' customClassName='PatientHome__CancelModal__success__warning'>
            <Typography variant='content2'>
              {isProfessional ? (
                <>
                  <b>IMPORTANTE:</b> Se no horário desta reserva cancelada houver uma ou mais consultas agendadas pelo paciente,
                  ele ainda poderá realizá-las. Caso seja preciso cancelar essas consultas, entre em contato com nossa equipe de atendimento
                </>
              ) : (
                <>
                  <br>IMPORTANTE:</br> Se no horário desta reserva cancelada houver uma ou mais consultas agendadas,
                  você ainda poderá realizá-las. Para cancelá-las, vá em <i>"Minhas consultas"</i> e selecione <i>"cancelar"</i> no menu da consulta.".
                </>
              )}
            </Typography>
          </Paper>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClose}
            customClassName='PatientHome__CancelModal__successButton'
          >
            FECHAR
          </Button>
        </div>
      }
    </ModalGeneric>
  )
}

export { CancelReservation }
