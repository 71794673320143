import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import FloatActionButtonMobile from '../../../../components/FloatActionButtonMobile/';

function ModalMenu({ 
  menuList,
  onCloseMenu, 
}) {
  return (
    <div className="Modal Modal-isFixed">
      <div className="Modal__backdrop"></div>
      <div className="ModalMenu__backdrop">
        <div className="ModalMenu">
          {
            menuList.map(menuItem => {
              return (
                <div className="ModalMenu__item"
                  id={menuItem.id}
                  key={menuItem.id}
                  onClick={menuItem.func}>
                  <div className={`ModalMenu__item__text ModalMenu__item__background`}>
                    <Typography>
                      {menuItem.title}
                    </Typography>
                  </div>
                  <div className={`ModalMenu__item__icon ModalMenu__item__background`}>
                    <CoreIcons name={menuItem.iconName}
                      height="24px"
                      width="24px"
                      viewBox="0 0 24 24"
                      fill="#4b4b4b"
                    />
                  </div>
                </div>
              );
            })
          }
          <div className="ModalMenu__FAB">
            <FloatActionButtonMobile buttonId="close-modal-menu"
              iconName="Close"
              showInfo={false}
              type="primary"
              onClickFab={onCloseMenu}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

ModalMenu.propTypes = { menuList: PropTypes.array };

export default ModalMenu;

