export enum FeedbackTypeMessage  {
  NEW_SEARCH = 'NEW_SEARCH',
  NO_RESULTS = 'NO_RESULTS',
}

type ActionMap<M extends Record<string, unknown>> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      }
};

export type FilterActions = ActionMap<FilterPayload>[keyof ActionMap<FilterPayload>];

type FilterPayload = {
  'SET_CALENDAR_DATE': Date[]
  'SET_PLAN_GROUP_CODE': { label: string, id: string | number }
  'SET_PLAN_CODE': { label: string, id: string | number }
  'SET_QUERY': string
  'SET_PREVIOUS_QUERY': string
  'SET_SHOULD_FETCH': boolean
  'SET_FEEDBACK_MESSAGE': FeedbackTypeMessage
  'SET_STATUS_CODE': string
  'CLEAR_RESULTS': null
  'CLEAR_FILTERS': null
}

export interface FilterState {
  calendarDate: Date[]
  planGroupCode: { label: string, id: string | number }
  planCode: { label: string, id: string | number }
  statusCode: string
  query: string
  previousQuery: string
  shouldFetch: boolean
  feedbackMessage: FeedbackTypeMessage
}

export const initialState: FilterState = {
  calendarDate: [],
  planGroupCode: { label: 'Todos os convênios', id: 'all' },
  planCode: { label: 'Todos os planos', id: 'all' },
  statusCode: 'all',
  query: '',
  previousQuery: '',
  shouldFetch: false,
  feedbackMessage: FeedbackTypeMessage.NEW_SEARCH,
}

export const filterReducer = (state: FilterState,  action: FilterActions): FilterState => {
  switch (action.type) {
  case 'SET_CALENDAR_DATE':
    return {
      ...state,
      calendarDate: action.payload,
    }
  case 'SET_PLAN_GROUP_CODE':
    return {
      ...state,
      planGroupCode: action.payload,
    }
  case 'SET_PLAN_CODE':
    return {
      ...state,
      planCode: action.payload,
    }
  case 'SET_QUERY':
    return {
      ...state,
      query: action.payload,
    }
  case 'SET_PREVIOUS_QUERY':
    return {
      ...state,
      previousQuery: action.payload,
    }
  case 'SET_SHOULD_FETCH':
    return {
      ...state,
      shouldFetch: action.payload,
    }
  case 'SET_FEEDBACK_MESSAGE':
    return {
      ...state,
      feedbackMessage: action.payload,
    }
  case 'SET_STATUS_CODE':
    return {
      ...state,
      statusCode: action.payload,
    }
  case 'CLEAR_RESULTS':
    return {
      ...state,
      feedbackMessage: FeedbackTypeMessage.NEW_SEARCH,
      shouldFetch: false,
      query: '',
      previousQuery: '',      
    }
  case 'CLEAR_FILTERS':
    return {
      ...state,
      calendarDate: [],
      planGroupCode: { label: 'Todos os Convênios', id: 'all' },
      planCode: { label: 'Todos os Planos', id: 'all' },
      statusCode: 'all',
      shouldFetch: false,
    }
  default:
    return state
  }
}