import {
  GET_PLANS,
  GET_GROUP_PLANS,
  GET_PLANS_GROUPS, GET_GROUP_PLANS_ELIGIBLES, GET_GROUP_PLANS_ELIGIBLES_FILE, UPDATE_GROUP_PLANS_ELIGIBLES_FILE, CLEAR_GROUP_PLANS_ELIGIBLES_FILE
} from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

export const initialState = {
  plans: [],
  groupPlans: {},
  allPlansGroups: {},
  eligiblesUpdateInfo: {},
  eligiblesFile: null,
  eligiblesFileUpdate: {
    success: false,
    errors: false
  }
};

const plansSucess = (state, { payload }) => {
  return updateState(state, { plans: payload.plans });
};

const groupPlansSucess = (state, { payload }) => {
  return updateState(state, { groupPlans: payload.groupPlans });
};

const allPlansGroups = (state, { payload }) => {
  return updateState(state, { allPlansGroups: payload.allPlansGroups });
}

const getEligibles = (state, { payload }) => {
  return updateState(state, { eligiblesUpdateInfo: payload.eligibles });
};

const getEligiblesFile = (state, { payload }) => {
  return updateState(state, { eligiblesFile: payload.file });
};

const updateEligiblesFile = (state, { payload }) => {
  return updateState(state, { eligiblesFileUpdate: payload });
};

const clearEligiblesFile = state => {
  return updateState(state, {
    eligiblesFileUpdate: {
      success: false,
      errors: false
    }
  });
};

const plansReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PLANS: return plansSucess(state, action);
    case GET_GROUP_PLANS: return groupPlansSucess(state, action);
    case GET_PLANS_GROUPS: return allPlansGroups(state, action);
    case GET_GROUP_PLANS_ELIGIBLES: return getEligibles(state, action);
    case GET_GROUP_PLANS_ELIGIBLES_FILE: return getEligiblesFile(state, action);
    case UPDATE_GROUP_PLANS_ELIGIBLES_FILE: return updateEligiblesFile(state, action);
    case CLEAR_GROUP_PLANS_ELIGIBLES_FILE: return clearEligiblesFile(state);
    default: return state;
  }
};

export default plansReducer;