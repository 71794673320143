import React from 'react';

// STYLE
import './ShareButton.sass'

const ShareButton = ({ children, href, id }) => {
  return (
    <a className="ShareButton"
      id={id + "-link"}
      href={href}
      target="_blank"
      rel="noopener noreferrer">
      {children}
    </a>
  )
}

export {
  ShareButton
}