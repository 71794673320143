import {
  EMPTY_FIELD,
  IMG_INVALID_FORMAT,
  IMG_INVALID_SIZE,
  IMG_PDF_INVALID_FORMAT,
  LONG_PASSWORD,
  NO_CREDIT_CARD_PATTERN,
  NO_DATE_PATTERN,
  NO_CPF_PATTERN,
  NO_CVV_PATTERN,
  NO_MAIL_PATTERN,
  NO_NAME_PATTERN,
  NO_URL_PATTERN,
  NO_TIME_PATTERN,
  SHORT_PASSWORD,
  BIO_LENGTH,
  FILE_CSV_FORMAT,
  CID_LIMIT,
  INVALID_HOUR,
} from './ValidationErrorList'

import CPF from 'gerador-validador-cpf/dist/js/CPF'
import moment from 'moment'
import { base64FileEncoder } from './filesHandlers'

export default class InputValidation {

  /**
   * "InputValidation" class is responsible for Input
   * validation it can grow on input types/validations
   * needed it recieves "value" of the input and "type"
   * of validation that this "value" needs
   */

  constructor(value, type, options = undefined) {
    this.value = value
    this.type = type
    this.options = options
  }

  validate = () => {

    /**
     * The "validate" method calls another class method
     * depending on the defined class "type" propriety.
     * All the possible "types" are described on the
     * "cases" of the "switch" bellow.
     *
     * All the methods should return an object with the keys:
     * -> "success" - defines if the validation is successfull
     * -> "message" - if the validation failed, returns a friendly
     * message describing why the validation failed.
     *
     * All return messages should be on "ValidationErrorList.js"
     *
     * If the "type" is not listed, it'll
     * return an object where "success" is "false", and message
     * is "Invalid Input Type".
     */

    switch (this.type) {
    case 'email':
      return this._checkEmail()

    case 'password':
      return this._checkPassword()

    case 'name':
      return this._checkName()

    case 'cpf':
      return this._checkCpf()

    case 'cvv':
      return this._checkCvv()

    case 'date':
      return this._checkDate()

    case 'month':
      return this._checkMonth()

    case 'time':
      return this._checkTime()

    case 'url':
      return this._checkUrl()

    case 'year':
      return this._checkYear()

    case 'end_year':
      return this._checkYear()

    case 'creditCardNumber':
      return this._creditCardNumber()

    case 'biography':
      return this._checkBio()

    case 'image':
      return this._checkImage()

    case 'imageOrPdf':
      return this._checkImgOrPdf()

    case 'csv':
      return this._checkCsv()

    case 'integer':
      return this._checkInteger()

    case 'free':
      return this._noChecks()

    case 'hours':
      return this._hoursCheck()

    case 'cid':
      return this._checkCID()

    case 'integerHour':
      return this._checkIntegerHour()

    default:
      return { success: false, message: 'Invalid Input Type' }
    }
  };

  _checkPassword = () => {
    let passwordProps = {
      minLength: 6,
      maxLength: 20,
    }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length < passwordProps.minLength) return { success: false, message: SHORT_PASSWORD }

    if (this.value.length > passwordProps.maxLength) return { success: false, message: LONG_PASSWORD }

    return { success: true, message: '' }
  };

  _checkEmail = () => {
    let mailProps = { pattern: new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) }

    let isValid = mailProps.pattern.test(this.value)

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (!isValid) return { success: false, message: NO_MAIL_PATTERN }

    return { success: true, message: '' }
  };

  _checkName = () => {
    let nameProps = { pattern: new RegExp(/^[A-Za-zÀ-ÿ ]+$/) }

    let isValid = nameProps.pattern.test(this.value)

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (!isValid) return { success: false, message: NO_NAME_PATTERN }

    return { success: true, message: '' }
  };

  _checkInteger = () => {
    let numberProps = { pattern: new RegExp('^[0-9]*$') }

    let isValid = numberProps.pattern.test(this.value)

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (!isValid) return { success: false, message: 'Digite apenas números' }

    return { success: true, message: '' }
  };

  _checkCpf = () => {

    let isValid = CPF.validate(this.value)

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (!isValid) return { success: false, message: NO_CPF_PATTERN }

    return { success: true, message: '' }

  }

  _checkDate = () => {
    let valueMoment = moment(this.value, 'DD/MM/YYYY', true)
    let isValid = valueMoment.isValid()

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    /**
     * Checks if there're options to match
     * the code is ready to accept multiple
     * returns.
     * For now, it only returns NO_DATE_PATTERN
     */
    if (this.options) {
      let isAfter = true
      let isBefore = true

      if (this.options.minDate) {
        isAfter = (valueMoment.isAfter(this.options.minDate))
      }
      if (this.options.maxDate) {
        isBefore = (valueMoment.isBefore(this.options.maxDate))
      }

      isValid = isAfter && isBefore
    }

    if (!isValid) return { success: false, message: NO_DATE_PATTERN }

    return { success: true, message: '' }

  }

  _creditCardNumber = () => {
    let cardProps = { length: 16 }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length !== cardProps.length) return { success: false, message: NO_CREDIT_CARD_PATTERN }

    return { success: true, message: '' }
  }

  _checkCvv = () => {
    let cvvProps = { minLength: 3 }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length < cvvProps.minLength) return { success: false, message: NO_CVV_PATTERN }

    return { success: true, message: '' }
  }

  _checkMonth = () => {
    let monthProps = { length: 2 }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length !== monthProps.length) return { success: false, message: NO_DATE_PATTERN }

    return { success: true, message: '' }
  }

  _checkYear = () => {

    let yearProps = { length: 4 }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length !== yearProps.length) return { success: false, message: NO_DATE_PATTERN }

    return { success: true, message: '' }
  }
  _checkEndYear=()=>{
    let yearProps = { length: 4 }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length !== yearProps.length) return { success: false, message: NO_DATE_PATTERN }

    return { success: true, message: '' }
  }

  _checkBio = () => {

    let bioProps = { length: 100 }
    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length <= bioProps.length) return { success: false, message: BIO_LENGTH }
    return { success: true, message: '' }

  }

  _checkTime = () => {

    let timeProps = { length: 5 }

    let timeArray = this.value.split(':')
    let hour = parseInt(timeArray[0])
    let minutes = parseInt(timeArray[1])

    if (this.value.length !== timeProps.length) {
      return { success: false, message: '' }
    } else {
      if (hour > 23 ||
        hour < 0 ||
        minutes > 59 ||
        minutes < 0 ||
        isNaN(hour) ||
        isNaN(minutes)) {
        return { success: false, message: NO_TIME_PATTERN }
      }
    }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    return { success: true, message: '' }
  }

  _checkUrl = () => {
    const urlRegex = new RegExp(/^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(\?\S*)?$/)

    let isValid = urlRegex.test(this.value) || this.value === ''

    if (!isValid) return { success: false, message: NO_URL_PATTERN }

    return { success: true, message: '' }
  }

  _checkImage = async() => {
    let image = this.value
    let encodedImage = ''
    let maxFileSize = 300000
    const filePattern = /image-*/

    if (this.options && this.options.maxFileSize) maxFileSize = this.options.maxFileSize

    try {
      if (image.length === 0) encodedImage = ''
      else if (!image[0].type.match(filePattern)) throw IMG_INVALID_FORMAT
      else if (image[0].size > maxFileSize) throw IMG_INVALID_SIZE(maxFileSize)
      else encodedImage = await base64FileEncoder(image[0])
    } catch (err) {
      return { success: false, message: err, encodedImage }
    }

    return { success: true, message: '', encodedImage }
  }

_checkImgOrPdf = async() => {
  let file = this.value
  let encodedFile = ''
  let maxFileSize = 2000000

  const pdfPattern = 'application/pdf'
  const imgPattern = /image-*/

  if (this.options && this.options.maxFileSize) maxFileSize = this.options.maxFileSize

  try {
    if (file.length === 0) encodedFile = ''
    else if (!file[0].type.match(imgPattern) && file[0].type !== pdfPattern) throw IMG_PDF_INVALID_FORMAT
    else if (file[0].size > maxFileSize) throw IMG_INVALID_SIZE(maxFileSize)
    else encodedFile = await base64FileEncoder(file[0])
  } catch (err) {
    return { success: false, message: err, encodedFile }
  }

  return { success: true, message: '', encodedFile }
}

  _checkCsv = async() => {
    let image = this.value
    let encodedValue = ''
    let maxFileSize = 200000
    let filePattern = '.csv'

    if (this.options && this.options.maxFileSize) maxFileSize = this.options.maxFileSize

    try {
      if (image.length === 0) encodedValue = ''
      else if (!image[0].name.includes(filePattern)) throw FILE_CSV_FORMAT
      else if (image[0].size > maxFileSize) throw IMG_INVALID_SIZE(maxFileSize)
      else encodedValue = await base64FileEncoder(image[0])
    } catch (err) {
      return { success: false, message: err, encodedValue }
    }

    return { success: true, message: '', encodedValue }
  }

  _checkCID = () => {
    let maxLength = 10

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    if (this.value.length > maxLength) return { sucess: false, message: CID_LIMIT }

    return { success: true, message: '' }
  }

  _noChecks = () => {
    return { success: true, message: '' }
  }

  _hoursCheck = () => {

    // let timeProps = { length: 5 };
    let timeArray = this.value
    let startHour = parseInt(timeArray[0])
    let endHour = parseInt(timeArray[1])
    let lastHourMin = parseInt(this.value[1].split(':')[1])
    let startHourMin = parseInt(this.value[0].split(':')[1])

    if ( endHour < startHour   ||
      endHour > 23 ||
    // startHour < 0 ||
        // endHour < 0
        (endHour < startHour && lastHourMin < startHourMin) ||
        timeArray[1] < timeArray[0]||
        isNaN(startHour) ||
        isNaN(endHour)) {
      return { success: false, message: NO_TIME_PATTERN }
    }

    if (this.value === '') return { sucess: false, message: EMPTY_FIELD }

    return { success: true, message: '' }
  }

  _checkIntegerHour = () => {

    let hour = this.value

    if (hour > 24) return { sucess: false, message: INVALID_HOUR }

    return { success: true, message: '' }
  }
}
