import React from 'react'

const Graphics = ({
  fill,
  height,
  viewBox = '0 0 16 16',
  width,
}) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <path data-name="Caminho 4507" d="M19,3H5A2.006,2.006,0,0,0,3,5V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM5,19V5h6V19Zm14,0H13V12h6Zm0-9H13V5h6Z" fill={fill}/>
  </svg>
)

export default Graphics

