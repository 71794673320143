import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  padding: 20px 20px 15px 30px;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
`

export const Footer = styled.footer`
  margin-top: 15px;

  > button {
    padding: 0;
  }
`