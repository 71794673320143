// import * as githubActions from './actions'

const INITIAL_STATE = {
  data: [],
  error: false,
  loading: false,
};

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_PROFESSIONALS':
        return {
            ...state,
            loading: true,
        };

    case 'LOAD_PROFESSIONALS_SUCCESS':
      return {
          ...state,
          data: action.payload.persons,
          loading: false,
      };

    case 'LOAD_PROFESSIONALS_FAILURE':
      return {
          ...state,
          loading: false,
          error: true
      };

    default:
        return state;
  }
}

export { reducer };