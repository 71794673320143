export const NOT_SCHEDULED = 'STS21001'
export const SCHEDULED = 'STS21002'
export const CANCELED = 'STS21003'
export const CANCEL_REQUESTED = 'STS21004'
export const ATTENDED = 'STS21005'
export const UNATTENDED = 'STS21006'
export const ATTENDING = 'STS21007'
export const RETURNED = 'STS21008'
export const PROFESSIONAL_NO_SHOW = 'STS21009'
export const PATIENT_NO_SHOW = 'STS21010'
export const REPROVED = 'STS24016'
export const INVALIDATED = 'STS24018'
export const SCHEDULE_NO_PARTICIPANTS = 'SCHEDULE_NO_PARTICIPANTS'
