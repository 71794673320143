import styled from 'styled-components'

export const Container = styled.section`
  padding: 20px 20px 30px 30px;
`

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TabsContent = styled.div`
  margin-left: 20px;
`