import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { Typography } from '@telavita-core/react-design-kit'

import { hideModal } from 'redux/actions'

import { ActionsOptions, actions } from './settings'

import NotificationStep from 'components/NotificationStep'
import ModalGeneric from '../ModalGeneric'
import { Review } from 'components/Review'
import { Container, WarningMessageContent } from './styles'

export interface ReviewActionModalProps {
  action: ActionsOptions
  author: string
  createdAt: string
  review: string
  reviewId: number
  onAction: (id: number) => Promise<void>
}

export function ReviewActionModal({
  action,
  author,
  createdAt,
  review,
  onAction,
  reviewId,
}: ReviewActionModalProps): JSX.Element {

  const dispatch = useDispatch()

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const actionProps = actions.find(item => item.action === action)

  const {
    btnText,
    headerText,
    modalTitle,
    successMessage,
    warningText,
  } = actionProps

  async function handleSubmit() {
    setLoading(true)
    await onAction(reviewId)
    setSuccess(true)
    setLoading(false)
  }

  return (
    <ModalGeneric
      hasButton={!success}
      btnText={btnText}
      btnLoading={loading}
      btnDisabled={loading}
      modalTitle={!success && modalTitle}
      btnOnClick={handleSubmit}
      onCloseModal={() => dispatch(hideModal())}
    >
      {!success && (
        <Container>
          
          <Typography
            weight='bolder'
            variant='content2'
            center
          >
            {headerText}
          </Typography>

          <Review
            author={author}
            createdAt={createdAt}
            review={review}
            reviewId={reviewId}
          />

          <WarningMessageContent>
            <Typography
              variant='content2'
              inlineStyles={{
                padding: '15px 20px',
              }}
            >
              {warningText}
            </Typography>
          </WarningMessageContent>

        </Container>
      )}

      {success && (
        <NotificationStep
          colWidth={12}
          btnText='FECHAR'
          onSuccessClick={() => dispatch(hideModal())}
          iconColor="#4ac326"
          iconName="Check"
          hasButton
          mainText={
            <Typography
              weight='bold'
              variant='header3'
              inlineStyles={{ margin: '10px 0 15px' }}
            >
              {successMessage}
            </Typography>
          }
        />
      )}
    </ModalGeneric>
  )
}