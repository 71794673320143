import React from "react";

const CalendarOneDay = ({
    fill = "#1f35b5",
    height = "24",
    viewBox = "0 0 24 24",
    width = "24",
}) => (
    <svg id="CalendarOneDay" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
        <path id="Caminho_2220" data-name="Caminho 2220" d="M17,12H12v5h5ZM16,1V3H8V1H6V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5a2.006,2.006,0,0,0-2-2H18V1Zm3,18H5V8H19Z" transform="translate(0 1)" fill={fill} />
        <path id="Caminho_2221" data-name="Caminho 2221" d="M0,0H24V24H0Z" fill="none" />
    </svg>

);


export default CalendarOneDay;
