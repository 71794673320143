import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Button, CoreIcons, TimestampTag, ReservationExceptions } from 'components'
import Dropdown from 'components/Dropdown'
import { Typography, Icon, Spinner } from '@telavita-core/react-design-kit'
import {
  dateToIso,
  setMoment,
  toDayMonthYear,
} from '../../utils/dateTimeHandlers'

import { history } from '../../routers/AppRouter'
import * as routes from '../../settings/_routesSettings'
import { addWeeks, eachDayOfInterval, endOfWeek, format, getDay, isSameDay, startOfWeek, subWeeks } from 'date-fns'
import { ScheduleContainerFrequencyOptions } from 'containers/ScheduleContainer/_settings'
import { FORMAT, WEEKDAYS_SHORT, WEEKDAYS_LONG, MONTHS, WEEKDAYS_NAMES } from '../../settings/dayPicker'
import { adminExceptions, patientExceptions } from '../../settings/_reservationExceptions.tsx'

import { ReactComponent as AlertIcon } from 'static/svg/alert-icon.svg'

import DayPicker from 'react-day-picker'
import moment from 'moment'

export class ScheduleContainer extends Component {
  state = {
    loading: true,
    loadingSubmit: false,
    month: new Date(),
    selectedDays: [],
    selectedAvailabilities: [],

    // Legado
    disabledDays: [],
    overbookingDates: [],
  }

  onToggleDay = async (day, { disabled, selected: isSelected }) => {
    const { maxSchedules } = this.props
    const { selectedDays, selectedAvailabilities } = this.state

    if (disabled) return

    this.setState({ overbookingDates: [] })

    if (isSelected) {
      this.setState({
        selectedDays: selectedDays.filter(selectedDay => !isSameDay(selectedDay, day)),
        selectedAvailabilities: selectedAvailabilities.filter(aval => !isSameDay(aval.startDate, day))
      })
    } else {
      if (maxSchedules === null || selectedDays.length < maxSchedules) {
        this.setState({
          selectedDays: [day, ...selectedDays],
        })
      }
    }
  }

  checkDateIsAvailable = (selectedDate) => {
    const { overbookingDates } = this.state
    const dateUnavailable = overbookingDates.find(item => {
      let itemDate = moment(item.start_date).format('DD-MM-YYYY')
      let currentDate = moment(selectedDate).format('DD-MM-YYYY')
      return itemDate === currentDate
    })
    return dateUnavailable
  }

  getDisabledDays = () => {
    const { selectedDays } = this.state
    const { fromMonth, toMonth, availabilities, frequency } = this.props

    const initialDays = eachDayOfInterval({
      start: fromMonth,
      end: toMonth,
    })

    const availableDays = availabilities.map(availability => availability.day.toDateString())
    // eslint-disable-next-line array-callback-return
    const notAvailableDays = initialDays.filter(day => {
      if (!availableDays.includes(day.toDateString())) return day
    })

    const blockedDays = selectedDays.flatMap(day => {
      let start
      let end

      if (frequency === ScheduleContainerFrequencyOptions.WEEKLY) {
        start = startOfWeek(day)
        end = endOfWeek(day)
      } else if (frequency === ScheduleContainerFrequencyOptions.BIWEEKLY) {
        start = subWeeks(startOfWeek(day), 1)
        end = addWeeks(endOfWeek(day), 1)
      } else if (frequency === ScheduleContainerFrequencyOptions.QUARTERLY){
        start = subWeeks(startOfWeek(day), 12)
        end = addWeeks(endOfWeek(day), 12)
      } else {
        start = day
        end = day
      }

      return eachDayOfInterval({
        start: start,
        end: end
      }).filter(e => {
        return e.toDateString() !== day.toDateString()
      })
    })

    return [
      {
        before: new Date()
      },
      ...notAvailableDays,
      ...blockedDays,
    ]
  }

  onSelectAvailability = (availability) => {
    this.setState((prevState) => {

      const selectedIndexAvailabilityDay = prevState.selectedAvailabilities.findIndex(av => isSameDay(availability.startDate, av.startDate))
      let newAvailabilities = prevState.selectedAvailabilities

      if (selectedIndexAvailabilityDay >= 0) {

        newAvailabilities[selectedIndexAvailabilityDay] = availability

      } else {
        newAvailabilities.push(availability)
      }

      return {
        ...prevState,
        selectedAvailabilities: newAvailabilities
      }
    })
  }

  onSubmit = () => {
    const { onSubmit: onSubmitProp } = this.props
    const { selectedAvailabilities } = this.state
    onSubmitProp(selectedAvailabilities)
  }

  render() {
    const {
      isAdminArea,
      loading,
      loadingSubmit,
      fromMonth,
      toMonth,
      schedules,
      reservationDate,
      error,
      isAuth,
      enableNewPatients,
      onMonthChange,
      reservationExceptions,
      psychiatryTreatmentStatusMessage
    } = this.props

    const {
      selectedDays,
    } = this.state

    return (
      <div className="ScheduleContainer">
        {reservationDate && (
          isAdminArea ?
            <div className='ScheduleContainer__ReservationAdmin'>
              <div className='ScheduleContainer__Reservation__icon'>
                <Icon name='RefreshClockSuccess' />
              </div>
              <Typography weight='bold' variant='content2'>
                Importante:
              </Typography>
              <Typography variant='content2' center>
                o paciente tem reserva com este profissional:
                <b style={{color: '#4ac326'}}>
                  {/* eslint-disable-next-line */}
                  {` ${WEEKDAYS_NAMES[getDay(reservationDate)]}, ${format(reservationDate, "HH'h'mm")}`}
                </b>
              </Typography>
              <Typography variant='content3' italic center customClassName='ScheduleContainer__ReservationAdmin__footer'>
                Agende sempre neste dia e horário para evitar o cancelamento da reserva
              </Typography>
              <div className='ScheduleContainer__Reservation__Exceptions'>
                <ReservationExceptions datesExceptions={reservationExceptions} tooltipContent={adminExceptions}/>
              </div>
            </div>
            :
            <div className='ScheduleContainer__Reservation'>
              <div className='ScheduleContainer__Reservation__container'>
                <Icon name='RefreshClockSuccess' />
                <Typography variant='content2' center>
                  <>
                    <b>Lembre-se:</b>
                    <br />
                    <span>você tem reserva com este profissional: </span>
                    <b style={{color: '#4ac326'}}>
                      {/* eslint-disable-next-line */}
                      {`${WEEKDAYS_NAMES[getDay(reservationDate)]}, ${format(reservationDate, "HH'h'mm")}`}
                    </b>
                  </>
                </Typography>
                <Typography variant='content3' italic center customClassName='ScheduleContainer__Reservation__footer'>
                  Agende sempre neste dia e horário para evitar o cancelamento da reserva
                </Typography>
                <div className='ScheduleContainer__Reservation__Exceptions'>
                  <ReservationExceptions datesExceptions={reservationExceptions} tooltipContent={patientExceptions}/>
                </div>
              </div>
            </div>
        )}


        <div className="ScheduleContainer__calendar">
          {loading &&
            <div className="SpinnerContainer" style={{ height: '330px' }}>
              <Spinner />
            </div>
          }
          <DayPicker
            format={FORMAT}
            months={MONTHS}
            weekdaysLong={WEEKDAYS_LONG}
            weekdaysShort={WEEKDAYS_SHORT}
            fromMonth={fromMonth}
            toMonth={toMonth}
            selectedDays={selectedDays}
            disabledDays={this.getDisabledDays()}
            onDayClick={this.onToggleDay}
            canChangeMonth={!loading && !loadingSubmit && !error}
            modifiers={{schedule: schedules}}
            onMonthChange={onMonthChange}
          />
        </div>

        {!!selectedDays.length &&
          <div className="ScheduleContainer__hours">
            {
              selectedDays.map(day => {
                const key = dateToIso(toDayMonthYear(setMoment(day)))

                const currentAvailabilityDay = this.props.availabilities.find(availability => availability.day.toDateString() === day.toDateString())

                const options = currentAvailabilityDay.options.reduce((acc, curr) => {
                  if(curr.startDate > new Date()) {
                    acc.push({
                      code: curr,
                      name: format(curr.startDate, 'HH:mm')
                    })
                  }

                  return acc
                }, [])

                let errorObj = this.checkDateIsAvailable(day) // TODO: LEGADO => Investigar com mais detalhes o funcionamento

                return (
                  <div key={key} className="ScheduleContainer__hours__selected">
                    <p>{format(day, 'dd/MM')} às</p>

                    <Dropdown
                      name={key}
                      onSelect={this.onSelectAvailability}
                      options={options}
                      placeholder={'Horários'}
                      dropDownOverflow={true}
                      hasError={errorObj ? true : false}
                      messageError={errorObj ? errorObj.message : ''}
                      disabled={loadingSubmit}
                    />

                    <div
                      className="ScheduleContainer__hours__selected__clear"
                      onClick={() => loadingSubmit ? null : this.onToggleDay(day, {selected: true})}>
                      <CoreIcons name="Close" fill="#acacac" />
                    </div>
                  </div>
                )
              })
            }

            <TimestampTag />
          </div>
        }

        {psychiatryTreatmentStatusMessage && (
          <div className='ScheduleContainer__PsychiatryMessage'>
            <AlertIcon />
            <Typography center variant='content2' color='danger'>
              {psychiatryTreatmentStatusMessage}
            </Typography>
          </div>
        )}

        <Button
          disabled={loading || this.state.selectedAvailabilities.length !== selectedDays.length || this.state.selectedAvailabilities.length <= 0 || error || loadingSubmit}
          onButtonClick={this.onSubmit}
          text="CONFIRMAR AGENDAMENTO"
          loading={loadingSubmit}
        />

        {error === 400 && isAdminArea &&
          <div className="ScheduleContainer__Error">
            <div className="ScheduleContainer__Error__Icon">
              <CoreIcons fill="#FF4949" name="Info" width="22px" height="22px" viewBox="00 00 22 22" />
            </div>
            <Typography
              variant='content2'
              color='danger'
            >
              O CPF do paciente não consta mais na lista de beneficiários do convênio. Se houver algum engano, peça para ele entrar em contato com o responsável pelo convênio para mais informações.
            </Typography>
          </div>
        }

        {error === 406 &&
          <div className="ScheduleContainer__Error">
            <div className="ScheduleContainer__Error__Icon">
              <CoreIcons fill="#FF4949" name="Info" width="22px" height="22px" viewBox="00 00 22 22" />
            </div>
            <div className="ScheduleContainer__Error__Text">
              {isAdminArea ?
                <Typography
                  variant='content2'
                  color='danger'
                >
                  Infelizmente este profissional não atende o convênio do paciente.
                </Typography>
                :
                <Typography
                  variant='content2'
                  color='danger'
                >
                  Infelizmente este profissional não atende o seu convênio.
                  <Typography
                    link
                    variant='content2'
                    onClick={() => history.push(routes.HOME_PSIC)}
                  > Veja aqui </Typography>
                  os profissionais com quem você pode agendar uma consulta.
                </Typography>
              }
            </div>
          </div>
        }

        {!enableNewPatients && !isAuth &&
          <div className="ScheduleContainer__Error">
            <div className="ScheduleContainer__Error__Icon">
              <CoreIcons fill="#FF4949" name="Info" width="22px" height="22px" viewBox="00 00 22 22" />
            </div>
            <Typography
              variant='content2'
              color='danger'
            >
              A agenda deste profissional está cheia. Por favor, escolha outro. <b>Se você já é paciente dele, faça login para conseguir agendar.</b>
            </Typography>
          </div>
        }

        {error === 403 &&
          <div className="ScheduleContainer__Error">
            <div className="ScheduleContainer__Error__Icon">
              <CoreIcons fill="#FF4949" name="Info" width="22px" height="22px" viewBox="00 00 22 22" />
            </div>
            <Typography
              variant='content2'
              color='danger'
            >
              {isAdminArea ? <>
                <b>O paciente tem reserva de horário com outro profissional.</b>
                &nbsp;Para marcar consulta com outro psicólogo, o paciente deve, antes, cancelar a sua reserva em <i>Minha conta.</i>
              </> : <>
                <b>Você tem reserva de horário com outro profissional,</b> por isso, deve agendar somente com ele. Para marcar consulta com outro psicólogo você deve, antes, cancelar a sua reserva em <i>Minha conta.</i>
              </>}
            </Typography>
          </div>
        }
      </div>
    )
  }
}

ScheduleContainer.propTypes = {
  loading: PropTypes.bool,
  loadingSubmit: PropTypes.bool,
  isAdminArea: PropTypes.bool,
  fromMonth: PropTypes.instanceOf(Date),
  toMonth: PropTypes.instanceOf(Date),
  availabilities: PropTypes.arrayOf(PropTypes.shape({
    day: PropTypes.instanceOf(Date),
    options: PropTypes.arrayOf(PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
    }))
  })),
  schedules: PropTypes.arrayOf(Date),
  frequency: PropTypes.oneOf(Object.values(ScheduleContainerFrequencyOptions)),
  onSubmit: PropTypes.func,

  // Legadinho
  isCorporate: PropTypes.bool,
  isEnterprise: PropTypes.bool,
  corporateFrequency: PropTypes.string,
  patientSchedules: PropTypes.arrayOf(Date),

  // Legado
  professionalProfile: PropTypes.object,
  clearOrder: PropTypes.func,
  availableCreditsPatient: PropTypes.number,
  creditsPanel: PropTypes.shape({
    data: PropTypes.object,
  }),
  professional: PropTypes.object,
  errorCode: PropTypes.number,
  enableNewPatients: PropTypes.bool,
  user: PropTypes.object,
  appointment: PropTypes.object,
  appointmentId: PropTypes.number,
  onOrderCreation: PropTypes.func,
  person: PropTypes.object,
  plan: PropTypes.object,
  product: PropTypes.object,
  selectedProfessional: PropTypes.any,
  professionalProfileCode: PropTypes.string,
  daysUnavailable: PropTypes.array,
}

const mapStateToProps = (state, ownProps) => {
  return ({

    // Legado
    isAuth: state.auth.isAuth,
    ...ownProps,
  })
}

export default connect(mapStateToProps)(ScheduleContainer)
