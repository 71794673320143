export const PLANS_TIERS = {
  CORPORATE: [
    { id: 3, name: 'Anexo III - Empresa I' },
    { id: 5, name: 'Anexo V - Empresa II' },
    { id: 8, name: 'Anexo VIII - Empresa III' },
    { id: 12, name: 'Anexo XII - Empresa IV' }
  ],
  ENTERPRISE: [
    { id: 4, name: 'Anexo IV - Care Plus' },
    { id: 6, name: 'Anexo VI - Omint Premium' },
    { id: 7, name: 'Anexo VII - Bradesco' },
    { id: 9, name: 'Anexo IX - Sompo "Superiores"' },
    { id: 10, name: 'Anexo X - Omint Skill e Corporate' },
    { id: 11, name: 'Anexo XI - Sompo "Inferiores"' },
    { id: 13, name: 'Anexo XIII - Cassi' },
    { id: 14, name: 'Anexo XIV - Cuidar Omint' },
    { id: 15, name: 'Anexo XV - Sami Saúde' },
    { id: 17, name: 'Anexo XVII - Unimed Anhanguera' },
    { id: 18, name: 'Anexo XVIII - PMESP' },
    { id: 19, name: 'Anexo XIX - Alice' },
    { id: 22, name: 'Anexo XXII - Santa Helena' },
    { id: 34, name: 'Anexo XXXIV - Rede Padrão III' },
  ],
  CAPITATION: [
    { id: 16, name: 'Anexo XVI - Empresas de Cuidado Coordenado' }
  ]
}