import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components';
import * as statusCode from '../../settings/_paymentStatusSettings';

function PaymentStatus({ paymentStatus, paymentStatusName }) {

  // let text = '';

  switch (paymentStatus) {
    case statusCode.STS20001:
      paymentStatus = '--pending';
      break;

    case statusCode.STS20002:
      paymentStatus = '--paid';
      break;

    case  statusCode.STS20003:
      paymentStatus = '--cancelled';
      break;

    case statusCode.STS20004:
      paymentStatus='--pending';
      break;  
  
    case statusCode.STS20005:
      paymentStatus = '--cancelled';
      break;

    case statusCode.STS20006:
      paymentStatus='--cancelled';
      break;  

    case statusCode.STS25002:
      paymentStatus='--pending';
      break;  

    case 'notApproved':
      paymentStatus = '--notApproved';
      break;

    default:
      break;
  }

  return (
    <div className={`PaymentStatus${paymentStatus}`}>
      <Typography tag='span'
        align='justify'
        uppercase>{paymentStatusName}</Typography>
    </div>
  );
}

PaymentStatus.propTypes = {
  paymentStatus: PropTypes.any,
  paymentStatusName: PropTypes.string,

};

export default PaymentStatus;
