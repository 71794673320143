import React from 'react'

const ArrowLeft = ({
  fill,
  height,
  viewBox = '0 0 24 24',
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path fill={fill} d="M10,14.291l-1.9,1.9L0,8.1,8.1,0,10,1.9,3.819,8.1Z" transform="translate(6.973 3.424)"/>
  </svg>
)


export default ArrowLeft
