import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import { useDimensions, Typography as Typo } from '@telavita-core/react-design-kit'

import './MyPatients.sass'

import { getMypatientsRequest, applyFilters } from 'redux/modules/mypatients'

import { Typography, TextInput, Spinner, PatientsList, PageTop } from 'components'
import { ReactComponent as EmptyIcon } from 'static/svg/icon-nopatients.svg'

const MyPatients = () => {
  const { isMobile } = useDimensions()
  const dispatch = useDispatch()
  // LOCAL STATE
  const [searchName, handleSearchName] = useState('')
  // STORE
  const auth = useSelector((state) => state.auth) // .userToken
  const mypatientsStore = useSelector(state => state.mypatients)
  const loading = mypatientsStore.loading
  const patientsList = mypatientsStore.patients // -> PATIENTS LIST FROM STORE
  const filteredBy = mypatientsStore.filteredBy

  // RENDERS MESSAGE FOR EMPTY PATIENTS LIST
  const rendersMessageForEmptyList = () => {
    return (
      <div className="MyPatients__group">
        <EmptyIcon className="MyPatients__icon" />
        <Typography className="MyPatients__text" align='center' Tag='p' type='paragraph' color="grey-ten">Você ainda não possui pacientes, pois nenhuma consulta foi marcada.</Typography>
      </div>
    )
  }

  // CAPTURE ENTER BUTTON PRESSING
  function captureEnterPress () {
    const searchField = document.getElementById('mypatients-searchField')
    searchField.addEventListener('keypress', (e) => {
      if (e.keyCode === 13) searchByName()
    })
  }

  // TURN A OBJECT PROPS INTO A ARRAY
  function getObjectKeys (object) {
    return Object.keys(object)
  }

  // REGROUP PATIENTS LIST BY MAIN LETTER - PROP
  function handlePatientsList (prop) {
    try {
      const list = patientsList[prop]
      return list
    } catch(err) {
      return []
    }
  }

  // RENDERS RESPECTIVE LISTS CONTAINER
  function renderListContainer () {
    return getObjectKeys(patientsList).map(group => (
      <PatientsList key={group} index={group} items={handlePatientsList(group).map(patient => patient)} />
    ))
  }

  // RENDERS COMPONENT BY REQUEST STATUS
  function renderContent () {
    return loading
      ? <Spinner color="default" height="20px" />
      :  getObjectKeys(patientsList).length
        ? <div className="MyPatients__list">{renderListContainer()}</div>
        : rendersMessageForEmptyList()
  }

  // REQUEST TO GET PATIENTS
  async function getPatients () {
    await dispatch(getMypatientsRequest(auth.userToken, { group_by: 'first_letter' , sort: 'asc', query: searchName }))
  }

  // UPDATE STORE WITH SEARCHED NAME
  function searchByName () {
    dispatch(applyFilters(searchName))
  }

  useEffect(() => {
    getPatients()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredBy])

  return (
    <section className="MyPatients">
      {/* PAGE TOP */}
      <div className="MyPatients__top">
        <PageTop
          id="my-patients"
          toHideOnMobile={
            <Typo variant={!isMobile ? 'header2' : 'header4'}>
              Meus pacientes
            </Typo>
            // <Typography className="MyPatients__title" align='mcenter-dleft' Tag='h1' type='title'>
            //   <Trans>Meus pacientes</Trans>
            // </Typography>
          }
          toShowOnMobile={
            <div className="MyPatients__searchField">
              <TextInput
                name="mypatients-searchField"
                placeholder="Buscar por nome"
                disable={loading}
                onInputChange={(e) => handleSearchName(e.target.value)}
                onKeyPress={(e) => captureEnterPress(e)}
                onClickIcon={() => searchByName()}
                value={searchName}
                addIcon
                iconProps={{
                  fill: '#1F35B5',
                  height: '30px',
                  name: 'Search',
                  viewBox: '3 2 30 30',
                  width: '30px',
                }}
              />
            </div>
          }/>
      </div>

      {/* VIEW CONTENT LIST */}
      <div className={classNames('MyPatients__content', {
        'MyPatients__content--centered': loading || !getObjectKeys(patientsList).length
      })}>
        { renderContent() }
      </div>
    </section>
  )
}

export {
  MyPatients
}
