import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

import { ReviewStatus } from './review.types'

interface ReviewsCountData {
  totalArchived: number
  totalReceived: number
  totalPublished: number
}

interface Response {
  data: {
    pagination: {
      total: number
    }
  }
}

interface Error {
  response: {
    status: number
  }
}

export const reviewsCount = async (): Promise<ReviewsCountData> => {

  const endpoint = apiEndpoints.ME_REVIEWS as string

  const countTotalArchived: number = await new Promise((resolve, reject) => {
    axios.get(endpoint, { params: {
      status: ReviewStatus.ARCHIVED,
      per_page: 1
    } })
      .then((response: Response) => resolve(response.data.pagination.total))
      .catch((error: Error) => reject(error.response.status))
  })

  const countTotalReceived: number = await new Promise((resolve, reject) => {
    axios.get(endpoint, { params: {
      status: ReviewStatus.RECEIVED,
      per_page: 1
    } })
      .then((response: Response) => resolve(response.data.pagination.total))
      .catch((error: Error) => reject(error.response.status))
  })

  const countTotalPublished: number = await new Promise((resolve, reject) => {
    axios.get(endpoint, { params: {
      status: ReviewStatus.PUBLISHED,
      per_page: 1
    } })
      .then((response: Response) => resolve(response.data.pagination.total))
      .catch((error: Error) => reject(error.response.status))
  })
  
  return new Promise((resolve) => {
    resolve({
      totalArchived: countTotalArchived,
      totalPublished: countTotalPublished,
      totalReceived: countTotalReceived,
    })
  })
}