import React from "react";

const PlansBigger = ({
  fill = "#1f35b5",
  height,
  viewBox = "0 0 42.71 36.43",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <rect id="Retângulo_1134" data-name="Retângulo 1134" width="2.51" height="2.51" 
      transform="translate(5.02 18.84)" fill={fill}
    />
    <rect id="Retângulo_1135" data-name="Retângulo 1135" width="2.51" height="2.51" 
      transform="translate(10.05 18.84)" fill={fill}
    />
    <rect id="Retângulo_1136" data-name="Retângulo 1136" width="2.51" height="2.51" 
      transform="translate(5.02 23.87)" fill={fill}
    />
    <rect id="Retângulo_1137" data-name="Retângulo 1137" width="2.51" height="2.51" 
      transform="translate(10.05 23.87)" fill={fill}
    />
    <rect id="Retângulo_1138" data-name="Retângulo 1138" width="2.51" height="2.51" 
      transform="translate(5.02 28.89)" fill={fill}
    />
    <rect id="Retângulo_1139" data-name="Retângulo 1139" width="2.51" height="2.51" 
      transform="translate(10.05 28.89)" fill={fill}
    />
    <rect id="Retângulo_1140" data-name="Retângulo 1140" width="2.51" height="2.51" 
      transform="translate(21.36 6.28)" fill={fill}
    />
    <rect id="Retângulo_1141" data-name="Retângulo 1141" width="2.51" height="2.51" 
      transform="translate(27.64 6.28)" fill={fill}
    />
    <rect id="Retângulo_1142" data-name="Retângulo 1142" width="2.51" height="2.51" 
      transform="translate(33.92 6.28)" fill={fill}
    />
    <rect id="Retângulo_1143" data-name="Retângulo 1143" width="2.51" height="2.51" 
      transform="translate(21.36 11.31)" fill={fill}
    />
    <rect id="Retângulo_1144" data-name="Retângulo 1144" width="2.51" height="2.51" 
      transform="translate(27.64 11.31)" fill={fill}
    />
    <rect id="Retângulo_1145" data-name="Retângulo 1145" width="2.51" height="2.51" 
      transform="translate(33.92 11.31)" fill={fill}
    />
    <rect id="Retângulo_1146" data-name="Retângulo 1146" width="2.51" height="2.51" 
      transform="translate(21.36 16.33)" fill={fill}
    />
    <rect id="Retângulo_1147" data-name="Retângulo 1147" width="2.51" height="2.51" 
      transform="translate(27.64 16.33)" fill={fill}
    />
    <rect id="Retângulo_1148" data-name="Retângulo 1148" width="2.51" height="2.51" 
      transform="translate(33.92 16.33)" fill={fill}
    />
    <g id="Grupo_2006-2" data-name="Grupo 2006">
      <path id="Caminho_3963" data-name="Caminho 3963" d="M42.71,33.92h0V26.65h0v1.89h0V0H15.07V13.82H0V36.43H42.71Zm-27.64,0H2.51V16.33H15.07V33.92Zm16.34,0H26.39V25.13h5.02Zm8.79,0H33.92V22.61H23.87V33.92H17.59V2.51H40.2Z" fill={fill}/>
      <path id="Caminho_3964" data-name="Caminho 3964" d="M42.71,22.61h0v.01Z" fill={fill}/>
    </g>
  </svg>
);
  
export default PlansBigger;