import qs from 'query-string'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { AdminSchedule } from 'views/Dashboard/Sections/AdminScheduleList/types'
import * as status from 'settings/_scheduleStatusSettings'

interface GetSchedulesParams {
  username?: string
  start_date?: string
  start_date__gte?: string
  start_date__lte?: string
  end_date?: string
  end_date__gte?: string
  end_date__lte?: string
  status_code?: string
  profile_role?: string
  sort?: string
  page?: number
  per_page?: number
  query?: string
  planParams?: {
    plan_group_code: string
    plan_code: string
  }
}

export interface GetSchedulesResponse {
  data: {
    schedules: AdminSchedule[]
  },
  pagination: {
    total: number
    pages: number
    page: number
    per_page: number
  }
  prevOffset: number
}

export const getSchedules = async({
  username, 
  start_date, 
  start_date__gte, 
  start_date__lte, 
  end_date, 
  end_date__gte,
  end_date__lte,
  status_code, 
  profile_role,
  sort,
  page,
  per_page,
  query,
  planParams,
}: GetSchedulesParams): Promise<GetSchedulesResponse> => {

  const params = {
    ...(username && { username }),
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(start_date__gte && { start_date__gte }),
    ...(start_date__lte && { start_date__lte }),
    ...(end_date && { end_date }),
    ...(end_date__gte && { end_date__gte }),
    ...(end_date__lte && { end_date__lte }),
    ...(status_code ? { status_code } : {
      status_code: [
        status.SCHEDULED,
        status.CANCELED,
        status.CANCEL_REQUESTED,
        status.ATTENDED,
        status.UNATTENDED,
        status.ATTENDING,
        status.RETURNED,
        status.PROFESSIONAL_NO_SHOW,
        status.PATIENT_NO_SHOW,
        status.SCHEDULE_NO_PARTICIPANTS
      ],
    }),
    ...(sort && { sort }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(query && { query }),
    ...planParams
  } as GetSchedulesParams


  const response: GetSchedulesResponse = await axios.get(apiEndpoints.SCHEDULES,
    { 
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      }})
        
  return {
    ...response,
    prevOffset: page
  }
}