import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { TVTVPACI, TVTVPSIC, TVTVPSIQ } from 'settings/_profileSettings'

import { FormattedScheduleData, Guest, ScheduleDataResponse } from './types'

interface UseCanceledScheduleDetailService {
  data: FormattedScheduleData
  isFetching: boolean
  isLoading: boolean
  isError: boolean
}

function handleGuestsInfo(guests: Guest[]) {
  const findProfessional = guests?.find(guest => guest.profile_code === TVTVPSIC || guest.profile_code === TVTVPSIQ)
  const findPatient = guests?.find(guest => guest.profile_code === TVTVPACI)

  return findPatient && findProfessional && {
    professional: {
      fullName: findProfessional.full_name
    },
    patient: {
      fullName: findPatient.full_name,
      email: findPatient.username
    }
  }
}

function handleFormatDate(date: string) {
  if (!date) return ''

  const formatDate = format(new Date(date), 'dd/MM/yyyy')
  const formatHour = format(new Date(date), 'HH')
  const formatMinutes = format(new Date(date), 'mm')

  return `${formatDate}, às ${formatHour}h${formatMinutes}`
}

function formatResponseData(data: ScheduleDataResponse): FormattedScheduleData {
  const { guests, details, reason, start_date, cancel_request } = data.schedule

  const guestsInfo = handleGuestsInfo(guests)

  return {
    professional: guestsInfo?.professional,
    patient: guestsInfo?.patient,
    details,
    reason,
    scheduleDate: handleFormatDate(start_date),
    canceledAt: handleFormatDate(cancel_request?.created_at),
    canceledBy: cancel_request?.performed_by?.full_name,
    canceledPerformerProfileCode: cancel_request?.performed_by?.profile_code
  }
}

async function fetchCanceledScheduleDetail(scheduleId: number): Promise<FormattedScheduleData> {
  const { data } = await axios.get<ScheduleDataResponse>(apiEndpoints.SCHEDULE_DETAIL(scheduleId))
  
  return formatResponseData(data)
}

export const useCanceledScheduleDetailService = (scheduleId: number): UseCanceledScheduleDetailService => {
  const { data, isError, isLoading, isFetching } = useQuery({
    queryFn: () => fetchCanceledScheduleDetail(scheduleId),
    queryKey: ['CANCELED_SCHEDULE_DETAILS', scheduleId],
    enabled: !!scheduleId,
    refetchOnWindowFocus: false,
    retry: false
  })

  return {
    data,
    isFetching,
    isLoading,
    isError
  }
}