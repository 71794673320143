import React from "react";

const SexFemale = ({
  fill,
  width="20.576",
  height="41.99",
  viewBox="0 0 20.576 41.99",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g  transform="translate(0 0)">
      <defs>
        <clipPath id="a"><rect fill='none'width="20.576" height="41.99"/>
        </clipPath>
      </defs>
      <path fill='#4b4b4b' d="M69.25,11.7a4.35,4.35,0,1,0-3.141-1.131A4.359,4.359,0,0,0,69.25,11.7Z" transform="translate(-58.766 -2.99)"/><path className="c" d="M71.827,36.3l-4.02-8.71A4.489,4.489,0,0,0,63.743,25H59.678a4.489,4.489,0,0,0-4.065,2.591l-4.02,8.71A1.819,1.819,0,1,0,54.9,37.819l2.9-6.253V35.72l-3.261,6.7a1.858,1.858,0,0,0,.089,1.742,1.742,1.742,0,0,0,1.519.849h.759l-1.251,9.782a2.072,2.072,0,0,0,1.831,2.323h.268a2.072,2.072,0,0,0,2.055-1.831l1.3-10.318h1.206l1.3,10.318a2.086,2.086,0,0,0,2.1,1.876h.268a2.1,2.1,0,0,0,1.831-2.323l-1.251-9.782h.759a1.742,1.742,0,0,0,1.519-.849,1.849,1.849,0,0,0,.089-1.742L65.708,35.72V31.566l2.9,6.253a1.787,1.787,0,0,0,1.653,1.027,1.577,1.577,0,0,0,.759-.179A1.787,1.787,0,0,0,71.827,36.3Z" transform="translate(-51.427 -15.169)"/>
    </g>
  </svg>

);

export default SexFemale;