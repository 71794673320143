import { 
  GET_SLOTS_SUCCESS,
  REMOVE_SLOTS_SUCCESS,
  CLEAR_SLOT_STATUS,
  REMOVE_SLOTS_FAILED
} from '../actions/_actionTypes'

import { updateState } from '../helpers/utilityHelpers'

const initialState = {
  slots: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
  },
}

const getSlotsSuccess = (state, { payload }) => {
  return updateState(state, { 
    success: true, 
    ...payload,
  })
}

const removeSlotsSuccess = (state, { payload }) => {
  return updateState(state, { 
    success: true, 
    removedSuccess: true,
    ...payload,
  })
}

const removeSlotsFailed = (state, { payload }) => {
  return updateState(state, { 
    ...payload,
  })
}

const clearSlotStatus = (state) => {
  return updateState(state, { 
    removedSuccess: false,
    errorMessage: null
  })
}

const slotReducer = (state=initialState, action) => {
  switch (action.type) {
  case GET_SLOTS_SUCCESS: return getSlotsSuccess(state, action)
  case REMOVE_SLOTS_SUCCESS: return removeSlotsSuccess(state, action)
  case CLEAR_SLOT_STATUS: return clearSlotStatus(state, action)
  case REMOVE_SLOTS_FAILED: return removeSlotsFailed(state, action)

  default: return state
  }
}

export default slotReducer