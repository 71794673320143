import { Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

interface ContainerProps {
  isMobile: boolean
}

export const Container = styled.main<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ isMobile }) => isMobile ? '12px' : '20px'};
  width: ${({ isMobile }) => isMobile ? '75%' : '790px'};
  border-radius: 10px 10px 0 0;
  background-color: #FFFFB5;
  box-shadow: 0px 0px 24px #00000033;
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: ${({ isMobile }) => isMobile && 0};
`

export const StartInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const EnterButtonWrapper = styled.div`
  width: 110px;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    width: 100%;
    margin-left: 25px;

    @media (max-width: 768px) {
      font-size: 12px;
      font-weight: normal;
      height: 3.5rem;
      margin-bottom: 0;
      padding: 1rem;
      width: 80px;
      margin-left: 0;
    }
  }
`

export const PatientName = styled(Typography)`
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 420px) {
    width: 120px;
  }
`