import React from 'react'
import { Typography } from 'components'

const Tabs = ({
  onSelectTab,
  selectedItem,
  tabId,
  tabs,
  ...rest
}) => {
  return (
    <div
      className="Tabs"
      id={tabId}
      {...rest}
    >
      {
        tabs.map((item, index) => {
          const identifier = item.id
            ? item.id
            : item.code
              ? item.code
              : index
          
          const isSelected = identifier === selectedItem

          return (
            <div
              className={`Tabs__item${isSelected ? '-selected' : ''}`}
              onClick={() => onSelectTab(identifier)}
              id={`${tabId}-${identifier}`}
              key={identifier}
            >
              <div>
                <Typography align='center' bold={isSelected}>
                  {item.title}
                </Typography>

                {item?.subtitle && (
                  <Typography italic align='center' >
                    {item.subtitle}
                  </Typography>
                )}
              </div>
              
              {
                (item.quantity || item.quantity === 0) && (
                  <Typography className={`Tabs__item${isSelected ? '-selected' : ''}__quantity`}>
                    {`(${item.quantity})`}
                  </Typography>
                )
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default Tabs