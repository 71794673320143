import React from 'react';

const Instagram = ({
  fill,
  height,
  width,
  viewBox,
}) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}>
      <path d="M18 35.5A17.52 17.52 0 0 1 .5 18 17.518 17.518 0 0 1 18 .5 17.52 17.52 0 0 1 35.5 18 17.522 17.522 0 0 1 18 35.5zm0-27.958c-2.762 0-3.131.012-4.247.063a7.615 7.615 0 0 0-2.5.478 5.067 5.067 0 0 0-1.824 1.189 5.036 5.036 0 0 0-1.188 1.824 7.59 7.59 0 0 0-.479 2.5c-.05 1.084-.061 1.447-.061 4.246s.011 3.14.061 4.247a7.591 7.591 0 0 0 .479 2.5 5.052 5.052 0 0 0 1.188 1.825 5.038 5.038 0 0 0 1.824 1.188 7.626 7.626 0 0 0 2.5.479c1.107.05 1.474.062 4.247.062s3.139-.012 4.246-.062a7.625 7.625 0 0 0 2.5-.479 5.039 5.039 0 0 0 1.824-1.187 5.053 5.053 0 0 0 1.188-1.825 7.593 7.593 0 0 0 .479-2.5c.051-1.106.063-1.473.063-4.247s-.012-3.141-.063-4.246a7.585 7.585 0 0 0-.479-2.5 5.032 5.032 0 0 0-1.188-1.824 5.068 5.068 0 0 0-1.824-1.189 7.614 7.614 0 0 0-2.5-.478c-1.116-.053-1.485-.065-4.246-.065zm0 18.744c-2.763 0-3.087-.01-4.162-.06a5.667 5.667 0 0 1-1.912-.355 3.159 3.159 0 0 1-1.184-.77 3.175 3.175 0 0 1-.771-1.184 5.7 5.7 0 0 1-.355-1.912c-.049-1.076-.059-1.4-.059-4.163s.01-3.086.059-4.163a5.724 5.724 0 0 1 .355-1.913 3.192 3.192 0 0 1 .771-1.184 3.19 3.19 0 0 1 1.184-.771 5.691 5.691 0 0 1 1.912-.355c1.108-.05 1.437-.06 4.162-.06s3.054.01 4.161.06a5.692 5.692 0 0 1 1.912.355 3.2 3.2 0 0 1 1.185.771 3.2 3.2 0 0 1 .77 1.184 5.726 5.726 0 0 1 .355 1.913c.05 1.1.06 1.428.06 4.163s-.01 3.064-.06 4.163a5.7 5.7 0 0 1-.355 1.912 3.183 3.183 0 0 1-.77 1.184 3.173 3.173 0 0 1-1.185.77 5.668 5.668 0 0 1-1.912.355c-1.075.049-1.399.059-4.161.059zm0-13.734a5.3 5.3 0 0 0-5.29 5.29A5.3 5.3 0 0 0 18 23.131a5.3 5.3 0 0 0 5.289-5.289A5.3 5.3 0 0 0 18 12.551zm5.5-1.443a1.237 1.237 0 0 0-1.236 1.236A1.237 1.237 0 0 0 23.5 13.58a1.237 1.237 0 0 0 1.236-1.235 1.237 1.237 0 0 0-1.236-1.237zM18 21.275a3.437 3.437 0 0 1-3.434-3.433A3.437 3.437 0 0 1 18 14.409a3.437 3.437 0 0 1 3.433 3.433A3.437 3.437 0 0 1 18 21.274z" fill="#fff" stroke="transparent" />
    </svg>
  );
}

export default Instagram;