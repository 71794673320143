import React from "react";

const GroupPlanBuilding = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg width={width} height={height} viewBox={viewBox}>
    <g id="Grupo_5099" data-name="Grupo 5099">
      <path fill={fill} id="Caminho_4243" data-name="Caminho 4243" d="M17,11V3H7V7H3V21h8V17h2v4h8V11ZM7,19H5V17H7Zm0-4H5V13H7Zm0-4H5V9H7Zm4,4H9V13h2Zm0-4H9V9h2Zm0-4H9V5h2Zm4,8H13V13h2Zm0-4H13V9h2Zm0-4H13V5h2Zm4,12H17V17h2Zm0-4H17V13h2Z" />
    </g>
  </svg>
);

export default GroupPlanBuilding;
