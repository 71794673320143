import {
  AUTH_FAILED,
  AUTH_LOGIN,
  AUTH_USER,
  AUTH_CHANGE_PROFILE,
  AUTH_CHANGE_PLAN,
} from '../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

export const initialState = {
  isAuth: false,
  isLoading: true,
  profilePermissions: null,
  failMessage: "",
  planSelected: null,
  profileSelected: null,
  userData: null,
  userToken: null,
};

const loginUser = (state, { payload }) => {
  return updateState(state, { userToken: payload.userToken });
};

const authUser = (state, { payload }) => {
  return updateState(state, {
    isAuth: true,
    isLoading: false,
    profilePermissions: payload.profilePermissions,
    failMessage: "",
    planSelected: payload.planSelected,
    profileSelected: payload.profileSelected,
    userData: payload.userData,
    userToken: payload.userToken,
  });
};

const changeProfile = (state) => {
  return updateState(state);
};

const authChangePlan = (state, { payload }) => {
  return updateState(state, { planSelected: payload.planSelected });
};

const authFailed = (state, { payload }) => {
  return updateState(state, {
    ...initialState,
    failMessage: payload,
  });
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_FAILED: return authFailed(state, action);
    case AUTH_LOGIN: return loginUser(state, action);
    case AUTH_USER: return authUser(state, action);
    case AUTH_CHANGE_PROFILE: return changeProfile(state);
    case AUTH_CHANGE_PLAN: return authChangePlan(state, action);
    default: return state;
  }
};

export default authReducer;
