import React, { PureComponent } from 'react';

import { VIEWPORTS } from './../settings/_generalSettings';

export default ChildComponent => {
  return class ComposedComponent extends PureComponent {
    /**
     * This High Order Component is responsible
     * for check what viewport the user is using.
     * It pass the state as props to the "Child Component".
     * You can use the props to display and hide components.
     */
    state = {
      isMobile: false,
      isDesktop: false,
      isReady: false,
    }

    componentDidMount() {
      this.handleWindowSizeChange();
      window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
      let actualWidth = window.innerWidth;

      this.setState({
        isMobile: actualWidth <= VIEWPORTS.DESKTOP,
        isDesktop: actualWidth >= VIEWPORTS.DESKTOP,
        isReady: true,
      });
    };

    render() {
      return this.state.isReady && (
        <ChildComponent {...this.state} {...this.props} />
      );
    }
  };
};
