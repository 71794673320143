import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

// STYLE
import './RemoveUnavailabilityModal.sass'

// STORE
import { removeUnavailabilityRequest } from 'redux/modules/unavailability/requests'

// COMPONENTS
import { Typography, Button, CoreIcons } from 'components'
import ModalGeneric from '../ModalGeneric'

const RemoveUnavailabilityModal = ({ event }) => {
  const dispatch = useDispatch()
  // STORE
  const unavailability = useSelector((state) => state.unavailability)
  const loading = unavailability.loading
  //
  const date = event.date
  const time = event.time
  //
  async function removeRequest(username, id) {
    dispatch(removeUnavailabilityRequest(username, id))
  }

  return (
    <ModalGeneric modalTitle="Remover indisponibilidade">
      <div className="RemoveUnavailabilityModal">
        <div className="RemoveUnavailabilityModal__topInfo">
          <div className="RemoveUnavailabilityModal__toptext">
            <Typography align="center">
              Tem certeza que deseja excluir esta 
              indisponibilidade?
            </Typography>
          </div>
          <Typography bold>{date}</Typography>
          <Typography bold>Das {time.start} às {time.end}</Typography>
        </div>
        <div className="RemoveUnavailabilityModal__toast">
          <CoreIcons name ="AlertIcon" width="22px" height="22px" fill="#ff4949" />
          <Typography
            className="RemoveUnavailabilityModal__text RemoveUnavailabilityModal__text--toast"
            type="custom"
          >
            O profissional voltará a ficar disponível para agendamento 
            caso este horário esteja configurado em sua disponibilidade recorrente.
          </Typography>
        </div>
        <Button
          id={'remove-unavailability-btn'}
          loading={loading}
          onButtonClick={() => removeRequest(event.username, event.id)}
          text="SIM, QUERO REMOVER"/>
      </div>
    </ModalGeneric>
  )
}

export {
  RemoveUnavailabilityModal
}