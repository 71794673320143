import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addLanguagesData } from 'redux/actions'

import {
  LanguageData,
  UseLanguageData,
  RegisterBlocks,
  Persons
} from '../types'

const languageData: LanguageData[] = [ 
  {
    id: 1,
    name: 'Português',
    isSelected: false,
    icon: '🇧🇷'
  },
  {
    id: 2,
    name: 'Inglês',
    isSelected: false,
    icon: '🇺🇸'
  },
  {
    id: 3,
    name: 'Espanhol',
    isSelected: false,
    icon: '🇪🇸'
  },
  {
    id: 4,  
    name: 'Libras',
    isSelected: false,
    icon: '🤟'
  }
]

export const useLanguageData = (): UseLanguageData => {
  const [languages, setLanguages] = useState<LanguageData[]>(languageData)

  const dispatch = useDispatch()

  const isEdit = useSelector((state: RegisterBlocks) => state.registerBlocks.isEdit)
  const personProfileLanguages = useSelector((state: Persons) => state?.persons?.personProfile?.languages)
  const isBlockChecked = true
  const canSave = languages?.some((language) => language.isSelected)
  
  useEffect(() => {
    if (isEdit) {
      const languagesAlreadyInProfessionalProfile = personProfileLanguages?.map((language) => language.id)
      const languagesToBeSelected = languages.filter((language) => languagesAlreadyInProfessionalProfile?.includes(language.id))
      
      setLanguages(prevState => {
        return prevState.map((language) => {
          if (languagesToBeSelected?.includes(language)) {
            return {
              ...language,
              isSelected: true
            }
          }
          return {
            ...language,
            isSelected: false
          }
        })
      })
    }
  }, [isEdit, personProfileLanguages])

  function handleEditToggleSkill(id: number) {
    setLanguages(prevState => {
      return prevState.map((language) => {
        if (language.id === id) {
          return {
            ...language,
            isSelected: !language.isSelected
          }
        }
        return language
      })
    })
  }

  function handleSelectLanguage(id: number) {
    if (isEdit) {
      return handleEditToggleSkill(id)
    }

    setLanguages(prevState => {
      return prevState.map((language) => {
        if (language.id === id) {
          return {
            ...language,
            isSelected: !language.isSelected
          }
        }
        return language
      })
    })
  }

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    dispatch(addLanguagesData(isBlockChecked, canSave, { languages }))
  }, [languages])

  return {
    languages,
    handleSelectLanguage,
  }
}
