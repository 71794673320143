import { AxiosResponse, AxiosError } from 'axios'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

export interface UpdateReservationProps {
  uuid: number;
  professional_username: string;
  patient_username: string;
  date_exceptions: string[];
  reason: string;
}

const updateReservation = ({
  uuid,
  professional_username,
  patient_username,
  date_exceptions,
  reason
}: UpdateReservationProps): Promise<AxiosResponse | AxiosError> => {
  return new Promise((resolve, reject) => {
    const payload = {
      professional_username,
      patient_username,
      date_exceptions,
      reason
    }
    axios.patch(`${apiEndpoints.DELETE_RESERVATION}${uuid}/`, payload)
      .then(res => resolve(res))
      .catch(e => reject(e))
  })
}

export {
  updateReservation
}