import React from 'react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Button, Spinner, Typography } from '@telavita-core/react-design-kit'

import { CoreIcons } from 'components'
import ModalGeneric from '../ModalGeneric'

import { Status, useRemoveSingleAvailability } from './hooks/useRemoveSingleAvailability'

import { ReactComponent as FulfilledAlertIcon } from 'static/svg/fulfilled-alert-icon.svg'

import * as S from './styles'
import { WEEKDAYS_LONG } from 'settings/dayPicker'

interface SelectedSingleSlot {
  start_date: string
  end_date: string
  id: number 
  repeat_interval: null | number
}

interface RemoveSingleAvailabilityModalProps {
  selectedSingleSlot: SelectedSingleSlot
  onClose: () => void
}

interface ContentProps {
  selectedSingleSlot: SelectedSingleSlot
  handleRemoveSlot: () => void
}

const formatHour = (date: string) => format(new Date(date), 'HH', { locale: ptBR })

const SuccessMessage = ({ onClose }: { onClose: () => void }) => (
  <S.SuccessWrapper>
    <CoreIcons
      name='Check'
      width='50px'
      height='50px'
      fill='#49C327'
    />
    <Typography>
      Disponibilidade avulsa removida <br /> com sucesso!
    </Typography>
    <Button variant='contained' onClick={onClose}>
      FECHAR
    </Button>
  </S.SuccessWrapper>
)

const Content = ({ selectedSingleSlot, handleRemoveSlot }: ContentProps) => (
  <S.ContentWrapper>
    <Typography variant='content2' center>
      Tem certeza que deseja excluir este horário para agendamento?
    </Typography>

    <S.ContentDateInfoWrapper>
      <Typography variant='content2' weight='bold'>
        {format(new Date(selectedSingleSlot.start_date), 'dd/MM/yyyy')}
      </Typography>
      <Typography variant='content2' weight='bold'>
        Das {formatHour(selectedSingleSlot.start_date)}h 
        às {formatHour(selectedSingleSlot.end_date)}h
      </Typography>
    </S.ContentDateInfoWrapper>

    <S.ContentAlertWrapper>
      <CoreIcons 
        name='AlertIcon'
        width='24px'
        height='24px'
        fill='#FF4949'
      />
      <Typography variant='content2' color='danger' center>
        Ao remover, este horário deixará de aparecer para os pacientes.
      </Typography>
    </S.ContentAlertWrapper>

    <Button variant='contained' onClick={handleRemoveSlot}>
      SIM, QUERO REMOVER
    </Button>
  </S.ContentWrapper>
)

const BlockedSlotMessage = ({ onClose, selectedSingleSlot }: RemoveSingleAvailabilityModalProps) => {
  const startDate = new Date(selectedSingleSlot.start_date)
  const endDate = new Date(selectedSingleSlot.end_date)

  const formattedWeekdayName = WEEKDAYS_LONG[startDate.getDay()]
  const formattedDate = format(startDate, 'dd/MM/yy')
  const formattedStartTime = format(startDate, 'HH:mm')
  const formattedEndTime = format(endDate, 'HH:mm')

  return (
    <S.BlockedWrapper>
      <FulfilledAlertIcon width={50} height={50}/>

      <S.BlockedDateTimeWrapper>
        <Typography variant='content1' weight='bold' center color='danger'>
          {formattedWeekdayName}, {formattedDate}
        </Typography>

        <Typography variant='content1' weight='bold' center color='danger'>
          {formattedStartTime} às {formattedEndTime}
        </Typography>
      </S.BlockedDateTimeWrapper>

      <Typography variant='content2' color='danger' customClassName='infoBlockedText'>
        Há uma <strong>reserva</strong> neste dia e horário.
      </Typography>
      <Typography center variant='content2'>
        Para removê-la, é preciso que o profissional vá até “Reservas” e <strong>cancele</strong> ou <strong>remarque</strong> a reserva do paciente.
      </Typography>
      <Button variant='contained' onClick={onClose}>
        ENTENDI
      </Button>
    </S.BlockedWrapper>
  )
}

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => (
  <S.ErrorWrapper>
    <FulfilledAlertIcon width={50} height={50}/>
    <Typography variant='content2' color='danger' center>
      {errorMessage}
    </Typography>
  </S.ErrorWrapper>
)

export const RemoveSingleAvailabilityModal: React.FC<RemoveSingleAvailabilityModalProps> = ({
  selectedSingleSlot,
  onClose
}) => {
  const { errorMessage, handleRemoveSlot, status } = useRemoveSingleAvailability({
    slotId: selectedSingleSlot.id,
  })

  return (
    <ModalGeneric
      modalTitle={status === Status.SUCCESS ? '' : 'Excluir disponibilidade avulsa'}
      onCloseModal={onClose}
      modalSubtitle=''
    >

      {status === Status.LOADING && (
        <S.LoadingWrapper>
          <Spinner />
        </S.LoadingWrapper>
      )}

      {status === Status.SUCCESS && <SuccessMessage onClose={onClose} />}

      {status === Status.BLOCKED && (
        <BlockedSlotMessage 
          onClose={onClose}
          selectedSingleSlot={selectedSingleSlot}  
        />
      )}

      {status === Status.IDLE && (
        <Content
          selectedSingleSlot={selectedSingleSlot}
          handleRemoveSlot={handleRemoveSlot}
        />
      )}

      {status === Status.ERROR && (
        <ErrorMessage errorMessage={errorMessage} />
      )}

    </ModalGeneric>
  )
}