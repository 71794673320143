import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { useQuery } from '@tanstack/react-query'

interface Plan {
  id: number
  code: string
  name: string
  is_particular: boolean
}

interface PlanGroup {
  id: number
  code: string
  name: string
  plans: Plan[]
}

interface PlanGroups {
  plan_groups: PlanGroup[]
}

interface FormattedPlan {
  label: string
  id: string
}

interface FormattedPlanGroup {
  id: string
  label: string
  plans: FormattedPlan[]
}

interface UseGetPlans {
  data: FormattedPlanGroup[]
}

async function getPlans(): Promise<PlanGroups> {
  const response = await axios.get(apiEndpoints.PLANS_GROUPS)

  return response.data
}

function formatData(data: PlanGroups): FormattedPlanGroup[] {
  return data.plan_groups.map((planGroup) => {
    const formattedPlans = planGroup.plans.map((plan) => {
      return {
        label: plan.name,
        id: plan.code,
      }
    })

    return {
      id: planGroup.code,
      label: planGroup.name,
      plans: formattedPlans,
    }
  })
}

export function useGetPlans(): UseGetPlans {
  const { data } = useQuery({
    queryKey: ['FILTER_MODAL_GET_PLANS'],
    queryFn: getPlans,
  })

  if (!data) {
    return {
      data: [],
    }
  }

  return {
    data: formatData(data),
  }
}