function handleProfileCode (profileCode) {
  switch(profileCode) {
    case 'TVTVPSIQ': return 'Psiquiatra - '
    default: return 'Psicólogo - '
  }
}

function handleScheduleProfileCode (type) {
  switch(type) {
    case 'TVTVPSIQ': return 'psiquiatra'
    default: return 'psicólogo'
  }
}

function handleProfessionalUrl (type) {
  switch(type) {
    case 'TVTVPSIQ': return 'psiquiatra'
    default: return 'psicologo'
  }
}

function handleProfessionalCode (type) {
  switch(type) {
    case 'psiquiatra': return 'PSIQ'
    default: return 'PSIC'
  }
}

function getProfessionalCodeByURL (url) {
  if (url.includes('psiquiatria-online')) return 'PSIQ'
    else return 'PSIC'
}

function getProfessionalCodeByProfileCode (profileCode) {
  switch(profileCode) {
    case 'TVTVPSIQ': return 'PSIQ'
    default: return 'PSIC'
  }
}

export {
  handleProfileCode,
  handleScheduleProfileCode,
  handleProfessionalUrl,
  handleProfessionalCode,
  getProfessionalCodeByURL,
  getProfessionalCodeByProfileCode
}