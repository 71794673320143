const domain = window.location.hostname.includes('localhost') ? 'localhost' : 'telavita.com.br'
const cookieName = process.env.REACT_APP_SESSION_COOKIE_NAME

// Essa função seta o token em novo cookie com 24 horas de expiração.
// Se o cookie já existir, então será sobrescrito.
const setAuthToken = token => {
  let d = new Date()
  d.setTime(d.getTime() + (1 * 24 * 60 * 60 * 1000))
  let expires = 'expires=' + d.toUTCString()
  document.cookie = cookieName + '=' + token + ';' + expires + ';path=/;domain=' + domain
}

// Recuperar o token atual dos cookies.
const getAuthToken = () => {
  let name = cookieName + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ')
      c = c.substring(1)
    if (c.indexOf(name) === 0)
      return c.substring(name.length, c.length)
  }
  return null
}

// Remover o token atual dos cookies.
const deleteAuthToken = () => {
  document.cookie = cookieName + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=' + domain
}

export {
  setAuthToken,
  getAuthToken,
  deleteAuthToken
}