import React from "react";

const PencilEdit = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path id="Caminho_2222" data-name="Caminho 2222" d="M0,0H24V24H0Z" fill="none" />
      <path id="Caminho_2337" data-name="Caminho 2337" d="M3,17.25V21H6.75L17.81,9.94,14.06,6.19ZM20.71,7.04a1,1,0,0,0,0-1.41L18.37,3.29a1,1,0,0,0-1.41,0L15.13,5.12l3.75,3.75Z" transform="translate(-0.085 0.003)" fill={fill} />
    </svg>
);


export default PencilEdit;