import {
  CLEAR_NOTIFICATIONS,
  WS_NOTIFICATIONS,
} from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

const initialState = {
  wsGetSchedule: {},
  wsScheduleDuration: 0,
  wsStartSchedule: false,
  wsTimeUp: false,
};

const onClearNotification = (state) => {
  return updateState(state, initialState);
};

const onNotification = (state, { payload }) => {
  return updateState(state, {
    wsGetSchedule: payload.wsGetSchedule,
    wsScheduleDuration: payload.wsScheduleDuration,
    wsStartSchedule: payload.wsStartSchedule,
    wsTimeUp: payload.wsTimeUp,
  });
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_NOTIFICATIONS: return onClearNotification(state);
    case WS_NOTIFICATIONS: return onNotification(state, action);
    default: return state;
  }
};

export default notificationReducer;