import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { addTreatmentByAgeData } from 'redux/actions/registerBlocksActions'
import { childAgeRangeOptions, teenagerAgeRangeOptions } from 'settings/_ageRangeOptions'

import { AgeData, AgeRangeCategory, UseAgeData, RegisterBlocks, Persons, AgeRangeOptions } from '../TreatmentByAgeData.types'

const MIN_ADULT_AGE = 18
const MAX_ADULT_AGE = 59
const MIN_ELDERLY_AGE = 60
const MAX_ELDERLY_AGE = 140

export const initialAgesData: AgeData[] = [
  {
    id: 1,
    name: 'Criança',
    isSelected: false,
    category: AgeRangeCategory.CHILD,
    ageRange: {  
      id: null,
      label: null,
      startAge: null,
      endAge: null  
    }
  },
  {
    id: 2,
    name: 'Adolescente',
    isSelected: false,
    category: AgeRangeCategory.TEENAGER,
    ageRange: {  
      id: null,
      label: null,
      startAge: null,
      endAge: null  
    }
  },
  {
    id: 3,
    name: 'Adulto (18 a 59 anos)',
    isSelected: false,
    category: AgeRangeCategory.ADULT,
    ageRange: {  
      id: null,
      label: null,
      startAge: MIN_ADULT_AGE,
      endAge: MAX_ADULT_AGE  
    }
  },
  {
    id: 4,
    name: 'Idoso (60+)',
    isSelected: false,
    category: AgeRangeCategory.ELDERLY,
    ageRange: {  
      id: null,
      label: null,
      startAge: MIN_ELDERLY_AGE,
      endAge: MAX_ELDERLY_AGE  
    }
  }
]

export const useAgeData = (): UseAgeData => {
  const [ages, setAges] = useState<AgeData[]>(initialAgesData)
  
  const dispatch = useDispatch()
  const isBlockChecked = true

  const isEdit = useSelector((state: RegisterBlocks) => state.registerBlocks.isEdit)
  const alreadySavedInProfile = useSelector((state: Persons) => state?.persons?.personProfile?.age_ranges)

  useEffect(() => {
    if (isEdit) {
      setAges(prevState => prevState.map(age => {
        const findAgeRange = alreadySavedInProfile.find(ageRange => ageRange.category === age.category)
        if (findAgeRange) {
          return {
            ...age,
            isSelected: true,
            ageRange: {
              id: findAgeRange.id,
              label: findAgeRange.category,
              startAge: findAgeRange.start_age,
              endAge: findAgeRange.end_age
            }
          }
        }
        return age
      }))
    }
  }, [isEdit, alreadySavedInProfile])

  const canSave = () => ages.some(age => age.isSelected)

  useEffect(() => {
    dispatch(addTreatmentByAgeData(isBlockChecked, canSave(), { ages }))
  }, [ages])

  function clearSelectedAgeRange(id: number) {
    const updatedAges = ages.map(age => {
      if (age.id === id) {
        return {
          ...age,
          isSelected: false,
          ageRange: {
            id: age.ageRange.id,
            label: null,
            startAge: null,
            endAge: null
          }
        }
      }
      return age
    })

    setAges(updatedAges)
  }

  function ageOptionIsAlreadySelected(id: number) {
    const findAge = ages.find(age => age.id === id)
    return findAge?.isSelected
  }

  function handleSelectAge(id: number) {
    const findCategory = ages.find(age => age.id === id)?.category
    const categoriesWithRangeSelect = [AgeRangeCategory.CHILD, AgeRangeCategory.TEENAGER]
    const isSelectedAgeHasRangeSelect = categoriesWithRangeSelect.includes(findCategory)

    if (ageOptionIsAlreadySelected(id) && isSelectedAgeHasRangeSelect) {
      return clearSelectedAgeRange(id)
    }

    setAges(ages.map(age => age.id === id 
      ? { ...age, isSelected: !age.isSelected } 
      : age
    ))
  }

  function updateAgeRange(category: AgeRangeCategory, id: number, label: string, ageRanges: AgeRangeOptions[]) {
    const findAgeRange = ageRanges.find(ageRange => ageRange.id === id)
  
    return ages.map(age => {
      if (age.category === category) {
        return {
          ...age,
          ageRange: {
            id,
            label,
            startAge: findAgeRange?.startAge,
            endAge: findAgeRange?.endAge
          }
        }
      }
      return age
    })
  }
  
  function handleSelectChildAgeRange(id: number, label: string) {
    setAges(updateAgeRange(AgeRangeCategory.CHILD, id, label, childAgeRangeOptions))
  }


  function handleSelectTeenagerAgeRange(id: number, label: string) {
    setAges(updateAgeRange(AgeRangeCategory.TEENAGER, id, label, teenagerAgeRangeOptions))
  }

  return {
    ages,
    handleSelectAge,
    handleSelectChildAgeRange,
    handleSelectTeenagerAgeRange,
    hasErrorOnChildAgeRange: false,
    hasErrorOnTeenagerAgeRange: false,
  }
}