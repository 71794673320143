import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  patientName: number,
  planCode: string,
  userName: string,
  cardNumber: string
}

interface Error {
  response: {
    status: number
  }
}

export const linkPlan = ({
  patientName,
  planCode,
  userName,
  cardNumber
}: Payload): Promise<void> => {

  const endpoint = apiEndpoints.PATIENTS_PLANS(userName, planCode)

  const params = {
    ...(patientName && { cardholder_name: patientName }),
    ...(cardNumber && { card_number: cardNumber }),
  }

  return new Promise<void> ((resolve, reject) => {
    resolve()
    axios.post(endpoint, params)
      .then(() => resolve())
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}
