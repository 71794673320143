import React, { useState, useRef, useEffect, useCallback } from 'react'
import { format, startOfMonth, subMonths } from 'date-fns'
import { Button, Tooltip, Typography } from '@telavita-core/react-design-kit'
import { ptBR } from 'date-fns/locale'

import { useSchedulesAverage } from './hooks/useSchedulesAverage'

import { PatientsAverageScheduleModal } from 'containers/ModalManager/Modals/PatientsAverageScheduleModal'

import { GraphicsCard } from 'components/GraphicsCard'
import { CustomizedHeader } from './components/CustomizedHeader'
import { CustomizedLineChart } from './components/CustomizedLineChart'
import { ReactComponent as InfoFilledIcon } from 'static/svg/icon-info-filled.svg'

import { ISchedulesAverageData, PlanGroupFrequency } from './types'

import { Container, Icon, Footer } from './styles'

const DATA_LENGTH = 6
const PREVIOUS_MONTHS = 5

const lastMonth = subMonths(new Date(), 1)
const previousMonths = subMonths(lastMonth, PREVIOUS_MONTHS)

export function ProfessionalSchedulesAverage(): JSX.Element {
  const cardRef = useRef<HTMLDivElement>(null)
  const [rightArrowDisable, setRightArrowDisable] = useState(false)
  const [activeTab, setActiveTab] = useState(0)
  const [data, setData] = useState<ISchedulesAverageData[]>([])
  const [showPatientsAverageScheduleModal, setShowPatientsAverageScheduleModal] = useState(false)
  const [selectedDate, setSelectedDate] = useState({
    startDate: startOfMonth(previousMonths),
    endDate: startOfMonth(lastMonth),
  })

  const {
    data: scheduleData, 
    loading,
    fetchSchedulesAverage, 
    getLastMonthData,
    setScheduleData
  } = useSchedulesAverage()

  useEffect(() => {
    if(scheduleData) {
      setData(scheduleData.slice(-DATA_LENGTH))
    }

  }, [scheduleData])

  useEffect(() => {
    void fetchSchedulesAverage({
      selectedDate,
      planGroupFrequency: activeTab === 0 
        ? PlanGroupFrequency.WEEKLY 
        : PlanGroupFrequency.BIWEEKLY,
    })
  }, [fetchSchedulesAverage, activeTab, selectedDate])

  const handleChangeTab = (_, id: number) => {
    setActiveTab(id)
    setScheduleData([])
    setSelectedDate({
      startDate: startOfMonth(previousMonths),
      endDate: startOfMonth(lastMonth),
    })
  }

  const isRightArrowDisable = useCallback(() => {
    const scheduleDataValue = scheduleData[0]
    const dataValue = data[0]
    const isSameDate = 
      scheduleDataValue?.month === dataValue?.month && 
      scheduleDataValue?.year === dataValue?.year

    if (isSameDate) {
      return true
    }

    return false
  }, [data, scheduleData])

  useEffect(() => {
    setRightArrowDisable(() => isRightArrowDisable())
  }, [data, isRightArrowDisable])

  const getPreviousMonths = () => {
    const newEndDate = subMonths(selectedDate.startDate, 1)
    const newStartDate = subMonths(newEndDate, PREVIOUS_MONTHS)

    setSelectedDate({ startDate: newStartDate, endDate: newEndDate })
  }

  function navLeft() {
    const firstGraphDate = data[0]?.date

    const newEndDate = subMonths(firstGraphDate, 1)
    const newStartDate = subMonths(newEndDate, PREVIOUS_MONTHS)
    const formattedYear = format(newStartDate, 'yy')
    const formattedMonth = format(newStartDate, 'MMM', {
      locale: ptBR
    })

    const findFirstValueData = scheduleData.findIndex(item => {
      return item?.month === formattedMonth && item?.year === formattedYear
    })
    
    if (findFirstValueData >= 0) {
      const newData = scheduleData.slice(findFirstValueData, findFirstValueData + DATA_LENGTH)
      return setData(newData)
    }

    getPreviousMonths()
  }

  function navRight() {
    const firstValue = data[0]
    const findFirstValueData = scheduleData.findIndex(item => (item?.month === firstValue?.month && item?.year === firstValue?.year))

    if (scheduleData.length > DATA_LENGTH) {
      setData(scheduleData.slice(findFirstValueData - DATA_LENGTH, findFirstValueData))
    }
  }

  return (
    <>
      <GraphicsCard
        title='Média de consultas por paciente'
        headerAction={
          <Tooltip
            content={
              <Typography variant='content3'>
                O número de vezes que seus pacientes voltam a se consultar com você.
              </Typography>
            }
            arrow
            theme='info'
            placement='bottom'
            maxWidth={180}
          >
            <Icon>
              <InfoFilledIcon />
            </Icon>
          </Tooltip>
        }
      >
        <Container ref={cardRef}>
          <main>
            <CustomizedHeader 
              activeTab={activeTab}
              handleChangeTab={handleChangeTab}
            />

            <CustomizedLineChart
              loading={loading}
              data={data}
              activeTab={activeTab}
              navLeft={navLeft}
              navRight={navRight}
              rightArrowDisable={rightArrowDisable}
              getLastMonthData={getLastMonthData}
            />
          </main>

          <Footer>
            <Button
              uppercase={false}
              variant='text'
              onClick={() => setShowPatientsAverageScheduleModal(true)}
            >
              Saiba como melhorar a sua média
            </Button>
          </Footer>
        </Container>

      </GraphicsCard>
      {showPatientsAverageScheduleModal && (
        <PatientsAverageScheduleModal 
          onClose={() => setShowPatientsAverageScheduleModal(false)}
        />
      )}
    </>
  )
}