import React, { useState, useEffect } from 'react'

import { Button, Typography } from '@telavita-core/react-design-kit'
import { useSelector, useDispatch } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import clsx from 'clsx'

import { TextInput, RadioButton, CheckBox } from 'components'
import LineSeparator from '../../../../components/LineSeparator'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import RadioUnselected from 'components/CoreIcons/Icons/RadioUnselected'
import Check from 'components/CoreIcons/Icons/Check'
import Toggle from '../../../../components/Toggle'
import Row from '../../../../components/Row'

import { getProducts, addProductsData } from 'redux/actions'
import { getPlans } from 'redux/actions/plansActions'

import { currencyNormalization } from 'utils/orderHandlers'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'
import { PLANS_TIERS } from 'settings/_tiersSettings'

const { CARE_PLUS, ENTERPRISE, CORPORATE_UNLIMITED, CORPORATE_LIMITED, VITTA, PARTICULAR, CAPITATION } = PlanGroupCategoryEnum

const INACTIVE_TIER = 0

const ProductsData = ({ role }) => {
  const dispatch = useDispatch()

  const isEdit = useSelector((state) => state.registerBlocks.isEdit)
  const personProductsData = useSelector(
    (state) => state.registerBlocks.blocksData.productsData.products
  )
  const savedProducts = useSelector(
    (state) => state.registerBlocks.productsData.data.products
  )
  const reduxProducts = useSelector((state) => state.products.products)
  const reduxPlans = useSelector((state) => state.plans.plans).filter(plan => plan.tier !== INACTIVE_TIER)

  const particularPlanId = reduxPlans.find(rPlan => rPlan.is_particular)?.id

  const plansWithoutParticular = reduxPlans.filter(rPlan => rPlan.plan_group.category !== PARTICULAR)
  const professionalHasParticularPlanActive = personProductsData && personProductsData?.reduce((acc, curr) => {
    const hasParticular = curr.plans.some(plan => plan.partner_plan_id === particularPlanId)
    if (hasParticular) return acc = true
    return acc
  },false)

  const [products, setProducts] = useState(isEdit ? personProductsData?.map(product => ({ ...product, active: true })) : [])
  const [isPrivateChecked, setIsPrivateChecked] = useState(undefined)
  const [openModal, setModalOpen] = useState(false)
  const [productSelected, setProductSelected] = useState()
  const [appointmentId, setAppoinmentId] = useState()
  const [categoryFilter, setCategoryFilter] = useState(undefined)
  const [modalRef, setModalRef] = useState()
  
  const PLANS_CATEGORYS = {
    CORPORATE: [CORPORATE_UNLIMITED, CORPORATE_LIMITED],
    ENTERPRISE: [ENTERPRISE, CARE_PLUS, VITTA],
    CAPITATION: [CAPITATION],
  }
  
  const plans = categoryFilter !== undefined ?
    plansWithoutParticular.filter((plan => (PLANS_CATEGORYS[categoryFilter]).includes(plan.plan_group.category))) :
    plansWithoutParticular
  
  const groupedPlans = plans.length > 0 ? 
    plans.reduce((acc, curr) => {
      const planGroupName = curr.plan_group.name
      if (acc[planGroupName]) {
        acc[planGroupName] = [curr, ...acc[planGroupName]]
      } else {
        acc[planGroupName] = [curr]
      }

      return acc
    }, {})
    : []

  const orderedGroupedPlans = Object.keys(groupedPlans).sort().reduce(
    (obj, key) => { 
      obj[key] = groupedPlans[key] 
      return obj
    }, 
    {}
  )

  useEffect(() => {
    checkRequiredFields()
  }, [products, isPrivateChecked])

  const checkRequiredFields = () => {
    const filedProducts = [...products]
    
    const isValidRequiredFields = (product) => {
      if (isPrivateChecked) {
        const invalidPrices = ['0,00', '0.00']
        if (product.price && !invalidPrices.includes(product.price)) {
          return true
        }

        return false
      }
      
      if (product.plans.length > 0) {
        return true
      }
    }
    
    const finalProducts = filedProducts.map((product) => {
      if (product.active === false) {
        product.plans = []
      }
          
      return product
    })

    const atLeastOneProductIsCorrectlyFulfilled = finalProducts.some((product) => 
      product.active && isValidRequiredFields(product))

    const isReadyToSave = atLeastOneProductIsCorrectlyFulfilled
    
    dispatch(
      addProductsData(true, isReadyToSave, { products: finalProducts })
    )
  }

  const handleToggleProduct = (appointmentId, productId) => {
    setIsPrivateChecked(false)
    let newProducts = [...products]
  
    const productIndex = newProducts.findIndex(
      (product) => product.product_id === productId
    )
  
    if (productIndex >= 0) {
      // Se o produto já está ativo, desativa e remove da lista
      if (newProducts[productIndex].active) {
        newProducts[productIndex].active = false
        newProducts[productIndex].plans = []
      } else {
        // Se o produto não está ativo, desativa todos os outros produtos e ativa este
        newProducts = newProducts.map(product => ({ ...product, active: false }))
        newProducts[productIndex].active = true
      }
    } else {
      // Se o produto não está na lista, desativa todos os outros produtos e adicione este como ativo
      newProducts = newProducts.map(product => ({ ...product, active: false }))
      const priceDefault = null
      newProducts.push({
        active: true,
        appointment_id: appointmentId,
        product_id: productId,
        price: priceDefault,
        plans: []
      })
    }

    setProducts(newProducts)
    checkRequiredFields()
  }

  const handleCheckAppointment = (productId) => {
    return products.find(
      (product) => product.product_id === productId && product.active
    )
      ? true
      : false
  }

  const handleCheckPlan = (appointmentId, planId, productId) => {
    const product = products.find(
      (product) =>
        product.appointment_id === appointmentId &&
        product.product_id === productId
    )
    const hasPlan = product.plans
      ? product.plans.find((plan) => plan.partner_plan_id === planId) !==
        undefined
        ? true
        : false
      : false
    return hasPlan
  }

  const handlePlanSelection = (plansIds, productId) => {
    const targetProduct = products.find(
      (product) => product.product_id === productId
    )

    const currentPlans = targetProduct.plans ? [...targetProduct.plans] : []

    let newPlans = []

    if (currentPlans.length > 0) {
      newPlans = plansIds.reduce((acc, curr) => {
        const currentPlan = acc.find(plan => plan.partner_plan_id === curr)
        if (currentPlan) {
          acc = [...acc].filter(plan => plan.partner_plan_id !== currentPlan.partner_plan_id)
        } else {
          acc = [...acc, { partner_plan_id: curr }]
        }

        return acc
      },currentPlans)
    } else {
      newPlans = [...currentPlans, ...plansIds.map(planId => ({ partner_plan_id: planId }))]
    }

    handleUpdateTargetAppointment(productId, { plans: newPlans })
  }

  const handleUpdateTargetAppointment = (productId, newData) => {
    let newProducts = [...products]

    let targetProductIndex = newProducts.findIndex(
      (product) => product.product_id === productId
    )
    let targetProduct = newProducts[targetProductIndex]

    let updatedProduct = {
      ...targetProduct,
      ...newData,
    }

    newProducts[targetProductIndex] = updatedProduct

    setProducts(newProducts)
  }

  const handleCheckPrice = (productId) => {
    const targetProduct = products.find(
      (product) => product.product_id === productId
    )

    return targetProduct.price ? `R$ ${targetProduct.price.replace('.', ',')}` : ''
  }

  const handlePriceInput = (e, productId) => {
    const inputValue = e.target.value
    const inputValueWithoutCurrency = inputValue.slice(3)

    if (
      new RegExp(/^[0-9]*,[0-9]*$/g).test(inputValueWithoutCurrency) ||
      new RegExp(/^[0-9]*$/g).test(inputValueWithoutCurrency)
    ) {
      const formatedValue = currencyNormalization(inputValueWithoutCurrency)

      const updatedProducts = products.map((product) => {
        if (product.product_id === productId) {
          product.price = formatedValue
          return product
        }

        return product
      })

      setProducts(updatedProducts)

      checkRequiredFields()
    }
  }

  const getCurrentSelectedPlans = () => {
    const currentProduct = products.find(product => product.product_id === productSelected)
    const currentSelectedPlans = currentProduct.plans ? currentProduct.plans.map(plan => plan.partner_plan_id) : []

    return currentSelectedPlans
  }

  const getCurrentPlansSelectedCount = (products, productId) => {
    const currentProductId = productId ?? productSelected

    const currentProductData =  particularPlanId && products?.length > 0 ? products.find(product => product.product_id === currentProductId) : {}

    const plansWithoutParticular = currentProductData?.plans ? currentProductData?.plans.filter(plan => plan.partner_plan_id !== particularPlanId) : []
    const plansSelectedCount = plansWithoutParticular.length > 0 ? plansWithoutParticular.length : 0

    return plansSelectedCount
  }

  const handleSelectCategoryAndTier = async (tier, category) => {
    const plansByTierAndCategory = reduxPlans.reduce((acc, curr) => {

      if ((curr.tier === tier && (PLANS_CATEGORYS[category]).includes(curr.plan_group.category))) acc.push(curr.id)
      
      return acc
    },[])

    const currentSelectedPlans = getCurrentSelectedPlans()

    const isRemovingPlans = currentSelectedPlans.length > 0

    const plansIdsByPlanNameDif = plansByTierAndCategory.filter(function (element, index, array) {
      if(currentSelectedPlans.indexOf(element) == -1)
        return element
    })

    const filteredPlansIds = isRemovingPlans ?  plansIdsByPlanNameDif : currentSelectedPlans.filter( a => !plansIdsByPlanNameDif.includes( a ) )

    const plansIds = filteredPlansIds.length > 0 ? filteredPlansIds : plansByTierAndCategory


    handlePlanSelection(plansIds, productSelected)
  }

  const getPlanSectionButtonText = (planName) => {
    const plansIdsByPlanName = groupedPlans[planName].map(plan => plan.id)

    const currentSelectedPlans = getCurrentSelectedPlans()

    const isRemovingPlans = plansIdsByPlanName.every(item => currentSelectedPlans.includes(item))

    return isRemovingPlans ?  'Remover todos os planos' : 'Selecionar todos os planos'

  }

  const getTierByCategoryAndCurrentPlansByTier = (tier, category) => {
    const plansByTierAndCategory = reduxPlans.reduce((acc, curr) => {
      if (curr.tier === tier && (PLANS_CATEGORYS[category]).includes(curr.plan_group.category)) acc.push(curr.id)
      return acc
    },[])
    const currentSelectedPlans = getCurrentSelectedPlans()
    const currentPlansByTier = currentSelectedPlans.reduce((acc, curr) => {
      if (plansByTierAndCategory.includes(curr)) acc.push(curr)
      return acc
    },[])

    return { currentPlansByTier, plansByTierAndCategory }
  }

  const isAllPlansOfTierSelected = (tier, category) => {
    const { currentPlansByTier, plansByTierAndCategory } = getTierByCategoryAndCurrentPlansByTier(tier, category)

    if (currentPlansByTier.length === 0) return false

    return currentPlansByTier.length === plansByTierAndCategory.length
  }

  const isTierCheckboxInderteminate = (tier, category) => {
    const { currentPlansByTier, plansByTierAndCategory } = getTierByCategoryAndCurrentPlansByTier(tier, category)

    if (currentPlansByTier.length === 0) return false

    return currentPlansByTier.length < plansByTierAndCategory.length  
  }

  const handleSelectAllPlan = (planName) => {    
    const plansIdsByPlanName = groupedPlans[planName].map(plan => plan.id)
    
    const currentSelectedPlans = getCurrentSelectedPlans()

    const isRemovingPlans = currentSelectedPlans.length > 0

    const plansIdsByPlanNameDif = plansIdsByPlanName.filter(function (element, index, array) {
      if(currentSelectedPlans.indexOf(element) == -1)
        return element
    })

    const filteredPlansIds = isRemovingPlans ?  plansIdsByPlanNameDif : currentSelectedPlans.filter( a => !plansIdsByPlanNameDif.includes( a ) )

    const plansIds = filteredPlansIds.length > 0 ? filteredPlansIds : plansIdsByPlanName

    handlePlanSelection(plansIds, productSelected)
  }

  const onSave = () => {
    checkRequiredFields()
    setModalOpen(false)
  }

  const onCancelChanges = () => {
    const currentSavedProduct = savedProducts.find(sProduct => sProduct.product_id === productSelected) ?? { plans: [] }
    
    const newProducts = products.map(product => {
      if (product.product_id === productSelected) {
        return {
          ...product,
          plans: currentSavedProduct.plans
        }
      }
      return product
    })

    setProducts(newProducts)
    setModalOpen(false)
  }

  const handleSelectParticularPlan = (isSelected, productId) => {
    const particularPlan = reduxPlans.find(rPlan => rPlan.is_particular)
    const updatedProducts = products.map(product => {
      if (product.product_id === productId) {
        if (isSelected) {
          product.plans = product.plans.filter(plan => plan.partner_plan_id !== particularPlan.id)
          product.plans.push({ partner_plan_id: particularPlan.id })
        } else {
          product.plans = product.plans.filter(plan => plan.partner_plan_id !== particularPlan.id)
        }
      }
      return product
    })

    setProducts(updatedProducts)
  }

  useEffect(() => {
    dispatch(getProducts(role))
    dispatch(getPlans({ profile_role: role }))
  }, [])

  useEffect(() => {
    if (isEdit) checkRequiredFields()
  },[])

  useEffect(() => {
    if (isEdit && professionalHasParticularPlanActive) {
      setIsPrivateChecked(true)
    } else {
      setIsPrivateChecked(false)
    }
      
  },[isEdit, professionalHasParticularPlanActive])

  const dynamicSidebarMaxHeight = `calc(${modalRef?.offsetHeight}px - 150px)`

  return (
    <div className='RegisterBlock__ProductsData'>
      <Row padding=' 0 0 2rem 0' direction='column'>
        <Typography variant='content1' weight='bold'>Produtos</Typography>
        <Typography
          variant='content2'
          italic
          inlineStyles={{ color: '#898989' }}
        >
          Apenas um produto pode ser ativo por vez
        </Typography>
      </Row>
      <Row>
        {reduxProducts.map((product, index) => {
          const currentPlansSelectedCount = getCurrentPlansSelectedCount(savedProducts, product.id)
          const hasPlansSaved = currentPlansSelectedCount > 0

          return product.appointments.map((appointment) => {
            return (
              <Row
                className='RegisterBlock__ProductsData__product'
                key={appointment.code}
              >
                <div className='RegisterBlock__ProductsData__product__heading'>
                  <div className='RegisterBlock__ProductsData__product__heading__fixed'>
                    <div className='RegisterBlock__ProductsData__product__heading__fixed__title'>
                      <Typography variant='content2' weight='bolder'>
                          Consulta de {product.product_general.duration} minutos
                      </Typography>
                    </div>
                    <div className='RegisterBlock__ProductsData__product__heading__fixed__toggle'>
                      <Toggle
                        isToggled={handleCheckAppointment(product.id)}
                        onToggledSwitch={(value) =>
                          handleToggleProduct(value, product.id, product.code)
                        }
                        toggleId={`toggle-${appointment.code}`}
                        value={appointment.id}
                      />
                    </div>
                  </div>
                </div>
                {handleCheckAppointment(product.id) && (
                    <>
                      <div className='RegisterBlock__ProductsData__PlanContainer'>
                        
                        <div className='RegisterBlock__ProductsData__ParticularPlanContainer'>
                          <Typography variant='content2'>
                              Atendimento particular*
                          </Typography>
                          <div className='RegisterBlock__ProductsData__ParticularPlanContainer__RadioButtonsContainer'>
                            <RadioButton
                              iconHeight='24px'
                              iconWidth='24px'
                              onClickRadioButton={() => {
                                setIsPrivateChecked(true)
                                handleSelectParticularPlan(true, product.id)
              
                              }}
                              selected={isPrivateChecked === true}
                              title='Sim'
                            />
                            <RadioButton
                              iconHeight='24px'
                              iconWidth='24px'
                              onClickRadioButton={() => {
                                setIsPrivateChecked(false)
                                handleSelectParticularPlan(false, product.id)
                              }}
                              selected={isPrivateChecked === false}
                              title='Não'
                            />
                          </div>
                          {isPrivateChecked && (
                            <TextInput
                              placeholder='R$ *'
                              required={true}
                              name={'document-{product.id}'}
                              inputHeight='4.8rem'
                              onInputChange={(e) =>
                                handlePriceInput(e, product.id)
                              }
                              value={handleCheckPrice(product.id)}
                              customClassName='RegisterBlock__ProductsData__ParticularPlanContainer__InputPrice'
                            />
                          )}
                        </div>
                        
                        <div className='RegisterBlock__ProductsData__AddPlansContainer'>
                          <Typography variant='content2'>
                            Atendimento por convênio
                          </Typography>
                          <Typography
                            variant='content2'
                            italic={!hasPlansSaved}
                            inlineStyles={{ color: hasPlansSaved ? '#1F35B5' : '#898989' }}
                          >
                            {hasPlansSaved ? `${currentPlansSelectedCount} planos adicionados` : 'Nenhum plano adicionado'}
                          </Typography>
                          <Button
                            onClick={(event) => {
                              event.preventDefault()
                              setProductSelected(product.id)
                              setAppoinmentId(appointment.id)
                              setModalOpen(true)
                            }}
                            variant='contained'
                            customClassName='RegisterBlock__ProductsData__AddPlansContainer__AddPlansButton'
                          >
                            {hasPlansSaved ? 'EDITAR' : 'ADICIONAR'} PLANOS
                          </Button>
                        </div>
                      </div>
                    </>
                )}
                <LineSeparator />
              </Row>
            )
          })
        })}
      </Row>
      <AnimatePresence>
        {openModal && (
          <ModalGeneric
            onCloseModal={onCancelChanges}
            customClassName='RegisterBlock__ProductsData__SelectPlansModal'
            containerRef={elem => setModalRef(elem)}
          >
            <div className='RegisterBlock__ProductsData__SelectPlansModal__Container'>
              <div className='RegisterBlock__ProductsData__SelectPlansModal__Title'>
                <Typography
                  variant='header4'
                  weight='bold'
                >
                  Planos atendidos pelo profissional
                </Typography>
              </div>
              <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__ModalContentWrapper'>
                <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper' style={{ maxHeight: dynamicSidebarMaxHeight }}>
                  <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__FiltersWrapper'>
                    <Typography customClassName='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__SidebarTitle'>
                        VISUALIZAR
                    </Typography>
                    <RadioButton 
                      title='Todos os convênios' 
                      selected={categoryFilter === undefined}
                      onClickRadioButton={() => setCategoryFilter(undefined)}
                    />
                    <RadioButton 
                      title='Só capitation' 
                      selected={categoryFilter === 'CAPITATION'}
                      onClickRadioButton={() => setCategoryFilter('CAPITATION')}
                    />
                    <RadioButton 
                      title='Só corporate' 
                      selected={categoryFilter === 'CORPORATE'}
                      onClickRadioButton={() => setCategoryFilter('CORPORATE')}
                    />
                    <RadioButton 
                      title='Só enterprise' 
                      selected={categoryFilter === 'ENTERPRISE'}
                      onClickRadioButton={() => setCategoryFilter('ENTERPRISE')}
                    />
                  </div>
                  <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper'>
                    <Typography customClassName='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__SidebarTitle'>
                        SELEÇÃO MÚLTIPLA
                    </Typography>
                    {Object.entries(PLANS_TIERS).map((planTier, index) => {
                      const category = planTier[0]

                      return (
                        <div 
                          className='RegisterBlock__ProductsData__SelectPlansModal__Container__SidebarWrapper__TierSelecionWrapper__TierSelectionSection'
                          key={`${category}-${index}`}
                        >
                          {Array.from(planTier[1]).map((tier) => (
                            <CheckBox 
                              key={tier.id}
                              title={tier.name}
                              isSelected={isAllPlansOfTierSelected(tier.id, category)}
                              onClickCheckBox={() => handleSelectCategoryAndTier(tier.id, category)}
                              customIconColor={(isAllPlansOfTierSelected(tier.id, category) || isTierCheckboxInderteminate(tier.id, category)) && '#1F35B5'}
                              indeterminate={isTierCheckboxInderteminate(tier.id, category)}
                            />
                          ))}
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent'>
                  {Object.values(orderedGroupedPlans).map((plans) => (
                    <div key={plans[0].plan_group.name} className='RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection'>
                      <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection__GroupedPlansHeader'>
                        <Typography variant='content2'>{plans[0].plan_group.name}</Typography>
                        <Button uppercase={false} onClick={() => handleSelectAllPlan(plans[0].plan_group.name)}>
                          {getPlanSectionButtonText(plans[0].plan_group.name)}
                        </Button>
                      </div>
                      <div className='RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlansSection__GroupedPlans'>
                        {plans.map((plan) => {
                          const isSelected = handleCheckPlan(appointmentId,plan.id,productSelected)
                          return (
                            <div 
                              key={plan.id}
                              className={clsx({
                                'RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection': true,
                                'RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection--active': isSelected
                              })}
                              onClick={() => handlePlanSelection([plan.id], productSelected)}
                            >
                              <div className={clsx({
                                'RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection__TextWrapper': true,
                                'RegisterBlock__ProductsData__SelectPlansModal__Container__MainContent__PlanSelection__TextWrapper--active': isSelected
                              })}>
                                <Typography variant='content2' inheritColor>
                                  {plan.plan_group.name || ''} {plan.name}
                                </Typography>
                                <Typography variant='content3' inheritColor>
                                  {[...PLANS_TIERS.CORPORATE, ...PLANS_TIERS.ENTERPRISE, ...PLANS_TIERS.CAPITATION].find(planTier => planTier.id === plan.tier)?.name}
                                </Typography>
                              </div>
                              {isSelected ? (
                                <Check 
                                  height='20px'
                                  width='20px'
                                  viewBox='0 0 20 20'
                                  fill='#1F35B5'
                                />
                              ) : (
                                <RadioUnselected
                                  height='20px'
                                  width='20px'
                                  viewBox='2 2 20 20'
                                  fill='#C3C3C3'
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className='RegisterBlock__ProductsData__SelectPlansModal__ModalFooter'>
                <Button onClick={onCancelChanges} uppercase={false}>Cancelar</Button>
                <div className='RegisterBlock__ProductsData__SelectPlansModal__ModalFooter__ActionsWrapper'>
                  <Typography variant='content2'>{getCurrentPlansSelectedCount(products)} planos atendidos</Typography>
                  <Button 
                    variant='contained'
                    onClick={onSave}
                  >
                      SALVAR PLANOS
                  </Button>
                </div>
              </div>
            </div>
          </ModalGeneric>
        )}
      </AnimatePresence>
    </div>
  )
}

export default ProductsData
