
import axios from 'axios';

// ACTIONS
import * as plansFilterAction from './actions';

const mainURL =  process.env.REACT_APP_API_URL; // main route prefix set by environment

const plansFilterRequest = () => {
  return function (dispatch) {
    dispatch(plansFilterAction.loadConvenios());
    axios.get(mainURL + '/v0/plans-groups/?page=1&per_page=9999')
      .then(response => {
        dispatch(plansFilterAction.loadConveniosSuccess(response.data.data));
      })
      .catch(response => {
        dispatch(plansFilterAction.loadConveniosSuccess(response.error));
      });
  };
};

export { plansFilterRequest };