import qs from 'querystring'

import { CLEAN_SCHEDULES, GET_SCHEDULES, SET_SCHEDULE_STATUS, GET_SCHEDULE_LOGS, START_LOADING_SCHEDULES, UPDATE_SCHEDULE } from './_actionTypes'

import axios, { isCancel } from '../../settings/axios'
import * as status from '../../settings/_scheduleStatusSettings'
import { apiEndpoints } from '../../settings/_apiSettings'
import { asyncError } from './asyncActions'

export const setScheduleStatus = (scheduleId, scheduleData) =>
  async dispatch => {
    const reqData = {
      status_code: scheduleData.statusCode,
      reason_code: scheduleData.reasonCode,
      details: scheduleData.details,
    }

    const response = await axios.put(
      apiEndpoints.SCHEDULE_DETAIL(scheduleId), reqData
    )
    let payload = {}

    switch (response.status) {
    case 'OK':
      return dispatch({
        type: SET_SCHEDULE_STATUS,
        payload: payload,
      })

    case 'ZERO_RESULTS':
      payload = { schedules: [] }

      return dispatch({
        type: SET_SCHEDULE_STATUS,
        payload: payload,
      })
    default:
      break
    }
  }

export const updateSchedule = (scheduleData) => dispatch =>{
  return dispatch({
    type: UPDATE_SCHEDULE,
    payload: scheduleData,
  })
}

export const getSchedules = ({
  username, 
  start_date, 
  start_date__gte, 
  start_date__lte, 
  end_date, 
  end_date__gte,
  end_date__lte,
  status_code, 
  profile_role,
  sort,
  page,
  per_page,
  query,
  planParams,
  abortController = null
}) => async dispatch => {

  const params = {
    ...(username && { username }),
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(start_date__gte && { start_date__gte }),
    ...(start_date__lte && { start_date__lte }),
    ...(end_date && { end_date }),
    ...(end_date__gte && { end_date__gte }),
    ...(end_date__lte && { end_date__lte }),
    ...(status_code ? { status_code } : {
      status_code: [
        status.SCHEDULED,
        status.CANCELED,
        status.CANCEL_REQUESTED,
        status.ATTENDED,
        status.UNATTENDED,
        status.ATTENDING,
        status.RETURNED,
        status.PROFESSIONAL_NO_SHOW,
        status.PATIENT_NO_SHOW,
        status.SCHEDULE_NO_PARTICIPANTS
      ],
    }),
    ...(sort && { sort }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(query && { query }),
    ...planParams
  }

  try {
    const response = await axios.get(
      apiEndpoints.SCHEDULES,
      { 
        params,
        paramsSerializer: params => {
          return qs.stringify(params)
        },
        signal: abortController && abortController.signal
      },
    )

    let payload

    switch (response.status) {
    case 'OK':
      payload = { 
        schedules: response.data.schedules,
        pagination: response.pagination,
      }

      return dispatch({
        type: GET_SCHEDULES,
        payload: payload,
      })

    case 'ZERO_RESULTS':
      payload = { schedules: [] }

      return dispatch({
        type: GET_SCHEDULES,
        payload: payload,
      })

    default:
      /**
         * 
         * TODO TRATAR ERROS
         * 
         */
      // dispatch(asyncError());
      break
    }
  } catch (err) {

    // Se o erro da requisição for devido ao cancelamento manual feito pela troca de rota apenas retorna
    if (isCancel(err)) {
      return
    }

    let payload = { schedules: [] }

    dispatch(asyncError(err))

    return dispatch({
      type: GET_SCHEDULES,
      payload: payload,
    })
  }
}

export const getProfessionalEvents = ({
  start_date, 
  end_date, 
  query,
  status_code,
  username,
  page,
  per_page
}) => async dispatch => {

  const params = {
    ...(username && { username }),
    ...(query && { query }),
    ...(status_code && { status_code }),
    ...(start_date && { start_date }),
    ...(end_date && { end_date }),
    ...(page && { page }),
    ...(per_page && { per_page })

  }

  try {

    dispatch({
      type: START_LOADING_SCHEDULES
    })

    const response = await axios.get(
      apiEndpoints.PROFESSIONAL_AGENDA_EVENTS(username),
      { 
        params,
        paramsSerializer: params => {
          return qs.stringify(params)
        },
      },
    )

    let payload

    switch (response.status) {
    case 'OK':
      payload = { 
        schedules: response.data.events,
        pagination: response.data.pagination,
      }

      return dispatch({
        type: GET_SCHEDULES,
        payload: payload,
      })

    case 'ZERO_RESULTS':
      payload = { schedules: [] }

      return dispatch({
        type: GET_SCHEDULES,
        payload: payload,
      })

    default:
      /**
         * 
         * TODO TRATAR ERROS
         * 
         */
      // dispatch(asyncError());
      break
    }
  } catch (err) {

    let payload = { schedules: [] }

    console.log(err)
    dispatch(asyncError(err))

    return dispatch({
      type: GET_SCHEDULES,
      payload: payload,
    })
  }
}

/* Get virtual room logs by schedule ID */
export const getScheduleLogs = (virtualRoomHash) => 
  async dispatch => {
    try {
      const response = await axios.get(apiEndpoints.SCHEDULE_LOGS(virtualRoomHash))
      const payload = response.data
      return dispatch({
        type: GET_SCHEDULE_LOGS,
        payload,
      })
    } catch (err) {
      const payload = {}
      return dispatch({
        type: GET_SCHEDULE_LOGS,
        payload,
      })
    }
  }

/** Clear schedules list state */
export const cleanSchedules = () => {
  return { type: CLEAN_SCHEDULES }
}