import React from 'react'

import { Question } from 'containers/ModalManager/Modals/HelpModal'

enum CategoriesQuestion {
  SOLICITED_NEW_CREDITS = 'solicitedNewCredits',
  WITH_CREDITS_AND_NOT_EXPIRED = 'withCreditsAndNotExpired',
  TREATMENT_VALIDITY_DISAPPROVED = 'treatmentValidityDisapproved',
  WITHOUT_CREDITS_AND_NOT_EXPIRED = 'withoutCreditsAndNotExpired',
  SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST = 'solicitedNewCreditsAndWaitingMedicalRequest',
}

interface QuestionPatientEligibility extends Question {
  id: number
  category?: CategoriesQuestion
}

const {
  SOLICITED_NEW_CREDITS,
  WITH_CREDITS_AND_NOT_EXPIRED,
  TREATMENT_VALIDITY_DISAPPROVED,
  WITHOUT_CREDITS_AND_NOT_EXPIRED,
  SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST,
} = CategoriesQuestion

export const handleQuestions = (daysToAuthorizeProcedure: number | null): QuestionPatientEligibility[] => [
  {
    id: 1,
    title: 'O que é um crédito de convênio?',
    text: 'Um crédito adquirido significa que você tem direito a agendar uma consulta com custo coberto pelo seu convênio.',
    category: WITH_CREDITS_AND_NOT_EXPIRED,
  },
  {
    id: 2,
    title: 'Tenho direito a quantos créditos no total?',
    text: <>A quantidade anual de créditos varia de acordo com o convênio e o quadro do paciente mediante pedido médico. Independente do limite anual, ou da quantidade total de consultas prescritas em seu pedido médico, os créditos para agendamento <strong>sempre são liberados em partes</strong>, para serem utilizados dentro de um prazo de tempo, de acordo com o que foi negociado entre a Telavita e o seu convênio.</>,
    category: WITH_CREDITS_AND_NOT_EXPIRED,
  },
  {
    id: 3,
    title: 'Posso utilizar todos os créditos disponíveis para agendar consultas futuras de uma só vez?',
    text: <>Sim. Uma vez liberados em sua conta na Telavita, os créditos podem ser usados para agendar várias consultas de uma só vez. Aconselhamos o agendamento de <strong>1 consulta por semana</strong>. Fique de olho na data de validade deles.<br /><br /><strong>Dica:</strong> quando for a primeira vez com um psicólogo, não agende mais do que uma consulta, até entender se você realmente irá continuar com ele.</>,
    category: WITH_CREDITS_AND_NOT_EXPIRED,
  },
  {
    id: 4,
    title: 'Como funciona a renovação de créditos?',
    text: <>Assim que você realizar todas as consultas agendadas, pediremos a liberação de novos créditos junto ao convênio e em <strong>até {daysToAuthorizeProcedure} dias úteis</strong> te avisaremos por e-mail e SMS.</>,
  },
  {
    id: 5,
    title: 'Por que o convênio não libera mais créditos de uma só vez?',
    text: 'A limitação do número de créditos e a data de validade são determinadas em negociação do seu convênio com a Telavita. Portanto, os créditos sempre serão liberados em partes, conforme você realiza todas as consultas agendadas.',
    category: WITHOUT_CREDITS_AND_NOT_EXPIRED,
  },
  {
    id: 6,
    title: 'Ainda possuo créditos mas a data de validade deles expirou. O que devo fazer?',
    text: <>Créditos com data de validade vencida não podem mais ser utilizados. Neste caso, a renovação depende de você clicar no botão <strong>"solicitar novos créditos"</strong> em seu painel do convênio. Assim, encaminhamos o pedido de liberação de novos créditos junto ao seu convênio. A aprovação leva até <strong>{daysToAuthorizeProcedure} dias úteis</strong>, e você será avisado por e-mail e SMS.</>,
  },
  {
    id: 7,
    title: 'Se eu cancelar uma consulta agendada, terei o crédito de volta?',
    text: <>Sim. Mas para obter o crédito de volta, a consulta deve ser cancelada com, no mínimo, <strong>24 horas de antecedência</strong> do horário agendado. A data de validade do crédito devolvido continuará sendo a mesma de antes.</>,
    category: WITH_CREDITS_AND_NOT_EXPIRED,
  },
  {
    id: 8,
    title: 'Por que preciso aguardar o convênio aprovar meu pedido médico para agendar consultas?',
    reducedTitle: 'Por que preciso aguardar a aprovação do convênio?',
    text: <>Embora seu convênio seja credenciado à Telavita, ele requer o encaminhamento do seu pedido médico para poder avaliar a quantidade de créditos que devem ser liberados. Este processo pode levar <strong>até {daysToAuthorizeProcedure} dias úteis</strong>. Assim que o convênio aprova, notificamos você por e-mail e SMS.</>,
    category: SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST,
  },
  {
    id: 9,
    title: `O que fazer se não houver aprovação do pedido médico ou renovação de créditos dentro do prazo de até ${daysToAuthorizeProcedure} dias úteis?`,
    reducedTitle: `O que fazer se não houver retorno após ${daysToAuthorizeProcedure} dias úteis?`,
    text: 'Neste caso, entre em contato com a nossa equipe de atendimento pelo chat, disponível no canto direito do nosso site.',
    category: SOLICITED_NEW_CREDITS,
  },
  {
    id: 10,
    title: 'Por qual motivo o pedido médico ou a renovação de créditos é reprovada? Há algo que possa ser feito, neste caso?',
    reducedTitle: 'Por que foi reprovada? Há algo que possa ser feito?',
    text: <>O <strong>pedido médico</strong> pode ser reprovado se houver informações incorretas ou incompletas, como número do CID, descrição do CID, CRM e UF do médico. Neste caso, <strong>revise o pedido médico</strong>, certificando-se de que todas estas informações estão presentes, corretas e completas, e reenvie para a nossa equipe de atendimento pelo chat, para que possamos fazer uma nova tentativa.<br /><br />A <strong>renovação de créditos</strong> pode ser reprovada caso você já tenha atingido o limite anual de sessões realizadas pelo seu convênio. Outra possibilidade é seu plano ter mudado - neste caso, entre em contato com a nossa equipe de atendimento para atualizar seu cadastro para o novo plano em questão.</>,
    category: TREATMENT_VALIDITY_DISAPPROVED,
  },
  {
    id: 11,
    title: 'O que acontece com meus créditos se eu sair do convênio?',
    text: 'Os créditos são válidos apenas enquanto você é elegível ao convênio. Caso você ainda possua créditos ao ser desligado do seu convênio, eles automaticamente perdem a validade.',
  },
]