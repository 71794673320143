import React from "react";

const UploadFile = ({
  fill = "#c1c1c1",
  height = "24",
  viewBox = "0 0 24 24",
  width = "24",
}) => (
  <svg id="Grupo_1920" data-name="Grupo 1920" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Caminho_3608" data-name="Caminho 3608" d="M16.5,6V17.5a4,4,0,0,1-8,0V5a2.5,2.5,0,0,1,5,0V15.5a1,1,0,0,1-2,0V6H10v9.5a2.5,2.5,0,0,0,5,0V5A4,4,0,0,0,7,5V17.5a5.5,5.5,0,0,0,11,0V6Z" fill={fill}/>
    <path id="Caminho_3609" data-name="Caminho 3609" d="M0,0H24V24H0Z" fill="none"/>
  </svg>
);

export default UploadFile;