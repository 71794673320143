import React from "react";
import { Trans } from "@lingui/macro";

export const REPORT = [
  {
    code: "schedules",
    name: <Trans>Consultas</Trans>,
  },
  {
    code: "financiers",
    name: <Trans>Financeiro</Trans>,
  },
];