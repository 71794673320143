import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from 'components';

const FloatMenu = ({
  className,
  id,
  itemList,
}) => {
  return (
    <div id={id}
      className={`FloatMenu ${className ? className : ''}`}>
      {
        itemList.map((item, index) => {
          return (
            <div key={item.id ? item.id : index}
              className="FloatMenu__item"
              id={`float-menu-${item.code}`}
              onClick={item.func}>
              <Typography>
                {item.name}
              </Typography>
            </div>
          );
        })
      }
    </div>
  );
};

FloatMenu.propTypes = {
  id: PropTypes.string,
  itemList: PropTypes.arrayOf(PropTypes.object),
};

export default FloatMenu;