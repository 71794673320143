import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'

// COMPONENTS`
import Modal from '../Modal'
import NotificationStep from '../../../../components/NotificationStep'
import Row from '../../../../components/Row'
import { Typography, Button, Column, TextInput, Spinner } from 'components'

import * as actions from '../../../../redux/actions'
import InputValidation from '../../../../utils/InputValidation'

class ModalUploadMedicalRequest extends Component {

  static propTypes = {
    cardNumber: PropTypes.string,
    username: PropTypes.string,
    validityId: PropTypes.number,
  };

  static defaultProps = {
    username: '',
    cardNumber: '',
  };

  state = {
    isUploading: false,
    medicalRequest: {
      placeholder: 'Selecionar arquivo',
      value: null,
      isValid: true,
      message: null,
    },
  }

  isSubmitButtonEnabled = () => (
    this.state.medicalRequest.isValid &&
    (this.state.medicalRequest.value !== null &&
      this.state.medicalRequest.value !== '')
  )

  handlePictureInput = async(e) => {
    const getFile = e.target.files
    const imageTitle = getFile[0] ? getFile[0].name : 'Selecionar arquivo'

    const validation = new InputValidation(getFile, 'imageOrPdf')
    const imageIsValid = await validation.validate()

    this.setState({
      medicalRequest: {
        placeholder: imageTitle,
        value: imageIsValid.encodedFile,
        isValid: imageIsValid.success,
        message: imageIsValid.message,
      },
    })
  }

  submitMedicalRequest = async() => {
    const params = { treatment: { medical_request: this.state.medicalRequest.value } }
    this.setState({ isUploading: true })
    await this.props.onChangeMedicalRequest(this.props.validityId, params)
    this.setState({ isUploading: false })
  }

  clearMedicalRequestReducer = () => {
    this.props.onClearMedicalRequest()
  }

  handleCloseButton = () => {
    this.props.onSuccessAction()
    this.props.onHideModal()
  }

  renderMedicalRequestUploadForm = () => {
    const { username, cardNumber } = this.props
    const { medicalRequest } = this.state

    return (
      <Fragment>
        <div className="ModalUploadMedicalRequest__Data">
          <Row margin="1.5rem 0">
            <Row>
              <Column>
                <Typography align='center'><Trans>Paciente:</Trans>{' '}<Typography Tag="span" bold>{username}</Typography></Typography>
              </Column>
            </Row>
            <Row>
              <Column>
                <Typography align='center'><Trans>Carteirinha:</Trans>{' '}<Typography Tag="span" bold>{cardNumber}</Typography></Typography>
              </Column>
            </Row>
            <Row margin="20px 0 0">
              <Column>
                <div className="ModalUploadMedicalRequest--image-wrapper">
                  <TextInput
                    addIcon
                    iconProps={{ name: 'Clip', fill: '#dedede', height: '24px', width: '24px' }}
                    hasError={!medicalRequest.isValid}
                    messageError={medicalRequest.message}
                    name="medical-request"
                    placeholder={medicalRequest.placeholder}
                  />
                  <input
                    id="hiddenInput-file"
                    type='file'
                    onChange={(e) => this.handlePictureInput(e)}
                  />
                </div>
              </Column>
            </Row>
          </Row>
        </div>
        <Row>
          <Column>
            <Button
              disabled={!this.isSubmitButtonEnabled()}
              text={(<Trans>Enviar</Trans>)}
              id="send-medical-request"
              onButtonClick={this.submitMedicalRequest}
            />
          </Column>
        </Row>
      </Fragment>
    )
  }

  renderUploadSuccess = () => {

    const notificationStepContent = (
      <Row justify="center">
        <Typography bold
          align="center"
          style={{ maxWidth: '33rem' }}
          type="heading2">
          <Trans>
            Avaliação técnica anexada com sucesso!
          </Trans>
        </Typography>
      </Row>
    )

    return (
      <div className="ModalUploadMedicalRequest__Data">
        <NotificationStep
          buttonId="close-modal"
          btnText="Fechar"
          mainText={notificationStepContent}
          onSuccessClick={this.handleCloseButton}
          iconColor="#4ac326"
          iconName="Check"
          hasButton
        />
      </div>
    )
  }

  renderSpinner = () => (<Spinner height="250px" />)

  renderContentBasedOnUploadSuccess = () => {
    return this.props.medicalRequestSuccess ?
      this.renderUploadSuccess() :
      this.renderMedicalRequestUploadForm()
  }

  renderTitleBasedOnUploadSuccess = () => {
    return this.props.medicalRequestSuccess ? '' : <Trans>Anexar avaliação técnica</Trans>
  }

  render() {
    return (
      <div className="ModalUploadMedicalRequest">
        <Modal
          btnId="submit-medical-request"
          modalTitle={this.renderTitleBasedOnUploadSuccess()}
          hasButton={false}
          closeModal={this.handleCloseButton}
          extraClassName>
          {
            this.state.isUploading ?
              this.renderSpinner() :
              this.renderContentBasedOnUploadSuccess()
          }
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  medicalRequestSuccess: state.medicalRequests.success,
})

const mapDispatchToProps = dispatch => ({
  onHideModal: () => dispatch(actions.hideModal()),
  onChangeMedicalRequest: (id, params) => dispatch(actions.changeMedicalRequest(id, params, true)),
  onClearMedicalRequest: () => dispatch(actions.clearMedicalRequest()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalUploadMedicalRequest)
