import React from "react";

const Calendar = ({
  fill,
  height,
  viewBox = "-10 -10 45 45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} transform="translate(-149 -77)">
      <path d="M20 3h-1V1h-2v2H7V1H5v2H4a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2zm0 18H4V8h16z" className="cls-1" data-name="Path 2160" transform="translate(149 77)" />
      <path d="M0 0h2v2H0z" transform="translate(155 87)" />
      <path d="M0 0h2v2H0z" transform="translate(160 87)" />
      <path d="M0 0h2v2H0z" transform="translate(165 87)" />
      <path d="M0 0h2v2H0z" transform="translate(155 90.5)" />
      <path d="M0 0h2v2H0z" transform="translate(160 90.5)" />
      <path d="M0 0h2v2H0z" transform="translate(165 90.5)" />
      <path d="M0 0h2v2H0z" transform="translate(155 94)" />
      <path d="M0 0h2v2H0z" transform="translate(160 94)" />
      <path d="M0 0h2v2H0z" transform="translate(165 94)" />
    </g>
  </svg>
);

export default Calendar;