import React from 'react'
import clsx from 'clsx'
import { format } from 'date-fns'
import { Badge, DateDisplayer, FloatButton, List, Typography } from '@telavita-core/react-design-kit'
import pt from 'date-fns/locale/pt-BR'

import { DATE_DISPLAYER_STATUS } from 'settings/_dateDisplayerStatusSettings'

const ScheduleEvent = ({ event, actions = [] }) => {

  const isMedicalReturn = event.schedule.medical_return

  return (
    <List.Item 
      ripple={
        <div className='ProfessionalAgenda__List__Item__Ripple'>
          <DateDisplayer 
            date={new Date(event.start_date)}
            label={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.label}
            variant={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.variant}
            showDay={false}
            labelTextColor={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.labelTextColor}
            hourTextColor={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.hourTextColor}
            icon={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.icon}
            iconColor={DATE_DISPLAYER_STATUS[event.schedule.status_code]?.iconColor}
          />
        </div>
      }
      content={
        <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
          <div className={clsx({
            'ProfessionalAgenda__List__Item__Content': true,
            'ProfessionalAgenda__List__Item__Content__MedicalReturn': isMedicalReturn
          })}>
            <div>
              {event.schedule.consultation_evaluation ?
                <Typography variant="content2" color='success'>
              Consulta de avaliação
                </Typography>
                : event.schedule.session_number ?
                  <Typography variant='content2'>{event.schedule.session_number}ª consulta com </Typography>
                  :
                  <Typography variant="content2">
                  Consulta com
                  </Typography>
              }
              <Typography variant='content2' weight='bold'>{event.schedule.patient.full_name}</Typography>
            </div>
            {isMedicalReturn && (
              <div className='ProfessionalAgenda__List__Item__Badge'>
                <Badge colorScheme='grey'>
                  <Typography variant='content3' weight='bold' inheritColor>
                    CONSULTA DE RETORNO  
                  </Typography>
                </Badge>
              </div>
            )}
            <div className='ProfessionalAgenda__List__Item__PlanData'>
              <div className='ProfessionalAgenda__List__Item__PlanData__Wrapper'>
                <Typography variant='content3'>ID {event.schedule.id}</Typography>
                <Typography variant='content3'>
                  {event.schedule.plan.full_name}
                </Typography>
              </div>
              {event.schedule.reservation_date && (
                <Typography variant='content3' weight='bold' customClassName='ProfessionalAgenda__List__Item__PlanData__Reservation'>
                  RESERVA: {format(new Date(event.schedule.reservation_date), 'EEEEEE HH', { locale: pt }).toUpperCase()}h
                </Typography>
              )}
            </div>
          </div>
          {actions.length > 0 && (
            <div className='ProfessionalAgenda__List__Item__FloatButton'>
              <FloatButton
                actions={actions}
              />
            </div>
          )}
        </div>
      }
    />
  )
}

export {
  ScheduleEvent
}