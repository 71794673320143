import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Textarea extends Component {

  state = { detail: '' }
  componentDidMount = () => {

    // this.handleChange(event);

  }
  handleChange = (event) => {

    this.setState({ detail: event.target.value });
  };

  render() {

    const {
      disabled,
      inputHeight,
      onChange,
      name,
      placeholder,
      required,
      size,
      hasError,
      messageError,
      onFocusOut,
      value,
    } = this.props;

    return (
      <div className="Textarea">
        <textarea resize='none' className='Textarea__inputContent'
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder}
          onBlur={onFocusOut}
          onChange={onChange}
          required={required}
          size={size ? size : null}
          style={{ 'height': `${inputHeight}` }}
          value={value}
        />
        {
          hasError &&
          <div className="messageError">
            <span className="spanError"> {messageError} </span>
          </div>
        }
      </div>
    );
  }
}

Textarea.propTypes = {
  disabled: PropTypes.bool,
  hasError: PropTypes.any,
  iconProps: PropTypes.any,
  messageError: PropTypes.any,
  name: PropTypes.string,
  inputHeight: PropTypes.string,
  placeholder: PropTypes.any,
  required: PropTypes.bool,
  size: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onFocusOut: PropTypes.func,
};

Textarea.defaultProps = {
  disabled: false,
  hasError: false,
  inputHeight: '17.2rem',
  placeholder: "Placeholder text",
  type: 'text',
};

export default Textarea;