import React from 'react';
import PropTypes from 'prop-types';

function Row({
  align,
  children,
  className,
  direction,
  id,
  height,
  isMansory,
  justify,
  margin,
  onClick,
  padding,
  wrap,
  width,
  maxWidth,
  position,
}) {
  let compClassName = 'row';

  const style = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
    margin: '0',
    padding: '0',
    maxWidth: 'initial',
    position: 'initial',
  };

  if (align) style.alignItems = align;
  if (direction) style.flexDirection = direction;
  if (justify) style.justifyContent = justify;
  if (height) style.height = height;
  if (wrap) style.flexWrap = wrap;
  if (width) style.width = width;
  if (maxWidth) style.maxWidth = maxWidth;
  if (position) style.position = position;

  if (padding) {
    style.padding = padding;
  }

  if (margin) {
    style.margin = margin;
  }

  if (className) compClassName = `${compClassName} ${className}`;

  if (isMansory) {
    compClassName = `row-mansory`;
  }

  return (
    <div className={compClassName}
      id={id}
      style={style}
      onClick={onClick}>
      {children}
    </div>
  );

}

Row.propTypes = {
  align: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'initial',
  ]),
  direction: PropTypes.oneOf([
    'column',
    'column-reverse',
    'row',
    'row-reverse',
    'initial',
    'inherit',
  ]),
  id: PropTypes.string,
  justify: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'initial',
    'inherit',
  ]),
  wrap: PropTypes.oneOf([
    'nowrap',
    'wrap',
    'wrap-reverse',
    'initial',
    'inherit',
  ]),
  padding: PropTypes.string,
  margin: PropTypes.string,
};

export default Row;

