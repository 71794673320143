import { 
  CLEAR_PARTNER_PROFILES, 
  GET_PARTNER_PROFILES, 
} from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

const initialState = { profiles: [] };

const getPartnerProfiles = (state, payload) => {
  return updateState(state, { profiles: payload.profiles });
};

const clearPartnerProfiles = (state) => {
  return updateState(state, initialState);
};

const profilesReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_PARTNER_PROFILES: return clearPartnerProfiles(state);
    case GET_PARTNER_PROFILES: return getPartnerProfiles(state, action.payload);
    default: return state;
  }
};

export default profilesReducer;