import qs from 'querystring'

import {
  CLEAR_ORDER,
  CREATE_ORDER,
  GENERATE_ORDER,
  GET_ORDERS,
  SAVE_ORDER,
  GET_ORDER_LIST,
  CLEAR_ORDER_LIST,
} from "./_actionTypes";

import axios from "../../settings/axios";
import * as routes from "../../settings/_routesSettings";
import * as profiles from "../../settings/_profileSettings";
import { STS20006, STS20001, STS20002, STS20003, STS20005, STS20004 } from "settings/_paymentStatusSettings"
import { history } from "../../routers/AppRouter";
import { getRoleFromProfile } from "../../utils/textTransformations";
import { apiEndpoints } from "../../settings/_apiSettings";
import { asyncError } from "./";
import { createCheckout } from "./checkoutActions";
import { BY_PASS } from "settings/_paymentMethodsSettings";

/** Action to create order - used in Professional Profile view */
export const createOrder = ({
  product_group_code,
  username,
  plan_code,
  profile_role,
  person_profile_product_appointment_id,
  schedules,
}, redirect) => async dispatch => {
  const fetchUrl = apiEndpoints.ORDERS();
  const params = {
    ...(person_profile_product_appointment_id && { person_profile_product_appointment_id }),
    ...(plan_code && { plan_code }),
    ...(product_group_code && { product_group_code }),
    ...(profile_role && { profile_role }),
    ...(schedules && { schedules }),
    ...(username && { username }),
  };

  try {
    const response = await axios.post(fetchUrl, { ...params });
    const orderId = response.data.id;
    const instantCheckout = response.data.instant_checkout;

    const payload = { instantCheckout, orderId };
    dispatch({
      type: CREATE_ORDER,
      payload,
    });

    if (instantCheckout) {
      await dispatch(createCheckout(orderId, { payment_method: BY_PASS }))
        .then(data => {
          if (data && data.payload && data.payload.checkoutStatus === "OK") {
            if (redirect)
              history.push(routes.PLAN_CHECKOUT);
          }
        });
    } else {
      if (redirect)
        history.push(routes.PAYMENT);
    }


  } catch (err) {
    console.log(err);
    dispatch(asyncError(err));
    return err;
  }

  return true
};

/** Action to save the order when user isn't authenticated */
export const saveOrder = order => dispatch => {
  const payload = { order };

  dispatch({
    type: SAVE_ORDER,
    payload,
  });
};

/** Action to generate the order after user autehntication - used in Login page */
export const generateOrder = (order = {}, user = {}) => dispatch => {
  let newOrder = {};
  let newSchedules = [];

  try {
    if (user && user.session.profile_role_active !== profiles.PATIENT) history.push(routes.HOME_PSIC);

    const userId = {
      person_profile_id: user.person_profile.find(
        profile => getRoleFromProfile(profile.profile_code) === profiles.PATIENT
      ).id,
    };

    order.schedules.map(item => {
      let addGuest = {
        ...item,
        guests: [...item.guests, userId],
      };
      return newSchedules.push(addGuest);
    });

    newOrder = {
      ...order,
      schedules: newSchedules,
    };

    const payload = { newOrder };

    dispatch({
      type: GENERATE_ORDER,
      payload,
    });

    dispatch(createOrder(newOrder, true));
  } catch (err) {
    dispatch(asyncError(err));
  }
};

/** Action to clear the order */
export const clearOrder = () => {
  return { type: CLEAR_ORDER };
};

export const clearOrderList = () => {
  return { type: CLEAR_ORDER_LIST };
};

export const getOrdersList = ({
  query,
  date_created__gte,
  date_created__lte,
  status_code,
}) => async dispatch => {
  const params = {
    ...(query && { query }),
    ...(date_created__gte && { date_created__gte }),
    ...(date_created__lte && { date_created__lte }),
    ...(status_code ? { status_code } : {
      status_code: [
        STS20006,
        STS20002,
        STS20003,
        STS20005,
        STS20001,
        STS20004,
      ],
    }),
  };

  try {
    const response = await axios.get(
      apiEndpoints.ALL_ORDER_LIST,
      { 
        params,
        paramsSerializer: params => {
          return qs.stringify(params)
        },
      });


    let payload = { orderList: [...response.data] };

    return dispatch({
      type: GET_ORDER_LIST,
      payload: payload,
    });
  } catch (err) {
    let payload = { orderList: [] };

    console.log(err);
    dispatch(asyncError(err));

    return dispatch({
      type: GET_ORDER_LIST,
      payload: payload,
    });
  }
};
export const getOrders = id => async dispatch => {
  const response = await axios.get(apiEndpoints.ORDERS(id));

  let payload;

  switch (response.status) {
    case "OK":
      try {
        payload = {
          ...response.data,
          professionalName: response.data.items[0].seller_person_profile.person_general.full_name,
          professionalFriendlyUrl: response.data.items[0].seller_person_profile.person_general.friendly_url ,
          status: response.data.status_code,
        };
        return dispatch({
          type: GET_ORDERS,
          payload,
        });
      } catch {
        payload = {
          total: 0.0,
          discount: 0,
          items: [],
          professionalName: "",
          status: "",
        };

        return dispatch({
          type: GET_ORDERS,
          payload,
        });
      }

    case "ZERO_RESULTS":
      payload = {
        total: 0.0,
        discount: 0,
        items: [],
        professionalName: "",
        status: "",
      };

      return dispatch({
        type: GET_ORDERS,
        payload,
      });

    default:
      dispatch(asyncError());
      break;
  }
};

export const deleteOrderItem = (orderId, itemId) => async dispatch => {
  const response = await axios.delete(
    apiEndpoints.ORDERS_ITEM(orderId, itemId)
  );

  switch (response.status) {
    case "OK":
      dispatch(getOrders(orderId));
      break;
    default:
      dispatch(asyncError());
      break;
  }
};
