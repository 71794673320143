import React, { Component } from "react";
import { connect } from "react-redux";
import { Typography, Button, Spinner } from 'components';
import Dropdown from "components/Dropdown";
import ModalGeneric from "../ModalGeneric";
import { getTimeH, toDayMonthYear } from '../../../../utils/dateTimeHandlers';
import { changePerson, clearOnePerson } from "../../../../redux/actions";

class EnableNewPatientsModal extends Component {

  state = {
    loading: false,
    enablePatientsValue: true
  };

  enableOptions = [
    { id: 'ATIVO', name: "Ativo" },
    { id: 'INATIVO', name: "Inativo" }
  ];

  changeEnablePatients = (value) => {
    this.setState({
      enablePatientsValue: value === 'ATIVO'
    });
  }

  changeEnableNewPatients = () => {
    this.setState({ loading: true });
    this.props.onChangePerson(
      this.props.professionalSlug,
      this.props.professionalRole,
      { enable_new_patients: this.state.enablePatientsValue }
    );
  }

  resetState = () => {
    this.setState({ loading: false });
    this.props.onClearPerson();
  }

  render() {
    const {
      professionalName,
      professionalDocuments,
      professionalDocumentValue,
      enableNewPatients,
      dateUpdated,
      userNameUpdated,
      success,
      reloadProfessionalList
    } = this.props;

    const { loading, enablePatientsValue } = this.state;

    const date = new Date(dateUpdated);
    const dayUpdated = toDayMonthYear(date);
    const hourUpdated = getTimeH(date);

    const professionalInfo = (
      <>
        <b>{professionalName}</b><br />
        {`${professionalDocuments} ${professionalDocumentValue}`}
      </>
    );

    return (
      <ModalGeneric
        modalTitle="Agendamento para novos pacientes"
        modalSubtitle={professionalInfo}
        onSuccessClose={() => {
          this.resetState();
          reloadProfessionalList();
        }}
        feedbackShow={success}
        feedbackType="success"
        feedbackMessage={`O agendamento para novos pacientes com este profissional foi ${enablePatientsValue ? "ativado" : "inativado"}.`}
        feedbackContent={
          <div style={{ marginTop: 20 }}>
            {professionalInfo}
          </div>
        }
      >
        {
          loading && success !== true ? 
            <Spinner height="150px" />
          : 
            <>
              <ModalGeneric.Group>
                <Typography>
                  <b>Caso seja inativado, o profissional deixará de aparecer na vitrine para
                  novos pacientes.</b> No entanto, sua agenda continuará disponível para os
                  pacientes já atendidos, para que eles possam marcar novas consultas.
                </Typography>
              </ModalGeneric.Group>
              <ModalGeneric.Group>
                <ModalGeneric.GroupItem>
                  <Dropdown
                    onChange={this.changeEnablePatients}
                    onSelect={this.changeEnablePatients}
                    preSelectedItem={enableNewPatients ? 'ATIVO' : 'INATIVO'}
                    options={this.enableOptions}
                  />
                </ModalGeneric.GroupItem>
                <ModalGeneric.GroupItem>
                  <Button
                    text="SALVAR"
                    onButtonClick={this.changeEnableNewPatients}
                    disabled={enablePatientsValue === enableNewPatients}
                  />
                </ModalGeneric.GroupItem>
              </ModalGeneric.Group>
              {
                userNameUpdated &&
                <Typography type="custom" className="EnableNewPatientsModal__UpdatedInfo">
                  <b>Última alteração:</b> {dayUpdated}, às {hourUpdated}, {userNameUpdated}
                </Typography>
              }
            </>
        }
      </ModalGeneric>
    );
  }
}

const mapStateToProps = state => {
  return {
    success: state.persons.newPerson.success || false
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onChangePerson: (slug, role, putData) => dispatch(changePerson(slug, role, putData)),
    onClearPerson: (slug, role, putData) => dispatch(clearOnePerson()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnableNewPatientsModal);
