import React from 'react';
import { useDispatch } from 'react-redux';

// STYLE
import './SuccessModal.sass';

// STORE
import * as actions from 'redux/actions';

// COMPONENTS
import { Typography, Button, CoreIcons } from 'components';
import ModalGeneric from "../ModalGeneric";

const SuccessModal = ({ title }) => {
  const dispatch = useDispatch();
  //
  function closeModal() {
    dispatch(actions.hideModal());
  }

  return (
    <ModalGeneric>
      <div className="SuccessModal">
        <div className="SuccessModal__top">
          <CoreIcons name="Done" width="50" height="50" />
          <Typography align="center" type=" subtitle">{title}</Typography>
        </div>
        <Button
          id={"close-success-modal"}
          loading={false}
          onButtonClick={() => closeModal()}
          text="FECHAR"/>
      </div>
    </ModalGeneric>
  )
};

export {
  SuccessModal
}