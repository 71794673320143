const colList = [
  {
    className: 'one',
    col: 1,
  },
  {
    className: 'two',
    col: 2,
  },
  {
    className: 'three',
    col: 3,
  },
  {
    className: 'four',
    col: 4,
  },
  {
    className: 'five',
    col: 5,
  },
  {
    className: 'six',
    col: 6,
  },
  {
    className: 'seven',
    col: 7,
  },
  {
    className: 'eight',
    col: 8,
  },
  {
    className: 'nine',
    col: 9,
  },
  {
    className: 'ten',
    col: 10,
  },
  {
    className: 'eleven',
    col: 11,
  },
  {
    className: 'twelve',
    col: 12,
  },
];

export default colList;