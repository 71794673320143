export const GENDER = [
  {
    code: 'FEM',
    name: 'Feminino',
  },
  {
    code: 'MAS',
    name: 'Masculino',
  },
  {
    code: 'NDA',
    name: 'Outro',
  },
]

export const GENDER_FILTERS = [
  {
    code: 'ALL',
    name: 'Tanto faz',
    iconName: 'SexInfinite',
    id: 1,
  },
  {
    code: 'FEM',
    name: 'Mulher',
    iconName: 'SexFemale',
    id: 2,
  },
  {
    code: 'MAS',
    name: 'Homem',
    iconName: 'SexMale',
    id: 3,
  },

]

export const ACTIVE = 'STS24001'
export const AWAITING_PIN = 'STS24002'
export const AWAITING_MEDICAL_REQUEST = 'STS24017'
export const INCOMPLETE_ACCOUNT = 'STS24003'
export const INACTIVE = 'STS24004'
export const FINISHED = 'STS24012'
export const REPROVED = 'STS24016'
export const INVALIDATED = 'STS24018'

export const PERSON_STATUS = {
  ACTIVE: {
    code: 'STS24001',
    weight: 'positive',
  },
  AWAITING_PIN: {
    code: 'STS24002',
    weight: 'neutral',
  },
  AWAITING_MEDICAL_REQUEST: {
    code: 'STS24017',
    weight: 'neutral',
  },
  INCOMPLETE_ACCOUNT: {
    code: 'STS24003',
    weight: 'negative',
  },
  INACTIVE: {
    code: 'STS24004',
    weight: 'negative',
  },
  AWAITING_ELIGIBILITY: {
    code: 'STS24005',
    weight: 'neutral',
  },
  AWAITING_VALIDITY: {
    code: 'STS24006',
    weight: 'neutral',
  },
  ELIGIBLE: {
    code: 'STS24007',
    weight: 'positive',
  },
  VALID: {
    code: 'STS24008',
    weight: 'positive',
  },
  WAITING_CONSULTATION_REQUEST: {
    code: 'STS24013',
    weight: 'neutral',
  },
  FINISHED: {
    code: 'STS24012',
    weight: 'done',
  },
  VALID_WITHOUT_MEDICAL_REQUEST: {
    code: 'STS24015',
    weight: 'positive',
  },
  REPROVED: {
    code: 'STS24016',
    weight: 'negative',
  },
  INVALIDATED: {
    code: 'STS24018',
    weight: 'negative',
  }
}