import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Error {
  response: {
    status: number
  }
}

enum PlanGroupFrequency {
  WEEKLY = 1,
  BIWEEKLY = 2
}

interface IMonthData {
  month: string
  average: number
}

interface ISchedulesAverage {
  monthly: IMonthData[]
}

interface IScheduleAverageParams {
  startDate: string
  endDate: string
  planGroupFrequency: PlanGroupFrequency
}

export const getSchedulesAverage = ({
  startDate,
  endDate,
  planGroupFrequency
}: IScheduleAverageParams): Promise<ISchedulesAverage> => {
  const endpoint = apiEndpoints.METRICS_PROFESSIONALS_SCHEDULE_AVERAGE

  const params = {
    start_date: startDate,
    end_date: endDate,
    plan_group_frequency: planGroupFrequency
  }

  return new Promise<ISchedulesAverage> ((resolve, reject) => {
    axios.get<ISchedulesAverage>(endpoint, { params })
      .then(response => resolve(response.data))
      .catch((error: Error) => 
        reject(error?.response?.status)
      )
  })
}