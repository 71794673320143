import * as dateHandler from './dateTimeHandlers';

export const formatCartItems = (items) => {
  const formatedItems = items.map(item => {

    const itemDate = dateHandler.datetimeObject(item.schedule.start_date);
    const itemDuration = item.appointment.product.product_general.duration;
    const itemId = item.id;
    const itemPrice = formatFloatToReal(item.price);

    return {
      duration: itemDuration,
      id: itemId,
      price: itemPrice,
      ...itemDate,
    };
  });
  return formatedItems;
};

export const formatFloatToReal = (value) =>
  parseFloat(value)
    .toFixed(2)
    .toString()
    .replace('.', ',');

export const currencyNormalization = (value) => { 
  let newValue = value.replace(',', '');
  newValue = Number(`00${newValue}`).toString();

  let decimal = newValue.slice(-2);
  let inteiro = newValue.slice(0, -2);

  if (inteiro.length < 1) {
    inteiro = '0';
  }
  if (decimal.length < 2) {
    decimal = `0${decimal}`;
  }

  return `${inteiro},${decimal}`;
};