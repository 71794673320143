/*
* Actions exporter
*/

export {
  clearAvailability,
  clearProfessionals,
  getAllAvailability,
  getAvailability,
  getScheduledDays,
  professionalsAvailable,
} from './agendaActions'
export {
  asyncError,
  asyncRequest,
  asyncSuccess,
  cleanErrorList,
  cleanGenericError,
  setGenericError,
} from './asyncActions'
export {
  authChangePlan,
  authLogin,
  authLogout,
  authUser,
  authFailed,
  authSwap,
} from './authActions'
export { getBlocks } from './blocksActions'
export { getCreditCards, saveCreditCard } from './creditCardActions'
export { createCheckout, clearCheckout } from './checkoutActions'
export { getDocuments } from './documentsActions'
export { recoverEmail } from './emailRecoveryActions'
export {
  setDateFilter,
  setStatusFilter,
  setTextFilter,
  clearFilter,
  genderStatus,
  selectDay,
  startHour,
  endHour,
  getDateTimeFiltersData,
  getPriceRange,
} from './filtersActions'
export { hideModal, showModal } from './modalActions'
export {
  clearOrder,
  createOrder,
  generateOrder,
  getOrders,
  deleteOrderItem,
  saveOrder,
  getOrdersList,
  clearOrderList,
} from './ordersActions'
export { getCancelReason } from './CancelActions'
export {
  changePerson,
  changePersonProfile,
  clearConcatedProfessionals,
  clearOnePerson,
  clearPersons,
  clearPersonsProfessionals,
  clearProfessionalData,
  getPerson,
  listPersons,
  listPatients,
  listProfessionals,
  listPersonsProfessionals,
  getPersonPlans,
  getPersonProfileRole,
  getPersonProfessionalHistory,
  setPerson,
  setPersonPlans,
  setPersonProfile,
  setProfessional,
} from './personsActions'
export { setPayment, clearPayment } from './paymentActions'
export { cleanProducts, getProducts } from './productsActions'
export {
  registerGroupSelection,
  registerInfoGroup,
  registerInfoPersonal,
  registerPhoneCheck,
} from './registerActions'
export {
  clearBlocksData,
  addDocumentsData,
  addGeneralData,
  addBiographyData,
  addEducationData,
  addMediaData,
  addProductsData,
  addSkillsData,
  addSubspecialtiesData,
  cleanBlocksEdition,
  editBlocksData,
  addLanguagesData
} from './registerBlocksActions'
export {
  cleanSchedules,
  getSchedules,
  getProfessionalEvents,
  setScheduleStatus,
  updateSchedule
} from './schedulesActions'
export { cleanSkills, getSkills, selectSkills } from './skillsActions'
export { getSlot, removeSlot, clearSlotStatus } from './slotActions'
export { getFilterStatus, clearFilters } from './filterStatusActions'
export { generateReport, clearReport } from './reportActions'
export { getPartnerProfiles, clearPartnerProfiles } from './profilesActions'
export { setSurveyAnswer } from './surveyAction'
export { getPrice } from './priceFilterActions'
export {
  changeMedicalRequest,
  clearMedicalRequest,
  getMedicalRequest,
  sendProcedureRequest,
  createMedicalRequest,
  getDisapproveDetails
} from './medicalRequestActions'
export {
  getPlans,
  getPlanGroups,
  getAllPlansGroups,
  getPlanGroupEligibles, 
  getPlanGroupEligiblesFile,
  clearPlanGroupEligiblesFile
} from './plansActions'