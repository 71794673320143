import React from "react";

const ArrowDown = ({
  fill = "#acacac",
  height = "24px",
  viewBox = "0 0 24 24",
  width = "24px",
}) => (
    <svg
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g transform="translate(-170.457 -425)">
        <path transform="translate(170.457 425)" />
        <path fill={fill} d="M111-20l5,5,5-5Z" transform="translate(66.459 455)" />
      </g>
    </svg>
  )

export default ArrowDown;