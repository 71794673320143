
import axios from 'axios';

// ACTIONS
import * as myPatientsActions from './actions';

const mainURL =  process.env.REACT_APP_API_URL; // main route prefix set by environment

const getMypatientsRequest = (token, params) => {
  const header = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: { ...params }
  };

  return function (dispatch) {
    dispatch(myPatientsActions.loadPatients());
    axios.get(mainURL + '/v0/persons/patients/', header)
      .then(response => {
        dispatch(myPatientsActions.loadPatientsSuccess(response.data.data));
      })
      .catch(response => {
        dispatch(myPatientsActions.loadPatientsFailure(response.error));
      });
  };
};

export {
  getMypatientsRequest
}