import { useMutation, useQueryClient } from '@tanstack/react-query'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { ADMIN_SCHEDULES_QUERY_KEY } from 'settings/_queryKeySettings'
interface RescheduleData {
  id: number
  start_date: string
  end_date: string
}

interface UseReschedule {
  handleReschedule: (schedule: RescheduleData) => void
  isSuccess: boolean
}

async function rescheduleService({ id, start_date, end_date }: RescheduleData) {
  const fetchUrl = apiEndpoints.SCHEDULE_DETAIL(id)
  const response = await axios.put(fetchUrl, { start_date, end_date })
  return response
}

export function useReschedule(): UseReschedule {
  const queryClient = useQueryClient()
  const { mutate: handleReschedule, isSuccess } = useMutation({
    mutationFn: (scheduleData: RescheduleData) => rescheduleService({
      id: scheduleData.id,
      start_date: scheduleData.start_date,
      end_date: scheduleData.end_date,
    }),
    onSuccess: () => {
      return queryClient.resetQueries([ADMIN_SCHEDULES_QUERY_KEY])
    }
  })
  
  return { handleReschedule, isSuccess }
}