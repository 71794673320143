import React from "react";

const ErrorIcon = ({
  fill = "#ff4949",
  height = "50.002",
  viewBox = "0 0 49.998 50.002",
  width = "49.998",
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="Subtração_11" data-name="Subtração 11" d="M8993,14731a24.976,24.976,0,1,1,17.685-7.316A24.844,24.844,0,0,1,8993,14731Zm0-21.477h0l6.477,6.474,3.523-3.523-6.474-6.477,6.474-6.474-3.523-3.527-6.478,6.478-6.474-6.478-3.526,3.527,6.477,6.474-6.477,6.477,3.526,3.523,6.473-6.474Z" transform="translate(-8968 -14681)" fill={fill} />
    </svg>

);

export default ErrorIcon;