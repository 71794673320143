import { GetSchedulesResponse } from 'services/schedules/getSchedules'

interface UpdateScheduleParams {
  data: GetSchedulesResponse[]
  scheduleId: number
  newValue: {
    [key: string]: unknown
  }
}

export function updateSchedule({
  data,
  scheduleId,
  newValue
}: UpdateScheduleParams): GetSchedulesResponse[] {
  return data.map(page => ({
    ...page,
    data: {
      schedules: page.data.schedules.map(schedule => {
        if (schedule.id === scheduleId) {
          return {
            ...schedule,
            ...newValue
          }
        }
        return schedule
      })
    } 
  }))
}