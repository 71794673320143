import {
  CLEAR_ORDER,
  CLEAR_ORDER_LIST,
  CREATE_ORDER,
  GENERATE_ORDER,
  GET_ORDERS,
  SAVE_ORDER,
  GET_ORDER_LIST,
} from './../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

export const initialState = {
  checkout: true,
  total: 0.00,
  discount: 0,
  items: [],
  orderId: '',
  professionalName: '',
  status: '',
  order: {},
  orderList: [],
};

const createOrder = (state, { payload }) => {
  return updateState(state, { ...payload });
};
const getOrderList =(state, { payload }) => {
  return updateState(state, { orderList: payload.orderList });
};
const generateOrder = (state, { payload }) => {
  return updateState(state, { order: payload.order });
};
const clearOrderList =(state)=>{
  return updateState(state, { orderList: [] });
};
const getOrders = (state, { payload }) => {
  return updateState(state, { ...payload });
};

const clearOrder = (state) => {
  return updateState(state, { order: {} });
};

const saveOrder = (state, { payload }) => {
  return updateState(state, { order: payload.order });
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_ORDER: return clearOrder(state);
    case CREATE_ORDER: return createOrder(state, action);
    case CLEAR_ORDER_LIST: return clearOrderList(state);
    case GENERATE_ORDER: return generateOrder(state, action);
    case GET_ORDERS: return getOrders(state, action);
    case SAVE_ORDER: return saveOrder(state, action);
    case  GET_ORDER_LIST: return getOrderList(state, action);
    default: return state;
  }
};

export default ordersReducer;