import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// COMPONENTS
import { Typography } from '@telavita-core/react-design-kit'
import { ReactComponent as IconLocation } from 'static/svg/location-icon.svg'
import { ReactComponent as UTCIcon } from 'static/svg/icon-utc.svg'

function TimestampTag({ card }) {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

  let timeZoneLocal = (String(timezone).slice(String(timezone).search(/\//) + 1, timezone?.length)).replace('_', ' ')
  
  const timezoneLabel = !timezone ? 'Não definido' : timeZoneLocal

  return (
    <div className={classNames('TimestampTag', { 'TimestampTag--card': card })}>
      { card ? <UTCIcon /> : null}

      <div className={classNames('TimestampTag__timezone', { 'TimestampTag__timezone--card': card })}>
        <div className='TimestampTag__timezone__wrapper'>
          <IconLocation className='TimestampTag__timezone__icon'/>
          <Typography center variant="content3" inheritColor weight='bolder'>
            Fuso horário:&nbsp;
          </Typography>
          <Typography center variant="content3" inheritColor>
            {timezoneLabel}
          </Typography>
        </div>
        <Typography center variant="content3" inheritColor>&nbsp;(padrão 00:00 a 23:59)</Typography>
      </div>
    </div>
  )
}

TimestampTag.propTypes = {
  card: PropTypes.bool,
}

export { TimestampTag }