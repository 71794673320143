import { SET_ANSWER_RESPONSE  } from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';
  
const initialState = {};

const setSurveyAnswer= (state,  payload ) => {
  return updateState(state, { questions: payload.questions });
};

const surveyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANSWER_RESPONSE : return setSurveyAnswer(state, action.payload);
    default: return state;
  }
};
  
export default surveyReducer;