import React from 'react'
import { Spinner } from '@telavita-core/react-design-kit'
import { ReactComponent as SearchIcon } from 'static/svg/search_black_24dp.svg'

import * as S from './styles'

interface SearchInputProps {
  onSearchClick: (query: string) => void
  onChangeQuery: (query: string) => void
  query: string
  isFetching?: boolean
}

export function SearchInput({ query, onChangeQuery, onSearchClick, isFetching }: SearchInputProps): JSX.Element {

  function handleSearch() {
    if (isFetching) return

    onSearchClick(query)
  }

  return (
    <S.Container>
      <input
        onChange={(e) => onChangeQuery(e.target.value)}
        placeholder='Buscar por nome ou e-mail'
        value={query}
        name='search'
        type='text'
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSearch()
          }
        }}
      />
     
      <button onClick={handleSearch}>
        {isFetching ? <Spinner /> : <SearchIcon  />}
      </button>
    </S.Container>
  )
}