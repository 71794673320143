import React from "react";

const CheckBoxUnselected = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={width} 
    height={height} 
    viewBox={viewBox}>
    <path fill={fill} d="M19 5v14H5V5h14m0-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2z"/>
  </svg>
);

export default CheckBoxUnselected;