import { AxiosError } from 'axios'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface ListProfessionalsProps {
  profile_role?: string
  start_date?: string
  product_code?: string
  appointment_code?: string
  status_code?: string
  plan_code?: string
  query?: string
  per_page?: number
  page?: number
  sort?: 'ASC' | 'DESC'
}

interface ProfessionalData {
  id: number
  enable_new_patients: boolean
  friendly_url: string
  username: string
  person_general: {
    email: string
    first_name: string
    full_name: string
    last_name: string
    picture_url: string
  }
  person_profile: Array<{
    id: number
    profile_code: string
    status_code: string
    status_name: string
    documents: Array<{
      id: number
      type_name: string
      value: string
    }>
  }>
}


interface Response {
  data: {
    persons: ProfessionalData[]
    pagination: {
      total: number
      page: number
      pages: number
    }
  }
}

interface ProfessionalsData {
  professionals: ProfessionalData[]
  pagination: {
    total: number
    page: number
    pages: number
  }
}

const listProfessionals = ({
  profile_role,
  start_date,
  product_code,
  appointment_code,
  status_code,
  plan_code,
  query,
  per_page,
  page,
  sort,
}: ListProfessionalsProps): Promise<ProfessionalsData> => {

  const endpoint = apiEndpoints.PERSONS_PROFESSIONALS

  const params = {
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(status_code && { status_code }),
    ...(plan_code && { plan_code }),
    ...(query && { query }),
    ...(per_page && { per_page }),
    ...(page && { page }),
    ...(sort && { sort }),
  }

  return new Promise<ProfessionalsData> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => resolve({ pagination: response.data.pagination, professionals: response.data.persons }))
      .catch(((err: AxiosError) => reject(err.response.status)))
  })
}

export {
  listProfessionals
}
