import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import { Typography } from '@telavita-core/react-design-kit'


// COMPONENTS
import Row from '../../../../components/Row'
import { Column, TextInput } from 'components'
import { getDocuments, addDocumentsData } from '../../../../redux/actions'

class DocumentsData extends Component {

  state = {
    documents: this.props.isEdit ? this.props.personDocumentsData : [],
    isBlockChecked: false,
  }

  async componentDidMount() {
    await this.checkRequiredFields()
    this.handleSaveDocumentData()
    await this.props.onGetDocuments(this.props.role)
    if (this.props.isEdit) {
      await this.setState({ documents: this.handleNormalizeDocumentData() })
    }
    this.handleSaveDocumentData()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.documents !== this.state.documents) {
      this.checkRequiredFields()
    }
  }

  checkRequiredFields = async () => {
    let isBlockChecked = false
    const requiredDocuments = [ ...this.props.documents ]
    const currentDocuments = [ ...this.state.documents ]

    requiredDocuments.forEach((doc) => {
      const validDocuments = currentDocuments.filter(curr => curr.document_type_id === doc.id && curr.document_value !== '')
      if(validDocuments.length > 0){
        isBlockChecked = true
      }
    })

    await this.setState({ isBlockChecked })
    await this.handleSaveDocumentData()
  }

  handleNormalizeDocumentData = () => {
    let documents = [ ...this.props.personDocumentsData ]

    let fixedDouments = documents.map(document => {
      let fixedDocument = { ...document }

      fixedDocument.document_type_id = document.type_id
      delete fixedDocument['type_id']

      fixedDocument.document_value = document.value
      delete fixedDocument['value']

      return fixedDocument
    })
    return fixedDouments
  }

  handleDocumentInput = (e, documentId) => {
    const inputValue = e.target.value

    if (inputValue.length <= 25){

      const actualDocuments = [ ...this.state.documents ]
      const targetDocument = actualDocuments.find(document =>
        (document.document_type_id || document.type_id) === documentId)
      
      /**
       * documentObject is formated equaly to
       * API's document object in persons POST
       */
  
      let documentObject = {
        document_type_id: documentId,
        document_value: inputValue,
      }

      documentObject = targetDocument ? {
        ...documentObject, 
        id: targetDocument.id,
      } : documentObject
      
      /**
       * Check if the target document exists
       * in the array fro m the state
       */
  
      if (targetDocument) {
        let newDocuments = []
  
        for (let i = 0; i < actualDocuments.length; i++) {
          const document = actualDocuments[i]
  
          if ((document.document_type_id || document.type_id) !== documentId) {
            newDocuments.push({
              ...document, 
              id: targetDocument.id,
            })
          }
        }
  
        newDocuments.push(documentObject)
        this.setState({ documents: newDocuments })
      } else {
        this.setState({
          documents: [
            ...this.state.documents,
            documentObject,
          ],
        })
      }
    }

    this.checkRequiredFields()
  }

  handleSaveDocumentData = () => {
    this.props.onAddDocumentsData(
      this.state.isBlockChecked,
      this.state.isBlockChecked,
      { documents: this.state.documents }
    )
  }

  getDocumentText = (documentId) => {
    /**
     * This function covers possible errors
     * on trying to get the document value
     * Could a try/catch solve this?
     */
    const documents = [ ...this.state.documents ]
    const targetDocument = documents.find(document =>
      (document.document_type_id || document.type_id) === documentId)

    if (!targetDocument) {
      return ''
    }

    const documentValue = (targetDocument.document_value || targetDocument.value) ?
      (targetDocument.document_value || targetDocument.value) : ''

    return documentValue
  }

  render() {
    const { documents } = this.props

    return (
      <div className="RegisterBlock__DocumentsData">
        <Row padding="2rem 0 0">
          <Row padding=" 0 0 2rem 0">
            <Typography variant="content1" weight='bold'>
              Documentos
            </Typography>
          </Row>

          <Row>
            {
              documents.map(document => {
                return (
                  <Column colWidth={6}
                    key={document.id}>
                    <TextInput
                      placeholder={`${document.name}*`}
                      required={true}
                      name={`document-${document.id}`}
                      inputHeight='4.8rem'
                      onInputChange={(e) => this.handleDocumentInput(e, document.id)}
                      value={this.getDocumentText(document.id)}
                    />
                  </Column>
                )
              })
            }
          </Row>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    documents: state.documents.documents,
    isEdit: state.registerBlocks.isEdit,
    personDocumentsData: state.registerBlocks.blocksData.documentsData.documents,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetDocuments: (role) => (dispatch(getDocuments(role))),
    onAddDocumentsData: (isBlockChecked, canSave, fields) => (dispatch(addDocumentsData(isBlockChecked, canSave, fields))),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsData)