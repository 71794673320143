import React from "react";

const Trash = ({
  fill,
  height,
  viewBox = "0 0 18.857 24",
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={width} 
    height={height} 
    viewBox={viewBox}>
    <g id="icon_busca" data-name="icon busca" transform="translate(-7.891 -5.771)">
    <g id="Grupo_496" data-name="Grupo 496" transform="translate(7.891 5.771)">
      <g id="Grupo_495" data-name="Grupo 495">
        <path id="Caminho_2255" data-name="Caminho 2255" d="M15.487,21.741a.585.585,0,0,1-.573.545H3.943a.585.585,0,0,1-.573-.545L2.743,6.651a.858.858,0,0,0-1.714.069l.627,15.089A2.3,2.3,0,0,0,3.943,24H14.914A2.3,2.3,0,0,0,17.2,21.809L17.829,6.72a.856.856,0,1,0-1.711-.072ZM8.743,19.714a.686.686,0,1,0,1.371,0V8.057a.686.686,0,0,0-1.371,0Zm-3.429.021a.686.686,0,1,0,1.371-.041L6.343,8.037a.686.686,0,1,0-1.371.041Zm6.857-.041a.686.686,0,1,0,1.371.041l.343-11.657a.686.686,0,1,0-1.371-.041ZM13.649,2.914l-.326-1.142A2.529,2.529,0,0,0,10.975,0H7.882A2.529,2.529,0,0,0,5.534,1.773L5.208,2.914H.857a.857.857,0,1,0,0,1.714H18a.857.857,0,1,0,0-1.714Zm-2.674-1.2H7.882a.835.835,0,0,0-.7.528l-.189.672H11.87l-.192-.672A.835.835,0,0,0,10.975,1.714Z" fill={fill} fillRule="evenodd"/>
      </g>
    </g>
  </g>
  </svg>
);

export default Trash;