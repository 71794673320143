import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Response {
  data: {
    pagination: {
      total: number
    }
  }
}

interface Error {
  response: {
    status: number
  }
}

export const reviewsNotViewedCount = (): Promise<number> => {

  const endpoint = apiEndpoints.ME_REVIEWS as string

  const params = {
    unread: true,
    per_page: 1,
  }

  return new Promise<number> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        const pagination = { ...response.data.pagination }
        resolve(pagination.total)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}