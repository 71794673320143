import { PSYCHIATRIST, PSYCHOLOGIST } from 'settings/_profileSettings'

export interface AgeDataProps {
  role: typeof PSYCHOLOGIST | typeof PSYCHIATRIST
  hasErrorOnAgeSelectRange: {
    childSelectError: boolean
    teenagerSelectError: boolean
  }
  setHasErrorOnAgeSelectRange: React.Dispatch<
    React.SetStateAction<{ childSelectError: boolean; teenagerSelectError: boolean }>
  >
}

export enum AgeRangeCategory {
  CHILD = 'CRIANÇA',
  TEENAGER = 'ADOLESCENTE',
  ADULT = 'ADULTO',
  ELDERLY = 'IDOSO'
}

export interface AgeData {
  id: number
  name: string
  isSelected: boolean
  category: AgeRangeCategory
  ageRange: {
    id: number | string | null
    label: string | null
    startAge: number | null
    endAge: number | null
  }
}

interface AlreadyAgeDataInProfile {
  id: number
  category: AgeRangeCategory
  start_age: number
  end_age: number
}

export interface AgeRangeOptions {
  id: number | string
  label: string
  startAge: number
  endAge: number
}

export interface UseAgeData {
  ages: AgeData[]
  handleSelectAge: (id: number, ageRange: AgeRangeCategory) => void
  handleSelectChildAgeRange: (id: number | string, label: string) => void
  handleSelectTeenagerAgeRange: (id: number | string, label: string) => void
  hasErrorOnChildAgeRange: boolean
  hasErrorOnTeenagerAgeRange: boolean
}

export interface RegisterBlocks {
  registerBlocks: {
    isEdit: boolean
    agesData: AgeData[]
  }
}

export interface Persons {
  persons: {
    personProfile: {
      age_ranges: AlreadyAgeDataInProfile[]
    }
  }
}