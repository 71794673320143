import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Typography } from '@telavita-core/react-design-kit'

import Row from '../../../../components/Row'
import viewportHoc from '../../../../hoc/viewportHoc'
import Textarea from '../../../../components/Textarea'
import { Column } from 'components'
import InputValidation from '../../../../utils/InputValidation'
import { addBiographyData } from '../../../../redux/actions'
class BiographyData extends Component {
  state = {
    userRole: this.props.role,
    isMenuOpen: undefined,
    isNewUser: true,
    hasPartnerProfile: false,

    canSave: true,
    isBlockChecked: false,
    biography: {
      value: this.props.isEdit ? this.props.personBiographyData.biography : '',
      isValid: true,
      message: '',
      isEdit: false,
    },
  }

  componentDidUpdate() {
    this.checkIfCanSave()
    this.checkRequiredFields()
  }

  checkRequiredFields = () => {
    let isBlockChecked = true

    if (this.state.biography.value === '' ||
      this.state.biography.value === undefined ||
      this.state.biography.value === null ||
      this.state.biography.isValid === false) {
      isBlockChecked = false
    }

    if (isBlockChecked !== this.state.isBlockChecked) {
      this.setState({ isBlockChecked })
    }

    return isBlockChecked
  }

  checkIfCanSave = () => {
    let canSave = true

    const requiredStates = [
      'biography',
    ]

    requiredStates.map(rq => {
      if (this.state[rq].isValid === false) {
        canSave = false
      }
      return canSave
    })

    if (canSave !== this.state.canSave) {
      this.setState({ canSave })
    }

    return canSave
  }

  handleInput = (e) => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'biography').validate()

    this.setState({
      biography: {
        ...this.state.biography,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })
  }

  handleSaveData = () => {
    if (this.state.biography.isValid)
      this.props.onAddBiographyData(this.state.isBlockChecked, this.state.canSave, { biography: this.state.biography.value })
  }

  render() {
    return (
      <div className="RegisterBlock__BiographyData">
        <Row padding=" 0 0 2rem 0">
          <Typography variant='content1' weight='bold'>
            Biografia
          </Typography>
        </Row>
        <Row>
          <Column colWidth={12}>
            <Textarea
              placeholder="Escreva de forma resumida*"
              required={true}
              name="biography"
              inputHeight='11.8rem'
              hasError={!this.state.biography.isValid}
              messageError={this.state.biography.message}
              onChange={e => this.handleInput(e)}
              onFocusOut={this.handleSaveData}
              value={this.state.biography.value}
            />
          </Column>
        </Row>
      </div>
    )
  }
}



const mapStateToProps = state => {
  return {
    biographyData: state.registerBlocks,
    isEdit: state.registerBlocks.isEdit,
    personBiographyData: state.registerBlocks.blocksData.biographyData,
  }
}

const mapDispatchToProps = dispatch => {
  return { onAddBiographyData: (isBlockChecked, canSave, field) => (dispatch(addBiographyData(isBlockChecked, canSave, field))) }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(BiographyData))
