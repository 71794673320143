import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  product_group_code?: string,
  username?: string,
  plan_code?: string,
  profile_role?: string,
  person_profile_product_appointment_id?: string,
  schedules?: Array<{
    start_date: string
    end_date: string
    guests: Array<{
      person_profile_id: string
    }>
  }>,
}

interface Error {
  response: {
    status: number
  }
}

interface ICreateOrder {
  orderId: string
  instantCheckout: boolean
}

interface Response {
  data: {
    id: string
    instant_checkout: boolean
  }
}

const createOrder = ({
  product_group_code,
  username,
  plan_code,
  profile_role,
  person_profile_product_appointment_id,
  schedules,
}: Payload): Promise<ICreateOrder> => {

  const endpoint = apiEndpoints.ORDERS() 

  const params = {
    ...(person_profile_product_appointment_id && { person_profile_product_appointment_id }),
    ...(plan_code && { plan_code }),
    ...(product_group_code && { product_group_code }),
    ...(profile_role && { profile_role }),
    ...(schedules && { schedules }),
    ...(username && { username }),
  }


  return new Promise<ICreateOrder> ((resolve, reject) => {
    axios.post(endpoint, params)
      .then((response: Response) => resolve({
        orderId: response.data.id,
        instantCheckout: response.data.instant_checkout
      } as ICreateOrder))
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}

export default createOrder