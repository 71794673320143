import React, { Fragment, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import queryString from 'query-string'

import Logo from '../../components/Logo'
import Row from '../../components/Row'
import Toast from '../../components/Toast'
import { Typography, Button, Column, Container, TextInput } from 'components'
import { Typography as Typo } from '@telavita-core/react-design-kit'
import * as routes from '../../settings/_routesSettings'
import * as actions from '../../redux/actions'
import { history } from '../../routers/AppRouter'
import { showIntercom } from '../../utils/intercomHandlers'
import InputValidation from '../../utils/InputValidation'

const Login = ({ ...props }) => {
  const [password, setPassword] = useState({
    value: '',
    hasError: false,
    message: '',
    isValid: false,
  })

  const [username, setUsername] = useState({
    value: '',
    isValid: false,
    message: '',
  })

  const [loading, setLoading] = useState(false)
  const [redirectTo, setRedirectTo] = useState(null)

  const { location, userToken, isAuth, setGenericError } = props

  useEffect(() => {
    const intercomData = { email: undefined, name: undefined }
    showIntercom(false, intercomData)

    const getQuery = queryString.parse(location.search)
    setRedirectTo(getQuery.redirectTo)

    if (getQuery.sessionExpired !== undefined)
      setGenericError('Sua sessão expirou. Insira novamente seu e-mail e senha.')

    if (userToken && isAuth) history.push(routes.DASHBOARD)
  }, [location, userToken, isAuth, setGenericError])

  const onPasswordChange = e => {
    let passwordValue = e.target.value
    let passwordValidation = new InputValidation(passwordValue, 'password').validate()

    setPassword({
      ...password,
      value: passwordValue,
      message: passwordValidation.message,
      isValid: passwordValidation.success,
    })
  }

  const onUsernameChange = e => {
    let usernameValue = e.target.value
    let usernameValidation = new InputValidation(usernameValue, 'email').validate()
    setUsername({
      value: usernameValue,
      isValid: usernameValidation.success,
      message: usernameValidation.message,
    })
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    setLoading(true)

    const authData = {
      username: username.value,
      password: password.value,
    }

    setPassword({ ...password, isValid: false })

    await props.handleLogin(authData, redirectTo)
    setLoading(false)
  }

  return (
    <Fragment>
      <Container>
        <Row justify='center' className="Login__Header">
          <Column align='center' justify='center' colWidth={4}>
            <Column style={{ display: 'flex', justifyContent: 'center', alignItems: 'flex-end', gap: '6px' }}>
              <Logo fill="#1f35b5" onClickLogo={() => window.location.href = process.env.REACT_APP_INSTITUTIONAL_URL} />
              <Typo color='secondary'>
                {'{backoffice}'}
              </Typo>
            </Column>
          </Column>
        </Row>

        <Row justify='center'>
          <Column colWidth={4}>
            <Row justify='center' margin='30px 0 0'>
              <form className="hasMarginBottom" onSubmit={onSubmit} style={{ marginBottom: '25px' }}>
                {
                  props.failMessage !== '' ?
                    <Row key={props.failMessage} margin="0 0 2rem 0">
                      <Column>
                        <Toast variant="error" icon='Info' message={props.failMessage} />
                      </Column>
                    </Row> : null
                }

                <Row>
                  <Column>
                    <TextInput
                      autofocus={true}
                      disabled={false}
                      hasError={username.message}
                      messageError={username.isValid ? '' : username.message}
                      name="username"
                      onInputChange={onUsernameChange}
                      placeholder="E-mail"
                      required={true}
                      type="email"
                      value={username.value}
                      data-cy="login-username"
                    />
                  </Column>
                </Row>

                <Row margin='0 0'>
                  <Column>
                    <TextInput
                      autofocus={false}
                      disabled={false}
                      hasError={password.message}
                      isPassword
                      messageError={password.isValid ? '' : password.message}
                      name="password"
                      onInputChange={onPasswordChange}
                      placeholder="Senha"
                      required={true}
                      value={password.value}
                      data-cy="login-password"
                    />
                  </Column>
                </Row>

                <Row>
                  <Column>
                    <Button
                      loading={loading}
                      disabled={!(password.isValid && username.isValid)}
                      text={(<Trans>ENTRAR</Trans>)}
                      type="submit"
                      data-cy="login-submit"
                    />
                  </Column>
                </Row>
              </form>
            </Row>
          </Column>
        </Row>
      </Container>
    </Fragment>
  )
}

const mapStateToProps = state => ({ isAuth: state.auth.isAuth, failMessage: state.auth.failMessage })

const mapDispatchToProps = dispatch => ({
  handleLogin: (authData, redirectTo) => dispatch(actions.authLogin(authData, redirectTo)),
  setGenericError: (message) => dispatch(actions.setGenericError(message)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
