import styled from 'styled-components'

type StatusProps = {
  active: boolean
}

const ACTIVE_COLOR = '#4AC326'
const INACTIVE_COLOR = '#FF4949'

const getColor = (active: boolean) => active ? ACTIVE_COLOR : INACTIVE_COLOR

export const Status = styled.div<StatusProps>`
  display: flex;
  gap: 5px;
  align-items: center;

  > svg {
    fill: ${({ active }) => getColor(active)};
  }

  > p {
    color: ${({ active }) => getColor(active)};
  }
`

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`