import React from 'react';

// STYLE
import './ChartLabel.sass'

const ChartLabel = ({ color, label, percent, value }) => {
  const transparentColors = () => ({
    '#1F35B5': '#EBEEFD',
    '#4AC326': '#E4FBE5',
    '#F9A435': '#FFEDCE',
    '#FD4949': '#FDEBEB',
    '#BF1D1D': '#FDEBEB',
    '#D5D5D5': '#E9E9E9',
  }[color] || '#E9E9E9')

  const fontColors = () => ({
    '#1F35B5': '#1F35B5',
    '#4AC326': '#4AC326',
    '#F9A435': '#FF8900',
    '#FD4949': '#FD4949',
    '#BF1D1D': '#BF1D1D',
    '#D5D5D5': '#4B4B4B',
  }[color] || '#4B4B4B')

  function handleCicleColor () {
    return {
      'backgroundColor': color
    }
  }

  function handlePercentColor () {
    return {
      'background': `${transparentColors()}`,
      'color': `${fontColors()}`
    }
  }

  return (
    <div className="ChartLabel">
      <div className="ChartLabel__group">
        <div className="ChartLabel__color" style={handleCicleColor()} />
      </div>
      <div className="ChartLabel__group">
        <p className="ChartLabel__text">{label}</p>
        <p className="ChartLabel__text ChartLabel__text--highlight" style={handlePercentColor()}>
          <b>{percent}%</b>&nbsp;({value})
        </p>
      </div>
    </div>
  )
}

export {
  ChartLabel
}