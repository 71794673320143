import React, { useEffect, useRef, useState } from 'react'

import { Typography, Accordion, useDimensions } from '@telavita-core/react-design-kit'

import ModalGeneric from '../ModalGeneric'

import { Container, Item } from './styles'
import { useIntersectionObserver } from 'hooks'
export interface Question {
  id?: number
  title: string
  reducedTitle?: string
  text: string | JSX.Element
}
interface HelpModalProps {
  onClose: () => void
  questions: Question[]
  title: string
  activeQuestion?: number
}

export function HelpModal({
  onClose,
  questions,
  title,
  activeQuestion = null,
}: HelpModalProps): JSX.Element {
  const [shouldFocusQuestion, setShouldFocusQuestion] = useState(false)

  const activeQuestionRef = useRef<HTMLLIElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const { isMobile } = useDimensions()
  const { observerElement } = useIntersectionObserver(activeQuestionRef.current)

  useEffect(() => {
    if (activeQuestion) {
      setShouldFocusQuestion(true)
    }
  }, [activeQuestion])

  useEffect(() => {
    if(shouldFocusQuestion && observerElement) {
      const topbarHeight = isMobile ? -70 : 20
      const sizeAxisY = 
        observerElement.boundingClientRect.top - observerElement.boundingClientRect.height
      
      containerRef.current.scrollTo({
        top: sizeAxisY - topbarHeight,
        behavior: 'smooth',
      })

      setShouldFocusQuestion(false)
    }
  }, [observerElement, shouldFocusQuestion, isMobile])

  return (
    <ModalGeneric
      modalTitle={title}
      type='large'
      onCloseModal={onClose}
      containerRef={containerRef}
    >
      <Container>
        {questions.map((question) => (
          <Item 
            key={question.id} 
            ref={question.id === activeQuestion ? activeQuestionRef : null}
          >
            <Accordion
              expanded={question.id === activeQuestion}
              key={question.title}
              title={(
                <Typography
                  color='primary'
                  weight='bolder'
                  variant='content2'
                >
                  {question.title}
                </Typography>
              )}
            >
              <Typography
                variant='content2'
                inlineStyles={{
                  padding: '15px 0',
                  lineHeight: '19px',
                }}
              > 
                {question.text} 
              </Typography>
            </Accordion>  
          </Item>
        ))}
      </Container>
    </ModalGeneric>
  )
}