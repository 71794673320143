import React from 'react'
import { Button } from '@telavita-core/react-design-kit'
import { ReactComponent as ClearIcon } from 'static/svg/clear-icon.svg'

import * as S from './styles'

interface ClearResultsButtonProps {
  onClearResults: () => void
}

export function ClearResultsButton({ onClearResults }: ClearResultsButtonProps): JSX.Element {
  return (
    <S.Container>
      <Button onClick={onClearResults} uppercase={false}>
        <ClearIcon />
        Limpar resultados
      </Button>
    </S.Container>
  )
}