import React from "react";

const SexMale = ({
  fill,
  width="20.546", 
  height="41.945", 
  viewBox="0 0 20.546 41.945",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <defs>
      <clipPath id="a"><rect fill='none'width="20.546" height="41.945"/>
      </clipPath>
    </defs>
    <g transform="translate(0 0)">
      <path fill="#4b4b4b" d="M20.35,11.7a4.35,4.35,0,1,0-3.141-1.131A4.359,4.359,0,0,0,20.35,11.7Z" transform="translate(-9.881 -2.99)"/>
      <path fill="#4b4b4b" d="M18.926,27.491A4.489,4.489,0,0,0,14.861,24.9H10.8a4.489,4.489,0,0,0-4.065,2.591L2.712,36.2a1.867,1.867,0,0,0,.893,2.412,1.827,1.827,0,0,0,2.412-.893l2.9-6.253v6.789L6.777,54.736a2.072,2.072,0,0,0,1.787,2.323h.268a2.072,2.072,0,0,0,2.055-1.831l1.921-15.052,1.876,15.052a2.072,2.072,0,0,0,2.055,1.831h.268a2.1,2.1,0,0,0,1.831-2.323l-2.1-16.482V31.466l2.9,6.253a1.787,1.787,0,0,0,1.653,1.027,1.577,1.577,0,0,0,.759-.179,1.836,1.836,0,0,0,.893-2.412Z" transform="translate(-2.561 -15.114)"/>
    </g>
  </svg>

);

export default SexMale;