import React, { useEffect, useState } from 'react'

import { CPF } from 'gerador-validador-cpf'

import { Typography, TextInput, Paper, Button } from '@telavita-core/react-design-kit'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import Dropdown from 'components/Dropdown'

import { ReactComponent as CheckIcon } from 'static/svg/check.svg'

import * as S from './styles'

import { planGroupsSettings, PlanGroupCategoryEnum } from 'settings/_planGroupSettings'

import PatientsService from 'services/patients'
import { getCorporateEligibility } from 'services/corporateEligibilityService'
import { INACTIVE } from 'settings/_personSettings'
import { isCardNumberAvailiable } from 'services/patients/isCardNumberAvailiable'

interface GroupPlanSettings {
  identifier: {
    mask: string
    placeholder: string
  }
}

interface PlanDropdownOptionData {
  id: string
  name: string
  code: string
}

interface PlanData {
  name: string
  code: string
  plan_group: {
    code: string
    name: string
    category: PlanGroupCategoryEnum
    logo_url: string
  }
}

interface onSubmitData {
  patientName: string
  planCode: string
  userName: string
  cardNumber: string
}

interface LinkPlanModalProps {
  plans: PlanData[]
  patientCpf: string
  userName: string
  patientPlans: Array<{
    partner_plan_code: string
    partner_plan_name: string
    status_code: string
    plan_group: {
      code: string
      name: string
    }
  }>
  onClose: () => void
  onLinkPlan: ({ patientName, planCode, userName, cardNumber }: onSubmitData) => Promise<void>
}

interface LinkPlanModalSuccessProps {
  onClose: () => void
  showEnterpriseFeedback: boolean
}

const LinkPlanModalSuccess = ({
  onClose,
  showEnterpriseFeedback
}: LinkPlanModalSuccessProps): JSX.Element => {
  return (
    <ModalGeneric
      modalTitle={null}
      onCloseModal={onClose}
    >
      <S.Container>
        <CheckIcon />
        <Typography variant='header4' weight='bold' customClassName='LinkPlanModalSuccess__SuccessTitle'>
          Convênio vinculado com sucesso!
        </Typography>

        <Paper variant='warning' customClassName='LinkPlanModalSuccess__SuccessMessageWrapper'>
          <Typography variant='content2' weight='bolder'>
            INFORME AO PACIENTE:
          </Typography>
          <Typography variant='content2'>
            <b>1)</b> O convênio já está aparecendo em sua conta. É só entrar novamente para visualizá-lo.
          </Typography>
          <Typography variant='content2'>
            <b>2)</b>  {showEnterpriseFeedback ?
              'Para solicitar uma consulta, utilize o botão que aparece no painel do convênio e anexe o pedido médico, caso seja requerido.' :
              'Agora você já pode agendar consultas pelo convênio.'}
          </Typography>
        </Paper>

      </S.Container>
    </ModalGeneric>
  )
}

const LinkPlanModal = ({
  plans,
  patientCpf,
  patientPlans,
  userName,
  onClose,
  onLinkPlan
}: LinkPlanModalProps): JSX.Element => {

  const [selectedGroupPlan, setSelectedGroupPlan] = useState<string>()
  const [showWarningMessage, setShowWarningMessage] = useState(false)

  const [cpf, setCpf] = useState<string | null>(null)
  const [name, setName] = useState<string | null>(null)
  const [plan, setPlan] = useState<string | null>(null)
  const [cardNumber, setCardNumber] = useState<string | null>(null)

  const [cpfMsg, setCpfMsg] = useState('')
  const [planMsg, setPlanMsg] = useState('')
  const [cardNumberMsg, setCardNumberMsg] = useState('')

  const [isCpfValid, setIsCpfValid] = useState<boolean>(false)
  const [isCardNumberValid, setIsCardNumberValid] = useState<boolean>(false)

  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const groupedPlansDropdownOptions = plans
    ? plans.reduce((acc: PlanDropdownOptionData[], curr) => {
      const findGroupPlan = acc.find(accPlan => accPlan.code === curr.plan_group.code)
      if (!findGroupPlan) {
        acc.push({
          id: curr.plan_group.code,
          name: curr.plan_group.name,
          code: curr.plan_group.code
        })
      }

      return acc
    }, []).sort((a, b) => (a.name > b.name) ? 1 : -1)
    : []

  const plansDropdownOptions = selectedGroupPlan && plans
    ? plans.reduce((acc: PlanDropdownOptionData[], curr) => {
      if (curr.plan_group.code === selectedGroupPlan) {
        acc.push({
          id: curr.code,
          name: curr.name,
          code: curr.code
        })
      }

      return acc
    }, [])
    : []

  const { ENTERPRISE, CARE_PLUS, CORPORATE_LIMITED, CORPORATE_UNLIMITED, CAPITATION } = PlanGroupCategoryEnum

  const currentGroupPlan = !!selectedGroupPlan && plans && plans.find(plan => plan.plan_group.code === selectedGroupPlan)

  const hasGroupPlanSelected = !!selectedGroupPlan && currentGroupPlan

  const planGroupCategory = hasGroupPlanSelected && currentGroupPlan.plan_group.category

  const settings = !!selectedGroupPlan && planGroupsSettings[selectedGroupPlan] as GroupPlanSettings

  const isCapitation = planGroupCategory === CAPITATION
  const isCorporate = ([CORPORATE_LIMITED, CORPORATE_UNLIMITED]).includes(planGroupCategory)
  const isEnterprise = ([CARE_PLUS, ENTERPRISE]).includes(planGroupCategory)

  const showCpfInput = isCorporate || isCapitation

  const isReadyToSubmit =
    (isEnterprise && name && plan && !planMsg && isCardNumberValid && !cardNumberMsg) ||
    ((isCorporate || isCapitation) && (isCpfValid || !!patientCpf) && plan && !planMsg)

  const cleanFormData = () => {
    setName(null)
    setCpf(null)
    setPlan(null)
    setCardNumber(null)

    setCpfMsg('')
    setCardNumberMsg('')
    setPlanMsg('')
    setIsCpfValid(false)
    setIsCardNumberValid(false)
  }

  const handleCardNumber = (e: React.ChangeEvent<HTMLInputElement>, mask: string) => {
    const inputValue = e.target.value
    const inputCleanedValue = inputValue.replace(/_+/g, '').replace(/\.+/g, '')
    const minLength = mask && mask.match(/1/g)?.length

    setCardNumber(inputCleanedValue)
    setIsCardNumberValid(mask ? inputCleanedValue.length === minLength : inputCleanedValue.length >= 1)
    setCardNumberMsg('')
  }

  const handleSelectGroupPlan = (groupPlanName: string) => {
    cleanFormData()
    setSelectedGroupPlan(groupPlanName)

    const patientAlreadyHasGroupPlan = patientPlans.some(patientPlan => patientPlan.plan_group.code === groupPlanName && patientPlan.status_code !== INACTIVE)

    setShowWarningMessage(patientAlreadyHasGroupPlan)
  }

  const handleSelectPlan = (plan: string) => {
    setPlan(plan)


    const patientAlreadyHasPlan = patientPlans.some(patientPlan => patientPlan.partner_plan_code === plan && patientPlan.status_code !== INACTIVE)

    setPlanMsg(patientAlreadyHasPlan ? '* O paciente já está vinculado a este plano.' : '')
  }

  const checkIfCardNumberExists = async (): Promise<boolean> => {
    try {
      await isCardNumberAvailiable(cardNumber, selectedGroupPlan)

      return true
    } catch(err: unknown) {
      if (err === 404) {
        return false
      }
    }
  }

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)

      const currentPatientCpf = patientCpf ?? cpf 

      if (isCorporate || isCapitation) {
        const { isEligible } = await getCorporateEligibility({
          username: userName,
          planCode: plan
        })

        if (!isEligible) {
          setCpfMsg('Não conseguimos validar o número do seu CPF. Verifique se ele está correto ou entre em contato com o seu convênio.')
          setIsSubmitting(false)
          return
        }
      } else if (isEnterprise) {
        const cardNumberExists = await checkIfCardNumberExists()

        if (cardNumberExists) {
          setCardNumberMsg('Carteirinha já cadastrada')
          setIsSubmitting(false)
          return
        }
      }

      await onLinkPlan({
        patientName: name,
        userName: userName,
        ...(cardNumber && { cardNumber: cardNumber }),
        ...(isCapitation && { cardNumber: currentPatientCpf }),
        planCode: plan,
      })
      setShowSuccessMessage(true)
      setIsSubmitting(false)
      setCpfMsg('')
      setCardNumberMsg('')

    } catch (error: unknown) {
      setShowSuccessMessage(false)
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    if (cpf === null) {
      setIsCpfValid(false)
      setCpfMsg('')
      return
    }

    const isValid = CPF.validate(cpf) //eslint-disable-line
    const formattedCpf = CPF.format(cpf, 'digits') //eslint-disable-line

    if (cpf) {
      if (isValid) {
        PatientsService.isCpfAvailable(formattedCpf)
          .then(() => {
            setIsCpfValid(false)
            setCpfMsg('CPF já cadastrado')
          })
          .catch(() => {
            setIsCpfValid(true)
            setCpfMsg('')
          })

      } else {
        setIsCpfValid(false)
        setCpfMsg('CPF inválido')
      }
    } else {
      setIsCpfValid(false)
      setCpfMsg('O Campo é obrigatório')
    }
  }, [cpf])

  if (showSuccessMessage) return <LinkPlanModalSuccess onClose={onClose} showEnterpriseFeedback={isEnterprise || isCapitation} />

  return (
    <ModalGeneric
      modalTitle={'Adicionar convênio'}
      customClassName={'LinkPlanModal'}
      btnText='PROSSEGUIR'
      btnOnClick={onSubmit}
      hasButton={!!selectedGroupPlan && !showWarningMessage}
      onCloseModal={onClose}
      btnDisabled={!isReadyToSubmit || isSubmitting}
      btnLoading={isSubmitting}
    >
      <S.Container>
        <Dropdown
          options={groupedPlansDropdownOptions}
          placeholder={'Selecione um convênio'}
          onChange={handleSelectGroupPlan}
        />
        {showWarningMessage && (
          <Paper variant='warning' customClassName='LinkPlanModal__WarningMessageWrapper'>
            <Typography variant='content2' weight='bolder'>
              O PACIENTE JÁ POSSUI VINCULAÇÃO COM ESTE CONVÊNIO.
            </Typography>
            <Typography variant='content2'>
              Se a vinculação anterior deste mesmo convênio não é mais válida,
              <b> inative-a antes de continuar</b>.
              Caso contrário, o paciente poderá usufruir de ambos os planos vinculados.
            </Typography>
            <Typography variant='content2'>
              Deseja prosseguir mesmo assim?
            </Typography>
            <Button variant='contained' onClick={() => setShowWarningMessage(false)}>
              SIM, PROSSEGUIR
            </Button>
          </Paper>
        )}
        {!!selectedGroupPlan && !showWarningMessage && (
          <>
            <S.PlanImage
              src={currentGroupPlan.plan_group.logo_url}
            />

            <Typography variant='content2' customClassName='LinkPlanModal__FormTitle'>
              Insira os dados do convênio:
            </Typography>
            <S.FormWrapper>
              {isEnterprise && (
                <TextInput
                  name='name'
                  onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                  value={name}
                  placeholder='Nome completo (como na carteirinha) *'
                />
              )}
              <Dropdown
                options={plansDropdownOptions}
                placeholder={'Selecione o plano *'}
                onChange={(value: string) => handleSelectPlan(value)}
                defaultValue={plan}
                clearSelection={plan === null}
                customClassName='LinkPlanModal__PlanDropdown'
                hasError={!!planMsg}
                messageError={planMsg}
              />
              {showCpfInput && (
                <TextInput
                  hasError={!!cpfMsg}
                  messageError={cpfMsg}
                  name="cpf"
                  onInputChange={(e: React.ChangeEvent<HTMLInputElement>) => setCpf(e.target.value)}
                  placeholder="CPF"
                  required
                  type="text"
                  disabled={!!patientCpf}
                  value={patientCpf ? patientCpf : cpf}
                  mask='111.111.111-**'
                />
              )}
              {isEnterprise && (
                <TextInput
                  name='card_number'
                  messageError={cardNumberMsg}
                  hasError={!!cardNumberMsg}
                  placeholder={settings?.identifier.placeholder}
                  mask={settings?.identifier.mask ? settings?.identifier.mask : ''}
                  value={cardNumber}
                  onInputChange={e => handleCardNumber(e, settings?.identifier.mask)}
                />
              )}
            </S.FormWrapper>
            <Typography customClassName='LinkPlanModal__Label' variant='content2' italic>
              *Preenchimento obrigatório
            </Typography>
          </>
        )}
      </S.Container>
    </ModalGeneric>
  )
}

export default LinkPlanModal