import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ToastGenericError } from 'components/ToastGenericError/ToastGenericError';
import { cleanGenericError } from 'redux/actions/asyncActions';

const ContainerComponent = ({ children, elastic, className, isMobileFull, isView, genericError, cleanError }) => {

  let compClassName = 'container';
  let mobileFullClass = '--mobile-full';

  if (isView) compClassName = `${compClassName} view`;
  if (isMobileFull) compClassName = `${compClassName}${mobileFullClass}`;

  if (className) compClassName = `${compClassName}--${className}`;

  const inlineStyles = elastic ? { padding: 0 } : {}

  useEffect(() => {
    return () => {
      cleanError();
    }
  }, [cleanError]);

  return (
    <div className={compClassName} style={inlineStyles}>

      {children}

      {
        genericError &&
        <ToastGenericError message={genericError} />
      }

    </div>
  );
};

const mapStateToProps = state => {
  return { genericError: state.async.genericError };
};

const mapDispatchToProps = dispatch => {
  return { cleanError: () => dispatch(cleanGenericError()) };
};

const Container = connect(mapStateToProps, mapDispatchToProps)(ContainerComponent);

export { Container };