import { SET_ANSWER_RESPONSE  } from './_actionTypes';
import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';
import { asyncError } from "./";

//POST REQUEST TO SURVEY ENDPOINT
export const setSurveyAnswer =(code, data) => async dispatch=>{
  const fetchUrl= apiEndpoints.SURVEY_ANSWER(code);
  const fetchData={ ...data };
  try {
    const response= await axios.post(fetchUrl, fetchData);
    let payload;
    switch (response.status) {
      case 'OK': {
        payload = { questions: response.data };
        return dispatch({
          type: SET_ANSWER_RESPONSE,
          payload,
        });
      }
  
      case 'ZERO_RESULTS':
        payload = { questions: [] };
  
        return dispatch({
          type: SET_ANSWER_RESPONSE,
          payload,
        });
  
      default:
          
        break;
    }
 
  } catch (err) {
    dispatch(asyncError(err));
  }
};
