import React, { useState } from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import { SearchInput } from '../SearchInput/SearchInput'
import { FilterScheduleModal } from '../FilterScheduleModal'
import { CoreIcons } from 'components'

import * as S from './styles'

interface FilterAdminSchedulesHeaderProps {
  query: string
  onSearchClick: (query: string) => void
  onChangeQuery: (query: string) => void
  isFetching?: boolean
}

export function FilterAdminSchedulesHeader({
  query,
  onSearchClick,
  onChangeQuery,
  isFetching
}: FilterAdminSchedulesHeaderProps): JSX.Element {
  const [showFilterModal, setShowFilterModal] = useState(false)

  return (
    <S.Container>
      <Typography variant='header2'>
        Lista de Consultas
      </Typography>

      <div>
        <SearchInput
          onChangeQuery={(query) => onChangeQuery(query)}
          query={query}
          onSearchClick={(query) => onSearchClick(query)}
          isFetching={isFetching}
        />
        
        <S.FilterContainer onClick={() => setShowFilterModal(prevState => !prevState)}>
          <CoreIcons
            fill="#1F35B5"
            name="Filters"
            viewBox="-10 -10 35 35"
          />
        </S.FilterContainer>

        {showFilterModal && (
          <FilterScheduleModal closeModal={() => setShowFilterModal(false)}/>
        )}
      </div>
    </S.Container>
  )
}