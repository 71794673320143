import React from 'react'
import PropTypes from 'prop-types'
import { CoreIcons } from 'components'
import Typography from 'components/Typography'

const AlertIcon = ({ iconName, iconColor, iconSize, message, messageColor }) => {
  return (
    <div className="AlertIcon">
      <div className="AlertIcon__Icon">
        <CoreIcons
          fill={iconColor}
          name={iconName}
          width={iconSize}
          viewBox="00 00 25 25"
        />
      </div>
      <div className="AlertIcon__Text">
        <Typography color={messageColor}>
          {message}
        </Typography>
      </div>
    </div>
  )
}

AlertIcon.propTypes = {
  iconName: PropTypes.string,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  message: PropTypes.any,
  messageColor: PropTypes.string,
}

AlertIcon.defaultProps = {
  iconName: 'Info',
  iconColor: '#eb384d',
  iconSize: '35px',
  message: '',
  messageColor: 'false',
}

export default AlertIcon
