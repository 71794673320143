import React, { useState } from 'react'
import { Tab, TabPanel, Tabs } from '@telavita-core/react-design-kit'

import * as S from './styles'
import clsx from 'clsx'

interface PatientTreatmentsEligibilityProps {
  tabs: string[]
  tabsPanel: JSX.Element[]
}

const PatientTreatmentsEligibility = ({
  tabs = [],
  tabsPanel = [],
}: PatientTreatmentsEligibilityProps): JSX.Element => {
  const [tab, setTab] = useState(0)

  return (
    <S.Container>
      <S.TabsWrapper>
        <Tabs selectedTab={tab} onChange={(e, value) => setTab(value)} showBorder>
          {tabs.length > 0 && tabs.map((tab, index) => (
            <Tab customClassName={clsx({
              'PatientTreatmentsEligibility__Tab': true,
              [`PatientTreatmentsEligibility__Tab--${tab}`]: true
            })} label={tab} id={index} key={index}/>
          ))}
        </Tabs>
      </S.TabsWrapper>
      {tabsPanel.length > 0 && tabsPanel.map((tabPanel, index) => (
        <TabPanel isActive={tab === index} key={index}>
          {tabPanel}
        </TabPanel>
      ))}
    </S.Container>
  )
}

export {
  PatientTreatmentsEligibility
}