import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@telavita-core/react-design-kit'

import { 
  TextInput, 
  PageTop, 
  Column, 
} from 'components'
import StickyIndex from 'components/StickyIndex'
import ProfessionalListItem from 'components/ProfessionalListItem'
import FloatActionButtonDropdown from 'components/FloatActionButtonDropdown/FloatActionButtonDropdown'
import SearchPlaceHolder from 'components/SearchPlaceholder'
import Skeleton from 'components/Skeleton/Skeleton'
import Row from 'components/Row'
import { modalTypes } from 'containers/ModalManager/ModalManager'
import { ReactComponent as SkipRightIcon } from 'static/svg/skip-right.svg'

import * as actions from 'redux/actions'
import * as permissions from 'settings/_permissionSettings'
import * as routes from 'settings/_routesSettings'
import { TVTVADMN } from 'settings/_profileSettings'
import { INACTIVE } from 'settings/_personSettings'
import { history } from 'routers/AppRouter'
import { hasPermission } from 'utils/permissionHandlers'
import { getProfessionalCodeByProfileCode } from 'utils/profileCode'
import { groupByAlphabet } from 'utils/groupAlphabetically'
import { handleProfessionalUrl } from 'utils'
import ProfessionalsService from 'services/professionals'

import * as S from './styles'
import { DisableNewPatientsModal } from 'components/DisableNewPatientsModal'

const iconProps = {
  fill: '#1F35B5',
  height: '30px',
  name: 'Search',
  viewBox: '3 2 30 30',
  width: '30px',
}

const ProfessionalListView = () => {

  const dispatch = useDispatch()

  const currentUserPermissions = useSelector(state => state.auth.profilePermissions)
  const isCurrentUserAdmin = useSelector(state => state.auth.profileSelected.profile_code) === TVTVADMN

  const [professionals, setProfessionals] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [openDisableNewPatientsModal, setOpenDisableNewPatientsModal] = useState(false)
  const [professionalData, setProfessionalData] = useState({
    professionalName: undefined,
    professionalDocuments: undefined,
    professionalDocumentValue: undefined,
    professionalUsername: undefined,
    professionalRole: undefined
  })

  const professionalsGroupedByAlphabet = groupByAlphabet(professionals)

  const FloatActionButtonDropdownOptions = [
    {
      label: 'Psicólogo',
      role: 'PSIC'
    },
    {
      label: 'Psiquiatra',
      role: 'PSIQ'
    }
  ]

  const handleFetchProfessionals = async () => {
    if (query.length < 1) return
    setLoading(true)

    try {
      const { professionals } = await ProfessionalsService.listProfessionals({query})

      setProfessionals(professionals.map(pro => {
        const id = pro.id
        const professionalName = pro.person_general.full_name
        const professionalEmail = pro.person_general.email
        const profileUrl = pro.person_general.picture_url
        const friendlyUrl = pro.friendly_url
        const status = pro.person_profile[0].status_code
        const documents = pro.person_profile[0].documents
        const getTypeName = documents[0] ? documents[0] : null
        const professionalDocuments = getTypeName ? getTypeName.type_name : ''
        const getDocValue = documents[0] ? documents[0] : null
        const professionalDocumentValue = getDocValue ? getDocValue.value : ''
        const username = pro.username
        const professionalType = pro.person_profile[0].profile_code
        const dateUpdated = pro.date_updated
        const userNameUpdated = pro?.user_updated?.full_name || ''
        const enableNewPatients = pro.enable_new_patients

        return {
          id,
          professionalName,
          professionalEmail,
          status,
          profileUrl,
          friendlyUrl,
          professionalDocuments,
          professionalDocumentValue,
          username,
          professionalType,
          dateUpdated,
          userNameUpdated,
          enableNewPatients
        }
      }))
      setLoading(false)
    } catch {
      setLoading(false)
    }
  }

  const handleSelectOption = (data) => {
    dispatch(actions.cleanBlocksEdition())
    history.push(routes.DASHBOARD_ADD_PROFESSIONAL(data.role))
  }

  const onInputChange = (e) => {
    setQuery(e.target.value)
  }

  const buildMenu = (prof) => {
    let menuList = []
    const type = handleProfessionalUrl(prof.professionalType)
    const role = getProfessionalCodeByProfileCode(prof.professionalType)

    hasPermission(currentUserPermissions, permissions.ADMIN_VIEW_AGENDA) &&
      menuList.push(
        {
          code: 'see-schedule',
          name: 'Ver agenda',
          func: () => history.push(routes.DASHBOARD_ADMIN_AGENDA(prof.username, type)),
        }
      )

    hasPermission(currentUserPermissions, permissions.ADMIN_VIEW_AGENDA) &&
      menuList.push(
        {
          code: 'see-reservations',
          name: 'Ver reservas',
          func: () => history.push(routes.DASHBOARD_RESERVATIONS_ADMIN(prof.username)),
        }
      )


    hasPermission(currentUserPermissions, permissions.ADMIN_EDIT_PERSON) && prof.status !== INACTIVE &&
      menuList.push(
        {
          code: 'edit-profile',
          name: 'Editar perfil',
          func: () => history.push(routes.DASHBOARD_EDIT_PROFESSIONAL(prof.username, role)),
        }
      )

    hasPermission(currentUserPermissions, permissions.ADMIN_ENABLE_NEW_PATIENTS) && prof.status !== INACTIVE &&
      menuList.push(
        {
          code: 'enabled-new-patients',
          name: 'Agendamento para novos pacientes',
          func: () => {
            setOpenDisableNewPatientsModal(true)
            setProfessionalData({
              professionalName: prof.professionalName,
              professionalDocuments: prof.professionalDocuments,
              professionalDocumentValue: prof.professionalDocumentValue,
              professionalUsername: prof.username,
              professionalRole: getProfessionalCodeByProfileCode(prof.professionalType),
            })

          }
        }
      )

    if (isCurrentUserAdmin && prof.status !== INACTIVE) {
      menuList.push(
        {
          code: 'inactive-professional',
          name: 'Inativar profissional',
          func: () => dispatch(actions.showModal(
            modalTypes.INACTIVE_PROFESSIONAL,
            {
              name: prof.professionalName,
              document: prof.professionalDocuments,
              documentValue: prof.professionalDocumentValue,
              image: prof.profileUrl,
              slug: prof.username,
              role: getProfessionalCodeByProfileCode(prof.professionalType), 
              reloadProfessionalList: () => handleFetchProfessionals()
            }
          ))
        }
      )
    }

    if (isCurrentUserAdmin && prof.status === INACTIVE) {
      menuList.push(
        {
          code: 'active-professional',
          name: 'Ativar profissional',
          func: () => dispatch(actions.showModal(
            modalTypes.ACTIVE_PROFESSIONAL,
            {
              name: prof.professionalName,
              document: prof.professionalDocuments,
              documentValue: prof.professionalDocumentValue,
              image: prof.profileUrl,
              slug: prof.username,
              role: getProfessionalCodeByProfileCode(prof.professionalType), 
              reloadProfessionalList: () => handleFetchProfessionals()
            }
          ))
        }
      )
    }

    return menuList
  }

  const FloatActionButtonDropdownRenderOption = (data) => (
    <S.FloatButtonOption>
      <Typography variant='content2'>
        {data.label}
      </Typography>
      <SkipRightIcon />
    </S.FloatButtonOption>
  )

  const renderSkeletonItem = (amount) => {
    return (
      <>
        {new Array(amount).fill(0).map((_, index) => (
          <S.SkeletonContainer key={index}>
            <div>
              <Skeleton
                height={50}
                width={50}
                borderRadius={50}
              />
              <S.SkeletonProfessionalInfo>
                <Skeleton 
                  width={150}
                  height={10}
                />
                <Skeleton 
                  width={80}
                  height={10}
                />
                <div style={{ display: 'flex', gap: '10px' }}>
                  <Skeleton 
                    width={120}
                    height={10}
                  />
                  <Skeleton 
                    width={50}
                    height={10}
                  />
                </div>
              </S.SkeletonProfessionalInfo>
            </div>
            <Skeleton
              height={24}
              width={24}
              borderRadius={50}
            />
          </S.SkeletonContainer>
        ))}
      </>    
    )
  }

  return (
    <>
      <Row>
        <Column colWidth={12}>
          <PageTop
            id="professionals-view-header"
            toHideOnMobile={
              <Typography variant='header2'>
                  Profissionais
              </Typography>
            }
            toShowOnMobile={
              <S.FiltersContainer>
                <S.TextInputWrapper>
                  <TextInput
                    addIcon
                    iconProps={iconProps}
                    name="search_client"
                    inputHeight="4.8rem"
                    onInputChange={onInputChange}
                    onClickIcon={handleFetchProfessionals}
                    onKeyPress={e => e.which === 13 && handleFetchProfessionals()}
                    placeholder="Buscar nome ou e-mail"
                    value={query}
                  />
                </S.TextInputWrapper>
                <S.HeaderButtonWrapper>
                  <FloatActionButtonDropdown
                    icon="Add"
                    title='Adicionar'
                    type="primary"
                    options={FloatActionButtonDropdownOptions}
                    renderOption={FloatActionButtonDropdownRenderOption}
                    onSelectOption={handleSelectOption}
                  />
                </S.HeaderButtonWrapper>
              </S.FiltersContainer>
            } />
        </Column>
      </Row>
      <Row>
        <Column colWidth={12}>
          {!loading && professionals.length <= 0 && !query && (
            <S.SearchPlaceHolderWrapper>
              <SearchPlaceHolder
                titleText='Use a busca acima para'
                phrase='encontrar o profissional'
              />
            </S.SearchPlaceHolderWrapper>
          )}
          {loading && (
            <S.SkeletonWrapper>
              <S.SkeletonHeader>
                <Skeleton 
                  height={10}
                  width={100}
                />
              </S.SkeletonHeader>
              {renderSkeletonItem(8)}
            </S.SkeletonWrapper>
          )}
          {!loading && professionalsGroupedByAlphabet.length > 0 && professionalsGroupedByAlphabet.map((profs, index) => (
            <Row margin="0 0 15px 0" align="center" justify="center" key={index}>
              <StickyIndex
                textPosition='flex-start'
                barColor='#F5F5F5'
                barContent='Resultado da busca'
                barDate={profs.date}
              />
              {profs.children.map(el => (
                <ProfessionalListItem
                  key={el.id}
                  professionalName={el.professionalName}
                  friendlyUrl={el.friendlyUrl}
                  professionalEmail={el.professionalEmail}
                  professionalDocuments={el.professionalDocuments}
                  professionalDocumentValue={el.professionalDocumentValue}
                  id={el.id}
                  professionalStatus={el.status}
                  avatarUrl={el.profileUrl}
                  avatarSize='50px'
                  menuList={buildMenu(el)}
                  iconName='MoreOptions'
                />
              ))}
            </Row>
          ))}
          {professionalsGroupedByAlphabet.length <= 0 && query && (
            <S.SearchPlaceHolderWrapper>
              <SearchPlaceHolder
                titleText='Nenhum resultado'
                text='Refaça sua busca e seus filtros'
              />
            </S.SearchPlaceHolderWrapper>
          )}
        </Column>
      </Row>
      {openDisableNewPatientsModal && (
        <DisableNewPatientsModal 
          onClose={() => setOpenDisableNewPatientsModal(false)}
          professionalName={professionalData.professionalName}
          professionalDocumentType={professionalData.professionalDocuments}
          professionalDocumentValue={professionalData.professionalDocumentValue}
          professionalUsername={professionalData.professionalUsername}
          professionalRole={professionalData.professionalRole}
        />
      )}
    </>
  )
}

export {
  ProfessionalListView
}
