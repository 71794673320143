import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import Row from "../../../components/Row";
import { Typography, ClearSearch, Column, TextInput } from 'components';
import { Spinner } from '@telavita-core/react-design-kit'
import { modalTypes } from "../../../containers/ModalManager/ModalManager";
import Sticky from "react-sticky-el";
import SimplePersonItem from "../../../components/SimplePersonItem";
import viewportHoc from "../../../hoc/viewportHoc";
import * as actions from "../../../redux/actions";
import StickyIndex from '../../../components/StickyIndex';
import SearchPlaceHolder from '../../../components/SearchPlaceholder';
const iconProps = {
  fill: "#1F35B5",
  height: "30px",
  name: "Search",
  viewBox: "3 2 30 30",
  width: "30px",
};

const stickyFilter = {
  backgroundColor: "#fff",
  padding: "2.5rem 0px 2rem",
  zIndex: "2",
};
class Permissions extends Component {

  state = {
    query: "",
    loaded: false,
    isCleared: false,
    loading: false,
  };

  componentDidUpdate(prevProps) {
    const { filterData } = this.props;

    if (prevProps.filterData !== filterData) {
      this.setState({ loading: true })
      this.props
        .onListAllPersonals({
          sort: "asc",
          query: filterData.text,
        })
        .then(() => {
          this.setState({ loaded: true, loading: false });
        });
    }
  }
  // search
  handleSearchInput = e => {
    const inputValue = e.target.value;
    if (new RegExp(/^[A-Za-zÀ-ÿ ]+$/).test(inputValue) || inputValue === "") {
      this.setState({ query: inputValue });
    }
  };

  handleSearchClick = () => {
    if(this.state.query === ''){
      this.setState({ isCleared: false })
      return
    }

    this.props.onSetQuery(this.state.query);
    this.setState({ loaded: false, isCleared: true });
  };

  handlePersonsData = () => {
    const personsData = this.props.listAllPersonals;
    return personsData.map(item => {
      const avatarUrl = item.person_general.picture_url;
      const id = item.id;
      const name = item.person_general.full_name;
      const email = item.person_general.email;
      const slug = item.username;

      return {
        avatarUrl,
        id,
        name,
        email,
        slug,
      };
    });
  };

  groupByAlphabet = (prop) => {
    let data = prop.reduce((r, e) => {
      let group = e.name[0];
      if (!r[group]) r[group] = { group, children: [ e ] };
      else r[group].children.push(e);
      return r;
    }, {});

    return Object.values(data);
  };

  handleGroupData = () => {
    return this.groupByAlphabet(this.handlePersonsData());
  }

  renderGroupLists = (data) => (

    data.length <= 0 ?
      (
        <div className="dashboard-person-list--empty">
          <SearchPlaceHolder
            titleText='Nenhum resultado'
            text='Refaça sua busca e seus filtros'
          />

        </div>
      ) :
      data.map((item, index) => (

        <Row align="center" justify="center" key={index}>
          <StickyIndex
            textPosition='flex-start'
            barColor='#F5F5F5'
            barContent={this.props.filterData.isFiltering ? 'Resultado da busca' : item.group}
            barDate={item.date}
          />

          {item.children.map(el => {
            return (

              <Row direction="column" key={el.id}>
                <SimplePersonItem
                  personName={el.name}
                  personEmail={el.email}
                  personId={el.id}
                  onButtonClick={() =>
                    this.props.onShowModal(
                      modalTypes.ASSIGN_PERMISSION,
                      this.modalData(el, item.children)
                    )
                  }
                  personAvatar={el.avatarUrl}
                />
              </Row>
            );
          }

          )}

        </Row>

      ))
    // : (
    //   <div className="dashboard-person-list--empty">
    //     <Row justify="space-around">
    //       <CoreIcons
    //         className="dashboard-person-list--icon"
    //         fill="#898989"
    //         name="Client"
    //       />
    //     </Row>

    //     <Row justify="space-around">
    //       <Typography align='center'>
    //         <Trans>
    //             Nenhum psicologo adicionado
    //         </Trans>
    //       </Typography>
    //     </Row>
    //   </div>)
  );

  // renderSimplePersonItem = () => {
  //   let handlePerosnsData = this.groupByAlphabet(this.handlePersonsData())
  //   console.log(this.groupByAlphabet(this.handlePersonsData()))
  //   return handlePerosnsData.map(item => {
  //     return (
  //       <div key={item.id}>
  //         <SimplePersonItem
  //           personName={item.name}
  //           personId={item.id}
  //           onButtonClick={() =>
  //             this.props.onShowModal(
  //               modalTypes.ASSIGN_PERMISSION,
  //               this.modalData(item, handlePerosnsData)
  //             )
  //           }
  //           personAvatar={item.avatarUrl}
  //         />
  //       </div>
  //     );
  //   });
  // };

  // edit permission modal props
  modalData = (item) => {
    let editPermission = {
      btnText: `SALVAR PERMISSÕES`,
      titleColor: `black`,
      classModifier: 'default',
      modalTitle: `Editar permissões`,
      name: item.name,
      id: item.id,
      slug: item.slug,
    };
    return editPermission;
  };

  clearFilters = () => {
    this.props.onClearPersons();
    this.setState({ isCleared: false });
  }

  render() {
    const { loaded, isCleared, loading } = this.state;
    return (
      <div className="Permissions">
        <Sticky stickyStyle={stickyFilter}>
          <Row
            align="center"
            justify={this.props.isDesktop ? "space-between" : "center"}
            margin={this.props.isDesktop ? "4rem 0 1.5rem" : "2.5rem 0 1.5rem"}>
            <Column className="Permissions__title" align="center" justify="center" colWidth={7}>
              <Typography align="mcenter-dleft" Tag="h1" type="title">
                <Trans>Permissões</Trans>
              </Typography>
            </Column>
            <Column colWidth={5} className="search-input">
              <TextInput
                addIcon
                iconProps={iconProps}
                name="search_client"
                inputHeight="4.8rem"
                onInputChange={this.handleSearchInput}
                onClickIcon={this.handleSearchClick}
                onKeyPress={e => e.which === 13 && this.handleSearchClick()}
                placeholder="Buscar por nome"
                value={this.state.query}
              />
            </Column>
          </Row>

          <Row margin="auto auto 2rem ">
            {
              // this.props.filterData.text !== null ||
              // this.props.filterData.status !== null ||
              // this.props.filterData.startDate !== null ||
              // this.props.filterData.endDate !== null ? (
              //     <Row>
              //       <ClearFilters onClickText={() => this.props.onClearFilters()}/>
              //     </Row>
              //   ) : null

              isCleared && !loading
                ? (
                  <Row>
                    {/* <ClearFilters onClickText={() => this.props.onClearFilters()}/> */}
                    <ClearSearch fn={this.clearFilters} titleName='Limpar Resultados' />
                  </Row>
                ) : null
            }
          </Row>

        </Sticky>
        {/* <Row>
          <div className="dashboard-person-list">
            {loaded ? this.renderGroupLists(this.handleGroupData()) : <Spinner height="80%" />}
          </div>
        </Row> */}
        {loading 
          ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Spinner />
            </div>
          ) 
          : (loaded && isCleared) ?
            (<div className="dashboard-person-list">
              {this.renderGroupLists(this.handleGroupData())}

            </div>) :
            <div className="dashboard-person-list--empty">

              <SearchPlaceHolder
                titleText='Use a busca acima para'
                phrase='encontrar o usuário'
              />
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  //   isAuth: state.auth.isAuth,
  filterData: state.filters,
  //   userData: state.auth.userData,
  listAllPersonals: state.persons.persons,
  //   userPermissions: state.auth.profilePermissions
});

const mapDispatchToProps = dispatch => ({
  onClearPersons: () => dispatch(actions.clearPersons()),
  onListAllPersonals: getParams => dispatch(actions.listPersons(getParams)),
  onShowModal: (type, data) => dispatch(actions.showModal(type, data)),
  onSetQuery: query => dispatch(actions.setTextFilter(query)),
  onClearFilters: () => dispatch(actions.clearFilter()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(Permissions));
