import { CLEAR_FILTERS, FILTER_STATUS } from "../actions/_actionTypes";
import { updateState } from "../helpers/utilityHelpers";

const initialState = { filterStatus: [] };

const getStatus = (state, payload) => {
  return updateState(state, { filterStatus: payload.filterStatus });
};

export const clearFilters = state => {
  return updateState(state, initialState);
};

const filterStatusReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_FILTERS:
      return clearFilters(state);
    case FILTER_STATUS:
      return getStatus(state, action.payload);
    default:
      return state;
  }
};
export default filterStatusReducer;
