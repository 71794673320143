import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as actions from '../../../../redux/actions'
import { getSkillsDifference, formatSkillsData } from '../../../../utils/skillsHandlers'

import { CoreIcons } from 'components'
import { Typography } from '@telavita-core/react-design-kit'
import RadioToggle from '../../../../components/RadioToggle'
import Row from '../../../../components/Row'

const SkillsData = ({ role }) => {
  const [selectedSkills, setSelectedSkills] = useState([])
  const [selectedSubspecialties, setSelectedSubspecialties] = useState([])

  const [isOpen, setIsOpen] = useState(false)

  const isBlockChecked = true
  const canSave = true
  const countSubspecialties = selectedSubspecialties === null ? 0 : selectedSubspecialties.length
  const countSkills = selectedSkills === null ? 0 : selectedSkills.length
  const blockSubtitle = countSkills > 0 ?
    `${countSkills + countSubspecialties} temas adicionados` :
    'Nenhum tema adicionado'

  const dispatch = useDispatch()

  const subspecialties = useSelector(state => state.skills.subspecialties)
  const isEdit = useSelector(state => state.registerBlocks.isEdit)
  const childSkills = useSelector(state => state.skills.childSkills)
  const parentSkills= useSelector(state => state.skills.parentSkills)
  const personSkillsData = useSelector(state => state.registerBlocks.blocksData.skillsData.skills)
  const personSubspecialtiesData = useSelector(state => state.registerBlocks.blocksData.subspecialtiesData.subspecialties)

  const handleToggle = async (skillId, type) => {

    const isTogglingSkill = type === 'SKILL'

    const professionalExperienceData = isTogglingSkill ? selectedSkills : selectedSubspecialties

    const filteredExperiences = professionalExperienceData.filter(skill => skill === skillId).length > 0 ?
      professionalExperienceData.filter(skill => skill !== skillId) :
      [
        ...professionalExperienceData,
        skillId,
      ]

    if (isTogglingSkill) {
      setSelectedSkills(filteredExperiences)
    } else {
      setSelectedSubspecialties(filteredExperiences)
    }

    await onSaveSkills(filteredExperiences, isTogglingSkill)
  }

  const onSaveSkills = async (filteredExperiences, isTogglingSkill) => {
    let getIds = []
    let skillsData = getSkillsDifference([], filteredExperiences)

    const personExperiencesData = isTogglingSkill ? personSkillsData : personSubspecialtiesData

    if (isEdit) {
      let newSkills = skillsData.newSelection === null ? [] : skillsData.newSelection
      let getSkills = []

      personExperiencesData.map(item => getIds.push(isTogglingSkill ? item.skill_id : item.subspecialty_id))
      skillsData = getSkillsDifference(getIds, newSkills)

      skillsData.skillsRemoved.map(skill => {
        let findSkill = personExperiencesData.find(item => ((isTogglingSkill ? item.skill_id : item.subspecialty_id) === skill))
        return getSkills.push(findSkill)
      })

      skillsData = {
        skillsAdded: skillsData.skillsAdded,
        skillsRemoved: getSkills,
      }

    }

    const getData = formatSkillsData(skillsData, isTogglingSkill)

    if (isTogglingSkill) {
      dispatch(actions.addSkillsData(isBlockChecked, canSave, { skills: getData }))
    } else {
      dispatch(actions.addSubspecialtiesData(isBlockChecked, canSave, { subspecialties: getData }))
    }

  }

  const onShowSkills = () => setIsOpen(oldState => !oldState)

  const renderPsiSkills = () => {

    return (
      <div className={`RegisterSkillsModal${isOpen ? '--show' : ''}`}>
        {
          parentSkills.map(skill => {
            const skills = childSkills.filter(item => item.parent_code === skill.code)

            if (skills.length > 0) {
              return (
                <div className="SkillsSelection" key={skill.id}>
                  <Typography variant='content2' weight='bolder' inlineStyles={{ textTransform: 'uppercase' }}>
                    {skill.name}
                  </Typography>

                  <div className="SkillsSelection__options">
                    {
                      skills.map(skill => {
                        let isSelected = selectedSkills
                          .filter(skillId => skillId === skill.id)
                          .length > 0

                        return (
                          <RadioToggle
                            key={skill.id}
                            onHandleToggle={() => handleToggle(skill.id, 'SKILL')}
                            parentControl
                            selected={isSelected}
                            title={skill.name}
                          />
                        )
                      })
                    }
                  </div>
                </div>
              )
            }
            return null
          })
        }
      </div>
    )
  }

  const renderPsiqSkills = () => {
    return (
      <div className={`RegisterSkillsModal${isOpen ? '--show' : ''}`}>
        <div className="SkillsSelection">
          <Typography variant='content2' weight='bolder'>
            SUBESPECIALIDADES
          </Typography>

          <div className="SkillsSelection__options">
            {
              subspecialties.map(subspecialty => {
                let isSelected = selectedSubspecialties
                  .filter(subspecialtyId => subspecialtyId === subspecialty.id)
                  .length > 0
                
                return (
                  <RadioToggle
                    key={subspecialty.id}
                    onHandleToggle={() => handleToggle(subspecialty.id, 'SUBSPECIALTY')}
                    parentControl
                    selected={isSelected}
                    title={subspecialty.name}
                  />
                )
              })
            }
          </div>
        </div>
        <div className="SkillsSelection">
          <Typography variant='content2' weight='bolder'>
            TRANSTORNOS
          </Typography>

          <div className="SkillsSelection__options">
            {
              childSkills.map(skill => {
                let isSelected = selectedSkills
                  .filter(skillId => skillId === skill.id)
                  .length > 0

                return (
                  <RadioToggle
                    key={skill.id}
                    onHandleToggle={() => handleToggle(skill.id, 'SKILL')}
                    parentControl
                    selected={isSelected}
                    title={skill.name}
                  />
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }

  const renderContent = () => {
    return (
      <>
        <div className="RegisterBlock__SkillsData">
          <Row padding=" 0 0 2rem 0">
            <div className="RegisterBlock__SkillsData__title">
              <Typography variant='content1' weight='bold'>
                Experiência
              </Typography>
              <div className="RegisterBlock__SkillsData__title__toggle-btn"
                onClick={onShowSkills}>
                <CoreIcons name={isOpen ? 'ExpandLess' : 'ExpandMore'} />
              </div>
            </div>

            <Typography variant='content2' inlineStyles={{  color: '#898989' }} italic>
              {blockSubtitle}
            </Typography>
          </Row>

          <Row>
            {role === 'PSIC' ? renderPsiSkills() : renderPsiqSkills()}
          </Row>
        </div>
      </>
    )
  }

  useEffect(() => {
    dispatch(actions.getSkills(role))
    if (isEdit) {
      if (personSkillsData.length > 0) setSelectedSkills(personSkillsData.map(item => item.skill_id))
      if (personSubspecialtiesData.length > 0) setSelectedSubspecialties(personSubspecialtiesData.map(item => item.subspecialty_id))
    }
  },[])

  return (
    <>
      {renderContent()}
    </>
  )
}

export default SkillsData
