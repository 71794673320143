import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { endOfYesterday, formatISO, nextDay, format } from 'date-fns'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'

import { hideModal, getSlot } from 'redux/actions'

import { getWeekdays } from 'utils/dateTimeHandlers'
import InputValidation from 'utils/InputValidation'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

import ModalGeneric from '../ModalGeneric'
import LineSeparator from 'components/LineSeparator'
import Row from 'components/Row'
import Tabs from 'components/Tabs'
import { Typography, CalendarInput, TextInput, CoreIcons } from 'components'
import { Typography as Typo, Button, useDimensions } from '@telavita-core/react-design-kit'
import { TimestampTag } from 'components'
import { SlotTag } from 'components/SlotTag'

const ScheduleModal = ({ user, selectedWeekDay }) => {

  const dispatch = useDispatch()

  const slotsContainerRef = useRef(null)
  const itemsRef = useRef([])

  const { isMobile } = useDimensions()

  const [currentTab, setCurrentTab] = useState('recurrent-schedule-tab')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [errorWeekday, setErrorWeekday] = useState(null)
  const [slots, setSlots] = useState({}) 
  const [loadingSlots, setLoadingSlots] = useState([])
  const [loadingWeekday, setLoadingWeekday] = useState(null)
  const [removeSlot, setRemoveSlot] = useState()
  const [recurrentSchedule, setRecurrentSchedule] = useState({
    0: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    1: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    2: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    3: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    4: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    5: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    },
    6: {
      startTime: {value: '', isValid: true, message: ''},
      endTime: {value: '', isValid: true, message: ''},
    }
  })
  const [singleSchedule, setSingleSchedule] = useState({
    date: new Date(),
    endTime: {
      value: '',
      isValid: true,
      message: '',
    },
    startTime: {
      value: '',
      isValid: true,
      message: '',
    },
  })

  const tabs = [
    {
      title: (<Trans>Recorrente</Trans>),
      code: 'recurrent-schedule-tab',
    },
    {
      title: (<Trans>Avulsa</Trans>),
      code: 'single-schedule-tab',
    },
  ]

  useEffect(() => {
    getSlots()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (selectedWeekDay) {
      const element = Object.values(itemsRef.current)[selectedWeekDay]
      if (element) {
        const topbarHeight = isMobile ? 96 : 150
        const y = element.getBoundingClientRect().top - element.offsetHeight - topbarHeight
        slotsContainerRef.current.scrollTop += y
      }
    }
  })

  async function getSlots() {
    const { username } = user

    setLoading(true)

    return axios.get(apiEndpoints.AGENDA_SLOTS(username, 'weekday'))
      .then((resp) => {
        const { slots } = resp.data
        if (slots) {
          setLoading(false)
          setSlots(slots)
        }
      })
  }

  async function addSlot(dt, startTime, endTime) {
    const startDate = new Date(+dt)
    startDate.setHours(startTime.substring(0, 2), startTime.substring(3), 0, 0)

    const endDate = new Date(+dt)
    endDate.setHours(endTime.substring(0, 2), endTime.substring(3), 0, 0)

    if(endTime.substring(0, 2) === '00') {
      endDate.setDate(endDate.getDate() + 1)
    }

    const { username } = user

    return axios.post(apiEndpoints.AGENDA_SLOTS(username, false), {
      'start_date': formatISO(startDate),
      'end_date': formatISO(endDate),
    })
  }

  async function removeSlots(weekday, weekdaySlots, removalConfirmed = false) {
    if (weekdaySlots.length <= 0)
      return Promise.resolve(true)

    setLoadingWeekday(weekday)
    setLoadingSlots(weekdaySlots.map(slot => slot?.id))

    const { username } = user

    return axios.delete(apiEndpoints.AGENDA_SLOTS(username), {
      params: {
        ids: weekdaySlots.map(slot => slot?.id).join(','),
        removal_confirmed: removalConfirmed,
      }
    })
      .then(async () => {
        return getSlots().then(() => {

          setError(false)
          setErrorWeekday(null)

          return true
        })
      })
      .catch((err) => {
        const { restrictions } = err.response.data.data

        setError(restrictions.join(', '))
        setErrorWeekday(weekday)

        return false
      })
      .then(() => {

        setLoadingSlots([])
        setLoadingWeekday(null)
      })
  }

  function handleSingleTimeInput(e, position) {
    e.preventDefault()
    let inputValue = e.target.value
    let cleanInput = inputValue.replace('_', '')

    if(cleanInput.length <= 1) return

    let validation = new InputValidation(cleanInput, 'integerHour').validate()
    let statePosition

    if (position === 'start') {
      statePosition = 'startTime'
    } else if (position === 'end') {
      statePosition = 'endTime'
    }

    setSingleSchedule(prevState => ({
      ...prevState,
      [statePosition]: {
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      }
    }))
    if (validation.success)
      document.querySelector('#end-time').focus()
  }

  function handleRecurringTimeInput(e, weekday, position) {
    e.preventDefault()
    let inputValue = e.target.value
    let cleanInput = inputValue.replace('_', '')

    if(cleanInput.length <= 1) return

    let validation = new InputValidation(cleanInput, 'integerHour').validate()
    let statePosition
    let nextFocusId

    if (position === 'start') {
      statePosition = 'startTime'
      nextFocusId = `end-time-${weekday}`
    } else if (position === 'end') {
      statePosition = 'endTime'
      nextFocusId = `add-schedule-${weekday}`
    }

    const newRecurrentSchedule = Object.values(recurrentSchedule).map(schedule => ({ ...schedule }))

    newRecurrentSchedule[weekday][statePosition].value = inputValue
    newRecurrentSchedule[weekday][statePosition].isValid = validation.success
    newRecurrentSchedule[weekday][statePosition].message = validation.message

    setRecurrentSchedule(newRecurrentSchedule)

    if (validation.success)
      document.querySelector(`#${nextFocusId}`).focus()
  }

  function isStartEndTimeValid(startTime, endTime) {

    const validated = {
      valid: true,
      message: '',
    }

    if (startTime > endTime) {

      if (endTime === '00') return validated

      validated.valid = false
      validated.message = 'Horário inicial não pode ser maior que o final'

      return validated
    }

    if (startTime === endTime) {

      validated.valid = false
      validated.message = 'Horário inválido'
      
      return validated
    }

    return validated
  }

  function checkRecurringHour(weekday) {
    const startTime = recurrentSchedule[weekday].startTime.value
    const endTime = recurrentSchedule[weekday].endTime.value

    const { valid, message } = isStartEndTimeValid(startTime, endTime)

    if(!valid) {
      const newRecurrentSchedule = Object.values(recurrentSchedule).map(schedule => ({ ...schedule }))
      
      newRecurrentSchedule[weekday]['startTime'].isValid = valid
      newRecurrentSchedule[weekday]['endTime'].isValid = valid
      newRecurrentSchedule[weekday]['startTime'].message = message
      
      setRecurrentSchedule(newRecurrentSchedule)
    }

    return valid
  }

  function checkSingleHour() {
    const startTime = singleSchedule.startTime.value
    const endTime = singleSchedule.endTime.value

    const { valid, message } = isStartEndTimeValid(startTime, endTime)

    if(!valid) {
      const newSingleSchedule = { ...singleSchedule }

      newSingleSchedule.startTime.isValid = valid
      newSingleSchedule.endTime.isValid = valid
      newSingleSchedule.startTime.message = message

      setSingleSchedule(newSingleSchedule)
    }

    return valid
  }

  function handleSaveButton() {
    const { username } = user

    if (currentTab === 'recurrent-schedule-tab') {

      dispatch(hideModal())
      dispatch(getSlot(username))

    } else {

      const hoursValid = checkSingleHour()

      if(!hoursValid) return

      const startTime = `${singleSchedule.startTime.value}:00`

      const endTime = singleSchedule.endTime.value === '24'
        ? '00:00' : `${singleSchedule.endTime.value}:00`

      setLoading(true)
      addSlot(
        singleSchedule.date,
        startTime,
        endTime,
      ).then(() => {
        setLoading(false)
        dispatch(hideModal())
        dispatch(getSlot(username))
      })
    }
  }

  async function handleRecurringSaveButton(weekday) {
    const hoursValid = checkRecurringHour(weekday)

    if(!hoursValid) return

    const nextWeekday = nextDay(endOfYesterday(), parseInt(weekday) + 1)

    const startDate = new Date(nextWeekday)
    startDate.setHours(recurrentSchedule[weekday].startTime.value, 0, 0, 0)

    const endDate = new Date(nextWeekday)
    endDate.setHours(recurrentSchedule[weekday].endTime.value !== '24' ? recurrentSchedule[weekday].endTime.value : 0, 0, 0, 0)

    if(recurrentSchedule[weekday].endTime.value === '00' || recurrentSchedule[weekday].endTime.value === '24') {
      endDate.setDate(endDate.getDate() + 1)
    }

    setLoadingWeekday(weekday)

    const { username } = user

    const newRecurrentSchedule = recurrentSchedule.map(schedule => ({ ...schedule }))

    try {
      await axios.post(apiEndpoints.AGENDA_SLOTS(username), {
        'start_date': formatISO(startDate),
        'end_date': formatISO(endDate),
        'repeat_interval': 'weekly',
      })

      getSlots().then(() => {
        setLoadingWeekday(null)

        newRecurrentSchedule[weekday].startTime.value = ''
        newRecurrentSchedule[weekday].startTime.isValid = true
        newRecurrentSchedule[weekday].startTime.message = ''
        newRecurrentSchedule[weekday].endTime.value = ''
        newRecurrentSchedule[weekday].endTime.isValid = true
        newRecurrentSchedule[weekday].endTime.message = ''
        setRecurrentSchedule(newRecurrentSchedule)
      })
      setLoadingWeekday(null)
    } catch (err) {
      const { status } = err.response

      if (status === 400) {
        setLoadingWeekday(null)

        newRecurrentSchedule[weekday].startTime.isValid = false
        newRecurrentSchedule[weekday].endTime.isValid = false
        newRecurrentSchedule[weekday].startTime.message = 'Este horário já está cadastrado como avulso.'
        setRecurrentSchedule(newRecurrentSchedule)
      }
    }

    
  }

  function renderError(weekday, weekdaySlots) {
    if (!error) return

    const newWeekdaySlots = []
    const slotError = weekdaySlots.find(slot => slot.id === removeSlot?.id)
    newWeekdaySlots.push(slotError)

    const onCancelError = () => {
      setErrorWeekday(null)
    }

    return (
      <>
        <div className="ScheduleModal__error">
          <div className="ScheduleModal__error__icon">
            <CoreIcons
              name='AlertIcon'
              height='24px'
              width='24px'
              fill='#FD4949'
            />
          </div>
          <div className='ScheduleModal__error__text'>
            <Typo variant='content2' color='danger'>
              Você tem reserva às {error}.<br/>
              Ao excluir, você remove esta disponibilidade, mas a reserva continua existindo para o paciente e pode ser cancelada na área <i>Reservas</i>.
            </Typo>
          </div>
        </div>
        <div className='ScheduleModal__error__actions'>
          <Button
            variant='contained'
            color='danger'
            customClassName='ScheduleModal__error__actions__button'
            onClick={() => removeSlots(weekday, newWeekdaySlots, true)}
          >
            Excluir
          </Button>
          <Button
            variant='outlined'
            customClassName='ScheduleModal__error__actions__buttonRight'
            onClick={onCancelError}
          >
            Cancelar
          </Button>
        </div>
      </>
    )
  }
  

  function renderRecurring() {
    const weekdayNames = getWeekdays()

    let result = Object.keys(slots).map(weekday => {
      const weekdaySlots = slots[weekday].filter(slot => slot.repeat_interval)
      const weekdayName = weekdayNames.filter(w => w.index === parseInt(weekday))[0].weekday
      const slotStyle = clsx({
        'ScheduleModal__weekday__slots__slot': true,
        'ScheduleModal__weekday__slots__slot__error': errorWeekday === weekday
      })

      return (
        <>
          <div className="ScheduleModal__weekday" key={weekday} id={`weekday-${weekday}`} ref={elem => itemsRef.current[weekday] = elem}>
            <Row className="ScheduleModal__weekday__title" direction="row" align="center">
              <Typo variant="content2" weight="bold">
                {weekdayName}
              </Typo>
            </Row>
            
              <>
                <div className="ScheduleModal__weekday__time">
                  <div className="ScheduleModal__weekday__child ScheduleModal__weekday__input">
                    <TextInput
                      id={`start-time-${weekday}`}
                      name={`start-time-${weekday}`}
                      mask="11"
                      placeholder="00"
                      type="tel"
                      onInputChange={(e) => handleRecurringTimeInput(e, weekday, 'start')}
                      value={recurrentSchedule[weekday].startTime.value}
                      hasError={!recurrentSchedule[weekday].startTime.isValid}
                    />
                    <span>:</span>
                    <TextInput
                      disabled
                      mask="11"
                      placeholder="00"
                      type="tel"
                    />
                  </div>
                  <div className="ScheduleModal__weekday__child">
                    <Typo variant="content2" color="light">
                      às
                    </Typo>
                  </div>
                  <div className="ScheduleModal__weekday__child ScheduleModal__weekday__input">
                    <TextInput
                      id={`end-time-${weekday}`}
                      name={`end-time-${weekday}`}
                      mask="11"
                      placeholder="00"
                      type="tel"
                      onInputChange={(e) => handleRecurringTimeInput(e, weekday, 'end')}
                      value={recurrentSchedule[weekday].endTime.value}
                      hasError={!recurrentSchedule[weekday].endTime.isValid}
                    />
                    <span>:</span>
                    <TextInput
                      disabled
                      mask="11"
                      placeholder="00"
                      type="tel"
                    />
                  </div>
                  <div className="ScheduleModal__weekday__child ScheduleModal__weekday__button">
                    <Button
                      id={`add-schedule-${weekday}`}
                      variant="outlined"
                      uppercase={false}
                      disabled={
                        loadingWeekday === weekday
                        || recurrentSchedule[weekday].startTime.value.length <= 0
                        || recurrentSchedule[weekday].endTime.value.length <= 0
                        || !recurrentSchedule[weekday].startTime.isValid
                        || !recurrentSchedule[weekday].endTime.isValid
                      }
                      onClick={() => handleRecurringSaveButton(weekday)}
                    >
                      Incluir
                    </Button>
                  </div>
                </div>
                {!recurrentSchedule[weekday].startTime.isValid &&
                  <div className="ScheduleModal__weekday__hourError">* {recurrentSchedule[weekday].startTime.message}</div>
                }
                <div className="ScheduleModal__weekday__slots">
                  {weekdaySlots.map(slot => {

                    const endTime = format(new Date(slot.end_date), 'HH:mm') === '23:59' ? '00:00' : format(new Date(slot.end_date), 'HH:mm')

                    return (
                      <div key={slot.id} className={slotStyle}>
                        <SlotTag
                          value={slot.id}
                          startTime={format(new Date(slot.start_date), 'HH:mm')}
                          endTime={endTime}
                          loading={loadingSlots.includes(slot.id)}
                          onClose={ () => {
                            setRemoveSlot(slot)
                            removeSlots(weekday, [slot])
                          } }
                          error={(removeSlot && errorWeekday === weekday) && slot.id === removeSlot.id}
                        />
                      </div>
                    )
                  })}
                </div>
                {
                  errorWeekday === weekday && 
                  renderError(weekday, weekdaySlots)
                }
              </>
            
          </div>
          <LineSeparator />
        </>
      )
    })

    return (
      <>
        <div className="ScheduleModal__warn">
          <Typo variant="content3">
            Os horários adicionados aqui <strong>sempre aparecerão como disponíveis</strong> para os pacientes agendarem.
          </Typo>
        </div>
        {result}
      </>
    )
  }

  function renderSingle() {
    return (
      <>
        <div className="ScheduleModal__warn">
          <Typo variant="content3">
            Será mostrada como disponível para os pacientes agendarem <strong>apenas no dia selecionado</strong>.
          </Typo>
        </div>
        <Row direction="column"
          className="ScheduleModal__single"  
        >
          <Typography align='center'>
            <Trans>
              Adicionar disponibilidade em:
            </Trans>
          </Typography>
          <Row margin="0 auto 2.5rem">
            <div className="ScheduleModal__single__date">
              <CalendarInput
                daysUnavailable={[{ before: new Date() }]}
                handleDayChange={(day) => setSingleSchedule(prevState => ({
                  ...prevState,
                  date: day,
                }))}
                selectedDay={new Date()}
              />
            </div>
          </Row>
          <Typography align='center'>
            <Trans>
              Defina um horário:
            </Trans>
          </Typography>
          <Row>
            <div className="ScheduleModal__single__time">
              <div className="ScheduleModal__single__child">
                <TextInput 
                  name={'start-time'}
                  mask="11"
                  placeholder="00"
                  type="tel"
                  onInputChange={(e) => handleSingleTimeInput(e, 'start')}
                  value={singleSchedule.startTime.value}
                  hasError={!singleSchedule.startTime.isValid}
                />
                <span>:</span>
                <TextInput 
                  mask="11"
                  placeholder="00"
                  type="tel"
                  disabled
                />
              </div>
              <div className="ScheduleModal__single__child">
                <Typography>
                  até
                </Typography>
              </div>
              <div className="ScheduleModal__single__child">
                <TextInput 
                  name={'end-time'}
                  mask="11"
                  type="tel"
                  placeholder="00"
                  onInputChange={(e) => handleSingleTimeInput(e, 'end')}
                  value={singleSchedule.endTime.value}
                  hasError={!singleSchedule.endTime.isValid}
                />
                <span>:</span>
                <TextInput 
                  mask="11"
                  placeholder="00"
                  type="tel"
                  disabled
                />
              </div>
            </div>
          </Row>
          {
            !singleSchedule.startTime.isValid ?
              (
                <Row className="ScheduleModal__single__error"
                  margin="1.5rem 0 0">
                  <Typography color="false"
                    type="subheading2">
                      * {singleSchedule.startTime.message}
                  </Typography>
                </Row>
              ) : null
          }
          {
            !singleSchedule.endTime.isValid && singleSchedule.endTime.message ?
              (
                <Row className="ScheduleModal__single__error"
                  margin="1.5rem 0 0">
                  <Typography color="false"
                    type="subheading2">
                      * {singleSchedule.endTime.message}
                  </Typography>
                </Row>
              ) : null
          }
        </Row>
      </>
    )
  }

  return (
    <ModalGeneric
      btnId="save-schedule"
      btnText={currentTab === 'recurrent-schedule-tab' ? <Trans>CONCLUIR</Trans> : <Trans>ADICIONAR DISPONIBILIDADE</Trans>}
      btnOnClick={handleSaveButton}
      btnLoading={loading}
      hasButton
      error={!!errorWeekday}
      onCloseModal={handleSaveButton}
      modalTitle={(<Trans>Disponibilidade Online</Trans>)}
      containerRef={slotsContainerRef}
      btnDisabled={
        currentTab === 'single-schedule-tab' ? (
          singleSchedule.startTime.value === '' ||
            singleSchedule.endTime.value === '' ||
            !singleSchedule.startTime.isValid ||
            !singleSchedule.endTime.isValid
        ) : false
      }>

      <div className={'ScheduleModal'}>

        <div className="ScheduleModal__tabs">
          <Tabs
            tabs={tabs}
            selectedItem={currentTab}
            onSelectTab={setCurrentTab}
          />
        </div>
        {
          currentTab === 'recurrent-schedule-tab'
            ? renderRecurring()
            : renderSingle()
        }
      </div>
      <ModalGeneric.Group>
        <TimestampTag />
      </ModalGeneric.Group>
    </ModalGeneric>
  )
}

export default ScheduleModal
