import {
  REGISTER_INFO_GROUP,
  REGISTER_INFO_PERSONAL,
  REGISTER_PHONE_CHECK,
  REGISTER_GROUP_SELECTION,
} from './_actionTypes';

export const registerGroupSelection = (payload) => {
  return {
    type: REGISTER_GROUP_SELECTION,
    payload: payload,
  };
};

export const registerInfoGroup = (payload) => {
  return {
    type: REGISTER_INFO_GROUP,
    payload: payload,
  };
};

export const registerInfoPersonal = (payload) => {
  return {
    type: REGISTER_INFO_PERSONAL,
    payload: payload,
  };
};

export const registerPhoneCheck = (payload) => {
  return {
    type: REGISTER_PHONE_CHECK,
    payload: payload,
  };
};