import React, { Component } from 'react';
import { Trans } from "@lingui/macro";

import Modal from '../Modal';
import Row from '../../../../components/Row';
import { Typography } from 'components';

class SuccessPlanAdded extends Component {
  state = { visibility: true }

  handleCloseButton = () => {
    this.setState({ visibility: !this.state.visibility });
  }

  render() {
    return (
      this.state.visibility ? (
        <div className="SuccessPlanAdded">
          <Modal
            modalTitle="Convênio vinculado com sucesso! Agora é só aguardar a autorização."
            btnText="Fechar"
            hasButton={true}
            handleClick={this.handleCloseButton}
            closeModal={this.handleCloseButton}>
            <Row margin="15px 0 0"
              justify="center">
              <Typography
                align="center"
                style={{ maxWidth: "23rem" }}>
                <Trans>
                  Pediremos a autorização junto ao seu convênio e em
                    </Trans>
                <Typography bold
                  style={{ maxWidth: "24.8rem" }}
                  Tag="span">
                  {" "}
                  <Trans>
                    até 72 horas
                      </Trans>
                  {" "}
                </Typography>
                <Trans>
                  você receberá a confirmação por e-mail e sms.
                    </Trans>
              </Typography>
            </Row>

            <Row margin="15px 0 0" justify="center">
              <Typography align="center" style={{ maxWidth: "25rem" }}>
                <Trans>
                  Enquanto você aguarda a aprovação, ainda não será possível agendar consultas.
                </Trans>
              </Typography>
            </Row>

            <Row margin="15px 0 0" justify="center">
              <Typography align="center" style={{ maxWidth: "24.8rem" }}>
                <Trans>Aguarde nosso contato.</Trans>
              </Typography>
            </Row>
          </Modal>
        </div>
      ) : null
    );
  }
}

export default SuccessPlanAdded;