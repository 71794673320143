import styled from 'styled-components'

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Content = styled.div`
  position: relative;
  min-height: 100px;
`
