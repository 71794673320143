import React from "react";

const User = ({
  fill = "898989",
  height,
  viewBox = "0 0 30.356 36.43",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path id="Caminho_2204_1_" d="M18.215,31.51H12.141A12.141,12.141,0,0,0,0,43.651H0V47.3a1.215,1.215,0,0,0,2.429,0V43.651a9.711,9.711,0,0,1,9.712-9.712h6.074a9.711,9.711,0,0,1,9.712,9.712V47.3a1.215,1.215,0,0,0,2.429,0V43.651A12.137,12.137,0,0,0,18.215,31.51Z" transform="translate(0 -12.08)" fill={fill}/>
    <path id="Caminho_2205_1_" d="M19.333,17a8.5,8.5,0,1,0-8.5-8.5,8.5,8.5,0,0,0,8.5,8.5Zm0-14.571A6.074,6.074,0,1,1,13.259,8.5a6.074,6.074,0,0,1,6.074-6.074Z" transform="translate(-4.152)" fill={fill}/>
  </svg>
);

export default User;