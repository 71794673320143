import React from 'react';

const Twitter = ({
  fill,
  height,
  width,
  viewBox,
}) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}>
      <path d="M17.5 0A17.5 17.5 0 1 0 35 17.5 17.52 17.52 0 0 0 17.5 0zm7.807 13.495c.008.173.012.348.012.523a11.458 11.458 0 0 1-17.635 9.65A8.133 8.133 0 0 0 13.647 22a4.032 4.032 0 0 1-3.763-2.8 4.043 4.043 0 0 0 1.819-.068 4.029 4.029 0 0 1-3.231-3.95v-.051a4.011 4.011 0 0 0 1.824.5A4.034 4.034 0 0 1 9.05 10.26a11.437 11.437 0 0 0 8.3 4.21 4.031 4.031 0 0 1 6.864-3.675 8.04 8.04 0 0 0 2.559-.978A4.046 4.046 0 0 1 25 12.046a8.008 8.008 0 0 0 2.314-.634 8.1 8.1 0 0 1-2.007 2.083z" fill="#fff" />
    </svg>
  );
}

export default Twitter;