import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { AxiosResponse } from 'axios'

interface Payload {
  plans: Array<{
    plan_code: string
    accept_new_patients: boolean
  }>,
  professionalSlug: string
}

interface Error {
  response: {
    status: number
  }
}

interface EnableNewPatientsData {
  plan_code: string
  user_updated: string
  date_updated: string
  user_updated_full_name: string
}

interface Response extends AxiosResponse {
  enable_new_patients: Array<EnableNewPatientsData>
}


export const disableNewPatients = ({
  plans,
  professionalSlug
}: Payload): Promise<EnableNewPatientsData[]> => {

  const endpoint = `${apiEndpoints.PROFESSIONALS}${professionalSlug}/`

  const params = {
    enable_new_patients: plans,
  }

  return new Promise<EnableNewPatientsData[]> ((resolve, reject) => {
    axios.patch(endpoint, params)
      .then((response: Response) => {
        const data = response.enable_new_patients
        resolve(data)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}
