interface Action {
  action: ActionsOptions
  btnText: string
  modalTitle: string
  headerText: string
  warningText: string
  successMessage: string
}

export enum ActionsOptions {
  PUBLISH = 'Publicar',
  UNPUBLISH = 'Despublicar',
}

export const actions: Action[] = [
  {
    action: ActionsOptions.PUBLISH,
    btnText: 'CONFIRMAR PUBLICAÇÃO',
    modalTitle: 'Publicar depoimento',
    headerText: 'Leia atentamente e avalie se não há conteúdo impróprio, ofensivo ou compartilhamento de informações pessoais:',
    warningText: 'Ao confirmar a publicação, o depoimento aparecerá automaticamente em seu perfil público, e contabilizará como mais uma avaliação de paciente.',
    successMessage: 'Depoimento publicado com sucesso!',
  },
  {
    action: ActionsOptions.UNPUBLISH,
    btnText: 'SIM, QUERO DESPUBLICAR',
    modalTitle: 'Despublicar depoimento',
    headerText: 'Tem certeza que deseja remover este depoimento da sua página de perfil?',
    warningText: 'Ao confirmar, ele voltará para a sua caixa de depoimentos na sua lista de recebidos.',
    successMessage: 'Depoimento despublicado com sucesso!',
  },
]