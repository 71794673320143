import React from "react";

const Refresh = ({
  fill = "#1f35b5",
  height = "20",
  viewBox = "0 0 16 20",
  width = "16",
}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
      <path id="Caminho_3612" data-name="Caminho 3612" d="M12,5V1L7,6l5,5V7a6,6,0,1,1-6,6H4a8,8,0,1,0,8-8Z" transform="translate(-4 -1)" fill={fill} />
    </svg>

  );

export default Refresh;