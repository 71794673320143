import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";

import Row from "../../../components/Row";
import viewportHoc from "../../../hoc/viewportHoc";
import { Typography, Button, Column, TextInput } from 'components';
import Dropdown from "../../../components/Dropdown";

import InputValidation from "../../../utils/InputValidation";
import { REPORT } from "../../../settings/_reportSettings";
import { removeMaskInputValue } from "../../../utils/textTransformations";
import { generateReport, clearReport } from "../../../redux/actions";
import { dateToIso, toString } from "../../../utils/dateTimeHandlers";
import moment from "moment";

class Reports extends Component {
  state = {
    reportCategory: {
      value: "",
      isValid: true,
    },
    start_date: {
      value: "",
      isValid: true,
      message: "",
    },
    end_date: {
      value: "",
      isValid: true,
      message: "",
    },
    canGenerate: false,
    loading: false
  };

  componentDidMount() {
    this.checkIfCanGenerate();
  }

  componentDidUpdate() {
    this.checkIfCanGenerate();
  }

  componentWillUnmount() {
    this.props.onClearReport();
  }

  handleSelectOption = code => {
    this.setState({
      reportCategory: {
        ...this.state.reportCategory,
        value: code,
        isValid: true,
      },
    });
  };

  handleGenerateFile = async() => {
    this.setState({ loading: true })

    const reportType = this.state.reportCategory.value;
    const startDate = toString(dateToIso(this.state.start_date.value));
    const endDate = toString(dateToIso(this.state.end_date.value) + ' 23:59:59');

    if(reportType === 'schedules'){
      await this.props.onReportRequest({
        type: reportType,
        start_date: startDate,
        end_date: endDate,
      });
    }else{
      await this.props.onReportRequest({
        type: reportType,
        start_date__gte: startDate,
        start_date__lte: endDate,
        profile_role: 'PACI'
      });
    }

    this.setState({ loading: false })
  }

  checkIfCanGenerate = () => {
    let canGenerate = true;
    const requiredStates = [ "reportCategory", "start_date", "end_date" ];

    requiredStates.map(rq => {
      if (
        this.state[rq].value === "" ||
        this.state[rq].value === undefined ||
        this.state[rq].value === null ||
        this.state[rq].isValid === false
      ) {
        canGenerate = false;
      }
      return canGenerate;
    });

    if (canGenerate !== this.state.canGenerate) {
      this.setState({ canGenerate });
    }

    return canGenerate;
  };

  checkInterval = () => {
    if(moment(this.state.end_date.value, 'DD/MM/YYYY').isBefore(moment(this.state.start_date.value, 'DD/MM/YYYY'))){
      this.setState({
        end_date: {
          ...this.state.end_date,
          isValid: false,
          message: "A data informada é inválida",
        },
      });
    }
  }

  handleStartDateInput = e => {
    e.preventDefault();
    const inputValue = removeMaskInputValue(e.target.value);

    let validation = new InputValidation(inputValue, "date").validate();

    this.setState({
      start_date: {
        ...this.state.start_date,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    }, () => this.checkInterval());
    
  };

  handleEndDateInput = e => {
    e.preventDefault();
    const inputValue = removeMaskInputValue(e.target.value);

    let validation = new InputValidation(inputValue, "date").validate();

    this.setState({
      end_date: {
        ...this.state.end_date,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    }, () => this.checkInterval());
  };

  render() {
    const reportOptions = REPORT;
    this.checkIfCanGenerate();

    return (
      <Fragment>
        <Row
          align="center"
          justify={this.props.isDesktop ? "space-between" : "center"}
          margin={this.props.isDesktop ? "3.9rem 0 2.1rem" : "5rem 0 1.5rem"}>
          <Typography align="mcenter-dleft" Tag="h1" type="title">
            <Trans>Relatório</Trans>
          </Typography>
        </Row>
        <Row
          justify={this.props.isDesktop ? "flex-start" : "center"}
          margin={this.props.isDesktop ? "3.9rem 0 2.1rem" : "2.5rem 0 1.5rem"}>
          <Typography
            align={this.props.isDesktop ? "left" : "center"}
            color="grey-eleven">
            <Trans>
              Defina as datas de início e fim para gerar a planilha com todos os
              dados das consultas.
            </Trans>
          </Typography>
        </Row>
        <Column colWidth={6}>
          <Dropdown
            options={reportOptions}
            onSelect={code => this.handleSelectOption(code)}
            placeholder="Selecione uma categoria"
            name="reportCategory"
            selectedItem={this.state.reportCategory.value}
            preSelectedItem={this.state.reportCategory.value}
          />
          <div className="report">
            <div className="report--text">
              <Trans>De</Trans>
            </div>
            <div className="report--input">
              <TextInput
                placeholder="Digite a data inicial"
                required={true}
                name="start_date"
                hasError={!this.state.start_date.isValid}
                inputHeight="4.8rem"
                mask="11/11/1111"
                messageError={this.state.start_date.message}
                onInputChange={e => this.handleStartDateInput(e)}
                value={this.state.start_date.value}
              />
            </div>
          </div>
          <div className="report">
            <div className="report--text">
              <Trans>Até</Trans>
            </div>
            <div className="report--input">
              <TextInput
                placeholder="Digite a data fim"
                required={true}
                name="end_date"
                hasError={!this.state.end_date.isValid}
                inputHeight="4.8rem"
                mask="11/11/1111"
                messageError={this.state.end_date.message}
                onInputChange={e => this.handleEndDateInput(e)}
                value={this.state.end_date.value}
              />
            </div>
          </div>
        </Column>

        <Column className="report--button" colWidth={6}>
          <Button
            text={`EXPORTAR PLANILHA`}
            onButtonClick={() => this.handleGenerateFile()}
            disabled={this.state.canGenerate ? false : true}
            loading={this.state.loading}
          />
        </Column>

        <Row />
        {/* 
        <Row>
          <div className="dashboard-person-list">
            {loaded ? renderList : <Spinner height="80%" />}
          </div>
        </Row> */}
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuth,
    userData: state.auth.userData,
    reports: state.reports.report,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onReportRequest: params => dispatch(generateReport(params)),
    onClearReport: () => dispatch(clearReport()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(Reports));
