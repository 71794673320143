import React from "react";

const ArrowBack = ({
  fill,
  height,
  viewBox = "0 0 16 16",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path data-name="Caminho 2330" d="M13.64 2.35A8 8 0 1 0 15.72 10h-2.08a6 6 0 1 1-5.65-8 5.915 5.915 0 0 1 4.22 1.78L8.99 7h7V0z" fill={fill}/>
  </svg>
);


export default ArrowBack;