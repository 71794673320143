export const childAgeRangeOptions = [
  {id: 1, label: 'A partir de 3 anos (até 12)', startAge: 3, endAge: 12},
  {id: 2, label: 'A partir de 4 anos (até 12)', startAge: 4, endAge: 12},
  {id: 3, label: 'A partir de 5 anos (até 12)', startAge: 5, endAge: 12},
  {id: 4, label: 'A partir de 6 anos (até 12)', startAge: 6, endAge: 12},
  {id: 5, label: 'A partir de 7 anos (até 12)', startAge: 7, endAge: 12},
  {id: 6, label: 'A partir de 8 anos (até 12)', startAge: 8, endAge: 12},
  {id: 7, label: 'A partir de 9 anos (até 12)', startAge: 9, endAge: 12},
  {id: 8, label: 'A partir de 10 anos (até 12)', startAge: 10, endAge: 12},
  {id: 9, label: 'A partir de 11 anos (até 12)', startAge: 11, endAge: 12},
  {id: 10, label: 'Somente 12 anos', startAge: 12, endAge: 12},
]

export const teenagerAgeRangeOptions = [
  {id: 1, label: 'A partir de 13 anos (até 17)', startAge: 13, endAge: 17},
  {id: 2, label: 'A partir de 14 anos (até 17)', startAge: 14, endAge: 17},
  {id: 3, label: 'A partir de 15 anos (até 17)', startAge: 15, endAge: 17},
  {id: 4, label: 'A partir de 16 anos (até 17)', startAge: 16, endAge: 17},
  {id: 5, label: 'Somente 17 anos', startAge: 17, endAge: 17},
]