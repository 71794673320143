import React from "react";

const Positive = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
    <path xmlns="http://www.w3.org/2000/svg" d="M0 0h24v24H0z" fill="none"/>
  </svg>
);
  
export default Positive;