import styled from 'styled-components'

interface WrapperLabelsProps {
  isMobile: boolean
}

interface ContentProps {
  isLoading: boolean
}

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div<ContentProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  opacity: ${({ isLoading }) => isLoading && 0};
`

export const WrapperLabels = styled.div<WrapperLabelsProps>`
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 36px;
  margin-top: 20px;
  width: ${({ isMobile }) => (isMobile && 'auto')};
`

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`