export const groupBy =(prop)=>{
  return prop.reduce((groups, schedule) => {
    const date = schedule.date
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(schedule);
    return groups;
  }, {});
    
};