import React, { Component } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import MaskedInput from 'react-maskedinput'

import { CoreIcons } from 'components'

class TextInput extends Component {
  state = { showPassword: false }

  handleShowPassword = () => this.setState(state => ({ showPassword: !state.showPassword }));

  getKeyboardType = (type) => {
    const { showPassword } = this.state
    const { isPassword, mask } = this.props

    // Ocultar/mostrar senhas
    if (isPassword) return showPassword ? 'text' : 'password'

    // Somente números com base na máscara informada (ex: mask=111.111.11)
    // Nota: type TEL ao invés de NUMBER devido a limitação do react-maskedinput
    if (mask && mask.includes('1') && !mask.includes('a') && !mask.includes('A') && !mask.includes('*') && !mask.includes('#'))
      return 'tel'

    return type
  }

  handlePasteEvent = (e) => {
    const { canPaste } = this.props
    if (!canPaste) e.preventDefault()

    return canPaste
  }

  render() {
    const {
      addIcon,
      autofocus,
      disabled,
      hasError,
      errorOnTop,
      iconProps,
      inputClassName,
      inputHeight,
      isPassword,
      mask,
      maxLength,
      messageError,
      name,
      onClickIcon,
      onInputChange,
      onFocusOut,
      onKeyPress,
      onKeyDown,
      placeholder,
      readOnly,
      required,
      size,
      type,
      value,
      customClassName,
      ...rest
    } = this.props

    let { showPassword } = this.state

    let InputType
    mask ?
      InputType = MaskedInput :
      InputType = 'input'

    return (
      <div className={clsx({
        'TextInput': true,
        [customClassName]: Boolean(customClassName)
      })}>
        {
          hasError && errorOnTop &&
          <div className="messageError__topError">
            <span className="spanError"> {messageError} </span>
          </div>
        }
        <div className={`inputContent ${hasError ? 'error' : ''}`} >
          <InputType
            autoFocus={autofocus}
            disabled={disabled}
            readOnly={readOnly}
            id={name}
            mask={mask ? mask : false}
            name={name}
            onChange={onInputChange}
            onBlur={onFocusOut}
            onKeyPress={onKeyPress}
            onKeyDown={onKeyDown}
            placeholder={placeholder}
            required={required}
            size={size ? size : null}
            style={{ 'height': `${inputHeight}` }}
            type={this.getKeyboardType(type)}
            value={value === null ? '' : value}
            className={inputClassName}
            autoComplete="off"
            maxLength={maxLength}
            onPaste={(e) => this.handlePasteEvent(e)}
            onPasteCapture={(e) => this.handlePasteEvent(e)}
            onDrop={(e) => this.handlePasteEvent(e)}
            onDropCapture={(e) => this.handlePasteEvent(e)}
            {...rest}
          />

          {
            addIcon && (
              <span
                className="TextInput__Button"
                onClick={onClickIcon}>
                <CoreIcons {...iconProps} />
              </span>
            )
          }

          {
            isPassword ? (disabled ? null : (
              <span
                className='TextInput-password'
                onClick={() => this.handleShowPassword()}>
                <CoreIcons
                  fill='#acacac'
                  name={showPassword ? 'VisibilityOn' : 'VisibilityOff'}
                />
              </span>)) : null
          }
        </div>

        {
          hasError && !errorOnTop &&
          <div className="messageError">
            {
              typeof messageError === 'object' 
                ? <span className="spanError">{messageError}</span>
                : <span className="spanError" dangerouslySetInnerHTML={{ __html: messageError }} />      
            }
          </div>
        }
      </div>
    )
  }
}

TextInput.propTypes = {
  autofocus: PropTypes.bool,
  disabled: PropTypes.bool,
  canPaste: PropTypes.bool,
  hasError: PropTypes.any,
  hasIcon: PropTypes.bool,
  iconProps: PropTypes.any,
  isPassword: PropTypes.bool,
  inputClassName: PropTypes.string,
  mask: PropTypes.string,
  messageError: PropTypes.any,
  name: PropTypes.string,
  newClass: PropTypes.string,
  onClickIcon: PropTypes.func,
  onInputChange: PropTypes.func,
  onFocusOut: PropTypes.func,
  placeholder: PropTypes.any,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  size: PropTypes.number,
  type: PropTypes.string,
  value: PropTypes.any,
  maxLength: PropTypes.string,
}

TextInput.defaultProps = {
  autofocus: false,
  disabled: false,
  canPaste: true,
  hasError: false,
  inputHeight: '4.8rem',
  isPassword: false,
  placeholder: 'Placeholder text',
  readOnly: false,
  showPassword: false,
  type: 'text',
}

export { TextInput }
