import React from 'react';

const Facebook = ({
  fill,
  height,
  width,
  viewBox,
}) => {
  return (
    <svg
      preserveAspectRatio="xMidYMid meet"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}>
      <path d="M17.5 0A17.5 17.5 0 1 0 35 17.5 17.52 17.52 0 0 0 17.5 0zm4.354 17.114h-2.847v10.15h-4.22v-10.15H12.78v-3.586h2.006v-2.32a3.957 3.957 0 0 1 4.257-4.257l3.125.013v3.485H19.9a.859.859 0 0 0-.9.977v2.11h3.215zm0 0" fill="#fff"/>
    </svg>
  );
};

export default Facebook;