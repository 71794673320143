import React from "react";

const ExpandLess = ({
  fill,
  height,
  viewBox = "0 0 35 35",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path d="M17.497 11.862l-8.6 8.9 2.022 2.091 6.578-6.791 6.582 6.791 2.025-2.091z" />
    </g>
  </svg>
);

export default ExpandLess;