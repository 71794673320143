import {
  MODAL_HIDE,
  MODAL_SHOW,
} from './_actionTypes';

/* SHOW MODAL */
export const showModal = (modalType, modalData = {}) => dispatch => {
  dispatch({
    type: MODAL_SHOW,
    modalType: modalType,
    modalData: modalData,
  });
};

/* HIDE MODAL */
export const hideModal = () => dispatch => {
  dispatch({ type: MODAL_HIDE });
};

