import React from "react";

const AlertIcon = ({
  fill="#ff4949",
  width="22px",
  height="22px",
  viewBox = "0 0 22 22",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Caminho_2410" data-name="Caminho 2410" d="M11.9,16.3h2.2v2.2H11.9Zm0-8.8h2.2v6.6H11.9ZM12.989,2A11,11,0,1,0,24,13,10.995,10.995,0,0,0,12.989,2ZM13,21.8A8.8,8.8,0,1,1,21.8,13,8.8,8.8,0,0,1,13,21.8Z" transform="translate(-2 -2)" fill={fill}/>
  </svg>
);


export default AlertIcon;