import React from "react";

const Check = ({
  fill,
  height,
  viewBox = "0 0 20 20",
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}>
    <path fill={fill} d="M10 20A10.011 10.011 0 0 1 0 10 10.011 10.011 0 0 1 10 0a10.011 10.011 0 0 1 10 10 9.94 9.94 0 0 1-2.93 7.069A9.941 9.941 0 0 1 10 20zM5.846 9.051l-1.157 1.336 4.362 3.768 6.2-7.151-1.335-1.157-5.045 5.816-3.027-2.611z" />
  </svg>
);

export default Check;