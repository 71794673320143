// import moment from 'moment';

const INITIAL_STATE = {
  pacients: {
    total: 0,
    partial: 0,
    loading: true,
    error: false,
  },
  schedules: {
    total: 0,
    partial: 0,
    loading: true,
    error: false,
  },
  filters: {
    initialDate: null,
    finalDate: null,
    filteredDiffDays: null,
    plan: '',
    prevInitialDate: '',
    prevFinalDate: ''
  },
  appointmentsChart: {
    data: [],
    total: 0,
    loading: false,
    error: false
  },
  recomendationsChart: {
    data: [],
    total: 0,
    loading: false,
    error: false
  },
}

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_DATA':
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          loading: true,
        }
      };

    case 'LOAD_DATA_SUCCESS':
      return {
        ...state,
        [action.prop]: {
          ...state[action.payload],
          total: action.payload.total,
          partial: action.payload.partial,
          loading: false,
        }
      };

    case 'LOAD_DATA_FAILURE':
      return {
        ...state,
        [action.prop]: {
          ...state[action.payload],
          loading: false,
          error: true
        }
      };

    case 'GET_CHART_SUCCESS':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          data: action.payload.status || action.payload.answers,
          total: action.payload.total,
          loading: false,
        }
      }

    case 'GET_CHART_FAILURE':
      return {
        ...state,
        [action.prop]: {
          ...state[action.prop],
          loading: false,
          error: true
        }
      };

    case 'APPLY_FILTERS':
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      }

    case 'CLEAR_FILTERS':
      return {
        ...state,
        filters: {
          initialDate: null,
          finalDate: null,
          diffDays: 7,
          plan: '',
          prevInitialDate: '',
          prevFinalDate: ''
        }
      }

    default:
      return state;
  }
}

export { reducer };