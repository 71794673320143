import React from 'react'
import { Button, Spinner, Typography } from '@telavita-core/react-design-kit'

import { CoreIcons } from 'components'

import { ReactComponent as FulfilledAlertIcon } from 'static/svg/fulfilled-alert-icon.svg'

import * as S from './styles'

interface SlotTagProps {
  startTime: string
  endTime: string
  onClose: () => void
  loading?: boolean
  error?: boolean
}

interface StatusIconProps {
  loading: boolean
  error: boolean
  handleClick: () => void
}

const StatusIcon = ({ loading, error, handleClick }: StatusIconProps) => {
  if (loading) {
    return <Spinner customClassName='iconSpinner' /> 
  }

  if (error) {
    return <FulfilledAlertIcon />
  }

  return (
    <Button onClick={handleClick}>
      <CoreIcons
        name={'ErrorIcon'}
        height="24px"
        width="24px"
        fill='#cdcd85'
      />
    </Button>
  )
}

export const SlotTag: React.FC<SlotTagProps> = ({
  startTime,
  endTime,
  onClose,
  loading,
  error
}) => {
  
  return (
    <S.Container hasError={error}>
      
      <Typography variant='content2' customClassName='slotTagTime'>
        {startTime} às {endTime}
      </Typography>
        
      <S.IconWrapper>
        <StatusIcon 
          loading={loading} 
          error={error} 
          handleClick={onClose}  
        />
      </S.IconWrapper>
    </S.Container>
  )
}