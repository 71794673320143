/* PERSONS TYPES */
export const CHANGE_PERSON = 'CHANGE_PERSON'
export const CHANGE_PERSON_PROFILE = 'CHANGE_PERSON_PROFILE'
export const CLEAR_PERSON = 'CLEAR_PERSON'
export const CLEAR_PERSONS = 'CLEAR_PERSONS'
export const CLEAR_PERSONS_PROFESSIONALS = 'CLEAR_PERSONS_PROFESSIONALS'
export const CREATE_PERSON = 'CREATE_PERSON'
export const DELETE_PERSON = 'DELETE_PERSON'
export const GET_PERSON = 'GET_PERSON'
export const LIST_PERSONS = 'LIST_PERSONS'
export const LIST_PERSONS_PROFESSIONALS = 'LIST_PERSONS_PROFESSIONALS'
export const LIST_PROFESSIONALS = 'LIST_PROFESSIONALS'
export const START_LOADING_PERSONS = 'START_LOADING_PERSONS'
export const GET_PROFILE_PLAN = 'GET_PROFILE_PLAN'
export const GET_PROFESSIONAL_HISTORY = 'GET_PROFESSIONAL_HISTORY'
export const GET_PROFILE_ROLE = 'GET_PROFILE_ROLE'
export const SET_PERSON_PROFILE = 'SET_PERSON_PROFILE'
export const SET_PROFILE_PLAN = 'SET_PROFILE_PLAN'
export const UPDATE_PERSON = 'UPDATE_PERSON'
export const CLEAR_PROFESSIONALS = 'CLEAR_PROFESSIONALS'
export const CLEAR_CONCATED_PROFESSIONALS = 'CLEAR_CONCATED_PROFESSIONALS'

/* ASYNC TYPES */
export const ASYNC_CLEAN_ERRORS = 'ASYNC_CLEAN_ERRORS'
export const ASYNC_ERROR = 'ASYNC_ERROR'
export const ASYNC_REQUEST = 'ASYNC_REQUEST'
export const ASYNC_SUCCESS = 'ASYNC_SUCCESS'

/* AUTH TYPES */
export const AUTH_CHANGE_PLAN = 'AUTH_CHANGE_PLAN'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_LOGIN = 'AUTH_LOGIN'
export const AUTH_USER = 'AUTH_USER'
export const AUTH_CHANGE_PROFILE = 'AUTH_CHANGE_PROFILE'

/* DOCUMENT TYPES */
export const GET_DOCUMENTS = 'GET_DOCUMENTS'

/* EMAIL RECOVERY TYPES */
export const EMAIL_REC_SUCESS = 'EMAIL_REC_SUCESS'
export const EMAIL_REC_REQUEST = 'EMAIL_REC_REQUEST'
export const EMAIL_REC_FAILURE = 'EMAIL_REC_FAILURE'

/* FILTERS TYPES */
export const CLEAR_FILTER = 'CLEAR_FILTER'
export const DATE_FILTER = 'DATE_FILTER'
export const STATUS_FILTER = 'STATUS_FILTER'
export const TEXT_FILTER = 'TEXT_FILTER'
export const GENDER_STATUS = 'GENDER_STATUS'
export const SELECTED_DAY = 'SELECTED_DAY'
export const START_HOUR = 'START_HOUR'
export const END_HOUR = 'END_HOUR'
export const FILTER_DATE_HOURS = 'FILTER_DATE_HOURS'
export const PRICE_RANGE = 'PRICE_RANGE'

/* MODAL TYPES */
export const MODAL_HIDE = 'MODAL_HODE'
export const MODAL_SHOW = 'MODAL_SHOW'

/* REGISTER TYPES */
export const REGISTER_GROUP_SELECTION = 'REGISTER_GROUP_SELECTION'
export const REGISTER_INFO_GROUP = 'REGISTER_INFO_GROUP'
export const REGISTER_INFO_PERSONAL = 'REGISTER_INFO_PERSONAL'
export const REGISTER_STEP_THREE = 'REGISTER_STEP_THREE'
export const REGISTER_PHONE_CHECK = 'REGISTER_PHONE_CHECK'

/* SLOT ACTIONS */
export const GET_SLOTS_SUCCESS = 'GET_SLOTS_SUCCESS'
export const REMOVE_SLOTS_SUCCESS = 'REMOVE_SLOTS_SUCCESS'
export const REMOVE_SLOTS_FAILED = 'REMOVE_SLOTS_FAILED'
export const CLEAR_SLOT_STATUS = 'CLEAR_SLOT_STATUS'


/* SCHEDULE ACTIONS */
export const CLEAN_SCHEDULES = 'CLEAN_SCHEDULES'
export const START_LOADING_SCHEDULES = 'START_LOADING_SCHEDULES'
export const GET_SCHEDULES = 'GET_SCHEDULES'
export const ADD_SCHEDULE = 'ADD_SCHEDULE'
export const REMOVE_SCHEDULE = 'REMOVE_SCHEDULE'
export const SET_SCHEDULE_STATUS = 'SET_SCHEDULE_STATUS'
export const GET_SCHEDULE_LOGS = 'GET_SCHEDULE_LOGS'

/* AGENDA ACTIONS */
export const AGENDA_AVAILABILITY = 'AGENDA_AVAILABILITY'
export const CLEAR_AVAILABILITY = 'CLEAR_AVAILABILITY'
export const DAYS_OCCUPIED = 'DAYS_OCCUPIED'
export const CLEAR_PROFESSIONALS_AVAILABLE = 'CLEAR_PROFESSIONALS_AVAILABLE'
export const PROFESSIONALS_AVAILABLE = 'PROFESSIONALS_AVAILABLE'
export const GET_ALL_AVAILABILITY = 'GET_ALL_AVAILABILITY'
export const SHOW_NOTIFICATION_APPOINTMENT = 'SHOW_NOTIFICATION_APPOINTMENT'
export const UPDATE_SCHEDULE = 'UPDATE_SCHEDULE'

/* ORDER ACTIONS */
export const CLEAR_ORDER = 'CLEAR_ORDER'
export const CLEAR_ORDER_LIST = 'CLEAR_ORDER_LIST'
export const CREATE_ORDER = 'CREATE_ORDER'
export const CREATE_CHECKOUT = 'CREATE_CHECKOUT'
export const CLEAR_CHECKOUT = 'CLEAR_CHECKOUT'
export const GENERATE_ORDER = 'GENERATE_ORDER'
export const SAVE_CARD = 'SAVE_CARD'
export const GET_CARDS = 'GET_CARDS'
export const GET_ORDERS = 'GET_ORDERS'
export const GET_ORDER_LIST = 'GET_ORDER_LIST'
export const SAVE_ORDER = 'SAVE_ORDER'
export const SET_PAYMENT_RESPONSE = 'SET_PAYMENT_RESPONSE'
export const CLEAR_PAYMENT_RESPONSE = 'CLEAR_PAYMENT_RESPONSE'

/* CANCEL ACTION */
export const GET_CANCEL_REASON = 'GET_CANCEL_REASON'

/* REPPROVE ACTION */
export const GET_REPPROVE_REASON = 'GET_REPPROVE_REASON'

/* PARTNER PLANS ACTIONS */
export const GET_GROUP_PLANS = 'GET_GROUP_PLANS'
export const GET_PLANS = 'GET_PLANS'
export const GET_PLANS_GROUPS = 'GET_PLANS_GROUPS'
export const GET_GROUP_PLANS_ELIGIBLES = 'GET_GROUP_PLANS_ELIGIBLES'
export const GET_GROUP_PLANS_ELIGIBLES_FILE = 'GET_GROUP_PLANS_ELIGIBLES_FILE'
export const UPDATE_GROUP_PLANS_ELIGIBLES_FILE = 'UPDATE_GROUP_PLANS_ELIGIBLES_FILE'
export const CLEAR_GROUP_PLANS_ELIGIBLES_FILE = 'CLEAR_GROUP_PLANS_ELIGIBLES_FILE'

/* PARTNER ROLES ACTIONS */
export const GET_PARTNER_PROFILES = 'GET_PARTNER_PROFILES'
export const CLEAR_PARTNER_PROFILES = 'CLEAR_PARTNER_PROFILES'

/* PRODUCT ACTIONS */
export const CLEAN_PRODUCTS = 'CLEAN_PRODUCTS'
export const GET_PRODUCTS = 'GET_PRODUCTS'

/* SKILLS ACTIONS */
export const CLEAN_SKILLS = 'CLEAN_SKILLS'
export const GET_SKILLS = 'GET_SKILLS'
export const SELECT_SKILLS = 'SELECT_SKILLS'

/* WS NOTIFICATION ACTIONS */
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS'
export const WS_NOTIFICATIONS = 'WS_NOTIFICATIONS'

/* REGISTER BLOCKS ACTIONS */
export const GET_BLOCKS = 'GET_BLOCKS'
export const CLEAR_BLOCKS_DATA = 'CLEAR_BLOCKS_DATA'
export const CLEAN_BLOCKS_EDIT = 'CLEAN_BLOCKS_EDIT'
export const EDIT_BLOCKS_DATA = 'EDIT_BLOCKS_DATA'
export const ADD_BIOGRAPHY_DATA = 'ADD_BIOGRAPHY_DATA'
export const ADD_DOCUMENT_DATA = 'ADD_DOCUMENT_DATA'
export const ADD_EDUCATION_DATA = 'ADD_EDUCATION_DATA'
export const ADD_GENERAL_DATA = 'ADD_GENERAL_DATA'
export const ADD_MEDIA_DATA = 'ADD_MEDIA_DATA'
export const ADD_PRODUCTS_DATA = 'ADD_PRODUCTS_DATA'
export const ADD_SKILLS_DATA = 'ADD_SKILLS_DATA'
export const ADD_LANGUAGES_DATA = 'ADD_LANGUAGES_DATA'
export const ADD_TREATMENT_BY_AGE_DATA = 'ADD_TREATMENT_BY_AGE_DATA'
export const ADD_SUBSPECIALTIES_DATA = 'ADD_SUBSPECIALTIES_DATA'

/* FILTER ACTIONS */
export const FILTER_SCHEDULE = 'FILTER_SCHEDULE'
export const FILTER_STATUS = 'FILTER_STATUS'
export const CLEAR_FILTERS = 'CLEAR_FILTERS'
export const GET_PRICE = 'GET_PRICE'

/* REPORT ACTIONS */
export const GENERATE_REPORT = 'GENERATE_REPORT'
export const CLEAR_REPORT = 'CLEAR_REPORT'

/* SURVEY ACTION */
export const SET_ANSWER_RESPONSE = 'SET_ANSWER_RESPONSE'

/* MEDICAL REQUEST */
export const GET_MEDICAL_REQUEST = 'GET_MEDICAL_REQUEST'
export const CLEAR_MEDICAL_REQUEST = 'CLEAR_MEDICAL_REQUEST'
export const CHANGE_MEDICAL_REQUEST = 'CHANGE_MEDICAL_REQUEST'
export const CREATE_MEDICAL_REQUEST = 'CREATE_MEDICAL_REQUEST'
export const SEND_PROCEDURE_REQUEST = 'SEND_PROCEDURE_REQUEST'
export const GET_DISAPPROVE_DETAILS = 'GET_DISAPPROVE_DETAILS'
