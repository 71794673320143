import React from "react";

const BallonComment = ({
  fill = "#c1c1c1",
  height = "52",
  width = "52",
  viewBox = "0 0 52 52",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <g transform="translate(-125.117 -51.296)">
      <path d="M260.1,193.932h6.8a1.942,1.942,0,0,1,0,3.885h-6.8a1.942,1.942,0,1,1,0-3.885Z" transform="translate(-117.659 -110.126)" fill={fill}/>
      <path d="M277.455,267.085H260.1a1.943,1.943,0,0,1,0-3.885h17.353a1.943,1.943,0,0,1,0,3.885Z" transform="translate(-117.659 -187.403)" fill={fill}/>
      <path d="M277.455,336.355H260.1a1.942,1.942,0,1,1,0-3.885h17.353a1.942,1.942,0,1,1,0,3.885Z" transform="translate(-117.659 -264.682)" fill={fill}/>
      <path d="M151.119,51.3A25.932,25.932,0,0,0,130.58,93.15l-3.548,8.333a1.943,1.943,0,0,0,2.124,2.674l13.448-2.35a25.97,25.97,0,1,0,8.515-50.51Zm0,48.069a22.009,22.009,0,0,1-7.666-1.386,1.942,1.942,0,0,0-1.01-.09L132,99.715l2.59-6.119h0a1.944,1.944,0,0,0-.311-2.02,22.08,22.08,0,1,1,16.835,7.77Z" fill={fill}/>
    </g>
  </svg>
)

export default BallonComment;