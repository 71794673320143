import { ACTIVE, INCOMPLETE_ACCOUNT } from "../settings/_personSettings";

/**
 * Return a bool 'isPositive' based on
 * the status passed as argument
 */
export const checkProfileStatusActivity = status => {
  let isActive = false;

  if ([ACTIVE, INCOMPLETE_ACCOUNT].includes(status)) {
    isActive = true;
  } 

  return isActive;
};

/**
 * Returns a formated object of profiles with 
 * 'isActive' key based on the "status_code"
 * of the profile 
 */
export const checkProfileListActivity = profiles => {
  return profiles.map(profile => {
    return {
      ...profile,
      isActive: checkProfileStatusActivity(profile.status_code),
    };
  });
};