import React, { useState } from 'react'
import { 
  Typography, 
  TextBox, 
  Button, 
  Paper, 
  Spinner
} from '@telavita-core/react-design-kit'
import Dropdown from 'components/Dropdown'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

import * as S from './styles'

import { PersonProfilePlanReasons } from 'settings/_personProfilePlanReasons'
import { inactivatePlan } from 'services/plan/inactivatePlan'
import { ReactComponent as CheckIcon } from 'static/svg/check.svg'

interface InactivatePlanModalProps {
  patientName: string
  planName: string
  cardNumber: string
  username: string
  planCode: string
  onSuccess: () => void
  onClose: () => void
}

const InactivatePlanModal = ({
  patientName,
  planName,
  cardNumber,
  username,
  planCode,
  onSuccess,
  onClose
}: InactivatePlanModalProps): JSX.Element => {

  const [reason, setReason] = useState<string | undefined>(undefined)
  const [details, setDetails] = useState('')
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const reasons = Object.entries(PersonProfilePlanReasons).map(([key, index]) => {
    return {
      code: key,
      name: index,
    }
  })

  function handleSelectReason(code) {
    const selectedReason = reasons.find(reason => reason.code === code)
    setReason(selectedReason.code)
  }

  async function onSubmit() {
    try {
      setIsLoading(true)
      await inactivatePlan({
        details,
        reason,
        username,
        planCode
      })
      setIsSuccess(true)
      onSuccess()
      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      console.error(err)
    }
  }

  if (isSuccess) {
    return (
      <ModalGeneric
        modalTitle={null}
        onCloseModal={onClose}
      >
        <S.Container>
          <CheckIcon />
          <Typography variant='header4' weight='bold' customClassName='InactivatePlanModal__SuccessTitle'>Convênio inativado com sucesso!</Typography>
           
          <Paper variant='warning' customClassName='InactivatePlanModal__SuccessMessageWrapper'>
            <Typography variant='content2'> 
              <b>1)</b> A solicitação foi invalidada automaticamente.
            </Typography>
            <Typography variant='content2'>
              <b>2)</b> Caso haja consultas agendadas, é necessário cancelá-las manualmente.
            </Typography>
          </Paper>
  
          <Button
            variant='contained'
            color='primary'
            customClassName='InactivatePlanModal__SubmitButton'
            onClick={onClose}
          >
            Fechar
          </Button>
        </S.Container>
      </ModalGeneric>
    )
  }

  return (
    <ModalGeneric
      modalTitle='Tem certeza que deseja inativar este convênio do paciente?'
      modalSubtitle='Ao prosseguir, o convênio deixará de aparecer na conta do paciente.'
      titleColor='red-lighter'
      onCloseModal={onClose}
    >
      <S.Container>
        <S.PatientInfoWrapper>
          <Typography variant='content2'>
            {patientName}
          </Typography>
          <Typography variant='content2' weight='bolder'>
            {planName}
          </Typography>
          <Typography variant='content2'>
            Carteirinha {cardNumber}
          </Typography>
        </S.PatientInfoWrapper>
        <Dropdown 
          options={reasons}
          onSelect={handleSelectReason}
          placeholder={'Selecione o motivo*'}
        />
        <TextBox 
          placeholder='Detalhes'
          onChange={(e) => setDetails(e.target.value)}
        />
        <Typography customClassName='InactivatePlanModal__Label' variant='content2' italic>
          *Preenchimento obrigatório
        </Typography>
        <Button
          variant='contained'
          color='danger'
          disabled={!reason || isLoading}
          onClick={onSubmit}
          customClassName='InactivatePlanModal__SubmitButton'
        > 
          {isLoading ? <Spinner inlineStyles={{ height: 30, width: 30 }}/> : 'CONFIRMAR INATIVAÇÃO'}
        </Button>
      </S.Container>
    </ModalGeneric>
  )
}

export default InactivatePlanModal