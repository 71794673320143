import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Trans } from '@lingui/macro';

import { Typography } from 'components'
import CartList from '../../containers/CartList/CartList';

class CartSummary extends Component {

  static propTypes = {
    discountValue: PropTypes.any,
    isSuccinct: PropTypes.bool,
    itemList: PropTypes.array,
    professionalName: PropTypes.string,
    sumValue: PropTypes.any,
    totalValue: PropTypes.any,
  }

  static deafultProps = { isSuccinct: false  }

  render() {
    return (
      <div className="CartSummary">
        <div className="CartSummary__header">
          <Typography bold>
            <Trans>
              Agendamento:
            </Trans>
          </Typography>
          <Typography>
            {this.props.professionalName}
          </Typography>
        </div>
        {
          this.props.isSuccinct ? null : (
            <div className="CartSummary__items">
              {
                this.props.itemList.length > 0 ?
                  <CartList itemList={this.props.itemList}
                    isDeletable={false}
                  /> : null
              }
            </div>
          )
        }
        <div className="CartSummary__totals">
          <div className="CartSummary__totals__header">
            <div className="CartSummary__totals__header__value">
              <Typography>
                <Trans>
                  Valor das consultas:
                </Trans>
              </Typography>
              <Typography>
                R${this.props.sumValue}
              </Typography>
            </div>
            <div className="CartSummary__totals__header__value">
              <Typography>
                <Trans>
                  Desconto:
                </Trans>
              </Typography>
              <Typography>
                - R${this.props.discountValue}
              </Typography>
            </div>
          </div>
          <div className="CartSummary__totals__separator"></div>
          <div className="CartSummary__totals__final">
            <div className="CartSummary__totals__final__value">
              <Typography>
                <Trans>
                  Total:
                </Trans>
              </Typography>
              <Typography>
                R${this.props.totalValue}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { CartSummary };