import React from 'react';
import PropTypes from 'prop-types';

/**
 * This component is responsible for the
 * body's content centralization. It limits
 * the content's width to 320px on mobile
 * and 1087px on desktop.
 */
function BodyContent({ children }) {
  return (
    <div className="BodyContent">
      <div className="BodyContent__Container">
        {children}
      </div>
    </div>
  );
}

BodyContent.propTypes = { children: PropTypes.any };

export { BodyContent };
