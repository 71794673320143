import { useCallback, useEffect, useState } from 'react'
import { format, formatISO } from 'date-fns'

import metricsService from 'services/metrics'

import {
  SCHEDULED,
  RETURNED,
  PROFESSIONAL_NO_SHOW,
  ATTENDED,
  PATIENT_NO_SHOW,
} from 'settings/_scheduleStatusSettings'

import { 
  IAvailableAvailabilities,
  IFormattedScheduleAvailabilities, 
  IHoursViewData, 
  IScheduledAvailabilities, 
  IUseDataParams 
} from '../types'

const statusList = [
  SCHEDULED,
  ATTENDED,
  PATIENT_NO_SHOW,
  PROFESSIONAL_NO_SHOW,
  RETURNED,
]


const hours = {
  '00:00': {},
  '01:00': {},
  '02:00': {},
  '03:00': {},
  '04:00': {},
  '05:00': {},
  '06:00': {},
  '07:00': {},
  '08:00': {},
  '09:00': {},
  '10:00': {},
  '11:00': {},
  '12:00': {},
  '13:00': {},
  '14:00': {},
  '15:00': {},
  '16:00': {},
  '17:00': {},
  '18:00': {},
  '19:00': {},
  '20:00': {},
  '21:00': {},
  '22:00': {},
  '23:00': {},
}

function sortSlotsByHour(data: IFormattedScheduleAvailabilities[]) {
  return data.sort((a, b) => {
    if (a.hour < b.hour) {
      return -1
    }
    if (a.hour > b.hour) {
      return 1
    }
    return 0
  })
}

function reduceDataToCorrectHour(data: IFormattedScheduleAvailabilities[]) {
  const allSlots = Object.keys(hours).reduce((acc, curr) => {
    const slot = data.find(slot => slot.hour === curr)
    
    if (slot) {
      acc[curr] = slot
    }

    if(!slot) {
      acc[curr] = {
        hour: curr,
        value: null,
        percentage: null
      }
    }
    return acc as IFormattedScheduleAvailabilities[]
  }, [])

  return sortSlotsByHour(Object.values(allSlots))
}

function calculatePercentage(partialValue: number, totalValue: number) {
  const percentage = (partialValue / totalValue) * 100
  return percentage.toFixed(1)
}

function reduceTotalSlotsAvailability(data: IFormattedScheduleAvailabilities[]) {
  return data.reduce((acc, curr) => acc + curr.value, 0)
}

function reduceSlotsToCalculatePercentage(data: IFormattedScheduleAvailabilities[]) {
  const total = reduceTotalSlotsAvailability(data)
  const slotsWithPercentage = data.map(slot => {
    return {
      ...slot,
      percentage: calculatePercentage(slot.value, total)
    }
  })

  return reduceDataToCorrectHour(slotsWithPercentage)
}

function formatData(availableHours: IAvailableAvailabilities[], scheduledHours: IScheduledAvailabilities) {
  const availableSlots = availableHours.map(slot => {
    return {
      hour: slot.time?.replace(':00', ''),
      value: slot?.availabilities_count,
    }
  })

  const scheduledSlots = scheduledHours.times.map(slot => ({
    hour: slot.time?.replace(':00', ''),
    value: slot.partial,
    percentage: slot.percentage
  }))

  const checkedAvailableSlotWithSchedule = availableSlots.map(slot => {
    const filteredSchedulesSlots = scheduledSlots.filter(scheduledSlot => scheduledSlot?.hour === slot?.hour)

    return {
      hour: filteredSchedulesSlots[0]?.hour || slot?.hour,
      value: filteredSchedulesSlots[0]?.value || 0,
      ...filteredSchedulesSlots[0]
    }
  })

  return reduceSlotsToCalculatePercentage(checkedAvailableSlotWithSchedule)
}

export const useHoursViewData = ({selectedDate}: IUseDataParams): IHoursViewData => {
  const [loadingHoursGraphic, setLoadingHoursGraphic] = useState(false)
  const [hoursViewData, setHoursViewData] = useState([])

  const fetchData = useCallback(async () => {
    const availableHoursResponse = await metricsService.getScheduleAvailabilities({
      initialDate: format(new Date(selectedDate.initialDate), 'yyyy-MM-dd'),
      endDate: format(new Date(selectedDate.endDate), 'yyyy-MM-dd'),
      type: 'time'
    })

    const scheduledHoursResponse = await metricsService.getListSchedules({
      initialDate: formatISO(new Date(selectedDate.initialDate)),
      endDate: formatISO(new Date(selectedDate.endDate)),
      groupBy: 'time',
      statusList
    })

    return Promise.all([availableHoursResponse, scheduledHoursResponse])
  }, [selectedDate])

  useEffect(() => {
    async function getScheduleSlotsList() {
      try{
        setLoadingHoursGraphic(true)
       
        const [availableHoursResponse, scheduledHoursResponse] = await fetchData()

        setHoursViewData(formatData(availableHoursResponse, scheduledHoursResponse.data))

      } catch (error) {
        setLoadingHoursGraphic(false)
      } finally {
        setLoadingHoursGraphic(false)
      }
    }
    
    void getScheduleSlotsList()
  }, [selectedDate, fetchData])

  return {
    hoursViewData,
    loadingHoursGraphic
  }
}