import {
  GET_PLANS,
  GET_GROUP_PLANS,
  GET_PLANS_GROUPS, GET_GROUP_PLANS_ELIGIBLES, GET_GROUP_PLANS_ELIGIBLES_FILE, UPDATE_GROUP_PLANS_ELIGIBLES_FILE, CLEAR_GROUP_PLANS_ELIGIBLES_FILE,
} from './_actionTypes'
import { apiEndpoints } from './../../settings/_apiSettings'
import axios from '../../settings/axios'

/* Get available plans */
export const getPlans = ({ is_particular, profile_role }) => async dispatch => {
  const params = {
    ...(is_particular ? { is_particular } : is_particular === false ? { is_particular } : {}),
    ...(profile_role && { profile_role }),
  }

  const response = await axios.get(apiEndpoints.PLANS, { params })
  const payload = { plans: response.data.plans }

  return dispatch({
    type: GET_PLANS,
    payload,
  })
}

export const getPlanGroups = (plan_group_code, { plan_profile_role }) => async dispatch => {
  const params = { ...(plan_profile_role && { plan_profile_role }) }

  const response = await axios.get(apiEndpoints.GROUP_PLANS(plan_group_code), { params })
  const payload = { groupPlans: response.data }

  return dispatch({
    type: GET_GROUP_PLANS,
    payload,
  })
}

/** Get all groups plans */
export const getAllPlansGroups = () => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.PLANS_GROUPS)
    const payload = { allPlansGroups: response.data }

    return dispatch({
      type: GET_PLANS_GROUPS,
      payload
    })
  } catch (err) {
    console.log(err)
  }
}

export const createPlanGroup = payload => {
  return new Promise(async (resolve, reject) => {
    try {
      const endpoint = apiEndpoints.PLANS_GROUPS
      const response = await axios.post(endpoint, payload)
      resolve(response)

    } catch (err) { reject(err) }
  })
}

export const getPlanGroupEligibles = (plan_group_code) => async dispatch => {
  const response = await axios.get(apiEndpoints.GROUP_PLAN_ELIGIBLES(plan_group_code))
  const payload = { eligibles: response.data }

  return dispatch({
    type: GET_GROUP_PLANS_ELIGIBLES,
    payload,
  })
}

export const getPlanGroupEligiblesFile = (plan_group_code) => async dispatch => {
  const response = await axios.get(apiEndpoints.GROUP_PLAN_ELIGIBLES_FILE(plan_group_code))
  const payload = { file: response.data.csv }

  return dispatch({
    type: GET_GROUP_PLANS_ELIGIBLES_FILE,
    payload,
  })
}

export const updatePlanGroupEligiblesFile = (plan_group_code, csvData, exclusion) => async dispatch => {
  try {
    await axios.post(apiEndpoints.GROUP_PLAN_ELIGIBLES_FILE(plan_group_code), {
      csv: csvData,
      confirm: exclusion
    })

    let payload = {
      success: true,
      errors: null,
    }
    return dispatch({
      type: UPDATE_GROUP_PLANS_ELIGIBLES_FILE,
      payload,
    })
  } catch (error) {
    let payload = {
      success: false,
      errors: {
        info: error.response.data.messages[0].info,
        eligibles: error.response.data?.data?.eligibles,
        status: error.response.status
      }
    }
    return dispatch({
      type: UPDATE_GROUP_PLANS_ELIGIBLES_FILE,
      payload,
    })
  }
}

export const clearPlanGroupEligiblesFile = () => {
  return { type: CLEAR_GROUP_PLANS_ELIGIBLES_FILE }
}