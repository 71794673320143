import React from 'react';
import classNames from 'classnames';

// STYLE
import './DateFilter.sass'

// COMPONENTS
import { TextInput } from 'components';

const DateFilter = ({ start, final, error }) => {
  // date = {
  //   id: String,
  //   placeholder: String,
  //   defaultValue: String,
  //   onChange: Function,
  //   error: Boolean,
  //   errorMessage: String
  // }

  // error = {
  //   condition: Boolean,
  //   message: String,
  // }

  return (
    <div className="DateFilter">

      {/* INPUT FIELDS */}
      <div className="DateFilter__group">
        <div className="DateFilter__field">
          <TextInput
            id={start.id}
            placeholder={start.placeholder}
            mask="11/11/1111"
            onInputChange={(e) => start.onChange(e.target.value)}
            value={start.defaultValue}
          />
          <p className={classNames('DateFilter__errorMessage', {
            'DateFilter__errorMessage--hidden': !start.error
          })}>{start.errorMessage || error.message}</p>
        </div>
        <p className="DateFilter__text">até</p>
        <div className="DateFilter__field">
          <TextInput
            id={final.id}
            placeholder={final.placeholder}
            mask="11/11/1111"
            onInputChange={(e) => final.onChange(e.target.value)}
            value={final.defaultValue}
          />
          <p className={classNames('DateFilter__errorMessage', {
            'DateFilter__errorMessage--hidden': !final.error
          })}>{final.errorMessage || error.message}</p>
        </div>
      </div>

      {/* ERROR BOTTOM MESSAGE */}
      {/* {
        (start.error || final.error) && error.message && !(start.errorMessage && final.errorMessage)
          ? <p className="DateFilter__errorMessage">{error.message}</p>
          : null
      } */}
    </div>
  )
}

export {
  DateFilter
}