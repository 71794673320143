import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import * as routes from "settings/_routesSettings";
import { history } from "routers/AppRouter";
import Sticky from "react-sticky-el";
import PaymentListItem from "components/PaymentListItem";
import Row from "components/Row";
import {
  toDayMonthYear,
  getDayName,
  isToday,
  tomorrow,
} from "utils/dateTimeHandlers";
import StickyIndex from 'components/StickyIndex';
import { Typography, ClearFilters, CoreIcons, TextInput } from 'components';
import { modalTypes } from "containers/ModalManager/ModalManager";
import { formatFloatToReal } from "utils/orderHandlers";
import { hasPermission } from "utils/permissionHandlers";
import {
  getOrdersList,
  getFilterStatus,
  showModal,
  setTextFilter,
  clearFilter,
  clearOrderList,
} from "redux/actions";
import { groupBy } from 'utils/groupByDate';
import { STS20006, STS20001, STS20002, STS20003, STS20005 } from "settings/_paymentStatusSettings";
import * as permissions from "settings/_permissionSettings";
import viewportHoc from "hoc/viewportHoc";
import * as groupCode from "settings/_groupCodeSettings";
import SearchPlaceHolder from 'components/SearchPlaceholder';

const iconProps = {
  fill: "#1F35B5",
  height: "30px",
  name: "Search",
  viewBox: "3 2 30 30",
  width: "30px",
};

const stickyFilter = {
  backgroundColor: "#fff",
  padding: "2.5rem 0px 2rem",
  zIndex: "2",
};

class PaymentList extends Component {
  state = {
    loaded: false,
    isCleared: false,
    query: "",
  };

  buildMenu = paymentId => {
    let menuList = [];

    hasPermission(this.props.permissions, permissions.ADMIN_VIEW_PAYMENT) &&
      menuList.push({
        code: "see-detail",
        name: "Ver detalhe",
        func: () => history.push(routes.DASHBOARD_PAYMENT_DETAIL(paymentId)),
      });
    return menuList;
  }

  componentDidUpdate(prevProps) {
    const { filterData } = this.props;

    if (prevProps.filterData !== this.props.filterData) {
      this.props
        .onGetOrdersList({
          query: filterData.text,
          date_created__gte: filterData.startDate,
          date_created__lte: filterData.endDate,
          status_code: filterData.status,
        })
        .then(() => {
          this.setState({ loaded: true, isCleared: true });
        });
    }
  }

  handleSearchInput = e => {
    const inputValue = e.target.value;
    if (new RegExp(/^[A-Za-zÀ-ÿ ]+$/).test(inputValue) || inputValue === "") {
      this.setState({ query: inputValue });
    }
  };

  handleSearchClick = () => {
    this.props.onSetQuery(this.state.query);
    this.setState({ loaded: false, isCleared: true  });
  };

  handleOrderList = () => {
    const orders = this.props.orderList.map(el => {
      const id = el.id;
      const name = el.buyer_person_profile.person_general.full_name;
      const total = el.total;
      const itemsCount = el.items_count;
      const paymentMethod = el.payment_method.name;
      const paymentStatus = el.status_code;
      const paymentStatusName = el.status_name;
      const price = formatFloatToReal(total);
      const date = toDayMonthYear(el.date_created);
      const isoDate = el.date_created;
      return {
        id,
        name,
        price,
        itemsCount,
        paymentMethod,
        paymentStatus,
        paymentStatusName,
        date,
        isoDate,
      };
    });
    return orders;
  };
  handleGroupData=()=>{

    const listData = this.handleOrderList();
    const groupSchedules = Object.keys(
      groupBy(listData)
    ).map((date) => {

      return {
        date,
        schedules: groupBy(listData)[date],
        ScheduleDate: groupBy(listData)[date][0].isoDate,
      };
    });
    return groupSchedules;
  }

  renderGroupByDate = (data) => (

    data.length <= 0 ?
      (
        <div className="dashboard-person-list--empty">
          <SearchPlaceHolder
            titleText= 'Nenhum resultado'
            text='Refaça sua busca e seus filtros'
          />

        </div>
      ):
      data.map((item, index) => (

        <Row  align="center" justify="center" key={index}>
          <StickyIndex
            textPosition='flex-start'
            barContent={

              this.props.filterData.status === STS20006?
                (<Trans>Cancelados</Trans>) :
                this.props.filterData.status === STS20001 ?
                  (<Trans>Pendentes</Trans>) :
                  this.props.filterData.status === STS20002 ?
                    (<Trans>Pagos</Trans>) :
                    this.props.filterData.status === STS20005 ?
                      (<Trans>Estornados</Trans>) :
                      this.props.filterData.status === STS20003 ?
                        (<Trans>Reprovados</Trans>) :
                        isToday(item.ScheduleDate) ?
                          <Trans>HOJE</Trans>:
                          tomorrow(item.ScheduleDate) ?
                            (<Trans>Amanhã</Trans>) :
                            getDayName(item.ScheduleDate)
            }
            barDate={item.date}
          />

          {item.schedules.map(el=>{

            return (

              <Row margin="auto auto " key={el.id}>
                <PaymentListItem
                  paymentStatus={el.paymentStatus}
                  id={el.id}
                  menuList={this.buildMenu(el.id)}
                  psychologistName={el.name}
                  patientName={el.name}
                  sessionId={el.itemsCount}
                  price={el.price}
                  paymentMethod={el.paymentMethod}
                  paymentStatusName={el.paymentStatusName}
                />
              </Row>

            );
          }

          )}

        </Row>

      ))
  );

  filterData = {
    groupCode: groupCode.PAYMENT,
    bool: true,
    btnText: `APLICAR MODIFICAÇÕES`,
    iconForAll: `Clinic`,
  };
  tempFunc=()=>{
    this.props.onClearOrderList();
    this.setState({ isCleared: false  });
  }
  render() {
    const { loaded, isCleared } = this.state;

    return (
      <div className="PaymentList">
        <Sticky stickyStyle={stickyFilter}>
          <Row
            align="center"
            justify={this.props.isDesktop ? "space-between" : "center"}
            margin={
              this.props.isDesktop ? "3.9rem 0 2.1rem" : "2.5rem 0 1.5rem"
            }>
            <Typography className="PaymentList__heading" align="mcenter-dleft" Tag="h1" type="title">
              <Trans>Pedidos</Trans>
            </Typography>

            <div className="Dashboard-filter">
              <div className="filter-input">
                <TextInput
                  addIcon
                  iconProps={iconProps}
                  name="search_client"
                  inputHeight="4.8rem"
                  onInputChange={this.handleSearchInput}
                  onClickIcon={this.handleSearchClick}
                  onKeyPress={e => e.which === 13 && this.handleSearchClick()}
                  placeholder="Buscar paciente"
                  value={this.state.query}
                />
              </div>
              <div
                onClick={() =>
                  this.props.onShowModal(
                    modalTypes.FILTER_STATUS,
                    this.filterData
                  )
                }
                className="filter-btn">
                <CoreIcons
                  fill="#1F35B5"
                  name="Filters"
                  viewBox="-10 -10 35 35"
                />
              </div>
            </div>
          </Row>
          {
            isCleared
              ? (
                <Row>

                  <ClearFilters onClickText={() => this.tempFunc()}/>
                </Row>
              ) : null
          }
        </Sticky>
        {

          (loaded && isCleared) ?
            (<div className="dashboard-person-list">
              {this.renderGroupByDate(this.handleGroupData())}

            </div>):
            <div className="dashboard-person-list--empty">

              <SearchPlaceHolder
                titleText='Personalize a data e os filtros acima para'
                phrase='encontrar o pedido'
              />
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuth,
    userData: state.auth.userData,
    orderList: state.orders.orderList,
    permissions: state.auth.profilePermissions,
    filterData: state.filters,
    filterStatus: state.filterStatus.filterStatus,
    isFiltering: state.filters.isFiltering,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClearFilters: () => dispatch(clearFilter()),
    onGetOrdersList: params => dispatch(getOrdersList(params)),
    onGetFilters: () => dispatch(getFilterStatus()),
    onShowModal: (modal, data) => dispatch(showModal(modal, data)),
    onSetQuery: query => dispatch(setTextFilter(query)),
    onClearOrderList: ()=> dispatch(clearOrderList()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(PaymentList));
