//
const INITIAL_STATE = {
  patients: [],
  filteredBy: '',
  filtered: false,
  loading: false,
  error: false,
  interactions: {
    top: 0,
    topId: '',
  }
};

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_PATIENTS':
      return {
          ...state,
          loading: true,
      };

    case 'LOAD_PATIENTS_SUCCESS':
      return {
          ...state,
          patients: action.payload.patients,
          loading: false,
      };

    case 'LOAD_PATIENTS_FAILURE':
      return {
          ...state,
          loading: false,
          error: true
      };

    case 'APPLY_PATIENTS_FILTERS':
      return {
        ...state,
        filteredBy: action.payload,
        filtered: true,
      };

    case 'UPDATE_INTERACTION':
      return {
        ...state,
        interactions: {
          ...state.interactions,
          [action.payload.prop]: action.payload.value
        }
      }

    default:
      return state;
  };
};

export {
  reducer
}