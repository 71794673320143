
import axios from 'axios';

// ACTIONS
import * as generalViewActions from './actions';

const mainURL =  process.env.REACT_APP_API_URL; // main route prefix set by environment

const generalViewPacientsRequest = (token, params) => {
  const header = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: { ...params }
  }

  return function (dispatch) {
    dispatch(generalViewActions.loadData('pacients'));
    axios.get(mainURL + '/v0/metrics/persons/totals/', header)
      .then(response => {
        dispatch(generalViewActions.loadDataSuccess('pacients', response.data.data));
      })
      .catch(response => {
        dispatch(generalViewActions.loadDataFailure('pacients', response.error));
      });
  };
};

const generalViewSchedulesRequest = (token, params) => {
  const header = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: { ...params }
  }

  return function (dispatch) {
    dispatch(generalViewActions.loadData('schedules'));
    axios.get(mainURL + '/v0/metrics/schedules/totals/', header)
      .then(response => {
        dispatch(generalViewActions.loadDataSuccess('schedules', response.data.data));
      })
      .catch(response => {
        dispatch(generalViewActions.loadDataFailure('schedules', response.error));
      });
  };
};

// GET DATA FOR APPOINTMENTS CHART
const generalViewAppointements = (token, params) => {
  const header = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: { ...params }
  }

  return function (dispatch) {
    dispatch(generalViewActions.loadData('appointmentsChart'));
    axios.get(mainURL + '/v0/metrics/schedules/', header)
      .then(response => {
        dispatch(generalViewActions.loadChartSuccess('appointmentsChart', response.data.data));
      })
      .catch(response => {
        dispatch(generalViewActions.loadChartFailure('appointmentsChart', response.error));
      });
  };
};

// GET DATA FOR RECOMENDATION CHART
const generalViewRecomendation = (token, params) => {
  const header = {
    headers: {
      'Authorization': 'Bearer ' + token
    },
    params: {
      ...params,
      question_code: 'QET01001'
     }
  }

  return function (dispatch) {
    dispatch(generalViewActions.loadData('recomendationsChart'));
    axios.get(mainURL + '/v0/metrics/answers/', header)
      .then(response => {
        dispatch(generalViewActions.loadChartSuccess('recomendationsChart', response.data.data));
      })
      .catch(response => {
        dispatch(generalViewActions.loadChartFailure('recomendationsChart', response.error));
      });
  };
};

export { generalViewPacientsRequest, generalViewSchedulesRequest, generalViewAppointements, generalViewRecomendation };