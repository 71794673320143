import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import { Typography } from '@telavita-core/react-design-kit'

// COMPONENTS
import Row from '../../../../components/Row'
import { Column, TextInput } from 'components'

import InputValidation from '../../../../utils/InputValidation'
import { addMediaData } from '../../../../redux/actions'

class MediaData extends Component {
  state = {
    userRole: this.props.role,
    isMenuOpen: undefined,
    isNewUser: true,
    hasPartnerProfile: false,

    canSave: true,
    isBlockChecked: true,

    picture: {
      placeHolder: 'Selecione a foto',
      value: '',
      isValid: true,
      message: '',
    },

    video_url: {
      value: this.props.isEdit ? this.props.personMedia.video_url : '',
      isValid: true,
      message: '',
    },
  }

  handlePictureInput = async(e) => {
    let getFile = e.target.files
    let imgName = getFile[0] ? getFile[0].name : 'Selecione a foto'

    let validation = new InputValidation(getFile, 'image')
    let imageIsValid = await validation.validate()

    this.setState({
      picture: {
        placeHolder: imgName,
        value: imageIsValid.encodedImage,
        isValid: imageIsValid.success,
        message: imageIsValid.message,
      },
    })

    if (imageIsValid.success) {
      this.props.onAddMediaData(
        this.state.isBlockChecked,
        this.state.canSave,
        { picture: this.state.picture.value }
      )
    }
  }

  handleVideoInput = (e) => {
    const inputValue = e.target.value

    let validation = new InputValidation(inputValue, 'url').validate()

    this.setState({
      video_url: {
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })

    if (validation.success) {
      this.props.onAddMediaData(
        this.state.isBlockChecked,
        this.state.canSave,
        { video_url: inputValue }
      )
    }
  }

  render() {
    return (
      <div className="RegisterBlock__MediaData">
        <Row padding=" 0 0 2rem 0">
          <Typography variant="content1" weight='bold'>
            Foto de apresentação
          </Typography>
        </Row>

        <Row>
          <Column colWidth={6}>
            <div className="RegisterBlock__MediaData--image-wrapper">
              <TextInput
                addIcon={true}
                placeholder={this.state.picture.placeHolder}
                name="picture"
                hasError={!this.state.picture.isValid}
                iconProps={{ fill: '#898989', height: '20px', name: 'UploadFile', width: '20px' }}
                inputHeight='4.8rem'
                messageError={this.state.picture.message}
              />
              <input id="hiddenInput-file" type='file' onChange={(e) => this.handlePictureInput(e)} />
            </div>
          </Column>

          <Row padding=" 2rem 0">
            <Typography variant="content1" weight='bold'>
              Vídeo de apresentação
            </Typography>
          </Row>

          <Column colWidth={6}>
            <TextInput
              placeholder="Insira o link"
              name="video_url"
              hasError={!this.state.video_url.isValid}
              inputHeight='4.8rem'
              messageError={this.state.video_url.message}
              onInputChange={(e) => this.handleVideoInput(e)}
              type="url"
              value={this.state.video_url.value}
            />
          </Column>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  isEdit: state.registerBlocks.isEdit,
  personMedia: state.registerBlocks.blocksData.mediaData,
})

const mapDispatchToProps = dispatch => {
  return { onAddMediaData: (isBlockChecked, canSave, field) => (dispatch(addMediaData(isBlockChecked, canSave, field))) }
}

export default connect(mapStateToProps, mapDispatchToProps)(MediaData)