import styled from 'styled-components'

export const Container = styled.section`
  position: relative;
  height: 100%;
`

export const Header = styled.header`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const EmptyReviews = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
`