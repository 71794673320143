// GENERAL ACTION TO GET DATA
const loadData = (prop) => {
  return {
    type: 'LOAD_DATA',
    payload: prop
  }
}

// CARD RESPONSE ACTIONS
const loadDataSuccess = (prop, data) => {
  return {
    type: 'LOAD_DATA_SUCCESS',
    payload: data,
    prop
  }
}

const loadDataFailure = (prop, error) => {
  return {
    type: 'LOAD_DATA_FAILURE',
    payload: error,
    prop
  }
}

// CHART RESPONSE ACTIONS
const loadChartSuccess = (prop, data) => {
  return {
    type: 'GET_CHART_SUCCESS',
    payload: {
      ...data
    },
    prop
  }
}

const loadChartFailure = (prop, error) => {
  return {
    type: 'GET_CHART_FAILURE',
    payload: error,
    prop
  }
}

// UPDATE FILTERS AT REDUCER
const applyFilters = (params) => {
  return {
    type: 'APPLY_FILTERS',
    payload: params,
  }
}

// CLEAR FILTERS
const clearFilters = () => {
  return {
    type: 'CLEAR_FILTERS'
  }
}

export {
  loadData,
  loadDataSuccess,
  loadDataFailure,
  applyFilters,
  clearFilters,
  loadChartSuccess,
  loadChartFailure
}