import { GET_BLOCKS } from './_actionTypes';

import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';

export const getBlocks = (role) => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.BLOCKS, { params: { profile_role: role } });
    if (response.status === "OK") {
      return dispatch({
        type: GET_BLOCKS,
        payload: { blocks: response.data.blocks },
      });
    }
  } catch (err) {
    console.log(err);
  }
};