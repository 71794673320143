import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className, fill, height, onClickLogo, width, style, viewBox }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg"
      style={{cursor: "pointer"}}
      width={width}
      height={height}
      onClick={onClickLogo}
      viewBox={viewBox}>
      <defs></defs>
      <path
        fill={fill}
        d="M90.421 17.81a4.244 4.244 0 0 1-1.8.457 1.863 1.863 0 0 1-1.948-2.13V9.655h4.17v-3.2h-4.139V2.59h-3.713v3.865h-2.8v3.2h2.8v6.482c0 3.743 2.13 5.6 5.387 5.478a8.3 8.3 0 0 0 3.074-.639zM22.44 5.972c-4.869 0-7.974 3.287-7.974 7.852 0 4.809 3.074 8 8.247 8a9.215 9.215 0 0 0 6.452-2.435l-2.374-2.344a6.191 6.191 0 0 1-4.017 1.4c-2.617 0-4.23-1.339-4.474-3.2h11.717c.579-6.169-2.554-9.273-7.577-9.273zm-4.078 6.208a4.041 4.041 0 0 1 4.2-2.922c2.13 0 3.652.974 3.9 2.922zM32.006 0h3.682v21.432h-3.682zM50.21 8.498a5.507 5.507 0 0 0-4.778-2.435c-4.382-.031-7.821 2.678-7.821 7.852 0 5.265 3.287 8 7.73 7.974 1.674-.031 4.017-.883 4.869-2.648l.183 2.191h3.5v-15H50.33zm-4.443 9.982a4.551 4.551 0 0 1 0-9.1c5.843 0 5.843 9.1-.002 9.1zM105.489 6.428l-.122 2.069a5.508 5.508 0 0 0-4.778-2.435c-4.382-.031-7.821 2.678-7.821 7.852 0 5.265 3.287 8 7.73 7.974 1.674-.031 4.017-.883 4.869-2.648l.183 2.191h3.5v-15zm-4.565 12.051a4.551 4.551 0 0 1 0-9.1c5.843.001 5.843 9.101 0 9.101zM64.242 16.83L59.86 6.398h-4.048l6.421 15.095h4.017l6.391-15.095h-4.047zM74.562 6.368h3.713v15.064h-3.713zM10.713 5.254A5.357 5.357 0 0 0 5.359 0H0v5.358h5.358v16.074h5.358V5.255zM16.072 5.358V0h-5.358a5.358 5.358 0 0 0 5.358 5.358z"
      />
    </svg>
  );
};

Logo.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
};

Logo.defaultProps = {
  fill: "#FFFFFF",
  height: "21.889",
  width: "109.05",
};

export default Logo;