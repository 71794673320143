import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import * as actions from '../../../../redux/actions'
import { Typography, CoreIcons, Button } from 'components'
import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { createPortal } from 'react-dom'

import { motion } from 'framer-motion'

import { useDimensions } from '@telavita-core/react-design-kit'

import { ReactComponent as CloseModalIcon } from 'static/svg/close-modal-desktop.svg'

function ModalGeneric({
  modalTitle,
  titleColor = null,
  modalSubtitle = null,
  hasButton = false,
  btnText = '',
  btnDisabled = null,
  btnLoading = null,
  btnId = '',
  btnOnClick = () => {},
  classModifier = null,
  feedbackShow = false,
  feedbackType = 'success',
  feedbackMessage = '',
  feedbackContent = '',
  onSuccessClose = () => { },
  children,
  onHideModal = () => {},
  onCloseModal = () => {},
  customClassName = '',
  containerCustomClassName = '',
  topbarCustomClassName = '',
  titleCustomClassName = '',
  error = '',
  containerRef = null,
  type = '',
  shouldDispatchHideModalWhenClose = true,
}) {

  const [topbarActive, setTopbarActive] = useState(false)

  const { isMobile } = useDimensions()

  useEffect(() => {
    function isiOS() {
      return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
      ].includes(navigator.platform)
      // iPad on iOS 13 detection
      || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    }

    let scrollPosition = 0

    document.body.classList.add('overflow-y-hidden')
    
    if(isiOS()) {
      scrollPosition = window.pageYOffset
      document.body.style.overflow = 'hidden'
      document.body.style.position = 'fixed'
      document.body.style.top = `-${scrollPosition}px`
      document.body.style.width = '100%'
    }
    return () => {
      document.body.classList.remove('overflow-y-hidden')

      if(isiOS()) {
        document.body.style.removeProperty('overflow')
        document.body.style.removeProperty('position')
        document.body.style.removeProperty('top')
        document.body.style.removeProperty('width')
        window.scrollTo(0, scrollPosition)
      }
    }
  }, [])

  useEffect(() => {
    let pos = document.getElementById('ModalGeneric__Modal')
    pos.addEventListener('scroll', function (e) {
      if (pos.scrollTop > 50 && topbarActive === false) {
        setTopbarActive(true)
      } else if (pos.scrollTop <= 50 && topbarActive === true) {
        setTopbarActive(false)
      }
    })
  }, [topbarActive])

  const closeModalByBackdrop = (e) => {
    if (e.target.classList.contains('ModalGeneric__Backdrop')) {
      // We will keep the current operation, which is not to
      // close when clicking outside the modal. But if that
      // idea changes in the future, we will be ready.
      // closeModal(e);
    }
  }

  const closeModal = (e) => {
    if (feedbackShow && feedbackType === 'success') onSuccessClose()
    if (shouldDispatchHideModalWhenClose) {
      onHideModal(e)
    }

    onCloseModal()
  }

  const modalStyle = clsx({
    'ModalGeneric__Modal': true,
    'ModalGeneric__Modal__error': !!error,
    [customClassName]: Boolean(customClassName),
  })

  const containerStyle = clsx({
    'ModalGeneric__Modal__Container': true,
    [containerCustomClassName]: Boolean(containerCustomClassName)
  })

  const backdropAnimationProps = isMobile ? {
    initial:{ opacity: 0 },
    animate:{ opacity: 20, transition: { duration: 0.1 } },
    exit:{ opacity: 0 }
  } : {}

  const modalAnimationProps = isMobile ? {
    initial:{ y: 500, opacity: 0 },
    animate:{ y: 0, opacity: 1, transition: { duration: 0.3  }},
    exit:{ y: 700, opacity: 0 }
  } : {}

  return createPortal(
    <motion.section 
      className="ModalGeneric__Backdrop" 
      onClick={closeModalByBackdrop}
      {...backdropAnimationProps}
    >
      <motion.div 
        className={`${modalStyle} --${type}`} 
        id="ModalGeneric__Modal" ref={containerRef}
        {...modalAnimationProps}  
      >

        <div className="ModalGeneric__Modal__CloseButton" onClick={closeModal}>
          <CloseModalIcon />
        </div>

        <div className={containerStyle} >
          {
            !feedbackShow &&
              <div className={clsx({
                'ModalGeneric__Modal__Title': true,
                [titleCustomClassName]: Boolean(titleCustomClassName)
              })}>
                <Typography align='center' type="heading4" color={titleColor}>
                  {modalTitle}
                </Typography>
                {modalSubtitle &&
                  <div className="ModalGeneric__Modal__Title__Subtitle">
                    {modalSubtitle}
                  </div>
                }
              </div>
          }

          {!feedbackShow && children}

          {
            hasButton && !feedbackShow &&
              <div className="ModalGeneric__Modal__Button">
                <Button
                  disabled={btnDisabled}
                  id={btnId ? btnId : 'modal-btn'}
                  onButtonClick={() => btnOnClick()}
                  text={btnText}
                  classModifier={classModifier}
                  loading={btnLoading}
                />
              </div>
          }

          {
            feedbackShow &&
              <>
                <div className="ModalGeneric__Modal__Container__Feedback">
                  <div className="ModalGeneric__Modal__Container__Feedback__Icon">
                    <CoreIcons
                      name={feedbackType === 'success' ? 'Check' : 'ErrorIcon'}
                      fill={feedbackType === 'success' ? '#4AC326' : '#FF4949'}
                      height="50"
                      width="50"
                    />
                  </div>
                  {
                    <Typography align="center" type="heading4">
                      {
                        feedbackType === 'success' &&
                        (feedbackMessage ? feedbackMessage : <Trans>Ação realizada com sucesso!</Trans>)
                      }
                      {
                        feedbackType === 'error' &&
                        (feedbackMessage ? feedbackMessage : <Trans>Ops, houve algum problema e não foi possível completar a ação. Tente novamente.</Trans>)
                      }
                    </Typography>
                  }
                  {feedbackContent}
                </div>

                <div className="ModalGeneric__Modal__Button">
                  <Button
                    id="modal-btn-close"
                    onButtonClick={() => closeModal()}
                    text="FECHAR"
                  />
                </div>
              </>
          }
        </div>

        {modalTitle && (
          <div className={clsx({
            'ModalGeneric__Modal__Topbar': true,
            'ModalGeneric__Modal__Topbar--active': topbarActive,
            [topbarCustomClassName]: Boolean(topbarCustomClassName)
          })}>
            <div className={`ModalGeneric__Modal__Topbar${topbarActive ? '--active' : ''}__Title`}>
              <Typography align='center' type="paragraph-medium" Tag="span" color={titleColor}>
                {modalTitle}
              </Typography>
            </div>
          </div>
        )}

      </motion.div>
    </motion.section>
    , document.querySelector('#modal'))
}

ModalGeneric.Group = ({ children }) => {
  return (
    <div className="ModalGeneric__Modal__Container__Group">
      {children}
    </div>
  )
}

ModalGeneric.GroupItem = ({ children }) => {
  return (
    <div className="ModalGeneric__Modal__Container__Group__Item">
      {children}
    </div>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onHideModal: () => dispatch(actions.hideModal())
  }
}

export default connect(null, mapDispatchToProps)(ModalGeneric)
