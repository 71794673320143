import styled from 'styled-components'
interface ContentProps {
  isLoading: boolean
}

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div<ContentProps>`
  opacity: ${({ isLoading }) => isLoading && 0};
`

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
