import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import LineSeparator from 'components/LineSeparator'
import { useGetPatientPersonalData } from '../../useGetPatientPersonalData'

import * as S from './styles'

type InfoProps = {
  label: string
  value: string
}

interface PatientPersonalDataProps {
  patientEmail: string
}

const Info = ({ label, value }: InfoProps) => {
  return (
    <S.InfoTitleWrapper>
      <Typography variant='content2'>
        {label}
      </Typography>
      <Typography variant='content2'>
        {value}
      </Typography>
    </S.InfoTitleWrapper>
  )
}

export const PatientPersonalData = ({ patientEmail }: PatientPersonalDataProps): JSX.Element => {
  const { personalData } = useGetPatientPersonalData(patientEmail)

  const address = personalData.address
  const familyContact = personalData.familyContact

  const hasAddress = address && Object.values(address).some((value) => value)
  const hasFamilyContact = familyContact && Object.values(familyContact).some((value) => value)

  const addressInfo: InfoProps[] = address && [
    { label: 'CEP', value: address['cep'] },
    { label: 'Endereço', value: address['address'] },
    { label: 'Número', value: address['number'] },
    { label: 'Complemento', value: address['complement'] },
    { label: 'Bairro', value: address['neighborhood'] },
    { label: 'Cidade', value: address['city'] },
    { label: 'Estado', value: address['state'] }
  ]
  
  const familyContactInfo: InfoProps[] = familyContact && [
    { label: 'Nome', value: familyContact['fullName'] },
    { label: 'Parentesco', value: familyContact['relationship']},
    { label: 'Telefone', value: familyContact['phone'] }
  ]

  return (
    <S.Container>
      {hasAddress && (
        <>
          <S.InfoBlockWrapper>
            <Typography variant='content2' weight='bold'>
              Endereço
            </Typography>

            {addressInfo.map((info) => (
              <Info key={info.label} label={info.label} value={info.value} />
            ))}
          </S.InfoBlockWrapper>
          <LineSeparator
            lineColor="#e2e2e2"
            lineHeight="1px"
            lineMargin="10px 0"
            lineWidth="100%"
          />
        </>
      )}
      
      {hasFamilyContact && (
        <>
          <S.InfoBlockWrapper>
            <Typography variant='content2' weight='bold'>
              Contato do familiar
            </Typography>

            {familyContactInfo.map((info) => (
              <Info key={info.label} label={info.label} value={info.value} />
            ))}
          </S.InfoBlockWrapper>
          <LineSeparator
            lineColor="#e2e2e2"
            lineHeight="1px"
            lineMargin="10px 0 40px"
            lineWidth="100%"
          />
        </>
      )}
    </S.Container>
  )
}