/** Institutional site routes */
export const HOME_INSTITUCIONAL = `${process.env.REACT_APP_INSTITUTIONAL_URL}`
export const BLOG = `${process.env.REACT_APP_INSTITUTIONAL_URL}/blog`
export const FACA_PARTE_CONVENIOS = `${process.env.REACT_APP_INSTITUTIONAL_URL}/faca-parte/clinicas`
export const FACA_PARTE_EMPRESAS = `${process.env.REACT_APP_INSTITUTIONAL_URL}/faca-parte/empresas`
export const FACA_PARTE_MEDICOS = `${process.env.REACT_APP_INSTITUTIONAL_URL}/faca-parte/medicos`
export const FACA_PARTE_PSICOLOGOS = `${process.env.REACT_APP_INSTITUTIONAL_URL}/faca-parte/psicologos`
export const MEDICINA = `${process.env.REACT_APP_INSTITUTIONAL_URL}/medicina-online`
export const POLITICAS_PRIVACIDADE = `${process.env.REACT_APP_INSTITUTIONAL_URL}/politica-de-privacidade`
export const PSICOLOGIA = `${process.env.REACT_APP_INSTITUTIONAL_URL}/psicologia-online`
export const PSIQUIATRIA = `${process.env.REACT_APP_INSTITUTIONAL_URL}/psiquiatria-online`
export const QUEM_SOMOS = `${process.env.REACT_APP_INSTITUTIONAL_URL}/quem-somos`
export const TERMOS_USO = `${process.env.REACT_APP_INSTITUTIONAL_URL}/termos-de-uso`

/** Public routes */
export const HOME_PSIC = '/psicologia-online/encontre-seu-psicologo/'
export const HOME_PSIQ = '/psiquiatria-online/encontre-seu-psiquiatra/'
export const SURVEY_RESPONSE = '/nps-feedback'
export const NOT_FOUND = '/404'

/** Error page routes */
export const ERROR_CONECTION = '/connection-error'

/** Authentication flow routes */
export const FORGOT_PASSWORD = '/recuperar-senha'
export const LOGIN = '/'
export const RECOVER_EMAIL = '/recuperar-email'
export const REGISTER = '/criar-conta'
export const REGISTER_INFO_GROUP = '/criar-conta/convenio'
export const REGISTER_INFO_PERSONAL = '/criar-conta/informacoes'
export const REGISTER_PHONE_CHECK = '/criar-conta/confirmacao'
export const REGISTER_PHONE_CHECK_PIN = '/criar-conta/confirmacao/pin'
export const REGISTER_SUCCESS = '/criar-conta/sucesso'
export const REGISTER_ATTACH_MEDICAL_REQUEST = '/criar-conta/pedido-medico'
export const REGISTER_SUCCESS_ATTACH_MEDICAL_REQUEST = '/criar-conta/sucesso-pedido-medico'
export const REGISTER_ATTACH_MEDICAL_REQUEST_DONE = '/criar-conta/pedido-medico/sucesso'
export const RESEND_PIN = '/reenviar-pin'
export const RESET_PASSWORD = '/redefinir-senha'
export const FACE_ID = '/reconhecimento-facial'

/** TEMPORARY CHECKOUT PLANS ROUTES */
export const CHECKOUT_PLANS = '/checkout-plans'
export const CHECKOUT_PLANS_FIRST_TIME = '/checkout-plans/first-time'
export const CHECKOUT_PLANS_MEDICAL_REQUEST = '/checkout-plans/medical-request'
export const CHECKOUT_PLANS_DETAILS = '/checkout-plans/details'
export const CHECKOUT_PLANS_CONFIRMATION = '/checkout-plans/confirmation'

/** Payment flow routes */
export const PAYMENT = '/payment'
export const PAYMENT_CART = '/payment/cart'
export const PAYMENT_CHECKOUT = '/payment/checkout'
export const PAYMENT_PROCESSING = '/payment/processing'
export const PAYMENT_RESPONSE = '/payment/response'
export const PLAN_CHECKOUT = '/plans/checkout'

export const DASHBOARD = '/dashboard'
export const DASHBOARD_ROUTER = '/dashboard/home'

// Paciente
export const DASHBOARD_HOME_PATIENT = '/dashboard/patient'
export const DASHBOARD_APPOINTMENTS = '/dashboard/patient/schedules'

// Profissional
export const DASHBOARD_HOME = '/dashboard/professional'
export const DASHBOARD_AGENDA = '/dashboard/professional/agenda'
export const DASHBOARD_PATIENTS = '/dashboard/professional/patients'
export const DASHBORAD_MEDICAL_REQUESTS = '/dashboard/prof/medical-requests'
export const DASHBOARD_RESERVATIONS_PROFESSIONAL = '/dashboard/professional/reservations'
export const DASHBOARD_REVIEWS_PROFESSIONAL = '/dashboard/professional/reviews'
export const DASHBOARD_GRAPHICS_PROFESSIONAL = '/dashboard/professional/graphics'

// Secretário
export const DASHBOARD_GENERAL_VIEW = '/dashboard/secretary'
export const DASHBOARD_CLIENT_LIST = '/dashboard/secretary/patients'
export const DASHBOARD_ADMIN_SCHEDULES = '/dashboard/secretary/schedules'

// Administrador
export const DASHBOARD_PAYMENT_LIST = '/dashboard/payment-list'
export const DASHBOARD_PERMISSIONS = '/dashboard/permissions'
export const DASHBOARD_PLANS = '/dashboard/plans'
export const DASHBORAD_REPORTS = '/dashboard/reports'
export const DASHBORAD_ADMIN_MEDICAL_REQUESTS = '/dashboard/admin/medical-requests'
export const DASHBOARD_PLAN_GROUP_LIST_VIEW = '/dashboard/plan-groups'
export const DASHBOARD_PLAN_GROUP_LIST_NEW = '/dashboard/plan-groups/new'


/** Routes with arguments */
export const DASHBOARD_ADD_PROFESSIONAL = role => role ?
  `/dashboard/professional/${role}` : '/dashboard/professional/:role'

export const DASHBOARD_ADMIN_AGENDA = (slug, type) => slug ?
  `/dashboard/admin/agenda/${slug}/${type}` : '/dashboard/admin/agenda/:slug/:type'

export const DASHBOARD_EDIT_USER = (user, role) => user && role ?
  `/dashboard/edit-user/${user}/${role}` : '/dashboard/edit-user/:username/:role'

export const DASHBOARD_NEW_USER = role => role ?
  `/dashboard/new-user/${role}` : '/dashboard/new-user/:role'

export const DASHBOARD_PAYMENT_DETAIL = id => id ?
  `/dashboard/payment/detail/${id}` : '/dashboard/payment/detail/:id'

export const DASHBOARD_PROFESSIONAL_LIST = role => role ?
  `/dashboard/professional-list/${role}` : '/dashboard/professional-list/:role'

export const DASHBOARD_EDIT_PROFESSIONAL = (slug, role) => slug && role ?
  `/dashboard/professional/${role}/${slug}` : '/dashboard/professional/:role/:slug'

export const PROFESSIONAL_PSIC_PROFILE = friendlyUrl => friendlyUrl ?
  `/psicologia-online/${friendlyUrl}` : '/psicologia-online/:friendlyUrl'

export const PROFESSIONAL_PSIQ_PROFILE = friendlyUrl => friendlyUrl ?
  `/psiquiatria-online/${friendlyUrl}` : '/psiquiatria-online/:friendlyUrl'

export const QUESTIONNAIRE = scheduleId => scheduleId ? `/nps/${scheduleId}` : '/nps/:scheduleId'

export const DASHBOARD_RESERVATIONS_ADMIN = professional => `/dashboard/admin/reservation/${professional}`
