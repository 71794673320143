import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import { Typography, CoreIcons } from 'components'

// ToggleButton
// Alterar ToggleButton para Toggle

const ToggleButton = ({
  itemId,
  itemName,
  iconName,
  itemTitle,
  imgAlt,
  isActive,
  isFilterAll,
  wrapperRef,
  handleClick
}) => {
  return (
    <div
      className={isActive ? 'ToggleButton--active' : 'ToggleButton'}
      id={itemId}
      onClick={handleClick}
      ref={node => (wrapperRef = node)}
    >
      <div
        className="ToggleButton__icon"
        fill={isActive ? '#fff' : '#585858'}
      >
        {
          isFilterAll ?

            (
              <CoreIcons fill={isActive ? '#fff' : '#585858'} name={iconName} />
            ) :
            (
              <img className="ToggleButton__icon" src={itemName} alt={imgAlt}/>
            )}
      </div>
      <div className="text">
        <Typography
          align="center"
          bold
          color={isActive ? 'white' : 'color-title'}
          type="custom"
          className="ToggleButton__labelValue">
          {itemTitle}
        </Typography>
      </div>
    </div>
  )
}

ToggleButton.propTypes = {
  isActive: PropTypes.bool,
  itemId: PropTypes.string,
  itemName: PropTypes.string,
  itemTitle: PropTypes.string,
  imgAlt: PropTypes.string,
  handleClick: PropTypes.func,
  isFilterAll: PropTypes.bool
}

export default ToggleButton
