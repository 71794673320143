import { useCallback, useState } from 'react'
import { formatISO } from 'date-fns'

import metricsService from 'services/metrics'
import { IFetchScheduleParams, IScheduleData, IUseTableData, IPatient } from '../types'

function formatData(scheduleData: IScheduleData) {
  return scheduleData.patients.map(patient => {
    return {
      total: patient.schedule_count,
      groupPlan: patient.plan_group_name,
      allowsReservation: patient.allows_reservation,
      hasReservation: patient.has_reservation,
      name: patient.full_name,
      email: patient.username,
      phone: patient.cellphone_number
    }
  })
}

export const useTableData = (): IUseTableData => {
  const [data, setData] = useState<IPatient[]>([])
  const [loading, setLoading] = useState(false)

  const fetchScheduleDataByStatus = useCallback(async({
    selectedDate,
    statusCode,
    reasonCode
  }: IFetchScheduleParams) => {
    try {
      setLoading(true)
      const response = await metricsService.getListSchedulesByStatus ({
        initialDate: formatISO(new Date(selectedDate.initialDate)),
        endDate: formatISO(new Date(selectedDate.endDate)),
        scheduleStatusCode: statusCode,
        reasonCode: reasonCode
      })

      setData(formatData(response))
    } catch(err) {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }, [])

  return { fetchScheduleDataByStatus, data, loading }
}