import React, { useState, useEffect } from 'react'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { CPF } from 'gerador-validador-cpf/dist/js/CPF'
import { useDispatch, useSelector } from 'react-redux'
import flags from 'react-phone-number-input/flags'
import { format } from 'date-fns'

import { Typography, Button } from '@telavita-core/react-design-kit'
import {  TextInput } from 'components'
import Dropdown from 'components/Dropdown/index'

import { getFullPlansName } from 'utils/plansHandlers'
import InputValidation from 'utils/InputValidation'

import { DASHBOARD_EDIT_USER, DASHBOARD_CLIENT_LIST } from 'settings/_routesSettings'
import { apiEndpoints } from 'settings/_apiSettings'
import { GENDER, INACTIVE } from 'settings/_personSettings'
import { PATIENT } from 'settings/_profileSettings'
import axios from 'settings/axios'

import * as actions from 'redux/actions'
import { modalTypes } from 'containers/ModalManager/ModalManager'
import { getPlans } from 'services/getPlans'

import * as S from './styles'
import PlanCard from './components/PlanCard'
import InactivatePlanModal from 'components/InactivatePlanModal'
import { linkPlan } from 'services/plan/linkPlan'
import LinkPlanModal from 'components/LinkPlanModal'
import moment from 'moment'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'
import { handleApiErrors } from 'utils/errorsHandler'

const EditUser = ({ match, history, isDesktop }) => {

  const { VITTA } = PlanGroupCategoryEnum

  const dispatch = useDispatch()

  const userRole = match.params.role
  const userEmail = match.params.username

  const initialFormState = {
    full_name: {
      value: '',
      isValid: true,
      message: '',
    },
    gender: {
      value: '',
      isValid: true,
      message: '',
    },
    cpf: {
      value: '',
      isValid: true,
      message: '',
    },
    date_birth: {
      value: '',
      isValid: true,
      message: '',
    },
    cellphone_number: {
      value: '',
      isValid: true,
      message: '',
    },
    phone_number: {
      value: '',
      isValid: true,
      message: '',
    },
    partner_plan: {
      value: '',
      isValid: true,
      message: '',
    },
    card_number: {
      value: '',
      isValid: true,
      message: '',
    },
    email: {
      value: '',
      isValid: true,
      message: '',
    },
    plans: []
  }

  const [isNewUser, setIsNewUser] = useState(true)
  const [readyToSubmit, setReadyToSubmit] = useState(false)
  const [formData, setFormData] = useState(initialFormState)
  const [plans, setPlans] = useState([])
  const [selectedPlan, setSelectedPlan] = useState()
  const [openInactivatePlanModal, setOpenInactivePlanModal] = useState(false)
  const [openLinkPlanModal, setOpenLinkPlanModal] = useState(false)

  const particularPartnerPlanCode = 'PLN01000'

  const genderOptions = GENDER

  useEffect(() => {
    window.scrollTo(0, 0)
    
    getPlans(userRole).then(({ plans }) => {
      setPlans(plans)
      checkEmail()
    })  

  },[])

  useEffect(() => {
    checkRequiredFields()
  }, [formData])

  const checkEmail = () => {
    /**
     * Check if iputed email already
     * exists in the database and if it
     * exists fill the personal data
     */
    if (userEmail &&
      userEmail !== '') {
      axios.get(apiEndpoints.PERSON_SLUG(userEmail, userRole))
        .then(response => {
          if (response.status !== 'ZERO_RESULTS') {
            const userData = response.data
            setIsNewUser(false)
            setFormData(prevState => ({
              full_name: {
                ...prevState.full_name,
                value: userData.person_general.full_name,
              },
              email: {
                ...prevState.email,
                value: userData.person_general.email,
              },
              cpf: {
                ...prevState.cpf,
                value: userData.person_general.cpf,
              },
              date_birth: {
                ...prevState.date_birth,
                value: userData.person_general.date_birth,
              },
              gender: {
                ...prevState.gender,
                value: userData.person_general.gender,
              },
              phone_number: {
                ...prevState.phone_number,
                value: userData.person_general.phone_number ?
                  `+${userData.person_general.phone_number}` : '',
              },
              cellphone_number: {
                ...prevState.cellphone_number,
                value: userData.person_general.cellphone_number ?
                  `+${userData.person_general.cellphone_number}` : '',
              },
              card_number: {
                ...prevState.card_number,
                value: '',
              },
              plans: userData.person_profile.length > 0 ? userData.person_profile[0].plans.filter(plan => plan.partner_plan_code !== particularPartnerPlanCode && !plan.partner_plan_is_particular) : []
            }))
          }
        })
        .catch(err => {
          console.log(err)
          const response = err.response.data
          if (response.status !== 'ZERO_RESULTS') {
            console.log(err)
          } else {
            /**
             * Case there're ZERO_RESULTS reset
             * the form and set isNewUser state
             */
            setIsNewUser(true)
            setFormData(prevState => ({
              ...prevState,
              ...initialFormState,
            }))
          }
        })
    }
  }

  const handleErrorOnSubmit = (errorList) => {
    const fieldList = errorList?.fieldError !== undefined && errorList.fieldError.length > 0 ? errorList.fieldError : null
    const newFormData = formData && Object.keys(formData).reduce((acc, curr) => {
      const currentFormField = formData[curr]
      const currentError = fieldList.find(fieldError => fieldError.field === curr)

      if (currentError) {
        const newFormFieldData = {
          ...currentFormField,
          isValid: false,
          message: currentError.message
        }
        acc[curr] = newFormFieldData
      } else {
        acc[curr] = currentFormField
      }

      
      return acc
    }, {})

    setFormData(newFormData)
  }

  const onSubmit = () => {
    const statusCode = 'STS24001'
    const nameSpread = formData.full_name.value.split(' ')
    const firstName = nameSpread.splice(0, 1).join('')
    const lastName = nameSpread.join(' ')
    const cpf = CPF.format(formData.cpf.value, 'digits')

    const formatPhone = (phone) => {
      let firstChar = phone.slice('')[0]
      let hasPlus = (firstChar === '+')

      if (hasPlus)
        return phone.substr(1)

      return phone
    }

    let postData = {
      'profile_role': 'PACI',
      'first_name': firstName,
      'last_name': lastName,
      'gender': formData.gender.value,
      'cpf': cpf,
      'date_birth': formData.date_birth.value,
      'cellphone_number': formatPhone(formData.cellphone_number.value),
      'phone_number': formData.phone_number.value ? formatPhone(formData.phone_number.value) : '',
      'status_code': statusCode,
    }

    let modalType = modalTypes.NEW_USER_SUCCESS
    let modalData = {
      btnText: 'Fechar',
      modalTitle: '',
      handleClick: () => {
        history.push(DASHBOARD_CLIENT_LIST)
        dispatch(actions.hideModal())
      },
      modalChildren: (

        <Typography weight='bolder'>
          {formData.full_name.value}
        </Typography>
      ),
    }
    /**
     * If user already has a profile
     * related to actual partner
     * PUTs data and update person_general
     * and person_profile
     */
    modalData.modalTitle = 'Perfil modificado com sucesso!'

    axios.put(apiEndpoints.PERSON_SLUG(formData.email.value, formData.userRole), postData)
      .then(response => {
        dispatch(actions.showModal(modalType, modalData))
      })
      .catch(async (err) => {
        const errors = await handleApiErrors(err.response.data)
        handleErrorOnSubmit(errors)
      })
  }

  const handleSelectGender = (code) => {
    setFormData(prevState => ({
      ...prevState,
      ['gender']: {
        ...prevState.gender,
        value: code
      }
    }))
  }

  const checkRequiredFields = () => {
    let isReadyToSubmit = true
    let requiredStates = [
      'full_name',
      'email',
      'gender',
      'cpf',
      'date_birth',
      'cellphone_number',
    ]


    requiredStates.map(rq => {
      if (formData[rq].value === '' ||
        formData[rq].value === undefined ||
        formData[rq].value === null ||
        formData[rq].isValid === false) {
        isReadyToSubmit = false
      }
      return isReadyToSubmit
    })

    if (isReadyToSubmit !== readyToSubmit) setReadyToSubmit(isReadyToSubmit)

    return readyToSubmit
  }

  const handleUpdateForm = ({
    inputValue, 
    inputValidation, 
    formKey
  }) => {
    const validation = new InputValidation(inputValue, inputValidation).validate()
    setFormData(prevState => ({
      ...prevState,
      [formKey]: {
        ...prevState[formKey],
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      }
    }))
  }

  const handleBirthdateInput = (e) => {
    const inputValue = e.target.value
    const minDate = '01/01/1900'
    const validateOptions = {
      minDate: minDate,
      maxDate: moment(),
    }
    let validation = new InputValidation(inputValue, 'date', validateOptions).validate()
    setFormData(prevState => ({
      ...prevState,
      date_birth: {
        ...prevState.date_birth,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      }
    }))
  }

  const handleCellphoneInput = (value) => {
    const inputValue = value
    if (inputValue) {
      isValidPhoneNumber(inputValue) ?
        setFormData(prevState => ({
          ...prevState,
          cellphone_number: {
            ...prevState.cellphone_number,
            value: inputValue,
            isValid: true,
            message: undefined,
          }
        })) : 
        setFormData(prevState => ({
          ...prevState,
          cellphone_number: {
            ...prevState.cellphone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido',
          }
        }))
    } else {
      setFormData(prevState => ({
        ...prevState,
        cellphone_number: {
          ...prevState.cellphone_number,
          value: inputValue,
          isValid: true,
          message: '',
        }
      }))
    }
  }

  const handlePhoneInput = (value) => {
    const inputValue = value
    if (inputValue) {
      isValidPhoneNumber(inputValue) ?
        setFormData(prevState => ({
          ...prevState,
          phone_number: {
            ...prevState.phone_number,
            value: inputValue,
            isValid: true,
            message: undefined,
          }
        })) : 
        setFormData(prevState => ({
          ...prevState,
          phone_number: {
            ...prevState.phone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido',
          }
        }))     
    } else {
      setFormData(prevState => ({
        ...prevState,
        phone_number: {
          ...prevState.phone_number,
          value: inputValue,
          isValid: true,
          message: '',
        }
      }))
    }
  }

  const onLinkPlan = (data) => {
    linkPlan(data)
      .then(() => {
        const currentPlan = plans.find(plan => plan.code === data.planCode)
      
        setFormData(prevState => ({
          ...prevState,
          plans: [...prevState.plans, {
            card_number: data.cardNumber,
            partner_plan_name: currentPlan.name,
            partner_plan_code: data.planCode,
            plan_group: {
              logo_url: currentPlan.plan_group.logo_url,
              name: currentPlan.plan_group.name
            }
          }]
        }))
      })
  }

  const onInactivatePlan = () => {
    setFormData(prevState => ({
      ...prevState,
      plans: prevState.plans.map(plan => plan.partner_plan_code === selectedPlan.code ? { ...plan, status_code: INACTIVE } : plan)
    }))
  }

  const modalData = {
    emailUser: userEmail,
    name: formData?.full_name?.value,
    modalTitle: 'Alterar endereço de e-mail',
    titleColor: 'black',
    btnText: 'CONFIRMAR ALTERAÇÃO',
    classModifier: 'default',
    redirectURL: DASHBOARD_EDIT_USER,
    profile: PATIENT
  }

  return (
    <S.Container>
      <S.TitleWrapper>
        <Typography variant='header2'>
           Perfil do paciente
        </Typography>
      </S.TitleWrapper>
      <S.FormWrapper>
        <S.FormTitleWrapper>
          <Typography variant='content2'>
            Dados pessoais
          </Typography>        
        </S.FormTitleWrapper>
        <S.FormContainer>
          <TextInput
            placeholder="Nome completo*"
            required={true}
            name="full_name"
            hasError={!formData.full_name.isValid}
            inputHeight='4.8rem'
            messageError={formData.full_name.message}
            onInputChange={(e) => handleUpdateForm({
              inputValue: e.target.value,
              inputValidation: 'name',
              formKey: 'full_name'
            })}
            value={formData.full_name.value}
          />
          <TextInput disabled
            placeholder="E-mail*"
            required={true}
            name="email"
            hasError={!formData?.email?.isValid}
            inputHeight='4.8rem'
            messageError={formData.email.message}
            onInputChange={(e) => handleUpdateForm({
              inputValue: e.target.value,
              inputValidation: 'email',
              formKey: 'email'
            })}
            onFocusOut={checkEmail}
            value={formData.email.value}
            addIcon
            iconProps={{
              name: 'PencilEdit',
              fill: '#1F35B5',
              height: '24',
              width: '24',
              viewBox: '0 0 22 22',
            }}
            onClickIcon={()=> dispatch(actions.showModal(modalTypes.USER_PROFILE_EMAIL, modalData))}
          />
          <Dropdown
            options={genderOptions}
            onSelect={(code) => handleSelectGender(code)}
            placeholder="Gênero*"
            name="gender"
            selectedItem={formData.gender.value}
            preSelectedItem={isNewUser ?
              null : formData.gender.value}
          />
          <TextInput
            placeholder="CPF*"
            required={true}
            name="cpf"
            hasError={!formData.cpf.isValid}
            inputHeight='4.8rem'
            mask="111.111.111-**"
            messageError={formData.cpf.message}
            onInputChange={(e) => handleUpdateForm({
              inputValue: e.target.value,
              inputValidation: 'cpf',
              formKey: 'cpf'
            })}
            value={formData.cpf.value}
          />
          <TextInput
            placeholder="Data de nascimento*"
            required={true}
            name="date_birth"
            hasError={!formData.date_birth.isValid}
            inputHeight='4.8rem'
            messageError={formData.date_birth.message}
            mask="11/11/1111"
            onInputChange={(e) => handleBirthdateInput(e)}
            value={formData.date_birth.value}
          />
          <PhoneInput
            id="cellphone_number"
            country="BR"
            limitMaxLength={true}
            placeholder="Telefone celular*"
            value={formData.cellphone_number.value}
            onChange={(cellphone_number) => handleCellphoneInput(cellphone_number)}
            error={formData.cellphone_number.message}
            flags={flags}
          />
          <PhoneInput
            id="phone_number"
            country="BR"
            limitMaxLength={true}
            placeholder="Telefone comercial"
            value={formData.phone_number.value}
            onChange={(phone_number) => handlePhoneInput(phone_number)}
            error={formData.phone_number.message}
            className="editUserPaci__phone-number"
            flags={flags}
          />
        </S.FormContainer>
        <S.PlansWrapper>
          <S.PlansTitleWrapper>
            <Typography variant='content2'>
                Convênios
            </Typography>           
          </S.PlansTitleWrapper>
          {formData.plans.length <= 0 && (
            <S.EmptyStateContainer>
              <S.EmptyStateText>
               Nenhum convênio vinculado
              </S.EmptyStateText>
            </S.EmptyStateContainer>
          )}
          {formData.plans.length > 0 && (
            <S.PlansListWrapper>
              {formData.plans.map(plan => (
                <PlanCard 
                  planGroupName={plan.plan_group.name}
                  planLogo={plan.plan_group.logo_url}
                  planName={plan.partner_plan_name}
                  cardNumber={plan.card_number}
                  isPlanActive={plan.status_code !== INACTIVE}
                  actions={[
                    {
                      label: 'Inativar convênio',
                      icon: false,
                      onClick: () => {
                        setOpenInactivePlanModal(true)
                        setSelectedPlan({
                          name: `${plan.plan_group.name} ${plan.partner_plan_name}`,
                          code: plan.partner_plan_code,
                          cardNumber: plan?.card_number
                        })
                      }
                    }
                  ]}
                />
              ))}
            </S.PlansListWrapper>
          )}
          <S.AddPlanWrapper>
            <Button customClassName='AddPlanButton' variant='outlined' uppercase={false} onClick={() => setOpenLinkPlanModal(true)}>
            Adicionar convênio
            </Button>    
          </S.AddPlanWrapper>      
        </S.PlansWrapper>
      </S.FormWrapper>
      <Typography variant='content2' italic inlineStyles={{ color: '#898989' }}>
        *Campos de preenchimento obrigatório
      </Typography>
      <S.SubmitWrapper>
        <Button 
          id="submit"
          disabled={!readyToSubmit}
          onClick={() => onSubmit()}
          variant='contained'  
          type='submit'
          inlineStyles={{ width: '50%' }}
        >
        SALVAR MODIFICAÇÕES
        </Button>
      </S.SubmitWrapper>
      {openInactivatePlanModal && (
        <InactivatePlanModal
          patientName={formData?.full_name.value}
          planName={selectedPlan?.name}
          cardNumber={selectedPlan?.cardNumber}
          onSuccess={onInactivatePlan}
          username={userEmail}
          planCode={selectedPlan.code}
          onClose={() => {
            setOpenInactivePlanModal(false)
            setSelectedPlan(undefined)
          }}
        />
      )}
      {openLinkPlanModal && (
        <LinkPlanModal
          plans={plans.filter(plan => !plan.is_particular && plan.plan_group.category !== VITTA)}
          onClose={() => setOpenLinkPlanModal(false)}
          patientCpf={formData.cpf.value}
          patientPlans={formData.plans}
          onLinkPlan={onLinkPlan}
          userName={userEmail}
        />
      )}
    </S.Container>
  )
}

export default EditUser
