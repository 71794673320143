import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'

interface Error {
  response: {
    status: number
  }
}

export const isCpfAvailable = (cpf: string): Promise<void> => {

  const endpoint = apiEndpoints.PATIENTS_BY_CPF(cpf)

  return new Promise<void> ((resolve, reject) => {
    axios.head(endpoint)
      .then(() => resolve())
      .catch((error: Error) => reject(error.response.status))
  })
}

