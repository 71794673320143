import React from 'react';
import PropTypes from 'prop-types';

import colList from './ColList';

const Column = ({
  align,
  children,
  className,
  colWidth,
  isMansory,
  justify,
  leftGutter,
  rightGutter,
  style,
}) => {

  let classesNames = 'col';
  let compStyle = {};

  if (colWidth) {
    let columnSize = colList.filter(item => item.col === colWidth);
    classesNames = classesNames.concat(` ${columnSize[0].className}`);
  }

  if (leftGutter) {
    classesNames = classesNames.concat(` left-offset`);
  }

  if (isMansory) {
    classesNames = classesNames.concat(` mansory-col`);
  }

  if (rightGutter) {
    classesNames = classesNames.concat(` right-offset`);
  }

  if (className) {
    classesNames = classesNames.concat(` ${className}`);
  }

  if (align) compStyle.alignContent = align;
  if (justify) compStyle.justifyContent = justify;
  if (style) compStyle = {
    ...compStyle,
    ...style,
  };

  return (
    <div
      className={classesNames}
      style={compStyle}>
      {children}
    </div>
  );
};

Column.propTypes = {
  align: PropTypes.oneOf([
    'stretch',
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'initial',
  ]),
  colWidth: PropTypes.number,
  justify: PropTypes.oneOf([
    'center',
    'flex-start',
    'flex-end',
    'space-between',
    'space-around',
    'initial',
    'inherit',
  ]),
  leftGutter: PropTypes.bool,
  rightGutter: PropTypes.bool,
  style: PropTypes.any,
};

export { Column };
