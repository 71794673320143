import React, { useState } from 'react'

// Libs
import DayPicker, { DayModifiers } from 'react-day-picker'
import { addMonths, format, formatISO, getDay, isSameDay, set } from 'date-fns'

// Components
import { Icon, Paper, TextBox, Typography } from '@telavita-core/react-design-kit'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

// Services
import ReservationService from 'services/reservation'

// Settings
import { WEEKDAYS_SHORT, MONTHS, WEEKDAYS_NAMES } from '../../settings/dayPicker'

// Styles
import * as S from './styles'

interface CancelReservationDayModalProps {
  patientFullName?: string;
  patientUsername: string;
  reservationStartdate: Date;
  professionalFullName: string;
  professionalUsername: string;
  isProfessional?: boolean;
  reservationUUID: number;
  cancelledDates: Array<{
    exceptionDate: string;
    exceptionType: string;
  }>;
  onRefresh: () => void;
  onClose: () => void;
}

const CancelReservationDayModal = ({
  patientFullName,
  patientUsername,
  professionalFullName,
  professionalUsername,
  reservationStartdate,
  isProfessional,
  reservationUUID,
  cancelledDates = [],
  onRefresh,
  onClose
}: CancelReservationDayModalProps): JSX.Element => {
  const [cancelationReason, setCancelationReason] = useState('')
  const [selectedDays, setSelectedDays] = useState<Date[] | undefined>([])
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)

  const currentDate = new Date()

  const reservationDayOfWeek = getDay(reservationStartdate)
  const reservationWeekday = WEEKDAYS_NAMES[reservationDayOfWeek]
  const reservationHour = format(reservationStartdate, "HH'h'mm") // eslint-disable-line

  const getSubtitle = () => {
    if (isProfessional) {
      return <>Paciente <b>{patientFullName}</b></>
    } else {
      return <>Psicólogo <b>{professionalFullName}</b></>
    }
  }

  const handleDayClick = (day: Date, { selected, disabled }: DayModifiers) => {
    if (disabled) return

    if (selected) {
      setSelectedDays(oldState => oldState.filter(oldDay => !isSameDay(oldDay, day)))
    } else {
      setSelectedDays(oldState => [...oldState, day])
    }
  }

  const clearStates = (success: boolean) => {
    setSuccess(success)
    setCancelationReason('')
    setSelectedDays([])
    setLoading(false)
  }

  const onSubmit = () => {
    setLoading(true)

    ReservationService.updateReservation({
      uuid: reservationUUID,
      professional_username: professionalUsername,
      patient_username: patientUsername,
      reason: cancelationReason,
      date_exceptions: selectedDays.map(day => formatISO(set(day, { hours: Number(reservationHour.slice(0, 2)), minutes: 0, seconds: 0 })))
    })
      .then(() => {
        clearStates(true)
      })
      .catch(() => {
        clearStates(false)
      })
  }

  const handleCloseModal = () => {
    onClose()
    if (success) onRefresh()
  }

  return (
    <ModalGeneric
      onCloseModal={handleCloseModal}
      modalTitle={!success && <>Escolha o dia da reserva <br /> que quer cancelar</>}
      modalSubtitle={!success &&
        <>
          {getSubtitle()} <br /> Reserva <S.ReservationDay>{reservationWeekday}, {reservationHour}</S.ReservationDay>
        </>
      }
      btnLoading={loading}
      btnOnClick={success ? handleCloseModal : onSubmit}
      btnText={success ? 'FECHAR' : 'CANCELAR RESERVA DESTE(S) DIA(S)'}
      btnDisabled={loading || (success ? false : cancelationReason.length < 5 || selectedDays.length <= 0)}
      hasButton
      classModifier={(success || loading) ? null : 'false'}
    >
      <S.Container dayOfWeek={reservationDayOfWeek + 1}>
        {!success && (
          <>
            <DayPicker
              selectedDays={selectedDays}
              onDayClick={handleDayClick}
              fromMonth={currentDate}
              toMonth={addMonths(currentDate, 3)}
              weekdaysShort={WEEKDAYS_SHORT}
              months={MONTHS}
              disabledDays={[
                {
                  before: reservationStartdate,
                },
                {
                  before: currentDate,
                },
                {
                  daysOfWeek: [0, 1, 2, 3, 4, 5, 6].filter(day => day !== reservationDayOfWeek)
                },
                ...cancelledDates.map(cancelledDate => new Date(cancelledDate.exceptionDate))
              ]}
            />
            <S.LabelWrapper>
              <Typography variant='content2' weight='bold'>Motivo do cancelamento*</Typography>
              <Typography variant='content3' italic>
                {isProfessional ?
                  'O paciente receberá esta mensagem como justificativa. Escreva uma mensagem amigável, como se estivesse falando com o paciente.' :
                  'O psicólogo receberá esta mensagem como justificativa. Escreva uma mensagem amigável, como se estivesse falando com o psicólogo.'}
              </Typography>
            </S.LabelWrapper>
            <TextBox
              maxLength={1000}
              placeholder='Digite o motivo'
              value={cancelationReason}
              onChange={(e) => setCancelationReason(e.currentTarget.value)}
            />
            {isProfessional && (
              <Paper variant='warning' customClassName='CancelReservationDayModal__Paper'>
                <Typography variant='content2'>
                  <b>Antes de cancelar, ligue para o paciente para confirmar. </b> <br />
                  Ao cancelar a reserva, o horário voltará a ser disponibilizado para outros pacientes agendarem
                  (caso esteja cadastrado em sua disponibilidade).
                </Typography>
              </Paper>
            )}
            <Typography variant='content2' italic customClassName='CancelReservationDayModal__WarningMessage'>
              *Preenchimento obrigatório
            </Typography>
          </>
        )}
        {!loading && success && (
          <S.SuccessWrapper>
            <Icon
              name='Check'
              color='success'
              height={50}
              width={50}
            />
            <S.SuccessTextWrapper>
              <Typography variant='header4' weight='bold'>
                Cancelamento de reserva <br /> efetuado com sucesso!
              </Typography>
              {isProfessional && (
                <Typography variant='content2' customClassName='CancelReservationDayModal__Success__Disclaimer'>
                  O paciente receberá email avisando <br /> que a reserva foi cancelada.
                </Typography>
              )}
            </S.SuccessTextWrapper>
            <Paper variant='warning' customClassName='CancelReservationDayModal__Paper'>
              <Typography variant='content2'>
                {isProfessional ? (
                  <>
                    <b>IMPORTANTE:</b> Se no horário desta reserva cancelada houver uma ou mais consultas agendadas pelo paciente,
                    ele ainda poderá realizá-las. Caso seja preciso cancelar estas consultas, entre em contato com nossa equipe de atendimento.
                  </>
                ) : (
                  <>
                    <b>IMPORTANTE:</b> Se no horário desta reserva cancelada houver uma ou mais consultas agendadas,
                    você ainda poderá realizá-las. Para cancelá-las, vá em <i>“Minhas consultas”</i> e selecione <i>“cancelar”</i> no menu da consulta.
                  </>
                )}
              </Typography>
            </Paper>
          </S.SuccessWrapper>
        )}
      </S.Container>
    </ModalGeneric>
  )
}

export {
  CancelReservationDayModal
}
