import React from "react";

const Group = ({
  fill,
  height,
  viewBox = "-10 -10 45 45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path d="M16 11a3 3 0 1 0-3-3 2.987 2.987 0 0 0 3 3zm-8 0a3 3 0 1 0-3-3 2.987 2.987 0 0 0 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05A4.22 4.22 0 0 1 17 16.5V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"/>
    </g>
  </svg>
);

export default Group;