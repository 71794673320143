import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Typography, useDimensions } from '@telavita-core/react-design-kit'
import * as actions from 'redux/actions'
import * as routes from 'settings/_routesSettings'
import { ACTIVE, INCOMPLETE_ACCOUNT } from 'settings/_personSettings'
import { modalTypes } from 'containers/ModalManager/ModalManager'
import BiographyData from '../RegisterBlocks/BiographyData'
import DocumentsData from '../RegisterBlocks/DocumentsData'
import EducationData from '../RegisterBlocks/EducationData'
import GeneralData from '../RegisterBlocks/GeneralData'
import MediaData from '../RegisterBlocks/MediaData'
import ProductsData from '../RegisterBlocks/ProductsData'
import SkillsData from '../RegisterBlocks/SkillsData'
import { LanguageData } from '../RegisterBlocks/LanguageData'
import { TreatmentByAgeData } from '../RegisterBlocks/TreatmentByAgeData'

import { Button, Column, Container, Spinner } from 'components'
import Row from 'components/Row'
import Toast from 'components/Toast'

import { AgeRangeCategory } from '../RegisterBlocks/TreatmentByAgeData/TreatmentByAgeData.types'

import * as S from './styles'

export const NewProfessional = ({ match }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { role, slug } = match.params
  const isEdit = slug !== undefined
  const { isMobile } = useDimensions()
  const personGeneral = useSelector(state => state.persons.person)
  const personProfile = useSelector(state => state.persons.personProfile)
  const registerBlocks = useSelector(state => state.registerBlocks)
  const errorList = useSelector(state => state.async.errorList)
  const submitSuccess = useSelector(state => state.async.success)
  const [error, setError] = useState([])
  const [loadingData, setLoadingData] = useState(isEdit)
  const [loadingSubmit, setLoadingSubmit] = useState(null)
  const [canSave, setCanSave] = useState(false)

  const [hasErrorOnAgeSelectRange, setHasErrorOnAgeSelectRange] = useState({
    childSelectError: false,
    teenagerSelectError: false,
  })

  useEffect(() => {
    return () => {
      dispatch(actions.cleanBlocksEdition())
      dispatch(actions.clearBlocksData())
    }
  }, [])

  const blocksToCheck = [
    'generalData',
    'biographyData',
    'educationData',
    'documentsData',
    'mediaData',
    'productsData',
    'skillsData',
    'languagesData',
    'treatmentByAgeData',
  ]

  const blocksToCheckWhenProfessionalIsPSIQ = blocksToCheck.filter(block => block !== 'treatmentByAgeData')
  
  const blocksToCheckByProfessionalRole = role === 'PSIC'
    ? blocksToCheck
    : blocksToCheckWhenProfessionalIsPSIQ
  
  const submitTypes = {
    SAVE: 'SAVE',
    PUBLISH: 'PUBLISH'
  }

  const ages = registerBlocks?.treatmentByAgeData.data.ages
  const childAgeRangeSelect = ages?.find(age => age.category === AgeRangeCategory.CHILD)
  const teenagerAgeRangeSelect = ages?.find(age => age.category === AgeRangeCategory.TEENAGER)

  // Preparar dados para salvamento
  const generatePersonData = (status) => {
    /**
     * This method verifies if the block is on the blockList,
     * if it is, the method treat data (if needed) and then
     * spread it on postData
     */
    let postData = {}
    const cleanCpf = registerBlocks.generalData.data.cpf ? {
      cpf: registerBlocks.generalData.data.cpf.replace('.', '').replace('.', '').replace('-', '')
    } : {}
    const cleanCellphone = registerBlocks.generalData.data.cellphone_number ? {
      cellphone_number: registerBlocks.generalData.data.cellphone_number.replace('+', '')
    } : {}
    const cleanPhone = registerBlocks.generalData.data.phone_number ? {
      phone_number: registerBlocks.generalData.data.phone_number.replace('+', '')
    } : {}
    const formattedSelectedLanguages = registerBlocks.languagesData.data.languages.map(language => {
      if (isEdit) {
        return {
          id: language.id,
          delete: language?.isSelected ? false : true
        }
      }
      if (language?.isSelected) {
        return {
          id: language.id
        }
      }
    }) 
    const formattedTreatmentByAge = registerBlocks.treatmentByAgeData.data.ages.map(age => {
      if (isEdit && age.isSelected) {
        return {
          category: age.category,
          start_age: age.ageRange.startAge,
          end_age: age.ageRange.endAge,
        }
      }

      if (isEdit && !age.isSelected && age.ageRange.id) {
        return {
          id: age.ageRange.id,
          delete: true
        }
      }

      if (age.isSelected) {
        return {
          category: age.category,
          start_age: age.ageRange.startAge,
          end_age: age.ageRange.endAge,
        }
      }
    })

    const cleanTreatmentByAge = registerBlocks.treatmentByAgeData?.data?.ages.length > 0 
      ? { age_ranges: formattedTreatmentByAge.filter(Boolean) } 
      : {}

    const selectedLanguages = {
      languages: formattedSelectedLanguages.filter(Boolean)
    }

    postData = {
      ...postData,
      ...registerBlocks.generalData.data,
      ...cleanCellphone,
      ...cleanPhone,
      ...cleanCpf,
      ...cleanTreatmentByAge,
      ...selectedLanguages,
      documents: registerBlocks.documentsData.data.documents,
      bio: registerBlocks.biographyData.data.biography,
      ...registerBlocks.mediaData.data,
      skills: registerBlocks.skillsData.data.skills,
      subspecialties: registerBlocks.subspecialtiesData.data.subspecialties,
      education: registerBlocks.educationData.data.education,
      profile_role: role,
      status_code: status,
    }
    let formatedAppointments = []
    if (registerBlocks.productsData.data.products.length > 0) {
      if (registerBlocks.productsData.data.products) {
        let appointments = [...registerBlocks.productsData.data.products]
        for (let i = 0; i < appointments.length; i++) {
          const product = appointments[i]
          let formatedItem
          if (product.price) {
            formatedItem = {
              ...product,
              price: parseFloat(product.price.replace(',', '.')),
              delete: product?.active === false ? true : false
            }
          } else {
            const PLACEHOLDER_PRICE = 999
            formatedItem = {
              ...product,
              price: PLACEHOLDER_PRICE,
              delete: product?.active === false ? true : false
            }
          }
          formatedAppointments.push(formatedItem)
        }
        postData = {
          ...postData,
          appointments: formatedAppointments,
        }
      }
    }
    return postData
  }

  useEffect(() => {
    if (!childAgeRangeSelect?.isSelected) {
      setHasErrorOnAgeSelectRange({
        ...hasErrorOnAgeSelectRange,
        childSelectError: false,
      })
    }

    if (!teenagerAgeRangeSelect?.isSelected) {
      setHasErrorOnAgeSelectRange({
        ...hasErrorOnAgeSelectRange,
        teenagerSelectError: false,
      })
    }

  }, [childAgeRangeSelect, teenagerAgeRangeSelect])

  function isInvalidAgeRange() {
    const childSelectError = childAgeRangeSelect.isSelected && !childAgeRangeSelect.ageRange.label
    const teenagerSelectError = teenagerAgeRangeSelect.isSelected && !teenagerAgeRangeSelect.ageRange.label

    setHasErrorOnAgeSelectRange({
      childSelectError,
      teenagerSelectError,
    })

    const hasErrors = childSelectError || teenagerSelectError

    return hasErrors
  }

  // Salvar ou publicar informações do profissional
  const onSubmit = async (submitType) => {
    if (isInvalidAgeRange()) {
      return null
    }

    setError(null)
    setLoadingSubmit(submitType)
    const status = submitType === submitTypes.PUBLISH ? ACTIVE : INCOMPLETE_ACCOUNT
    const personData = generatePersonData(status)
    if (isEdit) {
      await dispatch(actions.changePerson(slug, role, personData))
    } else {
      await dispatch(actions.setProfessional(personData))
    }
    setLoadingSubmit(null)
  }
  const handleCanSave = useCallback(() => {
    let validation = true
    for (let i = 0; i < blocksToCheckByProfessionalRole.length; i++) {
      const block = blocksToCheckByProfessionalRole[i]
      if (!registerBlocks[block].canSave) {
        validation = false
      }
    }
    setCanSave(validation)
  }, [blocksToCheckByProfessionalRole, registerBlocks])
  // Montar e exibir modal de sucesso
  const onSubmitSuccess = useCallback(() => {
    const modalData = {
      feedbackType: 'success',
      feedbackMessage: isEdit ? 'Profissional atualizado com sucesso!' : 'Profissional adicionado com sucesso!',
      onSuccessClose: () => {
        dispatch(actions.clearOnePerson())
        dispatch(actions.hideModal())
        history.push(routes.DASHBOARD_PROFESSIONAL_LIST(role))
      }
    }
    dispatch(actions.showModal(modalTypes.FEEDBACK, modalData))
  }, [dispatch, isEdit, role])
  // Buscar informações do profissional
  useEffect(() => {
    if (isEdit)
      dispatch(actions.getPerson(slug, role))
  }, [dispatch, slug, role, isEdit])
  // Setar informações nos blocos do formulário
  useEffect(() => {
    if (isEdit && personGeneral.first_name && personProfile.id) {
      dispatch(actions.editBlocksData({
        person: personGeneral,
        personProfile: personProfile,
      }))
    }
  }, [dispatch, personGeneral, personProfile, isEdit])
  // Mostrar o formulário e fazer validação inicial
  useEffect(() => {
    if (isEdit && registerBlocks.blocksData.generalData.email && personGeneral.username === slug)
      setLoadingData(false)
    handleCanSave()
  }, [registerBlocks, isEdit, personGeneral, slug, handleCanSave])
  // Preparar mensagem de erro
  useEffect(() => {
    let message = null
    let messageDefault = 'Houve um problema para executar a ação requerida. Tente novamente.'
    if (errorList && errorList.fieldError && errorList.fieldError[0])
      message = errorList.fieldError[0].message || messageDefault
    if (errorList && errorList.sysError && errorList.sysError[0])
      message = errorList.sysError[0].message || messageDefault
    setError(message)
  }, [errorList])
  // Detectar sucesso e chamar modal
  useEffect(() => {
    if (submitSuccess) {
      onSubmitSuccess()
    }
  }, [submitSuccess, onSubmitSuccess])
  return (
    <Container>
      <Row align='center' justify={!isMobile ? 'space-between' : 'center'}
        margin={!isMobile ? '0 0 4rem' : '2.5rem 0 1.5rem'}>
        <Typography variant='header2'>
          {isEdit ? 'Editar Profissional' : 'Adicionar Profissional'}
        </Typography>
      </Row>
      {
        loadingData ?
          <Row>
            <Spinner height="250px" />
          </Row>
          :
          <Row>
            <form onSubmit={onSubmit}>
              <GeneralData role={role} />
              <DocumentsData role={role} />
              <MediaData role={role} />
              <BiographyData role={role} slug={slug} />
              <ProductsData role={role} />
              <SkillsData role={role} />
              <EducationData role={role} />
              <TreatmentByAgeData 
                role={role} 
                setHasErrorOnAgeSelectRange={setHasErrorOnAgeSelectRange}
                hasErrorOnAgeSelectRange={hasErrorOnAgeSelectRange}  
              />
              <LanguageData />
            </form>
            
            <S.WrapperBottomSeparatorBlocks>
              <S.BottomSeparator />
              <Typography italic variant='content2' customClassName='requiredFieldsText'>
                *Campos de preenchimento obrigatório
              </Typography>
            </S.WrapperBottomSeparatorBlocks>
            
            {
              error &&
              <Row margin='2rem 0 1.5rem'>
                <Toast icon="Info" message={error} variant="error" />
              </Row>
            }
            <Row margin='2.6rem 0'>
              <Column colWidth={6}>
                <Button
                  id="submit"
                  onButtonClick={() => onSubmit(submitTypes.PUBLISH)}
                  disabled={!canSave}
                  text="PUBLICAR PROFISSIONAL"
                  type="submit"
                  loading={loadingSubmit === submitTypes.PUBLISH}
                />
              </Column>
              <Column colWidth={6}>
                <Button
                  id="submit-schedule"
                  text="CADASTRO INCOMPLETO"
                  state="reverse"
                  onButtonClick={() => onSubmit(submitTypes.SAVE)}
                  disabled={!canSave}
                  loading={loadingSubmit === submitTypes.SAVE}
                />
              </Column>
            </Row>
          </Row>
      }
    </Container>
  )
}