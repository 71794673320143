import {
  EMAIL_REC_SUCESS,
  EMAIL_REC_REQUEST,
  EMAIL_REC_FAILURE,
} from './_actionTypes';

import { asyncError } from './';
import { apiEndpoints } from '../../settings/_apiSettings';
import axios from '../../settings/axios';

export const emailRecoveryFail = (failurePayload) => {
  return {
    type: EMAIL_REC_FAILURE,
    payload: failurePayload,
  };
};

export const emailRecoveryRequest = () => {
  return { type: EMAIL_REC_REQUEST };
};

export const emailRecoverySucess = (successPayload) => {
  return {
    type: EMAIL_REC_SUCESS,
    payload: successPayload,
  };
};

export const recoverEmail = (recoveryData) => async dispatch => {
  let fetchUrl = `${apiEndpoints.ACCOUNTS}${recoveryData}/email/`;

  dispatch(emailRecoveryRequest());

  try {
    const response = await axios.post(fetchUrl);
    if (response.status === "OK") {
      const successPayload = {};
      return dispatch(emailRecoverySucess(successPayload));
    }
  } catch (err) {
    let payload = {};

    dispatch(asyncError(err));

    if (err.response.data.status === "ZERO_RESULTS") {
      payload = {
        sysError: [ {
          code: '404',
          message: 'Este número de telefone não está cadastrado',
        } ],
      };
    }
    
    dispatch(emailRecoveryFail(payload));
  }
};