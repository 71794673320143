import React from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";
import { store } from "../../index";
import { Provider } from "react-redux";
import * as actions from "../../redux/actions";
import ModalManager from "../ModalManager";

const ModalPortal = props => {
  return ReactDOM.createPortal(
    <Provider store={store}>
      {props.showModal ? (
        <ModalManager modalType={props.modalType} modalData={props.modalData} />
      ) : (
        <div />
      )}
    </Provider>,
    document.querySelector("#modal")
  );
};

const mapPropsToState = state => {
  return {
    showModal: state.modal.showModal,
    modalType: state.modal.modalType,
    modalData: state.modal.modalData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch(actions.hideModal());
    }
  };
};
export default connect(
  mapPropsToState,
  mapDispatchToProps
)(ModalPortal);
