const Content = () => {
    return {
        __html: `
            <h3>Introdução</h3>
            A Telavita Serviços de Saúde Ltda., com sede ao Largo do Arouche, 337, CEP 01219-011, São Paulo, SP, e-mail: <a href="mailto:contato@telavita.com.br">contato@telavita.com.br</a> devidamente inscrita no CNPJ/MF sob o nº. 27.460.001/0001-75, apresenta sua POLÍTICA DE PRIVACIDADE que consiste em melhores práticas de gestão, visando dar maior transparência à sua forma de atuação, respeitando assim os direitos dos stakeholders. 
            <br><br>
            As informações pessoais significam dados relacionados a um indivíduo e que identificam esse indivíduo, direta ou indiretamente, como seu nome, número de documento e/ou suas informações de contato.
            <br><br>
            Declara a Telavita que adota o Princípio Geral da Boa-Fé Contratual, previsto no Art. 422, da Lei 10.406/2002, Código Civil Brasileiro, que norteia a sua conduta de probidade em todos os momentos.
            <br><br>
            Declara a Telavita que adota por princípio a privacidade, desde a concepção dos seus sistemas até a atualização e manutenção, restringindo o acesso de informações sensíveis evitando que seus dados caiam em mãos erradas.
            <br><br>
            Declara a Telavita que obrigam-se a cumprir, no tocante à atividade exercida, bem como aos seus serviços, todas as determinações legais, sejam fiscais, previdenciárias e trabalhistas, inclusive relativamente à defesa e proteção do meio ambiente, declarando ainda o cumprimento das disposições contidas na Constituição Federal, art. 7º, XXXIII, que proíbe, expressamente, o trabalho de menores de 18 anos em atividades noturnas, perigosas ou insalubres e de menores de 16 anos em qualquer trabalho, exceto na condição de aprendiz, a partir de 14 anos.
            <br><br>
            Declara a Telavita que atua (i) buscando o desenvolvimento sustentável da sociedade como um todo; (ii) proporcionando aos seus colaboradores e/ou pessoas que prestam serviços indiretamente condições adequadas ao desenvolvimento físico, psíquico, moral e social; (iii) não praticando atos de discriminação de qualquer gênero, por motivo de sexo, origem, raça, religião, condição física, idade, situação familiar, dentre outras; (iv) é possuidora de todas as certificações necessárias para o setor; (v) respeitando a legislação do meio ambiente; (vi) não utilizando de insumos objeto de exploração ilegal de recursos naturais; (vii) evitando e inibindo o assédio moral e sexual; (viii) compartilhando a responsabilidade social. 
            <br><br>
            Declara a Telavita que compreende e respeita as disposições e compromete-se a observar todas as leis aplicáveis relacionadas a corrupção e suborno, incluindo, mas sem limitação, as Leis Federais nº 8.429/92, nº 8.666/93, nº 12.529/11 e nº 12.846/13, e suas atualizações posteriores e ainda as disposições do Ato das Práticas de Corrupção Estrangeira – Título 15, Seção 78dd-1 do Código dos Estado Unidos da América (“FCPA”) e do The Bribery Act 2010 (“UKBA”) do Reino Unido, e da Lei 12.846 de 1º de agosto de 2013.
            <br><br>
            Declara a Telavita que compreende e respeita as disposições do Marco Civil da Internet, Lei 12.965/14, e das leis General Data Protection Regulation (“GDPR”), da União Europeia, de maio de 2018 e da Consumer Privacy Act of 2018 dos Estados Unidos da América, além das orientações preconizadas pela Lei Geral de Proteção de Dados, Lei nº 13.709/2018, aplicando aquilo que se faz necessário na sua plataforma e nas suas ferramentas.
            <br><br>
            Orientamos aos nossos stakeholders que, leiam atentamente a Política de Privacidade, visto que a utilização dos serviços disponibilizados pela Telavita implica na expressa aceitação quanto aos termos e condições desta Política de Privacidade. Outrossim, recomendamos aqueles que não concordem com a Política de Privacidade, a não utilizarem os serviços da Telavita. Visto que a sua não aceitação por parte do cliente/paciente/usuário ou ainda, a não disponibilização das informações solicitadas, pode impedir a prestação de tais serviços.
            <br><br>
            Desta forma a Telavita se compromete a informar a seus clientes/pacientes/usuários sobre os dados coletados e utilizados para proporcionar uma melhor experiência de navegação e compra online. 
            <br><br>
            Declara a Telavita que as informações coletadas serão armazenadas utilizando-se rígidos padrões técnicos, incluindo os de sigilo e integridade, bem como controles de acesso lógico, objetivando a restrição de acesso a pessoas não devidamente credenciadas para tal, e observando-se sempre os mais elevados princípios éticos e legais.
            <br><br>
            
            <h3>Divulgação, coleta, tratamento e uso e de informações cadastrais</h3>
            Esclarece a Telavita que seus serviços exigem que o cliente tenha um cadastro com uma conta de usuário. Ao se cadastrar, coletamos seu nome, e-mail, endereço de entrega e cobrança, telefone de contato, CPF, data de nascimento, sexo e dados biométricos faciais. Configurações de uso pessoal informadas durante o seu uso do site são arquivadas. Com isso, é garantido que encontraremos os dados e configurações pessoais de cada um durante uma nova visita. 
            <br><br>
            Assim o cliente/paciente/usuário autoriza a Telavita a utilizar seus dados pessoais para fins de informação, propaganda, pesquisa de mercado, análises de melhoramento da Telavita, para as necessidades de aperfeiçoamento da qualidade do serviço prestado e para a autenticação biométrica do usuário no momento de acesso aos serviços do site. Também para fins de aperfeiçoamento das nossas ferramentas, e para garantir uma navegabilidade melhorada e uma interface mais amigável, mantemos em sua base de dados todas as informações relativas aos acessos ao site, incluindo, mas não se limitando ao endereço Internet Protocol (IP), tipo de navegador, às páginas acessadas, aos horários e datas de acesso, e o dispositivo de acesso utilizado, sempre nos termos da legislação vigente. Tais registros poderão ser utilizados em investigações internas, públicas ou privadas, para averiguação de práticas que possam gerar quaisquer prejuízos à Telavita, inclusive, mas não se limitando, à prática de crimes virtuais. 
            <br><br>
            A Telavita esclarece que a fim de confirmar a veracidade dos dados pessoais informados pelo cliente/paciente/usuário, incluindo os dados relacionados ao pagamento das compras feitas pela plataforma, poderá acessar as bases de dados públicas ou privadas e que em caso de inconsistência informará ao cliente/paciente/usuário para que promova sua correção.
            <br><br>
            Para melhorar a relevância da comunicação, a Telavita poderá encaminhar e-mails para o e-mail cadastrado do cliente/paciente/usuário, ficando este responsável por solicitar a exclusão de seu envio automático, limitando o seu envio apenas para mensagens imprescindíveis.  Assim, o cliente/paciente/usuário declara expressamente a sua permissão para receber notificações de emails da Telavita. 
            <br><br>
            A Telavita esclarece que eventualmente pode receber informações do cliente/paciente/usuário de outras fontes, como cadastros de parceiros, para somá-los à sua base de dados, contudo destacamos que em hipótese alguma nossos dados serão compartilhados com terceiros, sem a prévia autorização do cliente/paciente/usuário.
            <br><br>
            A Telavita declara que utilizará cookies para proporcionar uma melhor experiência em nosso Site e viabilizar recursos personalizados como recomendações de produtos, publicidades e informações adicionais de itens do interesse do usuário.
            <br><br>
            Usaremos essas informações para aprimorar continuamente nossos Sites e para atender melhor à necessidade de nosso cliente/paciente/usuário. Por exemplo, usamos essas informações para saber quais navegadores as pessoas mais comumente usam, quais páginas são mais visitadas e qual funcionalidade é mais usada. Algumas das informações que coletamos de Visitantes, como Endereço IP, podem ser consideradas informações de identificação pessoal ("Informações pessoais"). Além disso, em nosso Site, há momentos em que os Visitantes podem enviar voluntariamente Informações pessoais, como nome, número de telefone e/ou endereço de e-mail, para obter mais informações da Telavita. Podemos remover identificadores pessoais de suas Informações pessoais e mantê-los e usá-los de forma não identificada ("Informações não identificáveis"). Informações não identificáveis e informações pessoais são coletivamente referidas em toda esta Política de Privacidade como "Informações".
            <br><br>
            Seus dados clínicos podem ser compartilhados com seu profissional de saúde, o responsável pela medicina do trabalho e/ou a operadora de saúde, quando/caso existe um risco à sua saúde ou à saúde de outros. Toda esta troca de informações respeitará os limites legais e éticos
            <br><br>
            Nosso cliente/paciente/usuário pode retirar seu consentimento para usos e divulgações de Informações pessoais, conforme descrito nesta Política de Privacidade, assim como solicitar a alteração ou exclusão de nossos bancos de dados a qualquer tempo. Para isso deve nosso cliente/paciente/usuário enviar sua solicitação por escrito à Telavita através do canal de comunicação <a href="mailto:contato@telavita.com.br">contato@telavita.com.br</a>. Esclarece a Telavita que a retirada do consentimento não invalida o consentimento para qualquer coleta, uso ou divulgação de Informações pessoais com as quais se consentiu antes que o consentimento fosse retirado. Assim, a retirada do consentimento ou a recusa de um novo consentimento, faz com que a capacidade da Telavita de oferecer serviços para este cliente/paciente/usuário seja limitada.
            <br><br>
            
            <h3>Alterações da Política de Privacidade</h3>
            A Telavita se reserva a alterar sua Política de Privacidade sempre que possível visando implementar melhorias e dar mais transparência ao seu cliente/paciente/usuário. 
            <br><br>
            
            <h3>Fale Conosco</h3>
            Perguntas ou comentários sobre esta Política devem ser enviados para o representante de privacidade da Telavita por correio ou por meios eletrônicos, da seguinte forma:
            <br><br>
            
            Telavita Serviços de Saúde Ltda.
            Largo do Arouche, 337, 01219-011
            <a href="mailto:contato@telavita.com.br">contato@telavita.com.br</a>
        `
    };
};

export { Content }