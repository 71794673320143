import { useCallback, useEffect, useState } from 'react'
import {
  SCHEDULED,
  RETURNED,
  PROFESSIONAL_NO_SHOW,
  ATTENDED,
  PATIENT_NO_SHOW,
} from 'settings/_scheduleStatusSettings'

import metricsService from 'services/metrics'
import { DaysViewData, IAvailableAvailabilities, IDaysViewData, IUseDataParams } from '../types'
import { format, formatISO } from 'date-fns'

const statusList = [
  SCHEDULED,
  ATTENDED,
  PATIENT_NO_SHOW,
  PROFESSIONAL_NO_SHOW,
  RETURNED,
]

interface ISchedule {
  date: string
  partial: number
  percentage: number
}

interface ISchedulesReceived {
  dates: ISchedule[]
  total: number
}

function sortSlotsByHour(data: DaysViewData[]) {
  return data.sort((a, b) => {
    if (a.date < b.date) {
      return -1
    }
    if (a.date > b.date) {
      return 1
    }
    return 0
  })
}

function formatDateToISO(date: string) {
  const dateFormatted = new Date(date)
  const setDateHours = dateFormatted.setHours(24, 0, 0, 0)

  return formatISO(new Date(setDateHours))
}

function formatData(slotsData: IAvailableAvailabilities[], schedulesReceivedData: ISchedulesReceived) {
  const allSlots = slotsData.map(slot => {
    const dateFromSlot = formatDateToISO(slot.date)
    const countFromSlot = slot.availabilities_count
      
    return {
      date: dateFromSlot,
      slots: countFromSlot,
      schedules: schedulesReceivedData.dates
        .find(schedule => formatDateToISO(schedule.date) === dateFromSlot)?.partial
    }
  })
  return sortSlotsByHour(allSlots)
}

export const useDaysViewData = ({selectedDate}: IUseDataParams): IDaysViewData => {
  const [daysViewData, setDaysViewData] = useState([])
  const [loadingDayGraphic, setLoadingDayGraphic] = useState(false)

  const [availabilitySlotsTotal, setAvailabilitySlotsTotal] = useState(0)
  const [schedulesReceivedTotal, setSchedulesReceivedTotal] = useState(0)

  const fetchScheduleData = useCallback(async () => {
    const slotsAvailablesResponse = await metricsService.getScheduleAvailabilities({
      initialDate: format(new Date(selectedDate.initialDate), 'yyyy-MM-dd'),
      endDate: format(new Date(selectedDate.endDate), 'yyyy-MM-dd'),
      type: 'date'
    })

    const scheduleReceivedResponse = await metricsService.getListSchedules({
      initialDate: formatISO(new Date(selectedDate.initialDate)),
      endDate: formatISO(new Date(selectedDate.endDate)),
      groupBy: 'date',
      statusList
    })

    return Promise.all([slotsAvailablesResponse, scheduleReceivedResponse])
  }, [selectedDate])

  useEffect(() => {
    async function getSchedules(){
      try {
        setLoadingDayGraphic(true)
        const [slotsAvailablesResponse, scheduleReceivedResponse] = await fetchScheduleData()

        const formattedSlotsData: DaysViewData[] = formatData(slotsAvailablesResponse, scheduleReceivedResponse.data)
        const formattedSchedulesReceivedData = scheduleReceivedResponse.data as ISchedulesReceived
        
        setDaysViewData(formattedSlotsData)
        setAvailabilitySlotsTotal(formattedSlotsData.reduce((acc, curr) => acc + curr.slots, 0))

        setSchedulesReceivedTotal(formattedSchedulesReceivedData.total)

      } catch(error) {
        setLoadingDayGraphic(false)
      } finally {
        setLoadingDayGraphic(false)
      }
    }
    void getSchedules()
  }, [fetchScheduleData])

  return {
    availabilitySlotsTotal,
    daysViewData,
    loadingDayGraphic,
    schedulesReceivedTotal
  }
}