import React from "react";

const Clip = ({
  fill = "#c1c1c1",
  height = "24px",
  viewBox = "0 0 24 24",
  width = "24px",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path
      d="M16.5,6V17.5a4,4,0,0,1-8,0V5a2.5,2.5,0,0,1,5,0V15.5a1,1,0,0,1-2,0V6H10v9.5a2.5,2.5,0,0,0,5,0V5A4,4,0,0,0,7,5V17.5a5.5,5.5,0,0,0,11,0V6Z" fill={fill}
    />
    <path d="M0,0H24V24H0Z" fill="none" />
  </svg>
);

export default Clip;