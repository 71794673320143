import React from 'react'
import { FloatButton, Typography } from '@telavita-core/react-design-kit'

import { IPatient } from '../../types'

import { TableAction, TableData } from './styles'

interface IPatientsTableRowProps {
  patientData: IPatient
  onClickPatient: (patient: IPatient) => void
}

function validatePatientReservation(patient: IPatient): string {
  if (!patient.allowsReservation) return 'Sem direito'

  if (patient.hasReservation) return 'Sim'

  return 'Não'
}

export const PatientsTableRow = ({
  patientData,
  onClickPatient,
}: IPatientsTableRowProps): JSX.Element => {
  return (
    <tr>
      <TableData>
        <Typography
          variant='content2'
          center
        >
          {patientData.total}
        </Typography>
      </TableData>
      <TableData>
        <Typography
          variant='content3'
          weight='bolder'
          inlineStyles={{
            paddingBottom: '3px',
          }}
        >
          {patientData.name}
        </Typography>
        <Typography
          variant='content3'
        >
          {patientData.groupPlan}
        </Typography>
      </TableData>
      <TableData>
        <Typography
          variant='content3'
          center
          italic={patientData.hasReservation === null}
        >
          {validatePatientReservation(patientData)}
        </Typography>
      </TableData>
      <TableData>
        <TableAction>
          <FloatButton
            actions={[
              {
                label: 'Ver detalhes do paciente',
                icon: true,
                onClick: () => onClickPatient(patientData)
              }
            ]}
          />
        </TableAction>
      </TableData>
    </tr>
  )
}