import { FILTER_STATUS, CLEAR_FILTERS } from "./_actionTypes";
import axios from "../../settings/axios";
import { apiEndpoints } from "../../settings/_apiSettings";
import { asyncError } from "./asyncActions";

export const getFilterStatus = ({ group_code, filter }) => async dispatch => {
  const params = {
    ...(group_code && { group_code }),
    ...(filter && { filter })
  };
  try {
    const response = await axios.get(apiEndpoints.STATUS, { params });

    let payload;

    switch (response.status) {
      case "OK":
        payload = { filterStatus: response.data.status };

        return dispatch({
          type: FILTER_STATUS,
          payload: payload
        });

      case "ZERO_RESULTS":
        payload = { filterStatus: [] };

        return dispatch({
          type: FILTER_STATUS,
          payload: payload
        });

      default:
        break;
    }
  } catch (err) {
    let payload = { filterStatus: [] };

    console.log(err);
    dispatch(asyncError(err));

    return dispatch({
      type: FILTER_STATUS,
      payload: payload
    });
  }
};

/** Clear staged filters*/
export const clearFilters = () => {
  return { type: CLEAR_FILTERS };
};
