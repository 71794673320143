import React, { useState, ReactNode } from 'react'

import { Popover, OptionItem, Typography } from '@telavita-core/react-design-kit'

import ArrowDownIcon from 'components/CoreIcons/Icons/ArrowDown'
import ArrowUpIcon from 'components/CoreIcons/Icons/ArrowUp'
import { ReactComponent as CheckIcon } from 'static/svg/check-nobg.svg'

import * as S from './styles'

interface PopoverMenu {
  label: string
  options: Array<{
    id: number | string
    title: string
    subTitle?: string
    onClick: () => void
  }>
  selectedOption: string | number
  customIcon: ReactNode
  buttonText: string
}

const PopoverMenu = ({
  label,
  options,
  customIcon,
  selectedOption,
  buttonText
}: PopoverMenu): JSX.Element => {
  const [showPopover, setShowPopover] = useState(false)

  const Content = () => (
    <S.ContentWrapper>
      <S.Header>
        <S.Label>   
          {label}
        </S.Label>
      </S.Header>
      <S.OptionsContainer>
        {options.map((mOption, index) => (
          <OptionItem
            key={index}
            handleClick={() => {
              mOption.onClick()
              setShowPopover(false)
            }}
            size='large'
          >
            <S.OptionContent>
              <S.TextWrapper>
                <Typography
                  variant='content2'
                >
                  {mOption.title}
                </Typography>
                {mOption?.subTitle && (
                  <Typography variant='content3' inlineStyles={{ color: '#898989' }}>
                    {mOption.subTitle}
                  </Typography>
                )}
              </S.TextWrapper>
              {selectedOption === mOption.id && <CheckIcon />}
            </S.OptionContent>
          </OptionItem>
        ))}
      </S.OptionsContainer>
    </S.ContentWrapper>
  )

  return (
    <Popover
      content={<Content />}
      onClickOutside={() => setShowPopover(false)}
      visible={showPopover}
    >
      <S.Button onClick={() => setShowPopover(prevState => !prevState)}>
        <S.ButtonText>
          {buttonText}
        </S.ButtonText>
        {customIcon ? customIcon : (
          <>
          {showPopover
            ? (
              <ArrowUpIcon
                fill='#4b4b4b'
                height="24px"
                width="24px"
                viewBox="0 0 24 24"
              />
            )
            : (
              <ArrowDownIcon 
                fill='#4b4b4b'
                height="24px"
                width="24px"
                viewBox="0 0 24 24"
              />
            )
          }
          </>
        )}
      </S.Button>
    </Popover>
  )
}

export default PopoverMenu