import React from 'react'

import './PlanDetail.sass'

import { Typography } from '@telavita-core/react-design-kit'
import { CoreIcons } from 'components'

const PlanDetail = ({
  toggleDetail,
  displayDetail,
  lastPlanName = '',
  lastCardNumber = null,
  reason,
  moreInfo,
  headerResume,
  reasonTitle,
}) => {
  return (
    <div className='PlanDetail'>
      <div className='PlanDetail__header'>
        <div className='PlanDetail__header__resume'>
          <Typography
            variant='content3'
          >
            {headerResume}            
          </Typography>
        </div>
        <div className='PlanDetail__header__button'>
          <div
            className='PlanDetail__header__button__wrapper'
            onClick={(id) => toggleDetail(id)}
          >
            <CoreIcons
              name={displayDetail ? 'ExpandMore' : 'ExpandLess'}
              fill='#1F35B5'
              height='14px'
              width='14px'
              viewBox='9 6 20 20'
            />
            <Typography
              variant='content2'
              weight='bolder'
            >
              Detalhes
            </Typography>
          </div>
        </div>
      </div>
      <div className={`PlanDetail__details--${displayDetail ? 'open' : 'close'}`}>
        {lastCardNumber && (
          <div className='PlanDetail__details__item'>
            <Typography
              variant='content3'
              weight='bolder'
            >
            Plano e carteirinha anteriores
            </Typography>
            <Typography
              variant='content3'
            >
              {lastPlanName}
            </Typography>
            <Typography
              variant='content3'
            >
              {lastCardNumber ? lastCardNumber : 'Não possui carteirinha'}
            </Typography>
          </div>
        )}
        <div className='PlanDetail__details__item'>
          <Typography
            variant='content3'
            weight='bolder'
          >
            {reasonTitle}
          </Typography>
          <Typography
            variant='content3'
          >
            {reason}
          </Typography>
        </div>
        <div className='PlanDetail__details__item'>
          <Typography
            variant='content3'
            weight='bolder'
          >
            Detalhes
          </Typography>
          <Typography
            variant='content3'
          >
            {moreInfo ? moreInfo : 'Sem detalhes'}
          </Typography>
        </div>
      </div>
    </div>
  )
}

export { PlanDetail }