import { 
  GET_MEDICAL_REQUEST, 
  CLEAR_MEDICAL_REQUEST,
  CHANGE_MEDICAL_REQUEST,
  SEND_PROCEDURE_REQUEST,
  CREATE_MEDICAL_REQUEST,
  GET_DISAPPROVE_DETAILS,
} from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

export const initialState = {
  errorMessage: null,
  procedure: {
    hasProcedure: false,
    start_date: null,
    plan_password: null,
  },
  pagination: {},
  success: null,
  validities: [],
};

const getMedicalRequest = (state, { payload }) => {
  return updateState(state, { 
    validities: [
      ...state.validities,
      ...payload.validities,
    ],
    pagination: payload.pagination,
  });
};

const getDisapproveDetails = (state, { payload }) => {
  return updateState(state, { 
    details: payload.details,
    detailsError: payload.detailsError,
  });
};

const changeMedicalRequest = (state, { payload }) => {
  return updateState(state, { success: payload.success });
};

const createMedicalRequest = (state, { payload }) => {
  return updateState(state, { createSuccess: payload.createSuccess, createError: payload.createError, createFeedbackMessage: payload.createFeedbackMessage });
};

const clearMedicalRequest = (state) => {
  return updateState(state, initialState);
};

const sendProcedureRequest = (state, { payload }) => {
  return updateState(state, {
    errorMessage: payload.errorMessage,
    procedure: payload.procedure,
  });
};

const medicalRequestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEDICAL_REQUEST: return getMedicalRequest(state, action);
    case CLEAR_MEDICAL_REQUEST: return clearMedicalRequest(state);
    case CHANGE_MEDICAL_REQUEST: return changeMedicalRequest(state, action);
    case SEND_PROCEDURE_REQUEST: return sendProcedureRequest(state, action);
    case CREATE_MEDICAL_REQUEST: return createMedicalRequest(state, action);
    case GET_DISAPPROVE_DETAILS: return getDisapproveDetails(state, action);
    default: return state;
  }
};

export default medicalRequestReducer;