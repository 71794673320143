import React, { Component } from 'react'
import PropTypes from 'prop-types'

import FloatmenuCard from '../FloatmenuCard'
import Row from '../Row'
import { Icon } from '@telavita-core/react-design-kit'

class ToggleFloatButton extends Component {
  state = { isMenuOpen: false }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  onClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ isMenuOpen: false })
    }
  }

  onMenuOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  render() {
    const {
      menuList,
      userId
    } = this.props

    let { isMenuOpen } = this.state


    return (
      <div id={`person-${userId}`}
        className={isMenuOpen ? 'profile-list-items--isOpen' : 'profile-list-items'}>
        <Row justify="center" align="center">
          <div className="profile-list-items--menu"
            id={`profile-list-items--menu-${userId}`}
            onClick={this.onMenuOpen}
            ref={node => this.wrapperRef = node}>
            <Icon name='OptionsHorizontal' />
            {
              isMenuOpen &&
              <FloatmenuCard
                className="profile-list-items--float-menu"
                itemList={menuList}
              />
            }
          </div>
        </Row>
      </div>
    )
  }
}

ToggleFloatButton.propTypes = {
  isMenuOpen: PropTypes.any,
  onMenuOpen: PropTypes.any,
  userId: PropTypes.any,
  viewBox: PropTypes.string,
  fill: PropTypes.string,
}

export default ToggleFloatButton