import { GET_BLOCKS  } from '../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

export const initialState = { blocks: [] };

const getBlocks = (state, { payload }) => {
  return updateState(state, { blocks: payload.blocks });
};

const blocksReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BLOCKS: return getBlocks(state, action);
    default: return state;
  }
};

export default blocksReducer;