export const removeDateMask = (date) => {
  return date
    .replace(/_/g, "")
    .replace(/\//g, "");
};

export const removeHourMask = (date) => {
  return date
    .replace(/_/g, "")
    .replace(/:/g, "")
};