import React from 'react'
import { Tab, Tabs, Typography } from '@telavita-core/react-design-kit'

import { Container, TabsContent, TabsWrapper } from './styles'
import { DayGraphic } from '../DayGraphic'
import { HourGraphic } from '../HourGraphic'
import { DaysViewData, HoursViewData } from '../../types'

interface IGraphicsSection {
  daysViewData: DaysViewData[]
  hoursViewData: HoursViewData[]
  activeTab: number
  handleChangeTab: (event: React.ChangeEvent, value: number) => void
}

export const GraphicsSection = ({
  daysViewData,
  hoursViewData,
  activeTab,
  handleChangeTab,
}: IGraphicsSection): JSX.Element => {
  return (
    <Container>
      <TabsWrapper>
        <Typography italic variant='content2'>
          Visualizar por:
        </Typography>

        <TabsContent>
          <Tabs selectedTab={activeTab} onChange={handleChangeTab}>
            <Tab label="Dias" id={0} />
            <Tab label="Horas" id={1} />
          </Tabs>
        </TabsContent>
      </TabsWrapper>

      {
        activeTab === 0
          ? (<DayGraphic data={daysViewData} />)
          : (<HourGraphic data={hoursViewData} />)
      }
    </Container>
  )
}