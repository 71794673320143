import React from "react";

const Location = ({
  fill,
  height,
  style,
  viewBox = "-8 -8 40 40",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
      <g data-name="Icon / Add / Filled">
        <path fill="none" d="M0 0h24v24H0z"/>
        <g data-name="Agrupar 227">
          <path data-name="Caminho 2171" d="M0 0h24v24H0z" fill="none"/>
          <g data-name="Agrupar 672" fill={fill}>
            <path data-name="Caminho 2341" d="M12 2a7 7 0 0 0-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 0 0-7-7zM7 9a5 5 0 0 1 10 0c0 2.88-2.88 7.19-5 9.88C9.92 16.21 7 11.85 7 9z"/>
            <circle data-name="Elipse 157" cx="2.5" cy="2.5" r="2.5" transform="translate(9.5 6.5)"/>
          </g>
        </g>
      </g>
    </svg>
);

export default Location;