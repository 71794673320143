import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { formatISO } from 'date-fns'

import { Typography } from '@telavita-core/react-design-kit'

import { postUnavailabilityRequest } from 'redux/modules/unavailability/requests'
import { updateErrorStatus } from 'redux/modules/unavailability/actions'

import './ProfUnavailabilityModal.sass'

import { HourFilter, TimestampTag, CoreIcons, CalendarInput } from 'components'
import ModalGeneric from '../ModalGeneric'

const mappedErrorMessages = {
  'there_is_an_event_at_this_time': 'Há consulta(s) agendada(s) neste período. Cancele-a(s) antes de criar este evento.',
  'there_is_a_reservation_at_this_time': <>Há reserva(s) neste período. Fale com o paciente e remarque-a(s) na sessão <br /> “Reservas”, antes de criar este evento.</>
}

const ProfUnavailabilityModal = ({ userData }) => {
  const dispatch = useDispatch()
  const username = userData.username

  const unavailability = useSelector((state) => state.unavailability)
  const loading = unavailability.loading
  const error = unavailability.error
  const errorMessage = mappedErrorMessages[unavailability.errorMessage]

  const [date, setDate] = useState(new Date())

  const [startHour, setStartHour] = useState('')
  const [finalHour, setFinalHour] = useState('')
  const [timeError, handleTimeError] = useState(false)

  async function submitUnavailability () {
    const startDate = new Date(date)
    startDate.setHours(startHour, 0, 0, 0)

    const endDate = new Date(date)
    endDate.setHours(finalHour !== '24' ? finalHour : 0, 0, 0, 0)

    if(finalHour === '00' || finalHour === '24') {
      endDate.setDate(endDate.getDate() + 1)
    }

    dispatch(postUnavailabilityRequest(username, {
      title: 'Indisponibilidade de agenda',
      start_date: formatISO(startDate),
      end_date: formatISO(endDate),
      event_type: 'AGD21002'
    }))
  }

  function validateTimeValue (value) {
    const validHour = /^([0-1]?[0-9]|2[0-4])$/.test(value)
    if (validHour) return true
    return false
  }

  function validateTime () {
    if (startHour.length > 1 && finalHour.length > 1) {
      if (validateTimeValue(startHour) && validateTimeValue(finalHour) && (finalHour > startHour || finalHour === '00')) return handleTimeError(false)
      return handleTimeError(true)
    }
  }

  useEffect(() => {
    validateTime()
    dispatch(updateErrorStatus())

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startHour, finalHour, date])

  return (
    <ModalGeneric
      modalTitle='Indisponibilidade'
      hasButton
      btnLoading={loading}
      btnDisabled={error || timeError || !(startHour.length > 1) || !(finalHour.length > 1) }
      btnOnClick={() => submitUnavailability()}
      btnText='ADICIONAR INDISPONIBILIDADE'
      btnId='unavailability-btn'
    >
      <div className='ProfUnavailabilityModal'>
        <div className='ProfUnavailabilityModal__doubleInput'>
          <Typography customClassName='ProfUnavailabilityModal__text' variant='content2'>Defina uma data</Typography>
          <CalendarInput
            daysUnavailable={[{ before: new Date() }]}
            handleDayChange={(day) => setDate(day)}
            selectedDay={new Date()}
          />
        </div>

        <div className='ProfUnavailabilityModal__doubleInput ProfUnavailabilityModal__doubleInput--shortSpaced'>
          <Typography customClassName='ProfUnavailabilityModal__text' variant='content2'>Defina um horário</Typography>
          <HourFilter
            start={{
              id: 'unavailability-start-hour',
              placeholder: '00',
              defaultValue: startHour,
              onChange: (value) => {
                setStartHour(value)
                if (value.length === 2) document.querySelector('#unavailability-final-hour').focus()
              }
            }}
            final={{
              id: 'unavailability-final-hour',
              placeholder: '00',
              defaultValue: finalHour,
              onChange: (value) => setFinalHour(value)
            }}
            error={{
              condition: timeError || error,
              message: error ? '' : 'Horário inválido'
            }} />
        </div>

        {error &&
          <div className='ProfUnavailabilityModal__toast'>
            <CoreIcons name ='AlertIcon' width='22px' height='22px' fill='#ff4949' />
            <Typography
              customClassName='ProfUnavailabilityModal__text ProfUnavailabilityModal__text--toast'
              variant='content2'
              color='danger'
            >
              {errorMessage}
            </Typography>
          </div>
        }

        <TimestampTag />
      </div>
    </ModalGeneric>
  )
}

export { ProfUnavailabilityModal }
