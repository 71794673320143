import { connect } from 'react-redux';
import PropTypes from 'prop-types';

function PermissionValidator({ children, permission, userPermissions }) {
  return (
    userPermissions.includes(permission) ? (
      children
    ) : null  
  );
}

PermissionValidator.propTypes = { 
  children: PropTypes.any,
  permission: PropTypes.string,
  userPermissions: PropTypes.array,
};

const mapStateToProps = (state) => ({ userPermissions: state.auth.profilePermissions  });

export default connect(mapStateToProps)(PermissionValidator);

