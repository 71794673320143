import React from 'react'
import { Typography, FloatButton } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface PlanCardProps {
  planGroupName: string
  planName: string
  planLogo: string
  cardNumber: string
  isPlanActive: boolean
  actions: Array<{
    label: string
    icon: boolean | string
    onClick: () => void
  }>
}

const PlanCard = ({
  planGroupName,
  planName,
  planLogo,
  cardNumber,
  isPlanActive,
  actions
}: PlanCardProps): JSX.Element => {

  return (
    <S.Container>
      <S.PlanContentWrapper>
        <S.PlanImage 
          src={planLogo}
          alt={planGroupName}
        />
        <S.PlanInfoWrapper>
          <Typography variant='content1' weight='bolder'>{planGroupName}</Typography>
          <Typography variant='content2'>{planName}</Typography>
          {cardNumber && <Typography variant='content2'>Carteirinha {cardNumber}</Typography>}
          <S.PlanStatus isActive={isPlanActive}>
            {isPlanActive ? 'Convênio ativo' : 'Convênio inativo'}
          </S.PlanStatus>
        </S.PlanInfoWrapper>
      </S.PlanContentWrapper>
      {isPlanActive && (
        <FloatButton 
          actions={actions}
        />
      )}
    </S.Container>
  )
}

export default PlanCard