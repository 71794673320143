import { CLEAN_PRODUCTS, GET_PRODUCTS } from './_actionTypes';

import { apiEndpoints } from './../../settings/_apiSettings';
import axios from '../../settings/axios';
import { asyncError } from './';

/* GET PRODUCTS */
export const getProducts = (profileRole) => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.PRODUCTS,
      { params: { "profile_role": profileRole } });

    const products = response.data.products;

    dispatch({
      type: GET_PRODUCTS,
      products,
    });
  } catch (err) {
    dispatch(asyncError(err));
  }
};

/** CLEAN PRODUCTS */
export const cleanProducts = () => dispatch => {
  return dispatch({ type: CLEAN_PRODUCTS });
};