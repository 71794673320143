import { FloatButton, Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

interface PlanStatusProps {
  isActive: boolean
}

export const Container = styled.div`
  box-shadow: 0px 2px 6px #00000029;
  border-radius: 10px;  
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const PlanContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 20px;
`

export const PlanImage = styled.img`
  border: 1px solid #DEDEDE;
  border-radius: 5px;
  align-self: flex-start;
  width: 116px;
  height: 62px;
`

export const PlanInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const PlanStatus = styled(Typography)<PlanStatusProps>`
  color: ${({ isActive }) => isActive ? '#4AC326' : '#FC4949'};
  font-weight: 500;

  display: flex;
  align-items: center;

  margin-top: 5px;

  &:before {
    content: '';
    display: inline-block;
    height: 14px;
    width: 14px;
    background: ${({ isActive }) => isActive ? '#4AC326' : '#FC4949'};
    border-radius: 50px;
    margin-right: 5px;
  }

` 