export const ScheduleContainerFrequencyOptions = {
  DAILY: '1 days, 0:00:00',
  WEEKLY: '7 days, 0:00:00',
  BIWEEKLY: '14 days, 0:00:00',
  QUARTERLY: '84 days, 0:00:00',
}

export const ScheduleContainerErrors = {
  'Nenhum': null,
  'Paciente não elegível': 400,
  'Paciente possui reserva com outro profissional': 403,
  'Profissional não atende o convênio': 406,
  'Profissional ou solicitação não encontrado': 404,
}