import React from "react";

const ExpandMore = ({
  fill,
  height,
  viewBox = "0 0 35 35",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path d="M24.203 12.519l-6.7 6.689-6.707-6.689-2.059 2.059 8.763 8.763 8.763-8.763z" />
    </g>
  </svg>
);

export default ExpandMore;