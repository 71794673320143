import React from "react";

const Building = ({
  fill="74",
  height="68",
  viewBox="0 0 74 68",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Grupo_1363" data-name="Grupo 1363" transform="translate(-193.254 -483.264)">
      <path id="Caminho_2347" data-name="Caminho 2347" d="M238.518,551.192V496.253L215.755,509.4v41.8Z" transform="translate(0.17 0.071)"
        fill="#9eb8ff"
      />
      <path id="Caminho_2348" data-name="Caminho 2348" d="M215.912,551.192l.106-41.713-22.763-13.143v54.855Z" transform="translate(0 0.072)"
        fill="#5076de"
      />
      <path id="Caminho_2248" data-name="Caminho 2248" d="M215.877,509.466l22.763-13.143-22.623-13.06-22.763,13.143Z" fill="#e0ebff"/>
      <path id="Caminho_2249" data-name="Caminho 2249" d="M215.794,506.681l18.512-10.688-18.4-10.62-18.512,10.688Z" transform="translate(0.023 0.012)"
        fill="#93b6f0"
      />
      <path id="Caminho_2250" data-name="Caminho 2250" d="M215.984,488.182l-16.079,9.284-2.433-1.408,18.512-10.688,18.4,10.621-2.433,1.407Z" transform="translate(0.023 0.012)"
        fill="#6d94d6"
      />
      <path id="Caminho_2251" data-name="Caminho 2251" d="M210.924,498.858l-6.375-.474-4.49-2.594,11.3-6.737,3.664,2.493Z" transform="translate(0.037 0.032)"
        fill="#6d94d6"
      />
      <g id="Grupo_1336" data-name="Grupo 1336" transform="translate(204.607 487.31)">
        <path id="Caminho_2252" data-name="Caminho 2252" d="M210.865,491.792l.04-.326h0l9.854-.513v2.186l-6.415,3.7h0l-3.476,2.011Z" transform="translate(-204.49 -487.268)"
          fill="#cde0ff"
        />
        <path id="Caminho_2253" data-name="Caminho 2253" d="M204.525,493l6.415-3.7h0l3.476-2.011,6.374,3.68-6.414,3.7h0l-3.476,2.011Z" transform="translate(-204.525 -487.288)"
          fill="#aac5f0"
        />
        <path id="Caminho_2254" data-name="Caminho 2254" d="M210.9,498.839v-2.187l-6.374-3.68v2.187Z" transform="translate(-204.525 -487.257)"
          fill="#7ea0d9"
        />
      </g>
      <path id="Caminho_2256" data-name="Caminho 2256" d="M238.518,512.475V496.253L215.755,509.4Z" transform="translate(0.17 0.071)"
        fill="#bad4ff"
      />
      <g id="Grupo_1338" data-name="Grupo 1338" transform="translate(217.401 513.704)">
        <path id="Caminho_2257" data-name="Caminho 2257" d="M233.512,519.458l3.432-1.983v-3.937l-3.432,1.982Z" transform="translate(-217.134 -513.538)"
          fill="#6794e0"
        />
        <path id="Caminho_2258" data-name="Caminho 2258" d="M228.083,522.546l3.432-1.982v-3.939l-3.432,1.982Z" transform="translate(-217.163 -513.521)"
          fill="#6794e0"
        />
        <path id="Caminho_2259" data-name="Caminho 2259" d="M222.653,525.636l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.193 -513.504)"
          fill="#6794e0"
        />
        <path id="Caminho_2260" data-name="Caminho 2260" d="M217.223,528.724l3.432-1.982V522.8l-3.432,1.982Z" transform="translate(-217.223 -513.487)"
          fill="#6794e0"
        />
      </g>
      <g id="Grupo_1339" data-name="Grupo 1339" transform="translate(217.401 502.199)">
        <path id="Caminho_2261" data-name="Caminho 2261" d="M236.944,503.648v7.861l-3.432,1.982v-9.413l3.432-1.982Z" transform="translate(-217.134 -502.096)"
          fill="#6794e0"
        />
        <path id="Caminho_2262" data-name="Caminho 2262" d="M231.514,506.736V514.6l-3.432,1.982v-9.413l3.432-1.982Z" transform="translate(-217.163 -502.079)"
          fill="#6794e0"
        />
        <path id="Caminho_2263" data-name="Caminho 2263" d="M226.085,509.826v7.861l-3.432,1.982v-9.413l3.432-1.982Z" transform="translate(-217.193 -502.062)"
          fill="#6794e0"
        />
        <path id="Caminho_2264" data-name="Caminho 2264" d="M220.655,511.362v9.413l-3.432,1.982v-9.414Z" transform="translate(-217.223 -502.045)"
          fill="#6794e0"
        />
      </g>
      <g id="Grupo_1340" data-name="Grupo 1340" transform="translate(217.401 519.77)">
        <path id="Caminho_2265" data-name="Caminho 2265" d="M233.512,525.491l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.134 -519.571)"
          fill="#6794e0"
        />
        <path id="Caminho_2266" data-name="Caminho 2266" d="M228.083,528.579l3.432-1.982v-3.939l-3.432,1.983Z" transform="translate(-217.163 -519.554)"
          fill="#6794e0"
        />
        <path id="Caminho_2267" data-name="Caminho 2267" d="M222.653,531.669l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.193 -519.537)"
          fill="#6794e0"
        />
        <path id="Caminho_2268" data-name="Caminho 2268" d="M217.223,534.757l3.432-1.981v-3.938l-3.432,1.982Z" transform="translate(-217.223 -519.52)"
          fill="#6794e0"
        />
      </g>
      <g id="Grupo_1341" data-name="Grupo 1341" transform="translate(217.401 525.831)">
        <path id="Caminho_2269" data-name="Caminho 2269" d="M233.512,531.519l3.432-1.982V525.6l-3.432,1.983Z" transform="translate(-217.134 -525.599)"
          fill="#6794e0"
        />
        <path id="Caminho_2270" data-name="Caminho 2270" d="M228.083,534.611l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.163 -525.582)"
          fill="#6794e0"
        />
        <path id="Caminho_2271" data-name="Caminho 2271" d="M222.653,537.7l3.432-1.982V531.78l-3.432,1.983Z" transform="translate(-217.193 -525.565)"
          fill="#6794e0"
        />
        <path id="Caminho_2272" data-name="Caminho 2272" d="M217.223,540.789l3.432-1.982v-3.937l-3.432,1.982Z" transform="translate(-217.223 -525.548)"
          fill="#6794e0"
        />
      </g>
      <g id="Grupo_1342" data-name="Grupo 1342" transform="translate(217.401 531.751)">
        <path id="Caminho_2273" data-name="Caminho 2273" d="M233.512,537.409l3.432-1.982v-3.942l-3.432,1.982Z" transform="translate(-217.134 -531.486)"
          fill="#6794e0"
        />
        <path id="Caminho_2274" data-name="Caminho 2274" d="M228.083,540.5l3.432-1.982v-3.938l-3.432,1.987Z" transform="translate(-217.163 -531.469)"
          fill="#6794e0"
        />
        <path id="Caminho_2275" data-name="Caminho 2275" d="M222.653,543.587l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.193 -531.452)"
          fill="#6794e0"
        />
        <path id="Caminho_2276" data-name="Caminho 2276" d="M217.223,546.676l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(-217.223 -531.435)"
          fill="#6794e0"
        />
      </g>
      <path id="Caminho_2277" data-name="Caminho 2277" d="M233.512,543.443l3.432-1.983v-3.937l-3.432,1.982Z" transform="translate(0.281 0.298)"
        fill="#6794e0"
      />
      <path id="Caminho_2278" data-name="Caminho 2278" d="M228.083,546.53l3.432-1.982v-3.937l-3.432,1.982Z" transform="translate(0.243 0.315)"
        fill="#6794e0"
      />
      <path id="Caminho_2279" data-name="Caminho 2279" d="M222.653,549.618l3.432-1.982V543.7l-3.432,1.981Z" transform="translate(0.205 0.332)"
        fill="#6794e0"
      />
      <path id="Caminho_2281" data-name="Caminho 2281" d="M233.512,549.475l3.432-1.982v-3.938l-3.432,1.982Z" transform="translate(0.281 0.331)"
        fill="#6794e0"
      />
      <g id="Grupo_1345" data-name="Grupo 1345" transform="translate(194.476 513.704)">
        <path id="Caminho_2285" data-name="Caminho 2285" d="M197.9,519.458l-3.432-1.983v-3.937l3.432,1.982Z" transform="translate(-194.469 -513.538)"
          fill="#3157bf"
        />
        <path id="Caminho_2286" data-name="Caminho 2286" d="M203.328,522.546l-3.432-1.982v-3.939l3.432,1.982Z" transform="translate(-194.439 -513.521)"
          fill="#3157bf"
        />
        <path id="Caminho_2287" data-name="Caminho 2287" d="M208.76,525.636l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.409 -513.504)"
          fill="#3157bf"
        />
        <path id="Caminho_2288" data-name="Caminho 2288" d="M214.19,528.724l-3.432-1.982V522.8l3.432,1.982Z" transform="translate(-194.38 -513.487)"
          fill="#3157bf"
        />
      </g>
      <g id="Grupo_1346" data-name="Grupo 1346" transform="translate(194.476 507.677)">
        <path id="Caminho_2289" data-name="Caminho 2289" d="M197.9,513.461l-3.432-1.981v-3.936l3.432,1.982Z" transform="translate(-194.469 -507.544)"
          fill="#3157bf"
        />
        <path id="Caminho_2290" data-name="Caminho 2290" d="M203.328,516.549l-3.432-1.982v-3.939l3.432,1.982Z" transform="translate(-194.439 -507.527)"
          fill="#3157bf"
        />
        <path id="Caminho_2291" data-name="Caminho 2291" d="M208.76,519.639l-3.432-1.983v-3.939l3.432,1.983Z" transform="translate(-194.409 -507.51)"
          fill="#3157bf"
        />
        <path id="Caminho_2292" data-name="Caminho 2292" d="M214.19,522.728l-3.432-1.982v-3.939l3.432,1.982Z" transform="translate(-194.38 -507.493)"
          fill="#3157bf"
        />
      </g>
      <g id="Grupo_1347" data-name="Grupo 1347" transform="translate(194.476 519.77)">
        <path id="Caminho_2293" data-name="Caminho 2293" d="M197.9,525.491l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.469 -519.571)"
          fill="#3157bf"
        />
        <path id="Caminho_2294" data-name="Caminho 2294" d="M203.328,528.579,199.9,526.6v-3.939l3.432,1.983Z" transform="translate(-194.439 -519.554)"
          fill="#3157bf"
        />
        <path id="Caminho_2295" data-name="Caminho 2295" d="M208.76,531.669l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.409 -519.537)"
          fill="#3157bf"
        />
        <path id="Caminho_2296" data-name="Caminho 2296" d="M214.19,534.757l-3.432-1.981v-3.938l3.432,1.982Z" transform="translate(-194.38 -519.52)"
          fill="#3157bf"
        />
      </g>
      <g id="Grupo_1348" data-name="Grupo 1348" transform="translate(194.476 525.831)">
        <path id="Caminho_2297" data-name="Caminho 2297" d="M197.9,531.519l-3.432-1.982V525.6l3.432,1.983Z" transform="translate(-194.469 -525.599)"
          fill="#3157bf"
        />
        <path id="Caminho_2298" data-name="Caminho 2298" d="M203.328,534.611l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.439 -525.582)"
          fill="#3157bf"
        />
        <path id="Caminho_2299" data-name="Caminho 2299" d="M208.76,537.7l-3.432-1.982V531.78l3.432,1.983Z" transform="translate(-194.409 -525.565)"
          fill="#3157bf"
        />
        <path id="Caminho_2300" data-name="Caminho 2300" d="M214.19,540.789l-3.432-1.982v-3.937l3.432,1.982Z" transform="translate(-194.38 -525.548)"
          fill="#3157bf"
        />
      </g>
      <g id="Grupo_1349" data-name="Grupo 1349" transform="translate(194.476 531.751)">
        <path id="Caminho_2301" data-name="Caminho 2301" d="M197.9,537.409l-3.432-1.982v-3.942l3.432,1.982Z" transform="translate(-194.469 -531.486)"
          fill="#3157bf"
        />
        <path id="Caminho_2302" data-name="Caminho 2302" d="M203.328,540.5l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.439 -531.469)"
          fill="#3157bf"
        />
        <path id="Caminho_2303" data-name="Caminho 2303" d="M208.76,543.587l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.409 -531.452)"
          fill="#3157bf"
        />
        <path id="Caminho_2304" data-name="Caminho 2304" d="M214.19,546.676l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(-194.38 -531.435)"
          fill="#3157bf"
        />
      </g>
      <path id="Caminho_2305" data-name="Caminho 2305" d="M197.9,543.443l-3.432-1.983v-3.937L197.9,539.5Z" transform="translate(0.007 0.298)"
        fill="#3157bf"
      />
      <path id="Caminho_2306" data-name="Caminho 2306" d="M203.328,546.53l-3.432-1.982v-3.937l3.432,1.982Z" transform="translate(0.036 0.315)"
        fill="#3157bf"
      />
      <path id="Caminho_2307" data-name="Caminho 2307" d="M208.76,549.618l-3.432-1.982V543.7l3.432,1.981Z" transform="translate(0.084 0.332)"
        fill="#3157bf"
      />
      <path id="Caminho_2309" data-name="Caminho 2309" d="M197.9,549.475l-3.432-1.982v-3.938l3.432,1.982Z" transform="translate(0.007 0.331)"
        fill="#3157bf"
      />
      <path id="Caminho_2349" data-name="Caminho 2349" d="M203.328,550.916v-2.291l-3.432-1.982v3.938l.578.334Z" transform="translate(0.036 0.348)"
        fill="#3157bf"
      />
      <g id="Grupo_1355" data-name="Grupo 1355" transform="translate(222.711 527.548)">
        <path id="Caminho_2350" data-name="Caminho 2350" d="M233.782,550.928l-11.336-6.545v6.545Z" transform="translate(-222.446 -527.213)"
          fill="#5076de"
        />
        <path id="Caminho_2351" data-name="Caminho 2351" d="M240.858,551.022l11.283-6.6,14.847-8.573-14.8-8.547-14.894,8.6-14.847,8.574,11.333,6.543Z" transform="translate(-222.446 -527.306)"
          fill="#e0ebff"
        />
        <path id="Caminho_2352" data-name="Caminho 2352" d="M266.886,550.975V535.8L240.77,550.975Z" transform="translate(-222.345 -527.26)"
          fill="#9eb8ff"
        />
        <path id="Caminho_2353" data-name="Caminho 2353" d="M266.889,550.975V535.8l-26.115,15.172Z" transform="translate(-222.345 -527.26)"
          fill="#bad4ff"
        />
      </g>
      <path id="Caminho_2354" data-name="Caminho 2354" d="M226.3,550.908v-1.071l-1.609-.932-1.575-.9v2.908Z" transform="translate(0.208 0.355)"
        fill="#3157bf"
      />
      <path id="Caminho_2355" data-name="Caminho 2355" d="M263.178,540.974v4.774l1.609-.932v-4.773Z" transform="translate(0.488 0.312)"
        fill="#6794e0"
      />
      <path id="Caminho_2356" data-name="Caminho 2356" d="M258.356,543.559v4.773l1.575-.9,1.609-.932v-4.773l-1.575.9Z" transform="translate(0.462 0.321)"
        fill="#6794e0"
      />
      <path id="Caminho_2357" data-name="Caminho 2357" d="M247.107,550.9v-.586l-1.02.586Z" transform="translate(0.366 0.368)"
        fill="#6794e0"
      />
      <path id="Caminho_2358" data-name="Caminho 2358" d="M207.348,550.9l-2.02-1.166V550.9Z" transform="translate(0.066 0.365)"
        fill="#3157bf"
      />
      <path id="Caminho_2359" data-name="Caminho 2359" d="M214.19,550.915v-2.146l-3.432-1.982v3.938l.327.189Z" transform="translate(0.122 0.349)"
        fill="#3157bf"
      />
      <path id="Caminho_2360" data-name="Caminho 2360" d="M220.327,550.915l.327-.189v-3.938l-3.432,1.982v2.146Z" transform="translate(0.167 0.349)"
        fill="#6794e0"
      />
      <path id="Caminho_2361" data-name="Caminho 2361" d="M251.926,550.911v-3.465l-1.575.9-1.609.932v1.628Z" transform="translate(0.387 0.352)"
        fill="#6794e0"
      />
      <path id="Caminho_2362" data-name="Caminho 2362" d="M254.016,550.927l1.107-.636,1.609-.932v-4.773l-1.575.9-1.609.931v4.506Z" transform="translate(0.436 0.337)"
        fill="#6794e0"
      />
      <path id="Caminho_2363" data-name="Caminho 2363" d="M261.539,550.908V548.03l-1.575.9-1.609.931v1.042Z" transform="translate(0.462 0.355)"
        fill="#6794e0"
      />
      <path id="Caminho_2364" data-name="Caminho 2364" d="M264.787,550.917v-4.488l-1.609.932v3.556Z" transform="translate(0.488 0.347)"
        fill="#6794e0"
      />
    </g>
  </svg>
);

export default Building;