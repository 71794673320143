import React from 'react'

import { useProfile } from 'hooks'
import { useScheduleNotification } from './useScheduleNotification'

import { ScheduleNotification } from './ScheduleNotification'

const RenderNotificationWithData = () => {
  const { scheduleToStart, showNotification, timeUp, setTimeUp } = useScheduleNotification()

  return (
    <ScheduleNotification 
      scheduleToStart={scheduleToStart}
      showNotification={showNotification}
      timeUp={timeUp}
      setTimeUp={setTimeUp}
    />
  )
}

export const WrapperScheduleNotification = (): JSX.Element => {
  const { isProfessional } = useProfile()

  return (
    <>
      {isProfessional ? (<RenderNotificationWithData />) : null}
    </>
  )
}