import { 
  CLEAR_REPORT, 
  GENERATE_REPORT, 
} from "../actions/_actionTypes";
import { updateState } from "../helpers/utilityHelpers";

export const initialState = { downloaded: false };

const clearReport = (state) => {
  return updateState(state, { downloaded: false  });
};

const generateReport = (state, { payload }) => {
  return updateState(state, { downloaded: payload.downloaded  });
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLEAR_REPORT: return clearReport(state);
    case GENERATE_REPORT: return generateReport(state, action);
    default: return state;
  }
};

export default reportReducer;