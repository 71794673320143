import React from "react";

const VisibilityOn = ({
  fill,
  height,
  viewBox = "-9 -12.5 40 40",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path fill={fill} d="M11 0A11.827 11.827 0 0 0 0 7.5 11.827 11.827 0 0 0 11 15a11.827 11.827 0 0 0 11-7.5A11.827 11.827 0 0 0 11 0zm0 12.5a5 5 0 0 1-5-5 5 5 0 0 1 5-5 5 5 0 0 1 5 5 5 5 0 0 1-5 5zm0-8a3 3 0 0 0-3 3 3 3 0 0 0 3 3 3 3 0 0 0 3-3 3 3 0 0 0-3-3z" />
  </svg>
);

export default VisibilityOn;