import React from "react";

const FilterActive = ({
  fill,
  height,
  viewBox = "0 0 37.38 40.38",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} transform="translate(0 .04)">
      <path d="M29.6 40.51a8.19 8.19 0 1 0-8.19-8.19 8.19 8.19 0 0 0 8.19 8.19zm-3.4-9l2.48 2.14 4.13-4.77 1.09.95-5.08 5.86-3.57-3.09z" transform="translate(-.41 -.17)"/>
      <path d="M15.21 17a8.52 8.52 0 1 0-8.52-8.48A8.52 8.52 0 0 0 15.21 17zm0-14.6a6.08 6.08 0 1 1-6.08 6.12 6.08 6.08 0 0 1 6.08-6.08z" />
      <path d="M26.32 22.54a12.11 12.11 0 0 0-8.07-3.08h-6.08A12.17 12.17 0 0 0 0 31.63v3.65a1.22 1.22 0 0 0 2.43 0v-3.65a9.73 9.73 0 0 1 9.73-9.73h6.08a9.68 9.68 0 0 1 5.58 1.77 10.07 10.07 0 0 1 2.5-1.13z" />
    </g>
  </svg>
);

export default FilterActive;