import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const InfoBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  
  & > :first-child {
    margin-bottom: 6px;
  }
`

export const InfoTitleWrapper = styled.div`
  display: flex;
  width: 100%;

  & > :first-child {
    color: #898989;
    min-width: 140px;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin: 10px 0 20px;
  gap: 20px;
  flex-direction: column;

  & > button {
    border-radius: 30px;
    border-width: 2px;
  }
`