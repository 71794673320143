export const toTitleCase = (str) => {
  const newStr = str.toLowerCase();
  return newStr.replace(
    /\w\S*/g,
    function(title) {
      return title.charAt(0).toUpperCase() + title.substr(1).toLowerCase();
    }
  );
};

/** Truncate string of number */
export const truncateNumber = (value) => value.split(',')[0];

export const removeMaskInputValue = (value) =>
  value.replace('_', '');

export const getRoleFromProfile = (profile) => {
  const profileArray = profile.split('');
  let role = profileArray
    .splice(4, profileArray.length)
    .join('');
  return role;
};

export const getPartnerFromProfile = (profile) => {
  const profileArray = profile.split('');
  let role = profileArray
    .splice(0, 2)
    .join('');
  return role;
};

export const textLimit = (str, limit) => {
  return str.substring(0, limit) + "...";
};

export const pad = (str, max) => {
  str = str.toString();
  return str.length < max ? pad("0" + str, max) : str;
};

export const formatCpf = (cpf) => {
  if (cpf.length !== 11) {
    return "";
  }
  return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
};