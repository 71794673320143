//
const postUnavailability = () => {
  return {
    type: 'POST_UNAVAILABILITY'
  }
}

const postUnavailabilitySuccess = (response) => {
  return {
    type: 'POST_UNAVAILABILITY_SUCCESS',
    payload: response
  }
}

const postUnavailabilityFailure = ({ message }) => {
  return {
    type: 'POST_UNAVAILABILITY_FAILURE',
    payload: message
  }
}

const updateErrorStatus = () => {
  return {
    type: 'UPDATE_ERROR_STATUS'
  }
}

const updateEventList = () => {
  return {
    type: 'UPDATE_EVENT_LIST',
  }
}

export {
  postUnavailability,
  postUnavailabilitySuccess,
  postUnavailabilityFailure,
  updateErrorStatus,
  updateEventList
}