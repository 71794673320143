import {
  GET_PARTNER_PROFILES,
  CLEAR_PARTNER_PROFILES,
} from './_actionTypes';
import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';

/** Gets all partner's profiles or all
 * partner's profiles filtered by a
 * role_group
 */
export const getPartnerProfiles = ({ role_group_code, page, per_page }) => async dispatch => {

  const params = { 
    ...(role_group_code && { role_group_code }),
    ...(page && { page }),
    ...(per_page && { per_page }),
  };

  try {
    const response = await axios.get(apiEndpoints.PARTNER_ROLES, { params });

    let payload;

    switch (response.status) {
      case 'OK': {
        payload = { profiles: response.data.profiles };

        return dispatch({
          type: GET_PARTNER_PROFILES,
          payload: payload,
        });
      }

      case 'ZERO_RESULTS':
        payload = { profiles: [] };

        return dispatch({
          type: GET_PARTNER_PROFILES,
          payload: payload,
        });

      default:
        /**
         * TODO TRATAR ERROS
         */
        // dispatch(asyncError());
        break;
    }
  } catch (err) {
    console.log(err);
  }
};

/** Clear partner profiles list state */
export const clearPartnerProfiles = () => {
  return { type: CLEAR_PARTNER_PROFILES };
};
