import { useEffect, useState } from 'react'

type useIntersectionObserverType = {
  observerElement: IntersectionObserverEntry,
  isElementVisible: boolean
}

export const useIntersectionObserver = (element: HTMLElement): useIntersectionObserverType  => {
  const [observerElement, setObserverComponent] = useState<IntersectionObserverEntry>()
  
  const observer = new IntersectionObserver(
    ([component]) => {
      setObserverComponent(component)
    },
    {
      threshold: 1
    }
  )
  
  useEffect(() => {
    if (element) {
      observer.observe(element)
    }
  
    return () => {
      if (element) {
        observer.unobserve(element)
      }
    }
  }, [element, observer])
  
  return { 
    observerElement,
    isElementVisible: observerElement?.isIntersecting
  }
}