import React, { Fragment } from 'react'
import { Trans } from '@lingui/macro'

/**
 * All the consts bellow should use the "Trans"
 * component from LinguiJS
 */

export const LONG_PASSWORD = <Trans>A senha é muito longa</Trans>
export const EMPTY_FIELD = <Trans>O campo é obrigatório</Trans>
export const IMG_INVALID_FORMAT = <Trans>O arquivo deve ser uma imagem</Trans>
export const IMG_PDF_INVALID_FORMAT = <Trans>O arquivo deve ser uma imagem ou PDF</Trans>
export const IMG_INVALID_SIZE = (fileSize) => (<Fragment><Trans>O arquivo deve ter no máximo</Trans>{` ${(fileSize / 1000000)}Mb`}</Fragment>)
export const NO_CPF_PATTERN = <Trans>O CPF é inválido</Trans>
export const NO_CVV_PATTERN = <Trans>O CVV é inválido</Trans>
export const NO_DATE_PATTERN = <Trans>A data informada é inválida</Trans>
export const NO_MAIL_PATTERN = <Trans>O e-mail é inválido</Trans>
export const NO_NAME_PATTERN = <Trans>O nome é inválido</Trans>
export const NO_URL_PATTERN = <Trans>Não é uma url do Youtube válida</Trans>
export const NO_TIME_PATTERN = <Trans>Horário inválido</Trans>
export const PASSWORD_NOT_EQUAL = <Trans>As senhas não são iguais</Trans>
export const EMAIL_NOT_EQUAL = <Trans>O endereço dos campos acima devem ser iguais.</Trans>
export const PHONE_INVALID = <Trans>Ops, telefone inválido!</Trans>
export const SHORT_PASSWORD = <Trans>A senha é muito curta</Trans>
export const NO_CREDIT_CARD_PATTERN = <Trans>O número do cartão é invalido</Trans>
export const BIO_LENGTH = <Trans> O comprimento mínimo deve ser de 100 caracteres</Trans>
export const FILE_CSV_FORMAT = <Trans>Insira apenas arquivos .csv, com base no modelo.</Trans>
export const CID_LIMIT = <Trans>O limite do campo CID, são de 10 caracteres</Trans>
export const INVALID_HOUR = <Trans>Horário inválido</Trans>
