import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Spinner, Typography } from '@telavita-core/react-design-kit'

import { FilterProvider, useFilter } from './context/FilterContext'
import { useGetSchedules } from './hooks'

import { 
  formatCalendarDateForRequest, 
  formatPlanGroupForRequest, 
  formatStatusCodeForRequest 
} from './helpers'

import { EmptyMessage } from './components/EmptyMessage/EmptyMessage'
import { FilterAdminSchedulesHeader, ClearResultsButton, ScheduleList} from './components'

import * as S from './styles'

const feedbackMessageType = {
  NEW_SEARCH: 'NEW_SEARCH',
  NO_RESULTS: 'NO_RESULTS',
}

function AdminScheduleList() {
  const { state, dispatch } = useFilter()
  
  const {
    schedules, 
    isFetching,
    fetchSchedules,
    removeResults, 
    fetchNextPage,
    hasNextPage,
  } = useGetSchedules({
    sort: 'desc',
    query: state.query,
    start_date__gte: formatCalendarDateForRequest(state.calendarDate)?.startDate,
    end_date__lte: formatCalendarDateForRequest(state.calendarDate)?.endDate,
    status_code: formatStatusCodeForRequest(state.statusCode),
    planParams: {
      plan_group_code: formatPlanGroupForRequest(state.planGroupCode),
      plan_code: formatPlanGroupForRequest(state.planCode)
    },
    per_page: 10,
    shouldFetch: state.shouldFetch
  })
  
  const onSearchSchedules = (newQuery: string) => {
    if (!newQuery || !newQuery.trim()) return
    
    /*  Evita requisições desnecessárias ao pressionar Enter ou clicar no ícone de busca
        se a query no campo de busca for igual a query que já foi pesquisada. 
    */
    if (newQuery === state.previousQuery && !!schedules.length) {
      return
    }

    dispatch({ type: 'SET_PREVIOUS_QUERY', payload: state.query})
    dispatch({ type: 'SET_SHOULD_FETCH', payload: true })

    return fetchSchedules()
  }

  const onClearResults = () => {
    dispatch({ type: 'CLEAR_RESULTS' })
    removeResults()
  }

  const EndMessage = () => {
    if (!schedules.length) return null

    return (
      <Typography weight='bolder' variant='content2' center inlineStyles={{ margin: '40px 0' }}>
        Não há mais resultados
      </Typography>
    )
  }

  return (
    <div>
      <FilterAdminSchedulesHeader
        onChangeQuery={(query) => dispatch({ type: 'SET_QUERY', payload: query })}
        onSearchClick={onSearchSchedules}
        query={state.query}
        isFetching={isFetching}
      />

      {!!schedules.length && (
        <ClearResultsButton onClearResults={onClearResults} />
      )}

      {isFetching && !schedules.length && (
        <S.SpinnerContainer>
          <Spinner />
        </S.SpinnerContainer>
      )}

      {!schedules.length && !isFetching && (
        <EmptyMessage showZeroResultsMessage={state.feedbackMessage === feedbackMessageType.NO_RESULTS} />
      )}
      
      <InfiniteScroll
        style={{ overflow: 'inherit' }}
        dataLength={schedules ? schedules.length : 0}
        next={() => fetchNextPage()}
        hasMore={hasNextPage}
        loader={<S.SpinnerContainer> <Spinner /></S.SpinnerContainer>}
        endMessage={<EndMessage />}
      >
        <ScheduleList data={schedules} />
      </InfiniteScroll>
    </div>
  )
}

const AdminScheduleListWithProvider = (): JSX.Element => (
  <FilterProvider>
    <AdminScheduleList />
  </FilterProvider>
)

export default AdminScheduleListWithProvider