import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'

type PatientData = {
  username: string
  planCode: string
  durationEligibility: string
}

type ApiResponse = {
  status: string
  message: string
}

type ApiError = {
  message: string
}

export async function byPassPatientEligibility(patientData: PatientData): Promise<ApiResponse> {
  try {
    const payload = {
      duration_eligibility: patientData.durationEligibility
    }

    const response = await axios.post(apiEndpoints.PATIENT_PLANS_BYPASS_ELIGIBILITY(patientData.username, patientData.planCode), payload)  
    
    return response.data
  } catch (error) {
    const errorMessage = (error as ApiError)?.message || 'Erro desconhecido'
    throw new Error(`Erro ao executar a ação: ${errorMessage}`)
  }
}