import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import * as routes from '../settings/_routesSettings';

const PermissionRoute = ({
  isAuth,
  component: Component,
  permission,
  userPermissions,
  ...rest
}) => (
  <Route {...rest} component={(props) => (
    isAuth ? (
      userPermissions.includes(permission) ? (
        <Component {...props} />
      ) : (
        <Redirect to={routes.HOME_PSIC} />
      )
    ) : (
      <Redirect to={routes.HOME_PSIC} />
    )
  )}
  />
);

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  userPermissions: state.auth.profilePermissions,
});

export default connect(mapStateToProps)(PermissionRoute);