import { useProfile } from 'hooks';
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import * as routes from '../settings/_routesSettings';

const PrivateRouteRole = ({
  component: Component,
  allowedRoles = [],
  useRender = false,
  render = () => { },
  ...rest
}
) => {

  const profile = useProfile();
  const isAuth = useSelector(state => state.auth.isAuth);

  if (useRender) return (
    <Route {...rest}
      render={() => {
        if (isAuth && allowedRoles.includes(profile.code))
          return render()

        return <Redirect to={routes.HOME_PSIC} />
      }}
    />
  )

  return (
    <Route {...rest} component={(props) => (
      isAuth ? (
        allowedRoles.includes(profile.code) ? (
          <Component {...props} />
        ) : (
            <Redirect to={routes.HOME_PSIC} />
          )
      ) : (
          <Redirect to={routes.HOME_PSIC} />
        )
    )}
    />
  );
};

export { PrivateRouteRole };