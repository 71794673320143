const PersonProfilePlanReasons = {
  UPDATE_CARD_NUMBER: 'Atualização de carteirinha',
  UPDATE_PLAN: 'Atualização de plano',
  INCORRECT_CARD_NUMBER: 'Carteirinha incorreta',
  INCORRECT_PLAN: 'Plano incorreto',
  INCORRECT_PLAN_GROUP: 'Convênio incorreto',
  PLAN_GROUP_CHANGE: 'Mudança de convênio',
  UNREADABLE_DOCUMENT: 'Documento ilegível',
  ELEGIBILITY_DENIED: 'Elegibilidade negada',
  MEDICAL_RECOMMENDATION_EXPIRED: 'Encaminhamento vencido',
  LACK_INFORMATIONS: 'Faltando informações',
  SCHEDULE_LIMIT_IN_PERIOD: 'Limites de consultas no período',
  NOT_ELIGIBLE: 'Não elegível',
  NOT_MEDICAL_RECOMMENDATION: 'Não é um encaminhamento',
  SERVICE_IN_GRACE_PERIOD: 'Serviço em carência',
  VALIDITY_FIXED: 'Solicitação corrigida',
  OTHERS: 'Outros',
}

export {
  PersonProfilePlanReasons
}