import React from 'react'
import PropTypes from 'prop-types'
import * as routes from './../../settings/_routesSettings'

// COMPONENTS
import { CoreIcons, Typography } from 'components'
import { Typography as Typo } from '@telavita-core/react-design-kit'
import HeaderButton from './../HeaderButton'
import Logo from '../Logo'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'
import viewportHoc from '../../hoc/viewportHoc'
import { useSelector } from 'react-redux'


const Header = ({
  isDesktop,
  isLogged,
  isSuccinct,
  onHandleMenuMobile,
  userProps,
}) => {
  const auth = useSelector(s => s.auth)
  const logoMenu = (
    <div className="header--logo">
      <Logo fill={isSuccinct ? '#1F35B5' : '#fff'}
        onClickLogo={() => {
          window.location.href = process.env.REACT_APP_INSTITUTIONAL_URL
        }}
      />
      <Typo color='secondary'>
        {'{backoffice}'}
      </Typo>
    </div>
  )

  const centerMenu = isDesktop ? (
    <div className="Header--center"></div>
  ) : null

  const navMenu =
    isSuccinct ? null :
      isDesktop ?
        isLogged ? (
          <div className="Header--nav">
            <div className="Header--nav--text">
              <Typography type="subheading2"
                align="right">
                {userProps.firstName}
              </Typography>
              <Link to={auth.userData.person_profile[0].profile_code === 'TVTVPACI' ? routes.DASHBOARD_HOME_PATIENT : routes.DASHBOARD}>
                <Typography type="subheading2"
                  align="right"
                  bold
                  id="my-account"
                  style={{ lineHeight: '0.5', color: 'white' }}>
                  <Trans>
                    Minha conta
                  </Trans>
                </Typography>
              </Link>
            </div>
            <div className="Header--nav--avatar">
              <CoreIcons
                name="Avatar"
                fill="#ffffff"
                width="30px"
                height="30px"
              />
            </div>
          </div>
        ) : (
          <div className="Header--nav">
            <Link to={routes.REGISTER}>
              <HeaderButton
                btnId="register-btn"
                classModifier="outlined"
                text="criar conta"
              />
            </Link>

            <Link to={routes.LOGIN}>
              <HeaderButton
                btnId="login-btn"
                text="entrar"
              />
            </Link>
          </div>
        ) : (
          <div className="Header--nav">
            <HeaderButton
              btnId="mobile-navbar"
              classModifier="text"
              text={(<CoreIcons name="Menu" fill="#fff" />)}
              onButtonClick={onHandleMenuMobile}
            />
          </div>
        )

  return (
    <header className={`Header${isSuccinct ? '--succinct' : ''}`}>
      <div className="Header__container">
        {logoMenu}
        {centerMenu}
        {navMenu}
      </div>
    </header>
  )
}

Header.propTypes = {
  onHandleLogin: PropTypes.func,
  onHandleRegister: PropTypes.func,
}

export default viewportHoc(Header)
