import React, { Component } from 'react'
import { connect } from 'react-redux'
import { removeMaskInputValue } from '../../../../utils/textTransformations'
import InputValidation from '../../../../utils/InputValidation'
import { Typography as Typo } from '@telavita-core/react-design-kit'

// STORE
import { addEducationData } from '../../../../redux/actions'

// COMPONENTS
import { Typography, Button, Column, CoreIcons, TextInput } from 'components'
import { Trans } from '@lingui/macro'
import Row from '../../../../components/Row'
import viewportHoc from '../../../../hoc/viewportHoc'
class EducationData extends Component {
  
    state = {
      userRole: this.props.role,
      isMenuOpen: undefined,
      isNewUser: true,
      hasPartnerProfile: false,
  
      canSave: true,
      isBlockChecked: false,
        
      institution: {
        value: '',
        isValid: true,
        message: '',
      },
      course: {
        value: '',
        isValid: true,
        message: '',
      },
      start_year: {
        value: '',
        isValid: true,
        message: '',
      },
      end_year: {
        value: '',
        isValid: true,
        message: '',
      },
      education: [],
      
    }
  
    componentDidUpdate() {
      this.checkIfCanSave()
      this.checkRequiredFields()      
    }
    componentWillMount(){
      if (this.props.isEdit) 
      
        this.setState({
          education: [
            ...this.state.education,
            ...this.props.personEducationData.education,
            
          ],
        })
    }
   
  
      checkIfCanSave = () => {
        let canSave = true
  
        const requiredStates = [
          'institution',
          'course',
          'start_year',
          'end_year',
         
        ]
        
        requiredStates.map(rq => {
          if (this.state[rq].isValid === false) {
            canSave = false
          }
          return canSave
        })
    
        if (canSave !== this.state.canSave) {
          this.setState({ canSave })
        }
    
        return canSave
  
      }
    
      checkRequiredFields = () => {
        let isBlockChecked = true
        const requiredStates = [
          'institution',
          'course',
          'start_year',
          'end_year',
        ]
    
        requiredStates.map(rq => {
          if (this.state[rq].value === '' ||
            this.state[rq].value === undefined ||
            this.state[rq].value === null ||
            this.state[rq].isValid === false) {
            isBlockChecked = false
          }
          return isBlockChecked
        })
    
        if (isBlockChecked !== this.state.isBlockChecked) {
          this.setState({ isBlockChecked })
        }
    
        return isBlockChecked
      }
    
      setWrapperRef = (node) => {
        this.wrapperRef = node
      }
      /**
       * INPUT HANDLERS
       */
      handleInstituteInput = (e) => {
        e.preventDefault()
        const inputValue = e.target.value
        let validation = new InputValidation(inputValue, 'free').validate()
        this.setState({
          institution: {
            ...this.state.institution,
            value: inputValue,
            isValid: validation.success,
            message: validation.message,
          },
        })
  
        // if (validation.success) {
        //   this.props.onAddEducationData(this.state.isBlockChecked, this.state.canSave,  { institution: inputValue });
        // }
      }

      
      handleCourseInput=(e)=>{
        e.preventDefault()
        const inputValue =e.target.value
        let validation =new InputValidation(inputValue, 'free').validate()
        this.setState({
          course: {
            ...this.state.course,
            value: inputValue,
            isValid: validation.success,
            message: validation.message,
          },
        })

     
        // if (validation.success) {
        //   this.props.onAddEducationData(this.state.isBlockChecked, this.state.canSave,  { course: inputValue });
        // }
      }
   
      handleStartYearInput = (e) => {
        e.preventDefault()
        const inputValue = removeMaskInputValue(e.target.value)
        if (new RegExp(/^[0-9]*$/g).test(inputValue) &&
            inputValue.length < 5){
          let validation = new InputValidation(inputValue, 'year').validate()
          this.setState({
            start_year: {
              ...this.state.start_year,
              value: inputValue,
              isValid: validation.success,
              message: validation.message,
            },
          })
        }
      }

      handleEndYearInput = (e) => {
        e.preventDefault()
       
        const inputValue = removeMaskInputValue(e.target.value)
        if (new RegExp(/^[0-9]*$/g).test(inputValue) &&
            inputValue.length < 5){
          let validation =new InputValidation(inputValue, 'year').validate()
          this.setState({
            end_year: {
              ...this.state.end_year,
              value: inputValue,
              isValid: validation.success,
              message: validation.message,
            },
          })
        }
      }
      
     
      handleAddEducation=async(e)=>{
        if ( this.checkRequiredFields ()){
          const { start_year, end_year } = this.state
          const checkYear = parseInt( end_year.value) >= parseInt( start_year.value)
          if (checkYear){
            await this.setState({
              education: [
                ...this.state.education,
                {
                  'institution': { ...this.state.institution }.value,
                  'course_name': { ...this.state.course }.value,
                  'start_year': parseInt({ ...this.state.start_year }.value),
                  'end_year': parseInt({ ...this.state.end_year }.value),
                },
              ],
            })
          }}

        await this.props.onAddEducationData(this.state.isBlockChecked, this.state.canSave,  { education: this.state.education })
        await this.setState(
          {
            ...this.state.end_year.value = '',
            ...this.state.institution.value = '',
            ...this.state.start_year.value = '',
            ...this.state.course.value = '',
            ...this.state.education.value=[],


          })
      }
      handleRemoveEducation = (e, index, qualification) => {
        e.preventDefault()
        let educations = [ ...this.state.education ]
        let newEducation = []

        if (qualification.id){

          let newQualificationObject = {
            id: qualification.id,
            delete: true,
          }

          for (let i = 0; i < educations.length; i++) {
            const education = educations[i]
            if (education.id !== qualification.id){
              newEducation.push(education)
            }
          }
          
          newEducation.push(newQualificationObject)
        } else {
          newEducation = [ ...educations ]
          newEducation.indexOf(index)
  
          if (index > -1) {
            newEducation.splice(index, 1)
          }
        }

        this.setState({
          education: [
            ...newEducation,
          ],
        })

        this.props.onAddEducationData(this.state.isBlockChecked, this.state.canSave,  { education: newEducation })
      }
  
      render() {       
        return (
          <div className="RegisterBlock__EducationData">
            <Row className="RegisterBlock__EducationData">
                
              <Row padding=" 0 0 2rem 0">
                <Typo variant='content1' weight='bold'>
                  Formação Acadêmica
                </Typo>
              </Row>
  
              <Row>
                <Column colWidth={6}>
                  <TextInput
                    placeholder="Instituição*"
                    required={true}
                    name="institute"
                    hasError={!this.state.institution.isValid}
                    inputHeight='4.8rem'
                    messageError={this.state.institution.message}
                    onInputChange={(e) => this.handleInstituteInput(e)}
                    onFocusOut={this.checkEmail}
                    value={this.state.institution.value}
                  />
                </Column>
                <Column colWidth={6}>
                  <TextInput
                    placeholder="Curso"
                    required={true}
                    name="course"
                    hasError={!this.state.course.isValid}
                    inputHeight='4.8rem'
                    messageError={this.state.course.message}
                    onInputChange={(e) => this.handleCourseInput(e)}
                    value={this.state.course.value}
                  />
                </Column>
              </Row>
              <div className='RegisterBlock__EducationData__year'>
                <div className='RegisterBlock__EducationData__year--start'> 
                  <div className='RegisterBlock__EducationData__year--start--text'>  
                    <Typography
                      Tag='span'
                      align='center'>
                      <Trans>
                        Início:
                      </Trans>
                    </Typography>
                  </div> 
                  <div className='RegisterBlock__EducationData__year--field'>
                    <TextInput
                      placeholder="Ano*"
                      required={true}
                      name="start_year"
                      hasError={!this.state.start_year.isValid}
                      inputHeight='4.8rem'
                      messageError={this.state.start_year.message}
                      onInputChange={(e) => this.handleStartYearInput(e)}
                      value={this.state.start_year.value}
                    />
                  </div>
                  
                </div>

                
                <div className='RegisterBlock__EducationData__year--end' >   

                  <div className='RegisterBlock__EducationData__year--end--text' >  
                    <Typography
                      Tag='span'
                      align='mcenter-dleft'>
                      <Trans>
                          Conclusão:
                      </Trans>
                    </Typography>
                  </div>
                 

                  <div className='RegisterBlock__EducationData__year--field'>
                    <TextInput
                      placeholder="Ano*"
                      required={true}
                      name="end_year"
                      hasError={!this.state.end_year.isValid}
                      inputHeight='4.8rem'
                      messageError={this.state.end_year.message}
                      onInputChange={(e) => this.handleEndYearInput(e)}
                      value={this.state.end_year.value}
                    />
                  </div>
                </div>

              </div>

              <Row padding=" 2rem 0 2rem 0">
                <Column colWidth={3} className="RegisterBlock__SkillsData--btn">
                  <Button 
                    classModifier='reverse'
                    text= 'ADICIONAR FORMAÇÃO'
                    type='button'
                    onButtonClick={(e) => this.handleAddEducation(e)}
                  />
                </Column>
                  
              </Row>

              {/* education list card */}

              <Row className='RegisterBlock__EducationData--Education'>
              
                {this.state.education.map((qualification, index) => 
                  qualification.delete ? null : (
                    <Row className='RegisterBlock__EducationData--Education--EducationList' key={index}
                      justify='space-between'>
                      <Column  className='RegisterBlock__EducationData--Education--EducationList--data' colWidth={10}>
                        <Typography bold> 
                          {qualification.institution} 
                        </Typography>
                        <Typography> 
                          {qualification.course_name} 
                        </Typography>
                        <Typography> 
                          {qualification.start_year}/{qualification.end_year} 
                        </Typography>
                      </Column>
                      <Column className='RegisterBlock__EducationData--Education--EducationList--icon' colWidth={2} >
                        <div className='RegisterBlock__EducationData--Education--EducationList--icon' onClick={(e)=>this.handleRemoveEducation(e, index, qualification)}
                          idx={index}
                          ref={node => this.wrapperRef = node}>
                          <CoreIcons
                            name='ErrorIcon'
                            height='25px'
                          />
                        </div>
                      </Column>
                    </Row>
                  ))}
              </Row>
            </Row>
          </div>
        )
      }
}



const mapStateToProps = state => {
  return {  
    educationData: state.registerBlocks,
    isEdit: state.registerBlocks.isEdit,
    personEducationData: state.registerBlocks.blocksData.educationData, 
  }
}

const mapDispatchToProps = dispatch => {
  return { onAddEducationData: (isBlockChecked, canSave, field) => (dispatch(addEducationData(isBlockChecked, canSave, field)))  }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc( EducationData))
