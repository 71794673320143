import React from "react";

const ExitToApp = ({
  fill,
  height,
  viewBox = "-10 -10 45 45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} transform="translate(0 -8)">
      <path d="M0 0h24v24H0z" transform="translate(0 8)" fill="none" />
      <g fill={fill} transform="translate(4.753 12.539)">
        <path
          d="M25 17.4v1.8h8.993l-1.83 1.808 1.288 1.292 4-4-4-4-1.288 1.288 1.83 1.808z"
          transform="translate(-19.351 -11.069)"
        />
        <path d="M12.2 10.394h-1.806v2.26H1.808V1.808h8.586v2.26H12.2V0H0v14.461h12.2z" />
      </g>
    </g>
  </svg>
);

export default ExitToApp;