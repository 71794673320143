import React from "react";

const Menu = ({
  fill = "#fff",
  height,
  viewBox = "0 0 35 35",
  width,
}) => (
    <svg
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g transform="translate(-311 -13)">
        <rect fill="none" width="35" height="35" transform="translate(311 13)" />
        <path fill={fill} d="M604-1398.667h20v-2.222H604Zm0-5.555h20v-2.222H604Zm0-7.778v2.222h20V-1412Z" transform="translate(-285 1436)" />
      </g>
    </svg>
  )

export default Menu;