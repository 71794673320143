import { GET_CANCEL_REASON } from './_actionTypes';

import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';
export const  getCancelReason=(reason_group)=>

  async dispatch =>{
    const response = await axios.get(apiEndpoints.REASONS(reason_group)); 
    let payload;
    switch (response.status) {
      case 'OK':
        payload = { reasons: response.data.reasons };

        return dispatch({
          type: GET_CANCEL_REASON,
          payload: payload,
        });

      case 'ZERO_RESULTS':
        payload = { reasons: [] };

        return dispatch({
          type: GET_CANCEL_REASON,
          payload: payload,
        });
       
      default:
        break;
    }

  };