import React from 'react'

import * as S from './styles'

const Tooltip = ({
  continuous,
  isLastStep,
  step,
  primaryProps,
  tooltipProps,
}) => {
  return (
    <S.TooltipBody {...tooltipProps}>
      {step.title && <S.TooltipTitle>{step.title}</S.TooltipTitle>}
      {step.content && <S.TooltipContent dangerouslySetInnerHTML={{ __html: step.content }} />}
      <S.TooltipFooter>
        {!isLastStep && continuous && (
          <S.Button {...primaryProps}>
          Próximo
          </S.Button>
        )}
        {isLastStep && (
          <S.Button {...primaryProps}>
          Entendi
          </S.Button>
        )}
      </S.TooltipFooter>
    </S.TooltipBody>
  )
}

export default Tooltip