import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface listProfessionalServiceProps {
  profileRole: string;
  statusCode: number;
  planCode: string;
  query: string;
  startDate?: string;
  productCode?: number;
  appointmentCode?: number;
  perPage?: number;
  page?: number;
  sort?: string;
}

interface Person {
  username: string
}

interface ProfessionalsData {
  persons: Person[]
}

interface Response {
  data: {
    persons: Person[]
  }
}

interface Error {
  response: {
    status: number
  }
}

const listProfessionalService = ({
  profileRole,
  startDate,
  productCode,
  appointmentCode,
  statusCode,
  planCode,
  query,
  perPage,
  page,
  sort,
}: listProfessionalServiceProps): Promise<ProfessionalsData>  => {

  const endpoint = apiEndpoints.PERSONS_PROFESSIONALS
  const params = {
    profile_role: profileRole,
    status_code: statusCode,
    plan_code: planCode,
    query: query,
    ...(startDate && { start_date: startDate }),
    ...(productCode && { product_code: productCode }),
    ...(appointmentCode && { appointment_code: appointmentCode }),
    ...(perPage && { per_page: perPage }),
    ...(page && { page: page }),
    ...(sort && { sort: sort }),
  }

  return new Promise <ProfessionalsData> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        const { persons } = response.data

        resolve({ persons } as ProfessionalsData)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}

export { listProfessionalService }