import styled from 'styled-components'

interface TotalProps {
  size: number
  lineHeight: number
}

interface LabelProps {
  weight: 'bold' | 'normal'
  maxWidth: number
  lineHeight: number
}

interface ContentProps extends ResponsiveProps {
  isLoading: boolean
}

interface ResponsiveProps {
  isMobile: boolean
}

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Container = styled.div`
  position: relative;
`

export const Content = styled.div<ContentProps>`
  padding: 20px 32px 30px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: ${({ isMobile }) => isMobile ? 35 : 70}px;
  opacity: ${({ isLoading }) => isLoading && 0};

  @media (max-width: 375px) {
    padding: 20px 20px 30px 32px;
    width: 100%;
  }
`

export const TotalWrapper = styled.div<ResponsiveProps>`
  display: flex;
  flex-direction: column;
  gap: ${({ isMobile }) => isMobile ? 5 : 11}px;
`

export const Total = styled.p<TotalProps>`
  color: #1F35B5;
  font-size: ${({ size }) => size}px;
  font-weight: bold;
  line-height: ${({ lineHeight }) => lineHeight}px;
  letter-spacing: -3px;
`

export const Label = styled.p<LabelProps>`
  font-weight: ${({ weight }) => weight};
  color: #4B4B4B;
  font-size: 12px;
  max-width: ${({ maxWidth }) => maxWidth}px;
  line-height: ${({ lineHeight }) => lineHeight}px;
`

export const AnalyticsWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  gap: 20px;

  @media (max-width: 1060px) {
    flex: 0;
    justify-content: center;
  }
`

export const AnalyticsItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media (max-width: 1060px) {
    min-width: 80px;
  }
`

export const AnalyticsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`

export const AnalyticsPercentage = styled.div`
  background-color: #ebeefd;
  border-radius: 3px;
  padding: 3px 5px;
  color: #1f35b5;
  font-size: 12px;
  line-height: 13px;
`

export const AnalyticsLabel = styled.div`
  max-width: 95px;
`