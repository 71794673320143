import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

import { Review, ReviewStatus } from './review.types'

interface ResponseReview {
  id: number
  patient_initial_letters: string
  status: string
  viewed: boolean
  created_at: string
  review: string
}

interface Response {
  data: {
    reviews: ResponseReview[]
    pagination: {
      total: number
      page: number
    }
  }
}

interface Error {
  response: {
    status: number
  }
}

interface ReviewsData {
  reviews: Review[]
  pagination: {
    total: number
    page: number
  }
}

interface ListReviewsProps {
  status: ReviewStatus
  page?: number
}

export const listReviews = ({
  status,
  page,
}: ListReviewsProps): Promise<ReviewsData> => {

  const endpoint = apiEndpoints.ME_REVIEWS as string

  const params = {
    status,
    per_page: 5,
    ...(page && { page }),
  }
  
  return new Promise<ReviewsData> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        const reviews = response.data.reviews.map(review => {
          return {
            id: review.id,
            author: review.patient_initial_letters,
            status: review.status,
            createdAt: new Date(review.created_at),
            review: review.review,
            viewed: review.viewed,
          } as Review
        })

        const pagination = { ...response.data.pagination }

        resolve({ reviews, pagination } as ReviewsData)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}