import { 
  GET_MEDICAL_REQUEST, 
  CLEAR_MEDICAL_REQUEST,
  CHANGE_MEDICAL_REQUEST,
  SEND_PROCEDURE_REQUEST,
  CREATE_MEDICAL_REQUEST,
  GET_DISAPPROVE_DETAILS,
} from './_actionTypes'
import { apiEndpoints } from './../../settings/_apiSettings'
import axios from '../../settings/axios'
import { asyncError } from './'
import qs from 'querystring'

// MEDICAL REQUESTS are also known as Treatment Validities
/* GET MEDICAL REQUEST */
export const getMedicalRequest = ({ 
  query, 
  status_code, 
  page, 
  per_page,
  date_created__gte, 
  date_created__lte,
  sort,
  start_date__lte,
  start_date__gte,
  end_date__lte,
  end_date__gte,
  planParams,
}) => async dispatch => {

  const params = {
    ...(query && { query }),
    ...(status_code && { status_code }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(date_created__gte && { date_created__gte }),
    ...(date_created__lte && { date_created__lte }),
    ...(sort && { sort }),
    ...(start_date__lte && { start_date__lte }),
    ...(start_date__gte && { start_date__gte }),
    ...(end_date__lte && { end_date__lte }),
    ...(end_date__gte && { end_date__gte }),
    ...planParams,
  }

  const response = await axios.get(
    apiEndpoints.VALIDITIES, 
    { 
      params,
      paramsSerializer: params => {
        return qs.stringify(params)
      },
    }
  )
  const medicalRequestPayload = { 
    validities: response.data.person_profile_plan_treatment_validities,
    pagination: response.data.pagination,
  }

  dispatch({  
    type: GET_MEDICAL_REQUEST,
    payload: medicalRequestPayload,
  })
}

/* CHANGE MEDICAL REQUEST */
export const createMedicalRequest = ({ profile_role, plan_code, card_number, treatment_validity }) => async dispatch => {

  const params = {
    ...(profile_role && { profile_role }),
    ...(plan_code && { plan_code }),
    ...(card_number && { card_number }),
    ...(treatment_validity && { treatment_validity }),
  }

  try {
    const response = await axios.post(apiEndpoints.VALIDITIES, params)
    let createSuccess = null

    if (response.status === 'OK') {
      createSuccess = true
    }
    
    const medicalRequestPayload = { createSuccess }
  
    dispatch({  
      type: CREATE_MEDICAL_REQUEST,
      payload: medicalRequestPayload,
    })

  } catch (err) {
    let createError = null
    let createFeedbackMessage = null

    if (err.response.data.status === 'INVALID_REQUEST' && err.response.data.messages[0]?.type === 'error') {
      createError = true
    }

    if (err.response.data.status === 'INVALID_REQUEST' && err.response.data.messages[0]?.type === 'feedback') {
      createFeedbackMessage = err.response.data.messages[0]?.message
    }
      
    const medicalRequestPayload = { createError, createFeedbackMessage }

    dispatch({  
      type: CREATE_MEDICAL_REQUEST,
      payload: medicalRequestPayload,
    })
  }
}

/* CHANGE MEDICAL REQUEST */
export const changeMedicalRequest = (id, props, isAttaching = false) => async dispatch => {
  const { status_code, start_date, plan_password, treatment, reason_code, details, max_appointment, validity_expiry_days } = props
  
  const params = {
    ...(status_code && { status_code }),
    ...(start_date && { start_date }),
    ...(plan_password && { plan_password }),
    ...(treatment && { treatment }),
    ...(reason_code && { reason_code }),
    ...(details && { details }),
    ...(max_appointment && { max_appointment }),
    ...(validity_expiry_days && { validity_expiry_days }),
  }
  
  const putUrl = isAttaching ? apiEndpoints.VALIDITY_ATTACH(id) : apiEndpoints.VALIDITY(id)

  try {
    const response = await axios.put(putUrl, params)
    let success = false

    if (response.status === 'OK') {
      success = true
    }
    
    const medicalRequestPayload = { success }
  
    dispatch({  
      type: CHANGE_MEDICAL_REQUEST,
      payload: medicalRequestPayload,
    })

  } catch (err) {
    dispatch(asyncError(err))
  }
}

/* SEND PROCEDURE REQUEST */
export const sendProcedureRequest = (card_number, cpf, plan_code) => async dispatch => {

  const params = {
    ...(card_number && { card_number }),
    ...(cpf && { cpf }),
    ...(plan_code && { plan_code }),
  }

  const postUrl = apiEndpoints.PROCEDURE_REQUEST

  try {
    const response = await axios.post(postUrl, params)
    
    const payload = {
      procedure: {
        hasProcedure: true,
        start_date: response.data.start_date,
        plan_password: response.data.plan_password,
      },
      errorMessage: null, 
    }
  
    dispatch({  
      type: SEND_PROCEDURE_REQUEST,
      payload,
    })

    return {
      errorMessage: null,
      startDate: response.data.start_date,
      planPassword: response.data.plan_password,
    }

  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))

    const payload = {
      procedure: {
        hasProcedure: false,
        start_date: null,
        plan_password: null,
      },
      errorMessage: err.response.data.messages[0].message,
    }
    
    dispatch({
      type: SEND_PROCEDURE_REQUEST,
      payload,
    })

    return {
      errorMessage: err.response.data.messages[0].message,
      startDate: null,
      planPassword: null,
    }
  }
}

/* GET DISAPPROVE DETAILS OF REQUEST */
export const getDisapproveDetails = (requestId) => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.DISAPPROVE_DETAILS(requestId))
    const payload = {
      details: response.data,
      detailsError: false,
    }
    dispatch({  
      type: GET_DISAPPROVE_DETAILS,
      payload: payload,
    })
  } catch (err) {
    const payload = {
      details: null,
      detailsError: true,
    }
    dispatch({  
      type: GET_DISAPPROVE_DETAILS,
      payload: payload,
    })
  }
}

/* CLEAR MEDICAL REQUEST */
export const clearMedicalRequest = () => dispatch => {
  dispatch({ type: CLEAR_MEDICAL_REQUEST })
}