import React from 'react';

const LinkedIn = ({
  fill,
  height,
  width,
  viewBox,
}) => {
  return (
    <svg preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}>
      <path d="M17.5 0A17.5 17.5 0 1 0 35 17.5 17.5 17.5 0 0 0 17.5 0zm-5.085 26.455H8.153V13.633h4.262zm-2.131-14.573h-.028a2.221 2.221 0 1 1 .056-4.431 2.222 2.222 0 1 1-.028 4.431zm17.5 14.573h-4.263V19.6c0-1.724-.617-2.9-2.159-2.9a2.333 2.333 0 0 0-2.187 1.559 2.918 2.918 0 0 0-.14 1.04v7.161h-4.262s.056-11.62 0-12.822h4.262v1.815a4.231 4.231 0 0 1 3.841-2.116c2.8 0 4.907 1.833 4.907 5.771z" fill="#fff" />
    </svg>
  );
}

export default LinkedIn;