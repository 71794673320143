import qs from 'querystring'

import instance from 'settings/axios'
import * as settings from '../settings/_apiSettings'
import { toString as now } from '../utils/dateTimeHandlers'
import * as scheduleStatus from '../settings/_scheduleStatusSettings'


// Buscar próximas consultas
const getNextSchedules = ({
  per_page = 5,
  sort = 'asc',
  status_code = [scheduleStatus.SCHEDULED, scheduleStatus.ATTENDING],
  username
}) => {

  const params = {
    ...(username && { username }),
    ...(status_code && { status_code }),
    ...(sort && { sort }),
    ...(per_page && { per_page }),
    end_date__gte: now(),
  }

  return instance.get(settings.apiEndpoints.SCHEDULES, {
    params,
    paramsSerializer: params => {
      return qs.stringify(params)
    },
  })
}

const getPatientCredits = (username, planCode) => {
  return new Promise((resolve, reject) => {
    instance.get(settings.apiEndpoints.PERSON_VALIDITIES(username, planCode))
      .then(response => {
        resolve({
          data: response.data,
          messages: response.messages,
        })
      })
      .catch(response => reject(response))
  })
}

const createMedicalRequest = () => {
  return new Promise((resolve, reject) => {
    instance.post(settings.apiEndpoints.VALIDITIES, {})
      .then(res => {
        const { data, messages } = res
        resolve({ data, messages })
      })
      .catch(err => reject(err))
  })
}

export {
  getNextSchedules,
  getPatientCredits,
  createMedicalRequest
}
