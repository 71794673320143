import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'
import { CoreIcons } from 'components'

interface CheckBoxProps {
  disabled?: boolean
  disabledToggleOnClick?: boolean
  iconWidth?: string
  iconHeight?: string
  iconViewBox?: string
  checkBoxId?: string
  title?: string
  customIconColor?: string
  indeterminate: boolean
  isSelected: boolean
  onClickCheckBox: (isSelected: boolean) => void
}

const CheckBox = ({
  disabled = false,
  disabledToggleOnClick = false,
  iconWidth = '24px',
  iconHeight = '24px', 
  iconViewBox = '0 0 24 24',
  checkBoxId,
  title,
  customIconColor,
  indeterminate,
  isSelected,
  onClickCheckBox
}: CheckBoxProps): JSX.Element => {

  const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    onClickCheckBox(!isSelected)
  }

  const icon = isSelected ? 'CheckBoxSelected' : 'CheckBoxUnselected'
  const fill = disabled ? '#c4c4c4' : customIconColor

  return (
    <div 
      className="CheckBox"
      onClick={!disabledToggleOnClick && handleToggle}
      id={checkBoxId}
    >
      <div 
        className="CheckBox__icon"
        onClick={disabledToggleOnClick && handleToggle}
      >
        <CoreIcons 
          name={indeterminate ? 'CheckBoxUndefined' : icon}
          fill={fill}
          width={iconWidth} 
          height={iconHeight} 
          viewBox={iconViewBox}
        />
      </div>
      <div className={`CheckBox__text ${disabled ? 'disabled' : ''}`}>
        <Typography variant='content2'>
          {title}
        </Typography>
      </div>
    </div>
  )
}

export { CheckBox }