import {
  CHANGE_PERSON,
  CHANGE_PERSON_PROFILE,
  CLEAR_PERSON,
  CLEAR_PERSONS,
  CREATE_PERSON,
  CLEAR_CONCATED_PROFESSIONALS,
  GET_PERSON,
  GET_PROFILE_PLAN,
  GET_PROFILE_ROLE,
  LIST_PERSONS,
  LIST_PROFESSIONALS,
  SET_PERSON_PROFILE,
  CLEAR_PROFESSIONALS,
  SET_PROFILE_PLAN,
  GET_PROFESSIONAL_HISTORY,
  LIST_PERSONS_PROFESSIONALS,
  CLEAR_PERSONS_PROFESSIONALS,
} from './_actionTypes'

import { asyncError } from './'
import { apiEndpoints } from '../../settings/_apiSettings'
import { checkProfileListActivity } from '../../utils/profileHelper'
import axios from '../../settings/axios'
import { ONLINE } from '../../settings/_statusSettings'
import * as routes from '../../settings/_routesSettings'
import { asyncSuccess } from './asyncActions'

/** Get one person */
export const getPerson = (friendlyUrl, profileRole, redirectNotFound = true) => async dispatch => {
  const fetchUrl = `${apiEndpoints.PERSONS}${friendlyUrl}/`

  try {
    const response = await axios.get(fetchUrl, { params: { profile_role: profileRole } })
    const responseData = response.data
    const personProfile = responseData.person_profile[0]
    const personInfo = responseData.person_general
    const personStatus = responseData.status
    const username = responseData.username
    const enableNewPatients = responseData.enable_new_patients
    personInfo.username = username
    let isOnline = false

    if (personStatus === ONLINE) {
      isOnline = true
    }

    let profileDocument, personDocument
    if (personProfile.documents &&
      personProfile.documents.length > 0) {
      profileDocument = personProfile.documents[0]
      personDocument = `${profileDocument.type_name} ${profileDocument.value}`
    } else {

      profileDocument = {}
      personDocument = ''
    }

    const personSkills = personProfile.skills
    const personsSubspecialties = personProfile.subspecialties || []

    const education = personProfile.education
    let personEducation = []

    education.map(course => {
      let newEducation = {
        school: course.institution,
        course: course.course_name,
        startYear: course.start_year,
        endYear: course.end_year,
      }

      personEducation = [
        ...personEducation,
        newEducation,
      ]

      return personEducation
    })

    const products = personProfile.products

    const payload = {
      isOnline: isOnline,
      person: personInfo,
      personDocument: personDocument,
      personEducation: personEducation,
      personsSubspecialties: personsSubspecialties,
      personSkills: personSkills,
      personProfile: personProfile,
      personProducts: products,
      profileCode: personProfile.profile_code,
      enableNewPatients
    }

    return dispatch({
      type: GET_PERSON,
      payload,
    })
  } catch (err) {
    if(err.response.status === 404 && redirectNotFound){
      window.location.href = routes.NOT_FOUND
    }else{
      dispatch(asyncError(err))
      throw Error(err)
    }
  }
}

//////
export const listProfessionals = ({
  plan_code,
  profile_role,
  start_date,
  end_date,
  product_code,
  appointment_code,
  gender,
  status_code,
  per_page,
  price__gte,
  price__lte,
  page,
  sort,
  skills
}) => async dispatch => {
  const fetchUrl = apiEndpoints.PROFESSIONAL_HOME
  const params = {
    ...(plan_code && { plan_code }),
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(end_date && { end_date }),
    ...(price__gte && { price__gte }),
    ...(price__lte && { price__lte }),
    ...(status_code && { status_code }),
    ...(gender && { gender }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(per_page && { per_page }),
    ...(page && { page }),
    ...(sort && { sort }),
    ...(skills && { skills }),
  }

  dispatch({
    type: 'START_LOADING_PERSONS'
  })

  dispatch({ type: 'RUN_LOADER' })

  try {
    const response = await axios.get(fetchUrl, { params })

    let persons = []
    let pagination = {}

    if (response.status === 'OK') {
      persons = response.data.persons
      pagination = response.data.pagination
    }

    dispatch({
      type: 'SET_PAGINATION',
      payload: pagination
    })

    dispatch({
      type: 'UPDATE_PAGE'
    })

    dispatch({ type: 'STOP_LOADER' })

    return dispatch({
      type: LIST_PROFESSIONALS,
      payload: {
        persons,
        pagination,
      },
    })
  } catch (err) {
    dispatch({ type: 'STOP_LOADER' })
    dispatch(asyncError(err))
  }
}

/** Load persons list */
export const listPersons = ({
  profile_role,
  start_date,
  product_code,
  appointment_code,
  status_code,
  plan_code,
  query,
  per_page,
  page,
  sort,
}) => async dispatch => {
  const fetchUrl = apiEndpoints.PERSONS

  const params = {
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(status_code && { status_code }),
    ...(plan_code && { plan_code }),
    ...(query && { query }),
    ...(per_page && { per_page }),
    ...(page && { page }),
    ...(sort && { sort }),
  }

  try {
    const response = await axios.get(fetchUrl, { params })
    const payload = { persons: response.data.persons }

    dispatch({
      type: LIST_PERSONS,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/** Load patients list */
export const listPatients = ({
  profile_role,
  start_date,
  product_code,
  appointment_code,
  status_code,
  plan_code,
  query,
  per_page,
  page,
  sort,
}) => async dispatch => {
  const fetchUrl = apiEndpoints.PERSONS_PATIENTS

  const params = {
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(status_code && { status_code }),
    ...(plan_code && { plan_code }),
    ...(query && { query }),
    ...(per_page && { per_page }),
    ...(page && { page }),
    ...(sort && { sort }),
  }

  try {
    const response = await axios.get(fetchUrl, { params })
    const payload = { persons: response.data.patients }

    dispatch({
      type: LIST_PERSONS,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/** Load persons list */
export const listPersonsProfessionals = ({
  profile_role,
  start_date,
  product_code,
  appointment_code,
  status_code,
  plan_code,
  query,
  per_page,
  page,
  sort,
}) => async dispatch => {
  const fetchUrl = apiEndpoints.PERSONS_PROFESSIONALS

  const params = {
    ...(profile_role && { profile_role }),
    ...(start_date && { start_date }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(status_code && { status_code }),
    ...(plan_code && { plan_code }),
    ...(query && { query }),
    ...(per_page && { per_page }),
    ...(page && { page }),
    ...(sort && { sort }),
  }

  try {
    const response = await axios.get(fetchUrl, { params })
    const payload = { persons: response.data.persons }

    dispatch({
      type: LIST_PERSONS_PROFESSIONALS,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/** Clear persons list state */
export const clearPersons = () => {
  return { type: CLEAR_PERSONS }
}

export const clearPersonsProfessionals = () => {
  return { type: CLEAR_PERSONS_PROFESSIONALS }
}

// need to clear professional before making call for filter professionals
export const clearProfessionalData = () => {
  return { type: CLEAR_PROFESSIONALS }
}
export const clearConcatedProfessionals = () => {
  return { type: CLEAR_CONCATED_PROFESSIONALS }
}
/** Clear state for one person */
export const clearOnePerson = () => {
  return { type: CLEAR_PERSON }
}

/** Adds a new Person */
export const setPerson = (postData) => async dispatch => {
  const postUrl = apiEndpoints.PERSONS
  try {
    const response = await axios.post(postUrl, postData)
    const payload = {
      persons: response.data.persons,
      slug: postData.email,
      success: true,
    }

    dispatch({ type: CREATE_PERSON, payload })
    dispatch(asyncSuccess())
  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/** Set a new Professional */
export const setProfessional = (postData) => async dispatch => {
  const postUrl = apiEndpoints.PROFESSIONALS
  try {
    const response = await axios.post(postUrl, postData)
    const payload = {
      persons: response.data.persons,
      slug: postData.email,
      success: true,
    }

    dispatch({ type: CREATE_PERSON, payload })
    dispatch(asyncSuccess())
  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/* For getting person profile role */
export const getPersonProfileRole = (slug, { role_group_code }) => async dispatch => {

  const fetchUrl = apiEndpoints.PERSON_PROFILES(slug)
  const params = { ...(role_group_code && { role_group_code }) }

  try {
    const response = await axios.get(fetchUrl, { params })
    const formatedProfileList = checkProfileListActivity(response.data.profiles)
    const payload = { profiles: formatedProfileList }

    dispatch({
      type: GET_PROFILE_ROLE,
      payload,
    })

  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/* For getting person profile role */
export const getPersonPlans = (slug, profile, { is_particular }) => async dispatch => {

  const fetchUrl = apiEndpoints.PERSON_PLANS(slug, profile)
  const params = { ...(is_particular ? { is_particular } : is_particular === false ? { is_particular } : {}) }

  try {
    const response = await axios.get(fetchUrl, { params })
    let payload = { personPlans: response.data.person_profile_plans }

    dispatch({
      type: GET_PROFILE_PLAN,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/* For getting person profile role */
export const setPersonPlans = (slug, profile,
  { plan_code, cpf, card_number, treatment_validity }) => async dispatch => {

  const fetchUrl = apiEndpoints.PERSON_PLANS(slug, profile)
  const params = {
    ...(plan_code && { plan_code }),
    ...(cpf && { cpf }),
    ...(card_number && { card_number }),
    ...(treatment_validity && { treatment_validity })
  }

  try {
    await axios.post(fetchUrl, { ...params })

    dispatch({
      type: SET_PROFILE_PLAN,
      // payload,
    })

  } catch (err) {
    dispatch(asyncError(err))
  }
}

/* Sets a new profile for a person */
export const setPersonProfile = (slug, { profile_role, status_code, plan_group_code }) => async dispatch => {

  const fetchUrl = apiEndpoints.PERSON_PROFILES(slug)
  const postData = {
    profile_role,
    status_code,
    ...(plan_group_code && { plan_group_code }),
  }

  try {
    const response = await axios.post(fetchUrl, postData)
    const payload = { success: true, status: response.status }

    dispatch({
      type: SET_PERSON_PROFILE,
      payload,
    })

  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/* Modifies a new profile for a person */
export const changePersonProfile = (slug, profile, { status_code, plan_group_code }) => async dispatch => {

  const fetchUrl = apiEndpoints.PERSON_PROFILE(slug, profile)
  const putData = { 
    status_code,
    ...(plan_group_code && { plan_group_code })
  }

  try {
    const response = await axios.put(fetchUrl, putData)
    const payload = { success: true, status: response.status }

    dispatch({
      type: CHANGE_PERSON_PROFILE,
      payload,
    })

  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/** Changes data of a Person */
export const changePerson = (slug, role, putData) => async dispatch => {
  const postUrl = apiEndpoints.PERSON_SLUG(slug, role)
  try {
    const response = await axios.put(postUrl, putData)
    const payload = {
      persons: response.data.persons,
      slug: slug,
      success: true,
    }

    dispatch({ type: CHANGE_PERSON, payload })
    dispatch(asyncSuccess())

  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

/* For getting professional history */
export const getPersonProfessionalHistory = () => async dispatch => {

  const fetchUrl = apiEndpoints.PROFESSIONAL_HISTORY

  try {
    const response = await axios.get(fetchUrl)
    let payload = { personProfessionalHistory: response.data.professionals }

    dispatch({
      type: GET_PROFESSIONAL_HISTORY,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}