import React from 'react'

const ScheduleNoParticipants = ({
  fill,
  width = 58.26,
  viewBox = '0 0 58.26 40.26',
  height = 40.26,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g transform="translate(21 0.04)">
      <path d="M15.21,17A8.52,8.52,0,1,0,6.69,8.52h0A8.52,8.52,0,0,0,15.21,17Zm0-14.6A6.08,6.08,0,1,1,9.13,8.52h0a6.08,6.08,0,0,1,6.08-6.08Z" fill={fill}/>
      <path d="M15.21,17A8.52,8.52,0,1,0,6.69,8.52h0A8.52,8.52,0,0,0,15.21,17Zm0-14.6A6.08,6.08,0,1,1,9.13,8.52h0a6.08,6.08,0,0,1,6.08-6.08Z" transform="translate(-21)" fill={fill}/>
      <path d="M29.1,24.12a8.13,8.13,0,1,0,8.13,8.13,8.13,8.13,0,0,0-8.13-8.13" transform="translate(0.03 -0.16)" fill={fill}/>
      <path d="M26.32,22.54a12.11,12.11,0,0,0-8.07-3.08H12.17A12.17,12.17,0,0,0,0,31.63v3.65a1.22,1.22,0,0,0,2.43,0V31.63a9.73,9.73,0,0,1,9.73-9.73h6.08a9.68,9.68,0,0,1,5.58,1.77,10.07,10.07,0,0,1,2.5-1.13Z" fill={fill}/>
      <path d="M26.32,22.54a12.11,12.11,0,0,0-8.07-3.08H12.17A12.17,12.17,0,0,0,0,31.63v3.65a1.22,1.22,0,0,0,2.43,0V31.63a9.73,9.73,0,0,1,9.73-9.73h6.08a9.68,9.68,0,0,1,5.58,1.77,10.07,10.07,0,0,1,2.5-1.13Z" transform="translate(-21)" fill={fill}/>
      <rect width="8" height="2" rx="1" transform="translate(25 30.961)" fill="#fff"/>
    </g>
  </svg>

)

export default ScheduleNoParticipants