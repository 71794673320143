import React from 'react';

function LineSeparator({
  lineColor,
  lineHeight,
  lineMargin,
  lineWidth,
}) {

  let separatorStyles = {
    backgroundColor: `${lineColor ? lineColor : '#e7e7e7'}`,
    height: `${lineHeight ? lineHeight : '2px'}`,
    margin: `${lineMargin ? lineMargin : '1rem 0'}`,
    width: `${lineWidth ? lineWidth : 'auto'}`,
  };

  return (
    <div className='LineSeparator' style={separatorStyles}></div>
  );
}

export default LineSeparator;
