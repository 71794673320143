import React from 'react'

const NoShow = ({ fill, height = 35, viewBox = '0 0 42 42', width = 35 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g transform="translate(0 0.04)">
      <path d="M15.21,17A8.52,8.52,0,1,0,6.69,8.52h0A8.52,8.52,0,0,0,15.21,17Zm0-14.6A6.08,6.08,0,1,1,9.13,8.52h0a6.08,6.08,0,0,1,6.08-6.08Z" fill={fill}/>
      <path d="M10276.131,12334.221a8.13,8.13,0,1,1,8.129-8.129A8.139,8.139,0,0,1,10276.131,12334.221Zm-3.13-9.259a1,1,0,0,0,0,2h6a1,1,0,0,0,0-2Z" transform="translate(-10247.001 -12294.001)" fill={fill}/>
      <path d="M26.32,22.54a12.11,12.11,0,0,0-8.07-3.08H12.17A12.17,12.17,0,0,0,0,31.63v3.65a1.22,1.22,0,0,0,2.43,0V31.63a9.73,9.73,0,0,1,9.73-9.73h6.08a9.68,9.68,0,0,1,5.58,1.77,10.07,10.07,0,0,1,2.5-1.13Z" fill={fill}/>
    </g>
  </svg>
)

export default NoShow
