import React from 'react'
import { Spinner, Typography } from '@telavita-core/react-design-kit'

import ModalGeneric from '../ModalGeneric'
import { 
  CancelationReasonInfo,
  CanceledByInfo, 
  PatientInfo, 
  ProfessionalInfo, 
  ScheduleInfo 
} from './components'

import { useCanceledScheduleDetailService } from './hooks/useCanceledScheduleDetailService'
import { CanceledScheduleDetailsModalProps } from './CanceledScheduleDetailsModal.types'

import * as S from './styles'

export const CanceledScheduleDetailsModal: React.FC<CanceledScheduleDetailsModalProps> = ({ scheduleId }) => {
  const { data, isFetching, isLoading, isError } = useCanceledScheduleDetailService(scheduleId)

  const shouldRenderCanceledByInfo = data?.canceledAt && data?.canceledBy

  return (
    <ModalGeneric 
      modalTitle=""
      feedbackShow={isError}
      feedbackMessage="Não foi possível carregar os dados da consulta"
      feedbackContent='Tente novamente em alguns instantes!'
      feedbackType="error"
    >
      <S.Container>
        <Typography
          customClassName='containerTitle' 
          color="danger" 
          variant="header4"
        >
        Consulta cancelada
        </Typography>

        {(isFetching || isLoading) && (
          <S.Loading>
            <Spinner />
            <Typography variant="content2">Carregando...</Typography>
          </S.Loading>
        )}

        <ProfessionalInfo 
          name={data?.professional?.fullName}
        />
      
        <PatientInfo 
          name={data?.patient?.fullName} 
          email={data?.patient?.email}
        />
      
        <ScheduleInfo 
          scheduleDate={data?.scheduleDate}
        />

        {
          shouldRenderCanceledByInfo
            ? (
              <CanceledByInfo 
                canceledBy={data?.canceledBy}
                cancelationDate={data?.canceledAt}
                canceledPerformerProfileCode={data?.canceledPerformerProfileCode}
              />
            )
            : null
        }

        <CancelationReasonInfo 
          cancelationReason={data?.reason}
          cancelationDescription={data?.details}
        />
      </S.Container>
    </ModalGeneric>
  )
}