import React from "react";

const Cancel = ({
  fill,
  height,
  viewBox = "0 0 20 20",
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={width} 
    height={height} 
    viewBox={viewBox}>
    <path fill={fill} d="M10,20a10.005,10.005,0,1,1,7.069-2.931A10.011,10.011,0,0,1,10,20Zm0-8.585h0l2.828,2.827,1.415-1.415L11.415,10l2.829-2.829L12.829,5.757,10,8.585,7.172,5.757,5.757,7.171,8.586,10,5.757,12.828l1.415,1.415L10,11.415Z"/>
  </svg>
);

export default Cancel;