import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { changePerson, hideModal } from 'redux/actions'
import { ACTIVE } from 'settings/_personSettings'

import Modal from '../Modal'
import NotificationStep from 'components/NotificationStep'

import { Typography } from '@telavita-core/react-design-kit'

const ActiveProfessionalModal = ({ 
  image, 
  name, 
  documentValue, 
  document,
  slug,
  role,
  reloadProfessionalList
}) => {

  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)

  async function handleSubmit() {

    setLoading(true)

    await dispatch(changePerson(slug, role, {
      status_code: ACTIVE
    }))
    reloadProfessionalList()

    setLoading(false)
    setSuccess(true)
  }

  return (
    <Modal
      modalTitle={!success ? 'Ativar profissional' : ''}
      btnText='ATIVAR PROFISSIONAL'
      handleClick={handleSubmit}
      hasButton={!success}
      isCentered
      btnLoading={loading}
      btnDisabled={loading}
      customClassName='ActiveProfessionalModal'
    >
      {
        success ? (
          <NotificationStep
            colWidth={12}
            btnText="FECHAR"
            mainText={
              <div className='__notification--success'>
                <Typography 
                  weight='bold'
                  variant="header3"
                  inlineStyles={{ marginBottom: '20px' }}
                >
                  Ativação concluída!
                </Typography>

                <Typography 
                  variant="content2"
                  center
                >
                  Profissional:
                </Typography>

                <Typography 
                  weight='bolder'
                  center
                  variant="content2"
                >
                  {name}
                </Typography>

                <Typography 
                  weight='bolder'
                  center
                  variant="content2"
                >
                  {`${document} ${documentValue}`}
                </Typography>
              </div>
            }
            onSuccessClick={() => dispatch(hideModal())}
            iconColor="#4ac326"
            iconName="Check"
            hasButton
          />
        ) : (
          <>
            <Typography 
              variant = 'content2' 
              center
              inlineStyles={{
                marginTop: '10px',
                marginBottom: '30px'
              }}
            >
              Ao prosseguir, a página deste profissional<br />voltará a ser exibida para pacientes.
            </Typography>

            <div 
              className='img-circular'
              style={{backgroundImage: `url(${image})`}}
            ></div>

            <Typography
              weight='bold'
              inlineStyles={{
                marginTop: '10px',
                marginBottom: '5px'
              }}
            >
              {name}
            </Typography>
            <Typography
              variant = 'content2' 
              inlineStyles={{marginBottom: '30px'}}
            >
              {`${document} ${documentValue}`}
            </Typography>

            <Typography
              variant = 'content2' 
              weight='bold'
              center
              inlineStyles={{marginBottom: '0'}}
            >
              Tem certeza que deseja ativar<br />este profissional?
            </Typography>
          </>
        )
      }
    </Modal>
  )
}

export default ActiveProfessionalModal