import React, { useEffect, useRef, useState } from 'react'
import { PopUpCalendar } from 'components/PopUpCalendar'
import { Icon } from '@telavita-core/react-design-kit'
import { FilterActionBtn, PlaceholderText } from './styles'
import { format, isSameDay } from 'date-fns'
export interface SelectedDate {
  initialDate: string
  endDate: string
}

interface CalendarFilterProps {
  onSelectedDate?: ({initialDate, endDate}: SelectedDate) => void
  calendarDate: Date[]
  handleChangeCalendarDate: (date: Date[]) => void
  showSelectedDateOnPlaceholder: boolean
  setShowSelectedDateOnPlaceholder?: (value: boolean) => void
}

export const CalendarFilter = ({ 
  onSelectedDate, 
  calendarDate,
  handleChangeCalendarDate,
  showSelectedDateOnPlaceholder,
  setShowSelectedDateOnPlaceholder
}: CalendarFilterProps): JSX.Element => {
  const [showPopUpCalendar, setShowPopUpCalendar] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const wrapperRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent | TouchEvent) {
      const buttonIsClicked = buttonRef.current?.contains(event.target as Node)
      const wrapperIsClicked = wrapperRef.current?.contains(event.target as Node)

      if (buttonRef.current && buttonIsClicked) {
        return setShowPopUpCalendar(prevState => !prevState)
      }

      if (wrapperRef.current && !wrapperIsClicked) {
        setShowPopUpCalendar(false)
      }
    }
    
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef, buttonRef])

  const onClickCancelCalendar = () => {
    setShowPopUpCalendar(false)
  }

  function handleApplyCalendar() {
    setShowPopUpCalendar(false)
    setShowSelectedDateOnPlaceholder && setShowSelectedDateOnPlaceholder(true)

    const initialDate = calendarDate[0]
    const endDate = calendarDate[1]

    onSelectedDate && onSelectedDate({
      initialDate: format(initialDate, 'dd/MM/yyyy'),
      endDate: calendarDate.length === 1 
        ? format(initialDate, 'dd/MM/yyyy')
        : format(endDate, 'dd/MM/yyyy'),
    })
  }

  const formatDate = (date: Date) => format(date, 'dd/MM/yyyy')

  const actionTextBtn = () => {
    if (!showSelectedDateOnPlaceholder) {
      return 'Selecione uma ou mais datas'
    }

    if (calendarDate.length > 1 && isSameDay(calendarDate[0], calendarDate[1])) {
      return formatDate(calendarDate[0])
    }

    return calendarDate.length === 1
      ? formatDate(calendarDate[0])
      : `${formatDate(calendarDate[0])} - ${formatDate(calendarDate[1])}`
  } 

  return (
    <>
      <FilterActionBtn ref={buttonRef}>
        <PlaceholderText 
          variant='content2' 
          hasLength={showSelectedDateOnPlaceholder}
        >
          {actionTextBtn()}
        </PlaceholderText>
        <Icon 
          name="Calendar" 
          fill="#4B4B4B" 
          width={20} 
          height={20}
          inlineStyles={{
            width: 'auto'
          }}
        />
      </FilterActionBtn>

      {showPopUpCalendar && (
        <div ref={wrapperRef}>
          <PopUpCalendar
            onClickOutside={() => null}
            visible={showPopUpCalendar}
            placement="bottom"
            handleChangeDate={handleChangeCalendarDate}
            selectedDate={calendarDate}
            onApplyDate={handleApplyCalendar}
            handleClose={onClickCancelCalendar}
          />
        </div>
      )}
    </>
  )
}