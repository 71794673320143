import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { CPF } from 'gerador-validador-cpf/dist/js/CPF'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Typography, Button, Column, Container, TextInput } from 'components'
import { modalTypes } from '../../../../containers/ModalManager/ModalManager'
import Dropdown from '../../../../components/Dropdown/index'
import Row from '../../../../components/Row'
import Toast from '../../../../components/Toast'

import viewportHoc from '../../../../hoc/viewportHoc'
import InputValidation from '../../../../utils/InputValidation'
import axios from '../../../../settings/axios'
import { apiEndpoints } from '../../../../settings/_apiSettings'
import { history } from '../../../../routers/AppRouter'
import * as routeSettings from '../../../../settings/_routesSettings'
import { ACTIVE } from '../../../../settings/_personSettings'
import * as actions from '../../../../redux/actions'

import { GENDER } from '../../../../settings/_personSettings'

const genderOptions = GENDER
const plans = [{ code: 'PLN01000', id: 37, name: 'Particular' }]
const fieldError = {
  email: 'Email',
  cellphone_number: 'Telefone celular',
  cpf: 'CPF'
}

const ParticularPatient = props => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const [submit, setSubmit] = useState({ ready: false, sucess: false, error: '' })
  const [form, setForm] = useState({
    full_name: {
      value: '',
      isValid: false,
      message: ''
    },
    email: {
      value: '',
      isValid: false,
      message: ''
    },
    gender: {
      value: '',
      isValid: false,
      message: ''
    },
    cpf: {
      value: '',
      isValid: false,
      message: ''
    },
    date_birth: {
      value: '',
      isValid: false,
      message: ''
    },
    cellphone_number: {
      value: '',
      isValid: false,
      message: ''
    },
    phone_number: {
      value: '',
      isValid: true,
      message: ''
    }
  })
  
  useEffect(() => {
    const {full_name: name, email, gender, cpf, date_birth: birth, cellphone_number: cellphone } = form
    let readyToSubmit = name.isValid && email.isValid && gender.isValid && cpf.isValid && birth.isValid && cellphone.isValid
  
    setSubmit(prevSubmit => ({
      ...prevSubmit,
      ready: readyToSubmit
    }))
  }, [form])

  const handleSelectGender = code => {
    setForm(prevForm => ({
      ...prevForm,
      gender: {
        ...prevForm.gender,
        value: code,
        isValid: code !== ''
      }
    }))
  }

  const handleNameInput = e => {
    e.preventDefault()
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'name').validate()

    setForm(prevForm => ({
      ...prevForm,
      full_name: {
        ...prevForm.full_name,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    }))
  }

  const handleEmailInput = e => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'email').validate()

    setForm(prevForm => ({
      ...prevForm,
      email: {
        ...prevForm.email,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    }))
  }

  const handleCpfInput = e => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'cpf').validate()

    setForm(prevForm => ({
      ...prevForm,
      cpf: {
        ...prevForm.cpf,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    }))
  }

  const handleBirthdateInput = e => {
    const inputValue = e.target.value
    const minDate = '01/01/1900'
    const validateOptions = {
      minDate: minDate,
      maxDate: moment()
    }
    let validation = new InputValidation(inputValue, 'date', validateOptions).validate()

    setForm(prevForm => ({
      ...prevForm,
      date_birth: {
        ...form.date_birth,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    }))
  }

  const handlePhoneInput = (field, value) => {
    setForm(prevForm => ({
      ...prevForm,
      [field]: {
        ...prevForm.field,
        value: value,
        isValid: value ? isValidPhoneNumber(value) : false,
        message: value ? isValidPhoneNumber(value) ? '' :'O número de telefone é inválido' : ''
      }
    }))
  }

  const handleErrorOnSubmit = error => {
    let errorMessage = ''

    if (error.status === 409) {
      const field = error.data.messages[0]?.field
      errorMessage = `${fieldError[field]} já cadastrado.`
    } else {
      errorMessage = 'Não foi possível criar o paciente.'
    }

    setSubmit(prevSubmit => ({
      ready: false,
      sucess: false,
      error: errorMessage
    }))
  }

  const formatPhone = phone => phone.includes('+', 0) ? phone.substr(1) : phone

  const onSubmit = async() => {
    setSubmit({ ready: false, sucess: false, error: '' })

    const nameSpread = form.full_name.value.split(' ')
    const firstName = nameSpread.splice(0, 1).join('')
    const lastName = nameSpread.join(' ')
    const cpf = CPF.format(form.cpf.value, 'digits')
    const token = await executeRecaptcha('signup')

    let postData = {
      email: form.email.value,
      first_name: firstName,
      last_name: lastName,
      gender: form.gender.value,
      cpf,
      date_birth: form.date_birth.value,
      cellphone_number: formatPhone(form.cellphone_number.value),
      phone_number: formatPhone(form.phone_number.value),
      status_code: ACTIVE,
      recaptcha_token: token
    }

    const modalContent = {
      modalType: modalTypes.NEW_USER_SUCCESS,
      modalData: {
        btnText: 'Fechar',
        modalTitle: 'Perfil adicionado com sucesso!',
        handleClick: () => {
          history.push(routeSettings.DASHBOARD_CLIENT_LIST)
          props.onHideModal()
        },
        modalChildren: (
          <Row padding='1rem 0 0' justify='center'>
            <Typography bold align='center'>
              {form.full_name.value}
            </Typography>
          </Row>
        )
      }
    }

    axios.post(apiEndpoints.PATIENTS_PARTICULAR, postData)
      .then(response => props.onShowModal(modalContent.modalType, modalContent.modalData))
      .catch(err => handleErrorOnSubmit(err.response))
      
    return true
  }

  return (
    <Container>
      <Row
        align='center'
        justify={props.isDesktop ? 'space-between' : 'center'}
        margin={props.isDesktop ? '3.9rem 0 2.1rem' : '2.5rem 0 1.5rem'}>
        <Typography align='mcenter-dleft' Tag='h1' type='title'> Adicionar Paciente </Typography>
      </Row>

      <Row>
        <Row>
          <Row padding=' 0 0 2rem 0'>
            <Typography Tag='h2' align='mcenter-dleft'> Dados pessoais </Typography>
          </Row>

          <Row>
            <Column colWidth={6}>
              <TextInput
                placeholder='E-mail*'
                required={true}
                name='email'
                hasError={!form.email.isValid && form.email.value !== ''}
                inputHeight='4.8rem'
                messageError={form.email.message}
                onInputChange={e => handleEmailInput(e)}
                value={form.email.value}
                onKeyPress={(e) => e.key === ' ' && e.preventDefault()}
              />
            </Column>

            <Column colWidth={6}>
              <TextInput
                placeholder='Nome completo*'
                required={true}
                name='full_name'
                hasError={!form.full_name.isValid && form.full_name.value !== ''}
                inputHeight='4.8rem'
                messageError={form.full_name.message}
                onInputChange={e => handleNameInput(e)}
                value={form.full_name.value}
              />
            </Column>

            <Column colWidth={6}>
              <Dropdown
                options={genderOptions}
                onSelect={code => handleSelectGender(code)}
                placeholder='Gênero*'
                name='gender'
                selectedItem={form.gender.value}
                preSelectedItem={form.isNewUser ? null : form.gender.value}
              />
            </Column>

            <Column colWidth={6}>
              <TextInput
                placeholder='CPF*'
                required={true}
                name='cpf'
                hasError={!form.cpf.isValid && form.cpf.value !== ''}
                inputHeight='4.8rem'
                mask='111.111.111-**'
                messageError={form.cpf.message}
                onInputChange={e => handleCpfInput(e)}
                value={form.cpf.value}
              />
            </Column>

            <Column colWidth={6}>
              <TextInput
                placeholder='Data de nascimento*'
                required={true}
                name='date_birth'
                hasError={!form.date_birth.isValid && form.date_birth.value !== ''}
                inputHeight='4.8rem'
                messageError={form.date_birth.message}
                mask='11/11/1111'
                onInputChange={e => handleBirthdateInput(e)}
                value={form.date_birth.value}
              />
            </Column>

            <Column colWidth={6}>
              <PhoneInput
                id='cellphone_number'
                country='BR'
                limitMaxLength={true}
                placeholder='Telefone celular*'
                value={form.cellphone_number.value}
                onChange={value => handlePhoneInput('cellphone_number', value)}
                error={form.cellphone_number.message}
                flags={flags}
              />
            </Column>

            <Column colWidth={6}>
              <PhoneInput
                id='phone_number'
                country='BR'
                limitMaxLength={true}
                placeholder='Telefone comercial'
                value={form.phone_number.value}
                onChange={value => handlePhoneInput('phone_number', value)}
                error={form.phone_number.message}
                style={{ marginTop: '10px' }}
                flags={flags}
              />
            </Column>
          </Row>
        </Row>

        <Row>
          <Row padding='2rem 0'>
            <Typography Tag='h2' align='mcenter-dleft'>Convênio</Typography>
          </Row>

          <Column colWidth={6}>
            <Dropdown
              options={plans}
              onSelect={() => true}
              placeholder='Tipo de plano*'
              name='user_profile_partner_plan'
              preSelectedItem={plans[0].id}
            />
          </Column>

          <Column colWidth={6}>
            <TextInput
              disabled
              inputHeight='4.8rem'
              placeholder='Quantidade de consultas por ano'
              required={false}
              name='plan_max_appointments'
            />
          </Column>
        </Row>

        <Row margin='2.5rem 0'>
          <Typography italic color='grey-dark'> *Campos de preenchimento obrigatório</Typography>
        </Row>

        { submit.error !== '' && (<Row margin='0 0 1.5rem'><Toast icon='Info' message={submit.error} variant='error' /></Row>) }

        <Row>
          <Column colWidth={6}>
            <Button id='submit' disabled={!submit.ready} onButtonClick={() => onSubmit()} text='ADICIONAR PACIENTE' type='submit' />
          </Column>
        </Row>
      </Row>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onHideModal: () => dispatch(actions.hideModal()),
    onShowModal: (modalType, modalData) => dispatch(actions.showModal(modalType, modalData))
  }
}

export default connect(null, mapDispatchToProps)(viewportHoc(ParticularPatient))
