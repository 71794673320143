import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 40px;

  > h2 {
    flex: 2;
  }

  > div {
    flex: 3;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px;

    > div {
      width: 100%;
    }
  }
`

export const FilterContainer = styled.div`
  cursor: pointer;
`