import React, { useState, useEffect } from 'react'

import ScheduleContainer from '../ScheduleContainer'
import Dropdown from '../../components/Dropdown'
import Row from '../../components/Row'
import { InputList } from 'components'
import ResultNotFound from '../../components/ResultNotFound'

import { Specialities } from 'settings/_specialitiesSettings'
import { Typography } from '@telavita-core/react-design-kit'

const AdminScheduleContainer = ({
  frequency,
  professionals,
  availabilities,
  schedules,
  reservationDate,
  reservationExceptions,
  error,
  loading,
  loadingSubmit,
  loadingProfessionals,
  onSearch,
  onSubmit,
  onSelectProfessional,
  fromMonth,
  toMonth,
  maxSchedules,
  enableNewPatients,
  onMonthChange,
  psychiatryTreatmentStatusMessage,
  showTreatmentNotStartedMessage,
  eligibilityLoading,
  selectedSpeciality, 
  setSelectedSpeciality
}) => {

  const [selectedProfessional, setSelectedProfessional] = useState(null)
  const [professionalOptions, setProfessionalOptions] = useState([])

  useEffect(() => {
    if (!professionals) return

    setProfessionalOptions(
      professionals.map(professional => {
        return {
          id: professional.id,
          name: professional.person_general.full_name
        }
      })
    )
  }, [professionals])

  const onChangeProfessionalName = name => {
    onSearch(selectedSpeciality, name)
    setSelectedProfessional(null)
    onSelectProfessional(null)
  }

  const onChangeProfessionalSelected = item => {
    if (item) {
      handleSelectProfessional(item.id)
      setProfessionalOptions([])
    }
  }

  const handleSelectProfessional = id => {
    const professional = professionals.find(professional => professional.id === id)

    setSelectedProfessional(professional)
    onSelectProfessional(professional)
  }

  const handleSpecialityDropdown = () => {
    return Specialities.filter((subspecialty) => {
      return {
        id: subspecialty.code,
        name: subspecialty.name
      }
    })
  }

  const handleSelectSpeciality = id => {
    const speciality = Specialities.find(speciality => speciality.code === id)

    setSelectedSpeciality(speciality)
  }
  const isPsychologist = selectedSpeciality && selectedSpeciality.code === 'PSIC'
  return (
    <div className='AdminScheduleContainer'>
      <div className='AdminScheduleContainer__Content'>
        <Row direction='column' margin='10px 0px 0px 0px'>
          <Dropdown
            dropDownOverflow
            dropDownItensToShow='3'
            name='specialities-list'
            options={handleSpecialityDropdown()}
            onSelect={id => handleSelectSpeciality(id)}
            placeholder='Selecione a especialidade'
          />
        </Row>
        {selectedSpeciality && (
          <Row direction='column' margin='10px 0px 30px 0px'>
            <InputList
              placeholder='Buscar profissional'
              onEnterPressed={onChangeProfessionalName}
              onChangeSelected={onChangeProfessionalSelected}
              options={professionalOptions}
              loading={loadingProfessionals}
            />
          </Row>
        )}
        {showTreatmentNotStartedMessage && !eligibilityLoading && (
          <div className='ModalAdminSchedule__Content__TreatmentNotStartedMessage'>
            <Typography variant='content2'>
              O paciente ainda não iniciou um tratamento com um{' '}
              <b>psicólogo</b>.
            </Typography>
            <Typography variant='content2'>
              Para iniciar o tratamento, ele deve clicar no botão{' '}
              <i>“Solicitar consulta com psicólogo”</i> em seu painel
              de convênio.
            </Typography>
          </div>
        )}
        {selectedProfessional && !showTreatmentNotStartedMessage &&(
          <Row direction='column'>
            <ScheduleContainer
              loading={loading}
              loadingSubmit={loadingSubmit}
              isAdminArea={true}
              fromMonth={fromMonth}
              toMonth={toMonth}
              availabilities={availabilities}
              schedules={schedules}
              maxSchedules={maxSchedules}
              frequency={frequency}
              reservationDate={isPsychologist && reservationDate ? reservationDate : null}
              reservationExceptions={reservationExceptions}
              error={error}
              onSubmit={(availabilities) => onSubmit(availabilities, selectedProfessional)}
              enableNewPatients={enableNewPatients}
              onMonthChange={onMonthChange}
              psychiatryTreatmentStatusMessage={psychiatryTreatmentStatusMessage}
            />
          </Row>
        )}
        {selectedSpeciality &&
          !loading &&
          professionals &&
          professionals.length === 0 &&
          !selectedProfessional && (
          <ResultNotFound
            iconName='Buildings'
            text='O convênio do paciente não atende a esta especialidade.'
          />
        )}
      </div>
    </div>
  )
}

export default AdminScheduleContainer
