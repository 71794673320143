import React from 'react'
import { Select } from '@telavita-core/react-design-kit'

import { useGetPlans } from './hooks/useGetPlans'

import * as S from '../styles'
import { useFilter } from 'views/Dashboard/Sections/AdminScheduleList/context/FilterContext'

interface Option {
  id: string | number
  label: string
}

export const Plans = (): JSX.Element => {
  const { data } = useGetPlans()
  const { state, dispatch } = useFilter()
  
  function findRelatedPlansOnSelectePlanGroup() {
    if (state.planGroupCode.id === 'all') return data
    const findedPlanGroup = data.filter(plan => plan.id === state.planGroupCode.id)
    return findedPlanGroup.flatMap(plan => plan.plans)
  }
  
  const relatedPlans = findRelatedPlansOnSelectePlanGroup()

  function handleSelectPlanGroup(planGroup: Option) {
    dispatch({ type: 'SET_PLAN_GROUP_CODE', payload: planGroup })
    dispatch({ type: 'SET_PLAN_CODE', payload: { id: 'all', label: 'Todos' } })
  }
  
  return (
    <S.FilterContainer>
      <Select 
        options={data}
        label='Convênios:'
        selected={state.planGroupCode}  
        onSelect={handleSelectPlanGroup}
      />
      
      {state.planGroupCode.id !== 'all' && (
        <Select 
          options={relatedPlans}
          label='Planos:'
          selected={state.planCode}  
          onSelect={plan => dispatch({ type: 'SET_PLAN_CODE', payload: plan })}
        />
      )}
    </S.FilterContainer>
  )
}