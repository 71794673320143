import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import { ReactComponent as SuccessIcon } from 'static/svg/green-check.svg'

import * as S from './styles'

interface RescheduleConfirmationProps {
  professionalName: string
  date: string
  time: string
}

export const RescheduleConfirmation: React.FC<RescheduleConfirmationProps> = ({ professionalName, date, time }) => {
  return (
    <S.Container>
      <SuccessIcon />
      <Typography center variant='header4' weight='bold'>
        Consulta reagendada!
      </Typography>
        
      <Typography center variant='content2'>
        Psicólogo(a) <span>{professionalName}</span>
      </Typography>
  
      <Typography center variant='content2' weight='bold'>
        Reagendada para {date}, às {time}
      </Typography>
    </S.Container>
  )
} 