import React from 'react'
import { ScheduleEvent } from 'components/ScheduleEvent'
import { format, roundToNearestMinutes } from 'date-fns'
import { DateDisplayer, List, Typography } from '@telavita-core/react-design-kit'

import { ReactComponent as IconDelete } from 'static/svg/icon_delete.svg'

const EventItem = ({ event, handleRemoveSingleSlot, handleRemoveUnavailability, actions }) => {

  if (event.event_type) {
    if (event.schedule && event.event_type === 'AGD21001') {
      return (
        <ScheduleEvent 
          event={event} 
          actions={actions}
        />
      )
    } else {
      return (
        <List.Item 
          key={event.id}
          ripple={
            <div className='ProfessionalAgenda__List__Item__Ripple'>
              <DateDisplayer
                date={new Date(event.start_date)}
                label='indisponível'
                variant='default'
                showDay={false}
                labelTextColor='#fff'
                hourTextColor='#fff'
                customClassName='ProfessionalAgenda__List__Item__Ripple__DateDisplayer'
              />
            </div>
          }
          content={
            <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
              <div className='ProfessionalAgenda__List__Item__Content'>
                <div>
                  <Typography variant='content2' weight='bold'>{event.title ? event.title : 'Compromisso pessoal'}</Typography>
                </div>
                <div className='ProfessionalAgenda__List__Item__PlanData'>
                  <Typography variant='content3'>Das {format(new Date(event.start_date), 'HH')}h às {format(roundToNearestMinutes(new Date(event.end_date), { nearestTo: 5 }), 'HH')}h</Typography>
                </div>
              </div>
              {handleRemoveUnavailability && (
                <button 
                  className='ProfessionalAgenda__List__Item__ActionButton' 
                  onClick={handleRemoveUnavailability}
                >
                  <IconDelete />
                </button>
              )}
            </div>
          }
        />
      )
    }
  } else {
    return (
      <List.Item
        key={event.id}
        content={
          <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
            <div className='ProfessionalAgenda__List__Item__SlotData'>
              <Typography variant='content3' weight='bold'>
                  Disponibilidade avulsa
              </Typography>
              <Typography variant='content3'>
                {format(new Date(event.start_date), 'HH')}h às {format(roundToNearestMinutes(new Date(event.end_date)), 'HH')}h
              </Typography>
            </div>
            <button
              onClick={handleRemoveSingleSlot}
              className='ProfessionalAgenda__List__Item__ActionButton'
            >
              <IconDelete />
            </button>
          </div>
        }          
      />
    )
  }
}

export {
  EventItem
}