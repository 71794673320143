// action to load convenios and each respective plans
const loadConvenios = () => {
  return {
    type: 'LOAD_CONVENIOS'
  }
};

// action to store loaded data into reducer
const loadConveniosSuccess = response => {
  return {
    type: 'LOAD_CONVENIOS_SUCCESS',
    payload: response
  }
};

// action to set error status on reducer from request
const loadConveniosFailure = response => {
  return {
    type: 'LOAD_CONVENIOS_FAILURE',
    payload: response
  }
};

const changeConvenio = (value) => {
  return {
    type: 'CHANGE_CONVENIO',
    payload: value
  }
}

// action to store selected plan in reducer
const selectEntity = (entity, code) => {
  return {
    type: 'SELECT_ENTITY',
    entity: entity,
    code: code,
  }
};

// action to clear convenios state
const clearConvenios = () => ({ type: 'CLEAR_CONVENIOS' })

export {
  loadConvenios,
  loadConveniosSuccess,
  loadConveniosFailure,
  selectEntity,
  changeConvenio,
  clearConvenios
}