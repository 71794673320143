import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 10px 0 0 0;

  .dropdown {
    min-width: none;
    width: 100%;
  }
  
  .LinkPlanModal__FormTitle {
    align-self: center;
    margin-top: 10px;
  }

  .LinkPlanModal__Label {
    margin: 20px 0 30px 0;
    align-self: start;
    color: #898989;
  }

  svg {
    width: 50px; 
    height: 50px;
    margin-bottom: 10px;
    path {
      fill: #4AC326;
    }
  }

  .LinkPlanModalSuccess__SuccessTitle {
    margin-bottom: 20px;
  }

  .LinkPlanModalSuccess__SuccessMessageWrapper {
    display: flex;
    gap: 15px;
    max-width: none;
    margin-bottom: 40px;
    padding: 20px;
  }

  .LinkPlanModal__WarningMessageWrapper {
    padding: 20px;
    gap: 20px;
    margin-top: 27px;
  }
`

export const PlanImage = styled.img`
  margin-top: 40px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  width: 130px;
  height: 70px;
`

export const FormWrapper = styled.div`
  width: 100%;
  margin-top: 20px;

  .LinkPlanModal__PlanDropdown {
    margin-bottom: 10px;
  }
`
