import React from 'react';
import { cellphoneMask } from 'utils';

// STYLE
import './PatientCard.sass';

// COMPONENTS
import { ReactComponent as NoPhoto} from 'static/svg/nophoto-patient.svg';

const PatientCard = ({ patient }) => {
  // LOCAL
  const patientGeneral = patient.person_general;

  return (
    <div className="PatientCard">
      {/* PATIENT PHOTO */}
      { !patient.photo
        ? <NoPhoto className="PatientCard__photo" />
        : <div className="PatientCard__photo" />
      }

      <div className="PatientCard__info">
        {/* PATIENT NAME */}
        <p className="PatientCard__text PatientCard__text--name">{patientGeneral.full_name}</p>

        {/* FIRST LINE */}
        <div className="PatientCard__row PatientCard__row--spaced">
          <p className="PatientCard__text PatientCard__text--appointments">
            {patient.consultations_held}&nbsp;
            {patient.consultations_held !== 1 ? 'consultas realizadas': 'consulta realizada'}
          </p>
          {/* FIRST LINE SECOND COLUMN */}
          <div className="PatientCard__row PatientCard__row--column">
            {/* <p className="PatientCard__text PatientCard__text--normal">{patient.plan}</p> */}
            <p className="PatientCard__text">ID {patient.id}</p>
          </div>
        </div>

        {/* SECOND LINE */}
        <div className="PatientCard__row">
          <p className="PatientCard__text">{patientGeneral.email}</p>
          <p className="PatientCard__text">{cellphoneMask(patientGeneral.cellphone_number)}</p>
        </div>
      </div>
    </div>
  )
}

export {
  PatientCard
}