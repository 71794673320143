import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import download from 'downloadjs'
import { format } from 'date-fns'


import axios from 'settings/axios'
import { TVTVADMN } from 'settings/_profileSettings'
import { apiEndpoints } from 'settings/_apiSettings'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'

// COMPONENTS
import FloatMenu from '../FloatMenu'
import { CoreIcons } from 'components'
import { ambToProcedure } from './proceduresMap'
import { toast, Typography } from '@telavita-core/react-design-kit'


class CardMedicalRequestComponent extends Component {

  state = { 
    isMenuOpen: false,
    isVisualizationLoading: false,
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  onClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ isMenuOpen: false })
    }
  }

  onMenuOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  noneValue = (
    <Typography italic variant='content3' inlineStyles={{ color: '#898989' }}>nenhum</Typography>
  );

  handleValue = (value) => {
    const { statusName } = this.props
    switch (statusName) {
    case 'Reprovado':
      return <span className="CardMedicalRequest--invalid">{value}</span>

    default:
      return value
    }
  }

  handleErrorToast = () => {
    toast({
      type: 'error',
      text: 'Você não possui permissão para visualizar o documento',
      position: 'top-right'
    })
  }

  viewMedicalRequest = async(e) => {
    e.preventDefault()

    try {
      this.setState({ isVisualizationLoading: true })

      const { medicalRequestLink } = this.props
      let params = {}

      if (medicalRequestLink.search('http') !== -1) {
        params.url = medicalRequestLink
      } else {
        params.key = medicalRequestLink
      }

      const response = await axios.get(apiEndpoints.DOWNLOAD_MEDICAL_REQUEST, { params })

      const buf = Buffer.from(response.content, 'base64')
      const blob = new Blob([buf])
      
      const extensionToMime = {
        '.pdf': 'application/pdf',
        '.jpg': 'image/jpeg',
        '.jpeg': 'image/jpeg',
        '.png': 'image/png',
      }
      
      const extension = response?.file_name.slice(response.file_name.lastIndexOf('.'))
      const type = extensionToMime[extension]
      
      if (type) {
        const file = new File([blob], response.file_name, { type })
      
        const fileURL = URL.createObjectURL(file)
      
        return window.open(fileURL, '_blank')
      }

    } catch(error) {
      this.handleErrorToast()
    } finally {
      this.setState({ isVisualizationLoading: false })
    }
  }

  downloadMedicalRequest = async(e) => {
    const { cardNumber } = this.props
    e.preventDefault()
    try {
      this.setState({ isLoadingDownload: true })
      
      const { medicalRequestLink } = this.props
      let params = {}

      if (medicalRequestLink.search('http') !== -1) {
        params.url = medicalRequestLink
      } else {
        params.key = medicalRequestLink
      }

      const response = await axios.get(apiEndpoints.DOWNLOAD_MEDICAL_REQUEST, { params })
    
      const fileExtension = response?.file_name.slice(response.file_name.lastIndexOf('.'))
      const currentTime = format(new Date(), 'HHmmss')
      const filename = `${cardNumber}_${currentTime}${fileExtension}`
    
      const buf = Buffer.from(response.content, 'base64')
      const blob = new Blob([buf])
      
      download(blob, filename)

    } catch (error) {
      this.handleErrorToast()
    } finally {
      this.setState({ isLoadingDownload: false })
    }
  }

  render() {
    const {
      cardNumber,
      extraInfo,
      lastProfessional,
      menuList,
      medicalRequestLink,
      medicalRequestId,
      planGroupName,
      planGroupCategory,
      planName,
      statusName,
      statusWeight,
      ambCode,
      fullName,
      username,
      availableCredits,
      maxCredits,
      startDate,
      endDate,
      planPassword,
      cid,
      professionalCrm,
      professionalUf
    } = this.props
    
    const startCredits = String(moment(startDate).format('DD/MM/YYYY'))
    const endCredits = String(moment(endDate).format('DD/MM/YYYY'))

    let baseClassName = 'CardMedicalRequest'

    if (this.state.isMenuOpen) baseClassName = baseClassName.concat('--open')

    return (
      <div className={baseClassName}
        id={`cardmedicalrequest-${medicalRequestId}`}>
        <div className="CardMedicalRequest__Data">
          <div className="CardMedicalRequest__Data__Block">
            <div className="CardMedicalRequest__Data__Block__Role">
              <Typography weight='bold' variant='content3'>PACIENTE</Typography>
            </div>
            <div className="CardMedicalRequest__Data__Block__Name">
              <Typography variant='content1'>{fullName}</Typography>
              <Typography variant='content3' inlineStyles={{ color: '#898989' }}>{username}</Typography>
            </div>
            <div className="CardMedicalRequest__insideGroup">
              <div className="CardMedicalRequest__Data__Block__Data">
                <div className="CardMedicalRequest__Data__Block__Data__Label">
                  <Typography variant='content3'>
                    Convênio:
                  </Typography>
                </div>
                <div className="CardMedicalRequest__Data__Block__Data__Value">
                  <Typography variant='content3'>
                    {`${planGroupName} ${planName}`}
                  </Typography>
                </div>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data">
                <div className="CardMedicalRequest__Data__Block__Data__Label">
                  <Typography variant='content3'>Carteirinha:</Typography>
                </div>
                <div className="CardMedicalRequest__Data__Block__Data__Value">
                  <Typography weight='bold' variant='content3'>{cardNumber}</Typography>
                </div>
              </div>
            </div>
            <div className="CardMedicalRequest__Data__Block__Tag">
              <div className={`CardMedicalRequest__Data__Block__Tag__Border--${statusWeight}`}>
                <Typography weight='bolder' variant='content3' inlineStyles={{ color: '#FFFFFF' }}>
                  {statusName.toUpperCase()}
                </Typography>
              </div>
            </div>
          </div>
          <div className="CardMedicalRequest__Data__Block">
            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                {this.props.isAdmin &&
                  <Typography variant='content3'>Av. Técnica:</Typography>
                }
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                {this.props.isAdmin ?
                  medicalRequestLink && medicalRequestLink !== '' ? (
                    <div className='CardMedicalRequest__Data__Block__Data__Actions'>
                      <Typography
                        weight='bold'
                        variant='content3'
                        color='primary'
                      >
                        {this.state.isLoadingDownload
                          ? <span>carregando...</span>
                          : <a href="#" onClick={this.downloadMedicalRequest}>Baixar</a>
                        }
                      </Typography>

                      <Typography
                        weight='bold'
                        variant='content3'
                        color='primary'
                      >
                        {this.state.isVisualizationLoading
                          ? <span>carregando...</span>
                          : <a href="#" onClick={this.viewMedicalRequest}>Visualizar</a>
                        }
                      </Typography>
                    </div>
                  ) : this.noneValue
                  : null}
              </div>
            </div>
            {planGroupCategory === PlanGroupCategoryEnum.ENTERPRISE ?
              <>
                <div className="CardMedicalRequest__Data__Block__Data">
                  <div className="CardMedicalRequest__Data__Block__Data__Label">
                    <Typography variant='content3'>CID:</Typography>
                  </div>
                  <div className="CardMedicalRequest__Data__Block__Data__Value">
                    <Typography variant='content3'>{cid ? cid : '-'}</Typography>
                  </div>
                </div>
                <div className="CardMedicalRequest__Data__Block__Data">
                  <div className="CardMedicalRequest__Data__Block__Data__Label">
                    <Typography variant='content3'>CRM:</Typography>
                  </div>
                  <div className="CardMedicalRequest__Data__Block__Data__Value">
                    <Typography variant='content3'>
                      {professionalCrm && professionalUf ? `${professionalUf} ${professionalCrm}` : '-'}
                    </Typography>
                  </div>
                </div>
              </>
              :
              <div className="CardMedicalRequest__Data__Block__Data">
                <div className="CardMedicalRequest__Data__Block__Data__Label">
                  <Typography variant='content3'>AMB:</Typography>
                </div>
                <div className="CardMedicalRequest__Data__Block__Data__Value">
                  {
                    ambCode ? (
                      <Typography variant='content3'>{ambCode}</Typography>
                    ) : this.noneValue
                  }
                </div>
              </div>
            }
            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Cód. Proced:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                {
                  ambCode ? (
                    <Typography variant='content3'>{ambToProcedure(ambCode)}</Typography>
                  ) : this.noneValue
                }
              </div>
            </div>

            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Créditos:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                <Typography variant='content3' weight='bold'>
                  <b>{this.handleValue(`${availableCredits} de ${maxCredits}`)}</b>
                </Typography>
              </div>
            </div>

            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Validado em:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                <Typography variant='content3'>
                  {startDate === null ? '-' : this.handleValue(startCredits)}
                </Typography>
              </div>
            </div>

            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Válido até:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                <Typography variant='content3'>
                  {endDate === null ? '-' : this.handleValue(endCredits)}
                </Typography>
              </div>
            </div>

            <div className="CardMedicalRequest__Data__Block__Data">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Senha:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                <Typography variant='content3'>
                  {planPassword ? planPassword : '-'}
                </Typography>
              </div>
            </div>

            <div className="CardMedicalRequest__Data__Block__Data--spaced">
              <div className="CardMedicalRequest__Data__Block__Data__Label">
                <Typography variant='content3'>Última Consulta:</Typography>
              </div>
              <div className="CardMedicalRequest__Data__Block__Data__Value">
                {
                  lastProfessional && lastProfessional !== '' ? (
                    <Typography variant='content3'>
                      {lastProfessional}
                    </Typography>
                  ) : this.noneValue
                }</div>
            </div>
          </div>
        </div>

        {menuList && menuList.length > 0 &&
          <div
            className="CardMedicalRequest__Menu"
            id={`cardmedicalrequest-menu--${medicalRequestId}`}
            ref={node => this.wrapperRef = node}
          >
            <span
              className="CardMedicalRequest__Menu__Icon"
              onClick={this.onMenuOpen}
            >
              <CoreIcons
                name="MoreOptions"
                fill="#4b4b4b"
                height="45px"
                width="45px"
              />
            </span>
            {
              this.state.isMenuOpen &&
              <FloatMenu
                className="CardMedicalRequest__Menu__FloatMenu"
                id={`cardmedicalrequest-menu-floatmenu--${medicalRequestId}`}
                itemList={menuList}
              />
            }
          </div>
        }

        {extraInfo &&
          <div className="CardMedicalRequest__Menu">
            <span
              className="CardMedicalRequest__Menu__Icon"
              id={`cardmedicalrequest-menu--${medicalRequestId}`}
              onClick={() => extraInfo.onClick && extraInfo.onClick()}
              ref={node => this.wrapperRef = node}
            >
              <CoreIcons
                name={extraInfo.iconName || 'Info'}
                fill="#4b4b4b"
                height="45px"
                width="45px"
              />
            </span>
          </div>
        }

      </div>
    )
  }
}

CardMedicalRequestComponent.propTypes = {
  cardNumber: PropTypes.string,
  extraInfo: PropTypes.object,
  lastProfessional: PropTypes.string,
  medicalRequestId: PropTypes.number,
  medicalRequestLink: PropTypes.string,
  menuList: PropTypes.array,
  planName: PropTypes.string,
  statusName: PropTypes.string,
  statusWeight: PropTypes.string,
  ambCode: PropTypes.string,
  fullName: PropTypes.string,
  username: PropTypes.string,
}

const mapStateToProps = state => {
  return {
    isAdmin: state.auth.profileSelected.profile_code === TVTVADMN,
  }
}

const CardMedicalRequest = connect(mapStateToProps)(CardMedicalRequestComponent)

export { CardMedicalRequest }
