import React, { useEffect, useState } from 'react'
import { format } from 'date-fns'
import pt from 'date-fns/locale/pt'

import InfiniteScroll from 'react-infinite-scroll-component'

import ProfessionalsService, { ReviewStatus, Review as IReview } from 'services/professionals'

import { Typography, useDimensions, toast, Button } from '@telavita-core/react-design-kit'
import Tabs from 'components/Tabs'
import { LabelOptions, Review, ReviewLoading, ListContainer } from 'components/Review'
import { HelpModal } from 'containers/ModalManager/Modals/HelpModal'

import BallonCommentIcon from 'components/CoreIcons/Icons/BallonComment'
import HelpIcon from 'components/CoreIcons/Icons/Help'

import { questions } from './questions'

import {
  Container,
  Header,
  EmptyReviews,
} from './styles'

enum TabsOptions {
  RECEIVED = 'Recebidos',
  ARCHIVED = 'Arquivados',
  PUBLISHED = 'Publicados',
}

interface Tab {
  title: string
  code: TabsOptions
  quantity?: number
}

export function Reviews(): JSX.Element {

  const { isMobile } = useDimensions()

  const { RECEIVED, ARCHIVED, PUBLISHED } = TabsOptions

  const [selectedTab, setSelectedTab] = useState<TabsOptions>(RECEIVED)
  const [reviews, setReviews] = useState<IReview[]>([])
  const [page, setPage] = useState(0)
  const [totalReviews, setTotalReviews] = useState(0)
  const [loadingTotals, setLoadingTotals] = useState(true)
  const [loadingReviews, setLoadingReviews] = useState(true)
  const [loadingReview, setLoadingReview] = useState<number|null>(null)
  const [helpModal, setHelpModal] = useState(false)
  const [tabs, setTabs] = useState<Tab[]>(() => 
    Object.values(TabsOptions).map(tab => ({
      title: tab,
      code: tab,
    }))
  )

  useEffect(() => {
    void ProfessionalsService.markAllReviewsAsViewed()
    
    async function getReviewsCount() {
      const { totalArchived, totalReceived, totalPublished } = await ProfessionalsService.reviewsCount()

      const newTabs = Object.values(TabsOptions).map(tab => {
        const total = () => {
          switch (tab) {
          case ARCHIVED: return totalArchived
          case PUBLISHED: return totalPublished
          case RECEIVED: return totalReceived
          }
        }
        
        return {
          title: tab,
          code: tab,
          quantity: total()
        }
      })

      setTabs(newTabs)
      setLoadingTotals(false)
    }

    void getReviewsCount()

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if(loadingTotals) return

    setLoadingReviews(true)
    setReviews([])

    async function getReviews() {
      const { reviews, pagination } = await ProfessionalsService.listReviews({
        status: status(selectedTab),
      })
      
      const tabIndex = tabs.findIndex(tab => tab.code === selectedTab)

      const newTabs = tabs.map(tab => ({ ...tab }))
      newTabs[tabIndex].quantity = pagination.total

      setTabs(newTabs)
      setReviews(reviews)
      setPage(pagination.page)
      setTotalReviews(pagination.total)
      setLoadingReviews(false)
    }

    void getReviews()

    //eslint-disable-next-line
  }, [selectedTab, loadingTotals])

  const status = (tab: TabsOptions) => {
    switch (tab) {
    case ARCHIVED: return ReviewStatus.ARCHIVED
    case PUBLISHED: return ReviewStatus.PUBLISHED
    case RECEIVED: return ReviewStatus.RECEIVED
    }
  }

  async function loadMoreReviews() {
    const response = await ProfessionalsService.listReviews({
      status: status(selectedTab),
      page: page + 1,
    })

    setReviews(prevState => [
      ...prevState,
      ...response.reviews,
    ])
    setPage(response.pagination.page)
  }

  async function changeReview(reviewId: number, destinyTab: TabsOptions) {
    await ProfessionalsService.changeReviewStatus({
      reviewId,
      newStatus: status(destinyTab),
    })

    const newReviews = reviews.filter(review => review.id !== reviewId)
    const tabActualIndex = tabs.findIndex(tab => tab.code === selectedTab)
    const tabDestinyIndex = tabs.findIndex(tab => tab.code === destinyTab)

    const newTabs = tabs.map(tab => ({ ...tab }))
    newTabs[tabDestinyIndex].quantity += 1 
    newTabs[tabActualIndex].quantity -= 1

    const newTotal = totalReviews - 1

    setReviews(newReviews)
    setTabs(newTabs)
    setTotalReviews(newTotal)
  }

  async function handlePublishReview(reviewId: number) {
    try {
      await changeReview(reviewId, PUBLISHED)

    } catch (error) {
      console.error(error)
    }
  }

  async function handleToArchiveReview(reviewId: number) {
    setLoadingReview(reviewId)

    try {
      await changeReview(reviewId, ARCHIVED)
  
      toast({
        text: 'Depoimento arquivado com sucesso!',
        type: 'success',
      })

      setLoadingReview(null)

    } catch (error) {
      console.error(error)
      setLoadingReview(null)
    }
  }

  async function handleUnpublishReview(reviewId: number) {
    try {
      await changeReview(reviewId, RECEIVED)

    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <Container>
        
        <Header>
          <Typography 
            variant={isMobile ? 'header3' : 'header2'}
            center={isMobile}
            inlineStyles={{
              width: '100%',
            }}
          >
            {isMobile ? 'Depoimentos' : 'Depoimentos de pacientes'}
          </Typography>

          <Button
            uppercase={false}
            customClassName='help-button'
            inlineStyles={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              right: 0,
              top: '50%',
              transform: 'translateY(-50%)',
            }}
            onClick={() => setHelpModal(true)}
          >
            <HelpIcon
              fill = '#1F35B5'
              height = '24'
              width = '24'
              viewBox = '0 0 24 24'
            />
            {
              !isMobile && (
                <Typography 
                  variant='content2'
                  color='primary'
                  weight='bolder'
                  inlineStyles={{
                    paddingLeft: '5px',
                  }}
                >
                  Ajuda
                </Typography>
              )
            }
          </Button>
        </Header>

        <Tabs
          tabs={tabs}
          tabId='reviews'
          selectedItem={selectedTab}
          onSelectTab={(tab: TabsOptions) => setSelectedTab(tab)}
          style={{ marginBottom: '15px' }}
        />

        { 
          (!reviews.length && !loadingReviews && !loadingTotals) && (
            <EmptyReviews>
              <BallonCommentIcon 
                fill = '#c1c1c1'
                height = '52'
                width = '52'
                viewBox = '0 0 52 52'
              />
              <Typography
                variant='content2'
                inlineStyles={{ color: '#c1c1c1' }}
              >
                {`Nenhum depoimento ${selectedTab.slice(0, -1).toLowerCase()}`}
              </Typography>
            </EmptyReviews>
          )
        }

        <InfiniteScroll
          dataLength={reviews.length}
          hasMore={(totalReviews > reviews.length || loadingReviews)}
          loader={
            <>
              <ReviewLoading hasActions isMobile={isMobile} />
              <ReviewLoading hasActions isMobile={isMobile} />
              <ReviewLoading hasActions isMobile={isMobile} />
              <ReviewLoading hasActions isMobile={isMobile} />
              <ReviewLoading hasActions isMobile={isMobile} />
            </>
          }
          next={loadMoreReviews}
        >
          <ListContainer>
            {
              reviews.map((review) => {
                const labelStatus = (): LabelOptions => {
                  switch (review.status) {
                  case ReviewStatus.PUBLISHED: return LabelOptions.PUBLISHED
                  case ReviewStatus.ARCHIVED: return LabelOptions.ARCHIVED
                  }
                }
                
                return (
                  <Review
                    key={review.id}
                    reviewId={review.id} 
                    label={!review.viewed ? LabelOptions.VIEWED : labelStatus()}
                    author={review.author}
                    createdAt={format(review.createdAt, 'LLLL yyyy', { locale: pt })}
                    review={review.review}
                    loadingBtn={review.id === loadingReview}
                    actions
                    onPublish={handlePublishReview}
                    onUnpublish={handleUnpublishReview}
                    onToArchive={handleToArchiveReview}
                  />
                )
              })
            }
          </ListContainer>
        </InfiniteScroll>

      </Container>
      {helpModal && (
        <HelpModal 
          title='Tire suas dúvidas sobre os depoimentos'
          questions={questions}
          onClose={() => setHelpModal(false)}
        />
      )}
    </>
  )
}