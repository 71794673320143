import { CLEAN_SCHEDULES, GET_SCHEDULES, GET_SCHEDULE_LOGS, START_LOADING_SCHEDULES, ADD_SCHEDULE, REMOVE_SCHEDULE, UPDATE_SCHEDULE } from '../actions/_actionTypes'

import { updateState } from '../helpers/utilityHelpers'

const initialState = { 
  schedules: [], 
  pagination: {
    pages: 1,
    page: 0,
    per_page: 0,
    total: 0
  }, 
  concatedSchedules: [],
  loading: false,
  success: false
}

const updateSchedule = (state, payload) => {
  const schedules = [...state.concatedSchedules]
  const index = schedules.findIndex(schedule => schedule.id === payload.id)
  schedules[index] = payload
  
  const diffState = {
    ...state,
    concatedSchedules: schedules
  }

  return updateState(state, diffState)
}

const getSchedules = (state, payload) => {
  const allSchedules = [...state.schedules, ...payload.schedules]

  const uniqueSchedules = allSchedules.filter((value, index, self) =>
    self.findIndex(el => el.id === value.id) === index
  )

  let diffState = {
    schedules: uniqueSchedules,
    pagination: payload.pagination,
    loading: false,
    success: true
  }

  // NOTA: fix para evitar requisições/resultados duplicados na concatenação de consultas (infinite scroll).
  // Se a paginação atual do reducer for igual a paginação atual obtida do backend, então não é realizada a
  // concatenação e somente é realizada a sobrescrita da variável.
  if(payload.pagination && payload.pagination.page && state.pagination && state.pagination.page && payload.pagination.page !== state.pagination.page){
    diffState.concatedSchedules = [ ...state.concatedSchedules, ...payload.schedules ]
  }else{
    diffState.concatedSchedules = payload.schedules
  }

  return updateState(state, diffState)
}

const getScheduleLogs = (state, payload) => {
  return updateState(state, { 
    scheduleLog: payload
  })
}

export const cleanSchedules = (state) => {
  return updateState(state, initialState)
}

const startSchedulesLoading = (state) => {
  return updateState(state, { loading: true })
}

const addNewSchedule = (state, payload) => {
  const diffState = {
    ...state,
    schedules: [...state.schedules, payload.schedule]
  }
  return updateState(state, diffState)
}

const removeSchedule = (state, payload) => {
  const diffState = {
    ...state,
    schedules: state.schedules.filter(schedule => schedule.id !== payload.id)
  }
  return updateState(state, diffState)
}

const schedulesReducer = (state = initialState, action) => {
  switch (action.type) {
  case CLEAN_SCHEDULES: return cleanSchedules(state)
  case GET_SCHEDULES: return getSchedules(state, action.payload)
  case GET_SCHEDULE_LOGS: return getScheduleLogs(state, action.payload)
  case START_LOADING_SCHEDULES: return startSchedulesLoading(state)
  case ADD_SCHEDULE: return addNewSchedule(state, action.payload)
  case REMOVE_SCHEDULE: return removeSchedule(state, action.payload)
  case UPDATE_SCHEDULE: return updateSchedule(state, action.payload)
  default: return state
  }
}

export default schedulesReducer