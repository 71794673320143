import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { CoreIcons } from 'components';

function SearchPlaceholder({ iconName, iconHieght, iconWidth, text, titleText, viewBox, phrase, fill }) {
  return (
    <div className='SearchPlaceholder'>
      <div className='SearchPlaceholder__icon'>
        <CoreIcons
          name={iconName}
          viewBox={viewBox}
          height={iconHieght}
          width={iconWidth}
          fill={fill}
        />
      </div>
      <div className='SearchPlaceholder__title'>
        {titleText}
      </div>
      <div className='SearchPlaceholder__title'>
        {phrase}
      </div>
      <div className='SearchPlaceholder__text'>
        {text}
      </div>

    </div>
  );
}

SearchPlaceholder.propTypes = {
  iconName: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHieght: PropTypes.string,
  titleText: PropTypes.string,
  text: PropTypes.string,
  phrase: PropTypes.string,
  fill: PropTypes.string

};
SearchPlaceholder.defaultProps={
  iconName: 'MagnifyingGlass',
  iconWidth: '59.278',
  iconHieght: '59.305',
  viewBox: "0 0 59.278 59.305",
};

export default SearchPlaceholder;
