import { 
  SCHEDULED, 
  ATTENDED, 
  UNATTENDED, 
  PROFESSIONAL_NO_SHOW, 
  PATIENT_NO_SHOW, 
  SCHEDULE_NO_PARTICIPANTS 
} from 'settings/_scheduleStatusSettings'
import * as permissions from 'settings/_permissionSettings'
import { ADMINISTRATOR } from 'settings/_profileSettings'
import { modalTypes } from 'containers/ModalManager/ModalManager'
import { hasPermission } from 'utils/permissionHandlers'

import { showModal } from 'redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { FormattedAdminScheduleData, PatientData } from '../types'
import { formatScheduleForRescheduleModal } from '../helpers/formatScheduleData'

const statusesToShowScheduleDetailsOption = [
  ATTENDED,
  SCHEDULED,
  UNATTENDED,
  PROFESSIONAL_NO_SHOW,
  PATIENT_NO_SHOW,
  SCHEDULE_NO_PARTICIPANTS
]

const statusesToShowChangeStatusOption = [
  ATTENDED,
  UNATTENDED,
  PROFESSIONAL_NO_SHOW,
  PATIENT_NO_SHOW,
  SCHEDULE_NO_PARTICIPANTS
]

interface AuthState {
  auth: {
    profilePermissions: string[]
  }
}

interface BuildMenuList {
  schedule: FormattedAdminScheduleData
  handlePatientData: (patientData: PatientData) => void
}

interface MenuList {
  code: string
  name: string
  func: () => void
}

interface UseBuildMenuList {
  handleBuildMenuList: ({ schedule, handlePatientData}: BuildMenuList) => MenuList[]
}

export const useBuildMenuList = (): UseBuildMenuList => {
  const userPermissions = useSelector((state: AuthState) => state.auth.profilePermissions)
  const dispatch = useDispatch()

  function handleBuildMenuList({ schedule, handlePatientData}: BuildMenuList) { 
    const patientData = schedule.client.person_profile.person.person_general
    const patientUsername = schedule.client.person_profile.person.username
    const formattedDataForRescheduleModal = formatScheduleForRescheduleModal(schedule)
  
    const menuList: MenuList[] = []
  
    const cancelModalData = {
      scheduleData: schedule,
      scheduleId: schedule.id,
      classModifier: 'false',
      hasButton: true,
      btnText: 'CONFIRMAR CANCELAMENTO',
      modalTitle: 'Cancelar consulta',
      btnId: 'modal-btn',
      titleColor: 'black',
      notificationText: '*Ao cancelar a consulta, o valor será estornado integralmente na próxima fatura do seu cartão de crédito.'
    }
  
    const rescheduleData = {
      dashboard: ADMINISTRATOR,
      client: formattedDataForRescheduleModal.client,
      professional: formattedDataForRescheduleModal.professional,
      schedule: {
        ...formattedDataForRescheduleModal.scheduleData,
        date: schedule.date,
        time: schedule.time,
        isoDate: schedule.isoDate
      },
      product: formattedDataForRescheduleModal.productData,
    }
   
    if (schedule.status === SCHEDULED && hasPermission(userPermissions, permissions.ADMIN_RESCHEDULE)) {
      menuList.push({
        code: 'reschedule-appointment',
        name: 'Reagendar',
        func: () =>
          dispatch(showModal(modalTypes.RESCHEDULE, rescheduleData))
      })
    }
  
    if (schedule.status !== ATTENDED && hasPermission(userPermissions, permissions.ADMIN_CANCEL_SCHEDULE)) {
      menuList.push({
        code: 'cancel-appointment',
        name: 'Cancelar',
        func: () =>
          dispatch(showModal(modalTypes.ADMIN_CANCEL, cancelModalData))
      })
    }
  
    if ((statusesToShowScheduleDetailsOption.includes(schedule.status)) &&
      hasPermission(userPermissions, permissions.ADMIN_VIEW_SCHEDULE_LOGS)) {
      menuList.push({
        code: 'schedule-detail',
        name: 'Detalhes da consulta',
        func: () => dispatch(showModal(modalTypes.LOGS_VIRTUAL_ROOM, { virtualRoomHash: schedule.virtualRoomHash }))
      })
    }
  
    if ((statusesToShowChangeStatusOption.includes(schedule.status)) && hasPermission(userPermissions, permissions.ADMIN_CHANGE_SCHEDULE)) {
      menuList.push({
        code: 'change-status',
        name: 'Mudar status',
        func: () => dispatch(showModal(modalTypes.ADMIN_CHANGE_SCHEDULE_STATUS, schedule))
      })
    }
  
    menuList.push({
      code: 'patient-detail',
      name: 'Detalhes do paciente',
      func: () => handlePatientData({
        name: patientData.full_name,
        email: patientUsername,
        cpf: patientData.cpf,
        phone: patientData.cellphone_number
      }),
    })  
        
    return menuList
  }

  return { handleBuildMenuList }
}