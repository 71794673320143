import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import moment from 'moment'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { CPF } from 'gerador-validador-cpf/dist/js/CPF'

import { Typography, Button , Column, Container, TextInput } from 'components'
import Dropdown from '../../../../components/Dropdown/index'
import Row from '../../../../components/Row'
import Toast from '../../../../components/Toast'

import axios from '../../../../settings/axios'
import InputValidation from '../../../../utils/InputValidation'
import { getFullPlansName } from '../../../../utils/plansHandlers'
import { apiEndpoints } from '../../../../settings/_apiSettings'
import { GENDER } from '../../../../settings/_personSettings'
import * as actions from '../../../../redux/actions'
import * as profiles from '../../../../settings/_profileSettings'
import { ACTIVE } from '../../../../settings/_personSettings'
import * as routeSettings from '../../../../settings/_routesSettings'
import { history } from '../../../../routers/AppRouter'
import { modalTypes } from '../../../../containers/ModalManager/ModalManager'
import viewportHoc from '../../../../hoc/viewportHoc'

class PartnerPatient extends Component {
  state = {
    userRole: profiles.PATIENT,
    isMenuOpen: undefined,
    isNewUser: true,
    hasPartnerProfile: false,
    readyToSubmit: false,
    successSubmit: true,
    errors: [],

    user_plan_id: undefined,
    plan_max_appointments: '',
    partner_plan_list: [],

    full_name: {
      value: '',
      isValid: true,
      message: ''
    },
    email: {
      value: '',
      isValid: true,
      message: ''
    },
    gender: {
      value: '',
      isValid: true,
      message: ''
    },
    cpf: {
      value: '',
      isValid: true,
      message: ''
    },
    date_birth: {
      value: '',
      isValid: true,
      message: ''
    },
    cellphone_number: {
      value: '',
      isValid: true,
      message: ''
    },
    phone_number: {
      value: '',
      isValid: true,
      message: ''
    },
    partner_plan: {
      value: '',
      isValid: true,
      message: ''
    },
    user_profile_partner_plan: {
      value: '',
      isValid: true,
      message: ''
    },
    card_number: {
      value: '',
      isValid: true,
      message: ''
    }
  }

  initialFormState = {
    full_name: {
      value: '',
      isValid: true,
      message: ''
    },
    gender: {
      value: '',
      isValid: true,
      message: ''
    },
    cpf: {
      value: '',
      isValid: true,
      message: ''
    },
    date_birth: {
      value: '',
      isValid: true,
      message: ''
    },
    cellphone_number: {
      value: '',
      isValid: true,
      message: ''
    },
    phone_number: {
      value: '',
      isValid: true,
      message: ''
    },
    partner_plan: {
      value: '',
      isValid: true,
      message: ''
    },
    user_profile_partner_plan: {
      value: '',
      isValid: true,
      message: ''
    },
    card_number: {
      value: '',
      isValid: true,
      message: ''
    }
  }

  componentDidMount() {
    this.props.onGetPlans({ profile_role: this.state.userRole })
  }

  componentDidUpdate(prevProps) {
    this.checkRequiredFields()

    if (prevProps.plans !== this.props.plans) {
      this.setState({ partner_plan_list: this.props.plans })
    }
  }

  handleSubmitSchedule = () => {
    return true
  }

  checkEmail = () => {
    /**
     * Check if iputed email already
     * exists in the database and if it
     * exists fill the personal data
     */
    if (this.state.email.value && this.state.email.value !== '') {
      axios
        .get(apiEndpoints.PERSON_SLUG(this.state.email.value, this.state.userRole))
        .then(response => {
          if (response.status !== 'ZERO_RESULTS') {
            const userData = response.data
            this.setState({
              isNewUser: false,
              hasPartnerProfile: userData.person_profile.length > 0,
              full_name: {
                ...this.state.full_name,
                value: userData.person_general.full_name
              },
              cpf: {
                ...this.state.cpf,
                value: userData.person_general.cpf
              },
              date_birth: {
                ...this.state.date_birth,
                value: userData.person_general.date_birth
              },
              gender: {
                ...this.state.gender,
                value: userData.person_general.gender
              },
              phone_number: {
                ...this.state.phone_number,
                value: userData.person_general.phone_number
                  ? `+${userData.person_general.phone_number}`
                  : ''
              },
              cellphone_number: {
                ...this.state.cellphone_number,
                value: userData.person_general.cellphone_number
                  ? `+${userData.person_general.cellphone_number}`
                  : ''
              },
              user_profile_partner_plan: {
                ...this.state.user_profile_partner_plan,
                value: ''
              },
              card_number: {
                ...this.state.card_number,
                value: ''
              }
            })
            if (userData.person_profile.length > 0 && userData.person_profile[0].plans.length > 0) {
              this.setState({
                user_profile_partner_plan: {
                  ...this.state.user_profile_partner_plan,
                  value: userData.person_profile[0].plans[0].partner_plan_id
                },
                card_number: {
                  ...this.state.card_number,
                  value: userData.person_profile[0].plans[0].card_number
                },
                user_plan_id: userData.person_profile[0].plans[0].id
              })
            }
          }
        })
        .catch(err => {
          const response = err.response.data
          if (response.status !== 'ZERO_RESULTS') {
            return // doesn't need a treatment or even show the error
          } else {
            /**
             * Case there're ZERO_RESULTS reset
             * the form and set isNewUser state
             */
            this.setState({
              ...this.state,
              isNewUser: true,
              ...this.initialFormState
            })
          }
        })
    }
  }

  handleErrorOnSubmit = errorList => {
    const fieldList = errorList && errorList.fieldError.length > 0 ? errorList.fieldError : null
    const systemList = errorList && errorList.sysError.length > 0 ? errorList.sysError : null
    let getErrors = []

    if (fieldList) fieldList.map(error => getErrors.push(error.message))

    if (systemList) systemList.map(error => getErrors.push(error.message))

    this.setState({ errors: getErrors })
    this.props.cleanErrorsList()
  }

  onSubmit = () => {
    this.setState({ errors: [] })

    const statusCode = ACTIVE
    const nameSpread = this.state.full_name.value.split(' ')
    const firstName = nameSpread.splice(0, 1).join('')
    const lastName = nameSpread.join(' ')
    const cpf = CPF.format(this.state.cpf.value, 'digits')

    const formatPhone = phone => {
      let firstChar = phone.slice('')[0]
      let hasPlus = firstChar === '+'

      if (hasPlus) return phone.substr(1)

      return phone
    }

    let postData = {
      email: this.state.email.value,
      profile_role: 'PACI',
      first_name: firstName,
      last_name: lastName,
      gender: this.state.gender.value,
      cpf: cpf,
      date_birth: this.state.date_birth.value,
      cellphone_number: formatPhone(this.state.cellphone_number.value),
      phone_number: this.state.phone_number.value ? formatPhone(this.state.phone_number.value) : '',
      status_code: statusCode,
      plans: [
        {
          partner_plan_id: this.state.user_profile_partner_plan.value,
          card_number: this.state.card_number.value,
          plan_validity: {
            our_evaluation: true // WARN: HARDCODE
          }
        }
      ]
    }

    let modalType = modalTypes.NEW_USER_SUCCESS
    let modalData = {
      btnText: 'Fechar',
      modalTitle: '',
      handleClick: () => {
        history.push(routeSettings.DASHBOARD_CLIENT_LIST)
        this.props.onHideModal()
      },
      modalChildren: (
        <Row padding='1rem 0 0' justify='center'>
          <Typography bold align='center'>
            {this.state.full_name.value}
          </Typography>
        </Row>
      )
    }

    if (this.state.isNewUser) {
      /**
       * If user is a new user
       * POSTs person_general data
       * and append person_profile's "plans"
       */
      modalData.modalTitle = 'Perfil adicionado com sucesso!'

      axios
        .post(apiEndpoints.PERSONS, postData)
        .then(response => {
          this.props.onShowModal(modalType, modalData)
        })
        .catch(async err => {
          await this.props.onGetErrors(err)
          await this.handleErrorOnSubmit(this.props.errorList)
          console.log(err)
        })
      return true
    } else {
      /**
       * If user already exists in database
       */

      if (this.state.hasPartnerProfile) {
        /**
         * If user already has a profile
         * related to actual partner
         * PUTs data and update person_general
         * and person_profile
         */
        modalData.modalTitle = 'Perfil modificado com sucesso!'

        postData.plans[0].id = this.state.user_plan_id

        axios
          .put(apiEndpoints.PERSON_SLUG(this.state.email.value, this.state.userRole), postData)
          .then(response => {
            this.props.onShowModal(modalType, modalData)
          })
          .catch(async err => {
            await this.props.onGetErrors(err)
            await this.handleErrorOnSubmit(this.props.errorList)
            console.log(err)
          })
      } else {
        /**
         * If user does not has a profile
         * related to actual partner
         * PUTs person_general data to make
         * sure changes were applied to personal
         * data and POSTs a new person_profile
         * data to create a profile retalte do
         * actual partner
         */
        const profileData = {
          profile_role: this.state.userRole,
          status_code: ACTIVE,
          plans: [...postData.plans]
        }

        delete postData.plans
        const putData = postData
        axios
          .post(apiEndpoints.PERSON_PROFILES(this.state.email.value), profileData)
          .then(response => {
            axios
              .put(apiEndpoints.PERSON_SLUG(this.state.email.value, this.state.userRole), putData)
              .then(response => {
                this.props.onShowModal(modalType, modalData)
              })
              .catch(async err => {
                await this.props.onGetErrors(err)
                await this.handleErrorOnSubmit(this.props.errorList)
                console.log(err)
              })
          })
          .catch(async err => {
            await this.props.onGetErrors(err)
            await this.handleErrorOnSubmit(this.props.errorList)
            console.log(err)
          })
      }
    }
  }

  handleInputText = (value, state) => {
    this.setState({ [state]: value })
  }

  handleSelectGender = code => {
    this.setState({
      gender: {
        ...this.state.gender,
        value: code
      }
    })
  }

  handleSelectPlan = code => {
    this.setState({
      user_profile_partner_plan: {
        ...this.state.user_profile_partner_plan,
        value: code
      }
    })
  }

  checkRequiredFields = () => {
    let readyToSubmit = true
    let requiredStates = [
      'full_name',
      'email',
      'gender',
      'cpf',
      'date_birth',
      'cellphone_number',
      'user_profile_partner_plan'
    ]

    if (this.requireCardNumber()) requiredStates.push('card_number')

    requiredStates.map(rq => {
      if (
        this.state[rq].value === '' ||
        this.state[rq].value === undefined ||
        this.state[rq].value === null ||
        this.state[rq].isValid === false
      ) {
        readyToSubmit = false
      }
      return readyToSubmit
    })

    if (readyToSubmit !== this.state.readyToSubmit) {
      this.setState({ readyToSubmit })
    }

    return readyToSubmit
  }

  handleAvailablePlans = () => {
    return this.props.plans.map(plan => {
      return {
        code: plan.id,
        name: getFullPlansName(plan),
        value: plan.code
      }
    })
  }

  /**
   * INPUT HANDLERS
   */

  handleNameInput = e => {
    e.preventDefault()
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'name').validate()
    this.setState({
      full_name: {
        ...this.state.full_name,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    })
  }

  handleEmailInput = e => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'email').validate()
    this.setState({
      email: {
        ...this.state.email,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    })
  }

  handleCpfInput = e => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'cpf').validate()
    this.setState({
      cpf: {
        ...this.state.cpf,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    })
  }

  handleBirthdateInput = e => {
    const inputValue = e.target.value
    const minDate = '01/01/1900'
    const validateOptions = {
      minDate: minDate,
      maxDate: moment()
    }
    let validation = new InputValidation(inputValue, 'date', validateOptions).validate()
    this.setState({
      date_birth: {
        ...this.state.date_birth,
        value: inputValue,
        isValid: validation.success,
        message: validation.message
      }
    })
  }

  handleCellphoneInput = value => {
    const inputValue = value
    if (inputValue) {
      isValidPhoneNumber(inputValue)
        ? this.setState({
          cellphone_number: {
            ...this.state.cellphone_number,
            value: inputValue,
            isValid: true,
            message: undefined
          }
        })
        : this.setState({
          cellphone_number: {
            ...this.state.cellphone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido'
          }
        })
    } else {
      this.setState({
        cellphone_number: {
          ...this.state.cellphone_number,
          value: inputValue,
          isValid: true,
          message: ''
        }
      })
    }
  }

  handlePhoneInput = value => {
    const inputValue = value
    if (inputValue) {
      isValidPhoneNumber(inputValue)
        ? this.setState({
          phone_number: {
            ...this.state.phone_number,
            value: inputValue,
            isValid: true,
            message: undefined
          }
        })
        : this.setState({
          phone_number: {
            ...this.state.phone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido'
          }
        })
    } else {
      this.setState({
        phone_number: {
          ...this.state.phone_number,
          value: inputValue,
          isValid: true,
          message: ''
        }
      })
    }
  }

  handleCardId = e => {
    const inputValue = e.target.value
    this.setState({
      card_number: {
        ...this.state.card_number,
        value: inputValue,
        isValid: true,
        message: ''
      }
    })
  }

  requireCardNumber = () => {
    let required = false
    let selectedPlan = this.props.plans.find(
      plan => plan.id === this.state.user_profile_partner_plan.value
    )

    if (
      selectedPlan &&
      selectedPlan.plan_group.use_atena !== undefined &&
      selectedPlan.has_validity !== selectedPlan.plan_group.use_atena
    ) {
      required = true
    }

    return required
  }

  render() {
    const genderOptions = GENDER
    const { errors } = this.state

    return (
      <Container>
        <Row
          align='center'
          justify={this.props.isDesktop ? 'space-between' : 'center'}
          margin={this.props.isDesktop ? '3.9rem 0 2.1rem' : '2.5rem 0 1.5rem'}
        >
          <Typography align='mcenter-dleft' Tag='h1' type='title'>
            <Trans>Adicionar Paciente</Trans>
          </Typography>
        </Row>
        <Row>
          <form onSubmit={this.onSubmit}></form>
          <Row>
            <Row padding=' 0 0 2rem 0'>
              <Typography Tag='h2' align='mcenter-dleft'>
                <Trans>Dados pessoais</Trans>
              </Typography>
            </Row>

            <Row>
              <Column colWidth={6}>
                <TextInput
                  placeholder='E-mail*'
                  required={true}
                  name='email'
                  hasError={!this.state.email.isValid}
                  inputHeight='4.8rem'
                  messageError={this.state.email.message}
                  onInputChange={e => this.handleEmailInput(e)}
                  onKeyPress={(e) => e.key === ' ' && e.preventDefault()}
                  onFocusOut={this.checkEmail}
                  value={this.state.email.value}
                />
              </Column>
              <Column colWidth={6}>
                <TextInput
                  placeholder='Nome completo*'
                  required={true}
                  name='full_name'
                  hasError={!this.state.full_name.isValid}
                  inputHeight='4.8rem'
                  messageError={this.state.full_name.message}
                  onInputChange={e => this.handleNameInput(e)}
                  value={this.state.full_name.value}
                />
              </Column>
              <Column colWidth={6}>
                <Dropdown
                  options={genderOptions}
                  onSelect={code => this.handleSelectGender(code)}
                  placeholder='Gênero*'
                  name='gender'
                  selectedItem={this.state.gender.value}
                  preSelectedItem={this.state.isNewUser ? null : this.state.gender.value}
                />
              </Column>
              <Column colWidth={6}>
                <TextInput
                  placeholder='CPF*'
                  required={true}
                  name='cpf'
                  hasError={!this.state.cpf.isValid}
                  inputHeight='4.8rem'
                  mask='111.111.111-**'
                  messageError={this.state.cpf.message}
                  onInputChange={e => this.handleCpfInput(e)}
                  value={this.state.cpf.value}
                />
              </Column>
              <Column colWidth={6}>
                <TextInput
                  placeholder='Data de nascimento*'
                  required={true}
                  name='date_birth'
                  hasError={!this.state.date_birth.isValid}
                  inputHeight='4.8rem'
                  messageError={this.state.date_birth.message}
                  mask='11/11/1111'
                  onInputChange={e => this.handleBirthdateInput(e)}
                  value={this.state.date_birth.value}
                />
              </Column>
              <Column colWidth={6}>
                <PhoneInput
                  id='cellphone_number'
                  country='BR'
                  limitMaxLength={true}
                  placeholder='Telefone celular*'
                  value={this.state.cellphone_number.value}
                  onChange={cellphone_number => this.handleCellphoneInput(cellphone_number)}
                  error={this.state.cellphone_number.message}
                  flags={flags}
                />
              </Column>
              <Column colWidth={6}>
                <PhoneInput
                  id='phone_number'
                  country='BR'
                  limitMaxLength={true}
                  placeholder='Telefone comercial'
                  value={this.state.phone_number.value}
                  onChange={phone_number => this.handlePhoneInput(phone_number)}
                  error={this.state.phone_number.message}
                  style={{ marginTop: '10px' }}
                  flags={flags}
                />
              </Column>
            </Row>
          </Row>
          <Row>
            <Row padding='2rem 0'>
              <Typography Tag='h2' align='mcenter-dleft'>
                <Trans>Convênio</Trans>
              </Typography>
            </Row>
            <Column colWidth={6}>
              <Dropdown
                options={this.handleAvailablePlans()}
                onSelect={code => this.handleSelectPlan(code)}
                placeholder='Tipo de plano*'
                name='user_profile_partner_plan'
                preSelectedItem={
                  this.state.isNewUser ? null : this.state.user_profile_partner_plan.value
                }
              />
            </Column>
            <Column colWidth={6}>
              {this.requireCardNumber() && (
                <TextInput
                  placeholder='Número da carteirinha*'
                  required={true}
                  name='card_number'
                  inputHeight='4.8rem'
                  onInputChange={e => this.handleCardId(e)}
                  value={this.state.card_number.value}
                />
              )}
            </Column>
            <Column colWidth={6}>
              <TextInput
                disabled
                inputHeight='4.8rem'
                placeholder='Quantidade de consultas por ano*'
                required={true}
                name='plan_max_appointments'
                value={this.state.plan_max_appointments}
              />
            </Column>
          </Row>

          <Row margin='2.5rem 0'>
            <Typography italic color='grey-dark'>
              <Trans>*Campos de preenchimento obrigatório</Trans>
            </Typography>
          </Row>
          {errors.length > 0 && (
            <Row margin='0 0 1.5rem'>
              <Toast icon='Info' message={errors} variant='error' />
            </Row>
          )}
          <Row>
            <Column colWidth={6}>
              <Button
                id='submit'
                disabled={!this.state.readyToSubmit}
                onButtonClick={() => this.onSubmit()}
                text={
                  this.state.isNewUser ? (
                    <Trans>ADICIONAR PACIENTE</Trans>
                  ) : (
                    <Trans>SALVAR MODIFICAÇÕES</Trans>
                  )
                }
                type='submit'
              />
            </Column>
          </Row>
        </Row>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return {
    userData: state.auth.userData,
    errorList: state.async.errorList,
    plans: state.plans.plans,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    cleanErrorsList: () => dispatch(actions.cleanErrorList()),
    onGetErrors: (err) => dispatch(actions.asyncError(err)),
    onGetPlans: (params) => dispatch(actions.getPlans(params)),
    onHideModal: () => dispatch(actions.hideModal()),
    onShowModal: (modalType, modalData) => dispatch(actions.showModal(modalType, modalData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(PartnerPatient))