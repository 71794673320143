import styled from 'styled-components'

interface BarItemProps {
  backgroundColor: string
  height: number
}

interface LabelProps {
  weight: 'normal' | 'bolder'
  color: string
}

interface CircleProps {
  color: string
}

export const Container = styled.div``

export const Bars = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 5px;
  margin-bottom: 7px;
`

export const BarItem = styled.div<BarItemProps>`
  width: 12px;
  height: calc(${({ height }) => height} * 5px);
  background-color: ${({ backgroundColor }) => backgroundColor};

  &:hover {
    opacity: 0.7;
  }
`

export const Label = styled.p<LabelProps>`
  font-size: 12px;
  line-height: 15px;
  text-transform: capitalize;
  font-weight: ${({ weight }) => weight};
  color: ${({ color }) => color};
`

export const TooltipContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`

export const Circle = styled.div<CircleProps>`
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
  margin-top: 2px;
`