import styled from 'styled-components'
import { Typography } from '@telavita-core/react-design-kit'

export const Container = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`

export const Image = styled.div`
  margin: 40px 0;
  width: 100%;
`

export const Text = styled(Typography)`
  line-height: 20px;
`