import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

// STYLE
import './SocialLinks.sass';

// COMPONENTS
import { ShareButton } from 'components';
import { ReactComponent as FacebookIcon} from 'static/svg/icon-facebook.svg';
import { ReactComponent as LinkedinIcon} from 'static/svg/icon-linkedin.svg';
import { ReactComponent as WhatsappIcon} from 'static/svg/icon-whatsapp.svg';

const SocialLinks = ({ forMobile, forDesktop }) => {
  // STORE
  const socialLinksStore = useSelector(state => state.socialLinks);
  const baseUrl = socialLinksStore.baseUrl;
  const title = socialLinksStore.title;

  // LIST OF SOCIAL NET APPS
  const links = [
    { icon: 'facebook', href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(baseUrl)}` },
    { icon: 'linkedin', href: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(baseUrl)}` },
    { icon: 'whatsapp', href: `https://api.whatsapp.com/send?text=${encodeURIComponent(title + ': ' + baseUrl)}` }
  ];

  // SET ICON BY SOCIAL NET NAME
  const setIcon = (icon) => ({
    'facebook': <FacebookIcon className="SocialLinks__icon"/>,
    'linkedin': <LinkedinIcon className="SocialLinks__icon" />,
    'whatsapp': <WhatsappIcon className="SocialLinks__icon SocialLinks__icon--last" />
  }[icon] || <p>Link</p>);

  return (
    <Fragment>
      <div className={classNames('SocialLinks', {
        'SocialLinks--hideOnDesktop': forMobile,
        'SocialLinks--hideOnMobile': forDesktop,
      })}>
        <p className="SocialLinks__label">Compartilhe</p>
        <ul className="SocialLinks__links">
          {
            links.map((item, index) => (
              <li className={classNames('SocialLinks__link', {
                'SocialLinks__link--last': index === links.length-1
              })} key={index}>
                <ShareButton href={item.href} id={item.icon}>{setIcon(item.icon)}</ShareButton>
              </li>
            ))
          }
        </ul>
      </div>
    </Fragment>
  )
}

export {
  SocialLinks
}