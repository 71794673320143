import React, { useState, ReactNode } from 'react'

import { RadioButton, Typography } from '@telavita-core/react-design-kit'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

import { ReactComponent as CheckIcon } from 'static/svg/check.svg'
import { ReactComponent as CircleIcon } from 'static/svg/circle.svg'


import * as S from './styles'


interface CancelReservationQuestionModalProps {
  patientFullName?: string;
  reservationHour: string;
  reservationWeekday: string;
  isProfessional?: boolean;
  onClose: () => void;
  onSelectAllReservation: () => void;
  onSelectSingleDay: () => void;
}

const CancelReservationQuestionModal: ReactNode = ({ 
  patientFullName, 
  reservationHour, 
  reservationWeekday,
  isProfessional = false,
  onClose,
  onSelectAllReservation,
  onSelectSingleDay
}: CancelReservationQuestionModalProps) => {
  const [selectedOption, setSelectedOption] = useState('')

  const isSingleDayOptionSelected = selectedOption === 'singleDay'
  const isAllReservationSelected = selectedOption === 'allReservation'

  const onSubmit = () => {
    if (isSingleDayOptionSelected) {
      onSelectSingleDay()
    } else {
      onSelectAllReservation()
    }
    onClose()
  }

  return (
    <ModalGeneric
      modalTitle='O que você deseja cancelar?'
      modalSubtitle={isProfessional && <>Paciente <b>{patientFullName}</b></>}
      btnText='PRÓXIMO'
      btnDisabled={!selectedOption}
      onCloseModal={onClose}
      btnOnClick={onSubmit}
      hasButton
    >
      <S.Container>
        <Typography variant='content2'>
          Selecione:
        </Typography>

        <RadioButton isSelected={isSingleDayOptionSelected} onSelect={() => setSelectedOption(oldState => oldState === 'singleDay' ? '' : 'singleDay')}>
          <S.RadioContentWrapper>
            <S.RadioButtonTextWrapper>
              <Typography variant='content2' weight='bold'>Cancelar apenas um dia da reserva</Typography>
              <Typography variant='content3'>Somente os dias escolhidos voltam a ficar disponíveis para outros pacientes.</Typography>
            </S.RadioButtonTextWrapper>
            {isSingleDayOptionSelected ? <CheckIcon /> : <CircleIcon />}
          </S.RadioContentWrapper>
        </RadioButton>

        <RadioButton isSelected={isAllReservationSelected} onSelect={() => setSelectedOption(oldState => oldState === 'allReservation' ? '' : 'allReservation')}>
          <S.RadioContentWrapper>
            <S.RadioButtonTextWrapper>
              <Typography variant='content2' weight='bold'>Cancelar toda a reserva</Typography>
              <Typography variant='content3'>Todas as {reservationWeekday}, às {reservationHour}, voltam a <br /> ficar disponíveis para outros pacientes.</Typography>
            </S.RadioButtonTextWrapper>
            {isAllReservationSelected ? <CheckIcon /> : <CircleIcon />}
          </S.RadioContentWrapper>
        </RadioButton>
      </S.Container>
    </ModalGeneric>
  )
}

export {
  CancelReservationQuestionModal
}