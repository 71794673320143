import React from "react";

const Close = ({
  fill = "#acacac",
  height,
  viewBox = "0 0 35 35",
  width,
}) => (
    <svg
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g fill={fill}>
        <path d="M25 11.41L23.59 10 18 15.59 12.41 10 11 11.41 16.59 17 11 22.59 12.41 24 18 18.41 23.59 24 25 22.59 19.41 17z" />
      </g>
    </svg>
  );

export default Close;