import {
  AGENDA_AVAILABILITY,
  CLEAR_AVAILABILITY,
  DAYS_OCCUPIED,
  CLEAR_PROFESSIONALS_AVAILABLE,
  PROFESSIONALS_AVAILABLE,
  GET_ALL_AVAILABILITY,
  SHOW_NOTIFICATION_APPOINTMENT,
} from '../actions/_actionTypes'
import { updateState } from '../helpers/utilityHelpers'

const initialState = {
  availableHours: [],
  errorCode: null,
  unavailableDates: [],
  unavailableDays: [],
  professionals: [],
  notificationAppointment: null
}

const getAvailability = (state, payload) => {
  const patientSchedules = payload.patientSchedules?.map((item) => {
    return new Date(item.start_date)
  })

  return updateState(state, {
    errorCode: payload.errorCode,
    availableHours: payload.availableHours,
    availableCreditsPatient: payload.availableCreditsPatient,
    unavailableDates: payload.unavailableDates,
    patientSchedules,
  })
}

const getScheduledDays = (state, payload) => {
  return updateState(state, { unavailableDays: payload.unavailableDays })
}

const getProfessionals = (state, payload) => {
  return updateState(state, { professionals: payload.professionals })
}

const clearProfessionals = (state) => {
  return updateState(state, { professionals: [] })
}

const clearAvailability = (state) => {
  return updateState(state, {
    availableHours: [],
    errorCode: null,
    unavailableDates: [],
    unavailableDays: [],
  })
}

const getAllAvailability = (state, payload) => {
  return updateState(state, {
    availableHours: payload.availableHours,
    unavailableDates: payload.unavailableDates,
  })
}

const showNotificationAppointment = (state, payload) => {
  return updateState(state, { notificationAppointment: payload })
}

const agendaReducer = (state = initialState, { type, payload }) => {
  switch (type) {
  case AGENDA_AVAILABILITY: return getAvailability(state, payload)
  case CLEAR_AVAILABILITY: return clearAvailability(state)
  case DAYS_OCCUPIED: return getScheduledDays(state, payload)
  case PROFESSIONALS_AVAILABLE: return getProfessionals(state, payload)
  case CLEAR_PROFESSIONALS_AVAILABLE: return clearProfessionals(state)
  case GET_ALL_AVAILABILITY: return getAllAvailability(state, payload)
  case SHOW_NOTIFICATION_APPOINTMENT: return showNotificationAppointment(state, payload)
  default: return state
  }
}

export default agendaReducer
