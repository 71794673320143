// import * as githubActions from './actions'

const INITIAL_STATE = {
  updateList: false,
  error: false,
  loading: false,
  errorMessage: '',
}

function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case 'POST_UNAVAILABILITY':
    return {
      ...state,
      loading: true,
      error: false
    }

  case 'POST_UNAVAILABILITY_SUCCESS':
    return {
      ...state,
      loading: false,
    }

  case 'POST_UNAVAILABILITY_FAILURE':
    return {
      ...state,
      loading: false,
      error: true,
      errorMessage: action.payload
    }

  case 'UPDATE_ERROR_STATUS':
    return {
      ...state,
      error: false
    }

  case 'UPDATE_EVENT_LIST':
    return {
      ...state,
      updateList: !state.updateList,
    }

  default:
    return state
  }
}

export { reducer }