import {
  ADD_BIOGRAPHY_DATA,
  ADD_DOCUMENT_DATA,
  ADD_EDUCATION_DATA,
  ADD_GENERAL_DATA,
  ADD_MEDIA_DATA,
  ADD_PRODUCTS_DATA,
  ADD_LANGUAGES_DATA,
  ADD_SKILLS_DATA,
  CLEAR_BLOCKS_DATA,
  CLEAN_BLOCKS_EDIT,
  EDIT_BLOCKS_DATA,
  ADD_SUBSPECIALTIES_DATA,
  ADD_TREATMENT_BY_AGE_DATA
} from './_actionTypes'

import { getSkillsByType, getSkillsIds } from '../../utils/skillsHandlers'
import * as skillType from '../../settings/_skillsSettings'

export const clearBlocksData = () => dispatch => {
  dispatch({ type: CLEAR_BLOCKS_DATA })
}

export const addGeneralData = (isBlockChecked, canSave, field) => dispatch => {
  dispatch({
    type: ADD_GENERAL_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  })
}

export const addEducationData = (isBlockChecked, canSave, field) => dispatch => {
  dispatch({
    type: ADD_EDUCATION_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  })
}

export const addDocumentsData = (isBlockChecked, canSave, field) => dispatch => {
  dispatch({
    type: ADD_DOCUMENT_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  })
}

export const addBiographyData = (isBlockChecked, canSave, field) => dispatch => {
  dispatch({
    type: ADD_BIOGRAPHY_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  })
}

export const addMediaData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_MEDIA_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}

export const addProductsData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_PRODUCTS_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}

export const addTreatmentByAgeData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_TREATMENT_BY_AGE_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}

export const addLanguagesData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_LANGUAGES_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}

export const addSkillsData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_SKILLS_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}

export const addSubspecialtiesData = (isBlockChecked, canSave, field) => {
  return {
    type: ADD_SUBSPECIALTIES_DATA,
    payload: {
      isBlockChecked,
      canSave,
      data: { ...field },
    },
  }
}


export const editBlocksData = (personData) => {
  const { person, personProfile } = personData

  const personSkills = personProfile.skills || []
  const personProducts = personProfile.products || []
  const personSubspecialties = personProfile.subspecialties || []

  let skillsId = []
  const subspecialtiesIds = personSubspecialties.length > 0 ? personSubspecialties.map(sub => ({ id: sub.id, subspecialty_id: sub.subspecialty_id })) : []

  if (personSkills.length > 0)
    skillsId = getSkillsIds(getSkillsByType(personSkills, skillType.SKILL_CHILD))

  let formattedProducts = []

  personProducts.map(product => {
    let newProduct = {}
    let plans = []

    if (product.appointments.length > 0){
      product.appointments.map(appointment => {
        const newAppointment = {
          id: appointment.id,
          appointment_id: appointment.appointment.id,
          price: appointment.price,
          product_id: product.product.id,
        }
  
        appointment.plans.map(plan => plans.push({ partner_plan_id: plan.plan.id }))
  
        return newProduct = {
          ...newProduct,
          ...newAppointment,
          plans,
        }
      })
      return formattedProducts.push(newProduct)
    }
    return null
  })

  return {
    type: EDIT_BLOCKS_DATA,
    payload: {
      blocksData: {
        biographyData: { biography: person.bio },
        documentsData: { documents: personProfile.documents },
        educationData: { education: personProfile.education },
        generalData: {
          cpf: person.cpf,
          cellphone_number: person.cellphone_number,
          date_birth: person.date_birth,
          email: person.email,
          full_name: person.full_name,
          gender: person.gender,
          phone_number: person.phone_number,
        },
        mediaData: { video_url: person.video_url },
        productsData: { products: formattedProducts },
        skillsData: { skills: skillsId },
        subspecialtiesData: { subspecialties: subspecialtiesIds}
      },
    },
  }
}

export const cleanBlocksEdition = () => {
  return { type: CLEAN_BLOCKS_EDIT }
}