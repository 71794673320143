import React, { useState } from 'react'
import { format, isToday, isTomorrow } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import StickyIndex from 'components/StickyIndex'
import { ConsultationLists } from 'components'
import { PatientDetailProfessionalModal } from 'containers/ModalManager/Modals/PatientDetailProfessionalModal'

import { useBuildMenuList } from '../../hooks'
import { AdminSchedule, PatientData } from '../../types'
import { handleGroupSchedulesByDate } from '../../helpers'

import * as S from './styles'

interface ScheduleListProps {
  data: AdminSchedule[]
}

export function ScheduleList({ data }: ScheduleListProps): JSX.Element {
  const { handleBuildMenuList } = useBuildMenuList()
  const formattedSchedules = handleGroupSchedulesByDate(data)
  const [patientDataModal, setPatientDataModal] = useState({
    openPatientDetailModal: false,
    patientData: {
      name: '',
      cpf: '',
      email: '',
      phone: ''
    }
  })

  const handlePatientData = (patientData: PatientData) => {
    setPatientDataModal({
      openPatientDetailModal: true,
      patientData,
    })
  }
 
  const renderGroupByDate = () => {
    return formattedSchedules.map((item) => {
      const labelContent = () => {
        const scheduleDate = new Date(item.isoDate)

        if (isToday(scheduleDate)) return 'HOJE'
        if (isTomorrow(scheduleDate)) return 'AMANHÃ'

        return format(new Date(scheduleDate), 'EEEE', { locale: ptBR })
      }
	
      return (
        <div key={`${item.date} + ${item.isoDate}`}>
          <StickyIndex
            textPosition='flex-start'
            barContent={labelContent()}
            barDate={item.date}
          />
          {
            item.schedules.map(schedule => {
              return (
                <S.ConsultationListsWrapper key={schedule.id}>
                  <ConsultationLists
                    sessionId={schedule.session}
                    status={schedule.status}
                    patientName={schedule.clientName}
                    id={schedule.id}
                    psychologistName={schedule.professionalName}
                    professionalType={schedule.professional.person_profile.profile_code}
                    time={schedule.time}
                    date={schedule.date}
                    menuList={handleBuildMenuList({ schedule, handlePatientData })}
                    planFullName={schedule.planFullName}
                    consultationEvaluation={schedule.consultationEvaluation}
                    virtualRoomHash={schedule.virtualRoomHash}
                    showMedicalReturnBadge={schedule.medicalReturn}
                    startDate={schedule.startDate}
                  />
                </S.ConsultationListsWrapper>
              )})}
        </div>
      )
    })
  }

  return (
    <>
      {renderGroupByDate()}
      {patientDataModal.openPatientDetailModal && (
        <PatientDetailProfessionalModal
          name={patientDataModal.patientData.name}
          email={patientDataModal.patientData.email}
          phone={patientDataModal.patientData.phone}
          cpf={patientDataModal.patientData.cpf}
          onClose={() => setPatientDataModal(prevState => ({...prevState, openPatientDetailModal: false }))}
        />
      )}
    </>
  )
}