import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { ADMIN_SCHEDULES_QUERY_KEY } from 'settings/_queryKeySettings'
import { GetSchedulesResponse } from 'services/schedules/getSchedules'
import { updateSchedule } from 'views/Dashboard/Sections/AdminScheduleList/helpers'

interface Schedule {
  id: number
  status: string
}

interface UseChangeScheduleStatus {
  handleUpdateScheduleStatus: (schedule: Schedule) => void
  isSuccess: boolean
  isLoading: boolean
  isError: boolean
}

async function updateScheduleStatus(schedule: Schedule) {
  const params = {
    status_code: schedule.status
  }

  const response = await axios.put(apiEndpoints.SCHEDULE_DETAIL(schedule.id), params)
  
  return {
    ...response,
    data: {
      id: schedule.id,
      status_code: schedule.status
    }
  }
}

export function useChangeScheduleStatus(): UseChangeScheduleStatus {
  const queryClient = useQueryClient()
  const { mutate: handleUpdateScheduleStatus, isSuccess, isLoading, isError } = useMutation({
    mutationFn: (schedule: Schedule) => updateScheduleStatus(schedule),
    onSuccess: (data) => {
      return queryClient.setQueryData<InfiniteData<GetSchedulesResponse>>([ADMIN_SCHEDULES_QUERY_KEY], oldData => {
        const newPages = updateSchedule({
          data: oldData?.pages,
          scheduleId: data.data.id,
          newValue: {
            status_code: data.data.status_code
          }
        })

        return {
          ...oldData,
          pages: newPages
        }
      }) 
    }
  })

  return {
    handleUpdateScheduleStatus,
    isSuccess,
    isLoading,
    isError
  }
}