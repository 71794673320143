import {
  CHANGE_PERSON,
  CHANGE_PERSON_PROFILE,
  CLEAR_PERSON,
  CLEAR_PERSONS,
  CREATE_PERSON,
  GET_PERSON,
  GET_PROFILE_PLAN,
  GET_PROFESSIONAL_HISTORY,
  GET_PROFILE_ROLE,
  LIST_PERSONS,
  SET_PERSON_PROFILE,
  LIST_PROFESSIONALS,
  CLEAR_PROFESSIONALS,
  CLEAR_CONCATED_PROFESSIONALS,
  START_LOADING_PERSONS,
  LIST_PERSONS_PROFESSIONALS,
  CLEAR_PERSONS_PROFESSIONALS
} from '../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

const initialState = {
  person: {},
  persons: [],
  professionals: [],
  concatedProfessionals: [],
  pagination: {},
  personDocument: '',
  personEducation: [],
  personSkills: [],
  personPlans: [],
  personsProfessionals: [],
  profileCode: '',
  personProfessionalHistory: [],
  personProfile: [],
  personProducts: [],
  newPerson: {},
  newProfile: {},
  loading: false,
};

const setPerson = (state, payload) => {
  return updateState(state, {
    newPerson: {
      success: payload.success,
      slug: payload.slug,
    },
  });
};

const getPersonPlans = (state, payload) => {
  return updateState(state, {personPlans: payload.personPlans,});
};

const getProfessionalHistory = (state, payload) => {
  return updateState(state, { personProfessionalHistory: payload.personProfessionalHistory })
};

const changePerson = (state, payload) => {
  return updateState(state, {
    newPerson: {
      success: payload.success,
      slug: payload.slug,
    },
  });
};

const setPersonProfile = (state, payload) => {
  return updateState(state, { newProfile: { success: payload.success } });
};

const changePersonProfile = (state, payload) => {
  return updateState(state, { newProfile: { success: payload.success } });
};

const clearPerson = state => {
  return updateState(state, {
    person: {},
    personDocument: '',
    personEducation: [],
    personSkills: [],
    personProfile: [],
    personProducts: [],
    newPerson: {}
  });
};

const startLoadingProfessionals = (state) => {
  return updateState(state, { loading: true });
};

const personsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHANGE_PERSON: return changePerson(state, payload);
    case GET_PROFILE_ROLE: return updateState(state, payload);
    case CLEAR_PERSON: return clearPerson(state);
    case CLEAR_PERSONS: return updateState(state, { persons: [] });
    case CLEAR_PERSONS_PROFESSIONALS: return updateState(state, { personsProfessionals: [] });
    case CLEAR_PROFESSIONALS: return updateState(state, { professionals: [] });
    case CLEAR_CONCATED_PROFESSIONALS: return updateState(state, { concatedProfessionals: [] });
    case CREATE_PERSON: return setPerson(state, payload);
    case GET_PROFILE_PLAN: return getPersonPlans(state, payload);
    case GET_PROFESSIONAL_HISTORY: return getProfessionalHistory(state, payload);
    case GET_PERSON:
      return updateState(state, {
        isOnline: payload.isOnline,
        person: payload.person,
        personDocument: payload.personDocument,
        personEducation: payload.personEducation,
        personSkills: payload.personSkills,
        personProfile: payload.personProfile,
        personProducts: payload.personProducts,
        profileCode: payload.profileCode,
        enableNewPatients: payload.enableNewPatients
      });
    case LIST_PERSONS: return updateState(state, { persons: payload.persons });
    case LIST_PERSONS_PROFESSIONALS: return updateState(state, { personsProfessionals: payload.persons });
    case LIST_PROFESSIONALS:
      return updateState(state, {
        loading: false,
        persons: [
          ...state.persons,
          ...payload.persons,
        ],
        pagination: payload.pagination,
      });
    case SET_PERSON_PROFILE: return setPersonProfile(state, payload);
    case CHANGE_PERSON_PROFILE: return changePersonProfile(state, payload);
    case START_LOADING_PERSONS: return startLoadingProfessionals(state);
    default: return state;
  }
};

export default personsReducer;
