import { Typography } from '@telavita-core/react-design-kit'
import React from 'react'
import { ProfessionalData } from '../CanceledScheduleDetailsModal.types'

import * as S from './styles'

export const ProfessionalInfo: React.FC<ProfessionalData> = (professionalData) => {
  return (
    <S.Container>
      <Typography variant="content2">Profissional: </Typography>
      <Typography weight='bold' variant="content2">
        {professionalData?.name}
      </Typography>
    </S.Container>
  )
}

