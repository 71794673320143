import React, { Component } from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import { Typography } from 'components'
import clsx from 'clsx'

class Dropdown extends Component {

  /**
   * TODO: Adicionar estilo para disabled (.dropdown[disabled=true])
   */

  state = {
    isOpen: false,
    isSelected: false,
    selectedItem: '',
    defaultValue: null,
  }

  // Encoded URI to use SVG in background of dropdown field
  iconDown = encodeURIComponent('<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g transform="translate(-170.457 -425)"><path transform="translate(170.457 425)" /><path fill="#acacac" d="M111-20l5,5,5-5Z" transform="translate(66.459 455)" /></g></svg>');
  iconUp = encodeURIComponent('<svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"><g transform="translate(-1290 -950)"><path fill="none" d="M0,0H24V24H0Z" transform="translate(1290 950)" /><path fill="#acacac" d="M111-20l5,5,5-5Z" transform="translate(1418 945) rotate(180)" /></g></svg>');

  componentDidMount = () => {
    if (this.props.preSelectedItem) {
      this.handleSelectedItem(this.props.preSelectedItem)
    }

    this.dropdownRef = React.createRef()
    document.addEventListener('click', function (e) {
      this.onClickOutside(e)
    }.bind(this), false)

    if (this.props.defaultValue) this.handleDefaultValue()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.preSelectedItem !== this.props.preSelectedItem) {
      this.handleSelectedItem(this.props.preSelectedItem)
    }
  }

  UNSAFE_componentWillReceiveProps = (receivedProps) => {
    if (receivedProps.preSelectedItem && receivedProps.preSelectedItem !== this.props.preSelectedItem && this.state.options !== receivedProps.options) {
      this.handleSelectedItem(receivedProps.preSelectedItem)
    }

    this.setState({ options: receivedProps.options })
  }

  onClickOutside(e) {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.hideDropdown()
    }
  }

  handleSelectedItem = (preSelectedItem) => {
    if (preSelectedItem) {
      const selectedItem = !this.props.clearSelection ?
        this.props.options.find(option => option.id === preSelectedItem || option.code === preSelectedItem) : ''
      if (selectedItem) {
        let identifier = selectedItem && selectedItem.id ? selectedItem.id : selectedItem.code
        this.handleSelect(identifier, selectedItem.name)
      }
    }
  }

  handleDropdown = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }

  hideDropdown() {
    this.setState({ isOpen: false })
  }

  showDropdown() {
    this.setState({ isOpen: true })
  }

  handleSelect = (code, name, closeOptions = true) => {
    this.props.onSelect(code)
    this.setState({
      isSelected: true,
      selectedItem: name,
      isOpen: !closeOptions
    })
  }

  handleChange = (code, name, closeOptions = true) => {
    this.props.onChange(code)
    this.setState({
      isSelected: true,
      selectedItem: name,
      isOpen: !closeOptions
    })
  }

  setDefaultValue = (code) => {
    let initialValue = this.props.options.filter(option => option.code === code)
    this.setState({ defaultValue: initialValue[0] })
    return initialValue[0]
  }

  handleDefaultValue = () => {
    let initialValue = this.setDefaultValue(this.props.defaultValue)
    this.handleChange(initialValue.code, initialValue.name)
  }

  // Simulate native comportament of HTML select
  setOptionBasedOnKey = (e) => {
    const { options, onSelect } = this.props
    let currentIndex = options.findIndex(option => option.name === this.state.selectedItem)
    let newOption = null

    if (e.key === ' ') {
      // Open dropdown list
      e.preventDefault()
      this.showDropdown()
    } else if (e.key === 'Enter') {
      // Open/close dropdown list
      this.handleDropdown()
    } else if (e.key === 'Escape' || e.key === 'Tab') {
      // Close options list
      this.hideDropdown()
    } else if (e.key === 'ArrowUp') {
      // Set prev option
      e.preventDefault()
      newOption = currentIndex === -1 ? options[0] : options[--currentIndex]
    } else if (e.key === 'ArrowDown') {
      // Set next option
      e.preventDefault()
      newOption = currentIndex === -1 ? options[0] : options[++currentIndex]
    } else {
      // Set option by letter
      const keyPressed = e.key.toLowerCase()
      const filteredOptions = options.filter(option => option?.name[0]?.toLowerCase() === keyPressed)
      let prevIndex = filteredOptions.findIndex(option => option.name === this.state.selectedItem)
      newOption = prevIndex === -1 ? filteredOptions[0] : (filteredOptions[++prevIndex] || filteredOptions[0])
    }

    if (newOption) {

      let identifier = newOption.code ?? newOption.id 
      onSelect ? this.handleSelect(identifier, newOption.name, false) : this.handleChange(newOption.code, newOption.name, false)
    }
  }

  optionsList = () => {
    const { isOpen, selectedItem } = this.state
    let classes = clsx({
      'dropdown--option-list': true,
      'dropdown--overflow': true,
      'open': isOpen
    })

    return (
      <div className={classes}>
        {
          this.props.options.map((option, index) => {
            let tagId = `item-${index}-${this.props.name}`
            let identifier = option.code ?? option.id 
            let optionsClick = this.props.onSelect ? () => this.handleSelect(identifier, option.name) : () => this.handleChange(identifier, option.name)
            let classSelected = option.name === selectedItem && !this.props.clearSelection ? '-selected' : ''
            let elem = document.querySelector(`#${tagId}`)
            if (classSelected && elem) document.querySelector(`#${tagId}`).focus()

            return (
              <div
                key={index}
                className={`dropdown--option-item${classSelected}`}
                onClick={optionsClick}
                id={tagId}
                tabIndex='0'
              >
                {option.name}
              </div>
            )
          })
        }
      </div>
    )
  }

  render() {
    let bgCurrentIcon = `data:image/svg+xml,${this.state.isOpen ? this.iconUp : this.iconDown}`
    let classes = clsx({
      'dropdown': true,
      'dropdown-error': this.props.hasError,
      [this.props.customClassName]: !!this.props.customClassName
    })

    return (
      <div className={classes} onKeyDown={this.setOptionBasedOnKey}>
        <div
          className="dropdown__toggle"
          tabIndex="0"
          disabled={this.props.disabled}
          name={this.props.name}
          id={this.props.name}
          ref={node => this.wrapperRef = node}
        >
          <div
            className={`dropdown--box${this.state.isOpen ? '-open' : ''} ${this.props.className ? this.props.className : ''} ${this.props.disabled ? 'dropdown--box--disabled' : ''}`}
            style={{ backgroundImage: `url('${bgCurrentIcon}')` }}
            onClick={this.handleDropdown}
          >
            <span className={`dropdown--placeholder${this.state.isSelected && !this.props.clearSelection ? '-selected' : ''}`}>
              {
                this.props.defaultValue && !this.state.isSelected
                  ? this.state.defaultValue
                  : this.state.isSelected && !this.props.clearSelection ? this.state.selectedItem : this.props.placeholder
              }
            </span>
          </div>
          {
            this.props.hasError && (
              <div className="messageError">
                <Typography color="false" type="subheading2">
                  {this.props.messageError}
                </Typography>
              </div>
            )
          }
        </div>
        {
          !this.props.disabled && this.optionsList()
        }
      </div>
    )
  }
}

Dropdown.propTypes = {
  className: PropTypes.string,
  clearSelection: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  messageError: PropTypes.string,
  name: PropTypes.string,
  onSelect: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.node,
  preSelectedItem: PropTypes.any
}

Dropdown.defaultProps = {
  clearSelection: false,
}

export default Dropdown
