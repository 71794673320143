import styled from 'styled-components'

export const Button = styled.button`
  background-color: transparent;
  border: 2px solid #fff;
  color: #fff;
  width: 96px;
  height: 35px;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;

  &:hover {
    background-color: #FFFFFF33;
  }
`

export const TooltipBody = styled.div`
  background-color: transparent;
  max-width: 310px;
  max-height: 400px;
  position: relative;
`

export const TooltipContent = styled.div`
  color: #fff;
  font-weight: bold;
  span {
    color: #FFFFB5;
  }
`

export const TooltipTitle = styled.h2`
  color: #f04;
  margin: 0;
`

export const TooltipFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;

  * + * {
    margin-left: 0.5rem;
  }

  ${Button} {
    padding: 0.8rem;
  }
`