import React, { PureComponent, Fragment } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'

import { Typography, TimestampTag, ConsultationCard, Spinner } from 'components'
import FloatActionButtonDropdown from '../../../components/FloatActionButtonDropdown/FloatActionButtonDropdown'
import ListPlaceholder from '../../../components/ListPlaceholder/ListPlaceholder'
import ProfessionalChip from '../../../components/ProfessionalChip/ProfessionalChip'
import Row from '../../../components/Row'
import Tabs from '../../../components/Tabs/index'

import viewportHoc from '../../../hoc/viewportHoc'
import { modalTypes } from '../../../containers/ModalManager/ModalManager'

import { formatFloatToReal } from '../../Payment/utils/orderUtils'
import { hasPermission } from '../../../utils/permissionHandlers'
import { getTime, toSimpleDayMonthYear, toString, isTodayBeforeTime } from '../../../utils/dateTimeHandlers'
import { getRoleFromProfile } from '../../../utils/textTransformations'
import { getRescheduleData } from '../../../utils/rescheduleHandlers'
import { showIntercom } from 'utils/intercomHandlers'
import { getSchedules } from '../../../redux/actions/schedulesActions'
import { cleanSchedules, showModal, hideModal, getPersonProfessionalHistory, clearPersons } from '../../../redux/actions'
import * as filterHomepageActions from '../../../redux/modules/filterHomepage/actions'

import { history } from '../../../routers/AppRouter'
import * as routes from '../../../settings/_routesSettings'
import * as permissions from '../../../settings/_permissionSettings'
import { PATIENT } from '../../../settings/_profileSettings'
import { 
  NOT_SCHEDULED, 
  SCHEDULED, 
  CANCELED, 
  CANCEL_REQUESTED,
  ATTENDED,
  UNATTENDED,
  RETURNED,
  PROFESSIONAL_NO_SHOW,
  PATIENT_NO_SHOW 
} from '../../../settings/_scheduleStatusSettings'

import { ReactComponent as DownArrowRight } from 'static/svg/down-arrow-right.svg'

import { Typography as Typo } from '@telavita-core/react-design-kit'

class AppointmentsList extends PureComponent {
  state = {
    isToday: false,
    selecetedTab: 'next-appointments',
    loaded: false,
    loadingProfessionalHistory: false,
  }

  tabsList = [
    {
      title: (<Trans>Próximas</Trans>),
      code: 'next-appointments',
    },
    {
      title: (<Trans>Histórico</Trans>),
      code: 'previous-appointments',
    },
  ]

  buildMenuList = (client, friendlyUrl, professional, schedule, product, originalSchedule) => {
    let menuList = []
    const planIsParticular = this.props.planSelected.partner_plan_is_particular

    const cancelModalData = {
      scheduleId: schedule.id,
      classModifier: 'false',
      hasButton: true,
      btnText: 'CONFIRMAR CANCELAMENTO',
      modalTitle: 'Cancelar consulta',
      btnId: 'modal-btn',
      titleColor: 'black',
      notificationText: planIsParticular ?
        '*Ao cancelar a consulta, o valor será estornado integralmente na próxima fatura do seu cartão de crédito.' :
        '*Ao cancelar a consulta, o crédito será estornado para que você possa agendar novamente.'
    }

    const blockedModalData = {
      modalTitle: 'Desculpe, não é possível\n reagendar ou cancelar com\n menos de 24 horas do\n horário da consulta',
      titleColor: 'false',
      btnText: 'ENTENDI',
      handleClick: this.props.closeModal,
      modalChildren: (
        <div>
          <br /><Typography align="center">Esta regra existe para garantir o compromisso entre paciente e profissional quanto a programação de agendas.</Typography>
          <br /><Typography align="center">Procure reagendar / cancelar com antecedência.</Typography>
        </div>
      ),
    }

    const rescheduleData = {
      dashboard: PATIENT,
      client,
      professional,
      schedule: {
        ...schedule,
        date: originalSchedule.date,
        time: originalSchedule.time,
        isoDate: originalSchedule.startDate
      },
      product,
    }

    if (schedule.status === SCHEDULED && hasPermission(this.props.userPermissions, permissions.USER_CHANGE_SCHEDULE)) {
      menuList.push(
        {
          code: 'reschedule-appointment',
          name: 'Reagendar',
          func: () => originalSchedule.rescheduleEnabled ? this.props.onShowModal(modalTypes.RESCHEDULE, rescheduleData) : this.props.onShowModal(modalTypes.GENERIC, blockedModalData),
        }
      )
    }

    if (hasPermission(this.props.userPermissions, permissions.USER_CANCEL_SCHEDULE)) {
      menuList.push(
        {
          code: 'cancel-appointment',
          name: 'Cancelar',
          func: () => originalSchedule.cancellationEnabled ? this.props.onShowModal(modalTypes.USER_CANCEL, cancelModalData) : this.props.onShowModal(modalTypes.GENERIC, blockedModalData),
        }
      )
    }

    menuList.push(
      {
        code: 'see-profile',
        name: 'Ver perfil',
        func: () => history.push(routes.PROFESSIONAL_PSIC_PROFILE(friendlyUrl)),
      }
    )

    return menuList
  }

  componentDidMount() {
    this.getSchedules()
    this.getProfessionalHistory()

    const { isAuth, userData } = this.props
    const intercomData = userData ?
      { email: userData.username, name: userData.person } : { email: undefined, name: undefined }
    showIntercom(isAuth, intercomData)
  }

  componentWillReceiveProps(prevProps) {
    if (prevProps.schedules !== this.props.schedules) {
      this.setState({ schedules: this.props.schedules })
    }
  }

  componentDidUpdate(nextProps, nextState) {
    if (nextState.selecetedTab !== this.state.selecetedTab) {
      this.getSchedules()
    }
  }

  componentWillUnmount() {
    this.props.onCleanSchedules()
  }

  getProfessionalHistory = () => {
    this.setState({ loadingProfessionalHistory: true })
    this.props.onGetProfessionalHistory()
      .then(() => {
        this.setState({ loadingProfessionalHistory: false })
      })
  }

  handleTabSelect = (value) => {
    this.setState({ selecetedTab: value })
  }

  getSchedules = () => {
    const dateTimeNow = toString()
    const username = this.props.userData.username
    const profileRole = this.props.userData.session.profile_role_active
    this.props.onCleanSchedules()
    this.setState({ loaded: false })

    if (this.state.selecetedTab === 'next-appointments') {
      this.props.onGetSchedules({
        username,
        end_date__gte: dateTimeNow,
        sort: 'asc',
        profile_role: profileRole,
      })
        .then(() => {
          this.setState({ loaded: true })
        })
    } else if (this.state.selecetedTab === 'previous-appointments') {
      this.props.onGetSchedules({
        username,
        start_date__lte: dateTimeNow,
        sort: 'desc',
        profile_role: profileRole,
        status_code: [
          CANCELED,
          CANCEL_REQUESTED,
          ATTENDED,
          UNATTENDED,
          RETURNED,
          PROFESSIONAL_NO_SHOW,
          PATIENT_NO_SHOW
        ],
      })
        .then(() => {
          this.setState({ loaded: true })
        })
    }
  }

  handleScheduleData = () => {
    const schedules = this.props.schedules
    const cleanSchedules = schedules.filter(schedule => schedule.status_code !== NOT_SCHEDULED)
    const newScheduleData = cleanSchedules.map(schedule => {
      const date = toSimpleDayMonthYear(schedule.start_date)
      const time = getTime(schedule.start_date)
        .split(':')
        .join('h')
      const scheduleId = schedule.id
      const virtualRoomReady = schedule.virtual_room_available
      const sessionId = schedule.session
      const scheduleStatus = schedule.status_code
      const planCode = schedule.order_item.order.plan_code
      const cancellationEnabled = schedule.cancellation_enabled
      const rescheduleEnabled = schedule.reschedule_enabled
      const meetingHash = schedule.virtual_room_hash || ''
      const planName = schedule.order_item.order.plan_full_name
      const price = `R$ ${formatFloatToReal(schedule.order_item.price)}`

      const professionalData = schedule.guests.find(professional =>
        getRoleFromProfile(professional.person_profile.profile_code) !== PATIENT
      )

      const clientData = schedule.guests.find(client =>
        getRoleFromProfile(client.person_profile.profile_code) === PATIENT
      )

      const hasDocument = schedule
        .order_item.person_profile_product_appointment.person_profile_product.person_profile.documents

      const professionalDocumentType = hasDocument.length > 0 ? hasDocument[0].type_name : ''

      const professionalDocument = hasDocument.length > 0 ? hasDocument[0].value : ''

      const productDuration = schedule
        .order_item.person_profile_product_appointment.person_profile_product.product.product_general.duration

      const productId = schedule
        .order_item.person_profile_product_appointment.person_profile_product.product.id

      const productCode =
        schedule.order_item.person_profile_product_appointment
          .person_profile_product.product.code

      return {
        planName: planName,
        client: clientData,
        startDate: schedule.start_date,
        endDate: schedule.end_date,
        date: date,
        id: scheduleId,
        meetingHash: meetingHash,
        planCode: planCode,
        price: price,
        productDuration,
        productId,
        productCode,
        professional: professionalData,
        professionalDocumentType,
        professionalDocument,
        session: sessionId,
        status: scheduleStatus,
        time: time,
        virtualRoomReady,
        cancellationEnabled,
        rescheduleEnabled,
      }
    })
    return newScheduleData
  }

  renderAppointmentList = () => {
    const { startSchedule, wsSchedule, timeIsUp, toStartDuration } = this.props
    const { selecetedTab } = this.state

    const isEmpty = (this.props.schedules.length === 0)

    let scheduleData = []
    if (!isEmpty) scheduleData = this.handleScheduleData()

    return (
      scheduleData.length === 0 ? (
        <div className="AppointmentsList__list-empty">
          <ListPlaceholder
            iconName="AgendaTime"
            iconHeight="65px"
            iconWidth="65px"
            title={(<Trans>Nenhuma consulta marcada</Trans>)}
          />
        </div>
      ) : (
        <Fragment>
          {
            selecetedTab === 'next-appointments' ?
              <div className="AppointmentsList__topMessage">
                <Typography type="subheading1" className="AppointmentsList__message">O botão <b>entrar</b> aparecerá à direita 3 min. antes do horário marcado</Typography>
                <DownArrowRight className="AppointmentsList__icon" />
              </div> : null
          }
          <div className="AppointmentsList__list-populated">
            {
              scheduleData.map(schedule => {

                const isToday = isTodayBeforeTime(schedule.startDate)

                let isNextSchedule = wsSchedule ? schedule.id === wsSchedule.id : false

                const scheduleData = getRescheduleData(schedule)

                const professionalData = schedule.professional.person_profile
                const professionalPicture = professionalData ? professionalData.person.person_general.picture_url : ''
                const friendlyUrl = professionalData ? professionalData.person.person_general.friendly_url : ''

                return (
                  <div className='MyConsultations' key={schedule.id}>
                    <ConsultationCard
                      cardColor='#FFFFB5'
                      status={schedule.status}
                      iconName='MoreOptions'
                      id={schedule.id}
                      name={scheduleData.professional.name}
                      sessoinId={schedule.session}
                      price={schedule.price}
                      time={schedule.time}
                      date={schedule.date}
                      menuList={this.buildMenuList(
                        scheduleData.client, friendlyUrl, scheduleData.professional, scheduleData.scheduleData, scheduleData.productData, schedule)}
                      avatarUrl={professionalPicture}
                      avatarSize='100%'
                      isTimeUp={(schedule.virtualRoomReady) || (isNextSchedule ? timeIsUp : null)}
                      isToday={isToday}
                      startSession={(schedule.virtualRoomReady) || (isNextSchedule ? startSchedule : null)}
                      initialTime={isNextSchedule ? toStartDuration : null}
                      meetingHash={schedule.meetingHash}
                      hasPrice={this.props.planSelected.partner_plan_is_particular}
                      startDate={schedule.startDate}
                      endDate={schedule.endDate}
                      professionalCode={schedule.professional.person_profile.profile_code}
                      professionalDocumentType={schedule.professionalDocumentType}
                      professionalDocument={schedule.professionalDocument}
                      planName={schedule.planName}
                    />
                  </div>
                )
              })
            }
            <TimestampTag card />
          </div>
        </Fragment>
      )
    )
  }

  handleProfessionalProfileRoute = (professional) => {
    const routeByProfileRole = {
      undefined: routes.HOME_PSIC,
      PSIC: routes.PROFESSIONAL_PSIC_PROFILE(professional.friendly_url),
      PSIQ: routes.PROFESSIONAL_PSIQ_PROFILE(professional.friendly_url),
    }
    this.props.clearPersons()
    history.push(routeByProfileRole[professional.profile_role])
  }

  render() {
    const { loaded, loadingProfessionalHistory } = this.state
    const { professionalHistory, planSelected } = this.props
    const canSeeNewSchedule = planSelected.plan_group.has_vitrine || planSelected.partner_plan_is_particular

    return (
      <div className="AppointmentsList">
        <Row className="Dashboard__Header">
          <Typo variant={this.props.isDesktop ? 'header2' : 'header4'}>
            Minhas consultas
          </Typo>
          {
            canSeeNewSchedule &&
            <FloatActionButtonDropdown
              icon="PencilEdit"
              title={'Marcar consulta'}
              type="primary"
              loading={loadingProfessionalHistory}
              options={[
                ...professionalHistory,
                { full_name: 'outro profissional' },
              ]}
              renderOption={(professional) => {
                return (
                  <ProfessionalChip
                    name={professional.full_name}
                    photo={professional.picture_url}
                  />
                )
              }}
              onSelectOption={(professional) => {
                this.handleProfessionalProfileRoute(professional)
              }}
            />
          }
        </Row>

        <Row margin="auto auto 2rem ">
          <Tabs tabs={this.tabsList}
            tabId="schedules-tabs"
            selectedItem={this.state.selecetedTab}
            onSelectTab={(value) => this.handleTabSelect(value)}
          />
        </Row>

        <Row direction="column" className="AppointmentsList__list">
          {
            loaded ? this.renderAppointmentList() : <Spinner height="100%" />
          }
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuth,
    planSelected: state.auth.planSelected,
    professionalHistory: state.persons.personProfessionalHistory,
    schedules: state.schedules.schedules,
    showModal: state.modal.showModal,
    startSchedule: state.notification.wsStartSchedule,
    timeIsUp: state.notification.wsTimeUp,
    toStartDuration: state.notification.wsScheduleDuration,
    userData: state.auth.userData,
    userPermissions: state.auth.profilePermissions,
    wsSchedule: state.notification.wsGetSchedule,
    testSchedule: state.notification,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCleanSchedules: () => dispatch(cleanSchedules()),
    onGetSchedules: (getParams) => dispatch(getSchedules(getParams)),
    onShowModal: (modal, data) => dispatch(showModal(modal, data)),
    closeModal: () => dispatch(hideModal()),
    onGetProfessionalHistory: () => dispatch(getPersonProfessionalHistory()),
    clearPersons: () => {
      dispatch(filterHomepageActions.clearPersons())
      dispatch(clearPersons())
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(AppointmentsList))
