// Helper para verificar se o agendamento está
// dentro do intervalo para iniciar o atendimento.
const isScheduleNow = (
  startDate,
  endDate,
  diffMinutes = 3
) => {
  const startMilli = new Date(startDate).getTime() - (diffMinutes * 60000)
  const endMilli = new Date(endDate).getTime()
  const currentMilli = new Date().getTime()
  return currentMilli >= startMilli && currentMilli <= endMilli
}

export { isScheduleNow }