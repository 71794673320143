import { GET_PRICE } from "./_actionTypes";
import axios from "../../settings/axios";
import { apiEndpoints } from "../../settings/_apiSettings";
import { asyncError } from "./asyncActions";
export const getPrice = ({
  profile_role,
  product_code,
}) => async dispatch => {
  const fetchMin = apiEndpoints.MIN_PRICE(product_code);
  const fetchMax=apiEndpoints.MAX_PRICE(product_code);

  const params = {
    ...(profile_role && { profile_role }),
    ...(product_code && { product_code }),
  
  };

  try {
    const responseMin = await axios.get(fetchMin, { params });
    const responseMax = await axios.get(fetchMax, { params });
    const payload = { 
      minPrice: responseMin.data.minprice,
      maxPrice: responseMax.data.maxprice,
    };

    dispatch({
      type: GET_PRICE,
      payload,
    });
  } catch (err) {
    dispatch(asyncError(err));
  }
};