import React from 'react'

import { Typography } from '@telavita-core/react-design-kit'
import { PatientData } from '../CanceledScheduleDetailsModal.types'

import * as S from './styles'

export const PatientInfo: React.FC<PatientData> = (patientData) => {
  return (
    <S.Container>
      <Typography variant="content2">Paciente: </Typography>
      <Typography weight='bold' variant="content2">{patientData?.name}</Typography>
      <Typography variant="content2">{patientData?.email}</Typography>
    </S.Container>
  )
}