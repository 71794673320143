import React from "react";

const Filters = ({
  fill,
  height,
  viewBox = "-10 -10 40 40",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path fill={fill} d="M8.852 11.679a3.144 3.144 0 0 1-6.027 0H.9a.9.9 0 1 1 0-1.8h1.925a3.144 3.144 0 0 1 6.027 0h11.806a.9.9 0 0 1 0 1.8zm3.848 5.838a3.144 3.144 0 0 1 6.027 0h1.927a.9.9 0 0 1 0 1.8h-1.922a3.144 3.144 0 0 1-6.027 0H.9a.9.9 0 1 1 0-1.8zm0-15.269a3.144 3.144 0 0 1 6.027 0h1.927a.9.9 0 0 1 0 1.8h-1.922a3.144 3.144 0 0 1-6.027 0H.9a.9.9 0 1 1 0-1.8zm3.013 2.245a1.347 1.347 0 1 0-1.342-1.347 1.347 1.347 0 0 0 1.347 1.348zm0 15.269a1.347 1.347 0 1 0-1.347-1.347 1.347 1.347 0 0 0 1.352 1.348zM5.838 9.434a1.347 1.347 0 1 0 1.347 1.347 1.347 1.347 0 0 0-1.347-1.347z" />
  </svg>
);

export default Filters;