import {
  GET_SLOTS_SUCCESS,
  REMOVE_SLOTS_SUCCESS,
  CLEAR_SLOT_STATUS,
  REMOVE_SLOTS_FAILED
} from './_actionTypes'

import { apiEndpoints } from '../../settings/_apiSettings'
import axios from '../../settings/axios'

const getSlotSuccess = (payload) => {
  return {
    type: GET_SLOTS_SUCCESS,
    payload: payload,
  }
}

const removeSlotSuccess = (payload) => {
  return {
    type: REMOVE_SLOTS_SUCCESS,
    payload: payload,
  }
}

const removeSlotFailed = (payload) => {
  return {
    type: REMOVE_SLOTS_FAILED,
    payload: payload,
  }
}

export const clearSlotStatus = () => {
  return {
    type: CLEAR_SLOT_STATUS,
  }
}


export const getSlot = (slug) => async dispatch => {
  let fetchUrl = apiEndpoints.AGENDA_SLOTS(slug, 'weekday')
  const response = await axios.get(fetchUrl)
  if (response.status === 'OK') {

    let payload = { slots: response.data.slots }
    return dispatch(getSlotSuccess(payload))

  }
}

export const removeSlot = (slug, slotId) => async dispatch => {
  try {
    await axios.delete(apiEndpoints.AGENDA_SLOT(slug, slotId), {
      params: {
        removal_confirmed: true,
      }
    })

    let payload = { success: true }
  
    dispatch(getSlot(slug))
    return dispatch(removeSlotSuccess(payload))

  } catch (err) {
    if (err.response.status === 409) {
      dispatch(removeSlotFailed({
        errorMessage: 'Há consulta(s) agendada(s) neste período. Cancele-a(s) antes deste evento.'
      }))
    }
  }

}
