import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'
import { AnimatePresence } from 'framer-motion'

import { CheckBox } from 'components'
import { SelectAgeRange } from './components/SelectAgeRange'

import { childAgeRangeOptions, teenagerAgeRangeOptions } from 'settings/_ageRangeOptions'
import { PSYCHIATRIST } from 'settings/_profileSettings'

import { useAgeData } from './hooks/useAgeData'
import { AgeDataProps, AgeRangeCategory } from './TreatmentByAgeData.types'

import * as S from './styles'

export const TreatmentByAgeData: React.FC<AgeDataProps> = ({ 
  role, 
  setHasErrorOnAgeSelectRange, 
  hasErrorOnAgeSelectRange 
}) => {
  const { 
    ages, 
    handleSelectAge,
    handleSelectChildAgeRange,
    handleSelectTeenagerAgeRange,
  } = useAgeData()

  if (role === PSYCHIATRIST) {
    return null
  }

  function onSelectChildAgeRange(id: number | string, label: string) {
    setHasErrorOnAgeSelectRange(prevState => ({
      ...prevState,
      childSelectError: false
    }))

    handleSelectChildAgeRange(
      id,
      label
    )
  }

  function onSelectTeenagerAgeRange(id: number | string, label: string) {
    setHasErrorOnAgeSelectRange(prevState => ({
      ...prevState,
      teenagerSelectError: false
    }))

    handleSelectTeenagerAgeRange(
      id,
      label
    )
  }

  return (
    <S.Container>
      <Typography variant="content1" weight='bold'>Atendimento por idade *</Typography>
      <Typography 
        italic 
        variant='content2'
        customClassName='subtitle'
      >
        Selecione uma ou mais opções
      </Typography>

      <S.WrapperAges>
        {ages.map(({ id, name, isSelected, category, ageRange }) => {

          return (
            <div key={id}>
              <CheckBox
                title={name}
                isSelected={isSelected}
                onClickCheckBox={() => handleSelectAge(id, category)}
                customIconColor={isSelected ? '#1F35B5' : '#4B4B4B'}
                indeterminate={false}
              />

              <AnimatePresence>
                {isSelected && category === AgeRangeCategory.CHILD && (
                  <SelectAgeRange 
                    handleSelectAgeRange={onSelectChildAgeRange}
                    hasErrorOnAgeRange={hasErrorOnAgeSelectRange.childSelectError}
                    selectOptions={childAgeRangeOptions}
                    startAge={ageRange.startAge}
                    endAge={ageRange.endAge}
                  />
                )}
              </AnimatePresence>

              <AnimatePresence>
                {isSelected && category === AgeRangeCategory.TEENAGER && (
                  <SelectAgeRange 
                    handleSelectAgeRange={onSelectTeenagerAgeRange}
                    hasErrorOnAgeRange={hasErrorOnAgeSelectRange.teenagerSelectError}
                    selectOptions={teenagerAgeRangeOptions}
                    startAge={ageRange.startAge}
                    endAge={ageRange.endAge}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        )}
      </S.WrapperAges>
    </S.Container>
  )
}