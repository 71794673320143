import React from 'react';
import PropTypes from 'prop-types';

import 'moment/locale/pt-br';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from 'react-day-picker/moment';

import { WEEKDAYS_SHORT } from '../../settings/dayPicker';
import { languageCode } from '../../settings/_apiSettings';

const CalendarInput = ({ daysUnavailable, handleDayChange, selectedDay }) => {
  let getLocale = languageCode.toString();

  const renderDay = (day) => {
    const date = day.getDate();

    return (
      <div className="DayCell">
        <div className="DayDate">
          {date}
        </div>
      </div>
    );
  };

  return (
    <DayPickerInput
      dayPickerProps={{
        disabledDays: daysUnavailable,
        locale: getLocale,
        localeUtils: MomentLocaleUtils,
        renderDay: renderDay,
        selectedDays: selectedDay,
        weekdaysShort: WEEKDAYS_SHORT['pt-br'],
      }}
      format="L"
      formatDate={formatDate}
      inputProps={{ type: "button" }}
      parseDate={parseDate}
      onDayChange={day => handleDayChange(day)}
      keepFocus={false}
      value={`${formatDate(selectedDay, 'L', getLocale)}`}
    />
  );
};

CalendarInput.propTypes = {
  daysUnavailable: PropTypes.array,
  handleDayChange: PropTypes.func,
  selectedDay: PropTypes.any,
};

export { CalendarInput };