import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  
  .reservationEmptyStateTitle {
    margin: 15px 0 20px;
  }

  .reservationEmptyStateText {
    color: #898989;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 20px;
  color: #898989;
  width: 340px;
`