import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'
import { useFilter } from 'views/Dashboard/Sections/AdminScheduleList/context/FilterContext'

import * as S from '../styles'
import { CalendarFilter } from 'components/CalendarFilter'

export const Calendar = (): JSX.Element => {
  const { state, dispatch } = useFilter()

  return (
    <S.FilterContainer>
      <Typography variant='content2'>
        Visualizar as datas:
      </Typography>
        
      <CalendarFilter
        calendarDate={state.calendarDate}
        handleChangeCalendarDate={(date) => dispatch({ type: 'SET_CALENDAR_DATE', payload: date })}
        showSelectedDateOnPlaceholder={state.calendarDate.length > 0}
      />
    </S.FilterContainer>
  )
}