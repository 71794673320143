import styled from 'styled-components'

interface EmptyProps {
  isEmpty: boolean
}

export const Footer = styled.footer<EmptyProps>`
  margin-bottom: 10px;
  margin-top: 4px;
  padding-left: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  opacity: ${({ isEmpty }) => isEmpty && 0};
  display: ${({ isEmpty }) => isEmpty && 'none'};

  > button {
    padding: 0;
  }
`