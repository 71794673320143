import React from 'react'

const ArrowRight = ({
  fill,
  height,
  viewBox = '0 0 24 24',
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path d="M10,1.9,8.1,0,0,8.1l8.1,8.1,1.9-1.9L3.819,8.1Z" transform="translate(17.027 19.617) rotate(180)" fill={fill} />
  </svg>
)


export default ArrowRight
