import styled from 'styled-components'

export const SubTitleWrapper = styled.div`
  .SubTitleWrapper__Text {
    color: #898989 !important;
  }
`

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .DisableNewPatientsModal__Paper {
    max-width: 545px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding: 15px;

  }
`

export const PaperContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const IconWrapper = styled.div`
  flex: 1;
`

export const Table = styled.table`
  width: 100%;
  margin-top: 40px;

  th {
    padding-bottom: 25px;
    padding-right: 70px;
  }

  td:not(:first-child) {
    padding-top:25px;
    padding-bottom:25px;
    padding-right:25px;       
  }

  .DisableNewPatientsModal__AdminEmail {
    color: #898989;
    margin-top: 2px;
  }

  .Toggle {
    width: 96px;
    background-color: #FF4949;
  }

  .Toggle--active {
    background-color: #4AC326;
  }

  .Toggle__icon--active {
    margin-left: 70px;
  }

  .Toggle__icon--loading {
    margin: 0 35px;
    transition: margin 0.1s;
  }
`

export const TableRow = styled.tr`
  border-bottom: 1px solid #E2E2E2;
`

export const TableHead = styled.th`
  text-align: left;
`

export const TableCell = styled.td``

export const ActionButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin: 30px 0;

  button {
    height: auto;
    margin: 0;
    padding: 0;
  }
`