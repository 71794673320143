import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import { Button, Column, CoreIcons } from 'components'
import Row from '../Row'

function NotificationStep({
  buttonId,
  btnText,
  colWidth = 4,
  hasIcon,
  iconClass,
  iconColor,
  iconName,
  hasButton,
  loading,
  mainText,
  onSuccessClick,
  buttonDisabled
}) {
  return (
    <Column align='center' justify='center' colWidth={colWidth}>
      <div className="NotificationStep">
        {hasIcon &&
          <Row justify="center" className="NotificationStep__iconHeader">
            <CoreIcons name={iconName}
              className={iconClass}
              height="50"
              width="50"
              fill={iconColor}
            />
          </Row>
        }
        <Row justify="center">
          {mainText}
        </Row>
        {
          hasButton && (
            <Row justify="center" margin="10px 0 0">
              <Button
                loading={loading}
                disabled={buttonDisabled}
                id={buttonId}
                text={btnText}
                onButtonClick={() => onSuccessClick()}
              />
            </Row>
          )
        }
      </div>
    </Column>
  )
}

NotificationStep.propTypes = {
  buttonId: PropTypes.string,
  buttonDisabled: PropTypes.bool,
  btnText: PropTypes.any,
  colWidth: PropTypes.number,
  hasButton: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconClass: PropTypes.string,
  iconColor: PropTypes.string,
  iconName: PropTypes.string,
  loading: PropTypes.bool,
  mainText: PropTypes.any,
  onButtonClick: PropTypes.func,
}

NotificationStep.defaultProps = {
  buttonDisabled: false,
  btnText: 'Começar',
  hasButton: true,
  hasIcon: true,
  iconName: 'Check',
  loading: false,
  mainText: 'Sua conta foi criada com sucesso!',
}

export default NotificationStep