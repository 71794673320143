import React, { useState } from 'react'

import { Typography } from '@telavita-core/react-design-kit'

import { toDayMonthYear } from 'utils/dateTimeHandlers'
import { format } from 'date-fns'

import { ScheduleContainerFrequencyOptions } from 'containers/ScheduleContainer/_settings'

import { CoreIcons } from 'components'
import { PlanDetail } from 'components/PlanDetail/PlanDetail'
import PatientTreatmentsEligibility from 'components/PatientTreatmentsEligibility'

import { Specialities } from 'settings/_specialitiesSettings'
import { 
  ALLOWED_TO_SCHEDULE,
  CONSULTATION_SCHEDULED, 
  INELIGIBLE, 
  MEDICAL_RETURN_AVAILABLE, 
  MEDICAL_RETURN_SCHEDULED, 
  SCHEDULE_CYCLE_END 
} from 'settings/_patientTreatmentStatus'
import ToggleFloatButton from 'components/ToggleFloatButton'
import { MakePatientEligibleModal } from 'containers/ModalManager/Modals/MakePatientEligibleModal'

import * as S from '../styles'

type CycleStatus = 
  typeof ALLOWED_TO_SCHEDULE | 
  typeof CONSULTATION_SCHEDULED | 
  typeof MEDICAL_RETURN_AVAILABLE | 
  typeof MEDICAL_RETURN_SCHEDULED | 
  typeof SCHEDULE_CYCLE_END | 
  typeof INELIGIBLE |
  null

interface CorporateEligibilityProps {
  isEligible: boolean
  isCorporateLimited: boolean
  availableCredits: string | number
  maxCredits: string | number
  expirationDate: string
  dateCreated: string
  frequency: string
  planName: string
  planGroupName: string
  isActive: boolean
  inactivatePlanInfo?: {
    inactivatedAt: string
    inactivatedBy: string
    moreInfo: string
    reason: string
  }
  showPlanDetails?: boolean
  showPsychiatry: boolean
  cycleStatus: CycleStatus
  cycleDate?: string
  eligibleToSchedule?: boolean | null
  patientUsername: string
  planCode: string
  refetchEligibility: () => void
}

const CorporateEligibility = ({
  availableCredits,
  dateCreated,
  expirationDate,
  isCorporateLimited,
  isEligible,
  frequency,
  maxCredits,
  planName,
  planGroupName,
  isActive,
  inactivatePlanInfo,
  showPlanDetails = false,
  showPsychiatry,
  cycleStatus,
  cycleDate,
  eligibleToSchedule,
  patientUsername,
  planCode,
  refetchEligibility,
}: CorporateEligibilityProps): JSX.Element => {

  const [openDetail, setOpenDetail] = useState(false)
  const [showMakePatientEligibleModal, setShowMakePatientEligibleModal] = useState(false)

  const getFrequency = () => {
    if (frequency === ScheduleContainerFrequencyOptions.WEEKLY) {
      return 'semana'
    } else if (
      frequency === ScheduleContainerFrequencyOptions.BIWEEKLY
    ) {
      return '2 semanas'
    } else if (
      frequency === ScheduleContainerFrequencyOptions.QUARTERLY
    ) {
      return '12 semanas'
    }
  }

  const setConditionalClass = () => {
    if (isEligible) {
      if (isCorporateLimited) {
        if (availableCredits) {
          if (new Date() < new Date(expirationDate)) {
            return {
              message: '',
              className: 'eligible',
            }
          } else
            return {
              message: '*A validade do(s) crédito(s) de consulta expirou.',
              className: 'expirationDate',
            }
        } else
          return {
            message: '*Não há mais consultas disponíveis pelo convênio.',
            className: 'unavailable',
          }
      } else
        return {
          message:
            'Agendamentos liberados em todos os meses disponíveis no calendário.',
          className: 'corporateUnlimited',
        }
    } else
      return {
        message: (
          <>
            O CPF do paciente não consta mais na lista de beneficiários do
            convênio. <strong>Se houver algum engano</strong>, peça para ele
            entrar em contato com o responsável pelo convênio para mais
            informações.
          </>
        ),
        className: isCorporateLimited ? 'ineligible' : 'ineligibleUnlimited',
      }
  }

  const renderMessage = () => {
    const result = setConditionalClass()

    if (!result.message) return

    return (
      <div
        className={`ContainerPatientDetail__planGroup__info ${result.className}`}
      >
        <Typography variant='content3' color='danger'>
          {result.message}
        </Typography>
      </div>
    )
  }

  const renderPsiqContent = () => {

    const formatedCycleDate =
      cycleDate && format(new Date(cycleDate), 'dd/MM/yyyy')

    if (cycleStatus === ALLOWED_TO_SCHEDULE) {
      return (
        <Typography variant='content2'>
          O agendamento com psiquiatra está liberado.
          <br /> O paciente pode agendar{' '}
          <b className='ContainerPatientDetail__Highlight__Green'>
            1 consulta por vez
          </b>
          .
        </Typography>
      )
    }

    if (cycleStatus === CONSULTATION_SCHEDULED) {
      return (
        <>
          <Typography variant='content2'>
            O paciente já possui uma consulta agendada com psiquiatra.
          </Typography>
          <Typography variant='content3' color='danger'>
            Só é possível agendar uma nova depois que o paciente realizá-la.
          </Typography>
        </>
      )
    }

    if (cycleStatus === MEDICAL_RETURN_AVAILABLE) {
      return (
        <>
          <Typography variant='content2'>
            O paciente tem direito a um{' '}
            <b className='ContainerPatientDetail__Highlight__Green'>
              retorno médico
            </b>{' '}
            com o seu psiquiatra.
          </Typography>
          <Typography variant='content3' color='success'>
            Agende com o mesmo profissional até {formatedCycleDate}.
          </Typography>
        </>
      )
    }

    if (cycleStatus === MEDICAL_RETURN_SCHEDULED) {
      return (
        <>
          <Typography variant='content2'>
            O paciente já possui um retorno médico agendado com psiquiatra.
          </Typography>
          <Typography variant='content3' color='danger'>
            Só é possível agendar uma nova consulta a partir de{' '}
            {formatedCycleDate}.
          </Typography>
        </>
      )
    }

    if (cycleStatus === SCHEDULE_CYCLE_END) {
      return (
        <Typography variant='content2'>
          O paciente pode agendar uma nova consulta com psiquiatra{' '}
          <b className='ContainerPatientDetail__Highlight__Green'>
            a partir de {formatedCycleDate}
          </b>
          .
        </Typography>
      )
    }

    if (cycleStatus === INELIGIBLE) {
      return (
        <Typography
          variant='content3'
          color='danger'
          inlineStyles={{ lineHeight: '16px' }}
        >
          O paciente não consta mais como beneficiário do convênio para esta
          especialidade. Infelizmente, isso impossibilita o agendamento de
          consultas de psiquiatria. Verifique se a carteirinha do convênio está
          certa/atualizada.
        </Typography>
      )
    }
  }

  const renderEligibilityInfo = () => {
    if (isCorporateLimited) {
      return (
        <>
          <ul className='ContainerPatientDetail__planGroup__list'>
            <li>
              <Typography variant='content2'>Consultas disponíveis:</Typography>
              <Typography
                variant='content2'
                weight='bolder'
                customClassName={`credits--${setConditionalClass().className}`}
              >
                {availableCredits} de{' '}
                {maxCredits}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Válidas até:</Typography>
              <Typography
                variant='content2'
                customClassName={`validity--${setConditionalClass().className}`}
              >
                {toDayMonthYear(expirationDate)}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>
                Frequência de agendamento
              </Typography>
              <Typography variant='content2'>
                1 a cada {getFrequency()}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Criado em: </Typography>
              <Typography variant='content2'>
                {toDayMonthYear(dateCreated)}
              </Typography>
            </li>
          </ul>
          {renderMessage()}
        </>
      )
    } else if (!isCorporateLimited) {
      return (
        <>
          <ul className='ContainerPatientDetail__planGroup__list'>
            <li>
              <Typography variant='content2'>Válidas até:</Typography>
              <Typography
                variant='content2'
                customClassName={`validity--${setConditionalClass().className}`}
              >
                <i>indeterminado</i>
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Tem direito a:</Typography>
              <Typography variant='content2'>
                1 a cada {getFrequency()}
              </Typography>
            </li>
            <li>
              <Typography variant='content2'>Criado em: </Typography>
              <Typography variant='content2'>
                {toDayMonthYear(dateCreated)}
              </Typography>
            </li>
          </ul>
          {renderMessage()}
        </>
      )
    }
  }

  return (
    <div
      className={`ContainerPatientDetail__planGroup planGroupInfo__${
        setConditionalClass().className
      }`}
    >
      <div className='ContainerPatientDetail__planInfo'>
        <S.HeaderContainer>
          <Typography weight='bolder' variant='content1'>
            Convênio {planGroupName} {planName}
          </Typography>

          {eligibleToSchedule === false && (
            <ToggleFloatButton 
              fill='#4B4B4B'
              iconName='MoreOptions'
              menuList={[{ 
                code: 1, 
                name: 'Tornar elegível', 
                func: () => setShowMakePatientEligibleModal(true) 
              }]}
            />
          )}
        </S.HeaderContainer>

        <S.Status active={isActive}>
          <CoreIcons
            height='13px'
            name='StatusIcon'
            width='13px'
          />
          <Typography variant='content2'>
            Convênio {isActive ? 'ativo' : 'inativo'}
          </Typography>
        </S.Status>

        {eligibleToSchedule === null
          ? null 
          : (
            <S.Status active={eligibleToSchedule}>
              <CoreIcons
                height='13px'
                name='StatusIcon'
                width='13px'
              />
              <Typography variant='content2'>
                {eligibleToSchedule ? 'Elegível' : 'Inelegível'}
              </Typography>
            </S.Status>
          )}
      </div>

      {showPsychiatry ? (
        <div className='ContainerPatientDetail__PatientTreatmentsEligibilityWrapper'>
          <PatientTreatmentsEligibility
            tabs={Specialities.map((specialty) => specialty.name)}
            tabsPanel={[
              <div className='ContainerPatientDetail__PsicTabWrapper'>
                {renderEligibilityInfo()}
              </div>,
              <div className='ContainerPatientDetail__PsiqTabWrapper'>
                {renderPsiqContent()}
              </div>,
            ]}
          />
        </div>
      ) : (
        <>
        {renderEligibilityInfo()}
        </>
      )}

      {showPlanDetails && (
        <PlanDetail
          reasonTitle='Motivo da inativação'
          headerResume={
            <>
              Convênio inativado em<br/>
              {format(new Date(inactivatePlanInfo.inactivatedAt), 'dd/MM/yyyy')}, às {format(new Date(inactivatePlanInfo.inactivatedAt), 'HH\'h\'mm')}<br/>
              Por {inactivatePlanInfo.inactivatedBy}
            </>
          }
          toggleDetail={() => setOpenDetail(prevState => !prevState)}
          displayDetail={openDetail}
          reason={inactivatePlanInfo.reason}
          moreInfo={inactivatePlanInfo.moreInfo}
        />
      )}

      {showMakePatientEligibleModal && (
        <MakePatientEligibleModal
          username={patientUsername}
          planCode={planCode}
          handleClose={() => setShowMakePatientEligibleModal(false)}
          onSuccess={() => refetchEligibility()}
        />
      )}
    </div>
  )
}

export default CorporateEligibility