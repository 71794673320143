import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

const deleteAllReservation = ({
  uuid,
  professional_username,
  patient_username,
  reason
}) => {
  return new Promise((resolve, reject) => {
    const params = {
      uuid,
      professional_username,
      patient_username,
      reason
    }

    axios.delete(apiEndpoints.DELETE_RESERVATION, { data: params })
      .then(res => {
        const { status, message } = res.data
        resolve({
          status,
          message
        })
      })
      .catch(e => { reject(e) })
  })
}

export { deleteAllReservation }