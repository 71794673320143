/*
 * The Root Reducer
 */

import { combineReducers } from "redux";

import agendaReducer from "../reducers/agendaReducer";
import asyncReducer from "../reducers/asyncReducer";
import authReducer from "../reducers/authReducer";
import blocksReducer from "../reducers/blockReducer";
import CancelReasonReducer from "../reducers/CancelReducer";
import checkoutReducer from "../reducers/checkoutReducer";
import creditCardReducer from "../reducers/creditCardReducer";
import documentsReducer from "../reducers/documentsReducer";
import emailRecoveryReducer from "../reducers/emailRecoveryReducer";
import filtersReducer from "../reducers/filtersReducer";
import filterStatusReducer from "../reducers/filterStatusReducer";
import medicalRequestReducer from "../reducers/medicalRequestReducer";
import modalReducer from "../reducers/modalReducer";
import notificationReducer from "../reducers/notificationReducer";
import ordersReducer from "../reducers/ordersReducer";
import personsReducer from "../reducers/personsReducer";
import paymentReducer from "../reducers/paymentReducer";
import plansReducer from "../reducers/plansReducer";
import productsReducer from "../reducers/productsReducer";
import registerReducer from "../reducers/registerReducer";
import registerBlocksReducer from "../reducers/registerBlocksReducer";
import profilesReducer from '../reducers/profilesReducer';
import reportReducer from '../reducers/reportReducer';
import schedulesReducer from "../reducers/schedulesReducer";
import skillsReducer from "../reducers/skillsReducer";
import slotReducer from "../reducers/slotReducer";
import surveyReducer from '../reducers/surveyReducer';
import priceFilterReducer from '../reducers/priceFilterReducer';
import { reducer as plansFilterReducer } from './plans/reducer';
import { reducer as creditsPanelReducer } from './creditsPanel/reducer';
import { reducer as filtersHomepage } from './filterHomepage/reducer';
import { reducer as generalView } from './generalView/reducer';
import { reducer as mypatients } from './mypatients/reducer';
import { reducer as professionals } from './professionals/reducer';
import { reducer as socialLinks } from './socialLinks/reducer';
import { reducer as unavailability } from './unavailability/reducer';
 
const rootReducer = combineReducers({
    agenda: agendaReducer,
    async: asyncReducer,
    auth: authReducer,
    blocks: blocksReducer,
    checkout: checkoutReducer,
    cards: creditCardReducer,
    documents: documentsReducer,
    emailRecovery: emailRecoveryReducer,
    filters: filtersReducer,
    filterStatus: filterStatusReducer,
    medicalRequests: medicalRequestReducer,
    modal: modalReducer,
    notification: notificationReducer,
    orders: ordersReducer,
    payment: paymentReducer,
    persons: personsReducer,
    plans: plansReducer,
    products: productsReducer,
    profiles: profilesReducer,
    reasons: CancelReasonReducer,
    register: registerReducer,
    registerBlocks: registerBlocksReducer,
    reports: reportReducer,
    schedules: schedulesReducer,
    skills: skillsReducer,
    slots: slotReducer,
    questions: surveyReducer,
    price: priceFilterReducer,
    plansFilter: plansFilterReducer,
    creditsPanel: creditsPanelReducer,
    filtersHomepage: filtersHomepage,
    generalView: generalView,
    mypatients: mypatients,
    professionals: professionals,
    socialLinks: socialLinks,
    unavailability: unavailability
});
 
 export { rootReducer };
 