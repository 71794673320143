import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import * as actions from '../../../../redux/actions'
import * as dateHandler from '../../../../utils/dateTimeHandlers'

import { Button, Column } from 'components'
import Row from '../../../../components/Row'
import RescheduleContainer from '../../../RescheduleContainer'
import { RescheduleConfirmation } from 'components/RescheduleConfirmation'
import ModalGeneric from '../ModalGeneric'
import { useReschedule } from './useReschedule'

const RescheduleModal = ({ client, professional, schedule, product}) => {
  const { handleReschedule, isSuccess } = useReschedule(schedule)
  const [newDate, setNewDate] = useState('')
  const [newHour, setNewHour] = useState('')
  const [actualStep, setActualStep] = useState(0)

  useEffect(() => {
    if (isSuccess) {
      setActualStep(actualStep + 1)
    }
  }, [isSuccess])

  const dispatch = useDispatch()

  const closeModal = () => {
    dispatch(actions.hideModal())
  }

  const modalInner = actualStep => {
    let innerContent

    switch (actualStep) {
    case 0:
      innerContent = (
        <div>
          <RescheduleContainer
            clientUsername={client.username}
            professionalDocument={professional.document}
            professionalName={professional.name}
            professionalSlug={professional.username}
            professionalProfileCode={professional.profileCode}
            schedule={schedule}
            productCode={product.code}
            handleSubmit={(scheduleData) => {
              handleReschedule({ ...scheduleData, id: schedule.id})
              setNewDate(dateHandler.toDayMonthYear(scheduleData.start_date))
              setNewHour(dateHandler.getTime(scheduleData.start_date))
            }}
          />
        </div>
      )
      break
    case 1:
      innerContent = (
        <div>
          <RescheduleConfirmation
            professionalName={professional.name}
            date={newDate}
            time={newHour}
          />
          <Row margin="2rem 0 0 0">
            <Column>
              <Button
                disabled={false}
                text="FECHAR"
                onButtonClick={(e) => closeModal(e)}
              />
            </Column>
          </Row>
        </div>
      )
      break

    default:
      break
    }

    return innerContent
  }

  return (
    <ModalGeneric>
      <div className="RescheduleStepperModal">
        { modalInner(actualStep) }
      </div>
    </ModalGeneric>
  )
}

RescheduleModal.propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
    profileCode: PropTypes.string,
  }),
  dashboard: PropTypes.string,
  professional: PropTypes.shape({
    name: PropTypes.string,
    document: PropTypes.string,
    username: PropTypes.string,
    profileCode: PropTypes.string,
  }),
  schedule: PropTypes.shape({
    duration: PropTypes.number,
    id: PropTypes.number,
    plan_code: PropTypes.string,
    session: PropTypes.number,
    status: PropTypes.string,
    date: PropTypes.string,
    time: PropTypes.string,
    isoDate: PropTypes.string
  }),
}

export default RescheduleModal