import React, { useState, useEffect } from 'react'
import { Route, Router, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import Dashboard from '../views/Dashboard'
import Login from '../views/Login'

import * as routes from '../settings/_routesSettings'

import PrivateRoute from './PrivateRoute'
import NotFound from 'views/NotFound'
import classNames from 'classnames'
import { useSelector } from 'react-redux'

export const history = createBrowserHistory()

const isBackgroundGradient = (currentUrl) => {
  return (
    currentUrl.includes('/psicologia-online/') ||
    currentUrl.includes('/psiquiatria-online/')
  )
}

const isBackgroundGrey = (currentUrl) => {
  return (
    currentUrl === routes.DASHBOARD_HOME ||
    currentUrl === routes.DASHBOARD_HOME_PATIENT ||
    currentUrl === routes.DASHBOARD_GENERAL_VIEW ||
    currentUrl === routes.DASHBOARD_GRAPHICS_PROFESSIONAL ||
    currentUrl === routes.DASHBOARD_APPOINTMENTS)
}

export const AppRouter = () => {
  const userStatus = useSelector(state => state.auth?.profileSelected?.status_code)
  const [isGrey, setGrey] = useState(isBackgroundGrey(window.location.pathname))
  const [isGradient, setGradient] = useState(isBackgroundGradient(window.location.pathname))



  useEffect(() => {
    const unListenHistory = history.listen(location => {
      setGrey(isBackgroundGrey(location.pathname))
      setGradient(isBackgroundGradient(location.pathname))
    })

    return () => {
      unListenHistory()
    }

  }, [])

  return (
    <div className={classNames(
      'app', {
      'app--gradient': isGradient,
      'app--grey': isGrey,
    }
    )}>
      <Router history={history}>
        <Switch>
          <Route exact path={routes.LOGIN} component={Login} />
          <PrivateRoute path={routes.DASHBOARD} component={Dashboard} />
          <Route render={() => <NotFound />} />
        </Switch>
      </Router>
    </div>
  )
}

export default AppRouter
