import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import FilterModal from './Modals/FilterModal'
import EditPermissions from './Modals/EditPermissions'
import EditEmailAccount from './Modals/EditEmailAccount'
import Modal from './Modals/Modal'
import ModalApproveValidity from './Modals/ModalApproveValidity/ModalApproveValidity'
import ModelCancel from './Modals/ModelCancel/ModelCancel'
import ModalMenu from './Modals/ModalMenu'
import ModalUploadMedicalRequest from './Modals/ModalUploadMedicalRequest'
import RescheduleModal from './Modals/RescheduleModal'
import ScheduleModal from './Modals/ScheduleModal/ScheduleModal'
import SuccessPlanAdded from './Modals/SuccessPlanAdded'
import { GeneralViewFilters } from './Modals/GeneralViewFilters'
import { ProfUnavailabilityModal } from './Modals/ProfUnavailabilityModal'
import { RemoveUnavailabilityModal } from './Modals/RemoveUnavailabilityModal'
import { SuccessModal } from './Modals/SuccessModal'

import * as actions from '../../redux/actions/'
import ModalRepproveRequest from './Modals/ModalRepproveRequest'
import ModalInvalidateRequest from './Modals/ModalInvalidateRequest'
import ModalNewMedicalRequest from './Modals/ModalNewMedicalRequest'
import ModalRepproveDetails from './Modals/ModalRepproveDetails'
import ModalLogsVirtualRoom from './Modals/ModalLogsVirtualRoom'
import ModalChangeScheduleStatus from './Modals/ModalChangeScheduleStatus'
import ModalTerms from './Modals/ModalTerms'
import ModalFileBeneficiaries from './Modals/ModalFileBeneficiaries'
import EnableNewPatientsModal from './Modals/EnableNewPatientsModal'
import ModalGeneric from './Modals/ModalGeneric'
import ModalPrivacyPolicy from './Modals/ModalPrivacyPolicy'
import PatientDetail from './Modals/PatientDetail'
import EditPlanModal from './Modals/EditPlanModal'
import InactiveProfessionalModal from './Modals/InactiveProfessionalModal'
import ActiveProfessionalModal from './Modals/ActiveProfessionalModal'
import { ReviewActionModal } from './Modals/ReviewActionModal'
import { CancelationScheduleModal } from './Modals/CancelationScheduleModal'
import { CanceledScheduleDetailsModal } from './Modals/CanceledScheduleDetailsModal'

class ModalManager extends Component {
  static propTypes = {
    modalType: PropTypes.string,
    modalData: PropTypes.object,
  };

  handleMountModal = () => {
    const { modalType, modalData } = this.props

    switch (modalType) {

    case modalTypes.GENERIC:
      return (
        <Modal
          isCentered
          btnId="close-modal"
          btnText={modalData.btnText}
          modalTitle={modalData.modalTitle}
          titleColor={modalData.titleColor}
          handleClick={modalData.handleClick}
          hasButton
          extraClassName>
          {modalData.modalChildren}
        </Modal>
      )

    case modalTypes.NEW_USER_SUCCESS:
      return (
        <Modal
          isCentered
          btnId="close-modal"
          btnText={modalData.btnText}
          modalTitle={modalData.modalTitle}
          handleClick={modalData.handleClick}
          hasButton
          extraClassName>
          {modalData.modalChildren}
        </Modal>
      )

    case modalTypes.DASHBOARD_MENU:
      return (
        <ModalMenu
          menuList={modalData.menuList}
          onCloseMenu={this.props.onHideModal}
        />
      )

    case modalTypes.NEW_SCHEDULE:
      return <ScheduleModal user={modalData.user} selectedWeekDay={modalData?.weekDay} />


    case modalTypes.CANCELED_DETAIL_MODAL:
      return <CanceledScheduleDetailsModal scheduleId={modalData?.id} />

    case modalTypes.ADMIN_CANCEL:
      return (
        <CancelationScheduleModal 
          scheduleData={modalData?.scheduleData} 
        />
      )

    case modalTypes.ADMIN_CHANGE_SCHEDULE_STATUS:
      return (
        <ModalChangeScheduleStatus
          schedule={modalData}
        />
      )

    case modalTypes.RESCHEDULE:
      return (
        <RescheduleModal
          client={modalData.client}
          dashboard={modalData.dashboard}
          professional={modalData.professional}
          schedule={modalData.schedule}
          product={modalData.product}
        />
      )


    case modalTypes.USER_CANCEL:
      return (
        <ModelCancel
          classModifier={modalData.classModifier}
          hasButton={modalData.hasButton}
          btnId={modalData.btnId}
          btnText={modalData.btnText}
          modalTitle={modalData.modalTitle}
          titleColor={modalData.titleColor}
          notificationText={modalData.notificationText}
          scheduleId={modalData.scheduleId}
        />
      )

    case modalTypes.FILTER_STATUS:
      return (
        <FilterModal
          groupCode={modalData.groupCode}
          bool={modalData.bool}
          btnText={modalData.btnText}
          iconForAll={modalData.iconForAll}
          showPlanFilter={modalData.showPlanFilter}
          showStatusBox={modalData.showStatusBox}
          isSecretary={modalData.isSecretary}
        />
      )

    case modalTypes.ASSIGN_PERMISSION:
      return (
        <EditPermissions
          btnText={modalData.btnText}
          titleColor={modalData.titleColor}
          classModifier={modalData.classModifier}
          modalTitle={modalData.modalTitle}
          name={modalData.name}
          id={modalData.id}
          slug={modalData.slug}
        />
      )

    case modalTypes.USER_PROFILE_EMAIL:
      return (
        <EditEmailAccount {...modalData} />
      )

    case modalTypes.EDIT_PLAN:
      return (
        <EditPlanModal {...modalData} />
      )

    case modalTypes.FILE_BENEFICIARIES:
      return (
        <ModalFileBeneficiaries {...modalData} />
      )

    case modalTypes.ENABLE_NEW_PATIENTS:
      return (
        <EnableNewPatientsModal {...modalData} />
      )

    case modalTypes.INACTIVE_PROFESSIONAL:
      return (
        <InactiveProfessionalModal {...modalData} />
      )

    case modalTypes.ACTIVE_PROFESSIONAL:
      return (
        <ActiveProfessionalModal {...modalData} />
      )

    case modalTypes.USE_TERMS:
      return (
        <ModalTerms />
      )

    case modalTypes.PRIVACY_POLICY:
      return (
        <ModalPrivacyPolicy />
      )

    case modalTypes.UPLOAD_MEDICAL_REQUEST:
      return (
        <ModalUploadMedicalRequest
          cardNumber={modalData.cardNumber}
          validityId={modalData.validityId}
          username={modalData.username}
          onSuccessAction={modalData.onSuccessAction}
        />
      )

    case modalTypes.APPROVE_VALIDITY:
      return (
        <ModalApproveValidity
          cardNumber={modalData.cardNumber}
          validityId={modalData.validityId}
          username={modalData.username}
          onSuccessAction={modalData.onSuccessAction}
        />
      )

    case modalTypes.DISAPPROVE_REQUEST:
      return (
        <ModalRepproveRequest
          cardNumber={modalData.cardNumber}
          validityId={modalData.validityId}
          username={modalData.username}
          onSuccessAction={modalData.onSuccessAction}
        />
      )

    case modalTypes.INVALIDATE_REQUEST:
      return (
        <ModalInvalidateRequest
          cardNumber={modalData.cardNumber}
          validityId={modalData.validityId}
          username={modalData.username}
          onSuccessAction={modalData.onSuccessAction}
        />
      )

    case modalTypes.DISAPPROVE_DETAILS:
      return (
        <ModalRepproveDetails
          requestId={modalData.requestId}
          statusCode={modalData.statusCode}
        />
      )

    case modalTypes.LOGS_VIRTUAL_ROOM:
      return (
        <ModalLogsVirtualRoom
          virtualRoomHash={modalData.virtualRoomHash}
        />
      )

    case modalTypes.NEW_MEDICAL_REQUEST:
      return (
        <ModalNewMedicalRequest {...modalData} />
      )

    case modalTypes.SUCCESS_PLAN_ADDED:
      return (<SuccessPlanAdded />)

    case modalTypes.GENERAL_VIEW_FILTERS:
      return (<GeneralViewFilters />)

    case modalTypes.PROFESSIONAL_UNAVAILABILITY:
      return (<ProfUnavailabilityModal userData={modalData.userData} />)

    case modalTypes.REMOVE_UNAVAILABILITY:
      return (<RemoveUnavailabilityModal event={modalData.event} />)

    case modalTypes.SUCCESS_MODAL:
      return (<SuccessModal title={modalData.title} />)

    case modalTypes.PATIENT_DETAIL:
      return (<PatientDetail patientData={modalData.person} />)

    case modalTypes.FEEDBACK:
      return (
        <ModalGeneric
          feedbackShow={true}
          feedbackType={modalData.feedbackType}
          feedbackMessage={modalData.feedbackMessage}
          feedbackContent={modalData.feedbackContent}
          onSuccessClose={modalData.onSuccessClose}
        />
      )

    case modalTypes.REVIEW_ACTION:
      return (
        <ReviewActionModal {...modalData} />
      )

    default:
      return null
    }
  };

  render() {
    return this.handleMountModal()
  }
}

export const modalTypes = {
  FEEDBACK: 'FEEDBACK',
  GENERIC: 'GENERIC',
  USER_FILTER: 'USER_FILTER',
  NEW_USER_SUCCESS: 'NEW_USER_SUCCESS',
  DASHBOARD_MENU: 'DASHBOARD_MENU',
  NEW_SCHEDULE: 'NEW_SCHEDULE',
  USER_CANCEL: 'USER_CANCEL',
  USER_RESCHEDULE: 'USER_RESCHEDULE',
  RESCHEDULE: 'RESCHEDULE',
  ADMIN_CANCEL: 'ADMIN_CANCEL',
  ADMIN_CHANGE_SCHEDULE_STATUS: 'ADMIN_CHANGE_SCHEDULE_STATUS',
  FILTER_STATUS: 'FILTER_STATUS',
  ASSIGN_PERMISSION: 'ASSIGN_PERMISSION',
  USER_PROFILE_EMAIL: 'USER_PROFILE_EMAIL',
  EDIT_PLAN: 'EDIT_PLAN',
  FILE_BENEFICIARIES: 'FILE_BENEFICIARIES',
  USE_TERMS: 'USE_TERMS',
  PRIVACY_POLICY: 'PRIVACY_POLICY',
  UPLOAD_MEDICAL_REQUEST: 'UPLOAD_MEDICAL_REQUEST',
  APPROVE_VALIDITY: 'APPROVE_VALIDITY',
  DISAPPROVE_REQUEST: 'DISAPPROVE_REQUEST',
  INVALIDATE_REQUEST: 'INVALIDATE_REQUEST',
  DISAPPROVE_DETAILS: 'DISAPPROVE_DETAILS',
  SUCCESS_PLAN_ADDED: 'SUCCESS_PLAN_ADDED',
  NEW_MEDICAL_REQUEST: 'NEW_MEDICAL_REQUEST',
  LOGS_VIRTUAL_ROOM: 'LOGS_VIRTUAL_ROOM',
  GENERAL_VIEW_FILTERS: 'GENERAL_VIEW_FILTERS',
  PROFESSIONAL_UNAVAILABILITY: 'PROFESSIONAL_UNAVAILABILITY',
  REMOVE_UNAVAILABILITY: 'REMOVE_UNAVAILABILITY',
  SUCCESS_MODAL: 'SUCCESS_MODAL',
  ENABLE_NEW_PATIENTS: 'ENABLE_NEW_PATIENTS',
  PATIENT_DETAIL: 'PATIENT_DETAIL',
  INACTIVE_PROFESSIONAL: 'INACTIVE_PROFESSIONAL',
  ACTIVE_PROFESSIONAL: 'ACTIVE_PROFESSIONAL',
  REVIEW_ACTION: 'REVIEW_ACTION',
  CANCELED_DETAIL_MODAL: 'CANCELED_DETAIL_MODAL'
}

const mapStateToProps = state => {
  return { userToken: state.auth.userToken }
}

const mapDispatchToProps = dispatch => {
  return { onHideModal: () => dispatch(actions.hideModal()) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalManager)
