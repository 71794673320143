export const formatSkillsData = (skillsObject, isTogglingSkill) => {
  const removedSkills = skillsObject.skillsRemoved
  const addedSkills = skillsObject.skillsAdded

  let removeSkills = []
  let addSkills = []

  removedSkills.length > 0 &&
    removedSkills.map(skill => {
      return removeSkills.push({
        id: skill.id,
        delete: true,
      })
    })

  addedSkills.length > 0 &&
    addedSkills.map(skill => {
      return addSkills.push({ [isTogglingSkill ? 'skill_id' : 'subspecialty_id']: skill })   
    })

  return [
    ...removeSkills,
    ...addSkills,
  ]
}

export const getSkillsByType = (skills, skillType) => {
  let getSkills = []

  skills.map(skill => {
    const getType = getSkillType(skill.skill_code)
    if (getType === skillType) getSkills.push(skill)
    return getSkills
  })

  return getSkills
}

export const getSkillsDifference = (original, change) => {
  let skillsRemoved = original.filter(item => !change.includes(item))
  let skillsAdded = change.filter(item => !original.includes(item))

  let newSelection = [
    ...original,
    ...skillsAdded,
  ]

  newSelection = newSelection.filter(item => !skillsRemoved.includes(item))

  return {
    newSelection: newSelection.length === 0 ? null : newSelection,
    skillsAdded,
    skillsRemoved,
  }
}

export const getSkillsIds = (skills) => {
  let skillsIds = []
  skills.map(skill => skillsIds.push({
    id: skill.id,
    skill_id: skill.skill_id,
  }))

  return skillsIds
}

export const getSkillsNames = (skills) => skills.map(skill => {
  let skillsNames = []

  return skillsNames = [
    ...skillsNames,
    skill.skill_name,
  ]
})

export const getSkillType = (skill) => skill.substring(0, 3)