import React from "react";

const House = ({
    fill = "#1f35b5",
    width = "24",
    height = "24",
    viewBox = "0 0 24 24",
}) => (
        <svg id="Grupo_1316" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <path id="Caminho_2521" d="M10,20V14h4v6h5V12h3L12,3,2,12H5v8Z" fill={fill} />
            <path id="Caminho_2522" d="M0,0H24V24H0Z" fill="none" />
        </svg>
    );

export default House;