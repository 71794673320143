import { SET_PAYMENT_RESPONSE, CLEAR_PAYMENT_RESPONSE } from './_actionTypes';

export const setPayment = ({ cardRegisterSuccess, paymentSucess, paymentConfirmed, overbooking }) =>
  dispatch => {

    let payload = {};

    if (cardRegisterSuccess)
      payload.cardRegisterSuccess = cardRegisterSuccess;

    if (paymentSucess)
      payload.paymentSucess = paymentSucess;

    if (paymentConfirmed)
      payload.paymentConfirmed = paymentConfirmed;

    if (overbooking)
      payload.overbooking = overbooking;

    return dispatch({
      type: SET_PAYMENT_RESPONSE,
      payload,
    });

  };

export const clearPayment = () =>
  dispatch => {
    return dispatch({
      type: CLEAR_PAYMENT_RESPONSE
    });
  };