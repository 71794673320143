import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import * as route from '../../settings/_routesSettings'

// STYLE
import './ChartCard.sass'

// COMPONENTS
import { ChartLabel, Typography, ChartDoughnut, ChartBar, Spinner } from 'components'

const ChartCard = ({ type, title, link, spaced, data, percents, labels, colors, loading, total, hideLabels, solo, bottom, correct }) => {

  // RENDER CHART BY TYPE
  const chart = () => ({
    'doughnut': (
      <div className='ChartCard__doughnut__container'>
        <ChartDoughnut 
          data={data} 
          percents={percents} 
          labels={labels} 
          colors={colors} 
          total={total} 
          empty={total === 0} 
          labelSubtitle='neste período'
        />
      </div>
    ),
    'bar': <ChartBar data={data} labels={labels} colors={colors} total={total} empty={total === 0} />,
  }[type] || <p>Sem gráfico</p>)

  return (
    <div className={classNames('ChartCard', {
      'ChartCard--spaced': spaced
    })}>
      <p className={classNames('ChartCard__title', {
        'ChartCard__title--correction': correct && !loading
      })}>{title}</p>

      {/* CHART AREA */}
      <div className={classNames('ChartCard__chart', {
        'ChartCard__chart--solo': solo
      })}>
        { loading ? <Spinner height="42px" /> : chart() }
      </div>

      {/* CHART LABELS */}
      {
        !hideLabels && !loading &&
        <div className="ChartCard__labels">
          {
            data.map((item, index) => (
              <ChartLabel key={index}
                label={labels[index]}
                value={item}
                percent={percents[index]}
                color={colors[index]} />
            ))
          }
        </div>
      }

      {/* LINK UNDER CHART */}
      {
        link ? <Link to={route.DASHBOARD_ADMIN_SCHEDULES}><Typography type="paragraph-medium" className="ChartCard__link">{link}</Typography></Link> : null
      }

      {/* BOTTOM TEXT INFO */}
      {
        bottom && !loading &&
          (
            <div className="ChartCard__bottom">
              <p className="ChartCard__text">{total}&nbsp;{total === 1 ? 'resposta neste período' : 'respostas neste período'}</p>
              <p className="ChartCard__text ChartCard__text--sub"><i>*Escala NPS (Net Promoter Score)</i></p>
            </div>
          )
      }
    </div>
  )
}

export {
  ChartCard
}