import { SET_PAYMENT_RESPONSE, CLEAR_PAYMENT_RESPONSE } from './../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

export const initialState = {
  cardRegisterSuccess: null,
  paymentSucess: false,
  paymentConfirmed: false,
  overbooking: false
};

const setPayment = (state, { payload }) => {
  return updateState(state, { ...payload });
};

const clearPayment = (state) => {
  return updateState(state, initialState);
};

const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAYMENT_RESPONSE: return setPayment(state, action);
    case CLEAR_PAYMENT_RESPONSE: return clearPayment(state);
    default: return state;
  }
};

export default paymentReducer;