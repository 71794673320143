import React from 'react'
import classNames from 'classnames'

import './HourFilter.sass'

import { TextInput } from 'components'

const HourFilter = ({ start, final, error }) => {
  return (
    <div className="HourFilter">

      <div className="HourFilter__group">
        <div className="HourFilter__field">
          <TextInput
            id={start.id}
            name={start.id}
            placeholder={start.placeholder}
            mask="11"
            onInputChange={(e) => start.onChange(e.target.value.replace(/_/g, ''))}
            value={start.defaultValue}
            hasError={error.condition}
          />
          <span>:</span>
          <TextInput
            disabled
            mask="11"
            placeholder="00"
            type="tel"
          />
        </div>
        <p className="HourFilter__text">às</p>
        <div className="HourFilter__field">
          <TextInput
            id={final.id}
            name={final.id}
            placeholder={final.placeholder}
            mask="11"
            onInputChange={(e) => final.onChange(e.target.value.replace(/_/g, ''))}
            value={final.defaultValue}
            hasError={error.condition}
          />
          <span>:</span>
          <TextInput
            disabled
            mask="11"
            placeholder="00"
            type="tel"
          />
        </div>
      </div>
      <p className={classNames('HourFilter__errorMessage', {
        'HourFilter__errorMessage--hidden': !error.condition
      })}>{error.message}</p>
    </div>
  )
}

export { HourFilter }
