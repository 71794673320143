import React from "react";

const MoreOptions = ({
  fill,
  height,
  viewBox = "-10 -10 45 45",
  width,
}) => (
  <svg 
    height={height}
    fill={fill}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g>
      <path d="M12 0a12 12 0 1 0 12 12A12 12 0 0 0 12 0zm0 22.154A10.154 10.154 0 1 1 22.154 12 10.152 10.152 0 0 1 12 22.154z"/>
      <circle cx="1.655" cy="1.655" r="1.655" transform="translate(5.289 10.345)" />
      <circle cx="1.655" cy="1.655" r="1.655" transform="translate(10.345 10.345)" />
      <circle cx="1.655" cy="1.655" r="1.655" transform="translate(15.4 10.345)" />
    </g>
  </svg>
);

export default MoreOptions;