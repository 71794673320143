import React, { useState } from 'react'
import { Typography, TextBox, Button, Spinner, Icon, Paper } from '@telavita-core/react-design-kit'

import ReservationService from 'services/reservation'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import * as S from './styles'

interface CancelNonRecurrentReservationModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  onRefresh: () => void
  reservationUUID: string
  reservationDate: Date
  patientUsername: string
  patientFullName: string
  professionalUsername: string
}

export const CancelNonRecurrentReservationModal: React.FC<CancelNonRecurrentReservationModalProps> = ({
  open,
  setOpen,
  onRefresh,
  reservationUUID,
  reservationDate,
  patientUsername,
  patientFullName,
  professionalUsername,
}) => {
  const [reason, setReason] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState(false)

  const hour = format(reservationDate, 'HH')
  const minute = format(reservationDate, 'mm')
  const time = `${hour}h${minute}`

  const weekday = format(reservationDate, 'EEEE', { locale: ptBR })
  const date = format(reservationDate, 'dd/MM/yyyy')
  
  const onSubmit = () => {
    setSubmitting(true)

    ReservationService.deleteAllReservation({
      uuid: reservationUUID,
      professional_username: professionalUsername,
      patient_username: patientUsername,
      reason: reason,
    })
      .then(() => {
        setSubmitting(false)
        setSuccess(true)
        setReason('')
      })
      .catch(() => {
        setSubmitting(false)
        setReason('')
      })
  }

  const handleClose = () => {
    setOpen(false)
    if (success) {
      onRefresh()
    }
  }

  const getTitle = () => {
    if (success || submitting) return null

    return (
      <Typography variant='header4'>
        Cancelar reserva de{' '}
        <span style={{ color: '#FD4949' }}>
          {date}
        </span>  
      </Typography>
    )
  }

  const getSubtitle = () => {
    if (success || submitting) return null

    return (
      <>
        <Typography
          variant='content2'
          inheritColor
          inlineStyles={{ color: '#898989', textTransform: 'capitalize' }}
          center
        >
          <b>{weekday}, {time}</b>
        </Typography>
        <Typography
          variant='content2'
          inheritColor
          inlineStyles={{ color: '#898989' }}
          center
        >
          Paciente <strong>{patientFullName}</strong>
        </Typography>
      </>
    )
  }

  if (!open) return null

  return (
    <ModalGeneric modalTitle={getTitle()} modalSubtitle={getSubtitle()} onCloseModal={handleClose}>
      {
        (!submitting && !success) &&
        <S.Container>
          <Typography variant='content2' color='danger' customClassName='attentionText'>
            <strong>Atenção:</strong> certifique-se da necessidade de avisar o paciente, por telefone e/ou e-mail.
          </Typography>
          
          <div>
            <Typography variant='content2' weight='bold'>
              Motivo do cancelamento*
            </Typography>
            <Typography variant='content3' italic customClassName='reasonDisclaimerText'>
              O paciente receberá esta mensagem como justificativa. Escreva uma mensagem amigável, como se estivesse falando com o paciente.
            </Typography>
            <TextBox
              maxLength={1000}
              placeholder='Digite o motivo'
              value={reason}
              onChange={({target}) => setReason(target.value)}
            />
            
            <Paper variant='warning' customClassName='warningPaper'>
              <Typography variant='content2'>
                Ao cancelar a reserva, o horário voltará a ser disponibilizado para outros pacientes agendarem (caso esteja cadastrado em sua disponibilidade).
              </Typography>
            </Paper>
            
            <Typography
              variant='content2'
              italic
              customClassName='requiredInfoText'
            >
              *Preenchimento obrigatório
            </Typography>
            <Button
              variant='contained'
              disabled={reason.length < 5}
              color='danger'
              onClick={onSubmit}
              customClassName='actionButton'
            >
              CONFIRMAR CANCELAMENTO
            </Button>
          </div>

        </S.Container>
      }

      {submitting && 
        <S.SpinnerContainer>
          <Spinner /> 
        </S.SpinnerContainer>
      }
      
      {
        (!submitting && success) &&
        <S.Container>
          <Icon
            name='Check'
            color='success'
            height={50}
            width={50}
          />
          <Typography variant='header4' weight='bold' center customClassName='successTitle'>
            Cancelamento de reserva <br/> efetuado com sucesso!
          </Typography>
          
          <Typography variant='content2' center>
            O paciente receberá email avisando <br/> que a reserva foi cancelada.
          </Typography>
          
          <Paper variant='warning' customClassName='warningPaper'>
            <Typography variant='content2'>
              <b>IMPORTANTE:</b> Se no horário desta reserva cancelada havia uma consulta agendada, ela foi cancelada automaticamente.
            </Typography>
          </Paper>
          <Button
            variant='contained'
            color='primary'
            onClick={handleClose}
            customClassName='actionButton'
          >
            FECHAR
          </Button>
        </S.Container>
      }
    </ModalGeneric>
  )
}