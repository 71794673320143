import React, { Component } from "react";
import PropTypes from "prop-types";

// COMPONENS
import { Typography, CoreIcons } from 'components';

class TextToggleFilter extends Component {
  
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  onSelect = () => {
    this.setState(prevState => ({ isSelected: !prevState.isSelected }));
  };

  render() {
    const { isSelected, itemId, itemName, itemTitle, onSelect } = this.props;

    return (
      <div
        className={isSelected ? "TextToggleFilter--active" : "TextToggleFilter"}
        id={itemId}
        onClick={onSelect}
        ref={node => (this.wrapperRef = node)}>
        <Typography
          align="center"
          color={isSelected ? "white" : "color-title"}
          bold>
          {itemTitle}
        </Typography>
        {isSelected ? (
          <CoreIcons
            height="20px"
            fill={isSelected ? "#fff" : "#585858"}
            name={itemName}
          />
        ) : null}
      </div>
    );
  }
}

TextToggleFilter.propTypes = {
  isSelected: PropTypes.bool,
  itemId: PropTypes.string,
  itemName: PropTypes.string,
  itemTitle: PropTypes.string,
  onSelect: PropTypes.func,
};

export default TextToggleFilter;
