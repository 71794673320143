import React from "react";

const ArrowUp = ({
  fill = "#acacac",
  height = "24px",
  viewBox = "0 0 24 24",
  width = "24px",
}) => (
    <svg
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g transform="translate(-1290 -950)">
        <path fill="none" d="M0,0H24V24H0Z" transform="translate(1290 950)" />
        <path fill={fill} d="M111-20l5,5,5-5Z" transform="translate(1418 945) rotate(180)" />
      </g>
    </svg>
  )

export default ArrowUp;