export const planGroupsSettings = {
  // Careplus
  GPL01001: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null
    },
    identifier: {
      mask: '111111111111',
      placeholder: 'Código Careplus (12 dígitos)'
    }
  },

  // CASSI
  CASSI: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null
    },
    identifier: {
      mask: '1111111111111111',
      placeholder: 'Código CASSI (16 dígitos)'
    },
    diseaseInfo: {
      cid: true,
      placeholder: 'Número do CID',
      helperText: true,
    },
    doctorInfo: {
      crm: true,
      uf: true
    },
    hasSolicitationType: true
  },

  // SAMI
  SAMISAUD: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '1111111111111111111',
      placeholder: 'Carteirinha (19 dígitos)',
    },
    hasSolicitationType: true,
  },

  // GOL
  GOLCARE: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // WTW
  WTWW: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // SPECDECO
  SPECDECO: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // ALTEOCYA
  ALTEOCYA: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // Onmnia
  ONMNIA: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // Grupo Becomex
  GRUPBECO: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  BOSTON: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // 11CARE
  '11CARE': {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  ROSAMAS9: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  // Cuidar Omint
  CUIDOMIN: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar avaliação técnica',
      solicitationText:
        '*A avaliação técnica é obrigatória para a criação de uma nova solicitação de Terapia (50000470). Caso não exista, o profissional deverá emitir o documento.',
      helperText: true,
    },
    identifier: {
      mask: '1111111111111',
      placeholder: 'Carteirinha (13 dígitos)'
    }
  },

  // Omint
  GPL01008: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar avaliação técnica',
      solicitationText:
        '*A avaliação técnica é obrigatória para a criação de uma nova solicitação de Terapia (50000470). Caso não exista, o profissional deverá emitir o documento.',
      helperText: true,
    },
    identifier: {
      mask: '11111111.11.11.1',
      placeholder: 'Código Omint (13 dígitos)'
    }
  },
  // Programa Viva
  GPL01012: { plansPlaceholder: 'Medicamento' },
  // Bradesco Saúde
  GPL01013: {
    plansPlaceholder: 'Tipo de rede',
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText:
        '*O pedido médico com CID é obrigatório para a criação de uma nova solicitação Bradesco. Caso não possua, o paciente deverá procurar um médico e obter o encaminhamento.',
      helperText: true,
    },
    identifier: {
      mask: '111111111111111',
      placeholder: 'Carteirinha (15 dígitos)'
    },
    diseaseInfo: {
      cid: true,
      placeholder: 'Número do CID',
      helperText: true,
    },
    doctorInfo: {
      crm: true,
      uf: true
    },
    hasSolicitationType: true
  },
  // Bradesco Seguros
  BRADSEGU: {
    plansPlaceholder: 'Tipo de rede',
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText:
        '*O pedido médico com CID é obrigatório para a criação de uma nova solicitação Bradesco. Caso não possua, o paciente deverá procurar um médico e obter o encaminhamento.',
      helperText: true,
    },
    identifier: {
      mask: '111111111111111',
      placeholder: 'Carteirinha (15 dígitos)'
    },
    diseaseInfo: {
      cid: true,
      placeholder: 'Número do CID',
      helperText: true,
    },
    doctorInfo: {
      crm: true,
      uf: true
    },
    hasSolicitationType: true
  },
  SAUDEID: {
    plansPlaceholder: 'Tipo de plano',
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText:
        '*O pedido médico com CID é obrigatório para a criação de uma nova solicitação Saúde Id. Caso não possua, o paciente deverá procurar um médico e obter o encaminhamento.',
      helperText: true,
    },
    identifier: {
      placeholder: 'Número da carteirinha'
    },
    diseaseInfo: {
      cid: true,
      placeholder: 'Número do CID',
      helperText: true,
    },
    doctorInfo: {
      crm: true,
      uf: true
    },
    hasSolicitationType: true
  },
  // Sompo
  SOMPO: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar avaliação técnica',
      solicitationText:
        '*A avaliação técnica é obrigatória para a criação de uma nova solicitação de Terapia (50000470). Caso não exista, o profissional deverá emitir o documento.',
      helperText: true,
    },
    identifier: {
      mask: '111111111111111111',
      placeholder: 'Carteirinha (18 dígitos)'
    }
  },

  // Mediservice
  MEDISERV: {
    plansPlaceholder: 'Tipo de plano',
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText:
        '*O pedido médico com CID é obrigatório para a criação de uma nova solicitação Mediservice. Caso não possua, o paciente deverá procurar um médico e obter o encaminhamento.',
      helperText: true,
    },
    identifier: {
      mask: '111111111111111111',
      placeholder: 'Carteirinha (18 dígitos)'
    },
    diseaseInfo: {
      cid: true,
      placeholder: 'Número do CID',
      helperText: true,
    },
    doctorInfo: {
      crm: true,
      uf: true
    },
    hasSolicitationType: true
  },

  // Unimed Anhanguera
  UNIMANHA: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111111111',
      placeholder: 'Carteirinha (17 dígitos)',
    },
    hasSolicitationType: true,
  },

  // Unimed Noroeste
  UNIMNORO: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111111111',
      placeholder: 'Carteirinha (17 dígitos)',
    },
    hasSolicitationType: true,
  },

  ALICE: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'Carteirinha (CPF)',
    },
    hasSolicitationType: true,
  },

  // Santa Helena - Grupo Amil
  SANTHELE: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '111111111',
      placeholder: 'Carteirinha (9 dígitos)',
    },
    hasSolicitationType: true,
  },

  PMESP: {
    medicalRequest: {
      enabled: true,
      required: true,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'Carteirinha (CPF)',
    },
    hasSolicitationType: true,
  },

  // ASA - Cuidar de quem cuida
  ACDQC: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  TAKAOKA: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  ALBRAS: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },

  DATALINK: {
    medicalRequest: {
      enabled: true,
      required: false,
      placeholder: 'Anexar pedido médico',
      solicitationText: null,
      helperText: null,
    },
    identifier: {
      mask: '11111111111',
      placeholder: 'CPF (11 dígitos)',
    },
    hasSolicitationType: true,
  },
}

export const plansType = {
  PARTICULAR: 'particular',
  CAREPLUS: 'care-plus',
  CORPORATE: 'corporate', // empresas como Zup, Dextra
  ENTERPRISE: 'enterprise', // seguradoras como Bradesco, Omint
  CAPITATION: 'capitation', // empresas como Gol, WTW
}

export enum PlanGroupCategoryEnum {
  CORPORATE_LIMITED = 'CORPORATE_LIMITED',
  CORPORATE_UNLIMITED = 'CORPORATE_UNLIMITED',
  ENTERPRISE = 'ENTERPRISE',
  PARTICULAR = 'PARTICULAR',
  CARE_PLUS = 'CARE_PLUS',
  VITTA = 'VITTA',
  CAPITATION = 'CAPITATION',
}
