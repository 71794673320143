import React from "react";

const AgendaTime = ({
  fill,
  height,
  viewBox = "0 0 67 67",
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={width} 
    height={height} 
    viewBox={viewBox}>
    <path fill={fill} xmlns="http://www.w3.org/2000/svg" data-name="Caminho 2167" d="M54.543 33.9V5.929A5.931 5.931 0 0 0 48.614 0H10.671a5.931 5.931 0 0 0-5.928 5.929v4.743H3.557a3.557 3.557 0 0 0 0 7.114h1.186v8.3H3.557a3.557 3.557 0 1 0 0 7.114h1.186v8.3H3.557a3.557 3.557 0 0 0 0 7.114h1.186v4.743a5.931 5.931 0 0 0 5.929 5.929H36.2A16.59 16.59 0 1 0 54.543 33.9zM2.371 14.229a1.189 1.189 0 0 1 1.186-1.186h5.929a1.186 1.186 0 1 1 0 2.371H3.557a1.189 1.189 0 0 1-1.186-1.185zm0 15.414a1.189 1.189 0 0 1 1.186-1.186h5.929a1.186 1.186 0 0 1 0 2.371H3.557a1.189 1.189 0 0 1-1.186-1.185zm0 15.414a1.189 1.189 0 0 1 1.186-1.186h5.929a1.186 1.186 0 1 1 0 2.371H3.557a1.189 1.189 0 0 1-1.186-1.185zm8.3 11.857a3.568 3.568 0 0 1-3.557-3.557v-4.743h2.372a3.557 3.557 0 1 0 0-7.114H7.114v-8.3h2.372a3.557 3.557 0 1 0 0-7.114H7.114v-8.3h2.372a3.557 3.557 0 1 0 0-7.114H7.114V5.929a3.568 3.568 0 0 1 3.557-3.557h37.943a3.568 3.568 0 0 1 3.557 3.557V33.39a15.609 15.609 0 0 0-2.371-.19 16.618 16.618 0 0 0-16.6 16.6 16.444 16.444 0 0 0 1.624 7.114zM49.8 64.029A14.229 14.229 0 1 1 64.029 49.8 14.24 14.24 0 0 1 49.8 64.029zM59.286 49.8a1.189 1.189 0 0 1-1.186 1.186h-8.3a1.189 1.189 0 0 1-1.186-1.186v-9.486a1.186 1.186 0 0 1 2.371 0v8.3H58.1a1.189 1.189 0 0 1 1.186 1.186z"/>
  </svg>
);

export default AgendaTime;