import React from "react";

const StatusIcon = ({
  fill = "#9f9f9f",
  height = "13px",
  viewBox = "0 0 13.713 13.713",
  width = "13px",
}) => (
  <svg
    fill={fill}
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <ellipse
      cx="6.857"
      cy="6.857"
      rx="6.857"
      ry="6.857"
    />
  </svg>
);

export default StatusIcon;

