import { useQuery } from '@tanstack/react-query'
import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'

interface RecurrencyException {
  user_uuid: string
  exception_date_utc: string
  exception_type: string
  next_available_date: string
}

interface PatientReservation {
  reservation_uuid: string
  recurrence: 'Semanal'| 'Avulsa'
  patient_username: string
  professional_full_name: string
  professional_picture_url: string
  professional_username: string
  start_date: string
  recurrency_exceptions: RecurrencyException[]
}

export interface PatientReservationData {
  reservations: PatientReservation[]
}

interface UseGetPatientReservations {
  quantityOfRecurrentReservations: number
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
}

async function getPatientReservations(patientUsername: string) {
  const isProfessional = false
  const { data } = await axios.get<PatientReservationData>(apiEndpoints.RESERVATIONS(patientUsername, isProfessional))
  
  return data
}

export const useGetPatientReservations = (patientUsername: string): UseGetPatientReservations => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ['patientReservations', patientUsername],
    queryFn: () => getPatientReservations(patientUsername),
    enabled: !!patientUsername,
    cacheTime: 0,
    staleTime: 0,
  })

  if (!data || isLoading || isError) {
    return { 
      quantityOfRecurrentReservations: 0, 
      isLoading, 
      isError,
      isSuccess
    }
  }
  
  const quantityOfRecurrentReservations = data.reservations
    .filter(reservation => reservation.recurrence === 'Semanal')?.length

  return { 
    quantityOfRecurrentReservations,
    isLoading,
    isError,
    isSuccess
  }
}