import React, { Component, Fragment } from "react";
import { Trans } from "@lingui/macro";
import { connect } from "react-redux";
import Sticky from "react-sticky-el";

// COMPONENTS
import { Typography, Button, CardEmptyPlan, CardPlan, CoreIcons, TextInput, RadioButton, Spinner } from 'components';
import Dropdown from '../../../components/Dropdown';
import Row from "../../../components/Row";

import * as routes from "../../../settings/_routesSettings";
import InputValidation from '../../../utils/InputValidation';
import viewportHoc from "../../../hoc/viewportHoc";
import { hideIntercom } from '../../../utils/intercomHandlers';
import { PERSON_STATUS } from "../../../settings/_personSettings";
import { history } from '../../../routers/AppRouter';
import { modalTypes } from '../../../containers/ModalManager/ModalManager';
import {
  authChangePlan,
  authUser,
  clearPersons,
  getAllPlansGroups,
  getPersonPlans,
  setPersonPlans,
  showModal,
} from "../../../redux/actions";
import { getAuthToken } from "utils/authHelper";

const initialState = {
  isUserPlansLoading: true,
  isReadyToSubmit: false,
  availablePlans: [],
  fields: {
    selectedPlan: {
      isValid: false,
      value: '',
      message: '',
    },
    card_number: {
      isValid: false,
      value: '',
      message: '',
    },
    hasMedicRequest: null,
    medicRequest: {
      isValid: false,
      message: '',
      placeholder: "Anexar avaliação técnica",
      value: '',
    },
  },
  hasError: false,
  errorMessage: '',
  showModal: true,
  successSubmit: false,
};

/**
 * This view is responsible for user's 
 * plans management. In this view the 
 * user should be able to manage its
 * assingned plans and also add new plans
 */
class Plans extends Component {
  state = initialState

  async componentDidMount() {
    hideIntercom();
    /**
     * Get user's plans and available plans
     * so the view can be loaded
     */
    await this.props.onClearPersons();
    await this.props.onGetGroupPlans();
    await this.props.onGetPersonPlans(this.props.userData.username, this.props.profileSelected.profile_code, { is_particular: false });
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     *  On load plans set loading to false
     */
    if (prevProps.personPlans !== this.props.personPlans) {
      this.setState({ isUserPlansLoading: false });
    }
    /**
     * Check if all fields are valid to
     * change 'isReadyToSubmit'
     */
    if (prevState.fields !== this.state.fields) {
      const { fields } = this.state;

      let isReadyToSubmit = fields.selectedPlan.isValid &&
        fields.card_number.isValid &&
        (fields.hasMedicRequest !== null);

      if (fields.hasMedicRequest)
        isReadyToSubmit = fields.medicRequest.isValid && (fields.medicRequest.value !== '');

      this.setState({ isReadyToSubmit });
    }
  }

  componentWillUnmount() {
    /**
     * Clear persons to clear stored
     * plans on redux
     */
    this.props.onClearPersons();
  }

  /**
   * Handle on click "ver profissionais" 
   * changing user's seleted plan and 
   * sending to "HOME"
   */
  handleSeeProfessionals = (planCode) => {
    const { onChangePlan, profileSelected } = this.props;
    const planSelected = profileSelected.plans.find(profilePlan => profilePlan.partner_plan_code === planCode);
    onChangePlan(planSelected);
    history.push(routes.HOME_PSIC);
  }

  /**
   *  Get and format plans from selected group plan
   *  and save it for the next dropdown
   */
  handleGroupPlan = (id) => {
    const { groupPlans } = this.props;

    this.setState({ availablePlans: [], hasError: false });

    const getPlans = groupPlans.find(plan => plan.id === id);

    const plans = getPlans.plans.map(plan => {
      return {
        code: plan.code,
        name: `${getPlans.name} ${plan.name}`,
      };
    });

    this.handleAvailablePlans(plans);
  }

  /**
   * Returns from the system plans,
   * the plans that the user is not
   * assigned
   */
  handleAvailablePlans = (plans) => {
    const { personPlans } = this.props;

    const availablePlans = [];
    const personPlanCodes = personPlans.map(personPlan => personPlan.plan.code);

    plans.map(plan => {
      const planCode = plan.code;
      if (!personPlanCodes.includes(planCode)) {
        availablePlans.push(plan);
      }
      return plan;
    });

    if (availablePlans.length === 0)
      this.setState({
        hasError: true,
        errorMessage: "Não há planos disponíveis no momento.",
      });
    else
      this.setState({ availablePlans });
  }

  /**
   * Change selectedPlan state based
   * on Dropdown component 
   */
  handleSelectPlan = (code) => {
    this.setState({
      fields: {
        ...this.state.fields,
        selectedPlan: {
          isValid: true,
          value: code,
        },
      },
      hasError: false,
      messageError: '',
    });
  }

  /**
   * Change card number value
   * there's no validation needed
   */
  handleCardInput = (e) => {
    const inputValue = e.target.value;
    const isValid = (inputValue !== '') && (inputValue.length === 12);

    if (inputValue.length <= 12) {
      this.setState({
        fields: {
          ...this.state.fields,
          card_number: {
            isValid: isValid,
            value: inputValue,
            message: '',
          },
        },
      });
    }
  }

  /**
    * Sets "hasMedicRequest" state based on
    * value received by the method
    */
  handleMedicRequestRadio = (value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        hasMedicRequest: value,
      },
    });
  }

  /**
   * Sets "medicRequest" state based on
   * event received by the method
   */
  handleMedicRequestFile = async(e) => {
    let getFile = e.target.files;
    let imgName = getFile[0] ? getFile[0].name : 'Anexar avaliação técnica';

    let validation = new InputValidation(getFile, 'imageOrPdf');
    let imageIsValid = await validation.validate();

    this.setState({
      fields: {
        ...this.state.fields,
        medicRequest: {
          placeholder: imgName,
          value: imageIsValid.encodedImage,
          isValid: imageIsValid.success,
          message: imageIsValid.message,
        },
      },
    });
  }

  /**
   * Async set block to loading, set a
   * new plan to the user, reset fields 
   * and loading states, get user's updated
   * data and finally get person's updated
   * plans, setting "isAvailablePlansLoading"
   * to false
   */
  handleSubmit = async() => {
    const {
      onAuthUser,
      onGetPersonPlans,
      onSetPersonPlans,
      profileSelected,
      userData,
    } = this.props;

    const { fields } = this.state;

    const userToken = getAuthToken();

    this.setState({ isReadyToSubmit: false });

    let treatmentValidity = { our_evaluation: fields.hasMedicRequest ? false : true };

    if (fields.hasMedicRequest) treatmentValidity.medical_request = fields.medicRequest.value;

    await onSetPersonPlans(userData.username, profileSelected.profile_code, {
      card_number: fields.card_number.value,
      plan_code: fields.selectedPlan.value,
      treatment_validity: treatmentValidity,
    });

    if (this.props.errorList)
      await this.setState({
        isUserPlansLoading: false,
        hasError: true,
        errorMessage: "Não foi possível vincular o plano.",
        fields: {
          ...this.state.fields,
          card_number: {
            isValid: false,
            value: '',
            message: '',
          },
        },
      });
    else {
      await this.setState({
        ...initialState,
        successSubmit: true,
      });
      this.props.onShowModal();
      await onAuthUser(userToken);
      await onGetPersonPlans(userData.username, profileSelected.profile_code, { is_particular: false });
    }
  }

  /**
   * Checks if isAvailablePlansLoading and
   * isUserPlansLoading to render the spinner
   * or the pages content
   */
  loadAndRender = () => {
    const { isUserPlansLoading } = this.state;

    return isUserPlansLoading ?
      this.renderSpinner(50) :
      this.renderContent();
  }

  /**
   * Renders Spinner
   */
  renderSpinner = (height) => <Spinner height={`${height}vh`} />

  /**
   * If isUserPlansLoading renders the spinner,
   * else if there are personPlans, renders
   * a the list of personPlans
   */
  renderPlanList = () => {
    const { isUserPlansLoading } = this.state;
    const { personPlans } = this.props;

    return isUserPlansLoading ?
      this.renderSpinner(20) : (
        <Fragment>
          <div className='Plans__List'>
            {
              personPlans.length <= 0 ?
                <CardEmptyPlan /> :
                personPlans.map(personPlan => {
                  const statusWeigth = PERSON_STATUS[personPlan.status].weight;
                  return (
                    <CardPlan key={personPlan.plan.code}
                      onClickProfessionals={() => this.handleSeeProfessionals(personPlan.plan.code)}
                      planId={personPlan.plan.code}
                      planImage={personPlan.plan.plan_group.logo_url}
                      planName={personPlan.plan.name}
                      planStatusWeight={statusWeigth}
                      planStatusName={personPlan.status_name}
                    />
                  );
                })
            }
          </div>
          {this.renderAddPlans()}
        </Fragment>
      );
  }

  /**
   * Calls handleAvailablePlans and if there
   * are available plans, renders a form to
   * add new plans
   */
  renderAddPlans = () => {
    const {
      availablePlans,
      isReadyToSubmit,
      isUserPlansLoading,
      fields,
      hasError,
      errorMessage,
    } = this.state;

    let { groupPlans } = this.props;

    const hasAvailablePlans = groupPlans.length > 0;

    return isUserPlansLoading ?
      this.renderSpinner(20) : hasAvailablePlans && (
        <div className="Plans__Add">
          <div className="Plans__Add__Label">
            <Typography>
              <Trans>
                Vincular convênio
              </Trans>
            </Typography>
          </div>

          <div className="Plans__Add__Dropdown">
            <Dropdown
              options={groupPlans}
              onSelect={(id) => this.handleGroupPlan(id)}
              name="user_profile_partner_plan_group"
              placeholder={(<Trans>Planos e empresas disponíveis</Trans>)}
            />
          </div>
          {
            availablePlans.length > 0 && (
              <Fragment>
                <div className="Plans__Add__Dropdown">
                  <Dropdown
                    options={availablePlans}
                    onSelect={(code) => this.handleSelectPlan(code)}
                    name="user_profile_partner_plan"
                    placeholder={(<Trans>Tipo de plano</Trans>)}
                  />
                </div>

                <div className="Plans__Add__Input">
                  <TextInput name="card_number"
                    placeholder="Código Careplus (12 dígitos)"
                    onInputChange={(e) => this.handleCardInput(e)}
                    value={fields.card_number.value}
                    hasError={fields.card_number.message !== ''}
                    messageError={fields.card_number.message}
                  />
                </div>

                <div className="Register__InfoGroup__Forms__Item--radio">
                  <div className="Register__InfoGroup__Forms__Item--radio__Item">
                    <Typography bold>
                      <Trans>
                        Tem pedido médico?
                      </Trans>
                    </Typography>
                  </div>

                  <div className="Register__InfoGroup__Forms__Item--radio__Item">
                    <RadioButton
                      iconHeight="21px"
                      iconWidth="21px"
                      onClickRadioButton={(value) => this.handleMedicRequestRadio(value)}
                      radioId="has_medic_request_true"
                      selected={this.state.fields.hasMedicRequest === true}
                      title="Sim"
                      value={true}
                    />
                  </div>

                  <div className="Register__InfoGroup__Forms__Item--radio__Item">
                    <RadioButton
                      iconHeight="21px"
                      iconWidth="21px"
                      onClickRadioButton={(value) => this.handleMedicRequestRadio(value)}
                      radioId="has_medic_request_false"
                      selected={this.state.fields.hasMedicRequest === false}
                      title="Não"
                      value={false}
                    />
                  </div>
                </div>
                {
                  this.state.fields.hasMedicRequest && (
                    <div className="Register__InfoGroup__Forms__Item">
                      <div className="Register__InfoGroup__Forms--image-wrapper">
                        <TextInput
                          addIcon={true}
                          placeholder={this.state.fields.medicRequest.placeholder}
                          name="medic_request"
                          hasError={!this.state.fields.medicRequest.isValid}
                          iconProps={{ fill: '#898989', height: '20px', name: 'UploadFile', width: '20px' }}
                          inputHeight='4.8rem'
                          messageError={this.state.fields.medicRequest.message}
                        />
                        <input id="hiddenInput-file" type='file' onChange={(e) => this.handleMedicRequestFile(e)} />
                      </div>
                    </div>
                  )
                }
              </Fragment>
            )
          }
          {
            hasError &&
            <div className="Plans__Add__Error">
              <div className="Plans__Add__Error__Icon">
                <CoreIcons name="Info"
                  height="22px"
                  fill="#eb384d"
                  viewBox="0 0 22 22"
                  width="22px"
                />
              </div>
              <div className="Plans__Add__Error__Description">
                <Typography color="false">
                  {errorMessage}
                </Typography>
              </div>
            </div>
          }
          <div className="Plans__Add__Button">
            <Button id="add-plan"
              text={(<Trans>VINCULAR</Trans>)}
              disabled={!isReadyToSubmit}
              onButtonClick={this.handleSubmit}
            />
          </div>
        </div>
      );
  }

  /**
   * Renders view's content
   */
  renderContent = () => {
    return (
      <div className="Plans__Content">
        {this.renderPlanList()}
      </div>
    );
  }

  render() {
    return (
      <div className="Plans">
        <Sticky stickyStyle={{
          backgroundColor: "#fff",
          padding: "2.5rem 0px 2rem",
          zIndex: "2",
        }}>
          <Row align="center"
            justify={this.props.isDesktop ? "space-between" : "center"}
            margin={this.props.isDesktop ? "3.9rem 0 2.1rem" : "2.5rem 0 1.5rem"}>
            <Typography className="AppointmentsList__heading" align="mcenter-dleft" Tag="h1" type="title">
              <Trans>Convênios</Trans>
            </Typography>
          </Row>
        </Sticky>
        {this.loadAndRender()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    errorList: state.async.errorList,
    groupPlans: state.plans.allPlansGroups.plan_groups,
    personPlans: state.persons.personPlans,
    profileSelected: state.auth.profileSelected,
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuthUser: (token) => dispatch(authUser(token)),
    onChangePlan: (plan) => dispatch(authChangePlan(plan)),
    onClearPersons: () => dispatch(clearPersons()),
    onGetGroupPlans: () => dispatch(getAllPlansGroups()),
    onGetPersonPlans: (slug, profile, params) => dispatch(getPersonPlans(slug, profile, params)),
    onSetPersonPlans: (slug, profile, params) => dispatch(setPersonPlans(slug, profile, params)),
    onShowModal: () => dispatch(showModal(modalTypes.SUCCESS_PLAN_ADDED)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(Plans));
