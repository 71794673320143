import axios, { isCancel } from 'axios'

import * as API from './_apiSettings'
import momentTz from 'moment-timezone'
import { getAuthToken } from 'utils/authHelper'


const createAxiosInstance = (headers) => {
  const instance = axios.create({
    baseURL: API.mainUrl
  })
  
  instance.interceptors.request.use((requestConfig) => {
    const authToken = getAuthToken()
  
    requestConfig.headers = {
      ...instance.defaults.headers,
      ...authToken && {
        'Authorization': `Bearer ${authToken}`
      },
      'Accept-Language': API.languageCode(),
      'Time-Zone': momentTz.tz.guess(true),
      ...(headers && { ...headers })
    }
  
    return requestConfig
  }, err => {
    return Promise.reject(err)
  })
  
  instance.interceptors.response.use((response) => {
    const responseData = response.data
    return responseData
  }, err => {
    return Promise.reject(err)
  })

  return instance
}

const defaultInstance = createAxiosInstance()

export { createAxiosInstance, isCancel }
export default defaultInstance
