import React, { Component } from 'react';
import PropTypes from 'prop-types';

import 'moment/locale/pt-br';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';

import { FORMAT, WEEKDAYS_SHORT } from '../../settings/dayPicker';
import { languageCode } from '../../settings/_apiSettings';

let getLocale = languageCode().toString();

class Calendar extends Component {

  state = {
    limitMonth: this.props.limitMonth,
    limitYear: this.props.limitYear,
  }

  componentDidMount() {
    this.setLimitMonthYear();
  }

  /** Custom style for DayPicker component */
  renderDay = (day) => {
    const date = day.getDate();
    let { daysOccupied } = this.props;

    return (
      <div className="DayCell">
        <div className="DayDate">
          {date}
          {
            daysOccupied.includes(date) &&
            <div className="DayDate--hasAppointment"></div>
          }
        </div>
      </div>
    );
  };

  createLimitThreeMonthsFromNow = () => {
    const currentDate = new Date();
    const threeMonthsFromNow =  new Date(currentDate.setMonth(currentDate.getMonth()+3));
    return {
      month: threeMonthsFromNow.getMonth(),
      year: threeMonthsFromNow.getFullYear(),
    };
  };

  setLimitMonthYear = () => {
    const { limitMonth, limitYear } = this.state;
    if ((!limitMonth && !limitYear) || !limitMonth) {
      const newLimits = this.createLimitThreeMonthsFromNow();
      this.setState({ 
        limitYear: newLimits.year,
        limitMonth: newLimits.month,
      });
    }
  }

  render() {
    const {
      currentMonth,
      currentYear,
      daysUnavailable,
      handleDayClick,
      handleMonthChange,
      selectedDays,
      startMonth,
      startYear,
      disableChangeMonth
    } = this.props;

    const {
      limitMonth,
      limitYear,
    } = this.state;

    return (
      <DayPicker
        disabledDays={daysUnavailable}
        format={FORMAT}
        fromMonth={new Date(startYear, startMonth)}
        locale={getLocale}
        localeUtils={MomentLocaleUtils}
        onDayClick={handleDayClick}
        onMonthChange={month => handleMonthChange(month)}
        renderDay={this.renderDay}
        selectedDays={selectedDays}
        month={currentMonth && currentYear ? new Date(currentYear, currentMonth) : null}
        toMonth={new Date(limitYear, limitMonth)}
        weekdaysShort={WEEKDAYS_SHORT}
        canChangeMonth={!disableChangeMonth}
      />
    );
  }
}

Calendar.propTypes = {
  daysOccupied: PropTypes.array,
  daysUnavailable: PropTypes.array,
  handleDayClick: PropTypes.func,
  handleMonthChange: PropTypes.func,
  limitMonth: PropTypes.number,
  limitYear: PropTypes.number,
  selectedDays: PropTypes.any,
  startMonth: PropTypes.number,
  startYear: PropTypes.number,
  disableChangeMonth: PropTypes.bool
};

Calendar.defaultProps = {
  daysOccupied: [],
  daysUnavailable: [],
  startMonth: 0,
  startYear: new Date().getFullYear() - 1,
  disableChangeMonth: false
};

export { Calendar };