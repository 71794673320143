import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Typography } from '@telavita-core/react-design-kit'

function Toggle({
  value,
  loading,
  toggleId,
  isToggled,
  label = '',
  onToggledSwitch,
}) {
  const containerStyle = clsx({
    'Toggle': true,
    'Toggle--active': isToggled,
    'Toggle--loading': loading,
  })

  const iconStyle = clsx({
    'Toggle__icon': true,
    'Toggle__icon--active': isToggled,
    'Toggle__icon--loading': loading,
  })

  const labelStyle = clsx({
    'Toggle__label': true,
    'Toggle__label--active': isToggled,
  })

  const hasLabel = !!label

  return (
    <div id={toggleId} className={containerStyle} onClick={()=> onToggledSwitch(value)}>
      {hasLabel && !loading && (
        <Typography 
          variant='content2'
          weight='bolder'
          customClassName={labelStyle}
        >
          {label}
        </Typography>
      )} 
      <div className={iconStyle}>
      </div>
    </div>
  )
}

Toggle.propTypes = {
  value: PropTypes.any,
  loading: PropTypes.bool,
  toggleId: PropTypes.string,
  isToggled: PropTypes.bool,
  onToggledSwitch: PropTypes.func,
}

export default Toggle
