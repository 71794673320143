import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextInput } from 'components';

import { removeDateMask } from '../../../../utils/inputMaskHandler';
import InputValidation from '../../../../utils/InputValidation';

class DateInput extends Component {
    static propTypes = {
      hasError: PropTypes.bool,
      errorMessage: PropTypes.string,
      id: PropTypes.string,
      isDisabled: PropTypes.bool,
      onComponentUpdate: PropTypes.func,
      placeholder: PropTypes.string,
      value: PropTypes.string,
    }

    static defaultProps = { 
      isDisabled: false,
      hasError: false,
    }

    state = {
      hasError: this.props.hasError || false,
      errorMessage: this.props.errorMessage || "",
      value: this.props.value || "",
    }

    mask = "11/11/1111"

    componentDidMount() {
      this.setupState();
    }

    componentDidUpdate(prevProps, prevState) {
      if (this.hasPropsUpdated(prevProps)) {
        this.setupState();
      }
      if (this.hasValueChanged(prevState)){
        if (this.props.onComponentUpdate) {
          this.handleComponentUpdate();
        }
      }
    }

    hasPropsUpdated = (prevProps) => {
      return (
        prevProps.hasError !== this.props.hasError ||
        prevProps.errorMessage !== this.props.errorMessage ||
        prevProps.value !== this.props.value
      );
    }

    hasValueChanged = (prevState) => {
      if (prevState.value !== this.state.value){
        return true;
      }
      return false;
    }

    setupState = () => {
      const { hasError, errorMessage, value } = this.props;

      const newState = {
        ...((hasError !== undefined) && { hasError }),
        ...((errorMessage !== undefined) && { errorMessage }),
        ...((value !== undefined) && { value }),
      };

      this.setState({ ...newState });
    }

    handleComponentUpdate = () => {
      const { hasError, errorMessage, value } = this.state;
      const componentData = { hasError, errorMessage, value };
      this.props.onComponentUpdate(componentData);
    }

    handleInput = (elementEvent) => {
      const eventValue = elementEvent.target.value;
      this.validateInput(eventValue);
      this.setState({ value: eventValue });
    }

    validateInput = (inputValue) => {
    
      let validation = {
        success: true, 
        errorMessage: "", 
        value: "",
      };
      
      if (removeDateMask(inputValue).length === 8) {
        validation = new InputValidation(inputValue, "date").validate();
      }

      this.setState({
        hasError: !validation.success,
        errorMessage: validation.message,
      });
    }

    render() {
      return (
        <TextInput
          disabled={this.props.isDisabled}
          hasError={this.state.hasError}
          messageError={this.state.errorMessage}
          name={this.props.id}
          mask={this.mask}
          onInputChange={(elementEvent) => this.handleInput(elementEvent)}
          placeholder={this.props.placeholder}
          type="text"
          value={this.state.value}
        />
      );
    }
}

export { DateInput };