import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 30px;
`

export const WarningMessageContent = styled.div`
  background-color: #FFFEB5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
`