import React, { Component } from "react";
import Modal from "../Modal";
import PropTypes from "prop-types";
import { Typography, Spinner } from "components";
import viewportHoc from "../../../../hoc/viewportHoc";
import { connect } from "react-redux";
import { hideModal } from "../../../../redux/actions";
import { Trans } from "@lingui/macro";
import { getScheduleLogs } from '../../../../redux/actions/schedulesActions'
import { getTime, getSeconds } from "../../../../utils/dateTimeHandlers";

const formatMinutes = payload => {
  if (!payload || !payload.total_meeting_time) return ''

  const padronized = payload.total_meeting_time // 'HH:mm:ss'
  const arr = padronized.split(':').map(str => +str) // [HH, mm, ss]

  const minutes = (arr[0] * 60) + arr[1]  // Ignoring seconds

  return `${minutes} min.`
}

class ModalLogsVirtualRoom extends Component {
  state = {
    isLoading: true,
    logs: null,
    logsError: false
  }

  componentDidMount() {
    this.getLogs()
  }

  getLogs = async () => {
    await this.props.onGetScheduleLogs(this.props.virtualRoomHash);
    this.setState({ isLoading: false })
  }

  render() {
    const { onHideModal, scheduleLog } = this.props;
    const { isLoading, logsError } = this.state;

    return (
      <Modal
        modalTitle={<Trans>Informações detalhadas</Trans>}
        closeModal={() => onHideModal()}
        btnId="repprove-details"
        hasButton={false}
        extraClassName
        scrollable={true}
      >
        {
          isLoading ?
            <Spinner height="250px" />
            :
            logsError ?
              <div className="Container">
                <Typography align="center">
                  <Trans>Nenhum resultado encontrado</Trans>
                </Typography>
              </div>
              :
              <>
                <div className="Container">
                  <Typography color="grey-ten">
                    <Trans>Paciente</Trans> <b>{scheduleLog?.patient?.full_name || ""}</b>
                  </Typography>
                  <Typography color="grey-ten">
                    <Trans>Profissional</Trans> <b>{scheduleLog?.professional?.full_name || ""}</b>
                  </Typography>
                  <Typography color="grey-ten">
                    <Trans>Tempo de consulta realizado:</Trans> <b>{formatMinutes(scheduleLog)}</b>
                  </Typography>
                </div>
                <div className="Container__List">
                  {scheduleLog.event_logs && scheduleLog.event_logs.length > 0 ?
                    scheduleLog.event_logs.map((event, index) =>
                      <div className="Container__List__LogCard" key={`evt-${index}`}>
                        <div className="Container__List__LogCard__Time">
                          <Typography color="primary" type="custom" style={{ fontSize: '12px' }} bold>
                            {getTime(event.date_created)}<span className="Container__List__LogCard__Time__Seconds">:{getSeconds(event.date_created)}</span>
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            color={event.event_code === 'EVT30007' ? 'false' : 'grey-eleven'}
                            type="custom"
                            style={{ fontSize: '12px' }}
                          >
                            {event.description || ""}
                          </Typography>
                        </div>
                      </div>
                    )
                    :
                    <div className="Container__List__Empty">Nenhuma informação disponível.</div>
                  }
                </div>
              </>
        }
      </Modal>
    );
  }
}

ModalLogsVirtualRoom.propTypes = {
  virtualRoomHash: PropTypes.string
};

const mapStateToProps = state => {
  return {
    scheduleLog: state.schedules.scheduleLog
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetScheduleLogs: (virtualRoomHash) => dispatch(getScheduleLogs(virtualRoomHash)),
    onHideModal: () => dispatch(hideModal()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(ModalLogsVirtualRoom));
