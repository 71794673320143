import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, Column } from 'components';

const StickyIndex = ({ barColor, barContent, barDate, textColor }) => {

  const barStyles = { backgroundColor: `${barColor}` };
  return (
    <Fragment>
      <div className="StickyIndex" style={barStyles}>
        
        <Column className="StickyIndex__day" colWidth={6} >
          <Typography bold color={textColor}> {barContent}</Typography>
        </Column >
        <Column className="StickyIndex__date" colWidth={6} color={textColor}>
          <Typography color={textColor}>{barDate}</Typography>
        </Column >

      </div>
    </Fragment>
  );
};

StickyIndex.propTypes = {
  barColor: PropTypes.any,
  barContent: PropTypes.any,
  textColor: PropTypes.string,
  textPosition: PropTypes.string,
  barDatw: PropTypes.string,
};

StickyIndex.defaultProps = {
  barColor: '#ffffb5',
  barContent: 'Some text goes here',
  textColor: 'color-title',
  textPosition: 'center',
};

export default StickyIndex;