import { GET_CARDS, SAVE_CARD } from './_actionTypes';

import axios from '../../settings/axios';
import { apiEndpoints } from '../../settings/_apiSettings';
import { asyncError } from './';

export const saveCreditCard = (userSlug, actualProfile, registerPayload) => 
  async dispatch => {
    try {
      const response = await axios.post(apiEndpoints.CREDIT_CARDS(userSlug, actualProfile), registerPayload);
      if (response.status === "OK") {
        const successPayload = {};
        return dispatch(saveCreditCardSuccess(successPayload));
      }
    } catch (err) {
      dispatch(asyncError(err));
    }
  };

export const saveCreditCardSuccess = (successPayload) => {
  return {
    type: SAVE_CARD,
    payload: successPayload,
  };
};

export const getCreditCards = (userSlug, actualProfile) => 
  async dispatch => {

    const response = await axios.get(apiEndpoints.CREDIT_CARDS(userSlug, actualProfile));

    let payload;

    switch (response.status) {
      case 'OK':
        payload = { creditCards: response.data.credit_cards  };
        return dispatch({
          type: GET_CARDS,
          payload,
        });

      case 'ZERO_RESULTS':
        payload = { creditCards: []  };

        return dispatch({
          type: GET_CARDS,
          payload,
        });
    
      default:
      /**
       * 
       * TODO TRATAR ERROS
       * 
       */
        break;
    }

  };