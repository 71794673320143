import React from "react";

const NotFound = ({
  fill,
  height,
  viewBox = "0 0 42 42",
  width,
}) => (
  <svg 
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g id="Grupo_5131" data-name="Grupo 5131" transform="translate(-1.998 -10.902)">
      <path id="Subtração_10" data-name="Subtração 10" d="M20921.105,12410.359h-58.232a7.383,7.383,0,0,1-7.375-7.374v-44.557a7.383,7.383,0,0,1,7.375-7.374h58.232a7.383,7.383,0,0,1,7.375,7.374v44.557A7.383,7.383,0,0,1,20921.105,12410.359Zm-62.607-46.3h0v38a5,5,0,0,0,5,4.994h57a5,5,0,0,0,5-4.994v-38Z" transform="translate(-20853 -12339.652)" fill={fill} stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1"/>
      <g id="Grupo_5132" data-name="Grupo 5132" transform="translate(0.417)">
        <path id="Caminho_4247" data-name="Caminho 4247" d="M50,60.472c4.327,0,8.073,1.859,10.02,4.974a.674.674,0,0,0,.894.081.687.687,0,0,0,.041-.87c-2.094-3.3-6.305-5.386-10.955-5.386s-8.861,2.084-10.989,5.44l-.009.014a.7.7,0,0,0,.2.871.716.716,0,0,0,.847-.252A11.887,11.887,0,0,1,50,60.472Z" transform="translate(-11.422 -6.871)" fill={fill} stroke={fill} strokeWidth="1.5"/>
        <circle id="Elipse_60" data-name="Elipse 60" cx="2.725" cy="2.725" r="2.725" transform="translate(27.5 39.29)" fill={fill}/>
        <circle id="Elipse_61" data-name="Elipse 61" cx="2.725" cy="2.725" r="2.725" transform="translate(44.215 39.29)" fill={fill}/>
      </g>
      <path id="Caminho_4248" data-name="Caminho 4248" d="M64.969,15.315a2.128,2.128,0,1,1-2.128,2.128A2.151,2.151,0,0,1,64.969,15.315Zm-6.322,0a2.128,2.128,0,1,1-2.128,2.128A2.194,2.194,0,0,1,58.647,15.315Zm-6.259,0a2.128,2.128,0,1,1-2.128,2.128A2.151,2.151,0,0,1,52.387,15.315Z" transform="translate(2.87 0.198)" fill="#fff"/>
    </g>
  </svg>
);

export default NotFound;