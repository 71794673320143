    import React from "react";

const ProfessionalFull = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path fill={fill} d="M5 13.18v4L12 21l7-3.82v-4L12 17zM12 3L1 9l11 6 11-6z" />
  </svg>
);

export default ProfessionalFull;