import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
interface Response {
  data: PersonsTotalsData
}

interface Error {
  response: {
    status: number
  }
}

export interface PersonsTotalsData {
  partial: number
  new: number
  recurring: number
}

interface ListPersonsTotalsProps {
  initialDate: string
  endDate: string
}

export const listPersonsTotals = ({
  initialDate,
  endDate,
}: ListPersonsTotalsProps): Promise<PersonsTotalsData> => {

  const endpoint = apiEndpoints.METRICS_PERSONS_TOTALS

  const params = {
    start_date__gte: initialDate,
    start_date__lte: endDate,
  }
  
  return new Promise<PersonsTotalsData> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        resolve(response.data)
      })
      .catch((error: Error) => {
        reject(error?.response?.status)
      })
  })
}