import React from "react";

const Search = ({
  fill,
  height,
  viewBox = "3 2 30 30",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path fill={fill} d="M285.52 32.02h-.79l-.28-.27a6.51 6.51 0 1 0-.7.7l.27.28v.79l5 5 1.49-1.5zm-6 0a4.49 4.49 0 1 1 3.185-1.315 4.5 4.5 0 0 1-3.185 1.315z" transform="translate(-264 -13)" />
  </svg>
);

export default Search;