import { AxiosResponse } from 'axios'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Payload {
  orderId: number | string
}

interface Error {
  response: {
    status: number
  }
}

interface Response extends AxiosResponse {
  data: {
    status: string
  }
}

const createCheckout = ({
  orderId,
}: Payload): Promise<string | number> => {

  const endpoint = apiEndpoints.CHECKOUT(orderId)

  const payload = {
    ...(orderId && { order_id: orderId }),
  }

  return new Promise<string | number> ((resolve, reject) => {
    axios.post(endpoint, payload)
      .then((response: Response) => {
        resolve(response.status)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}

export default createCheckout