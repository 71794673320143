import { GET_CARDS } from './../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

export const initialState = { creditCards: []  };

const getCreditCards = (state, { payload }) => {
  return updateState(state, { ...payload });
};

const creditCardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CARDS: return getCreditCards(state, action);
    default: return state;
  }
};

export default creditCardReducer;