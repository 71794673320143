import { GET_CANCEL_REASON } from '../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

const initialState = { reasons: []  };

const getCancelReason = (state, payload) => {
  return updateState(state, { reasons: payload.reasons  });
};

const CancelReasonReducer = (state=initialState, action) => {
  switch (action.type) {
    case GET_CANCEL_REASON: return getCancelReason(state, action.payload);
    default: return state;
  }
};

export default  CancelReasonReducer;