import React from "react";

const Client = ({
  fill,
  height,
  viewBox = "0 0 49.135 59.45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path d="M29.6 32.359H19.526A19.593 19.593 0 0 0 0 51.975v6.07a1.4 1.4 0 0 0 2.809 0v-6.07a16.782 16.782 0 0 1 16.726-16.8H29.6a16.782 16.782 0 0 1 16.726 16.8v6.07a1.4 1.4 0 1 0 2.809 0v-6.07A19.615 19.615 0 0 0 29.6 32.359zm-5.037-5.268a13.515 13.515 0 1 0-9.532-3.963 13.381 13.381 0 0 0 9.532 3.963zM17.007 5.96a10.578 10.578 0 0 1 7.555-3.141h.251a10.693 10.693 0 1 1-7.806 3.141z" />
    </g>
  </svg>
);

export default Client;
