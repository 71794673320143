import React from "react";

const Avatar = ({
  fill = "#fffff",
  height = "26",
  viewBox = "0 0 26 26",
  width = "26",
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width} 
    height={height} 
    viewBox={viewBox}
  >
  <path id="Caminho_2166" data-name="Caminho 2166" d="M15,2A13,13,0,1,0,28,15,13,13,0,0,0,15,2Zm0,4.9a3.9,3.9,0,1,1-3.9,3.9A3.895,3.895,0,0,1,15,6.9Zm0,17.46a9.361,9.361,0,0,1-7.8-4.186c.039-2.587,5.2-4,7.8-4s7.761,1.417,7.8,4A9.361,9.361,0,0,1,15,24.36Z" transform="translate(-2 -2)" fill={fill} />
</svg>

);

export default Avatar;