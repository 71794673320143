import React, { Component, Fragment } from 'react'
import { Trans } from '@lingui/macro'
import PropTypes from 'prop-types'
import ToggleButton from '../../../../components/ToggleButton'
import { connect } from 'react-redux'
import {
  getFilterStatus,
  clearFilter,
  setStatusFilter,
  hideModal,
} from '../../../../redux/actions'
import Row from '../../../../components/Row'
import { Typography, Button, Column, TextInput, Spinner } from 'components'
import Dropdown from '../../../../components/Dropdown'
import { CalendarFilter } from 'components/CalendarFilter'
import InputValidation from '../../../../utils/InputValidation'
import MenuDashboardItem from '../../../../components/MenuDashboardItem'
import {
  toDatetime,
  toString,
  dateToIso,
  toDayMonthYear,
  setMoment,
} from '../../../../utils/dateTimeHandlers'
import { setDateFilter } from '../../../../redux/actions/filtersActions'
import * as plansFilterActions from '../../../../redux/modules/plans/actions'
import { plansFilterRequest } from '../../../../redux/modules/plans/requests'
import ModalGeneric from '../ModalGeneric'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'
import { PERSON_STATUS } from 'settings/_personSettings'

const filterNameByCode = {
  [PERSON_STATUS.WAITING_CONSULTATION_REQUEST.code]: 'Aguard. Ped. de Consulta',
}
class FilterModal extends Component {
  state = {
    isMenuOpen: undefined,
    canSave: true,
    isBlockChecked: false,
    isActive: false,
    isDateChecked: true,
    start_date: {
      value: this.props.setStartDate
        ? toDayMonthYear(this.props.setStartDate)
        : '',
      isValid: true,
      message: '',
    },
    end_date: {
      value: this.props.setEndDate ? toDayMonthYear(this.props.setEndDate) : '',
      isValid: true,
      message: '',
    },
    selectedStatus: this.props.selectedStatus
      ? this.props.selectedStatus
      : null,
    allLists: null,
    atMedicalRequests: false,
    convenioSelected: false,
    calendarDate: [],
    showSelectedDateOnPlaceholder: false
  };

  componentDidMount() {
    if (window.location.pathname.includes('medical-requests')) this.setState({ atMedicalRequests: true })
    if (!this.props.plansFilter.data.length) this.props.loadConvenios()
    
    if (this.props.setStartDate && this.props.setEndDate) {
      this.setState({
        calendarDate: [new Date(this.props.setStartDate), new Date(this.props.setEndDate)],
        showSelectedDateOnPlaceholder: true
      })
    }

    this.props.onGetFilters({
      group_code: this.props.groupCode,
      filter: this.props.bool,
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.start_date !== this.state.start_date ||
      prevState.end_date !== this.state.end_date
    ) {
      this.handleDateCheck()
    }
  }

  handlePlanGroupsSelection = (value) => {
    if (value) {
      if (!this.state.convenioSelected) this.setState({ convenioSelected: true })
      return this.props.changeConvenio(value)
    } else {
      this.setState({ convenioSelected: false })
    }
  }

  handlePlanGroupItems = () => {
    const { plansFilter } = this.props

    const { ENTERPRISE, CARE_PLUS, CAPITATION } = PlanGroupCategoryEnum
    
    const planGroups = this.state.atMedicalRequests 
      ? plansFilter.data.reduce((acc, curr) => {
        if (([ENTERPRISE, CARE_PLUS, CAPITATION]).includes(curr.category)) {
          acc.push({
            id: curr.code,
            name: curr.name,
            code: curr.code
          })
        }
        return acc
      },[])
      : plansFilter.data.map((item) => {
        return { id: item.code, name: item.name, code: item.code }
      })

    return [
      {
        id: '',
        name: 'Todos os convênios',
        code: ''
      },
      ...planGroups
    ]
  }

  handleShowSelectedDateOnPlaceholder = (value) => {
    this.setState({ showSelectedDateOnPlaceholder: value })
  }

  handlePlansItems = () => {
    const { plansFilter } = this.props

    const plans = plansFilter.data.filter((convenio) => convenio.code === plansFilter.actualConvenio)[0].plans.map((plan) => { return { id: plan.code, name: plan.name, code: plan.code } })

    return [
      {
        id: '',
        name: 'Todos os planos',
        code: ''
      },
      ...plans
    ]
  }

  handleDateCheck = () => {
    let isValid = true

    if (!this.state.start_date.isValid) isValid = false
    if (!this.state.end_date.isValid) isValid = false

    if (this.state.start_date.isValid || this.state.end_date.isValid) {
      let startDateMoment = setMoment(dateToIso(this.state.start_date.value))
      let endDateMoment = setMoment(dateToIso(this.state.end_date.value))

      if (endDateMoment.isBefore(startDateMoment)) isValid = false
    }
    this.setState({ isDateChecked: isValid })
  };

  handleStartDate = inputValue => {
    let validation = new InputValidation(inputValue, 'date').validate()
    this.setState({
      start_date: {
        ...this.state.start_date,
        value: inputValue,
        isValid: inputValue === '' ? true : validation.success,
        message: validation.message,
      }
    })
  };

  handleEndDate = inputValue => {
    let validation = new InputValidation(inputValue, 'date').validate()
    this.setState({
      end_date: {
        ...this.state.end_date,
        value: inputValue,
        isValid: inputValue === '' ? true : validation.success,
        message: validation.message,
      },
    })
  };

  handleSetFilters = () => {
    if (
      (this.state.start_date.value === '' || !this.state.start_date.value) &&
      (this.state.end_date.value === '' || !this.state.end_date.value) &&
      (this.state.selectedStatus === '' || !this.state.selectedStatus) &&
      this.props.plansFilter.actualConvenio === '' &&
      this.props.plansFilter.actualPlan === ''
    ) {
      this.handleClearFilter()
    } else {
      this.handleSetDateFilter()
      this.props.onSetStatusFilter(this.state.selectedStatus)
      this.props.selectConvenioFilter('previousConvenio', this.props.plansFilter.actualConvenio)
      this.props.selectConvenioFilter('previousPlan', this.props.plansFilter.actualPlan)
    }

    this.props.onHideModal()
  };

  handleSelectFilter = status => {
    this.setState({ selectedStatus: status })
  };

  handleChangeCalendar = (date) => {
    this.setState({ calendarDate: date })
  }

  handleClearFilter = () => {
    this.props.onClearFilters()
    this.props.onClearConvenios()

    this.props.onGetFilters({
      group_code: this.props.groupCode,
      filter: this.props.bool,
    })
    this.setState({
      isActive: false,
      selectedStatus: null,
      start_date: {
        value: '',
        isValid: true,
        message: '',
      },
      end_date: {
        value: '',
        isValid: true,
        message: '',
      },
      convenioSelected: false,
      calendarDate: [],
      showSelectedDateOnPlaceholder: false
    })
  };

  handleIsSelected = status => {
    return this.state.selectedStatus === status
  };

  handleFormatFilterNameByCode = ({ code, name }) => {
    return filterNameByCode[code] || name
  };

  handleFilterData = () => {
    const filterData = this.props.filter
    const mapFilterData = filterData.map(item => {
      const icon = item.icon_url
      const id = item.id
      const name = this.handleFormatFilterNameByCode({ code: item.code, name: item.name })
      const code = item.code

      return {
        icon,
        id,
        name,
        code,
      }
    })
    return mapFilterData
  };

  handleSetDateFilter = () => {
    let startDate
    let endDate

    if (
      this.state.start_date.value !== '' &&
      this.state.end_date.value === ''
    ) {
      startDate = toString(
        toDatetime(dateToIso(this.state.start_date.value), '00:00')
      )
      endDate = toString(
        toDatetime(dateToIso(this.state.start_date.value), '23:59')
      )
    } else if (
      this.state.start_date.value === '' &&
      this.state.end_date.value !== ''
    ) {
      startDate = toString(
        toDatetime(dateToIso(this.state.end_date.value), '00:00')
      )
      endDate = toString(
        toDatetime(dateToIso(this.state.end_date.value), '23:59')
      )
    } else if (
      this.state.start_date.value === '' &&
      this.state.end_date.value === ''
    ) {
      startDate = null
      endDate = null
    } else {
      startDate = toString(
        toDatetime(dateToIso(this.state.start_date.value), '00:00')
      )
      endDate = toString(
        toDatetime(dateToIso(this.state.end_date.value), '23:59')
      )
    }
    this.props.onSetDateFilter(startDate, endDate)
  };

  renderFilterToggleBox = (data) => (
    data ?
      data.map(item => (
        <ToggleButton
          key={item.id}
          isActive={this.handleIsSelected(item.code)}
          itemName={item.icon}
          itemTitle={item.name}
          handleClick={() => this.handleSelectFilter(item.code)}
          isFilterAll={false}
        />
      )
      ) : <Spinner height="auto" />
  );

  setWrapperRef = node => {
    this.wrapperRef = node
  };

  iconProps = {
    fill: '#1F35B5',
    height: '24px',
    name: 'Refresh',
  };

  render() {
    const {
      btnText,
      titleColor,
      classModifier,
      iconForAll,
      plansFilter,
      showPlanFilter = false,
      showStatusBox = true,
      isSecretary
    } = this.props

    const { 
      atMedicalRequests, 
      showSelectedDateOnPlaceholder,
      calendarDate
    } = this.state

    this.handleFilterData()

    return (
      <ModalGeneric
        hasButton={false}
        modalTitle={'Filtros'}
        classModifier={classModifier}
        titleColor={titleColor}
        isCentered={true}
        extraClassName
        scrollable={true}
      >

        <div className="FilterModal__Content">

          <Row margin="20px 0 15px 0" align="center" justify="space-around">
            <Trans>Visualizar as datas:</Trans>
          </Row>

          <div className='filterInput'>
            <Column>
              <CalendarFilter
                calendarDate={calendarDate}
                onSelectedDate={date => {
                  this.handleStartDate(date.initialDate)
                  this.handleEndDate(date.endDate)
                }}
                handleChangeCalendarDate={this.handleChangeCalendar}
                showSelectedDateOnPlaceholder={showSelectedDateOnPlaceholder}
                setShowSelectedDateOnPlaceholder={this.handleShowSelectedDateOnPlaceholder}
              />
            </Column>
          </div>

          {!this.state.isDateChecked ? (
            <Row wrap="nowrap" margin="0 0 0 0" align="center" justify="center">
              <Typography color="false">
                <Trans>Período inválido</Trans>
              </Typography>
            </Row>
          ) : null}

          {
            (!isSecretary && (atMedicalRequests || showPlanFilter)) ? (
              <>
                {/* CONVENIOS FILTER */}
                <Row margin="20px 0 15px 0" align="center" justify="space-around">
                  <Trans>Convênio:</Trans>
                </Row>

                <div className='filterInput'>
                  <Column
                    className="column"
                    justify="center"
                    align="center"
                    style={{ gap: 10 }}
                  >  
                    {/* inserir select aqui */}
                    {
                      plansFilter.loading && !plansFilter.data.length ? null :
                        <Fragment>
                          <Dropdown
                            dropDownOverflow
                            dropDownItensToShow="3"
                            name="convenios-list"
                            options={this.handlePlanGroupItems()}
                            onChange={(value) => this.handlePlanGroupsSelection(value)}
                            placeholder="Todos os convênios"
                            defaultValue={plansFilter.previousConvenio}
                            clearSelection={!this.state.convenioSelected}
                          />

                          {plansFilter.actualConvenio.length > 1
                            ? <Dropdown
                              dropDownOverflow
                              dropDownItensToShow="3"
                              name="planos-list"
                              options={plansFilter.actualConvenio.length ? this.handlePlansItems() : [{ id: '', name: 'Todos os planos', code: '' }]}
                              onChange={(value) => this.props.selectConvenioFilter('actualPlan', value)}
                              placeholder="Todos os planos"
                              defaultValue={plansFilter.previousPlan}
                            /> : null}
                        </Fragment>
                    }

                  </Column>
                </div>
              </>
            ) : null
          }

          {showStatusBox &&
            <Row margin="1rem 0 " align="center" justify="space-around">
              <Trans>Mostrar:</Trans>
            </Row>
          }

          {/* TYPES FILTER */}
          <div className="filtermodalcontent">
            {showStatusBox &&
              <div className="filtermodalcontent__toggleBox" >
                <ToggleButton
                  isActive={this.handleIsSelected(null)}
                  itemName={'Todos'}
                  itemTitle={'Todos'}
                  handleClick={() => this.handleSelectFilter(null)}
                  isFilterAll={true}
                  iconName={iconForAll}
                />
                {this.renderFilterToggleBox(this.handleFilterData())}
              </div>
            }

            <div onClick={this.handleClearFilter} className="FilterClear">
              <MenuDashboardItem title="limpar filtros" icon={this.iconProps} />
            </div>
            <div className="cntbtn">
              <Button
                disabled={!this.state.isDateChecked}
                id={'12'}
                onButtonClick={this.handleSetFilters}
                text={btnText}
                classModifier={classModifier}
              />
            </div>
          </div>

        </div>

      </ModalGeneric>
    )
  }
}
FilterModal.propTypes = {
  classModifier: PropTypes.oneOf([
    'default',
    'false',
    'online',
    'reverse',
    'reverse--grey',
    'reverse--grey--padded',
    'reverse--grey--lighter',
  ]),
  btnDisabled: PropTypes.bool,
  btnId: PropTypes.string,
  btnText: PropTypes.any,
  children: PropTypes.any,
  handleClick: PropTypes.func,
  hasButton: PropTypes.bool,
  modalActive: PropTypes.bool,
  modalTitle: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  notificationText: PropTypes.string,
  groupCode: PropTypes.string,
  showStatusBox: PropTypes.bool
}

const mapStateToProps = state => {
  return {
    filter: state.filterStatus.filterStatus,
    selectedStatus: state.filters.status,
    setEndDate: state.filters.endDate,
    setStartDate: state.filters.startDate,
    plansFilter: state.plansFilter
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetFilters: params => dispatch(getFilterStatus(params)),
    onClearFilters: () => dispatch(clearFilter()),
    onClearConvenios: () => dispatch(plansFilterActions.clearConvenios()),
    onSetStatusFilter: selectedStatus =>
      dispatch(setStatusFilter(selectedStatus)),
    onSetDateFilter: (startDate, endDate) =>
      dispatch(setDateFilter(startDate, endDate)),
    onHideModal: () => dispatch(hideModal()),
    loadConvenios: () => dispatch(plansFilterRequest()),
    changeConvenio: (code) => dispatch(plansFilterActions.changeConvenio(code)),
    selectConvenioFilter: (entity, code) => dispatch(plansFilterActions.selectEntity(entity, code))
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterModal)
