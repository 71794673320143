import { MODAL_HIDE, MODAL_SHOW } from './../actions/_actionTypes';
import { updateState } from '../helpers/utilityHelpers';

const initialState = { 
  showModal: false,
  modalType: undefined,
  modalData: {},
};

const showModal = (state, modalPayload) => {
  return updateState(state, { 
    showModal: true,
    modalType: modalPayload.modalType,
    modalData: modalPayload.modalData,
  });
};

const hideModal = (state) => {
  return updateState(state, { 
    showModal: false,
    modalType: '',
    modalData: {},
  });
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case MODAL_HIDE: return hideModal(state);
    case MODAL_SHOW: return showModal(state, action);
    default: return state;
  }
};

export default modalReducer;