import {
  REGISTER_INFO_GROUP,
  REGISTER_INFO_PERSONAL,
  REGISTER_PHONE_CHECK,
  REGISTER_GROUP_SELECTION,
} from '../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

const initialState = {
  cellPhone: '',
  email: '',
  firstName: '',
  hasPlanCode: null,
  planCode: null,
  lastName: '',
  password: '',
  pinCode: '',
  plans: [],
  step: 0,
};

const saveGroupSelection = (state, { payload }) => {
  return updateState(state, { 
    hasPlanCode: payload.hasPlanCode,
    planCode: payload.planCode
  });
};

const saveInfoGroup = (state, { payload }) => {
  return updateState(state, { 
    plans: payload.plans,
    hasPlanCode: payload.hasPlanCode,
    ...(payload.cpf && { cpf: payload.cpf }),
  });
};

const saveInfoPersonal = (state, { payload }) => {
  return updateState(state, {
    firstName: payload.firstName,
    lastName: payload.lastName,
    email: payload.email,
    password: payload.password
  });
};

const savePhoneCheck = (state, { payload }) => {
  return updateState(state, {
    cellPhone: payload.cellPhone
  });
};

const registerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_GROUP_SELECTION: return saveGroupSelection(state, action);
    case REGISTER_INFO_GROUP: return saveInfoGroup(state, action);
    case REGISTER_INFO_PERSONAL: return saveInfoPersonal(state, action);
    case REGISTER_PHONE_CHECK: return savePhoneCheck(state, action);
    default: return state;
  }
};

export default registerReducer;