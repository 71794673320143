import styled from 'styled-components'

interface IContainerProps {
  hasError: boolean
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .selectAgeRange {
    > div {
      border: ${({ hasError }) => hasError && '1px solid #ff4949'};
    }
  }
`