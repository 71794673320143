import React, { useEffect, useState } from 'react'
import { formatISO } from 'date-fns'

import { useDimensions, Spinner } from '@telavita-core/react-design-kit'

import metricsService, { ISchedules } from 'services/metrics'

import { rangeDateFilterOptions } from 'settings/_filterSettings'

import {
  SCHEDULED,
  RETURNED,
  PROFESSIONAL_NO_SHOW,
  ATTENDED,
  CANCELED,
  PATIENT_NO_SHOW,
} from 'settings/_scheduleStatusSettings'

import { 
  GraphicsDropdown,
  SelectedDate,
  getRangeDays,
  lastDay
} from 'components/GraphicsDropdown'
import { GraphicsCard } from 'components/GraphicsCard'
import { ChartDoughnut } from 'components/Charts'
import { ChartLabel } from 'components/ChartLabel/ChartLabel'

import {
  Container,
  Content,
  WrapperLabels,
  Loading,
} from './styles'

type CustomSortFn = {
  data: ISchedules[]
  sortBy: string[]
  sortField: string
}

const colors = ['#1F35B5', '#4AC326', '#FD4949', '#BF1D1D', '#898989', '#D5D5D5']

const customLabels = (statusCode: string) => {
  const newStatusName = {
    [SCHEDULED]: 'Agendadas',
    [ATTENDED]: 'Realizadas',
    [PATIENT_NO_SHOW]: 'Paciente faltou',
    [PROFESSIONAL_NO_SHOW]: 'Eu faltei',
    [CANCELED]: 'Canceladas',
    [RETURNED]: 'Devolvidas',
  }

  return newStatusName[statusCode] as string
}

const customSortedSchedules = ({data, sortBy, sortField}: CustomSortFn) => {
  const sortByObject = sortBy.reduce((obj, item, index) => {
    return {
      ...obj,
      [item]: index
    }
  }, {})

  return data.sort((a, b) => {
    const firstValue = a[sortField] as string
    const secondValue = b[sortField] as string

    return sortByObject[firstValue] - sortByObject[secondValue]
  })
}

const statusList = [
  SCHEDULED,
  ATTENDED,
  PATIENT_NO_SHOW,
  PROFESSIONAL_NO_SHOW,
  CANCELED,
  RETURNED,
]

export function ProfessionalSchedules(): JSX.Element {
  const initialOption = rangeDateFilterOptions[0]

  const { isMobile } = useDimensions()

  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState(0) 
  const [schedules, setSchedules] = useState<ISchedules[]>()
  const [selectedDate, setSelectedDate] = useState<SelectedDate>({
    initialDate: getRangeDays(initialOption.quantity).toString(),
    endDate: lastDay.toString(),
  })

  const data = schedules?.map(item => item.partial)
  const percents = schedules?.map(item => isNaN(item.percentage) ? 0 : item.percentage)
  const labels = schedules?.map(item => customLabels(item.statusCode))

  useEffect(() => {
    async function getSchedules() {
      try {
        setLoading(true)

        const { status, schedulesTotal } = await metricsService.listSchedules({
          initialDate: formatISO(new Date(selectedDate.initialDate)),
          endDate: formatISO(new Date(selectedDate.endDate)),
          statusList: statusList,
        })

        setSchedules(customSortedSchedules({
          data: status,
          sortBy: statusList,
          sortField: 'statusCode',
        }))

        setTotal(schedulesTotal)

      } catch (error) {
        setLoading(false)

      } finally{
        setLoading(false)
      }
    }

    void getSchedules()
  }, [selectedDate])

  return (
    <GraphicsCard
      title={isMobile ? <>Consultas por <br />status</> : 'Consultas por status'}
      headerAction={
        <GraphicsDropdown 
          initialOption={initialOption}
          onSelectedDate={setSelectedDate}
        />
      }
    > 
      <Container>
        {loading && (
          <Loading>
            <Spinner />
          </Loading>
        )}
        <Content
          isLoading={loading}
        >
          <ChartDoughnut 
            data={data}
            labels={labels}
            colors={colors}
            total={total}
            percents={percents}
            empty={false}
            labelSubtitle='no período acima'
          />

          <WrapperLabels 
            isMobile={isMobile}
          >
            {data?.map((item, index) => (
              <ChartLabel 
                key={index}
                label={labels[index]}
                value={item}
                percent={percents[index]}
                color={colors[index]} 
              />
            ))}
          </WrapperLabels>
        </Content>
      </Container>
    </GraphicsCard>
  )
}