import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ avatarSize, imgAlt, imgUrl, userStatus }) => {

  const avatarStyle = {
    height: avatarSize,
    width: avatarSize,
  };
  return (
    <div className="Avatar" style={avatarStyle} >
      <div className={`Avatar__status--${userStatus.toLowerCase()}`}></div>
      <div className="Avatar__image">
        {
          imgUrl !== null &&
          imgUrl !== "" &&
          imgUrl !== undefined &&
          <img alt={imgAlt} src={imgUrl} />
        }
      </div>
    </div>
  );
};

Avatar.propTypes = {
  imgAlt: PropTypes.string,
  imgUrl: PropTypes.string,
  userStatus: PropTypes.string,
};

Avatar.defaultProps = {
  avatarSize: '92px',
  imgAlt: '',
  imgUrl: '',
  userStatus: 'OFFLINE',
};

export { Avatar };