import styled from 'styled-components'

export const Container = styled.div`
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-bottom: 30px;
`

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  gap: 10px;

  .customSelect {
    max-width: initial !important;
    > div {
      margin-top: 0;
    }
  }
`
export const TextBoxWrapper = styled(SelectWrapper)`
  gap: 3px;
  margin-bottom: 10px;

  > textarea {
    margin-top: 10px;
  }
`

export const CreditDisclaimer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: #FFFEB5;
  padding: 20px;

  > p {
    line-height: 1.4;
  }
`