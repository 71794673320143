import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px 10px;
  svg {
    margin-left: 2px;
  }
`

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`