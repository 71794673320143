import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  & > div {
    max-width: 100%;
    margin-bottom: 2rem;
  
  > div {
      margin-bottom: 10px !important;
    }
  }
`

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    width: 30px;
    height: 30px;
    border-width: 3px;
    border-color: #FFF;
    border-top-color: transparent !important;
  }
`