import React from 'react'
import { Icon, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface ReservationEmptyStateProps {
  selectedTab: 'RECURRENT' | 'NON_RECURRENT'
}

export const ReservationEmptyState: React.FC<ReservationEmptyStateProps> = ({
  selectedTab
}) => {
  return (
    <S.Container>
      {
        selectedTab === 'RECURRENT'
          ? (
          <>
            <Icon name='NoReservation'/>
            <Typography variant='content2' customClassName='reservationEmptyStateTitle'>
              Nenhuma reserva criada pelo profissional
            </Typography>
          </>
          )
          : (
          <>
            <Icon name='NoReservation'/>
            <Typography center variant='content2' customClassName='reservationEmptyStateTitle'>
              Nenhuma reserva avulsa criada pelo profissional
            </Typography>
          </>
          )
      }
    </S.Container>
  )
}