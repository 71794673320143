import React from 'react'
import { Spinner, Typography, Avatar, useDimensions, Icon } from '@telavita-core/react-design-kit'
import { useState } from 'react'
import { useEffect } from 'react'
import { useParams } from 'react-router'
import instance from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import './AdminReservations.sass'
import clsx from 'clsx'
import ReservationService from 'services/reservation'
import { DayHeader } from '../ProfessionalReservations/DayHeader'
import { ReservationListItem } from '../ProfessionalReservations/ReservationListItem'
import Tabs from 'components/Tabs'
import { ReservationEmptyState } from './components/ReservationEmptyState/ReservationEmptyState'

const ReservationType = {
  RECURRENT: 'RECURRENT',
  NON_RECURRENT: 'NON_RECURRENT'
}

const RecurrenceType = {
  WEEKLY: 'Semanal',
  NON_RECURRENT: 'Avulsa'
}

const handleReservationData = (selectedTab, reservations) => {
  if (selectedTab === ReservationType.RECURRENT) {
    return Object.keys(reservations).map((weekday) => ({
      label: weekday,
      reservations: reservations[weekday].filter((reservation) => reservation.recurrence === RecurrenceType.WEEKLY)
    }))
  }

  if (selectedTab === ReservationType.NON_RECURRENT) {
    return [{
      reservations: Object.keys(reservations)
        .reduce((arr, weekday) => arr.concat(reservations[weekday]), [])
        .filter((reservation) => reservation.recurrence === RecurrenceType.NON_RECURRENT)
    }]
  }
}

const AdminReservations = () => {
  const { professional: username } = useParams()
  const { isMobile } = useDimensions()

  const [professional, setProfessional] = useState(null)
  const [reservations, setReservations] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [loading, setLoading] = useState(false)
  const [selectedTab, setSelectedTab] = useState(ReservationType.RECURRENT)

  const onRefresh = () => setRefresh(true)

  useEffect(() => {
    instance.get(apiEndpoints.PERSON_SLUG(username, 'PSIC'))
      .then(res => {
        const {
          full_name,
          picture_url
        } = res.data.person_general

        const [profile] = res.data.person_profile
        const {
          documents: [{ type_name, value }]
        } = profile
        setProfessional({
          full_name,
          picture_url,
          document: `${type_name} ${value}`
        })
      }).catch(err => {
        console.error(err)
      })
  }, [username])

  useEffect(() => {
    setLoading(true)
    ReservationService.getReservations({ username, isProfessional: true }, true)
      .then(reservations => {
        setRefresh(false)
        setLoading(false)
        setReservations(reservations)
      })
      .catch(() => {
        setRefresh(false)
        setLoading(false)
        setReservations([])
      })
  }, [username, refresh])

  const headerStyles = clsx({
    'AdminReservations__header': true,
    'AdminReservations__header__mobile': isMobile
  })

  const professionalContainerStyles = clsx({
    'AdminReservations__professionalContainer': true,
    'AdminReservations__professionalContainer__mobile': isMobile
  })

  const filterByTab = (reservation, selectedTab) => {
    if (selectedTab === ReservationType.RECURRENT && reservation.recurrence === 'Semanal') {
      return true
    }
    if (selectedTab === ReservationType.NON_RECURRENT && reservation.recurrence === 'Avulsa') {
      return true
    }
    return false
  }

  
  const hasReservation = reservations && Object?.keys(reservations)
    .map(weekday => reservations[weekday].filter(reservation => filterByTab(reservation, selectedTab)))
    .flatMap(reservations => reservations)

  const formattedReservations = handleReservationData(selectedTab, reservations)

  const renderReservations = () => {
    return formattedReservations.map(reservation => {
      if (reservation.reservations.length === 0) {
        return null
      }

      const sortReservationsByDate = reservation.reservations?.sort((a, b) => {
        return a.start_date - b.start_date
      })

      return (
        <div key={reservation.label}>
          {reservation.label && <DayHeader day={reservation.label} />}
          {sortReservationsByDate.map((reservation, index) => {
            return (
              <ReservationListItem
                hideBorderTop={selectedTab === ReservationType.NON_RECURRENT && index === 0}
                key={reservation.id}
                reservation={reservation}
                onRefresh={onRefresh}
              />
            )
          })}
        </div>
      )
    })
  }

  return (
    <div className='AdminReservations'>
      <div className={headerStyles}>
        <Typography variant={isMobile ? 'header4' : 'header2'} center={isMobile}>
          Reservas
        </Typography>
        <div className={professionalContainerStyles}>
          {
            Boolean(professional) &&
              <div className='AdminReservations__professional'>
                <Avatar size='regular' alt={professional.full_name} src={professional.picture_url} />
                <div className='AdminReservations__professional__description'>
                  <Typography variant='content3'>
                          Psicólogo(a)
                  </Typography>
                  <Typography variant='content3' weight='bolder'>
                    {professional.full_name}
                  </Typography>
                  <Typography variant='content3'>
                    {professional.document}
                  </Typography>
                </div>
              </div>
          }
        </div>
      </div>
      <div className='AdminReservations__content'>
        <Tabs
          tabId='ProfessionalReservations'
          tabs={[
            { title: 'Recorrentes', code: ReservationType.RECURRENT, subtitle: 'Se repetem toda semana' },
            { title: 'Avulsas', code: ReservationType.NON_RECURRENT, subtitle: 'Não se repetem' },
          ]}
          selectedItem={selectedTab}
          onSelectTab={setSelectedTab}
          style={{ marginBottom: selectedTab === ReservationType.RECURRENT ? '30px' : '15px' }}
        />

        {loading && <div className='AdminReservations__spinnerContainer'><Spinner /></div>}

        {!loading && hasReservation?.length === 0 &&
          <ReservationEmptyState selectedTab={selectedTab} />
        }

        {!loading && Object.keys(reservations).length > 0 && renderReservations()}
      </div>
    </div>
  )
}

export { AdminReservations }
