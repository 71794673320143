export const COUNTRY_STATES = [
    { code: "AC", name: "AC" },
    { code: "AL", name: "AL" },
    { code: "AM", name: "AM" },
    { code: "AP", name: "AP" },
    { code: "BA", name: "BA" },
    { code: "CE", name: "CE" },
    { code: "DF", name: "DF" },
    { code: "ES", name: "ES" },
    { code: "GO", name: "GO" },
    { code: "MA", name: "MA" },
    { code: "MT", name: "MT" },
    { code: "MS", name: "MS" },
    { code: "MG", name: "MG" },
    { code: "PA", name: "PA" },
    { code: "PB", name: "PB" },
    { code: "PR", name: "PR" },
    { code: "PE", name: "PE" },
    { code: "PI", name: "PI" },
    { code: "RJ", name: "RJ" },
    { code: "RN", name: "RN" },
    { code: "RO", name: "RO" },
    { code: "RS", name: "RS" },
    { code: "RR", name: "RR" },
    { code: "SC", name: "SC" },
    { code: "SE", name: "SE" },
    { code: "SP", name: "SP" },
    { code: "TO", name: "TO" }
];