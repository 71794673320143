import React, { Fragment } from 'react';
import classNames from 'classnames';

// STYLE
import './GeneralViewCard.sass';

// COMPONENTS
import { Spinner } from 'components';

const GeneralViewCard = ({ spaced, loading, error, total, label, partial }) => {
  return (
    <div className={classNames('GeneralViewCard', {
      'GeneralViewCard--spaced': spaced
    })}>
      {
        loading
          ? <Spinner />
          : (
            <Fragment>
              <p className="GeneralViewCard__text GeneralViewCard__text--value">{error ? 'X' : partial}</p>
              <p className="GeneralViewCard__text">{label}</p>
              <p className="GeneralViewCard__text GeneralViewCard__text--chips">Total acumulado: {error ? 'X' : total}</p>
            </Fragment>
          )
      }
    </div>
  )
};

export {
  GeneralViewCard
};