import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';

function RadioButton({
  disabled,
  iconWidth,
  iconHeight,
  iconViewBox,
  onClickRadioButton,
  radioId,
  selected,
  title,
  value,
  inlineStyles = {},
  inlineIconStyle = {}
}) {

  const icon = selected ? 'RadioSelected' : 'RadioUnselected';
  const iconFill = selected ? '#1f35b5' : '#c4c4c4'
  // const fill = disabled ? '#c4c4c4' : null;

  return (
    <div 
      className="RadioButton"
      onClick={() => onClickRadioButton(value)}
      id={radioId}
      style={inlineStyles}
    >
      <div className="RadioButton__icon" style={inlineIconStyle}>
        <CoreIcons name={icon}
          fill={iconFill}
          width={iconWidth}
          height={iconHeight}
          viewBox={iconViewBox}
        />
      </div>
      <div className={`RadioButton__text ${disabled ? 'disabled' : ''}`}>
        <Typography>
          {title}
        </Typography>
      </div>
    </div>
  );
}

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconViewBox: PropTypes.string,
  radioId: PropTypes.string,
  onClickRadio: PropTypes.func,
  selected: PropTypes.bool,
  title: PropTypes.any,
  value: PropTypes.any,
};

RadioButton.defaultProps = {
  disabled: false,
  iconWidth: '24px',
  iconHeight: '24px',
  iconViewBox: '0 0 24 24',
  selected: false,
};

export {
  RadioButton
};
