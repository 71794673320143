import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// COMPONENTS
import { Typography, Button, CoreIcons } from 'components'
import Row from '../../../../components/Row'

import * as actions from '../../../../redux/actions'

class Modal extends Component {
  componentDidMount() {
    // document.addEventListener('mousedown', this.onClickOutside);
    // document.body.classList.add("overflow-hidden");
  }

  componentWillUnmount() {
    // document.removeEventListener('mousedown', this.onClickOutside);
    // document.body.classList.remove("overflow-hidden");
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  onClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.props.closeModal()
    }
  }

  onCloseModal = (e) => {
    document.body.classList.remove('overflow-hidden')
    this.props.closeModal(e)
    this.props.onClose && this.props.onClose()
  }

  render() {
    const {
      btnDisabled,
      btnLoading = false,
      btnId,
      btnText,
      isCentered,
      handleClick,
      hasButton,
      modalTitle,
      titleColor,
      classModifier,
      extraClassName,
      customClassName = '',
      btnExtraClass = '',
      scrollable = false
    } = this.props

    return (
      <div className={`Modal${isCentered ? '-align-center' : ''} Modal${extraClassName ? '-isFixed' : ''} ${customClassName}`}>
        <div className="Modal__backdrop"></div>

        <div className={`Modal__container${scrollable ? '--scrollable' : ''}`}
          ref={node => this.wrapperRef = node}>
          <div className="Modal__container__close" onClick={(e) => this.onCloseModal(e)}>
            <CoreIcons name='Close' />
          </div>

          <div className={`Modal__container__content${scrollable ? '--scrollable' : ''}`}>
            <Row align='center' justify='center'>
              <Typography align='center' type="heading4" color={titleColor}>
                {modalTitle}
              </Typography>
            </Row>

            {this.props.children}

            {
              hasButton &&
              <Row margin='2.8rem 0 0 0'>
                <Button
                  disabled={btnDisabled}
                  loading={btnLoading}
                  id={btnId ? btnId : 'modal-btn'}
                  onButtonClick={() => handleClick()}
                  text={btnText}
                  classModifier={classModifier}
                  extraClass={btnExtraClass}
                />
              </Row>
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeModal: () => {
      dispatch(actions.hideModal())
    },
  }
}

Modal.propTypes = {
  btnDisabled: PropTypes.bool,
  btnId: PropTypes.string,
  btnText: PropTypes.any,
  children: PropTypes.any,
  handleClick: PropTypes.func,
  hasButton: PropTypes.bool,
  modalActive: PropTypes.bool,
  modalTitle: PropTypes.any,
  classModifier: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  extraClassName: PropTypes.bool,
  customClassName: PropTypes.string,
  isCentered: PropTypes.bool,
  btnLoading: PropTypes.bool,
  scrollable: PropTypes.bool
}

export default connect(null, mapDispatchToProps)(Modal)