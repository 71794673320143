import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
`

export const TabsWrapper = styled.div`
  .PatientTreatmentsEligibility__Tab {
    margin-bottom: 6px;
  }
`

export const TabPanelContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`
