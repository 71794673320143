export enum ReviewStatus {
  RECEIVED = 'RECEIVED',
  ARCHIVED = 'ARCHIVED',
  PUBLISHED = 'PUBLISHED',
}

export interface Review {
  id: number
  author: string
  status: ReviewStatus
  viewed: boolean
  createdAt: Date
  review: string
}