export const base64FileEncoder = (file) => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onerror = () => {
      reader.abort();
      reject(new DOMException('Não foi possível ler o arquivo'));
    };
    reader.onload = () => {
      var base64String = reader.result.replace(/^data:.+;base64,/, '');

      resolve(base64String);
    };

    reader.readAsDataURL(file);
  });
};