import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import { Trans } from '@lingui/macro';
import MenuDashboardContainer from './../../containers/MenuDashboardContainer';
import MenuLogout from '../MenuLogout';
import MenuUserInfo from '../MenuUserInfo';
import MenuProfileSwap from '../MenuProfileSwap/';
import Row from '../Row';

import * as routes from './../../settings/_routesSettings';
import * as actions from '../../redux/actions';

class MenuMobile extends Component {
  handleLogout = async () => {
    await this.props.onLogout();
  };

  render() {
    const {
      isLogged,
      isOpen,
      onClickSwapSelect,
      onCloseClick,
      userProps,
    } = this.props;

    let containerClass = "main-menu--container-undefined";
    if (isOpen === true) containerClass = "main-menu--container";
    if (isOpen === false) containerClass = "main-menu--container-closed";

    return (
      <Fragment>
        <div className={containerClass}>
          <Row className={`main-menu--top-row`}>
            <div className="main-menu--top" onClick={onCloseClick}>
              <CoreIcons
                name="Close"
                fill="#fff"
              />
            </div>
          </Row>

          {
            isLogged ? (
              <Fragment>
                <div className="main-menu--header">
                  <MenuUserInfo {...userProps} />
                </div>
              </Fragment>) : null
          }

          <div className={`main-menu ${isLogged ? "isLogged" : ""}`}>
            <Row className="main-menu--user">

              {
                isLogged ? (
                  <Fragment>
                    <MenuDashboardContainer userPermissions={userProps.profilePermissions} />
                  </Fragment>
                ) : (
                  <Fragment>
                    <Link to={routes.LOGIN}>
                      <Row padding="1rem 0">
                        <Typography bold
                          type="custom"
                          className="main-menu--user--text">
                          <Trans>
                            Entrar
                          </Trans>
                        </Typography>
                      </Row>
                    </Link>
                    <Link to={routes.REGISTER}>
                      <Row padding="1rem 0">
                        <Typography bold
                          type="custom"
                          className="main-menu--user--text">
                          <Trans>
                            Criar Conta
                          </Trans>
                        </Typography>
                      </Row>
                    </Link>
                  </Fragment>
                )}
            </Row>

            {/* <Row padding="1rem 0">
              <div className="main-menu--separator"></div>
            </Row> */}
            <Row className="main-menu--body"></Row>

            {
              isLogged ? (
                <Fragment>
                  <Row padding="1rem 0">
                    <div className="main-menu--separator"></div>
                  </Row>
                  <MenuLogout onHandleLogout={this.handleLogout} />
                </Fragment>
              ) : null
            }

          </div>
        </div>

        {
          isOpen ?
            <div className="black-screen"></div> : null
        }
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return { userToken: state.auth.userToken };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      dispatch(actions.authLogout());
    },
  };
};

MenuMobile.propTypes = {
  isLogged: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
  userProps: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobile);
