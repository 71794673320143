import React from "react";

const Illustration = ({
  width,
  height
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 235 140">
    <g id="Grupo_1248" data-name="Grupo 1248" transform="translate(-44.168 -1704.198)">
      <path id="Caminho_2571" data-name="Caminho 2571" d="M88.85,1747.854c14.494-4.169,44.127-7.065,64.209-23.473,18.036-14.733,113.919-52.711,131.365,40.929-10.748,24.549-25.486,77.51-101.438,78.779-14.539.368-27.581-.151-38.677-2.217-3.734-.695-18.628-4.993-22.645-6.355-31.766-10.77-56.138-31.14-57.608-51.656C62.865,1767.226,68.253,1754.3,88.85,1747.854Z" transform="translate(-7.328)" fill="#ff9500" />
      <g id="Grupo_1220" data-name="Grupo 1220" transform="translate(44.168 1718.946)">
        <rect id="Retângulo_723" data-name="Retângulo 723" width="135.271" height="98.324" rx="2.377" transform="translate(53.735 0)" fill="#3f4572" />
        <path id="Retângulo_724" data-name="Retângulo 724" d="M257.445,1820.542H119.4v-95.309H257.408a.038.038,0,0,1,.037.038h0v95.271Z" transform="translate(-72.23 -1725.233)" fill="#59608e" />
        <rect id="Retângulo_725" data-name="Retângulo 725" width="120.658" height="80.16" rx="6.401" transform="translate(54.571 9.08)" fill="#fff" />
        <g id="Grupo_1216" data-name="Grupo 1216" transform="translate(54.571 9.052)">
          <rect id="Retângulo_725-2" data-name="Retângulo 725" width="120.658" height="80.16" rx="6.401" fill="#fff" />
          <g id="Grupo_1215" data-name="Grupo 1215" transform="translate(23.58 5.753)">
            <rect id="Retângulo_797" data-name="Retângulo 797" width="18.72" height="20.285" transform="translate(27.65 40.134)" fill="#ff907a" />
            <path id="Caminho_2729" data-name="Caminho 2729" d="M246.141,1785.147h-2.722v-18.375h2.722a9.187,9.187,0,0,1,9.187,9.187h0A9.187,9.187,0,0,1,246.141,1785.147Z" transform="translate(-189.975 -1760.46)" fill="#333854" />
            <path id="Retângulo_798" data-name="Retângulo 798" d="M0,0H13.611a0,0,0,0,1,0,0V14.26a0,0,0,0,1,0,0H9.088A9.088,9.088,0,0,1,0,5.172V0A0,0,0,0,1,0,0Z" transform="translate(20.177 27.365)" fill="#333854" />
            <path id="Retângulo_799" data-name="Retângulo 799" d="M0,0H3.867A10.906,10.906,0,0,1,14.774,10.906v2.878a9.861,9.861,0,0,1-9.861,9.861H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(46.937 0.568)" fill="#333854" />
            <path id="Caminho_2730" data-name="Caminho 2730" d="M233.5,1809.515H212.415v-27.209A14.986,14.986,0,0,1,227.4,1767.32h0a14.986,14.986,0,0,1,14.968,14.262l.858,17.728A9.734,9.734,0,0,1,233.5,1809.515Z" transform="translate(-184.752 -1760.552)" fill="#ff907a" />
            <path id="Caminho_2731" data-name="Caminho 2731" d="M249.062,1797.4l-.626-12.931Z" transform="translate(-190.82 -1763.752)" fill="none" />
            <path id="Retângulo_800" data-name="Retângulo 800" d="M15.65,0h0a0,0,0,0,1,0,0V25.62a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V15.65A15.65,15.65,0,0,1,15.65,0Z" transform="translate(20.177 4.208)" fill="#333854" />
            <path id="Caminho_2732" data-name="Caminho 2732" d="M211.585,1796.466h4.084v-4.083a4.083,4.083,0,0,0-4.084-4.083h0a4.083,4.083,0,0,0-4.083,4.083h0A4.083,4.083,0,0,0,211.585,1796.466Z" transform="translate(-183.924 -1764.419)" fill="#ff907a" />
            <path id="Caminho_2733" data-name="Caminho 2733" d="M245.545,1776.084h-15.5a11.471,11.471,0,0,1-11.458-11.459,5.444,5.444,0,0,1,10.888,0,.57.57,0,0,0,.57.569h15.5Z" transform="translate(-185.791 -1759.181)" fill="#333854" />
            <path id="Caminho_2734" data-name="Caminho 2734" d="M241.385,1770.754h-7.713a5.444,5.444,0,1,1,0-10.889h7.713a5.444,5.444,0,0,1,0,10.889Z" transform="translate(-187.416 -1759.296)" fill="#333854" />
            <circle id="Elipse_50" data-name="Elipse 50" cx="9.36" cy="9.36" r="9.36" transform="translate(27.666 50.825)" fill="#ff907a" />
            <path id="Subtração_7" data-name="Subtração 7" d="M70.885,13.342H0L1.931,8.954h0l0,0A14.932,14.932,0,0,1,14.14.083l.052-.007.028,0,.03,0c.03,0,.061-.008.091-.011.1-.009.2-.014.3-.017l.054,0,.118-.006h.01L14.993.02l.024,0C15.141.01,15.269,0,15.4,0H56.382a10.443,10.443,0,0,1,3.66.671,15.138,15.138,0,0,1,6.373,3.641,14.945,14.945,0,0,1,3.942,6.461,15.148,15.148,0,0,1,.528,2.567Z" transform="translate(0 60.193)" fill="#c7c7c7" />
          </g>
        </g>
        <path id="Caminho_2572" data-name="Caminho 2572" d="M47.168,1880.436H236.5v-9.524H49.2Z" transform="translate(-47.168 -1776.108)" fill="#3f4572" />
        <rect id="Retângulo_726" data-name="Retângulo 726" width="39.728" height="76.388" rx="9.509" transform="translate(195.272 27.939)" fill="#3f4572" />
        <rect id="Retângulo_727" data-name="Retângulo 727" width="39.728" height="76.388" rx="8.32" transform="translate(193.382 27.939)" fill="#59608e" />
        <path id="Caminho_2575" data-name="Caminho 2575" d="M347.509,1839.136a.719.719,0,0,1-.664-.761v-55.1a.719.719,0,0,1,.664-.761h31.776a.719.719,0,0,1,.664.761v55.1a.717.717,0,0,1-.664.761Z" transform="translate(-149.761 -1745.791)" fill="#fff" />
        <g id="Grupo_1219" data-name="Grupo 1219" transform="translate(198.384 43.199)">
          <g id="Grupo_1218" data-name="Grupo 1218">
            <rect id="Retângulo_801" data-name="Retângulo 801" width="11.111" height="12.039" transform="translate(7.059 24.228)" fill="#ff907a" />
            <path id="Caminho_2738" data-name="Caminho 2738" d="M386.365,1820.4h-1.616v-10.906h1.616a5.453,5.453,0,0,1,5.452,5.453h0A5.453,5.453,0,0,1,386.365,1820.4Z" transform="translate(-362.381 -1805.746)" fill="#333854" />
            <path id="Retângulo_802" data-name="Retângulo 802" d="M0,0H8.078a0,0,0,0,1,0,0V8.464a0,0,0,0,1,0,0h0A8.078,8.078,0,0,1,0,.385V0A0,0,0,0,1,0,0Z" transform="translate(2.624 16.492)" fill="#333854" />
            <path id="Retângulo_803" data-name="Retângulo 803" d="M0,0H1.4a7.37,7.37,0,0,1,7.37,7.37v0A6.664,6.664,0,0,1,2.1,14.034H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(18.506 0.337)" fill="#333854" />
            <path id="Caminho_2739" data-name="Caminho 2739" d="M377.755,1834.88H365.238v-16.149a8.894,8.894,0,0,1,8.894-8.894h0a8.894,8.894,0,0,1,8.883,8.464l.51,10.522A5.778,5.778,0,0,1,377.755,1834.88Z" transform="translate(-358.172 -1805.82)" fill="#ff907a" />
            <path id="Caminho_2740" data-name="Caminho 2740" d="M388.278,1828.3l-.371-7.674Z" transform="translate(-363.063 -1808.148)" fill="none" />
            <path id="Retângulo_804" data-name="Retângulo 804" d="M9.289,0h0a0,0,0,0,1,0,0V15.206a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V9.289A9.289,9.289,0,0,1,9.289,0Z" transform="translate(2.624 2.498)" fill="#333854" />
            <path id="Caminho_2741" data-name="Caminho 2741" d="M364.57,1827.886h2.423v-2.423a2.423,2.423,0,0,0-2.423-2.423h0a2.423,2.423,0,0,0-2.423,2.423h0A2.423,2.423,0,0,0,364.57,1827.886Z" transform="translate(-357.505 -1808.669)" fill="#ff907a" />
            <path id="Caminho_2742" data-name="Caminho 2742" d="M385.123,1814.747h-9.2a6.808,6.808,0,0,1-6.8-6.8,3.231,3.231,0,0,1,6.463,0,.338.338,0,0,0,.338.338h9.2Z" transform="translate(-359.009 -1804.715)" fill="#333854" />
            <path id="Caminho_2743" data-name="Caminho 2743" d="M383,1811.608h-4.578a3.231,3.231,0,0,1,0-6.463H383a3.231,3.231,0,0,1,0,6.463Z" transform="translate(-360.319 -1804.808)" fill="#333854" />
            <circle id="Elipse_51" data-name="Elipse 51" cx="5.555" cy="5.555" r="5.555" transform="translate(7.068 30.707)" fill="#ff907a" />
            <path id="Subtração_10" data-name="Subtração 10" d="M30.3,12.38H0V0H24.11a6.184,6.184,0,0,1,2.173.4A8.913,8.913,0,0,1,30.3,2.788v9.59Z" transform="translate(0 36.262)" fill="#c7c7c7" stroke="rgba(0,0,0,0)" strokeMiterlimit="10" strokeWidth="1" />
          </g>
        </g>
        <circle id="Elipse_28" data-name="Elipse 28" cx="3.109" cy="3.109" r="3.109" transform="translate(210.137 96.123)" fill="#3f4572" />
        <path id="Retângulo_728" data-name="Retângulo 728" d="M364.911,1777.127h9.038c.179,0,.324.286.324.636h0c0,.352-.145.636-.324.636h-9.038c-.179,0-.324-.284-.324-.636h0C364.587,1777.412,364.732,1777.127,364.911,1777.127Z" transform="translate(-155.421 -1744.782)" fill="#3f4572" />
      </g>
    </g>
  </svg>

);

export default Illustration