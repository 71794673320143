import React from "react";

const Tips = ({
  fill,
  height,
  viewBox = "0 0 229.018 229.018",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path fill={fill} d="M114.512 51.743c-32.669 0-59.248 26.579-59.248 59.248 0 13.37 4.384 26.003 12.672 36.527 1.636 2.083 3.355 4.077 5.175 6.188l.1.116c5.568 6.46 11.325 13.142 13.475 21.01.486 1.803.555 5.26.527 7.923v4.526a7.5 7.5 0 0 0 7.5 7.5h39.272a7.5 7.5 0 0 0 7.5-7.5v-4.579c-.025-3.478.155-6.016.518-7.333 2.162-7.816 8.542-15.321 14.222-22.001l.245-.288c1.639-1.926 3.188-3.745 4.598-5.538 8.3-10.53 12.687-23.169 12.687-36.551-.001-32.669-26.577-59.248-59.243-59.248zm34.769 86.521c-1.235 1.571-2.691 3.282-4.233 5.093l-.302.355c-6.635 7.804-14.155 16.649-17.203 27.667-.431 1.564-.91 3.904-1.032 8.402h-24.312c-.104-4.777-.591-7.227-1.036-8.877-3.05-11.164-10.557-19.875-16.59-26.876l-.101-.118c-1.705-1.977-3.315-3.845-4.746-5.665-6.19-7.86-9.462-17.285-9.462-27.254 0-24.398 19.85-44.248 44.248-44.248 24.395 0 44.242 19.85 44.242 44.248 0 9.98-3.274 19.408-9.473 27.273zM133.984 196.91H94.713a7.5 7.5 0 0 0 0 15h39.272a7.5 7.5 0 1 0-.001-15zM124.166 214.018h-19.635a7.5 7.5 0 0 0 0 15h19.635a7.5 7.5 0 0 0 0-15zM218 103.491h-25.873a7.5 7.5 0 0 0 0 15H218a7.5 7.5 0 0 0 0-15zM44.394 110.991a7.5 7.5 0 0 0-7.5-7.5H11.018a7.5 7.5 0 0 0 0 15h25.876a7.5 7.5 0 0 0 7.5-7.5zM114.51 40.881a7.5 7.5 0 0 0 7.5-7.5V7.5a7.5 7.5 0 0 0-15 0v25.881a7.5 7.5 0 0 0 7.5 7.5zM174.693 160.569a7.5 7.5 0 0 0-10.608 10.606l18.296 18.301a7.476 7.476 0 0 0 5.304 2.197 7.5 7.5 0 0 0 5.304-12.803l-18.296-18.301zM54.317 61.408a7.477 7.477 0 0 0 5.304 2.198 7.5 7.5 0 0 0 5.304-12.802L46.636 32.508a7.5 7.5 0 0 0-10.606-.002 7.5 7.5 0 0 0-.002 10.606l18.289 18.296zM54.325 160.569L36.028 178.87a7.5 7.5 0 0 0 5.304 12.802 7.478 7.478 0 0 0 5.304-2.197l18.297-18.301a7.5 7.5 0 0 0-.001-10.606 7.501 7.501 0 0 0-10.607.001zM169.393 63.605a7.478 7.478 0 0 0 5.304-2.197l18.292-18.295a7.5 7.5 0 0 0-.001-10.606 7.498 7.498 0 0 0-10.606.001L164.09 50.803a7.5 7.5 0 0 0 .001 10.606 7.467 7.467 0 0 0 5.302 2.196z"/>
  </svg>
);

export default Tips;