import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import { Trans } from '@lingui/macro';

function CartItem({
  date,
  duration,
  isDeletable,
  itemId,
  onDelete,
  price,
  time,
  weekday,
}) {
  return (
    <div className="CartItem" id={`cart-item-${itemId}`}>
      <div className="CartItem__body">
        <div className="CartItem__data">
          <div className="CartItem__data__date">
            <Typography>
              {date} - {weekday} <Trans>às</Trans> {time}
            </Typography>
          </div>
          <div className="CartItem__data__price">
            <Typography bold>
              {price && `R$ ${price}`}{duration ? ` (${duration}min.)` : null}
            </Typography>
          </div>
        </div>
        {
          isDeletable ? (
            <div className="CartItem__delete"
              onClick={() => onDelete(itemId)}
              id={`delete-${itemId}`}>
              <CoreIcons name="Trash"
                fill="#898989"
                width="24px"
                viewBox="0 0 24 24"
              />
            </div>
          ) : null
        }
      </div>
      <div className="CartItem__separator"></div>
    </div>
  );
}

CartItem.propTypes = {
  isDeletable: PropTypes.bool,
  item: PropTypes.object,
  onDelete: PropTypes.func,
};

export { CartItem };
