import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;

  > input {
    width: 100%;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    padding: 14px;
    font-size: 1.5rem;
    
    &:focus {
      border-color: #1f35b5;
    }
  }

  > button {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 50px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: 20px;
      height: 20px;
      color: #8c8c8c;
    }

    > div {
      width: 20px; 
      height: 20px;
      border-width: 2px;
    }
  }
`