import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  gap: 2px;
`

export const CancelationReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-top: 20px;
  width: 100%;

  .cancelationDescription {
    margin-top: 20px;
  }
`