import {
  getTime,
  toDayMonthYear,
  getDayName,
  isToday,
  tomorrow,
} from 'utils/dateTimeHandlers'
import { getRoleFromProfile } from 'utils/textTransformations'
import { PSYCHOLOGIST, PATIENT } from 'settings/_profileSettings'
import { formatFloatToReal } from 'utils/orderHandlers'
import { 
  AdminSchedule, 
  FormattedAdminScheduleData, 
  FormattedScheduleForRescheduleModal,
  GroupedAdminSchedules,
  GroupedAdminSchedulesByDate
} from '../types'

function formatScheduleData(schedules: AdminSchedule[]): FormattedAdminScheduleData[] {
  const newScheduleData = schedules.map(schedule => {
    const isoDate = schedule.start_date
    const scheduleId = schedule.id
    const sessionId = schedule.session
    const scheduleStatus = schedule.status_code
    const cancellationEnabled = schedule.cancellation_enabled
    const rescheduleEnabled = schedule.reschedule_enabled
    const scheduleStatusName = schedule.status_name
    const consultationEvaluation = schedule.consultation_evaluation
    const planFullName = schedule.order_item.order.plan_full_name
    const guestList = schedule.guests
    const planCode = schedule.order_item.order.plan_code
    const virtualRoomHash = schedule.virtual_room_hash

    const productDuration = schedule.order_item
      .person_profile_product_appointment
      .person_profile_product.product.product_general.duration

    const productId = schedule.order_item
      .person_profile_product_appointment
      .person_profile_product.product.id

    const productCode = schedule.order_item
      .person_profile_product_appointment
      .person_profile_product.product.code

    const professionalDocumentList = schedule.order_item
      .person_profile_product_appointment
      .person_profile_product.person_profile.documents

    const date = toDayMonthYear(schedule.start_date)
    const dayName = getDayName(schedule.start_date)
    const dayTomorrow = tomorrow(schedule.start_date)
    const today = isToday(schedule.start_date)
    const time = getTime(schedule.start_date)
      .split(':')
      .join('h')

    const price = `R$ ${formatFloatToReal(schedule.order_item.price)}`

    const professionalData = schedule.guests.find(professional =>
      getRoleFromProfile(professional.person_profile.profile_code) === PSYCHOLOGIST ||
      getRoleFromProfile(professional.person_profile.profile_code) === 'PSIQ'
    )
    const professionalFirstName = professionalData.person_profile.person.person_general.first_name
    const professionalLastName = professionalData.person_profile.person.person_general.last_name
    const professionalName = `${professionalFirstName} ${professionalLastName}`

    const professionalDocument =
      professionalDocumentList.length > 0 ?
        professionalDocumentList[0].value : ''

    const clientData = schedule.guests.find(client =>
      getRoleFromProfile(client.person_profile.profile_code) === PATIENT
    )
    const clientFirstName = clientData.person_profile.person.person_general.first_name
    const clientLastName = clientData.person_profile.person.person_general.last_name
    const clientName = `${clientFirstName} ${clientLastName}`

    return {
      date: date,
      today,
      dayTomorrow,
      dayName,
      time: time,
      id: scheduleId,
      session: sessionId,
      status: scheduleStatus,
      statusName: scheduleStatusName,
      cancellationEnabled,
      rescheduleEnabled,
      price: price,
      clientName: clientName,
      professionalName: professionalName,
      guestList: guestList,
      client: clientData,
      planCode: planCode,
      professional: professionalData,
      productDuration,
      productId,
      productCode,
      professionalDocument,
      isoDate,
      consultationEvaluation,
      planFullName,
      virtualRoomHash,
      medicalReturn: schedule.medical_return,
      startDate: schedule.start_date
    }
  })

  return newScheduleData
}

const sortByDate = (groupedSchedules: GroupedAdminSchedules) => {
  const availableDates = Object.keys(groupedSchedules)

  return availableDates.map(date => {
    const dateSchedules = groupedSchedules[date]
    
    return {
      date,
      isoDate: dateSchedules[0].isoDate,
      status: dateSchedules[0].statusName,
      schedules: dateSchedules,
    }
  })
}

export const handleGroupSchedulesByDate = (schedules: AdminSchedule[]): GroupedAdminSchedulesByDate[] => {
  const formattedSchedules = formatScheduleData(schedules)
  const groupedSchedules: { [date: string]: FormattedAdminScheduleData[] } = {}

  formattedSchedules.forEach((currentItem) => {
    const date = currentItem.date

    if (!groupedSchedules[date]) {
      groupedSchedules[date] = []
    }
    groupedSchedules[date].push(currentItem)
  })

  return sortByDate(groupedSchedules)
}

export const formatScheduleForRescheduleModal = (schedule: FormattedAdminScheduleData): FormattedScheduleForRescheduleModal => {
  const scheduleData = {
    duration: schedule.productDuration,
    id: schedule.id,
    plan_code: schedule.planCode,
    session: schedule.session,
    status: schedule.status,
  }
    
  const clientData = schedule.client.person_profile
    
  const client = {
    name: clientData.person.person_general.full_name,
    username: clientData.person.username,
    profileCode: clientData.profile_code,
  }
    
  const professionalData = schedule.professional.person_profile
    
  const professional = professionalData ? {
    name: professionalData.person.person_general.full_name,
    document: schedule.professionalDocument,
    username: professionalData.person.username,
    profileCode: professionalData.profile_code,
  } : {}
    
  const productData = { 
    id: schedule.productId,
    code: schedule.productCode,
  }
    
  return {
    client,
    professional,
    scheduleData,
    productData,
  }
}