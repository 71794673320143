import { apiEndpoints } from 'settings/_apiSettings'
import axios from 'settings/axios'

interface Error {
  response: {
    status: number
  }
}

export const isCardNumberAvailiable = async (cardNumber: string, selectedGroupPlan: string): Promise<void> => {

  const endpoint = apiEndpoints.PERSON_PROFILE_BY_CARD(cardNumber, selectedGroupPlan)

  return new Promise<void> ((resolve, reject) => {
    axios.head(endpoint)
      .then(() => resolve())
      .catch((error: Error) => reject(error.response.status))
  })
}
