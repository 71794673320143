import { Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

interface CalendarDate {
  hasLength: boolean
}

export const FilterActionBtn = styled.button`
  background: transparent;
  display: flex; 
  align-items: center;
  justify-content: space-between;
  border: 1px solid #C1C1C1;
  border-radius: 4px;
  padding: 12px 16px;
  width: 100%;
`

export const PlaceholderText = styled(Typography)<CalendarDate>`
  color: ${({ hasLength }) => (hasLength ? '#4B4B4B' : '#C1C1C1')};
`