export const initializeIntercom = (id) => {
  window.intercomSettings = { app_id: id };
  (function () { var w = window; var ic = w.Intercom; if (typeof ic === "function") { ic('reattach_activator'); ic('update', w.intercomSettings); } else { var d = document; var i = function () { i.c(arguments); }; i.q = []; i.c = function (args) { i.q.push(args); }; w.Intercom = i; var l = function () { var s = d.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `https://widget.intercom.io/widget/${id}`; var x = d.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); }; if (w.attachEvent) { w.attachEvent('onload', l); } else { w.addEventListener('load', l, false); } } })();
};

export const showIntercom = (isAuth, userData) => {
  // if (window.innerWidth > 1095) {
    if (isAuth) {
      window.Intercom('update', {
        "hide_default_launcher": false,
        "name": userData.name,
        "email": userData.email,
      });
    } else {
      window.Intercom('update', { "hide_default_launcher": false });
    }
  // }else{
  //   window.Intercom('update', { "hide_default_launcher": true });
  // }
};

export const hideIntercom = () => {
  window.Intercom('update', { "hide_default_launcher": true });
};