import styled from 'styled-components'

interface TableTitleProps {
  isLeft?: boolean
}

export const TableTitle = styled.th<TableTitleProps>`
  font-size: 12px;
  font-weight: normal;
  text-align: ${({ isLeft }) => isLeft && 'left'};
`