import React from "react";

const Professional = ({
  fill,
  height,
  viewBox = "0 0 50 50",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
      <circle fill="none" stroke={fill} strokeWidth="2" cx="25" cy="25" r="24" />
      <path fill={fill} d="M39.924 20.204L25.583 13.83a1.593 1.593 0 0 0-1.291 0L9.951 20.204a1.593 1.593 0 0 0 0 2.916l3.187 1.386-.956.335v10.357a.8.8 0 1 0 1.593 0v-9.369l11.154-4.175-1.944-.86-7.776 2.915-4.621-2.055L24.93 15.28l14.341 6.374-14.341 6.374-5.833-2.582-2.135.8 7.314 3.187a1.593 1.593 0 0 0 1.291 0l7.314-3.187v4.972a12.413 12.413 0 0 1-7.967 3.187 12.413 12.413 0 0 1-7.967-3.187v-4.975l-1.593.59v5.051l.478.462a13.991 13.991 0 0 0 9.1 3.649 13.991 13.991 0 0 0 9.083-3.6l.478-.462v-6.375l5.434-2.406a1.593 1.593 0 0 0 0-2.916z" />
    </g>
  </svg>
);

export default Professional;