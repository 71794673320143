import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'

import { Typography, Button } from '@telavita-core/react-design-kit'
import HelpIcon from 'components/CoreIcons/Icons/Help'
import ArrowLeftIcon from 'components/CoreIcons/Icons/ArrowLeft'
import ArrowRightIcon from 'components/CoreIcons/Icons/ArrowRight'
import { Spinner } from 'components'
import { HelpModal } from 'containers/ModalManager/Modals/HelpModal'

import { ReactComponent as CheckIcon} from 'static/svg/icon-check-green.svg'
import { ReactComponent as DangerIcon} from 'static/svg/icon-check-red.svg'

import { handleQuestions } from './questions'

import { ScheduleContainerFrequencyOptions } from 'containers/ScheduleContainer/_settings'
import { DISAPPROVED, INVALIDATED, FINISHED, WAITING_VALIDATION } from 'settings/_creditStatusSettings'

export const CategoriesQuestion = {
  SOLICITED_NEW_CREDITS: 'solicitedNewCredits',
  WITH_CREDITS_AND_NOT_EXPIRED: 'withCreditsAndNotExpired',
  TREATMENT_VALIDITY_DISAPPROVED: 'treatmentValidityDisapproved',
  WITHOUT_CREDITS_AND_NOT_EXPIRED: 'withoutCreditsAndNotExpired',
  SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST: 'solicitedNewCreditsAndWaitingMedicalRequest',
}

const PatientEligibility = ({
  variant,
  isEnterprise,
  isCorporate,
  hasButton,
  planGroupName,
  creditsStatus,
  availableCredits,
  maxCredits,
  enableDetails,
  validDate,
  expired,
  errorButton,
  loadingButton,
  solicitationDate,
  isUnlimited,
  startDate,
  loading,
  corporateEligibility,
  onClickRequestCreditsButton,
  forceUpdate
}) => {
  const [helpModal, setHelpModal] = useState(false)
  const [relatedQuestions, setRelatedQuestions] = useState([])
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0)
  const [activeQuestionId, setActiveQuestionId] = useState(null)
  
  const [checkIcon, setCheckIcon] = useState(false)
  const [dangerIcon, setDangerIcon] = useState(false)

  const {
    TREATMENT_VALIDITY_DISAPPROVED,
    WITHOUT_CREDITS_AND_NOT_EXPIRED,
    SOLICITED_NEW_CREDITS,
    WITH_CREDITS_AND_NOT_EXPIRED,
    SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST,
  } = CategoriesQuestion

  const hasRelatedQuestions = !relatedQuestions.length
  const isQuestionIndexEnd = (activeQuestionIndex + 1) >= relatedQuestions.length
  const isQuestionIndexStart = activeQuestionIndex === 0

  useEffect(() => {
    setRelatedQuestions([])
  }, [forceUpdate])

  const infoParagraphStyle = classnames('PatientEligibility__text', {
    'PatientEligibility__text--available': isCorporate ? !expired && (corporateEligibility.availableCredits > 0)  : !expired && (availableCredits > 0),
    'PatientEligibility__text--unavailable': isCorporate ? !expired && (corporateEligibility.availableCredits === 0)  : !expired && (availableCredits === 0),
    'PatientEligibility__text--expired': expired,
  })

  const dateStyle = classnames('PatientEligibility__text', {
    'PatientEligibility__text--expired-date-corporate': isCorporate && expired && corporateEligibility.isEligible,
    'PatientEligibility__text--inelegible-corporate': isCorporate && expired,
    'PatientEligibility__text--inelegible-enterprise': isEnterprise && expired,
  })

  const infoStyle = classnames({
    'PatientEligibility__info': true,
    'PatientEligibility__info--clickable': true,
    'PatientEligibility__corporate': isCorporate,
    'PatientEligibility__corporate--eligible': isCorporate && corporateEligibility.isEligible,
    'PatientEligibility__corporate--ineligible': isCorporate && !corporateEligibility.isEligible,
  })

  const handleOpenQuestion = (questionId) => {
    setActiveQuestionId(questionId)
    setHelpModal(true)
  }

  const prevQuestion = () => {
    setActiveQuestionIndex(prevState => prevState - 1)
  }

  const nextQuestion = () => {
    setActiveQuestionIndex(prevState => prevState + 1)
  }

  const getCorporateFrequency = () => {
    if (corporateEligibility.frequency === ScheduleContainerFrequencyOptions.WEEKLY)
      return 'semana'

    else if (corporateEligibility.frequency === ScheduleContainerFrequencyOptions.BIWEEKLY)
      return '2 semanas'

    else if (corporateEligibility.frequency === ScheduleContainerFrequencyOptions.QUARTERLY)
      return '12 semanas'
  }

  const getMessageColor = () => {
    if (isCorporate && corporateEligibility.isEligible) {
      if (isUnlimited) return 'success'
      if (corporateEligibility.availableCredits === 0) return 'danger'
      if (expired) return 'danger'
      return 'success'
    }

    if (isEnterprise) {
      const hasExpiredCredits = availableCredits > 0 && expired && creditsStatus !== WAITING_VALIDATION
      const hasDangerCreditStatus = ([DISAPPROVED, INVALIDATED]).includes(creditsStatus)
      const hasZeroCredits = availableCredits === 0
            
      if (hasExpiredCredits || hasDangerCreditStatus || errorButton || hasZeroCredits) return 'danger'
    }
    
    else if (isCorporate && !corporateEligibility.isEligible)
      return 'danger'

    else if (isCorporate && corporateEligibility.availableCredits > 0 && expired)
      return 'danger'

  }

  const isCASSI = planGroupName === 'CASSI'
  const daysToAuthorizeProcedure = isCASSI ? 5 : 3

  const renderInfo = () => {
    if (isCorporate){
      if (isUnlimited){
        return (
          <Typography
            variant="content2"
            inheritColor key="semanal"
            dangerouslySetInnerHTML={ {
              __html: `Você tem direito a <strong>1 consulta</strong> a cada <strong>${getCorporateFrequency()}</strong>`
            } }
          />
        )
      } else {
        return(<>
          <div className='PatientEligibility__textGroup'>
            <p className="PatientEligibility__text--label">Créditos disponíveis:</p>
            <p className={infoParagraphStyle}>
              {corporateEligibility.availableCredits} de {corporateEligibility.maxCredits}
            </p>
          </div>
          <div className='PatientEligibility__textGroup'>
            <p className="PatientEligibility__text--label">Válido até:</p>
            <p className={dateStyle}>
              {String(format(validDate, 'dd/MM/yyyy'))}
            </p>
          </div>
        </>)
      }
    }

    if (isEnterprise){
      if (enableDetails){
        return(<>
          <div className='PatientEligibility__textGroup'>
            <p className="PatientEligibility__text--label">Créditos disponíveis:</p>
            <p className={infoParagraphStyle}>
              {availableCredits} de {maxCredits}
            </p>
          </div>
          <div className='PatientEligibility__textGroup'>
            <p className="PatientEligibility__text--label">Válido até:</p>
            <p className={dateStyle}>{String(format(validDate, 'dd/MM/yyyy'))}</p>
          </div>
        </>)
      }
    }
  }

  const renderMessage = () => {
    if (isCorporate) {
      if (isUnlimited) {
        if (corporateEligibility.isEligible) {
          return 'Agendamentos liberados em todos os meses disponíveis no calendário do psicólogo.'
        } else {
          return 'Seu CPF não consta mais na lista de beneficiários do seu convênio. Infelizmente, isso impossibilita o agendamento de consultas. <strong>Se houver algum engano</strong>, entre em contato com nossa equipe pelo chat para mais informações.'
        }
      } else {
        if (corporateEligibility.isEligible) {
          if (corporateEligibility.availableCredits === 0) {
            return '*Não há mais consultas disponíveis pelo seu convênio.'
          } else if (corporateEligibility.availableCredits > 0 && expired) {
            return '*A validade do(s) seu(s) crédito(s) de consulta expirou.'
          } else {
            return `Você tem direito a <strong>1 consulta</strong> a cada <strong>${getCorporateFrequency()}</strong>`
          }
        } else {
          return 'Seu CPF não consta mais na lista de beneficiários do seu convênio. Infelizmente, isso impossibilita o agendamento de consultas. Se houver algum engano, entre em contato com nossa equipe pelo chat para mais informações.'
        }
      }
    }

    if (isEnterprise) {
      const withCreditsAndExpired = creditsStatus === FINISHED && expired
      const withCreditsAndNotExpired = availableCredits > 0 && !expired && !([DISAPPROVED, WAITING_VALIDATION, INVALIDATED].includes(creditsStatus)) 
      const solicitedNewCredits = creditsStatus === WAITING_VALIDATION && startDate
      const solicitedNewCreditsAndWaitingMedicalRequest = creditsStatus === WAITING_VALIDATION && !startDate
      const treatmentValidityDisapproved = creditsStatus === DISAPPROVED
      const creditsInvalidated = creditsStatus === INVALIDATED
      const withoutCreditsAndNotExpired = availableCredits === 0 && !expired

      if(withCreditsAndNotExpired) {
        if(hasRelatedQuestions) setRelatedQuestions(handleQuestions().filter(question => question.category === WITH_CREDITS_AND_NOT_EXPIRED))
        if(checkIcon) setCheckIcon(false)
        if(dangerIcon) setDangerIcon(false)
        return '<strong>Importante:</strong> a agenda dos profissionais só mostrará disponibilidades até a data de validade dos seus créditos.'
      }

      if(withCreditsAndExpired) {          
        if(checkIcon) setCheckIcon(false)
        if(dangerIcon) setDangerIcon(false)
        return '*A validade do(s) seu(s) crédito(s) expirou. Para renovar, use o botão de solicitação abaixo.'
      }

      if(solicitedNewCredits) {
        if(hasRelatedQuestions) setRelatedQuestions(handleQuestions().filter(question => question.category === SOLICITED_NEW_CREDITS))
        if(!checkIcon) setCheckIcon(true)
        if(dangerIcon) setDangerIcon(false)
        return `Solicitação de novos créditos feita em <strong>${solicitationDate}</strong>. <br />Agora é só aguardar a aprovação do seu convênio. <br /><strong>Em até ${daysToAuthorizeProcedure} dias úteis</strong> a partir da data da solicitação, avisaremos por e-mail e SMS.`
      }

      if(solicitedNewCreditsAndWaitingMedicalRequest) {
        if(checkIcon) setCheckIcon(false)
        if(dangerIcon) setDangerIcon(false)
        if(hasRelatedQuestions) setRelatedQuestions(handleQuestions().filter(question => question.category === SOLICITED_NEW_CREDITS_AND_WAITING_MEDICAL_REQUEST))
        return `Seu pedido médico está em aprovação pelo convênio. Enquanto aguardamos a liberação <strong>(que leva até ${daysToAuthorizeProcedure} dias úteis)</strong>, não é possível agendar novas consultas. Mas não se preocupe, avisaremos por e-mail e SMS.`
      }
       
      if(treatmentValidityDisapproved){
        if(hasRelatedQuestions) setRelatedQuestions(handleQuestions().filter(question => question.category === TREATMENT_VALIDITY_DISAPPROVED))
        if(!dangerIcon) setDangerIcon(true)
        if(checkIcon) setCheckIcon(false)
        return 'Infelizmente sua solicitação foi reprovada pelo seu convênio. Para mais informações, entre em contato com a nossa equipe de atendimento pelo chat.'
      }
      
      if(creditsInvalidated) {
        if(checkIcon) setCheckIcon(false)
        if(dangerIcon) setDangerIcon(false)
        return 'Seus créditos estão inválidos e por isso não será possível realizar uma consulta. Caso não reconheça o motivo da invalidação, entre em contato com a nossa equipe de atendimento pelo chat.'        
      }

      if(withoutCreditsAndNotExpired) {
        if(checkIcon) setCheckIcon(false)
        if(dangerIcon) setDangerIcon(false)
        if(hasRelatedQuestions) setRelatedQuestions(handleQuestions().filter(question => question.category === WITHOUT_CREDITS_AND_NOT_EXPIRED))
        return `*Assim que você realizar todas as consultas agendadas, pediremos a renovação dos seus créditos junto ao convênio e <strong>em até ${daysToAuthorizeProcedure} dias úteis</strong> te avisaremos por e-mail e SMS.`
      }
    }
  }

  const renderContent = () => {
    if (loading)
      return <Spinner height="48px" />
    
    return (
      <>
        <div className={infoStyle}>
          {renderInfo()}
        </div>
        <div className='PatientEligibility__message'>
          {
            (dangerIcon || checkIcon) && (
              <div style={{ width: 24, height: 24, marginRight: 10 }}>
                {dangerIcon && <DangerIcon />}
                {checkIcon && <CheckIcon />}
              </div>)
          }
          <Typography
            customClassName='PatientEligibility__messageText'
            variant="content3"
            color={getMessageColor()}
            dangerouslySetInnerHTML={{ __html: renderMessage() }}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <div className='PatientEligibility'>
        <div className='PatientEligibility__title'>
          <div className='PatientEligibility__title__wrapper'>
            Seu convênio
            <span className="PatientEligibility__title__groupName">
              {planGroupName}
            </span>
          </div>
          {isEnterprise && (
            <div
              className='PatientEligibility__title__icon'
              onClick={() => {
                setActiveQuestionId(null)
                setHelpModal(true)
              }}
            >
              <HelpIcon
                fill = '#1F35B5'
                height = '24'
                width = '24'
                viewBox = '0 0 24 24'
              />
            </div>
          )}
        </div>
        <div className='PatientEligibility__content'>
          {renderContent()}
          {
            hasButton
              ? loadingButton
                ? <span className="PatientEligibility__spinner">
                  <Spinner color="white" height="20px" />
                </span>
                : <div className="PatientEligibility__info__credButton">
                  <Button
                    variant={variant === 'creditsPanel' ? 'contained' : 'outlined'}
                    disabled={false}
                    onClick={onClickRequestCreditsButton}
                    uppercase={variant === 'creditsPanel'}
                    inlineStyles={{
                      height: variant === 'creditsPanel' ? '48px' : '36px',
                      padding: '0 10px',
                      width: variant === 'creditsPanel' && '100%',
                      marginTop: variant === 'creditsPanel' && '20px',
                    }}              
                  >
                    Solicitar novos créditos
                  </Button>
                </div>
              : null
          }
        </div>
      </div>
      {
        relatedQuestions.length
          ? (
            <div className='PatientEligibility__footer'>
              <div className='PatientEligibility__footer__wrapper'>
                <Typography
                  variant='content3'
                  inlineStyles={{
                    color: '#898989',
                    marginBottom: '5px', 
                  }}
                >
                  DÚVIDA FREQUENTE
                </Typography>
                
                <Typography
                  customClassName='PatientEligibility__footer__wrapper__question'
                  variant='content3'
                  color='primary'
                  weight='bold'
                  onClick={() => handleOpenQuestion(relatedQuestions[activeQuestionIndex]?.id)}
                  inlineStyles={{
                    lineHeight: '16px',
                  }}
                >
                  {
                    relatedQuestions[activeQuestionIndex]?.reducedTitle || 
                    relatedQuestions[activeQuestionIndex]?.title
                  }
                </Typography>

              </div>
              {relatedQuestions.length > 1 && (
                <div className='PatientEligibility__footer__arrows'>
                  <button
                    onClick={prevQuestion}
                    disabled={isQuestionIndexStart}
                  >
                    <ArrowLeftIcon 
                      fill='#000'
                      height='24px'
                      width='24px'
                    />
                  </button>
                  <button
                    onClick={nextQuestion}
                    disabled={isQuestionIndexEnd}
                  >
                    <ArrowRightIcon 
                      fill='#000'
                      height='24px'
                      width='24px'
                    />
                  </button>
                </div>
              )}
            </div>
          )
          : null
      }
      {helpModal && (
        <HelpModal 
          title='Tire suas dúvidas sobre os créditos de convênio'
          questions={handleQuestions(daysToAuthorizeProcedure)}
          onClose={() => setHelpModal(false)}
          activeQuestion={activeQuestionId}
        />
      )}
    </>
  )
}

export { PatientEligibility }
