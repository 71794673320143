import { useQuery } from '@tanstack/react-query'
import { RelationshipDegree, getPatientPersonalData } from 'services/patients/getPatientPersonalData'

interface PatientPersonalData {
  username: string
  fullName: string
  email: string
  cpf: string
  cellphoneNumber: string
  birthDate: string
  address: {
    cep: string
    address: string
    number: string
    complement: string
    neighborhood: string
    city: string
    state: string
  }
  familyContact: {
    fullName: string
    phone: string
    relationship: string
  }
}

interface UseGetPatientPersonalDataReturn {
  personalData: PatientPersonalData 
}

function formatRelationshipDegree(relationshipDegree: RelationshipDegree): string {
  const relationshipMapping: { [key in RelationshipDegree]: string } = {
    FATHER: 'Pai',
    MOTHER: 'Mãe',
    SPOUSE: 'Cônjuge',
    SIBLING: 'Irmão',
    OTHER: 'Outro'
  }

  return relationshipMapping[relationshipDegree]
}

export const useGetPatientPersonalData = (patientEmail: string): UseGetPatientPersonalDataReturn => {
  const { data } = useQuery({
    queryKey: ['PATIENT_PERSONAL_DATA', patientEmail],
    queryFn: () => getPatientPersonalData(patientEmail),
    enabled: !!patientEmail
  })

  const personalData: PatientPersonalData = {
    username: data?.username,
    fullName: data?.full_name,
    email: data?.email,
    cpf: data?.cpf,
    cellphoneNumber: data?.cellphone_number,
    birthDate: data?.birth_date,
    address: {
      cep: data?.address_data.cep,
      address: data?.address_data.address,
      number: data?.address_data.address_number,
      complement: data?.address_data.complement,
      neighborhood: data?.address_data.neighborhood,
      city: data?.address_data.city,
      state: data?.address_data.state 
    },
    familyContact: {
      fullName: data?.relative_data.relative_full_name,
      phone: data?.relative_data.relative_phone_number,
      relationship: formatRelationshipDegree(data?.relative_data.relationship_degree)
    }
  }

  return {
    personalData
  }
}