import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .dropdown {
    width: 100%;
    margin: 30px 0 10px 0;
  }

  .InactivatePlanModal__Label {
    align-self: flex-start;
    color: #898989;
    margin: 30px 0;
  }

  .InactivatePlanModal__SubmitButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 50px; 
    height: 50px;
    margin-bottom: 10px;
    path {
      fill: #4AC326;
    }
  }

  .InactivatePlanModal__SuccessTitle {
    margin-bottom: 20px;
  }

  .InactivatePlanModal__SuccessMessageWrapper {
    display: flex;
    gap: 15px;
    max-width: none;
    margin-bottom: 40px;
    padding: 20px;
  }
` 

export const PatientInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
