import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 300px;
  position: relative;

  .containerTitle {
    margin-bottom: 30px;
  }
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  flex-direction: column;
  gap: 20px;
  inset: 0;
  background-color: #FFF;
`