import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > h4 {
    margin: 10px 0 5px;
  }

  & > p:first-of-type {
    color: #898989;

    & > span {
      font-weight: bold;
    }
  }

  & > p:last-of-type {
    margin: 20px 0 10px;
  }
`