import React from "react";

const AllSchedules = ({
  fill = "#4b4b4b",
  height = "39.262",
  viewBox = "0 0 37.264 39.262",
  width = "37.264",
}) => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      width={width} 
      height={height} 
      viewBox={viewBox}
    >
      <g id="Grupo_553" data-name="Grupo 553" transform="translate(48.379 2.882)">
        <g id="Group_425" data-name="Group 425">
          <g id="Group_402" data-name="Group 402">
            <path id="Path_2227" data-name="Path 2227" d="M-22.712-2.881a1.082,1.082,0,0,1,1.08,1.08V.325h6.271a4.251,4.251,0,0,1,4.246,4.247V32.137a4.247,4.247,0,0,1-4.243,4.243H-44.136a4.245,4.245,0,0,1-4.243-4.239V4.571A4.251,4.251,0,0,1-44.132.325h6.271V-1.8a1.08,1.08,0,0,1,1.08-1.081A1.081,1.081,0,0,1-35.7-1.8V.325h11.906V-1.8a1.082,1.082,0,0,1,1.082-1.08m0,8.576a1.082,1.082,0,0,1-1.081-1.08V2.489H-35.7v2.13A1.08,1.08,0,0,1-36.781,5.7a1.079,1.079,0,0,1-1.08-1.08V2.489h-6.271a2.087,2.087,0,0,0-2.086,2.086V7.949H-13.28V4.571a2.087,2.087,0,0,0-2.085-2.086h-6.271V4.611A1.075,1.075,0,0,1-22.7,5.7h-.009M-44.136,34.219h28.775a2.083,2.083,0,0,0,2.081-2.078V10.11H-46.218V32.141a2.084,2.084,0,0,0,2.082,2.078" fill={fill} />
          </g>
        </g>
        <g id="Group_403" data-name="Group 403">
          <path id="Path_2228" data-name="Path 2228" d="M-40.5,20.959a1.206,1.206,0,0,1,1.207,1.207A1.206,1.206,0,0,1-40.5,23.373a1.206,1.206,0,0,1-1.207-1.207A1.208,1.208,0,0,1-40.5,20.959" fill={fill} />
        </g>
        <g id="Group_404" data-name="Group 404">
          <path id="Path_2229" data-name="Path 2229" d="M-40.5,28.127a1.206,1.206,0,0,1,1.207,1.207A1.206,1.206,0,0,1-40.5,30.541a1.206,1.206,0,0,1-1.207-1.207A1.206,1.206,0,0,1-40.5,28.127" fill={fill} />
        </g>
        <g id="Group_413" data-name="Group 413">
          <path id="Path_2238" data-name="Path 2238" d="M-26.164,13.788a1.207,1.207,0,0,1,1.208,1.207A1.207,1.207,0,0,1-26.164,16.2a1.206,1.206,0,0,1-1.207-1.207,1.209,1.209,0,0,1,1.207-1.207" fill={fill} />
        </g>
        <g id="Group_414" data-name="Group 414">
          <path id="Path_2239" data-name="Path 2239" d="M-26.164,20.959a1.207,1.207,0,0,1,1.208,1.207,1.207,1.207,0,0,1-1.208,1.207,1.206,1.206,0,0,1-1.207-1.207h0a1.209,1.209,0,0,1,1.207-1.207" fill={fill} />
        </g>
        <g id="Group_415" data-name="Group 415">
          <path id="Path_2240" data-name="Path 2240" d="M-26.164,28.127a1.207,1.207,0,0,1,1.208,1.207,1.207,1.207,0,0,1-1.208,1.207,1.206,1.206,0,0,1-1.207-1.207,1.208,1.208,0,0,1,1.207-1.207" fill={fill} />
        </g>
        <g id="Group_417" data-name="Group 417">
          <path id="Path_2242" data-name="Path 2242" d="M-33.33,13.788a1.206,1.206,0,0,1,1.207,1.207A1.206,1.206,0,0,1-33.33,16.2a1.207,1.207,0,0,1-1.208-1.207,1.209,1.209,0,0,1,1.208-1.207" fill={fill} />
        </g>
        <g id="Group_418" data-name="Group 418">
          <path id="Path_2243" data-name="Path 2243" d="M-33.33,20.959a1.206,1.206,0,0,1,1.207,1.207,1.206,1.206,0,0,1-1.207,1.207,1.207,1.207,0,0,1-1.208-1.207,1.209,1.209,0,0,1,1.208-1.207" fill={fill} />
        </g>
        <g id="Group_419" data-name="Group 419">
          <path id="Path_2244" data-name="Path 2244" d="M-33.33,28.127a1.206,1.206,0,0,1,1.207,1.207,1.206,1.206,0,0,1-1.207,1.207,1.207,1.207,0,0,1-1.208-1.207,1.208,1.208,0,0,1,1.208-1.207" fill={fill} />
        </g>
        <g id="Group_421" data-name="Group 421">
          <path id="Path_2246" data-name="Path 2246" d="M-18.993,13.788a1.206,1.206,0,0,1,1.207,1.207A1.206,1.206,0,0,1-18.993,16.2,1.206,1.206,0,0,1-20.2,14.995a1.209,1.209,0,0,1,1.207-1.207" fill={fill} />
        </g>
        <g id="Group_422" data-name="Group 422">
          <path id="Path_2247" data-name="Path 2247" d="M-18.993,20.959a1.206,1.206,0,0,1,1.207,1.207,1.206,1.206,0,0,1-1.207,1.207A1.206,1.206,0,0,1-20.2,22.166a1.209,1.209,0,0,1,1.207-1.207" fill={fill} />
        </g>
      </g>
    </svg>
  );


export default AllSchedules;