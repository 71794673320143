import React from 'react';
import PropTypes from 'prop-types';
import { CoreIcons } from 'components';
import Typography from 'components/Typography';
import { cleanGenericError } from 'redux/actions/asyncActions';
import { connect } from 'react-redux';

const ToastGenericErrorComponent = ({ message, iconName, cleanError }) => {

  let styleDefault = {
    iconColor: '#eb384d',
    textColor: 'false'
  }

  return (
    <div className="ToastGenericError">
      <div className="ToastGenericError__BoxAlert">
        <div className="ToastGenericError__BoxAlert__Icon">
          <CoreIcons
            fill={styleDefault.iconColor}
            name={iconName}
            height="44px"
            width="44px"
          />
        </div>
        <div className="ToastGenericError__BoxAlert__Text">
          <Typography color="false" type="custom">{message}</Typography>
        </div>
      </div>
      <div className="ToastGenericError__BoxClose">
        <div 
          className="ToastGenericError__BoxClose__IconClose"
          onClick={cleanError}
        >
          <CoreIcons name='Close' />
        </div>
      </div>
    </div>
  );
};

ToastGenericErrorComponent.propTypes = {
  iconName: PropTypes.string,
  message: PropTypes.string
};

ToastGenericErrorComponent.defaultProps = {
  iconName: 'Info'
};

const mapStateToProps = state => {
  return { isAuth: state.auth.isAuth };
};

const mapDispatchToProps = dispatch => {
  return { cleanError: () => dispatch(cleanGenericError()) };
};

let ToastGenericError = connect(mapStateToProps, mapDispatchToProps)(ToastGenericErrorComponent);

export { ToastGenericError };
