import styled from 'styled-components'

interface ContainerProps {
  backgroundColor: string
}

export const Container = styled.div<ContainerProps>`
  width: 75px;
  height: 110px;
  margin-right: 15px;
  border-radius: 10px;
  padding: 10px 5px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  position: relative;

  .text {
    color: #FFFFFF;
  }

  > svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`

export const Content = styled.div`
  z-index: 1;
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
`