import React from "react";

const GeneralView = ({
  fill,
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    id="Icone_Consulta" data-name="Icone Consulta" xmlns="http://www.w3.org/2000/svg">
    <path id="Caminho_2175" data-name="Caminho 2175" d="M0,0H24V24H0Z" fill="white"/>
    <path id="Caminho_2517" data-name="Caminho 2517" d="M9,17H7V10H9Zm4,0H11V7h2Zm4,0H15V13h2Zm2.5,2.1H4.5V5h15ZM19.5,3H4.5a2.006,2.006,0,0,0-2,2V19a2.006,2.006,0,0,0,2,2h15a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19.5,3Z" transform="translate(0.5)" fill={fill}/>
  </svg>
);

export default GeneralView;