import React from 'react'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Typography } from '@telavita-core/react-design-kit'

import { ReactComponent as Stripes } from 'static/svg/stripes.svg'

import * as S from './styles'

interface TileProps {
  date: Date
  isRecurrent: boolean
}

interface RecurrentTileProps {
  weekday: string
  hour: string
}

interface NonRecurrentTileProps {
  dateFormatted: string
  hour: string
}

interface ContainerProps {
  backgroundColor: string
  svgColor: string
}

const Container: React.FC<ContainerProps> = ({ children, backgroundColor, svgColor }) => {
  return (
    <S.Container backgroundColor={backgroundColor}>
      <Stripes color={svgColor}/>
      <S.Content>
        {children}
      </S.Content>
    </S.Container>
  )
}

const RecurrentTile: React.FC<RecurrentTileProps> = ({weekday, hour}) => {
  return (
    <Container backgroundColor='#354ABD' svgColor='#1F35B5'>
      <div>
        <Typography center customClassName='text' variant="content3">
          {weekday === 'dom' ? 'todo' : 'toda'} {weekday}
        </Typography>
        <Typography center customClassName='text' variant="header3" weight='bold'>
          {hour}h
        </Typography>
      </div>
      <div>
        <Typography center customClassName='text' variant="content3">
            reserva
        </Typography>
        <Typography center customClassName='text' variant="content3">
            recorrente
        </Typography>
      </div>
    </Container>
  )
}

const NonRecurrentTile: React.FC<NonRecurrentTileProps> = ({ dateFormatted, hour }) => {
  return (
    <Container backgroundColor='#788BF9' svgColor='#687EF8'>
      <div>
        <Typography center customClassName='text' variant="content3">{dateFormatted}</Typography>
        <Typography center customClassName='text' variant="header3" weight='bold'>{hour}h</Typography>
      </div>
      <div>
        <Typography center customClassName='text' variant="content3">reserva</Typography>
        <Typography center customClassName='text' variant="content3">avulsa</Typography>
      </div>
    </Container>
  )
}

export const Tile: React.FC<TileProps> = ({ date,isRecurrent }) => {
  const weekday = format(date, 'eeeeee', { locale: ptBR })
  const dateFormatted = format(date, 'dd/MM/yy')
  const hour = format(date, 'HH')

  return (
    <>
      {isRecurrent 
        ? <RecurrentTile weekday={weekday} hour={hour} /> 
        : <NonRecurrentTile dateFormatted={dateFormatted} hour={hour} />
      }
    </>
  )
}