import React from "react";

const Clinic = ({
  fill,
  height,
  viewBox = "0 0 50 50",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g transform="translate(-1414 3791)">
      <g fill="none" stroke={fill} strokeWidth="2" transform="translate(1414 -3791)">
        <circle cx="25" cy="25" r="24" />
      </g>
      <g fill={fill} transform="translate(1426.121 -3777.635)">
        <path d="M33.611 19.691h-5.692v-4.742a.949.949 0 0 0-.948-.949H15.588a.949.949 0 0 0-.949.949v4.743h-5.69A.949.949 0 0 0 8 20.64v15.177a.949.949 0 0 0 .949.949h24.662a.949.949 0 0 0 .949-.949V20.64a.949.949 0 0 0-.949-.949zM19.383 34.868v-5.691h3.794v5.691zm13.28 0h-7.589v-6.64a.949.949 0 0 0-.949-.949h-5.691a.949.949 0 0 0-.949.949v6.64H9.9v-13.28h5.691a.949.949 0 0 0 .949-.949V15.9h9.485v4.74a.949.949 0 0 0 .949.949h5.691z" transform="translate(-8 -14)" />
        <path d="M44.794 27.9h1.9v1.9h-1.9v1.9H42.9v-1.9H41v-1.9h1.9V26h1.9z" transform="translate(-30.566 -22.206)" />
        <path d="M20 44h2.846v1.9H20z" transform="translate(-16.206 -34.515)" />
        <path d="M20 56h2.846v1.9H20z" transform="translate(-16.206 -42.72)" />
        <path d="M73.846 45.9H71V44h2.846z" transform="translate(-51.081 -34.515)" />
        <path d="M73.846 57.9H71V56h2.846z" transform="translate(-51.081 -42.72)" />
        <path d="M73.846 69.9H71V68h2.846z" transform="translate(-51.081 -50.926)" />
        <path d="M20 68h2.846v1.9H20z" transform="translate(-16.206 -50.926)" />
      </g>
    </g>
  </svg>
);

export default Clinic;