import { Typography } from '@telavita-core/react-design-kit'
import styled from 'styled-components'

export const Container = styled.div`
  .AddPlanModal {
    overflow-y:inherit !important;
  }
`

export const TitleWrapper = styled.div`
  margin-bottom: 50px;
`

export const FormWrapper = styled.div``

export const FormTitleWrapper = styled.div``

export const FormContainer = styled.div`
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
  grid-template-columns: 50% 50%;
  margin-top: 20px;
  .TextInput {
    margin: 0 !important;
  }
`

export const PlansWrapper = styled.div`
  margin-top: 50px;
`

export const PlansTitleWrapper = styled.div`
  margin-bottom: 20px;
`

export const AddPlanWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  .AddPlanButton {
    width: 166px;
    height: 36px;
  }
`

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
`

export const EmptyStateContainer = styled.div`
  border: 2px dashed #C1C1C1;
  border-radius: 10px;
  height: 117px;

  display: flex;
  align-items: center;
  justify-content: center;
`

export const PlansListWrapper = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: column;
`

export const EmptyStateText = styled(Typography)`
  color: #898989;
`
