import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import { addMonths, endOfWeek, formatISO, getDay, isAfter, isSameDay, startOfWeek } from 'date-fns'
import {
  dateToIso,
  getFirstLastDay,
  getTime,
  getMonth,
  getYear,
  setMoment,
  toDayMonth,
  toDayMonthYear,
  toString,
  toDatetime
} from '../../utils/dateTimeHandlers'
import { history } from '../../routers/AppRouter'

// COMPONENTS
import { Column, CoreIcons, Spinner, Button } from 'components'
import { Typography } from '@telavita-core/react-design-kit'
import Dropdown from '../../components/Dropdown'
import Row from '../../components/Row'
import { getProfessionalCodeByProfileCode } from 'utils/profileCode'
import { getAvailability } from 'redux/actions'

import DayPicker from 'react-day-picker'
import { FORMAT, WEEKDAYS_SHORT } from '../../settings/dayPicker'
import MomentLocaleUtils from 'react-day-picker/moment'
import { TVTVPSIC } from 'settings/_profileSettings'
import { useGetPatientReservations } from './hooks/useGetPatientReservations'

const RescheduleContainer = ({
  clientUsername,
  professionalName,
  professionalSlug,
  professionalProfileCode,
  schedule,
  productCode,
  handleSubmit,
}) => {

  const dispatch = useDispatch()
  const availableHours = useSelector(state => state.agenda.availableHours)
  const daysUnavailable = useSelector(state => state.agenda.unavailableDates)
  const patientSchedules = useSelector(state => state.agenda.patientSchedules)
  const { quantityOfRecurrentReservations, isSuccess, isError } = useGetPatientReservations(clientUsername)

  const [disabledDays, setDisabledDays] = useState([
    {
      before: new Date()
    },
    new Date(),
  ])

  const weekDayName = {
    0: 'Domingo',
    1: 'Segunda-feira',
    2: 'Terça-feira',
    3: 'Quarta-feira',
    4: 'Quinta-feira',
    5: 'Sexta-feira',
    6: 'Sábado'
  }

  const [selectedDay, setSelectedDay] = useState(null)
  const [dropdownOptions, setDropdownOptions] = useState([])
  const [currentMonth, setCurrentMonth] = useState(0)
  const [currentYear, setCurrentYear] = useState(dateToIso(getYear(setMoment())))
  const [scheduleData, setScheduleData] = useState({
    startDate: '',
    endDate: '',
  })

  const [showDropdown, setShowDropdown] = useState(false)
  const [loading, setLoading] = useState(true)
  const [loadingSubmit, setLoadingSubmit] = useState(false)

  const patientHasRecurrentReservation = quantityOfRecurrentReservations > 0

  useEffect(() => {
    if (isSuccess || isError) {
      const getMonth = new Date()
      handleMonthChange(getMonth, professionalSlug, productCode)
    }

    return () => {
      onClearDate()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError])

  useEffect(() => {
    handleDisabledDays(daysUnavailable)
  }, [daysUnavailable])

  const handleMonthChange = (month, professionalSlug, productCode) => {
    /*
    * TO FORCE RENDERING IN THE DATEPICKER WITH THE UPDATED MONTH
    */
    const newDayObject = dateToIso(toDayMonthYear(setMoment(month)))
    const dateObject = setMoment(newDayObject)
    let monthNumber = dateToIso(getMonth(dateObject.subtract(1, 'months')))

    const incomingMonth = dateToIso(getMonth(month))
    let currentYear = dateToIso(getYear(month))

    /** This is a fix when navigating between years */
    if (incomingMonth === '01') currentYear = dateToIso(getYear(dateObject))

    /*
    * TO FORCE RENDERING IN THE DATEPICKER WITH THE UPDATED MONTH
    */

    setLoading(true)
    setCurrentMonth(monthNumber)
    setCurrentYear(currentYear)
    setShowDropdown(false)

    /** If past month, exit function */
    let passedMonths = moment(month).isBefore(moment(), 'month')
    let passedYears = moment(month).isBefore(moment(), 'year')
    if (passedMonths || passedYears) {
      setLoading(false)
      return
    }

    /** Check if current month to set firstDay as tomorrow */
    let currentMonth = moment().isSame(moment(month), 'month')

    const getDates = getFirstLastDay(month)
    let firstDay = toString(toDatetime(dateToIso(toDayMonthYear(currentMonth ?
      setMoment() : getDates.firstDay)), '00:00'))
    let lastDay = toString(toDatetime(dateToIso(toDayMonthYear(getDates.lastDay)), '23:59'))

    if (patientHasRecurrentReservation) {
      firstDay = formatISO(startOfWeek(new Date(schedule.isoDate)))
      lastDay = formatISO(endOfWeek(new Date(schedule.isoDate)))
    }

    const availabilityParams = {
      start_date: firstDay,
      end_date: lastDay,
      product_code: productCode,
      plan_code: schedule.plan_code,
      username: clientUsername,
      is_rescheduling: true,
      schedule_id: schedule.id,
      profile_role: getProfessionalCodeByProfileCode(professionalProfileCode)
    }

    dispatch(getAvailability(professionalSlug, availabilityParams))
      .then(() => {
        setLoading(false)
      })
  }

  const handleButtonSubmit = (scheduleData) => {
    setLoadingSubmit(true)
    handleSubmit(scheduleData)
  }

  const handleDisabledDays = async (unavailableDays) => {
    setDisabledDays([
      {
        before: new Date()
      },
      ...unavailableDays
    ])
  }

  const handleDayClick = async (day, { disabled }) => {
    if (disabled) return

    await (
      setShowDropdown(true),
      setSelectedDay(day)
    )

    await onGetHours(day)
  }

  const onClearDate = () => {
    setDropdownOptions([])
    setScheduleData({})
    setShowDropdown(false)
    setSelectedDay(null)
  }

  const removeHoursBeforeNow = (hours) => hours.filter(hour => isAfter(new Date(hour.start_date), new Date()))
  
  const onGetHours = async (day) => {
    const getDate = dateToIso(toDayMonthYear(setMoment(day)))

    const findDay = availableHours
      .find(item => Object.keys(item).includes(getDate))

    if (findDay === undefined || findDay === null) return

    const getHours = Object.values(findDay)[0]
    const filteredHours = removeHoursBeforeNow(getHours)
    let dayHours = []

    filteredHours.map(item => {
      const hour = getTime(item.start_date)
      const newItem = {
        id: item.start_date,
        name: hour,
        start_date: item.start_date,
        end_date: item.end_date
      }
      
      dayHours.push(newItem)
      return dayHours
    })

    await (
      setDropdownOptions(dayHours),
      setShowDropdown(true)
    )
  }

  const handleSaveSchedule = (itemId) => {
    const item = dropdownOptions.find(opt => opt.id === itemId)

    let newItem = {
      start_date: item.start_date,
      end_date: item.end_date
    }

    setScheduleData(newItem)

    return newItem
  }

  const dayPickerStartMonth = () => {
    if (patientHasRecurrentReservation) {
      return new Date(schedule.isoDate)
    }

    return currentMonth && currentYear ? new Date(currentYear, currentMonth) : null
  }

  const dayPickerDisabledDays = () => {
    if (patientHasRecurrentReservation) {
      const minDate = startOfWeek(new Date(schedule.isoDate), { weekStartsOn: 0 })
      const maxDate = endOfWeek(new Date(schedule.isoDate), { weekStartsOn: 0 })
      return [
        { before: minDate },
        { after: maxDate },
        ...daysUnavailable,
        ...disabledDays
      ]
    }

    return disabledDays
  }

  const filterNonRelevantSchedules = () => {
    if (!patientSchedules) return

    // Filtra as consultas que não são do mesmo dia da consulta atual que o paciente quer reagendar
    return patientSchedules?.filter(item => {
      const currentScheduleDate = new Date(schedule.isoDate)
      const dayToCompare = new Date(item)
      const isSameDate = isSameDay(currentScheduleDate, dayToCompare)
      
      return !isSameDate
    })
  }

  return (
    <div className="RescheduleContainer">
      <Row margin='1rem 0 0 0'>
        <Column>
          <Typography variant='header4' center weight='bold'>
            Reagendar consulta do dia
          </Typography>
          <Typography variant='header4' center color='danger' weight='bold'>{schedule.date}</Typography>
        </Column>
      </Row>

      <div className="RescheduleContainer__header">
        <Row margin='1rem 0 0 0'>
          <Column>
            <Typography
              variant='content2'
              center
              inlineStyles={{ color: '#898989' }}
            >
              <span>{weekDayName[getDay(new Date(schedule.isoDate))]}, às {schedule.time}</span>
            </Typography>
            <Typography
              variant='content2'
              center
              inlineStyles={{ color: '#898989' }}
            >
              com {professionalProfileCode === TVTVPSIC ? 'Psicólogo' : 'Psiquiatra'} <span>{professionalName}</span>
            </Typography>
          </Column>
        </Row>
      </div>

      {patientHasRecurrentReservation && (
        <div className="RescheduleContainer__reservation_text">
          <Typography center color='danger' variant='content2'>
            <b>Atenção:</b> só é possível remarcar para um dia da mesma semana. Caso não encontre nenhum horário, fale com o psicólogo.
          </Typography>
        </div>
      )}

      <div className="RescheduleContainer__calendar">
        {loading && <Spinner height="360px" />}
        <DayPicker
          disabledDays={dayPickerDisabledDays()}
          fromMonth={new Date()}
          onDayClick={handleDayClick}
          onMonthChange={month =>
            handleMonthChange(month, professionalSlug, productCode)
          }
          selectedDays={[selectedDay]}
          month={dayPickerStartMonth()}
          toMonth={addMonths(new Date(), 3)}
          canChangeMonth={!loading}
          locale={'pt-BR'}
          format={FORMAT}
          localeUtils={MomentLocaleUtils}
          weekdaysShort={WEEKDAYS_SHORT}
          modifiers={{schedule: filterNonRelevantSchedules()}}
        />
      </div>


      {
        showDropdown ? (
          <div className="RescheduleContainer__hours">
            <div className="RescheduleContainer__hours__selected">
              <p>{toDayMonth(selectedDay)} às </p>

              <Dropdown
                onSelect={(item) => handleSaveSchedule(item)}
                options={dropdownOptions}
                placeholder={'Horários'}
                dropDownOverflow={true}
              />

              <div
                className="RescheduleContainer__hours__selected__clear"
                onClick={() => onClearDate()}>
                <CoreIcons name="Close" fill="#acacac" />
              </div>
            </div>
          </div>
        ) : undefined
      }
      <Row margin='2.5rem 0 0 0'>
        <Column>
          <Button
            id="btn-reschedule"
            disabled={selectedDay === null || scheduleData.startDate === ''}
            onButtonClick={() => handleButtonSubmit(scheduleData)}
            text='CONFIRMAR REMARCAÇÃO'
            loading={loadingSubmit}
          />
        </Column>
      </Row>
    </div>
  )
}

export default RescheduleContainer