import { Typography } from "components";
import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../../redux/actions";
import ModalGeneric from "../ModalGeneric";
import { Content } from "./Content";

class ModalPrivacyPolicy extends Component {

  onDownloadFile = () => {
    const currentEnv = process.env.REACT_APP_SENTRY_ENVIRONMENT;
    const pdfUrl = `https://telavita-${currentEnv}-assets.s3-sa-east-1.amazonaws.com/docs/Pol%C3%ADtica+de+Privacidade+Telavita+-+15_12_2020.pdf`;
    window.open(pdfUrl, '_blank');
  }

  render() {
    return (
        <ModalGeneric
            hasButton={true}
            btnText="Fechar"
            modalTitle={`Política de Privacidade Telavita`}
            btnOnClick={this.props.onCloseModal}
        >
            <ModalGeneric.Group>
              <div dangerouslySetInnerHTML={Content()} />
            </ModalGeneric.Group>
            <ModalGeneric.Group>
              <Typography 
                bold
                color="primary"
                onTextClick={this.onDownloadFile}
              >
                Baixar a política de privacidade em PDF
              </Typography>
            </ModalGeneric.Group>
        </ModalGeneric>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(hideModal()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ModalPrivacyPolicy);
