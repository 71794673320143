import React from 'react';
import PropTypes from 'prop-types';

const HeaderButton = ({
  btnId,
  classModifier,
  disabled,
  onButtonClick,
  text,
  type,
}) => {
  return (
    <button
      className={classModifier ? `HeaderButton--${classModifier}` : 'HeaderButton'}
      disabled={disabled}
      id={btnId}
      onClick={onButtonClick}
      type={type}>
      {text}
    </button>
  );
};

HeaderButton.propTypes = {
  btnId: PropTypes.string,
  classModifier: PropTypes.oneOf([
    'default',
    'mobile',
    'secondary',
  ]),
  disabled: PropTypes.bool,
  onButtonClick: PropTypes.func,
  text: PropTypes.node,
  type: PropTypes.string,
};

HeaderButton.defaultProps = {
  btnId: "btn-header",
  classModifier: "default",
  disabled: false,
  text: "Text",
  type: "button",
};

export default HeaderButton;