import React from 'react'
import { Button, Typography, useDimensions } from '@telavita-core/react-design-kit'

import { redirectEndpoints } from 'settings/_apiSettings'
import { TVTVPACI, PATIENT } from 'settings/_profileSettings'

import TimerContainer from '../TimerContainer'

import { Schedule, IScheduleNotification } from './types'
import * as S from './styles'

const getPatientName = (data: Schedule) => {
  const patientData = data.guests?.find(guest =>
    guest?.profileCode === TVTVPACI || guest?.profileCode === PATIENT
  )

  return patientData?.fullName
}

export const ScheduleNotification: React.FC<IScheduleNotification> = ({
  scheduleToStart,
  showNotification,
  timeUp,
  setTimeUp
}) => {
  const { isMobile } = useDimensions()

  const handleRedirectToMeetAwaitingRoom = () => 
    window.location.assign(`${redirectEndpoints}/${scheduleToStart.virtualRoomHash}`)
  
  const onTimeUp = () => setTimeUp(true)

  function renderNotification() {
    return (
      <S.Container isMobile={isMobile}>
        <div>
          {timeUp 
            ? (
              <Typography variant='content2'>
                  Consulta já começou!
              </Typography>
            )
              
            : (
              <S.StartInfoWrapper>
                <Typography variant='content2'>
                    Próxima consulta vai começar em
                  <TimerContainer
                    initialTime={new Date(scheduleToStart.startDate).getTime() - Date.now()}
                    onTimerStop={onTimeUp}
                  />
                </Typography>
              </S.StartInfoWrapper>
            )
          }
          
          <S.PatientName variant='content3'>
            com {getPatientName(scheduleToStart)}
          </S.PatientName>
            
        </div>
  
        <S.EnterButtonWrapper>
          <Button
            onClick={handleRedirectToMeetAwaitingRoom}
            variant="contained"
            uppercase
          >
            Entrar
          </Button>
        </S.EnterButtonWrapper>
      </S.Container>
    )
  }

  return (
    <>
      {showNotification ? renderNotification() : null}
    </>
  )
}