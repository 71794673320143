import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { format, isAfter } from 'date-fns'

import { creditsPanelRequest, creditsPanelCreateMedicalRequest } from 'redux/modules/creditsPanel/requests'

import { getCorporateEligibility } from 'services/corporateEligibilityService'
import { PlanGroupCategoryEnum } from 'settings/_planGroupSettings'

const { CARE_PLUS, ENTERPRISE, CORPORATE_UNLIMITED, CORPORATE_LIMITED } = PlanGroupCategoryEnum

export const usePatientEligibility = () => {
  const [corporateEligibility, setCorporateEligibility] = useState({})
  const [forceUpdate, setForceUpdate] = useState(0)

  const dispatch = useDispatch()
  const username = useSelector((state) => state?.auth?.userData?.username)
  const planGroupName = useSelector(state => state.auth.planSelected?.plan_group.name)
  const planGroupCategory = useSelector(state => state.auth.planSelected?.plan_group.category)
  const isCorporate = ([CORPORATE_LIMITED, CORPORATE_UNLIMITED]).includes(planGroupCategory)
  const isEnterprise = ([ENTERPRISE, CARE_PLUS]).includes(planGroupCategory)

  const creditsPanel = useSelector((state) => state.creditsPanel)
  const creditsInfo = creditsPanel.data
  const creditsStatus = creditsInfo.status
  const availableCredits = creditsInfo.available_credits
  const maxCredits = creditsInfo.max_appointment
  const enableDetails = creditsInfo.enable_details
  const validDate = isCorporate ? corporateEligibility.expirationDate : new Date(creditsInfo.end_date)
  const expired = isAfter(new Date(), validDate)
  const errorButton = creditsPanel.errorButton
  const loadingButton = creditsPanel.loadingButton
  const solicitationDate = creditsInfo.date_created !== undefined 
    ? format(new Date(creditsInfo.date_created), 'dd/MM/yyyy') 
    : ''
 
  const startDate = creditsInfo.start_date

  const isUnlimited = planGroupCategory === CORPORATE_UNLIMITED

  const [hasButton, setHasButton] = useState(false)
  const [loading, setLoading] = useState(true)
  
  useEffect(() => {
    setForceUpdate(prevState => prevState + 1)
  }, [creditsInfo])

  useEffect(() => {
    if(creditsPanel.enableSolicitation) {
      setHasButton(creditsPanel.enableSolicitation)
    } else {
      setHasButton(false)
    }

  }, [creditsPanel])

  useEffect(() => {
    setLoading(true)
    if (isCorporate) {
      getCorporateEligibility(username)
        .then(eligibility => {
          setCorporateEligibility(eligibility)
          setLoading(false)
        })
    } else if (isEnterprise) {
        dispatch(creditsPanelRequest())
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planGroupCategory, hasButton])

  useEffect(() => {
    if (Object.keys(creditsInfo).length) setLoading(false)
  }, [ creditsInfo ])

  const onClickRequestCreditsButton = () => {
    dispatch(creditsPanelCreateMedicalRequest())
    setHasButton(false)
  }

  return {
    isEnterprise,
    isCorporate,
    hasButton,
    planGroupName,
    creditsStatus,
    availableCredits,
    maxCredits,
    enableDetails,
    validDate,
    expired,
    errorButton,
    loadingButton,
    solicitationDate,
    isUnlimited,
    onClickRequestCreditsButton,
    startDate,
    corporateEligibility,
    loading,
    forceUpdate
  }
}