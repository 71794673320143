import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;

  .title {
    margin: 15px 0 5px;
  }

  & abbr {
    border: none;
  }
`

export const Footer = styled.footer`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin: 15px 6px 25px 0;
  gap: 20px;

  .action-btn {
    height: 36px;
    width: 80px;
    font-weight: bold;
  }
`
