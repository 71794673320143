import React from 'react'
import { Tooltip, Typography } from '@telavita-core/react-design-kit'
import { format, isPast } from 'date-fns'
import { ptBR } from 'date-fns/locale'

import { ReactComponent as InfoIcon } from 'static/svg/info-icon.svg'

import * as S from './styles'

interface ReservationExceptionsProps {
  textVariant?: 'header1' | 'header2' | 'header3' | 'header4' | 'content1' | 'content2' | 'content3',
  datesExceptions: Array<{
    exceptionDate: string
    exceptionType: 'cancelled_by_professional' | 'cancelled_by_patient' | 'professional_unavailability',
    nextAvailableDate?: string
  }>
  customClassName?: string;
  tooltipContent: {
    [index: string]: {
      tooltipContent: JSX.Element
    }
  }
}

function handleFormatNextAvailableDate(date: string): string {
  const weekdayName = format(new Date(date), 'EEEE', { locale: ptBR })
  const time = format(new Date(date), 'HH:mm')

  return `${weekdayName}, às ${time}.`
}

const ReservationExceptions = ({ 
  textVariant = 'content3', 
  datesExceptions = [], 
  customClassName = '',
  tooltipContent
}: ReservationExceptionsProps): JSX.Element => {
  const filteredDatesOnFuture = datesExceptions.filter(date => !isPast(new Date(date.exceptionDate)))

  if (filteredDatesOnFuture.length === 0) return null

  const orderedDates = filteredDatesOnFuture.sort((a, b) => new Date(a.exceptionDate).getTime() - new Date(b.exceptionDate).getTime())

  return (
    <S.Container className={customClassName}>
      {orderedDates.map((date, index) => (
        <S.DateContainer key={index}>
          <Typography variant={textVariant} color='danger' weight='bolder' center>
            {index === 0 && 'Exceto em '}
            {format(new Date(date.exceptionDate), 'dd/MM/yyyy')}
          </Typography>
          <Tooltip 
            variant='error' 
            placement='bottom'
            content={
              <Typography variant='content3' color='danger'>
                {tooltipContent[date.exceptionType].tooltipContent}
                {date?.nextAvailableDate && (
                  <strong>{handleFormatNextAvailableDate(date.nextAvailableDate)}</strong>
                )}
              </Typography>
            } 
          >
            <InfoIcon />
          </Tooltip>
        </S.DateContainer>
      ))}
    </S.Container>
  )
}

export {
  ReservationExceptions
}