import React from 'react'
import clsx from 'clsx'

const Skeleton = ({ type = 'text', height = 10, width = '100%', minWidth, borderRadius = 10, customClassName = '' }) => {
  return (
    <div 
      className={clsx({
        'Skeleton': true,
        [`Skeleton--${type}`]: true,
        [customClassName]: Boolean(customClassName)
      })} 
      style={{ height, width: width, borderRadius, minWidth }}
    />
  )
}

export default Skeleton