import styled from 'styled-components'

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;

  > button {
    width: 100%;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 10px;
  margin-bottom: 3rem;
`