import { useState } from 'react'
import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { CANCELED } from 'settings/_scheduleStatusSettings'
import { ADMIN_SCHEDULES_QUERY_KEY } from 'settings/_queryKeySettings'

import { CancelationScheduleRequest, OptionData, ScheduleData, UseCancelationSchedule } from '../types'
import { GetSchedulesResponse } from 'services/schedules/getSchedules'
import { updateSchedule } from 'views/Dashboard/Sections/AdminScheduleList/helpers'

interface CancelationScheduleResponse {
  data: {
    id: number
  }
}

async function cancelScheduleService({
  scheduleId,
  requestedBy,
  reasonCode,
  details
}: CancelationScheduleRequest): Promise<CancelationScheduleResponse> {

  const requestBody = {
    status_code: CANCELED,
    requested_by: requestedBy,
    reason_code: reasonCode,
    details
  }

  const response = await axios.put<CancelationScheduleResponse>(apiEndpoints.SCHEDULE_DETAIL(scheduleId), requestBody)
  
  return {
    ...response,
    data: {
      id: scheduleId
    } 
  }
}

export const useCancelationSchedule = (scheduleData: ScheduleData): UseCancelationSchedule => {
  const queryClient = useQueryClient()
  const [cancelationFields, setCancelationFields] = useState({
    cancelationRequester: {
      code: '',
      id: null
    },
    cancelationReason: '',
    cancelationDetails: '',
  })

  const { mutate: handleCancelSchedule, isSuccess, isLoading, isError } = useMutation({
    mutationFn: () => cancelScheduleService({
      scheduleId: scheduleData?.id,
      requestedBy: cancelationFields.cancelationRequester.code,
      reasonCode: cancelationFields.cancelationReason,
      details: cancelationFields.cancelationDetails
    }),
    onSuccess: (data) => {
      return queryClient.setQueryData<InfiniteData<GetSchedulesResponse>>([ADMIN_SCHEDULES_QUERY_KEY], oldData => {
        const newPages = updateSchedule({
          data: oldData?.pages,
          scheduleId: data.data.id,
          newValue: {
            status_code: CANCELED
          }
        })

        return {
          ...oldData,
          pages: newPages
        }
      })
    }
  })

  function handleChangeRequester(data: OptionData) {
    setCancelationFields({
      ...cancelationFields,
      cancelationRequester: {
        code: data.code,
        id: data.id
      },
    })
  }

  function handleChangeReason(data: OptionData) {
    setCancelationFields({
      ...cancelationFields,
      cancelationReason: data.code,
    })
  }

  function handleChangeDetails(value: string) {
    setCancelationFields({
      ...cancelationFields,
      cancelationDetails: value,
    })
  }

  function validateRequiredFields() {
    const { cancelationRequester, cancelationReason } = cancelationFields

    if (!cancelationRequester || !cancelationReason) {
      return false
    }

    return true
  }

  return {
    cancelationFields,
    validateRequiredFields,
    handleChangeRequester,
    handleChangeReason,
    handleChangeDetails,
    handleCancelSchedule,
    isCancelationSuccess: isSuccess,
    isCancelationLoading: isLoading,
    isCancelationError: isError,
  }
}