/*
 * Common utility functions for Redux
*/

/* Update the state from reducer */
export const updateState = (oldState, updatedState) => {
  return {
    ...oldState,
    ...updatedState,
  };
};