import React from "react";

const FilterName = ({
  fill,
  height,
  viewBox = "0 0 42 36",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} transform="translate(-48 -178.489)">
      <path d="M1420.218 1478.456h-13.133l-2.953 7.991h-2.442l10.877-28.657h2.224l10.805 28.657h-2.428zm-12.34-2.106h11.571l-5.707-15.505h-.118z" transform="translate(-1353.69 -1271.576)"/>
      <path d="M1513.151 1508.879h13.837v2.006h-16.748v-1.75l12.833-17.54h-12.439v-2.007h15.411v1.736z" transform="translate(-1436.119 -1296.016)"/>
      <path d="M1479.993 1437.208l-.231-1.254h-.086a4.511 4.511 0 0 1-3.582 1.534 3.841 3.841 0 0 1-4.043-3.837c0-3.24 2.916-4.809 7.343-4.783v-.178c0-.664-.359-1.62-2.277-1.62a7.037 7.037 0 0 0-3.453.947l-.718-2.506a10.334 10.334 0 0 1 4.86-1.1c4.166 0 5.5 2.455 5.5 5.4v4.349a19.4 19.4 0 0 0 .178 3.046zm-.463-5.91c-2.046-.026-3.633.463-3.633 1.969a1.4 1.4 0 0 0 1.534 1.483 2.124 2.124 0 0 0 2.02-1.432 2.791 2.791 0 0 0 .076-.664z" transform="translate(-1406.767 -1245.921)" />
    </g>
  </svg>
);

export default FilterName;