import React from 'react'
import PropTypes from 'prop-types'

// COMPONENTS
import { Typography, BodyContent, CoreIcons } from 'components'
import Logo from '../Logo'

import * as routes from '../../settings/_routesSettings'

function Footer({ isMobile, pageType, extraClass = "" }) {
  return (
    <div className={`Footer ${extraClass}`}>
      <div className="Footer__Layer">
        <BodyContent>
          <div className="Footer__Upper">
            <div className="Footer__Upper__Left">
              {/** TOPIC */}
              {isMobile ? null : (
                <div className="Footer__Topic">
                  <div className="Footer__Topic__Title">
                    <Typography bold color="white">
                      Especialidades
                    </Typography>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a href={routes.PSICOLOGIA}>
                      <Typography color="white">Psicologia</Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a href={routes.PSIQUIATRIA}>
                      <Typography color="white">Psiquiatria</Typography>
                    </a>
                  </div>
                </div>
              )}

              {/** TOPIC */}
              {isMobile ? null : (
                <div className="Footer__Topic">
                  <div className="Footer__Topic__Title">
                    <Typography bold color="white">
                      Profissionais e empresas
                    </Typography>
                  </div>
                  <div className="Footer__Topic__Item">
                    <a href={routes.FACA_PARTE_PSICOLOGOS}>
                      <Typography color="white">
                        Telavita para psicólogos
                      </Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a href={routes.FACA_PARTE_MEDICOS}>
                      <Typography color="white">
                        Telavita para médicos
                      </Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a href={routes.FACA_PARTE_CONVENIOS}>
                      <Typography color="white">
                        Telavita para clínicas, convênios e seguradoras
                      </Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a href={routes.FACA_PARTE_EMPRESAS}>
                      <Typography color="white">
                        Telavita para empresas
                      </Typography>
                    </a>
                  </div>
                </div>
              )}

              {/** TOPIC */}
              {isMobile ? null : (
                <div className="Footer__Topic">
                  <div className="Footer__Topic__Title">
                    <Typography bold color="white">
                      Temas em destaque
                    </Typography>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a
                      href="https://www.telavita.com.br/blog/o-que-e-ansiedade/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="white">Ansiedade</Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a
                      href="https://www.telavita.com.br/blog/burnout/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="white">Burnout</Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a
                      href="https://www.telavita.com.br/blog/depressao/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="white">Depressão</Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a
                      href="https://www.telavita.com.br/blog/estresse-o-que-e/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="white">Estresse</Typography>
                    </a>
                  </div>

                  <div className="Footer__Topic__Item">
                    <a
                      href="https://www.telavita.com.br/blog/transtorno-bipolar/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Typography color="white">Transtorno bipolar</Typography>
                    </a>
                  </div>
                </div>
              )}

              {/** TOPIC */}
              <div className="Footer__Topic">
                <div className="Footer__Topic__Title">
                  <Typography bold color="white">
                    Saiba mais
                  </Typography>
                </div>

                <div className="Footer__Topic__Item">
                  <a
                    href="https://intercom.help/telavita/pt/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Typography color="white">Perguntas frequentes</Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href={routes.BLOG}>
                    <Typography color="white">Blog</Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href={routes.QUEM_SOMOS}>
                    <Typography color="white">Quem somos</Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href={routes.TERMOS_USO}>
                    <Typography color="white">Termos de uso</Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href={routes.POLITICAS_PRIVACIDADE}>
                    <Typography color="white">
                      Política de privacidade
                    </Typography>
                  </a>
                </div>
              </div>
            </div>

            {/** TOPIC */}
            {pageType === "PSIQ" ? null : isMobile ? null : (
              <div className="Footer__Topic">
                <div className="Footer__Topic__Title">
                  <Typography bold color="white">
                    Dúvidas Frequentes
                  </Typography>
                </div>

                <div className="Footer__Topic__Item">
                  <a href="https://www.telavita.com.br/blog/quem-pode-fazer-terapia/">
                    <Typography color="white">
                      Quem pode fazer terapia?
                    </Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href="https://www.telavita.com.br/blog/melhor-dia-terapia/">
                    <Typography color="white">
                      Qual o melhor dia para fazer terapia?
                    </Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href="https://www.telavita.com.br/blog/sessao-de-terapia/">
                    <Typography color="white">
                      Como é uma sessão de terapia?
                    </Typography>
                  </a>
                </div>

                <div className="Footer__Topic__Item">
                  <a href="https://www.telavita.com.br/blog/abordagens-da-psicologia/">
                    <Typography color="white">
                      Abordagens da psicologia
                    </Typography>
                  </a>
                </div>
              </div>
            )}
          </div>

          <div className="Footer__Under">
            <div className="Footer__Copyright">
              <div className="Footer__Copyright__Logo">
                <Logo width="105px" viewBox="38 0 32 23" />
              </div>
              <div className="Footer__Copyright__Text">
                <Typography color="white" type="subheading2">
                  © {new Date().getFullYear()} Telavita. Todos os direitos reservados.
                </Typography>
              </div>
            </div>
            <div className="Footer__Contact">
              <div className="Footer__Contact__Label">
                <Typography color="white" type="subheading2">
                  Fale conosco:
                </Typography>
              </div>
              <div className="Footer__Contact__Email">
                <a href="mailto:contato@telavita.com.br">
                  <Typography bold color="white" type="subheading2">
                    contato@telavita.com.br
                  </Typography>
                </a>
              </div>
            </div>

            <div className="Footer__Upper__Right">
              <div className="Footer__Upper__Right__Label">
                <Typography color="white">Siga a gente:</Typography>
              </div>
              <div className="Footer__Upper__Right__Social">
                <div className="Footer__Upper__Right__Social__Item">
                  <a
                    href="https://www.facebook.com/Telavita"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CoreIcons name="Facebook" height="35px" width="35px" />
                  </a>
                </div>
                <div className="Footer__Upper__Right__Social__Item">
                  <a
                    href="https://www.instagram.com/telavita"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CoreIcons name="Instagram" height="35px" width="35px" />
                  </a>
                </div>
                <div className="Footer__Upper__Right__Social__Item">
                  <a
                    href="https://www.linkedin.com/company/telavita"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <CoreIcons name="LinkedIn" height="35px" width="35px" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </BodyContent>
      </div>
    </div>
  );
}

Footer.propTypes = {
  isMobile: PropTypes.bool,
  extraClass: PropTypes.string,
};

export default Footer;
