import React from 'react'

const OfflinePatient = ({ fill, height = 35, viewBox = '0 0 42 42', width = 35 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g transform="translate(-145.171 -33.322)">
      <g>
        <path d="M148.712,63.263v2.885a3.608,3.608,0,0,0,3.606,3.606h15.526A10.09,10.09,0,1,0,179,54.314V37.3a3.608,3.608,0,0,0-3.606-3.606H152.318a3.608,3.608,0,0,0-3.606,3.606v25.96Zm1.442-23.075V37.3a2.17,2.17,0,0,1,2.164-2.163h23.076a2.169,2.169,0,0,1,2.163,2.163V54a9.409,9.409,0,0,0-1.442-.115,10.107,10.107,0,0,0-10.1,10.1,10,10,0,0,0,.988,4.326H152.318a2.17,2.17,0,0,1-2.164-2.163V40.188Zm25.961,32.45a8.653,8.653,0,1,1,8.654-8.653h0A8.66,8.66,0,0,1,176.115,72.638Z" fill={fill}/>
        <path d="M176.137,74.446a10.459,10.459,0,0,1-8.486-4.317H152.318a3.988,3.988,0,0,1-3.981-3.981V37.3a3.988,3.988,0,0,1,3.981-3.981h23.076a3.987,3.987,0,0,1,3.981,3.981V54.038A10.5,10.5,0,0,1,184.707,58a10.459,10.459,0,0,1-8.57,16.444ZM152.318,34.072a3.237,3.237,0,0,0-3.231,3.231V66.148a3.237,3.237,0,0,0,3.231,3.231H168.04l.112.16a9.715,9.715,0,1,0,10.741-14.865l-.268-.08V37.3a3.237,3.237,0,0,0-3.231-3.231Zm23.8,38.941h0a9.028,9.028,0,1,1,9.029-9.028A9.039,9.039,0,0,1,176.115,73.013Zm0-17.307a8.278,8.278,0,1,0,8.279,8.278A8.288,8.288,0,0,0,176.115,55.706ZM167.6,68.686H152.318a2.552,2.552,0,0,1-2.539-2.537V37.3a2.553,2.553,0,0,1,2.537-2.539h23.078a2.552,2.552,0,0,1,2.538,2.538V54.443l-.433-.068a9.163,9.163,0,0,0-1.385-.112,9.7,9.7,0,0,0-8.769,13.886Zm7.788-33.172H152.318a1.8,1.8,0,0,0-1.789,1.791V66.148a1.8,1.8,0,0,0,1.79,1.788H166.43a10.447,10.447,0,0,1,9.685-14.423,9.983,9.983,0,0,1,1.067.062V37.3A1.8,1.8,0,0,0,175.392,35.514Z" fill={fill}/>
        <path d="M395.634,64.693h0l2.791,2.79,1.4-1.4L397.03,63.3l2.792-2.792-1.4-1.4L395.634,61.9l-2.791-2.791-1.4,1.4,2.792,2.792-2.792,2.791,1.4,1.4,2.79-2.79Z" transform="translate(-219.276 0.78)" fill={fill}/>
      </g>
      <g>
        <rect width="2.159" height="8.562" rx="1.08" transform="translate(145.171 43.078) rotate(-90)" fill={fill}/>
        <rect width="2.159" height="8.562" rx="1.08" transform="translate(145.171 52.813) rotate(-90)" fill={fill}/>
        <rect width="2.159" height="8.562" rx="1.08" transform="translate(145.171 62.549) rotate(-90)" fill={fill}/>
      </g>
    </g>
  </svg>


)

export default OfflinePatient
