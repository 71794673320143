import React, { Component } from 'react'
import { Trans } from '@lingui/macro'
import { connect } from 'react-redux'
import moment from 'moment'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import flags from 'react-phone-number-input/flags'
import { Typography } from '@telavita-core/react-design-kit'

import viewportHoc from '../../../../hoc/viewportHoc'
import Dropdown from '../../../../components/Dropdown/index'
import Row from '../../../../components/Row'
import { Column, TextInput } from 'components'

import { GENDER } from '../../../../settings/_personSettings'
import InputValidation from '../../../../utils/InputValidation'
import { addGeneralData, getPerson } from '../../../../redux/actions'
import { history } from '../../../../routers/AppRouter'
import * as routes from '../../../../settings/_routesSettings'
import * as actions from '../../../../redux/actions'
import { PSYCHOLOGIST } from 'settings/_profileSettings'
import { modalTypes } from '../../../../containers/ModalManager/ModalManager'

class GeneralData extends Component {
  state = {
    userRole: this.props.role,
    isMenuOpen: undefined,
    isNewUser: true,
    hasPartnerProfile: false,

    canSave: false,
    isBlockChecked: false,

    full_name: {
      value: this.props.isEdit ? this.props.personGeneralData.full_name : '',
      isValid: true,
      message: '',
    },
    email: {
      value: this.props.isEdit ? this.props.personGeneralData.email : '',
      isValid: true,
      message: '',
    },
    gender: {
      value: this.props.isEdit ? this.props.personGeneralData.gender : '',
      isValid: true,
      message: '',
    },
    cpf: {
      value: this.props.isEdit ? this.props.personGeneralData.cpf : '',
      isValid: true,
      message: '',
    },
    date_birth: {
      value: this.props.isEdit ? this.props.personGeneralData.date_birth : '',
      isValid: true,
      message: '',
    },
    cellphone_number: {
      value: this.props.isEdit ? `+${this.props.personGeneralData.cellphone_number}` : '',
      isValid: true,
      message: '',
    },
    phone_number: {
      value: this.props.isEdit && this.props.personGeneralData.phone_number
        ? `+${this.props.personGeneralData.phone_number}` : '',
      isValid: true,
      message: '',
    },
    friendly_url: {
      value: this.props.isEdit ? this.props.personGeneralData.friendly_url : '',
      isValid: true,
      message: '',
    },
  }

  componentDidMount() {
    this.checkIfCanSave()
    this.checkRequiredFields()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.checkIfCanSave()
      this.checkRequiredFields()
    }
  }

  handleSelectGender = (code) => {
    this.setState({
      'gender': {
        ...this.state.gender,
        value: code,
      },
    })
    this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { gender: code })

  }

  checkIfCanSave = () => {
    let canSave = true

    const requiredStates = [
      'full_name',
      'email',
      'gender',
      'cpf',
      'date_birth',
      'cellphone_number',
    ]

    requiredStates.map(rq => {
      if (this.state[rq].isValid === false ||
          this.state[rq].value === '') {
        canSave = false
      }
      return canSave
    })

    if (canSave !== this.state.canSave) {
      this.setState({ canSave })
    }
    
    this.props.onAddGeneralData(this.state.isBlockChecked, canSave, {})

    return canSave

  }

  checkRequiredFields = () => {
    let isBlockChecked = true
    const requiredStates = [
      'full_name',
      'email',
      'gender',
      'cpf',
      'date_birth',
      'cellphone_number',
    ]

    requiredStates.map(rq => {
      if (this.state[rq].value === '' ||
        this.state[rq].value === undefined ||
        this.state[rq].value === null ||
        this.state[rq].isValid === false) {
        isBlockChecked = false
      }
      return isBlockChecked
    })

    if (isBlockChecked !== this.state.isBlockChecked) {
      this.setState({ isBlockChecked })
    }

    this.props.onAddGeneralData(isBlockChecked, this.state.canSave, {})

    return isBlockChecked
  }

  /**
   * INPUT HANDLERS
   */

  handleNameInput = (e) => {
    e.preventDefault()
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'name').validate()
    this.setState({
      full_name: {
        ...this.state.full_name,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })

    if (validation.success) {
      const nameSpread = inputValue.split(' ')
      const firstName = nameSpread.splice(0, 1).join('')
      const lastName = nameSpread.join(' ')
      const friendlyUrl = inputValue.split(' ')
        .filter((word) => word !== '')
        .join('-')
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        
      this.props.onAddGeneralData(
        this.state.isBlockChecked,
        this.state.canSave,
        {
          first_name: firstName,
          last_name: lastName,
          friendly_url: friendlyUrl,
        })
    }
  }

  handleEmailInput = (e) => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'email').validate()
    this.setState({
      email: {
        ...this.state.email,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })
    if (validation.success) {
      this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { email: inputValue })
    }
  }

  handleCpfInput = (e) => {
    const inputValue = e.target.value
    let validation = new InputValidation(inputValue, 'cpf').validate()
    this.setState({
      cpf: {
        ...this.state.cpf,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })
    if (validation.success) {
      this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { cpf: inputValue })
    }
  }

  handleBirthDateInput = (e) => {
    const inputValue = e.target.value
    const minDate = '01/01/1900'
    const validateOptions = {
      minDate: minDate,
      maxDate: moment(),
    }
    let validation = new InputValidation(inputValue, 'date', validateOptions).validate()

    this.setState({
      date_birth: {
        ...this.state.date_birth,
        value: inputValue,
        isValid: validation.success,
        message: validation.message,
      },
    })

    if (validation.success) {
      this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { date_birth: inputValue })
    }
  }

  handleCheckEmail = async() => {
    if (this.state.email.isValid) {
      const username = this.state.email.value
      const role = this.state.userRole

      if (this.state.email.isValid &&
        username &&
        username !== '') {
        try {
          await this.props.onGetPersons(username, role)

          const personData = {
            person: this.props.personGeneral,
            personProfile: this.props.personProfile,
          }

          await this.props.onEditBlocks(personData)
          history.push(routes.DASHBOARD_EDIT_PROFESSIONAL(username, role))
        } catch (err) {
          console.log(err)
        }
      }
    }
  }

  handleCellphoneInput = (value) => {
    const inputValue = value
    if (inputValue) {
      if (isValidPhoneNumber(inputValue)) {
        this.setState({
          cellphone_number: {
            ...this.state.cellphone_number,
            value: inputValue,
            isValid: true,
            message: undefined,
          },
        })
        this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { cellphone_number: inputValue })
      } else {
        this.setState({
          cellphone_number: {
            ...this.state.cellphone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido',
          },
        })
      }
    } else {
      this.setState({
        cellphone_number: {
          ...this.state.cellphone_number,
          value: inputValue,
          isValid: true,
          message: '',
        },
      })
    }
  }

  handlePhoneInput = (value) => {
    const inputValue = value
    if (inputValue) {
      if (isValidPhoneNumber(inputValue)) {
        this.setState({
          phone_number: {
            ...this.state.phone_number,
            value: inputValue,
            isValid: true,
            message: undefined,
          },
        })
        this.props.onAddGeneralData(this.state.isBlockChecked, this.state.canSave, { phone_number: inputValue })
      } else {
        this.setState({
          phone_number: {
            ...this.state.phone_number,
            value: inputValue,
            isValid: false,
            message: 'O número de telefone é inválido',
          },
        })
      }
    } else {
      this.setState({
        phone_number: {
          ...this.state.phone_number,
          value: inputValue,
          isValid: true,
          message: '',
        },
      })
    }
  }

  render() {
    const genderOptions = GENDER
    const { isEdit } = this.props

    return (
      <div className="RegisterBlock__GeneralData">
        <Row padding=" 0 0 2rem 0">
          <Typography variant='content1' weight='bold'>
            Dados pessoais
          </Typography> 
        </Row>

        <Row>
          <Column colWidth={6}>
            <TextInput
              placeholder="E-mail*"
              required={true}
              name="email"
              hasError={!this.state.email.isValid}
              inputHeight='4.8rem'
              messageError={this.state.email.message}
              onInputChange={(e) => this.handleEmailInput(e)}
              onFocusOut={this.handleCheckEmail}
              value={this.state.email.value}
              disabled={isEdit ? true : false}
              addIcon
              iconProps={{
                name: 'PencilEdit',
                fill: '#1F35B5',
                height: '24',
                width: '24',
                viewBox: '0 0 22 22',
              }}
              onClickIcon={()=>{this.props.onShowModal(modalTypes.USER_PROFILE_EMAIL, {
                emailUser: this.state.email.value,
                name: this.state.full_name.value,
                modalTitle: 'Alterar endereço de e-mail',
                titleColor: 'black',
                btnText: 'CONFIRMAR ALTERAÇÃO',
                classModifier: 'default',
                redirectURL: routes.DASHBOARD_EDIT_PROFESSIONAL,
                profile: PSYCHOLOGIST
              })}}
            />
          </Column>

          <Column colWidth={6}>
            <TextInput
              placeholder="Nome completo*"
              required={true}
              name="full_name"
              hasError={!this.state.full_name.isValid}
              inputHeight='4.8rem'
              messageError={this.state.full_name.message}
              onInputChange={(e) => this.handleNameInput(e)}
              value={this.state.full_name.value}
            />
          </Column>

          <Column colWidth={6}>
            <Dropdown
              options={genderOptions}
              onSelect={(code) => this.handleSelectGender(code)}
              placeholder="Gênero*"
              name="gender"
              selectedItem={this.state.gender.value}
              preSelectedItem={this.state.gender.value}
            />
          </Column>

          <Column colWidth={6}>
            <TextInput
              placeholder="CPF*"
              required={true}
              name="cpf"
              hasError={!this.state.cpf.isValid}
              inputHeight='4.8rem'
              mask="111.111.111-**"
              messageError={this.state.cpf.message}
              onInputChange={(e) => this.handleCpfInput(e)}
              value={this.state.cpf.value}
            />
          </Column>

          <Column colWidth={6}>
            <TextInput
              placeholder="Data de nascimento*"
              required={true}
              name="date_birth"
              hasError={!this.state.date_birth.isValid}
              inputHeight='4.8rem'
              messageError={this.state.date_birth.message}
              mask="11/11/1111"
              onInputChange={(e) => this.handleBirthDateInput(e)}
              value={this.state.date_birth.value}
            />
          </Column>

          <Column colWidth={6}>
            <PhoneInput
              id="cellphone_number"
              country="BR"
              limitMaxLength={true}
              placeholder="Telefone celular*"
              value={this.state.cellphone_number.value}
              onChange={(cellphone_number) => this.handleCellphoneInput(cellphone_number)}
              error={this.state.cellphone_number.message}
              flags={flags}
            />
          </Column>

          <Column colWidth={6}>
            <PhoneInput
              id="phone_number"
              country="BR"
              limitMaxLength={true}
              placeholder="Telefone comercial"
              value={this.state.phone_number.value}
              onChange={(phone_number) => this.handlePhoneInput(phone_number)}
              error={this.state.phone_number.message}
              style={{ 'marginTop': '10px' }}
              flags={flags}
            />
          </Column>
        </Row>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    isEdit: state.registerBlocks.isEdit,
    personGeneralData: state.registerBlocks.blocksData.generalData,
    generalData: state.registerBlocks,
    personGeneral: state.persons.person,
    personProfile: state.persons.personProfile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAddGeneralData: (isBlockChecked, canSave, field) => (dispatch(addGeneralData(isBlockChecked, canSave, field))),
    onGetPersons: (username, role) => (dispatch(getPerson(username, role, false))),
    onEditBlocks: (personData) => dispatch(actions.editBlocksData(personData)),
    onShowModal: (modalType, modalData) => dispatch(actions.showModal(modalType, modalData)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(GeneralData))