import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import Header from '../../components/Header';
import MenuMobile from '../../components/MenuMobile';

import viewportHoc from '../../hoc/viewportHoc';
import * as actions from '../../redux/actions';

class HeaderMenu extends Component {

  state = { isMenuOpen: undefined }

  componentDidMount() {
    //TO RETURN BODY SCROLL IN THE FIRST RENDER
    document.body.classList.remove('overflow-hidden');
  }

  handleOpenMenu = () => {
    if (this.state.isMenuOpen === undefined) {
      this.setState({ isMenuOpen: true });
    }

    this.setState({ isMenuOpen: !this.state.isMenuOpen }, function() {
      //TO PREVENT BODY SCROLL WHEN THE USER INTERACTS WITH MAIN MENU
      //ON MOBILE
      if (this.props.isMobile === true && this.state.isMenuOpen === true) {
        document.body.classList.add('overflow-hidden');
      } else {
        document.body.classList.remove('overflow-hidden');
      }
    });
  }

  handleSelectProfile = async(profileCode) => {
    await this.props.onSwap(profileCode);
  }

  handleLogout = async() => {
    await this.props.onLogout();
  }

  render() {
    let viewportInfo = {
      isDesktop: this.props.isDesktop,
      isMobile: this.props.isMobile,
      width: this.props.width,
    };

    let userProps = {};
    if (this.props.isAuth) {
      userProps = {
        avatar: this.props.user.person_general.picture_url,
        email: this.props.user.person_general.email,
        firstName: this.props.user.person_general.first_name,
        friendlyUrl: this.props.user.person_general.friendly_url,
        profilePermissions: this.props.profilePermissions,
        profiles: this.props.user.person_profile_swap,
        actualProfile: this.props.profileSelected,
      };
    }

    return (
      <Fragment>
        {
          viewportInfo.isMobile ? (
            <MenuMobile
              isOpen={this.state.isMenuOpen}
              onCloseClick={this.handleOpenMenu}
              isLogged={this.props.isAuth}
              userProps={userProps}
              onHandleLogout={this.handleLogout}
              onClickSwapSelect={this.handleSelectProfile}
            />
          ) : null
        }
        <Header
          onHandleMenuMobile={this.handleOpenMenu}
          isLogged={this.props.isAuth}
          isSuccinct={this.props.isSuccinct}
          userProps={userProps}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuth,
    profilePermissions: state.auth.profilePermissions,
    profileSelected: state.auth.profileSelected,
    user: state.auth.userData,
    userToken: state.auth.userToken,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      dispatch(actions.authLogout());
    },
    onMe: (meData) => dispatch(actions.authUser(meData)),
    onSwap: (profileCode) => dispatch(actions.authSwap(profileCode)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(HeaderMenu));