import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import Row from '../../components/Row/';
import { Typography, Button, CartItem } from 'components';

import { history } from '../../routers/AppRouter';
import * as routes from './../../settings/_routesSettings';

class CartList extends Component {
  static propTypes = {
    appointmentCategory: PropTypes.any,
    itemList: PropTypes.array,
    isDeletable: PropTypes.bool,
    onDelete: PropTypes.func,
    professionalName: PropTypes.string,
  }
  static defaultProps = {
    itemList: [],
    isDeletable: true,
  }

  handleRestartSearch = () => {
    history.push(routes.HOME_PSIC);
  }

  render() {
    return (
      <div className="CartList">
        {
          this.props.itemList.length > 0 ? (
            <div className="CartList__populated">
              <div className="CartList__populated__name">
                <Typography
                  type="subtitle">
                  {this.props.appointmentCategory}
                </Typography>
              </div>
              <div className="CartList__populated__appointment">
                <Typography>
                  {this.props.professionalName}
                </Typography>
              </div>
              {
                this.props.itemList.map(item => {
                  /*
                   * TODO: Ajustar as variáveis do JSON
                   * para as variáveis do componente
                   */
                  return (
                    <CartItem key={item.id}
                      onDelete={(id) => this.props.onDelete(id)}
                      isDeletable={this.props.isDeletable}
                      date={item.date}
                      duration={item.duration}
                      itemId={item.id}
                      price={item.price}
                      time={item.time}
                      weekday={item.weekday}
                    />);
                })
              }
            </div>
          ) : (
            <div className="CartList__empty">
              <Row className="CartList__empty__text">
                <Typography type="subtitle">
                  <Trans>
                      Não há itens em sua lista
                  </Trans>
                </Typography>
              </Row>
              <Row className="CartList__empty__button">
                <Button classModifier="reverse"
                  id="restart-search"
                  onButtonClick={this.handleRestartSearch}
                  text={(<Trans>RECOMECE SUA BUSCA</Trans>)}
                />
              </Row>
            </div>
          )
        }
      </div>
    );
  }
}

export default CartList;