import React from 'react'

import { Typography, Button, Avatar } from '@telavita-core/react-design-kit'

import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'

import { cellphoneMask } from 'utils'

import { ReactComponent as IconMail } from 'static/svg/icon_mail_24x24.svg'
import { ReactComponent as IconPhone } from 'static/svg/icon_telefone_24x24.svg'

import {
  Container,
  Content,
  UserList,
  UserItem,
} from './styles'
import { formatCpf } from 'utils/textTransformations'

interface PatientDetailProfessionalModalProps {
  name: string
  phone: string
  email: string
  cpf?: string
  onClose: () => void
}

export function PatientDetailProfessionalModal({
  onClose,
  email,
  name,
  phone,
  cpf
}: PatientDetailProfessionalModalProps): JSX.Element {
  return (
    <ModalGeneric
      modalTitle=''
      onCloseModal={onClose}
    >
      <Container>
        <Avatar 
          alt='paciente'
          src='/static/avatar.png'
          size='large'
        />
        <Content>
          <span>Paciente</span>
          <Typography 
            weight='bold'
            variant='header4'
            inlineStyles={{
              textAlign: 'center'
            }}
          >
            {name}
          </Typography>
          <UserList>
            {!!cpf && (
              <UserItem>
                <Typography
                  variant='content2'
                >
                 CPF: {formatCpf(cpf)}
                </Typography>
              </UserItem>
            )}
            <UserItem>
              <IconPhone />
              <Typography
                variant='content2'
              >
                {cellphoneMask(phone)}
              </Typography>
            </UserItem>

            <UserItem>
              <IconMail />
              <Typography
                variant='content2'
              >
                {email}
              </Typography>
            </UserItem>

          </UserList>
          <Button
            variant='contained'
            onClick={onClose}
            inlineStyles={{
              width: '100%',
              marginTop: '30px',
            }}
          >
            FECHAR
          </Button>
        </Content>
      </Container>
    </ModalGeneric>
  )
}