import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  AWAITING_PIN,
  AWAITING_MEDICAL_REQUEST,
  ACTIVE,
  INACTIVE,
  INCOMPLETE_ACCOUNT
} from '../../settings/_personSettings'
import { formatCpf } from 'utils/textTransformations'

// COMPONENTS
import { Typography, Avatar, CoreIcons } from 'components'
import FloatMenu from '../FloatMenu'
import Row from '../Row'

class ProfileListItem extends Component {
  state = { isMenuOpen: false }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClickOutside)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClickOutside)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  onClickOutside = (e) => {
    if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
      this.setState({ isMenuOpen: false })
    }
  }

  onMenuOpen = () => {
    this.setState({ isMenuOpen: true })
  }

  render() {
    const {
      avatar,
      id,
      menuList,
      name,
      email,
      userDocument,
      userId,
      userStatus,
    } = this.props
    
    let { isMenuOpen } = this.state

    let statusClass = ''

    switch (userStatus.status_code) {
    case ACTIVE:
      statusClass = '--active'
      break

    case INACTIVE:
      statusClass = '--inactive'
      break

    case INCOMPLETE_ACCOUNT:
      statusClass = '--uncomplete'
      break

    case AWAITING_PIN:
      statusClass = '--uncomplete'
      break

    case AWAITING_MEDICAL_REQUEST:
      statusClass = '--uncomplete'
      break

    default:
      statusClass = ''
      break
    }

    return (
      <div id={`person-${userId}`}
        className={isMenuOpen ? 'profile-list-item--isOpen' : 'profile-list-item'}>
        <Row
          padding="2.2rem 1.1rem 2.2rem 2.4rem"
          justify="flex-start"
          align="center"
          wrap='nowrap'>
          <div className="profile-list-item--avatar">
            <Avatar avatarSize='5rem' imgAlt={name} imgUrl={avatar} />
          </div>

          <div className="profile-list-item--info">
            <div className="profile-list-item--info--name">
              <Row>
                <Typography bold
                  type="paragraph">
                  {name}
                </Typography>
              </Row>

              <Row>
                <Typography
                  type="paragraph" style={{marginBottom: 10}}>
                  {email}
                </Typography>
              </Row>

              {!!userDocument && (
                <Row margin="-0.3rem auto auto auto">
                  <Typography>
                    CPF: {formatCpf(userDocument)}
                  </Typography>
                </Row>
              )}
              
              <div className="profile-list-item--info--data">
                <Row justify="flex-start">
                  <div className="profile-list-item--info--id">
                    <Typography color="grey-dark">
                      ID: {userId}
                    </Typography>
                  </div>

                  <div className={`profile-list-item--info--status${statusClass}`}>
                    <CoreIcons height='1.3rem' name="StatusIcon" width='1.3rem' />
                    <Typography>
                      {userStatus.status_name}
                    </Typography>
                  </div>
                </Row>
              </div>
            </div>
          </div>

          <div className="profile-list-item--menu"
            id={`profile-list-item--menu-${userId}`}
            onClick={this.onMenuOpen}
            ref={node => this.wrapperRef = node}>
            <CoreIcons
              name="MoreOptions"
              fill="#4b4b4b"
              height="45px"
              width="45px"
            />
            {
              isMenuOpen &&
              <FloatMenu
                className="profile-list-item--float-menu"
                id={id.toString()}
                itemList={menuList}
              />
            }
          </div>
        </Row>
      </div>
    )
  }
}

ProfileListItem.propTypes = {
  avatar: PropTypes.any,
  isMenuOpen: PropTypes.any,
  name: PropTypes.any,
  onMenuOpen: PropTypes.any,
  userDocument: PropTypes.any,
  userId: PropTypes.any,
  userStatus: PropTypes.any,
}

export default ProfileListItem