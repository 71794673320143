import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Review {
  id: number
  author: string
  createdAt: Date
  review: string
}

interface ResponseReview {
  id: number
  patient_initial_letters: string
  created_at: string
  review: string
}

interface Response {
  data: {
    reviews: ResponseReview[]
    pagination: {
      total: number
      page: number
    }
  }
}

interface Error {
  response: {
    status: number
  }
}

interface ReviewsData {
  reviews: Review[]
  pagination: {
    total: number
    page: number
  }
}

interface ListProfessionalReviewsProps {
  professionalUUID: string
  page?: number
  viewAll?: boolean
}

export const listProfessionalReviews = ({
  professionalUUID,
  page,
  viewAll,
}: ListProfessionalReviewsProps): Promise<ReviewsData> => {

  //eslint-disable-next-line
  const endpoint = apiEndpoints.PROFESSIONAL_REVIEWS(professionalUUID)

  const params = {
    per_page: 5,
    ...(page && { page }),
    ...(viewAll && { view_all: viewAll }),
  }
  
  return new Promise<ReviewsData> ((resolve, reject) => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        const reviews = response.data.reviews.map(review => {
          return {
            id: review.id,
            author: review.patient_initial_letters,
            createdAt: new Date(review.created_at),
            review: review.review,
          } as Review
        })

        const pagination = { ...response.data.pagination }

        resolve({ reviews, pagination } as ReviewsData)
      })
      .catch((error: Error) => {
        reject(error.response.status)
      })
  })
}