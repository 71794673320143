import React from "react";

const Location = ({
  fill,
  height,
  style,
  viewBox = "-8 -8 40 40",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g data-name="Icon / Add / Filled">
      <path fill="none" d="M0 0h24v24H0z"/>
        <g data-name="Agrupar 227">
          <path data-name="Caminho 2171" d="M0 0h24v24H0z" fill="none"/>
        <g data-name="Agrupar 231">
          <path data-name="Caminho 2181" d="M0 0h24v24H0z" fill="none"/>
          <path data-name="Caminho 2182" d="M11.99 2A10 10 0 1 0 22 12 10 10 0 0 0 11.99 2zM12 20a8 8 0 1 1 8-8 8 8 0 0 1-8 8zm3.5-9A1.5 1.5 0 1 0 14 9.5a1.5 1.5 0 0 0 1.5 1.5zm-7 0A1.5 1.5 0 1 0 7 9.5 1.5 1.5 0 0 0 8.5 11zm3.5 6.5a5.5 5.5 0 0 0 5.11-3.5H6.89A5.5 5.5 0 0 0 12 17.5z" fill={fill}/>
        </g>
      </g>
    </g>
  </svg>
);

export default Location;
