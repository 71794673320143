import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useDimensions, Typography, Spinner } from '@telavita-core/react-design-kit'

import { CoreIcons } from 'components'
import disableScroll from 'utils/disableScroll'
import clsx from 'clsx'

function FloatActionButtonDropdown({
  loading,
  title,
  icon,
  position = 'left',
  options,
  renderOption,
  onSelectOption,
  leftButtonRipple = null,
  closeModalWhenSelectOption = true,
  updateModalState = false,
  onClose = () => {}
}) {
  const container = useRef(null)
  const optionListRef = useRef()

  const { isMobile } = useDimensions()

  const [isModalOpen, setModalOpen] = useState(false)

  const windowWidth = window.innerWidth
  const windowHeight = window.innerHeight

  const containerPosition = container.current?.getBoundingClientRect()
  const modalOptionListWidth = optionListRef.current?.getBoundingClientRect().width
  
  const shouldShowLeftButtonRipple = isModalOpen && leftButtonRipple

  const optionsListStyle = isMobile ? {
    bottom: windowHeight - containerPosition?.top + 20,
    ...position === 'left' ? { left: '24px'} : {},
    ...position === 'right' ? { right: '24px' } : {},
  } : {
    top: containerPosition?.bottom + 20,
    right: windowWidth - containerPosition?.right,
  }

  const containerStyle = clsx({
    'FloatActionButtonDropdown': true,
    'FloatActionButtonDropdown--open': isModalOpen,
    'FloatActionButtonDropdown--left': position === 'left',
    'FloatActionButtonDropdown--right': position === 'right',
  })

  const buttonStyle = clsx({
    'FloatActionButtonDropdown__Button': true,
    'FloatActionButtonDropdown__Button--open': isModalOpen,
  })

  const iconStyle = clsx({
    'FloatActionButtonDropdown__Icon': true,
    'FloatActionButtonDropdown__Icon--open': isModalOpen,
  })

  const optionStyle = clsx({
    'FloatActionButtonDropdown__Modal__OptionsList__Option': true,
    'FloatActionButtonDropdown__Modal__OptionsList__Option--left': position === 'left',
    'FloatActionButtonDropdown__Modal__OptionsList__Option--right': position === 'right',
  })


  const handleUpdateModalStatus = () => {
    setModalOpen(oldState => {
      if (oldState) {
        disableScroll.off()
        onClose()
      } else {
        disableScroll.on()
      }
      return !oldState
    })
  }

  useEffect(() => {
    if (updateModalState) handleUpdateModalStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateModalState])

  return (
    <>
    <div className={containerStyle} style={{ width: shouldShowLeftButtonRipple ? `${modalOptionListWidth}px` : 'auto' }} ref={container}>
      {shouldShowLeftButtonRipple && !isMobile && leftButtonRipple}
      <button 
        className={buttonStyle} 
        onClick={handleUpdateModalStatus}
        disabled={loading}
      >
        {
          !loading &&
          <div className={iconStyle}>
            <CoreIcons
              name={isModalOpen ? 'Close' : icon}
              height="24px"
              fill="white"
              width="24px"
              viewBox={isModalOpen ? '6 5 24 24' : '0 0 24 24'}
            />
          </div>
        }

        {
          !loading && !isModalOpen && !isMobile &&
          <Typography variant='content2' inheritColor>
            {title}
          </Typography>
        }

        {
          loading &&
          <div className='FloatActionButtonDropdown__SpinnerContainer'>
            <Spinner customClassName='FloatActionButtonDropdown__Spinner' />
          </div>
        }
      </button>
    </div>
       <div className={clsx({
         'FloatActionButtonDropdown__Modal': true,
         'FloatActionButtonDropdown__Modal--open': isModalOpen
       })}>
         <div className="FloatActionButtonDropdown__Modal__Backdrop"></div>
         <div className="FloatActionButtonDropdown__Modal__Wrapper">
           <div className="FloatActionButtonDropdown__Modal__OptionsList" ref={optionListRef} style={optionsListStyle}>
             {shouldShowLeftButtonRipple && isMobile && leftButtonRipple}
             {
               options.map((option, index) => {
                 return (
                   <div
                     key={index}
                     className={optionStyle}
                     onClick={() => {
                       onSelectOption(option)
                       if (closeModalWhenSelectOption) setModalOpen(false)
                       disableScroll.off()
                     }}>
                     {renderOption(option, index)}
                   </div>
                 )
               })
             }
           </div>
         </div>
       </div>
    </>
  )
}

FloatActionButtonDropdown.propTypes = {
  loading: PropTypes.bool,
  iconName: PropTypes.string,
  title: PropTypes.any,
  type: PropTypes.oneOf([
    'primary',
    'reverse',
    'iconOnly',
  ]),
  position: PropTypes.oneOf([
    'left',
    'right',
  ]),
  options: PropTypes.array,
  renderOption: PropTypes.func,
  onSelectOption: PropTypes.func,
}

export default FloatActionButtonDropdown
