import React from "react";

const AddPerson = ({
  fill,
  height,
  viewBox = "-10 -10 45 45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M15 12a4 4 0 1 0-4-4 4 4 0 0 0 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
    </g>
  </svg>
);


export default AddPerson;