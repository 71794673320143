import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@telavita-core/react-design-kit'

import { CoreIcons } from 'components'
import Row from './../Row'

import { ReactComponent as BadgeNew } from 'static/svg/badge-new.svg'

const MenuDashboardItem = ({ icon, id, title, hasBadge }) => {
  return (
    <Row
      align="center"
      justify="flex-start"
      className="menu-dashboard-item"
      id={`menu-dashboard-item-${id}`}
      wrap="nowrap"
    >
      <div className="menu-dashboard-item--icon">
        <CoreIcons {...icon} />
      </div>
      <Typography 
        color='primary'
        variant='content2'
        weight='bold'
        customClassName="menu-dashboard-item--text"
      >
        {title}
      </Typography>

      {hasBadge && (<BadgeNew className='menu-dashboard-item--badge'/>)}

    </Row>
  )
}

MenuDashboardItem.propTypes = {
  icon: PropTypes.object,
  id: PropTypes.string,
  title: PropTypes.any,
  hasBadge: PropTypes.bool,
}

MenuDashboardItem.defaultProps = {
  iconProps: {
    fill: '#1F35B5',
    height: '24',
    name: 'ListAlt',
    viewBox: '0 0 24 24',
    width: '24'
  },
  hasBadge: false,
}

export default MenuDashboardItem
