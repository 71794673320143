import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Typography, Button, Spinner } from 'components'
import Dropdown from 'components/Dropdown'
import AlertIcon from 'components/AlertIcon'
import Row from 'components/Row'
import NotificationStep from 'components/NotificationStep'
import Modal from '../Modal/Modal'

import { ATTENDED, UNATTENDED, RETURNED, PROFESSIONAL_NO_SHOW, PATIENT_NO_SHOW } from 'settings/_scheduleStatusSettings'

import { Trans } from '@lingui/macro'
import { useChangeScheduleStatus } from './useChangeScheduleStatus'
import { hideModal } from 'redux/actions'

const getAvailableOptions = (status) => {
  if (status === PROFESSIONAL_NO_SHOW || status === PATIENT_NO_SHOW)
    return [
      { name: 'Profissional faltou', code: PROFESSIONAL_NO_SHOW },
      { name: 'Paciente faltou', code: PATIENT_NO_SHOW },
      { name: 'Realizada', code: ATTENDED },
      { name: 'Devolvida', code: RETURNED }
    ]

  if (status === ATTENDED || status === UNATTENDED)
    return [
      { name: 'Realizada', code: ATTENDED },
      { name: 'Devolvida', code: RETURNED },
      { name: 'Profissional faltou', code: PROFESSIONAL_NO_SHOW },
      { name: 'Paciente faltou', code: PATIENT_NO_SHOW }
    ]

  return [
    { name: 'Não realizada', code: UNATTENDED },
    { name: 'Devolvida', code: RETURNED }
  ]
}

function ModalChangeScheduleStatus({ schedule }) {
  const dispatch = useDispatch()
  const currentStatus = schedule?.status || ''

  const [state, setState] = useState({
    success: false,
    statusSelected: currentStatus,
    availableStatus: getAvailableOptions(currentStatus)
  })
  
  const { handleUpdateScheduleStatus, isLoading, isSuccess } = useChangeScheduleStatus()

  useEffect(() => {
    if (isSuccess) {
      setState({ ...state, success: true })
    }
  }, [isSuccess])

  const closeWithSucess = () => {
    dispatch(hideModal())
  }

  const handleSubmitButton = () => {
    handleUpdateScheduleStatus({ id: schedule.id, status: state.statusSelected })
  }

  const renderUpdateSuccess = () => {
    const notificationStepContent = (
      <Row justify="center">
        <Typography bold
          align="center"
          style={{ maxWidth: '33rem' }}
          type="heading2">
          <Trans>
            Modificação de status efetuada com sucesso!
          </Trans>
        </Typography>
      </Row>
    )

    return (
      <div className="ModalApproveValidity__Data">
        <NotificationStep
          buttonId="close-modal"
          btnText="Fechar"
          mainText={notificationStepContent}
          onSuccessClick={closeWithSucess}
          iconColor="#4ac326"
          iconName="Check"
          hasButton
        />
      </div>
    )
  }

  const getStatusName = (status) => {
    return state.availableStatus?.find(item => item.code === status)?.name
  }

  const btnSubmitDisabled = () => {
    return state.statusSelected === currentStatus
  }

  return (
    <Modal
      btnId="confirm-change-status"
      modalTitle={state.success ? '' : 'Mudar status da consulta'}
      closeModal={handleSubmitButton}
      extraClassName
    >
      {isLoading ?
        <div className="Container">
          <Spinner height="150px" />
        </div>
        :
        state.success ?
          renderUpdateSuccess()
          :
          <div className="Container">
            <div className="Container__data">
              <Typography align='center'>Psicólogo:</Typography>
              <Typography align='center' bold>{schedule.professionalName}</Typography>
              <Typography align='center'>CRP {schedule.professionalDocument}</Typography>
            </div>

            <div className="Container__data">
              <Typography align='center'>Paciente:</Typography>
              <Typography align='center' bold>{schedule.clientName}</Typography>
            </div>

            <div className="Container__data">
              <Typography align='center'>Data e horário:</Typography>
              <Typography align='center' bold>{`${schedule.date} às ${schedule.time}`}</Typography>
            </div>

            <div className="Container__dropdown">
              <Dropdown
                onSelect={status => setState({ ...state, statusSelected: status })}
                options={state.availableStatus}
                placeholder='Selecione o status'
                preSelectedItem={state.statusSelected}
              />
              {
                state.statusSelected !== schedule.status &&
                <div className="Container__data">
                  <AlertIcon message={`Mudar o status da consulta para ${getStatusName(state.statusSelected)} é irreversível.\nTenha certeza antes de confirmar.`} />
                </div>
              }
            </div>

            <Button
              disabled={btnSubmitDisabled()}
              text='Modificar status'
              id="send-medical-request"
              onButtonClick={handleSubmitButton}
            />
          </div>
      }
    </Modal>
  )}

export default ModalChangeScheduleStatus
