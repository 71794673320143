import React from 'react';
import PropTypes from 'prop-types';

import { Avatar } from 'components';

const MenuUserInfo = ({
  avatar,
  email,
  firstName,
}) => {
  return (
    <div className="MenuUserInfo">
      <div className="MenuUserInfo__avatar">
        <Avatar avatarSize='50px' imgAlt={firstName} imgUrl={avatar} />
      </div>

      <div className="MenuUserInfo__name">
        <p>{firstName}</p>
      </div>

      <div className="MenuUserInfo__email">
        <p>{email}</p>
      </div>
    </div>
  );
};

MenuUserInfo.propTypes = {
  avatar: PropTypes.string,
  email: PropTypes.string,
  firstName: PropTypes.string,
};

export default MenuUserInfo;