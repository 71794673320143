import {
  ASYNC_CLEAN_ERRORS,
  ASYNC_ERROR,
  ASYNC_REQUEST,
  ASYNC_SUCCESS,
} from './../actions/_actionTypes'

import { updateState } from '../helpers/utilityHelpers'

const initialState = {
  errorList: null,
  loading: false,
  genericError: null,
  success: false
}

const asyncError = (state, { payload }) => {
  return updateState(state, {
    errorList: {
      fieldError: payload.fieldError,
      sysError: payload.sysError,
      feedbackError: payload.feedbackError

    },
    genericError: payload.genericError,
    loading: false,
    success: false
  })
}

const asyncRequest = (state) => {
  return updateState(state, {
    errorList: null,
    loading: true,
    success: false
  })
}

const asyncSuccess = (state) => {
  return updateState(state, {
    errorList: null,
    loading: false,
    success: true
  })
}

const asyncReducer = (state = initialState, action) => {
  switch (action.type) {
  case ASYNC_CLEAN_ERRORS: return asyncSuccess(state)
  case ASYNC_ERROR: return asyncError(state, action)
  case ASYNC_REQUEST: return asyncRequest(state)
  case ASYNC_SUCCESS: return asyncSuccess(state)
  default: return state
  }
}

export default asyncReducer