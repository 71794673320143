import React, { useRef, useState } from 'react';

import CoreIcons from 'components/CoreIcons';
import Spinner from 'components/Spinner';

const InputList = ({ placeholder = 'Digite', onChangeSelected = () => { }, onEnterPressed = () => { }, options = [], loading = false }) => {
  const inputListRef = useRef(null);
  const [ value, setValue ] = useState('');
  
  const onSelect = (item) => {
    setValue(item.name);
    onChangeSelected(item);
  };

  const onChangeValue = (e) => setValue(e.target.value);

  const onPressed = (e) => {
    if (e.key === 'Enter') {
      onEnterPressed(value);
    }
  };

  return (
    <div className="InputList" ref={inputListRef} onKeyUp={onPressed}>
      <div>
        <input type="text" className="InputList__Input" placeholder={placeholder} onChange={onChangeValue} value={value}/>
        <span className="InputList__Button" onClick={() => onEnterPressed(value)}>
          <CoreIcons fill='#1F35B5' height='30px' name='Search' viewBox="3 2 30 30" width='30px' />
        </span>
      </div>

      <ul className={`InputList__List${options.length > 0 || loading ? '--show' : ''}`}
        style={{ width: inputListRef?.current?.offsetWidth || 'auto' }}>

        { options.map(item => <li key={item.id} className="InputList__List__Item" onClick={() => onSelect(item)}>{item.name}</li>) }
        
        { loading && <li className="InputList__List__Item"><Spinner height="15px" /></li> }
      </ul>
    </div>
  );
};

export { InputList };