import React, { createContext, useContext } from 'react'
import { FilterActions, FilterState, filterReducer, initialState } from './filterReducer'

interface FilterContextProps {
  state: FilterState
  dispatch: React.Dispatch<FilterActions>
}

const FilterContext = createContext<FilterContextProps>({
  state: initialState,
  dispatch: () => null,
})

export const FilterProvider: React.FC = ({ children }) => {
  const [state, dispatch] = React.useReducer(filterReducer, initialState)
  
  return (
    <FilterContext.Provider value={{ state, dispatch }}>
      {children}
    </FilterContext.Provider>
  )
}

export function useFilter(): FilterContextProps {
  const context = useContext(FilterContext)

  if (context === undefined) {
    throw new Error('useFilter must be used within a FilterProvider')
  }

  return context
}