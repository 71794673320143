import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';

function ListPlaceholder({
  description,
  iconHeight,
  iconName,
  iconViewBox,
  iconWidth,
  title,
}) {
  return (
    <div className="ListPlaceholder">
      <div className="ListPlaceholder__icon">
        <CoreIcons
          height={iconHeight}
          name={iconName}
          width={iconWidth}
          viewBox={iconViewBox}
        />
      </div>
      <div className="ListPlaceholder__title">
        <Typography type="paragraph"
          align="center">
          {title}
        </Typography>
      </div>
      <div className="ListPlaceholder__description">
        <Typography type="subheading2"
          align="center">
          {description}
        </Typography>
      </div>
    </div>
  );
}

ListPlaceholder.propTypes = {
  description: PropTypes.any,
  iconName: PropTypes.string,
  iconViewBox: PropTypes.string,
  iconWidth: PropTypes.string,
  title: PropTypes.any,
};

export default ListPlaceholder;

