import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import { Column, Container } from 'components'
import MenuSidebar from '../../components/MenuSidebar'

import viewportHoc from '../../hoc/viewportHoc'
import DashboardRouter from '../../routers/DashboardRouter'
import * as actions from '../../redux/actions'
import { hideIntercom } from '../../utils/intercomHandlers'
import { WrapperScheduleNotification } from 'containers/ScheduleNotification'

class Dashboard extends Component {
  state = { elastic: false }

  componentDidMount() {
    hideIntercom()
  }

  componentWillUnmount() {
    this.props.onClearFilters()
    this.props.onCleanPersons()
  }

  setElastic = elastic => this.setState({ elastic })

  render() {
    const { elastic } = this.state

    return (
      <div className="Dashboard">
        <Container elastic={elastic && !this.props.isDesktop}>

          {this.props.isDesktop &&
            <Column colWidth={3} className="Dashboard__Menu">
              <MenuSidebar />
            </Column>
          }

          <Column colWidth={9} leftGutter className="Dashboard__Content">
            <DashboardRouter
              elastic={elastic}
              setElastic={this.setElastic}
              userPermissions={this.props.permissions}
            />
            <WrapperScheduleNotification />
          </Column>

        </Container>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    permissions: state.auth.profilePermissions,
  }
}

const mapDispatchToProps = dispatch => ({
  onCleanPersons: () => dispatch(actions.clearPersons()),
  onClearFilters: () => dispatch(actions.clearFilter()),
})

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(Dashboard))
