import React from 'react'
import PropTypes from 'prop-types'

import Row from '../Row'

function Spinner({ message, height, spinnerStyle, color }){
  return (
    <Row className="SpinnerContainer" align="center" justify="center" height={height} direction="column">
      <div className="Spinner" style={{ ...spinnerStyle, width: '100%', height: '100%' }} >
        <div className={color}></div>
        {
          message ? (
            <span className="Spinner__message">{message}</span>
          ) : null
        }
      </div>
    </Row>
  )
}

Spinner.propTypes = {
  message: PropTypes.string,
  height: PropTypes.string,
  spinnerStyle: PropTypes.object,
  color: PropTypes.oneOf([
    'default',
    'white',
    'black',
    'yellow-gray',
  ]),
}

Spinner.defaultProps = { height: '100vh'  }

export { Spinner }
