import React from 'react'
import PropTypes from 'prop-types'
import Add from './Icons/Add'
import AddPerson from './Icons/AddPerson'
import AgendaTime from './Icons/AgendaTime'
import AlertIcon from './Icons/AlertIcon'
import AllMedicalRequests from './Icons/AllMedicalRequests'
import AllSchedules from './Icons/AllSchedules'
import ArrowRight from './Icons/ArrowRight'
import ArrowLeft from './Icons/ArrowLeft'
import ArrowBack from './Icons/ArrowBack'
import ArrowClock from './Icons/ArrowClock'
import ArrowDown from './Icons/ArrowDown'
import ArrowFab from './Icons/ArrowFab'
import ArrowTop from './Icons/ArrowTop'
import ArrowUp from './Icons/ArrowUp'
import BallonComment from './Icons/BallonComment'
import Building from './Icons/Building'
import Calendar from './Icons/Calendar'
import Cancel from './Icons/Cancel'
import ChatBubble from './Icons/ChatBubble'
import Check from './Icons/Check'
import CheckBoxSelected from './Icons/CheckBoxSelected'
import CheckBoxUnselected from './Icons/CheckBoxUnselected'
import CheckBoxUndefined from './Icons/CheckBoxUndefined'
import CirclePlay from './Icons/CirclePlay'
import Client from './Icons/Client'
import ClientCircle from './Icons/ClientCircle'
import Clinic from './Icons/Clinic'
import Clip from './Icons/Clip'
import Close from './Icons/Close'
import CreditCard from './Icons/CreditCard'
import Done from './Icons/Done'
import ErrorIcon from './Icons/ErrorIcon'
import ExitToApp from './Icons/ExitToApp'
import Facebook from './Icons/Facebook'
import FilterActive from './Icons/FilterActive'
import FilterInactive from './Icons/FilterInactive'
import FilterName from './Icons/FilterName'
import Filters from './Icons/Filters'
import Graphics from './Icons/Graphics'
import Graphs from './Icons/Graphs'
import Group from './Icons/Group'
import GroupPlanBuilding from './Icons/GroupPlanBuilding'
import Help from './Icons/Help'
import Hourglass from './Icons/Hourglass'
import House from './Icons/House'
import Info from './Icons/Info'
import UnavailabilitySchedule from './Icons/UnavailabilitySchedule'
import Instagram from './Icons/Instagram'
import ExpandLess from './Icons/ExpandLess'
import ExpandMore from './Icons/ExpandMore'
import LinkedIn from './Icons/LinkedIn'
import ListAlt from './Icons/ListAlt'
import Location from './Icons/Location'
import MagnifyingGlass from './Icons/MagnifyingGlass'
import MedicalRequest from './Icons/MedicalRequest'
import Menu from './Icons/Menu'
import MediaTest from './Icons/MediaTest'
import MoreOptions from './Icons/MoreOptions'
import NavigativeAfter from './Icons/NavigativeAfter'
import NavigativeBefore from './Icons/NavigativeBefore'
import NotFound from './Icons/NotFound'
import OutlineCheck from './Icons/OutlineCheck'
import PencilEdit from './Icons/PencilEdit'
import PersonCheck from './Icons/PersonCheck'
import Plans from './Icons/Plans'
import PlansBigger from './Icons/PlansBigger'
import Positive from './Icons/Positive'
import Professional from './Icons/Professional'
import ProfessionalFull from './Icons/ProfessionalFull'
import RadioSelected from './Icons/RadioSelected'
import RadioUnselected from './Icons/RadioUnselected'
import Refresh from './Icons/Refresh'
import ResponseEmoji from './Icons/ResponseEmoji'
import StatusIcon from './Icons/StatusIcon'
import Search from './Icons/Search'
import SexInfinite from './Icons/SexInfinite'
import SexMale from './Icons/SexMale'
import SexFemale from './Icons/SexFemale'
import Smile from './Icons/Smile'
import Tips from './Icons/Tips'
import Trash from './Icons/Trash'
import Twitter from './Icons/Twitter'
import UploadFile from './Icons/UploadFile'
import User from './Icons/User'
import UserAvatar from './Icons/UserAvatar'
import VisibilityOff from './Icons/VisibilityOff'
import VisibilityOn from './Icons/VisibilityOn'
import GeneralView from './Icons/GeneralView'
import Avatar from './Icons/Avatar'
import MyPatients from './Icons/MyPatients'
import FileUsers from './Icons/FileUsers'
import Buildings from './Icons/Buildings'
import CalendarOneDay from './Icons/CalendarOneDay'
import Schedule from './Icons/Schedule'
import Illustration from './Icons/Illustration'
import Scheduled from './Icons/Scheduled'
import Returned from './Icons/Returned'
import NoShow from './Icons/NoShow'
import Completed from './Icons/Completed'
import OfflinePatient from './Icons/OfflinePatient'
import ScheduleNoParticipants from './Icons/ScheduleNoParticipants'

const CoreIcons = (props) => {
  const { name } = props

  switch (name) {
  case 'Add': return <Add {...props} />
  case 'AddPerson': return <AddPerson {...props} />
  case 'AgendaTime': return <AgendaTime {...props} />
  case 'Avatar': return <Avatar {...props} />
  case 'AllMedicalRequests': return <AllMedicalRequests {...props} />
  case 'AllSchedules': return <AllSchedules {...props} />
  case 'AlertIcon': return <AlertIcon {...props} />
  case 'ArrowRight': return <ArrowRight {...props} />
  case 'ArrowLeft': return <ArrowLeft {...props} />
  case 'ArrowClock': return <ArrowClock {...props} />
  case 'ArrowBack': return <ArrowBack {...props} />
  case 'ArrowDown': return <ArrowDown {...props} />
  case 'ArrowFab': return <ArrowFab {...props} />
  case 'ArrowTop': return <ArrowTop {...props} />
  case 'ArrowUp': return <ArrowUp {...props} />
  case 'BallonComment': return <BallonComment {...props} />
  case 'Building': return <Building {...props} />
  case 'Buildings': return <Buildings {...props} />
  case 'Calendar': return <Calendar {...props} />
  case 'CalendarOneDay': return <CalendarOneDay {...props} />
  case 'Cancel': return <Cancel {...props} />
  case 'ChatBubble': return <ChatBubble {...props} />
  case 'Check': return <Check {...props} />
  case 'CheckBoxSelected': return <CheckBoxSelected {...props} />
  case 'CheckBoxUnselected': return <CheckBoxUnselected {...props} />
  case 'CheckBoxUndefined': return <CheckBoxUndefined {...props} />
  case 'CirclePlay': return <CirclePlay {...props} />
  case 'Client': return <Client {...props} />
  case 'ClientCircle': return <ClientCircle {...props} />
  case 'Clinic': return <Clinic {...props} />
  case 'Clip': return <Clip {...props} />
  case 'Close': return <Close {...props} />
  case 'CreditCard': return <CreditCard {...props} />
  case 'Done': return <Done {...props} />
  case 'ErrorIcon': return <ErrorIcon {...props} />
  case 'ExitToApp': return <ExitToApp {...props} />
  case 'ExpandLess': return <ExpandLess {...props} />
  case 'ExpandMore': return <ExpandMore {...props} />
  case 'Facebook': return <Facebook {...props} />
  case 'FilterActive': return <FilterActive {...props} />
  case 'FilterInactive': return <FilterInactive {...props} />
  case 'FilterName': return <FilterName {...props} />
  case 'Filters': return <Filters {...props} />
  case 'FileUsers': return <FileUsers {...props} />
  case 'Graphs': return <Graphs {...props} />
  case 'Graphics': return <Graphics {...props} />
  case 'Group': return <Group {...props} />
  case 'GroupPlanBuilding': return <GroupPlanBuilding {...props} />
  case 'Help': return <Help {...props} />
  case 'Hourglass': return <Hourglass {...props} />
  case 'House': return <House {...props} />
  case 'Illustration': return <Illustration {...props} />
  case 'Info': return <Info {...props} />
  case 'Instagram': return <Instagram {...props} />
  case 'LinkedIn': return <LinkedIn {...props} />
  case 'ListAlt': return <ListAlt {...props} />
  case 'Location': return <Location {...props} />
  case 'MagnifyingGlass': return <MagnifyingGlass {...props} />
  case 'MoreOptions': return <MoreOptions {...props} />
  case 'MedicalRequest': return <MedicalRequest {...props} />
  case 'Menu': return <Menu {...props} />
  case 'MediaTest': return <MediaTest {...props} />
  case 'NavigativeAfter': return <NavigativeAfter {...props} />
  case 'NavigativeBefore': return <NavigativeBefore {...props} />
  case 'NotFound': return <NotFound {...props} />
  case 'OutlineCheck': return <OutlineCheck {...props} />
  case 'PencilEdit': return <PencilEdit {...props} />
  case 'PersonCheck': return <PersonCheck {...props} />
  case 'Plans': return <Plans {...props} />
  case 'PlansBigger': return <PlansBigger {...props} />
  case 'Positive': return <Positive {...props} />
  case 'Professional': return <Professional {...props} />
  case 'ProfessionalFull': return <ProfessionalFull {...props} />
  case 'RadioSelected': return <RadioSelected {...props} />
  case 'RadioUnselected': return <RadioUnselected {...props} />
  case 'Refresh': return <Refresh {...props} />
  case 'ResponseEmoji': return <ResponseEmoji {...props} />
  case 'Schedule': return <Schedule {...props} />
  case 'Search': return <Search {...props} />
  case 'SexInfinite': return <SexInfinite {...props} />
  case 'SexMale': return <SexMale {...props} />
  case 'SexFemale': return <SexFemale {...props} />
  case 'Smile': return <Smile {...props} />
  case 'StatusIcon': return <StatusIcon {...props} />
  case 'Tips': return <Tips {...props} />
  case 'Trash': return <Trash {...props} />
  case 'Twitter': return <Twitter {...props} />
  case 'UploadFile': return <UploadFile {...props} />
  case 'User': return <User {...props} />
  case 'UserAvatar': return <UserAvatar {...props} />
  case 'VisibilityOff': return <VisibilityOff {...props} />
  case 'VisibilityOn': return <VisibilityOn {...props} />
  case 'GeneralView': return <GeneralView {...props} />
  case 'MyPatients': return <MyPatients {...props} />
  case 'UnavailabilitySchedule': return <UnavailabilitySchedule {...props} />
  case 'Scheduled': return <Scheduled {...props} />
  case 'Completed': return <Completed {...props} />
  case 'Returned': return <Returned {...props} />
  case 'NoShow': return <NoShow {...props} />
  case 'OfflinePatient': return <OfflinePatient {...props} />
  case 'ScheduleNoParticipants': return <ScheduleNoParticipants {...props} />

  default: return null
  }
}

CoreIcons.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.string,
  name: PropTypes.oneOf([
    'Add',
    'AddPerson',
    'AgendaTime',
    'Avatar',
    'AllMedicalRequests',
    'AllSchedules',
    'AlertIcon',
    'ArrowBack',
    'ArrowClock',
    'ArrowLeft',
    'ArrowRight',
    'ArrowDown',
    'ArrowFab',
    'ArrowTop',
    'ArrowUp',
    'BallonComment',
    'Building',
    'Buildings',
    'Calendar',
    'OutlineCheck',
    'CalendarOneDay',
    'Cancel',
    'Check',
    'ChatBubble',
    'CheckBoxSelected',
    'CheckBoxUnselected',
    'CheckBoxUndefined',
    'CirclePlay',
    'Client',
    'ClientCircle',
    'Clinic',
    'Clip',
    'Close',
    'CreditCard',
    'Done',
    'ErrorIcon',
    'ExclamationFilled',
    'ExitToApp',
    'ExpandLess',
    'ExpandMore',
    'Facebook',
    'FilterActive',
    'FilterInactive',
    'FilterName',
    'Filters',
    'FileUsers',
    'Graphs',
    'Graphics',
    'Group',
    'GroupPlanBuilding',
    'GeneralView',
    'Illustration',
    'MyPatients',
    'Help',
    'Hourglass',
    'House',
    'Info',
    'Instagram',
    'UnavailabilitySchedule',
    'LinkedIn',
    'ListAlt',
    'Location',
    'MagnifyingGlass',
    'MedicalRequest',
    'Menu',
    'MediaTest',
    'MoreOptions',
    'NavigativeAfter',
    'NavigativeBefore',
    'NotFound',
    'PencilEdit',
    'PersonCheck',
    'Plans',
    'PlansBigger',
    'Positive',
    'Professional',
    'ProfessionalFull',
    'RadioSelected',
    'RadioUnselected',
    'Refresh',
    'ResponseEmoji',
    'Schedule',
    'Search',
    'Smile',
    'StatusIcon',
    'Tips',
    'Trash',
    'Twitter',
    'UploadFile',
    'User',
    'UserAvatar',
    'VisibilityOff',
    'VisibilityOn',
    'SexInfinite',
    'SexMale',
    'SexFemale',
    'Scheduled',
  ]).isRequired,
  viewBox: PropTypes.any,
  width: PropTypes.any,
}

CoreIcons.defaultProps = {
  fill: '#000',
  height: '35px',
  name: 'AddPerson',
  width: '35px',
}

export { CoreIcons }
