import React from 'react'

import { useDispatch } from 'react-redux'

import { showModal } from 'redux/actions'

import { ActionsOptions } from 'containers/ModalManager/Modals/ReviewActionModal/settings'
import { ReviewActionModalProps } from 'containers/ModalManager/Modals/ReviewActionModal'
import { modalTypes } from 'containers/ModalManager/ModalManager'

import Skeleton from 'components/Skeleton/Skeleton'

import {
  Typography,
  Avatar,
  Button,
  useDimensions,
  Spinner,
} from '@telavita-core/react-design-kit'

import {
  Container,
  Content,
  Label,
  Title,
  Actions,
  Wrapper,
  ArchiveButtonContent,
  Loading,
  ShapeWrapper,
  ShapeAvatar,
  ShapeContent,
  ShapeText,
  ShapeActions,
  ShapeSeparator,
  ListContainer,
} from './styles'

import AvatarIcon from 'static/svg/avatarReview.svg'
import Stars from 'static/svg/ratingStars.svg'

export enum LabelOptions {
  VIEWED = 'NOVO',
  PUBLISHED = 'PUBLICADO',
  ARCHIVED = 'ARQUIVADO'
}

interface ReviewLoadingProps {
  hasActions: boolean
  isMobile: boolean
}

interface ReviewProps {
  label?: LabelOptions
  rating?: boolean
  actions?: boolean
  author: string
  createdAt: string
  review: string
  reviewId: number
  loadingBtn?: boolean
  onPublish?: (id: number) => Promise<void>
  onUnpublish?: (id: number) => Promise<void>
  onToArchive?: (id: number) => Promise<void>
}

export function Review({
  label,
  rating = false,
  actions = false,
  author,
  createdAt,
  review,
  reviewId,
  loadingBtn = false,
  onPublish,
  onUnpublish,
  onToArchive,
}: ReviewProps): JSX.Element {

  const { isMobile } = useDimensions()
  const dispatch = useDispatch()

  const { ARCHIVED, PUBLISHED } = LabelOptions
  const { PUBLISH, UNPUBLISH } = ActionsOptions

  const receivedActions = ![PUBLISHED, ARCHIVED].includes(label)
  const archivedActions = (label === ARCHIVED)
  const publishedActions = (label === PUBLISHED)

  function handleAction(action: ActionsOptions) {
    const modalData: ReviewActionModalProps = {
      action,
      author,
      createdAt,
      review,
      reviewId,
      onAction: action === PUBLISH 
        ? onPublish
        : onUnpublish
    }

    dispatch(showModal(modalTypes.REVIEW_ACTION, modalData))
  }

  return (
    <Container
      isMobile={isMobile}
    >

      <Wrapper>

        <Avatar
          alt={author}
          src={AvatarIcon}
          size='medium'
          inlineStyles={{ minWidth: '50px' }}
        />

        <Content>
          <Title>
            <Typography
              variant='content2'
              weight='bolder'
            >
              {author}
            </Typography>

            { label && <Label label={label}>{label}</Label> }
            { (!label && rating) && <img src={Stars} alt='five golden stars' /> }
          </Title>
            
          <Typography
            variant='content2'
            italic
            inlineStyles={{
              color: '#898989',
              textTransform: 'capitalize',
            }}
          >
            {createdAt}
          </Typography>

          <Typography
            variant='content2'
            inlineStyles={{
              lineHeight: '19px',
              whiteSpace: 'pre-wrap',
            }}
          >
            {review}
          </Typography>
        </Content>

      </Wrapper>

      {
        actions && (
          <Actions isMobile={isMobile}>
            {receivedActions && (
              <Button
                uppercase={false}
                variant='text'
                onClick={() => onToArchive(reviewId)}
                inlineStyles={{
                  padding: 0,
                }}
              >
                <ArchiveButtonContent
                  loading={loadingBtn}
                >
                  {
                    loadingBtn
                      ? (
                        <Spinner 
                          inlineStyles={{
                            width: '20px',
                            height: '20px',
                            border: '2px solid',
                            borderTopColor: 'transparent',
                          }}
                        />
                      )
                      : 'Arquivar'
                  }
                </ArchiveButtonContent>
              </Button>
            )}
            {(receivedActions || archivedActions) && (
              <Button  
                uppercase={false}
                variant='outlined'
                inlineStyles={{
                  height: '36px',
                  padding: '0 15px',
                }}
                onClick={() => handleAction(PUBLISH)}
              >
                  Publicar
              </Button>
            )}
            {publishedActions && (
              <Button  
                uppercase={false}
                variant='outlined'
                inlineStyles={{
                  height: '36px',
                  padding: '0 15px',
                }}
                onClick={() => handleAction(UNPUBLISH)}
              >
                  Despublicar
              </Button>
            )}
          </Actions>
        )
      }

    </Container>
  )
}

export function ReviewLoading({
  isMobile,
  hasActions,
}: ReviewLoadingProps): JSX.Element {

  return (
    <Loading
      isMobile={isMobile}
    >

      <ShapeWrapper>
        <ShapeAvatar>
          <Skeleton 
            minWidth={50}
            borderRadius={25}
            height={50}
          />
        </ShapeAvatar>

        <ShapeContent>
          <ShapeText maxWidth={40}>
            <Skeleton
              minWidth={40}
              height={15}
            />
          </ShapeText>
          <ShapeText maxWidth={540}>
            <Skeleton
              minWidth={100}
              height={15}
            />
          </ShapeText>
          <ShapeText 
            maxWidth={isMobile ? 250 : 400}
          >
            <Skeleton
              minWidth={80}
              height={15}
            />
          </ShapeText>
        </ShapeContent>
      </ShapeWrapper>

      {hasActions && (
        <ShapeActions isMobile={isMobile}>
          <Skeleton
            minWidth={40}
            width='90px'
            height={36}
          />
        </ShapeActions>
      )}

      <ShapeSeparator>
        <Skeleton
          minWidth={100}
          height={1}
        />
      </ShapeSeparator>

    </Loading>
  )
}

export { ListContainer }