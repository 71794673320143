import React from 'react'
import { useDispatch } from 'react-redux'

// COMPONENTS
import { CoreIcons, TextInput } from 'components'

import * as groupCodeSettings from '../../settings/_groupCodeSettings'
import { modalTypes } from '../../containers/ModalManager/ModalManager'
import { setTextFilter } from '../../redux/actions/filtersActions'
import { showModal } from '../../redux/actions'
import { useProfile } from 'hooks'

const DashboardFilters = ({ 
  hasModal, 
  inputId, 
  inputPlaceholder, 
  groupCode, 
  iconForAll, 
  showStatusBox, 
  showPlanFilter,
  query,
  onQueryChange
}) => {
  const dispatch = useDispatch()

  const { isSecretary } = useProfile()

  const handleSearchInput = e => {
    const inputValue = e.target.value
    onQueryChange(inputValue)
  }

  const handleSearchClick = () => {
    if (!query || !query.trim()) return

    dispatch(setTextFilter(query))
  }

  const showFilterModal = () => {
    const filterModalProps = {
      groupCode: groupCodeSettings[groupCode],
      bool: true,
      btnText: 'APLICAR MODIFICAÇÕES',
      iconForAll: iconForAll,
      showStatusBox: showStatusBox,
      showPlanFilter: showPlanFilter,
      isSecretary: isSecretary
    }

    dispatch(showModal(modalTypes.FILTER_STATUS, filterModalProps))
  }

  return (
    <div className="DashboardFilters">
      <div className="DashboardFilters__Input">
        <TextInput
          addIcon
          iconProps={{
            fill: '#1F35B5',
            height: '30px',
            name: 'Search',
            viewBox: '3 2 30 30',
            width: '30px',
          }}
          name={inputId}
          inputHeight="4.8rem"
          onInputChange={handleSearchInput}
          onClickIcon={handleSearchClick}
          onKeyPress={e => e.which === 13 && handleSearchClick()}
          placeholder={inputPlaceholder}
          value={query}
        />
      </div>
      {
        hasModal && (
          <div className="DashboardFilters__Button"
            onClick={showFilterModal}>
            <CoreIcons
              fill="#1F35B5"
              name="Filters"
              viewBox="-10 -10 35 35"
            />
          </div>
        )
      }
    </div>
  )
} 


export default DashboardFilters
