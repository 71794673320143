import {
  ASYNC_CLEAN_ERRORS,
  ASYNC_ERROR,
  ASYNC_REQUEST,
  ASYNC_SUCCESS,
} from './_actionTypes'
import { handleApiErrors } from '../../utils/errorsHandler'
import * as routes from '../../settings/_routesSettings'
import { deleteAuthToken } from 'utils/authHelper'

export const asyncError = (responseErr) => async dispatch => {
  let fieldError = []
  let sysError = []
  let feedbackError = []
  let errorList = {}
  let genericError = null

  let genericMsg = 'Houve um problema para executar a ação requerida. Tente novamente.'

  if (responseErr.response === undefined) {
    genericError = genericMsg
  } else if (responseErr.response.status === 500) {
    genericError = genericMsg
  // } else if (responseErr.response.status === 404) {
  //   genericError = genericMsg;
  } else if (responseErr.response.status === 401) {
    deleteAuthToken()
    window.location.href = `${routes.LOGIN}?sessionExpired`
  } else if (responseErr.response) {
    errorList = await handleApiErrors(responseErr.response.data)
    fieldError = errorList.fieldError
    sysError = errorList.sysError
    feedbackError = errorList.feedbackError

  }

  dispatch({
    type: ASYNC_ERROR,
    payload: {
      fieldError: fieldError,
      sysError: sysError,
      feedbackError,
      genericError
    },
  })
}

export const asyncRequest = () => {
  return { type: ASYNC_REQUEST }
}

export const asyncSuccess = () => {
  return { type: ASYNC_SUCCESS }
}

export const cleanErrorList = () => {
  return { type: ASYNC_CLEAN_ERRORS }
}

export const setGenericError = (message) => async dispatch => {
  dispatch({
    type: ASYNC_ERROR,
    payload: {
      genericError: message
    },
  })
}

export const cleanGenericError = () => async dispatch => {
  dispatch({
    type: ASYNC_ERROR,
    payload: {
      genericError: null
    },
  })
}