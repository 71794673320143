import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import { CheckBox } from 'components'

import { useLanguageData } from './hooks/useLanguageData'

import * as S from './styles'

export const LanguageData: React.FC = () => {
  const {
    languages,
    handleSelectLanguage
  } = useLanguageData()

  return (
    <div>
      <Typography variant="content1" weight='bold'>Idiomas de atendimento *</Typography>
      <S.WrapperLanguages>
        {languages.map(({id, icon, name, isSelected}) => {
          return (
            <CheckBox 
              key={id}
              title={`${icon} ${name}`}
              isSelected={isSelected}
              onClickCheckBox={() => handleSelectLanguage(id)}
              customIconColor={isSelected ? '#1F35B5' : '#4B4B4B'}
              indeterminate={false}
            />
          )}
        )}
      </S.WrapperLanguages>
    </div>
  )
}
