import { GET_PRICE  } from "../actions/_actionTypes";

import { updateState } from "../helpers/utilityHelpers";

const initialState = { 
  minPrice: 0,
  maxPrice: 2, 
};
const getPrice = (state, payload) => {
  return updateState(state, {
    maxPrice: payload.maxPrice,
    minPrice: payload.minPrice,
  });
};
  
const priceFilterReducer = (state = initialState, action) => {
  switch (action.type) {
  
    case GET_PRICE: return getPrice(state, action.payload);
    default:
      return state;
  }
};
export default priceFilterReducer;