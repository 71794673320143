import {
  EMAIL_REC_SUCESS,
  EMAIL_REC_REQUEST,
  EMAIL_REC_FAILURE,
} from '../actions/_actionTypes';

import { updateState } from '../helpers/utilityHelpers';

const initialState = {
  success: false,
  loading: false,
  errors: { 
    sysError: [],
    fieldError: [],
  },
};

/**
 * TODO:
 * Modificar funções quando a API estiver pronta
 */

const emailRecoveryFail = (state, { payload }) => {
  return updateState(state, { 
    success: false, 
    loading: false,
    errors: { ...payload },
  });
};

const emailRecoveryRequest = (state) => {
  return updateState(state, { 
    success: false, 
    loading: true,
    errors: { 
      sysError: [],
      fieldError: [],
    },  
  });
};

const emailRecoverySucess = (state, { payload }) => {
  return updateState(state, { 
    loading: false,
    success: true, 
    errors: { 
      sysError: [],
      fieldError: [],
    },
  });
};

const emailRecoveryReducer = (state=initialState, action) => {
  switch (action.type) {
    case EMAIL_REC_REQUEST: return emailRecoveryRequest(state);
    case EMAIL_REC_FAILURE: return emailRecoveryFail(state, action);  
    case EMAIL_REC_SUCESS: return emailRecoverySucess(state, action);
    default: return state;
  }
};

export default emailRecoveryReducer;
