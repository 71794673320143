import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface CancelationReasonInfoProps {
  cancelationReason: string
  cancelationDescription?: string
}

export const CancelationReasonInfo: React.FC<CancelationReasonInfoProps> = (cancelationReasonData) => {
  return (
    <S.CancelationReasonContainer>
      <Typography variant="content2" weight='bold'>Motivo do cancelamento:</Typography>
      <Typography variant="content2">{cancelationReasonData?.cancelationReason}</Typography>

      {cancelationReasonData?.cancelationDescription && (
        <Typography
          customClassName='cancelationDescription' 
          italic 
          variant="content2"
        >
          {cancelationReasonData?.cancelationDescription}
        </Typography>
      )}
    </S.CancelationReasonContainer>
  )
}