import { CLEAN_SKILLS, GET_SKILLS, SELECT_SKILLS } from '../actions/_actionTypes'
import { updateState } from '../helpers/utilityHelpers'

export const initialState = {
  childSkills: [],
  parentSkills: [],
  countSkills: 0,
  selectedSkills: [],
  subspecialties: []
}

export const cleanSkills = (state) => {
  return updateState(state, initialState)
}

const getSkills = (state, { childSkills, parentSkills, subspecialties }) => {
  return updateState(state, {
    childSkills,
    parentSkills,
    subspecialties
  })
}

const selectSkills = (state, { count, skills }) => {
  return updateState(state, {
    countSkills: count,
    selectedSkills: skills,
  })
}

const skillsReducer = (state = initialState, action) => {
  switch (action.type) {
  case CLEAN_SKILLS: return cleanSkills(state)
  case GET_SKILLS: return getSkills(state, action)
  case SELECT_SKILLS: return selectSkills(state, action)
  default: return state
  }
}

export default skillsReducer