import * as dateHandler from "../../../utils/dateTimeHandlers";

export const formatCartItems = (items) => {
  const formatedItems = items.map(item => {

    const itemDate = dateHandler.datetimeObject(item.schedule.start_date);
    const itemDuration = item.appointment.product.product_general.duration;
    const itemId = item.id;
    const itemPrice = formatFloatToReal(item.price);
    
    return {
      duration: itemDuration,
      id: itemId,
      price: itemPrice,
      ...itemDate,
    };
  });
  return formatedItems;
};

export const formatFloatToReal = (value) =>
  parseFloat(value)
    .toFixed(2)
    .toString()
    .replace('.', ',');
