import React, { Fragment, useEffect } from 'react'
import { Trans } from '@lingui/macro'
import { Typography, Spinner, CoreIcons, Button, PageTop } from 'components'
import FloatActionButtonMobile from '../../../../components/FloatActionButtonMobile'
import { useViewportInfo } from 'hooks'
import { useDispatch, useSelector } from 'react-redux'
import { getAllPlansGroups } from 'redux/actions'
import { PlanGroupItem } from './PlanGroupItem'
import { useHistory } from 'react-router-dom'
import * as routes from '../../../../settings/_routesSettings'

import './PlanGroupsList.sass'


const PlanGroupsList = () => {

  const { isDesktop } = useViewportInfo()

  const dispatch = useDispatch()
  const planGroups = useSelector(s => s.plans.allPlansGroups)
  const history = useHistory()

  useEffect(() => {
    dispatch(getAllPlansGroups())
  }, [dispatch])

  const onButtonClick = () => {
    history.push(routes.DASHBOARD_PLAN_GROUP_LIST_NEW)
  }


  const renderGroupPlans = () => {
    return planGroups.plan_groups.map((planGroup, i) =>
      <PlanGroupItem
        isLast={i === planGroups.plan_groups.length - 1}
        planGroup={planGroup}
        key={planGroup.id}
      />
    )
  }

  return (
    <div>
      <Fragment>
        {
          !isDesktop && (
            <FloatActionButtonMobile
              buttonId="new-professional"
              iconName="Positive"
              onClickFab={onButtonClick}
              type="primary"
              title={<Trans>ADICIONAR</Trans>}
            />
          )
        }
        <div className="ProfessionalsList__header" style={{ 'width': '100%' }}>
          <PageTop
            id="professionals-view-header"
            toHideOnMobile={
              <Typography align='mcenter-dleft' Tag='h1' type='title'>
                <Trans>Convênios</Trans>
              </Typography>
            }
            toShowOnMobile={
              <div className="ProfessionalsList__filters">
                <div className="PlanGroupsList__headerButton">
                  <Button
                    id="new-professional-button"
                    onButtonClick={onButtonClick}>
                    <CoreIcons name="Add"
                      height="14px"
                      width="14px"
                      fill="#FFF"
                      viewBox={'0 0 18 18'}
                    />
                    <div className="ProfessionalsList__buttonLabel">
                      Adicionar
                    </div>
                  </Button>
                </div>
              </div>
            } />
        </div>

        {!planGroups.plan_groups ?
          <Spinner height="80%" />
          :
          <>
            <div className="PlanGroupsList__CompaniesContainer">
              <h3 className="PlanGroupsList__Companies">
                Empresas
              </h3>
            </div>
            <div style={{ marginBottom: 40 }}>
              {renderGroupPlans()}
            </div>
          </>
        }

      </Fragment>

    </div>
  )
}

export { PlanGroupsList }