import styled, { css } from 'styled-components'

interface SlotTagProps {
  hasError?: boolean;
}

const DEFAULT_COLOR = '#BDBD77'
const ERROR_COLOR = '#FD4949'
const BACKGROUND_COLOR = '#FFFFB6'

const errorStyles = ({ hasError }: SlotTagProps) => css`
  border: 0.1rem solid ${hasError ? ERROR_COLOR : DEFAULT_COLOR};
  color: ${hasError ? ERROR_COLOR : DEFAULT_COLOR};

  & .slotTagTime {
    color: ${hasError ? ERROR_COLOR : DEFAULT_COLOR};
  }
`

export const Container = styled.div<SlotTagProps>`
  align-items: center;
  background-color: ${BACKGROUND_COLOR};
  border-radius: 50rem;
  display: flex;
  padding: 5px 5px 5px 10px;
  width: fit-content;
  z-index: 1;

  ${errorStyles}
`

export const IconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 5px;
  height: 24px;

  > button {
    height: 24px;
    padding: 0;
  }

  .iconSpinner {
    height: 22px;
    width: 22px;
    border-color: ${DEFAULT_COLOR};
    border-top-color: transparent;
    border-width: 3px;
  }
`
