import React from "react";

const MedicalRequest = ({
  fill,
  height = "128",
  viewBox = "0 0 165.346 128",
  width = "165.346",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#ededed" />
        <stop offset="0.268" stopColor="#fff" />
      </linearGradient>
    </defs>
    <g id="Grupo_1900" data-name="Grupo 1900" transform="translate(-79.503 -128.737)">
      <path id="Caminho_3001" data-name="Caminho 3001" d="M244.17,222.018c-2.924,23-22.129,40.792-39.271,48.344-17.894,8.133-33.73,6.03-47.317,2.677-13.633-4.169-28.384-8.305-43.8-15.933-14.886-7.445-30.434-18.383-33.47-31.929C76.49,211.518,86.58,201.983,101.642,196c15.238-6.245,35.448-8.935,55.941-15.043,20.447-5.128,41.74-11.123,59.457-4.9C234.361,181.447,248.106,199.057,244.17,222.018Z" transform="translate(0 -19.273)" fill="#ff9500" />
      <g id="Grupo_1918" data-name="Grupo 1918" transform="translate(113.916 128.737)">
        <g id="Grupo_1904" data-name="Grupo 1904">
          <path id="Caminho_3598" data-name="Caminho 3598" d="M184.262,264.769c13.64,3.355,29.54,5.459,47.5-2.678,1.773-.778,3.568-1.671,5.364-2.662v-102.7a2.491,2.491,0,0,0-2.5-2.485H143.109a2.49,2.49,0,0,0-2.5,2.485v92.257C155.972,256.514,170.67,260.627,184.262,264.769Z" transform="translate(-140.614 -139.742)" fill="#22cbd3" />
          <path id="Caminho_3599" data-name="Caminho 3599" d="M188.345,269.442c13.637,3.368,29.531,5.482,47.49-2.684V163.924H150.076v92.191C163.489,262.122,176.348,265.769,188.345,269.442Z" transform="translate(-144.697 -144.428)" fill="#fff" />
          <path id="Caminho_3600" data-name="Caminho 3600" d="M188.345,269.442c13.637,3.368,29.531,5.482,47.49-2.684V163.924H150.076v92.191C163.489,262.122,176.348,265.769,188.345,269.442Z" transform="translate(-144.697 -144.428)" fill="url(#linear-gradient)" />
          <g id="Grupo_1903" data-name="Grupo 1903" transform="translate(21.933)">
            <g id="Grupo_1902" data-name="Grupo 1902">
              <g id="Grupo_1901" data-name="Grupo 1901">
                <g id="Grupo_1900-2" data-name="Grupo 1900">
                  <path id="Caminho_3601" data-name="Caminho 3601" d="M215.066,135.869a2.808,2.808,0,0,1-2.686-2.02,7.159,7.159,0,0,0-13.719,0,2.808,2.808,0,0,1-2.688,2.019l-13.972,0a2.805,2.805,0,0,0-2.806,2.805v12.558c0,1.549,1.256,1.757,2.8,1.758l47.039.008c1.549,0,2.806-.208,2.806-1.757l0-12.557a2.8,2.8,0,0,0-2.8-2.806Z" transform="translate(-179.194 -128.737)" fill="#1f35b5" />
                </g>
              </g>
            </g>
            <path id="Caminho_3602" data-name="Caminho 3602" d="M179.2,166.42c.006,1.538,1.258,1.746,2.8,1.746l47.038.009c1.547,0,2.8-.208,2.805-1.753v-2.5H179.2Z" transform="translate(-179.195 -143.919)" fill="#6c83e8" />
          </g>
        </g>
        <path id="Caminho_3603" data-name="Caminho 3603" d="M226.25,139.336a3.106,3.106,0,1,1-3.106-3.106A3.106,3.106,0,0,1,226.25,139.336Z" transform="translate(-174.885 -131.97)" fill="#fff" />
        <g id="Grupo_1917" data-name="Grupo 1917" transform="translate(15.345 33.659)">
          <g id="Grupo_1906" data-name="Grupo 1906" transform="translate(0)">
            <g id="Grupo_1905" data-name="Grupo 1905">
              <circle id="Elipse_111" data-name="Elipse 111" cx="13.025" cy="13.025" r="13.025" fill="#22cbd3" />
            </g>
            <path id="Caminho_3604" data-name="Caminho 3604" d="M191.8,204.383h-4.785V199.6a.376.376,0,0,0-.376-.376h-2.7a.377.377,0,0,0-.377.376v4.785h-4.785a.376.376,0,0,0-.376.376v2.7a.376.376,0,0,0,.376.376h4.785v4.785a.377.377,0,0,0,.376.376h2.7a.376.376,0,0,0,.376-.376v-4.785H191.8a.376.376,0,0,0,.376-.376v-2.7A.376.376,0,0,0,191.8,204.383Z" transform="translate(-172.266 -193.083)" fill="#fff" />
          </g>
          <g id="Grupo_1911" data-name="Grupo 1911" transform="translate(33.909 0.693)">
            <g id="Grupo_1907" data-name="Grupo 1907" transform="translate(0)">
              <rect id="Retângulo_1104" data-name="Retângulo 1104" width="23.953" height="2.915" rx="1.458" fill="#22cbd3" />
            </g>
            <g id="Grupo_1908" data-name="Grupo 1908" transform="translate(0 7.382)">
              <path id="Caminho_3605" data-name="Caminho 3605" d="M255.328,205.544H228.71a1.458,1.458,0,0,1-1.457-1.458h0a1.457,1.457,0,0,1,1.457-1.457h26.618a1.457,1.457,0,0,1,1.458,1.457h0A1.458,1.458,0,0,1,255.328,205.544Z" transform="translate(-227.253 -202.629)" fill="#e5e5e5" />
            </g>
            <g id="Grupo_1909" data-name="Grupo 1909" transform="translate(0 14.565)">
              <rect id="Retângulo_1105" data-name="Retângulo 1105" width="23.953" height="2.915" rx="1.457" fill="#e5e5e5" />
            </g>
            <g id="Grupo_1910" data-name="Grupo 1910" transform="translate(0 21.747)">
              <rect id="Retângulo_1106" data-name="Retângulo 1106" width="29.532" height="2.915" rx="1.458" fill="#e5e5e5" />
            </g>
          </g>
          <g id="Grupo_1916" data-name="Grupo 1916" transform="translate(0 40.625)">
            <g id="Grupo_1912" data-name="Grupo 1912" transform="translate(0 21.973)">
              <path id="Caminho_3606" data-name="Caminho 3606" d="M209.858,301.448H169.064a1.457,1.457,0,0,1-1.457-1.458h0a1.457,1.457,0,0,1,1.457-1.457h40.794a1.458,1.458,0,0,1,1.458,1.457h0A1.458,1.458,0,0,1,209.858,301.448Z" transform="translate(-167.607 -298.533)" fill="#e5e5e5" />
            </g>
            <g id="Grupo_1913" data-name="Grupo 1913">
              <rect id="Retângulo_1107" data-name="Retângulo 1107" width="65.826" height="2.915" rx="1.457" fill="#e5e5e5" />
            </g>
            <g id="Grupo_1914" data-name="Grupo 1914" transform="translate(0 7.195)">
              <rect id="Retângulo_1108" data-name="Retângulo 1108" width="65.826" height="2.915" rx="1.457" fill="#e5e5e5" />
            </g>
            <g id="Grupo_1915" data-name="Grupo 1915" transform="translate(0 14.52)">
              <rect id="Retângulo_1109" data-name="Retângulo 1109" width="65.826" height="2.915" rx="1.457" fill="#e5e5e5" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default MedicalRequest;