// UTILS ACTIONS
const runLoader = () => {
    return {
        type: 'RUN_LOADER',
    }
}

const stopLoader = () => {
    return {
        type: 'STOP_LOADER',
    }
}

const setPagination = (data) => {
    return {
        type: 'SET_PAGINATION',
        payload: data,
    }
}

const updatePage = (page) => {
    return {
        type: 'UPDATE_PAGE'
    }
}

// -----------------------------------

const getSkills = () => {
    return {
        type: 'GET_SKILLS',
    }
};

const getSkillsSuccess = (themes) => {
    return {
        type: 'GET_SKILLS_SUCCESS',
        payload: themes
    }
};

const getSkillsFailure = () => {
    return {
        type: 'GET_SKILLS_FAILURE',
    }
};

const setActualTheme = (code) => {
    return {
        type: 'SET_ACTUAL_THEME',
        payload: code
    }
};

const setActualPropertyValue = (property, value) => {
    return {
        type: 'SET_ACTUAL_FILTER',
        payload: {
            property,
            value
        }
    }
};

const clearFilters = () => {
    return {
        type: 'CLEAR_FILTERS',
    }
};

// MOVE HOMEPAGE FILTER FOR THIS MODULE
const getPersons = () => {
    return {
        type: 'GET_PERSONS',
    }
}

const getPersonsSuccess = (data) => {
    return {
        type: 'GET_PERSONS_SUCCESS',
        payload: data
    }
}

const clearPersons  = () => {
    return {
        type: 'CLEAR_PERSONS',
    }
};

const addIds = (ids) => {
    return {
        type: 'ADD_IDS',
        payload: ids
    }
};

const clearIds  = () => {
    return {
        type: 'CLEAR_IDS',
    }
};

const getProfessionals = () => {
    return {
        type: 'GET_PROFESSIONALS',
    }
}

const clearProfessionals = () => {
    return {
        type: 'CLEAR_PROFESSIONALS',
    }
}

const getProfessionalsSuccess = (data) => {
    return {
        type: 'GET_PROFESSIONALS_SUCCESS',
        payload: {
            persons: data.persons,
            pagination: data.pagination
        }
    }
}

const getProfessionalsFailure = (data) => {
    return {
        type: 'GET_PROFESSIONALS_FAILURE',
    }
}
  
export {
    runLoader,
    stopLoader,
    setPagination,
    updatePage,
    addIds, 
    clearIds,
    getSkills,
    getSkillsSuccess,
    getSkillsFailure,
    setActualTheme,
    setActualPropertyValue,
    clearFilters,
    getPersons,
    getPersonsSuccess,
    clearPersons,
    getProfessionals,
    clearProfessionals,
    getProfessionalsSuccess,
    getProfessionalsFailure
}