import React from 'react'

const Help = ({
  height,
  viewBox = '0 0 24 24',
  width,
  fill = '#c1c1c1', 
}) => (
  <svg xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <path style={{fill: 'none'}} d="M0,0H24V24H0Z"/>
    <path style={{fill: fill}} d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm1,17H11V17h2Zm2.07-7.75-.9.92A3.4,3.4,0,0,0,13,15H11v-.5a4.025,4.025,0,0,1,1.17-2.83l1.24-1.26A1.955,1.955,0,0,0,14,9a2,2,0,0,0-4,0H8a4,4,0,0,1,8,0A3.182,3.182,0,0,1,15.07,11.25Z"/>
  </svg>
)

export default Help