import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import PropTypes from 'prop-types'
import Row from '../../../../components/Row'
import { Typography, Spinner, Column, TextInput, CoreIcons } from 'components'

import {  hideModal } from '../../../../redux/actions'
import NotificationStep from 'components/NotificationStep'
import ModalGeneric from '../ModalGeneric'
import InputValidation from 'utils/InputValidation'
import { EMAIL_NOT_EQUAL } from 'utils/ValidationErrorList'

import axios from '../../../../settings/axios'
import { apiEndpoints } from '../../../../settings/_apiSettings'
import { history } from '../../../../routers/AppRouter'

class EditEmailAccount extends Component {

  state = {
    loading: false,
    success: false,
    isConfirmMatch: false,
    email: '',
    emailIsValid: false,
    emailMsg: '',
    emailsMatch: true,
    retypeEmail: '',
    retypeEmailMsg: EMAIL_NOT_EQUAL,
    responseData: {},
    problemStatusCode: null,
  };

  onEmailChange = e => {
    let emailValue = e.target.value
    let validation = new InputValidation(emailValue, 'email').validate()

    this.setState({
      email: emailValue,
      emailMsg: validation.message,
      emailIsValid: validation.success
    })

    if (this.state.retypeEmail !== '')
      this.setState({
        emailsMatch: false,
        retypeEmail: ''
      })
  }

  onEmailConfirm = e => {
    let inputValue = e.target.value
    if (this.state.email === inputValue) {
      this.setState({
        emailsMatch: true,
        retypeEmail: inputValue,
        isConfirmMatch: true
      })
    } else {
      this.setState({
        emailsMatch: false,
        retypeEmail: inputValue,
        isConfirmMatch: false

      })
    }
  }

  readyToSubmit = () => {
    const { isConfirmMatch } = this.state
    let ready = false

    if(isConfirmMatch){
      ready = true
    }else{
      ready = false
    }

    return ready
  }

  onSubmit = async () => {
    const { email } = this.state
    const { emailUser } = this.props

    try {
      this.setState({ loading: true })
      const response = await axios.put(apiEndpoints.ACCOUNT_CHANGE_EMAIL(emailUser), { email })

      this.setState({ loading: false, success: true, responseData: response.data })
    } catch (err) {
      const { status } = err.response
      this.setState({ loading: false, success: false, problemStatusCode: status })
      console.error(err)
    }
  }

  onChangeEmailSuccess = () => {
    const { email } = this.state
    const { redirectURL, onCloseModal, profile } = this.props
    onCloseModal()
    history.push(redirectURL(email, profile))
  }

  onKeyPress = (e) => e.key === ' ' && e.preventDefault()

  renderUpdateSuccess = () => {
    const { responseData } = this.state
    return (
      <NotificationStep
        colWidth={12}
        buttonId="close-modal"
        btnText="FECHAR"
        mainText={
          <Row justify="center">
            <Typography bold
              align="center"
              type="heading2">
              <Trans>Endereço de e-mail alterado!</Trans>
            </Typography>
            <div className="editEmailAccount__data">
              <Typography align="center">
                <Trans>Endereço de e-mail anterior:</Trans>
              </Typography>
              <Typography align="center" bold>{responseData.old_email}</Typography>

            </div>
            <div className="editEmailAccount__data">
              <Typography align="center">
                <Trans>Endereço de e-mail atual:</Trans>
              </Typography>
              <Typography align="center" bold>{responseData.new_email}</Typography>
            </div>
            <div className="editEmailAccount__AlertSuccess">
              <p className="editEmailAccount__AlertSuccess--Text">Informe ao usuário que ele receberá a confirmação da alteração por SMS, no número de telefone cadastrado em sua conta.</p>
            </div>
          </Row>
        }
        onSuccessClick={this.onChangeEmailSuccess}
        iconColor="#4ac326"
        iconName="Check"
        hasButton
      />
    )
  }

  render() {
    const { emailUser, btnText, titleColor, classModifier, modalTitle, name } = this.props
    const {
      loading,
      success,
      email,
      emailIsValid,
      emailMsg,
      emailsMatch,
      retypeEmail,
      retypeEmailMsg,
      problemStatusCode,
    } = this.state

    return (
      <div className="editEmailAccount">
        <ModalGeneric
          hasButton={loading || success ? false : true}
          btnDisabled={!this.readyToSubmit()}
          btnText={btnText}
          modalTitle={success ? '' : modalTitle}
          modalSubtitle={success ? '' :
            <span>
              <Trans>de</Trans>
              <Typography color="grey-ten" Tag="span" bold> {name}</Typography>
            </span>
          }
          btnOnClick={this.onSubmit}
          classModifier={classModifier}
          titleColor={titleColor}
          extraClassName>
          {loading ?
            <Spinner height="auto" />
            :
            success ?
              this.renderUpdateSuccess()
              :
            <>
            <div className="editEmailAccount__actual-email">
              <Typography align="center">
                <Trans>Endereço de e-mail atual:</Trans>
              </Typography>
              <Typography align="center" bold>{emailUser}</Typography>
            </div>
              <div className="editEmailAccount__form-container">
                <form className="hasMarginBottom" onSubmit={this.onSubmit}>
                  <Row>
                    <Column>
                      <Trans>
                        Novo endereço de e-mail:
                      </Trans>
                      <TextInput
                        disabled={false}
                        name="email"
                        placeholder="Digite"
                        required={true}
                        type="email"
                        hasError={emailMsg}
                        messageError={emailIsValid ? '' : emailMsg}
                        onInputChange={(e) => this.onEmailChange(e)}
                        onKeyPress={this.onKeyPress}
                        value={email}
                      />
                      <Trans>
                        Confirme o novo endereço de e-mail:
                      </Trans>
                      <TextInput
                        disabled={false}
                        name="confirm_email"
                        placeholder="Digite"
                        required={true}
                        type="email"
                        hasError={!emailsMatch}
                        messageError={emailsMatch ? '' : retypeEmailMsg}
                        onInputChange={(e) => this.onEmailConfirm(e)}
                        value={retypeEmail}
                        canPaste={false}
                        onKeyPress={this.onKeyPress}
                      />
                      {!!problemStatusCode ?
                        <span className="editEmailAccount__error">
                          <CoreIcons
                            name="AlertIcon"
                            fill="#ff4949"
                          />
                          <Typography
                            color="red-lighter"
                            className="editEmailAccount__error--msg"
                          >
                            {problemStatusCode !== 409 
                              ? 'Ocorreu algum problema para concluir a ação. Tente novamente.'
                              : `O e-mail ${email} já está cadastrado. Tente novamente.`}
                          </Typography>
                        </span>
                        :
                        <>
                        </>
                      }
                    </Column>
                  </Row>
                </form>
              </div>
            </>
          }
        </ModalGeneric>
      </div>
    )
  }
}

EditEmailAccount.propTypes = {
  classModifier: PropTypes.string,
  btnDisabled: PropTypes.bool,
  btnText: PropTypes.any,
  hasButton: PropTypes.bool,
  modalTitle: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  name: PropTypes.string,
  redirectURL: PropTypes.func
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return { onCloseModal: () => dispatch(hideModal()) }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditEmailAccount)
