import React from 'react';
import PropTypes from 'prop-types';

import { toDayMonthYear } from '../../utils/dateTimeHandlers';

import Row from '../Row';
import { Typography, Column } from 'components';

const CartPlansCredit = ({
  currentCredit,
  totalCredits,
  expiryDate,
}) => {
  return (
    <div className="CartPlansCredit">
      <Row margin='0 0 1.5rem'>
        <Typography type="subtitle">
          Seus créditos:
        </Typography>
      </Row>

      <Row wrap="no-wrap">
        <div className="CartPlansCredit__credit--header">
          <Typography>Consultas disponíveis:</Typography>
        </div>

        <div className="CartPlansCredit__credit--info">
          <Typography align="right" bold>{`${currentCredit} de ${totalCredits}`}</Typography>
        </div>
      </Row>

      <Row wrap="no-wrap">
        <Column colWidth={6}>
          <Typography>Válido até:</Typography>
        </Column>

        <Column colWidth={6}>
          <Typography align="right">
            {toDayMonthYear(expiryDate)}
          </Typography>
        </Column>
      </Row>
    </div>
  );
};

CartPlansCredit.propTypes = {
  currentCredit: PropTypes.number,
  expiryDate: PropTypes.any,
  totalCredits: PropTypes.number,
};

CartPlansCredit.defaultProps = {
  currentCredit: 3,
  expiryDate: new Date(),
  totalCredits: 6,
};

export { CartPlansCredit };