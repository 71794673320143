import React from "react";

const Schedule = ({
  fill = "#c1c1c1",
  height = "40",
  viewBox = "0 0 40 40",
  width = "40",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Caminho_2167" data-name="Caminho 2167" d="M32.857,20.421V3.571A3.573,3.573,0,0,0,29.286,0H6.429A3.573,3.573,0,0,0,2.857,3.571V6.429H2.143a2.143,2.143,0,0,0,0,4.286h.714v5H2.143a2.143,2.143,0,1,0,0,4.286h.714v5H2.143a2.143,2.143,0,1,0,0,4.286h.714v2.857a3.573,3.573,0,0,0,3.571,3.571H21.807a9.994,9.994,0,1,0,11.05-15.293ZM1.429,8.571a.716.716,0,0,1,.714-.714H5.714a.714.714,0,0,1,0,1.429H2.143A.716.716,0,0,1,1.429,8.571Zm0,9.286a.716.716,0,0,1,.714-.714H5.714a.714.714,0,0,1,0,1.429H2.143A.716.716,0,0,1,1.429,17.857Zm0,9.286a.716.716,0,0,1,.714-.714H5.714a.714.714,0,1,1,0,1.429H2.143A.716.716,0,0,1,1.429,27.143Zm5,7.143a2.149,2.149,0,0,1-2.143-2.143V29.286H5.714a2.143,2.143,0,1,0,0-4.286H4.286V20H5.714a2.143,2.143,0,1,0,0-4.286H4.286v-5H5.714a2.143,2.143,0,0,0,0-4.286H4.286V3.571A2.149,2.149,0,0,1,6.429,1.429H29.286a2.149,2.149,0,0,1,2.143,2.143V20.114A9.4,9.4,0,0,0,30,20,10.011,10.011,0,0,0,20,30a9.906,9.906,0,0,0,.979,4.286ZM30,38.571A8.571,8.571,0,1,1,38.571,30,8.578,8.578,0,0,1,30,38.571ZM35.714,30a.716.716,0,0,1-.714.714H30A.716.716,0,0,1,29.286,30V24.286a.714.714,0,1,1,1.429,0v5H35A.716.716,0,0,1,35.714,30Z" transform="translate(0 0)" fill={fill} />
  </svg>
);


export default Schedule;
