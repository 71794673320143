import { VIEWPORTS } from './../settings/_generalSettings';

function useViewportInfo() {
    const currentWidth = window.innerWidth;

    return {
        isMobile: currentWidth <= VIEWPORTS.DESKTOP,
        isDesktop: currentWidth >= VIEWPORTS.DESKTOP
    };
}

export { useViewportInfo }