import React from "react";

const ArrowFab = ({
  fill = "##4b4b4b",
  height = "35px",
  viewBox = "0 -10 49.369 15.649",
  width = "49px",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} opacity=".5" transform="rotate(175 24.3877584 7.87424855)">
      <g transform="translate(.169 2.169)">
        <path d="M25.776 14.493a49.643 49.643 0 0 0 22.082-5.5.747.747 0 0 0 .273-.951.6.6 0 0 0-.858-.3c-.261.147-26.073 14.517-46.224-4.493a.594.594 0 0 0-.9.077.757.757 0 0 0 .07.993 36.21 36.21 0 0 0 25.557 10.174z" transform="translate(0 -3.079)" />
      </g>
      <g transform="translate(0 .002)">
        <path d="M64.313 13.646a.812.812 0 0 0 .183-.021.71.71 0 0 0 .5-.866L62.468 3.4l8.505-2.014a.7.7 0 1 0-.324-1.364l-9.927 2.337 2.908 10.766a.72.72 0 0 0 .683.521z" transform="translate(-60.722 -.002)" />
      </g>
    </g>
  </svg>
);

export default ArrowFab;