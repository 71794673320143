import Button from 'components/Button';
import CoreIcons from 'components/CoreIcons';
import Spinner from 'components/Spinner';
import React from 'react';
import './FileCard.sass'
import { toDayMonthYear, getTimeH } from '../../utils/dateTimeHandlers';

const FileCard = ({ title, subtitle, userUpdatedInfo, onClickUploadButton, onClickDownloadButton }) => {

  const updatedAt = new Date(userUpdatedInfo && userUpdatedInfo.updated_at ? userUpdatedInfo.updated_at : null);

  return (
    <div className="FileCard">
      <p className="FileCard__Title">{title}</p>
      <p className="FileCard__Subtitle">{subtitle}</p>
      { userUpdatedInfo.updated_at !== undefined ?
        <>
          <div className={`FileCard__UploadArea${userUpdatedInfo.updated_at ? '--row' : ''}`}>
            {userUpdatedInfo.updated_at ?
              <>
                <div className="FileCard__UploadArea__Col">
                  <CoreIcons
                    name="FileUsers"
                    fill="#1f35b5"
                    height="57.995"
                    width="46"
                  />
                </div>
                <div className="FileCard__UploadArea__Col">
                  <div className="FileCard__UploadArea__Col__LastUpdate">Última atualização</div>
                  <div className="FileCard__UploadArea__Col__Date"><b>{toDayMonthYear(updatedAt)}</b></div>
                  <div className="FileCard__UploadArea__Col__Time">às {getTimeH(updatedAt)}</div>
                  <div className="FileCard__UploadArea__Col__UpdateInfo">
                    <div>Por {userUpdatedInfo?.updated_by?.full_name || ""}</div>
                    <div>{userUpdatedInfo?.updated_by?.email || ""}</div>
                  </div>
                </div>
                <div className="FileCard__UploadArea__Col">
                  <Button
                    text="BAIXAR"
                    classModifier="reverse"
                    extraClass="FileCard__UploadArea__Button"
                    onButtonClick={() => onClickDownloadButton()}
                  />
                </div>
              </>
              :
              <>
                <span className="FileCard__UploadArea__EmptyMessage">Nenhuma lista adicionada</span>
                <Button
                  text="BAIXAR MODELO"
                  classModifier="reverse"
                  extraClass="FileCard__UploadArea__Button"
                  onButtonClick={() => onClickDownloadButton()}
                />
              </>
            }

          </div>
          <Button
            text="INSERIR LISTA DE BENEFICIÁRIOS"
            onButtonClick={onClickUploadButton}
          />
          <a 
            href="#beneficiarios" 
            download="beneficiarios.csv" 
            id="file-download"
            className="FileCard__Downloadlink"
          >Download</a>
        </>
        :
        <Spinner height="120px" />
      }
    </div>
  )
}

export {
  FileCard
}