import React, { Component } from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { Typography, Spinner } from 'components'
import viewportHoc from '../../../../hoc/viewportHoc'
import { connect } from 'react-redux'
import { hideModal } from '../../../../redux/actions'
import * as actions from '../../../../redux/actions'
import { Trans } from '@lingui/macro'
import { getTime, toDayMonthYear } from '../../../../utils/dateTimeHandlers'
import { INVALIDATED } from 'settings/_personSettings'

class ModalRepproveDetails extends Component {
  state = {
    isLoading: false,
    details: null,
    detailsError: false
  };

  componentDidMount() {
    this.getDetails()
  }

  getDetails = async () => {
    await this.setState({ isLoading: true })
    await this.props.onGetDisapproveDetails(this.props.requestId)
    if(this.props.details){
      await this.setState({
        isLoading: false,
        details: this.props.details
      })
    }
    if(this.props.detailsError){
      await this.setState({
        isLoading: false,
        detailsError: true
      })
    }
  };

  isInvalidate = (statusCode) => {
    return statusCode === INVALIDATED
  }

  getDateCreatedFormatted = (date) => {
    if(date){
      return `${toDayMonthYear(date)}, às ${getTime(date).replace(':', 'h')}`
    }
    return ''
  }

  render() {
    const { onHideModal } = this.props

    const { isLoading, details, detailsError } = this.state

    return (
      <Modal
        modalTitle={`Informações da ${this.isInvalidate(this.props.statusCode) ? 'invalidação' : 'reprovação'}`}
        titleColor="false"
        closeModal={() => onHideModal()}
        btnId="repprove-details"
        hasButton={false}
        extraClassName
      >
        {
          isLoading ?
            <Spinner height="250px" />
            :
            detailsError ?
              <div className="Container">
                <div className="Container_textbox">
                  <Typography align="center">
                    <Trans>Nenhum resultado encontrado</Trans>
                  </Typography>
                </div>
              </div>
              :
              <div className="Container">
                <div className="Container_textbox">
                  <Typography align="left" bold><Trans>Paciente</Trans>:</Typography>
                  <Typography align="left">{details?.person_profile_plan?.person_profile?.person?.person_general?.full_name || ''}</Typography>
                </div>
                <div className="Container_textbox">
                  <Typography align="left" bold><Trans>Carteirinha</Trans>:</Typography>
                  <Typography align="left">{details?.person_profile_plan?.card_number || ''}</Typography>
                </div>
                <div className="Container_textbox">
                  <Typography align="left" bold>
                    {`Data e horário da ${this.isInvalidate(this.props.statusCode) ? 'interrupção' : 'reprovação'}:`}
                  </Typography>
                  <Typography align="left">{this.getDateCreatedFormatted(details?.date_created || '')}</Typography>
                </div>
                <div className="Container_textbox">
                  <Typography align="left" bold><Trans>Motivo</Trans>:</Typography>
                  <Typography align="left">{details?.reason?.name || ''}</Typography>
                </div>
                <div className="Container_textbox">
                  <Typography align="left" bold><Trans>Detalhes</Trans>:</Typography>
                  <Typography align="left">{details?.details || ''}</Typography>
                </div>
                <div className="Container_textbox">
                  <Typography align="left" bold><Trans>Atendente</Trans>:</Typography>
                  <Typography align="left">{details?.attendant?.full_name || ''}</Typography>
                </div>
              </div>
        }
      </Modal>
    )
  }
}

ModalRepproveDetails.propTypes = {
  requestId: PropTypes.number,
  statusCode: PropTypes.string
}

const mapStateToProps = state => {
  return {
    details: state.medicalRequests.details,
    detailsError: state.medicalRequests.detailsError
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onGetDisapproveDetails: (requestid) => dispatch(actions.getDisapproveDetails(requestid)),
    onHideModal: () => dispatch(hideModal()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(ModalRepproveDetails))
