import axios from '../settings/axios'
import { apiEndpoints } from '../settings/_apiSettings'

interface PlanData {
  code: string
    has_validity: boolean
    id: number
    is_particular: boolean
    name: string
    plan_group: {
      allows_reservation: boolean
      category: string
      code: string
      credit_renew: boolean
      has_evaluation: boolean
      id: number
      logo_url: null | string
      max_appointment: number
      name: string
      self_register: boolean
      use_atena: boolean
      validity_expiry_days: number
      validity_renew: boolean
    }
    tier: number
}

interface GetPlansData {
  plans: Array<PlanData>
}

interface Response {
  data: GetPlansData
}

export const getPlans = (
  profileRole: string
): Promise<GetPlansData> => {

  const params = {
    ...(profileRole && { profile_role: profileRole }),
  }
  
  const endpoint = apiEndpoints.PLANS

  return new Promise <GetPlansData> (resolve => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        resolve({
          plans: response.data.plans
        } as GetPlansData)
      })
      .catch(err => {
        console.error(err)
      })
  })
}