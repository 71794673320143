import { updateReservation, UpdateReservationProps } from './updateReservation'
import { deleteAllReservation } from './deleteReservation'
import { getReservations } from './getReservations'

export default {
  updateReservation,
  deleteAllReservation,
  getReservations
}

export type { UpdateReservationProps }