import { Typography } from '@telavita-core/react-design-kit'
import React from 'react'

const DayHeader = ({
    day
}) => {
    return (
        <div className='ProfessionalReservations__dayHeader'>
            <Typography variant='content2' weight='bold'>
                {day}
            </Typography>
        </div>
    )
}

export { DayHeader }
