import { Tooltip, Typography } from '@telavita-core/react-design-kit'
import React from 'react'

import { ReactComponent as InfoFilledIcon } from 'static/svg/icon-info-filled.svg'

import { 
  Container, 
  Icon,
  Label,
  LabelWrapper,
  StatisticTitle 
} from './styles'

interface IResumeSectionProps {
  availabilitySlotsTotal: number
  schedulesReceivedTotal: number
}

function formatOccupancyRate(value: string) {
  const replaceDotForComma = value?.replace('.', ',')

  return `${replaceDotForComma}%`
}

function calculateOccupancyRate(value: number, total: number) {
  const calculatedPercentage = (value / total) * 100
  const isInfinity = calculatedPercentage === Infinity

  if (isNaN(calculatedPercentage) || isInfinity) {
    return formatOccupancyRate('0')
  }

  return formatOccupancyRate(calculatedPercentage.toFixed(1))
}

export const ResumeSection = ({
  availabilitySlotsTotal,
  schedulesReceivedTotal
}: IResumeSectionProps): JSX.Element => {
  return(
    <Container>
      <div>
        <StatisticTitle>
          {availabilitySlotsTotal}
        </StatisticTitle>
        <Label>
          Horários disponibilizados
        </Label>
      </div>

      <div>
        <StatisticTitle>
          {schedulesReceivedTotal}
        </StatisticTitle>
        <Label>
          Agendamentos recebidos
        </Label>
      </div>

      <div>
        <StatisticTitle>
          {calculateOccupancyRate(schedulesReceivedTotal, availabilitySlotsTotal)}
        </StatisticTitle>

        <LabelWrapper>
          <Label>
            Taxa de ocupação
          </Label>
          <Tooltip
            content={
              <Typography
                variant='content3'
              >
                Percentual de agendamentos recebidos sobre o total de horários disponibilizados.
              </Typography>
            }
            arrow
            theme='info'
            placement='bottom'
            maxWidth={200}
          >
            <Icon>
              <InfoFilledIcon />
            </Icon>
          </Tooltip>
        </LabelWrapper>
      </div>

    </Container>
  )
}