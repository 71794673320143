import { getDay } from 'date-fns'
import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

const days = {
  0: 'Domingos',
  1: 'Segundas-feiras',
  2: 'Terças-feiras',
  3: 'Quartas-feiras',
  4: 'Quintas-feiras',
  5: 'Sextas-feiras',
  6: 'Sábados'
}

const groupReservationsByWeekday = reservations => {
  const filteredReservations = reservations.reduce((prev, curr) => {
    const reservationWeekday = days[getDay(curr.start_date)]
    
    if (prev[reservationWeekday]) {
      prev[reservationWeekday] = [...prev[reservationWeekday], curr]
    } else {
      prev[reservationWeekday] = [curr]
    }

    return prev
  },{})

  return filteredReservations
}

const getReservations = ({
  username,
  isProfessional
}, groupByWeekday = false) => {
  return new Promise((resolve, reject) => {
    axios.get(apiEndpoints.RESERVATIONS(username, isProfessional))
      .then(resp => {
        const { reservations } = resp.data

        if (reservations) {
          let data = reservations.map(reservation => ({
            uuid: reservation.reservation_uuid,
            start_date: new Date(reservation.start_date),
            creation_date: new Date(reservation.reservation_creation_date),
            patient_username: reservation.patient_username,
            patient_full_name: reservation.patient_full_name,
            patient_cellphone: reservation.patient_cellphone,
            professional_username: reservation.professional_username,
            professional_full_name: reservation.professional_full_name,
            professional_cellphone: reservation.professional_cellphone,
            recurrence: reservation.recurrence,
            recurrency_exceptions: reservation.recurrency_exceptions.map(recurrencyException => ({
              exceptionDate: recurrencyException.exception_date_utc,
              exceptionType: recurrencyException.exception_type,
              nextAvailableDate: recurrencyException?.next_available_date
            }))
          }))

          if (groupByWeekday) resolve(groupReservationsByWeekday(data))
          else resolve(data)
        }
      })
      .catch(e => {
        console.log(e)
        reject(e.response)
      })
  })
}

export { getReservations }