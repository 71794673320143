import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import Sticky from 'react-sticky-el'
import { useDimensions, Typography as Typo } from '@telavita-core/react-design-kit'

// STYLE
import './GeneralView.sass'

// STORE
import {
  generalViewPacientsRequest,
  generalViewSchedulesRequest,
  generalViewAppointements,
  generalViewRecomendation } from 'redux/modules/generalView/requests'
import { applyFilters } from 'redux/modules/generalView/actions'
import * as actions from '../../../../redux/actions'

// COMPONENTS
import { Trans } from '@lingui/macro'
import { CoreIcons, GeneralViewCard, ChartCard } from 'components'
import { modalTypes } from '../../../../containers/ModalManager/ModalManager'
import { FileCard } from 'components/FileCard/FileCard'
import Row from 'components/Row'
import { getEligiblesBeneficiaresFile } from 'services/beneficiariesService'

const GeneralView = () => {
  // STORE
  const { isMobile } = useDimensions()
  const auth = useSelector((state) => state.auth)
  const generalView = useSelector((state) => state.generalView)
  const eligiblesUpdateInfo = useSelector((state) => state.plans.eligiblesUpdateInfo)
  const partnerPlanGroupName = useSelector((state) => state.auth.planSelected.plan_group.name)
  const planGroup = useSelector((state) => state.auth.profileSelected.plans.find(plan => plan.default === true))
  const planGroupCode = planGroup.plan_group.code
  const planGroupHasEligibles = planGroup.plan_group.use_eligibles_csv
  const pacients = generalView.pacients
  const schedules = generalView.schedules
  const initialDate = generalView.filters.initialDate
  const finalDate = generalView.filters.finalDate
  const filteredDiffDays = generalView.filters.filteredDiffDays
  const appointmentsChart = generalView.appointmentsChart
  const recomendationsChart = generalView.recomendationsChart
  const prevInitialDate = generalView.filters.prevInitialDate
  const prevFinalDate = generalView.filters.prevFinalDate

  // LOCAL
  const filterSticky = { top: '0', zIndex: '3' }
  const today = moment().endOf('day').toJSON()
  const lastWeek = moment().startOf('day').subtract(7, 'days').toJSON()
  const dispatch = useDispatch()
  const [onPage, handleOnPage] = useState(false)
  const requestParams = {
    profile_role: 'PACI',
    start_date__gte: onPage ? moment(initialDate).startOf('day').toJSON() : lastWeek,
    start_date__lte: onPage ? moment(finalDate).endOf('day').toJSON() : today
  }

  // METHOD TO ORDER CONSULTAS CHART DATA BY AN SPECIFIC PROP
  function orderArr (arr, prop) {
    const finalArr = [0, 0, 0, 0, 0, 0]
    // ORDER -> Agendadas, Realizadas, Atendendo, Não realizadas, canceladas, Devolvidas
    const labelsOrder = [ 'STS21002', 'STS21005', 'STS21007', 'STS21006', 'STS21010', 'STS21009', 'STS21003', 'STS21008']

    arr.map(item => finalArr.splice(labelsOrder.indexOf(item[prop]), 1, item))

    return finalArr
  }

  // RENDERS TOPBAR PERIOD DATES OR DIFF DAYS
  function renderPeriodDates () {
    if (filteredDiffDays === 7 || filteredDiffDays === 30 || filteredDiffDays === 60 || filteredDiffDays === 90) {
      return ( <p className="GeneralView__filterValue">Últimos {filteredDiffDays} dias</p> )
    } else {
      if (prevInitialDate.length && !prevFinalDate.length) return <p className="GeneralView__filterValue">{moment(initialDate).format('DD/MM/YYYY')}</p>
      else if (!prevInitialDate.length && prevFinalDate.length) return <p className="GeneralView__filterValue">{moment(finalDate).format('DD/MM/YYYY')}</p>
      else return <p className="GeneralView__filterValue">{moment(initialDate).format('DD/MM/YYYY')} até {moment(finalDate).format('DD/MM/YYYY')}</p>
    }
  }

  // METHOD TO REQUEST CHARTS DATA
  async function loadChartsData () {
    await dispatch(generalViewPacientsRequest(auth.userToken, requestParams)) // load total pacients data - card
    await dispatch(generalViewSchedulesRequest(auth.userToken, requestParams)) // load total appointments data - card
    await dispatch(generalViewAppointements(auth.userToken, requestParams)) // load appointments data - doughnut chart
    await dispatch(generalViewRecomendation(auth.userToken, requestParams)) // load experience recom. data - bars chart
  }

  // METHOD TO REQUEST ELIGIBLES DATA
  async function loadEligiblesData () {
    await dispatch(actions.getPlanGroupEligibles(planGroupCode))
  }

  // METHOD TO REQUEST ELIGIBLES FILE
  function downloadEligiblesFile () {
    getEligiblesBeneficiaresFile(planGroupCode)
      .then(response => {
        let csvContent = decodeURIComponent(escape(atob(response.file)))
        let blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8'})
        let url  = window.URL.createObjectURL(blob)
        let downloadLink = document.getElementById('file-download')
        downloadLink.href = url
        downloadLink.click()
      })
  }

  useEffect(() => {
    // DID MOUNT
    if (!onPage) dispatch(applyFilters({ filteredDiffDays: 7, prevInitialDate: '', prevFinalDate: '' }))
    if (!onPage && !appointmentsChart.loading && !appointmentsChart.error) handleOnPage(true)

    // get data
    loadChartsData()
    planGroupHasEligibles && loadEligiblesData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, finalDate])

  return (
    <section className="GeneralView">
      <Row className="Dashboard__Header">
        <Typo variant={!isMobile ? 'header2' : 'header4'}>
          Visão geral - {partnerPlanGroupName}
        </Typo>
      </Row>
      <Sticky stickyStyle={filterSticky}>
        <div className="GeneralView__topbar">
          <div className="GeneralView__filters">
            {renderPeriodDates()}
            <p className="GeneralView__filterValue GeneralView__filterValue--planValue"><Trans>Todos os planos</Trans></p>
          </div>
          <span className="GeneralView__filterIcon" onClick={() => dispatch(actions.showModal(modalTypes.GENERAL_VIEW_FILTERS))}>
            <CoreIcons
              fill="#1F35B5"
              height="24px"
              name="Filters"
              width="34px"
              viewBox="0 0 24 24"/>
          </span>
        </div>
      </Sticky>
      <div className="GeneralView__content">
        {/* CARDS AREA */}
        <div className="GeneralView__content GeneralView__content--cards">
          <div className="GeneralView__content__column">
            <GeneralViewCard
              spaced
              loading={pacients.loading || !auth.userToken}
              error={pacients.error}
              label="Pacientes atendidos neste período"
              total={pacients.total}
              partial={pacients.partial}/>
          </div>
          <div className="GeneralView__content__column">
            <GeneralViewCard
              loading={schedules.loading || !auth.userToken}
              error={schedules.error}
              label="Consultas feitas neste período"
              total={schedules.total}
              partial={schedules.partial}/>
          </div>
        </div>

        {/* FIRST CHARTS GROUP */}
        <div className="GeneralView__content GeneralView__content--charts">
          <div className="GeneralView__content__column">
            <ChartCard
              spaced correct
              loading={appointmentsChart.loading}
              type="doughnut"
              title="Consultas"
              link="Ver lista de consultas"
              emptyMessage="Sem dados"
              total={appointmentsChart.total}
              data={appointmentsChart.error ? [0] : orderArr(appointmentsChart.data.map(item => item), 'status_code').map(item => item.partial)}
              percents={appointmentsChart.error ? [0] : orderArr(appointmentsChart.data.map(item => item), 'status_code').map(item => item.percentage)}
              colors={['#1F35B5', '#4AC326', '#F9A435', '#FFD800', '#FD4949', '#BF1D1D', '#898989', '#D5D5D5']}
              labels={appointmentsChart.error ? ['Nada'] : orderArr(appointmentsChart.data.map(item => item), 'status_code').map(item => item.status_name)}/>
          </div>
          <div className="GeneralView__content__column">
            <ChartCard
              hideLabels bottom solo
              loading={recomendationsChart.loading}
              type="bar"
              colors={['#EB2D2D', '#FF4949', '#FF6549', '#FF7349', '#FF9649', '#FFB249', '#FFCE49', '#C5DB1B', '#47DB1B', '#3AAD17', '#268909']}
              emptyMessage="Nenhuma resposta nesse período"
              title="Recomendação da experiência"
              total={recomendationsChart.total}
              data={recomendationsChart.error ? [0] : recomendationsChart.data.map(item => item.partial)}
              labels={recomendationsChart.error ? ['Nada'] : recomendationsChart.data.map(item => item.score)}
            />
            {
              planGroupHasEligibles &&
              <FileCard
                title="Lista de beneficiários"
                subtitle="Administre quem possui direito às consultas"
                loading={recomendationsChart.loading}
                userUpdatedInfo={eligiblesUpdateInfo}
                onClickDownloadButton={() => downloadEligiblesFile()}
                onClickUploadButton={() => dispatch(actions.showModal(modalTypes.FILE_BENEFICIARIES, {
                  planGroupCode,
                  onSuccessUpdate: () => {
                    dispatch(actions.clearPlanGroupEligiblesFile())
                    loadEligiblesData()
                  }
                }))}
              />
            }
          </div>
        </div>

        {/* SECOND CHARTS GROUP */}
        {/* <div className="GeneralView__content GeneralView__content--charts"></div> */}
      </div>
    </section>
  )
}

export {
  GeneralView
}
