import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { ToastContainer } from '@telavita-core/react-design-kit'


import ModalPortal from './containers/ModalPortal'
import { Spinner } from './components'

import AppRouter from './routers/AppRouter'
import { getAuthToken } from 'utils/authHelper'
import * as actions from './redux/actions'

import './app.sass'

const App = props => {
  const [loading, setLoading] = useState(true)
  const { authUser } = props

  const isAutenticated = useCallback(async () => {
    const userToken = getAuthToken()
    if (userToken !== '' && userToken !== null) await authUser(userToken)

    setLoading(false)
  }, [authUser])

  useEffect(() => {
    isAutenticated()
  }, [isAutenticated])

  return (
    <div>
      <ModalPortal />
      <ToastContainer
        newestOnTop
        theme='colored'
      />
      {
        loading ? <Spinner /> : (
          <AppRouter {...props} />
        )
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => ({ authUser: userToken => dispatch(actions.authUser(userToken)) })

export default connect(null, mapDispatchToProps)(App)
