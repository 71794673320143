import React from "react";

const SexInfinite = ({
  fill,
  width="47.16",
  height="24.01",
  viewBox="0 0 47.16 24.01",
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g  transform="translate(0 0)">
      <defs>
        <clipPath id="a"><rect fill='none' width="47.16" height="24.01"/>
        </clipPath>
      </defs>
      <path fill={fill} d="M37,13.45v.28h0v-.28a11,11,0,0,0-5.75,1.91,26.06,26.06,0,0,0-5.37,5.56c-.14.17-1.36,1.82-2.72,3.62s-2.84,3.73-3.44,4.37a18.56,18.56,0,0,1-2.84,2.95,6.8,6.8,0,0,1-3,1.15h0A7.519,7.519,0,0,1,8.71,20.23,7.37,7.37,0,0,1,13.81,18h0a5.65,5.65,0,0,1,2.7,1.16,19.64,19.64,0,0,1,4.05,3.91l.23.29,2.86-3.83-.15-.17c-.2-.23,0-.09-.25-.32a21.82,21.82,0,0,0-3.74-3.67,11,11,0,0,0-5.73-1.91,12,12,0,1,0,0,24,11,11,0,0,0,5.73-1.91A26,26,0,0,0,24.91,30c.13-.17,1.35-1.82,2.71-3.62s2.84-3.73,3.44-4.37a19.44,19.44,0,0,1,3.21-2.88A5.51,5.51,0,0,1,36.88,18h0a7.42,7.42,0,0,1,7.34,7.41,7.6,7.6,0,0,1-2.15,5.31A7.41,7.41,0,0,1,36.91,33h0a6.92,6.92,0,0,1-3.07-1.22,19.14,19.14,0,0,1-3.78-4l-.23-.3L26.97,31.3l.15.16c.2.23.14.19.35.42a21.92,21.92,0,0,0,3.74,3.67,11,11,0,0,0,5.73,1.91,12,12,0,0,0,12-12A12.37,12.37,0,0,0,37,13.45Z" transform="translate(-1.78 -13.45)"/>
    </g>
    
  </svg>
);

export default SexInfinite;
