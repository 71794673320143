import React, { Component } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { CoreIcons } from 'components';

class RadioToggle extends Component {
  state = { selected: this.props.selected }

  componentWillReceiveProps(nextProps) {
    this.setState({ selected: nextProps.selected });
  }

  handleToggle = (e) => {
    e.preventDefault();

    this.setState({ selected: !this.state.selected }, function() {
      // IF THIS METHOD IS PASSED VIA PROPS
      // UPDATE PARENT STATE CONTROL OF THIS CHILD (COMPONENTE ITSELF)
      if (this.props.onClickRadioToggle) {
        this.props.onClickRadioToggle(this.state.selected);
      }
      // IF THIS METHOD IS PASSED VIA PROPS
      // UPDATE PARENT STATE CONTROL OF THIS CHILD (COMPONENTE ITSELF)
    });
  }

  render() {
    const {
      disabled,
      iconWidth,
      iconHeight,
      onHandleToggle,
      parentControl,
      radioId,
      title,
    } = this.props;

    const { selected } = this.state;

    const icon = selected ? 'Check' : 'RadioUnselected';
    const iconViewBox = selected ? `0 0 20 20` : `2 2 20 20`;
    const fill = selected ? '#fff' : "#c4c4c4";

    return (
      <div className={`RadioToggle ${selected ? 'active' : ''}`}
        onClick={parentControl ? onHandleToggle : this.handleToggle}
        id={radioId}>
        <div className={`RadioToggle__text ${disabled ? 'disabled' : ''}`}>
          {title}
        </div>

        <div className="RadioToggle__icon">
          <CoreIcons name={icon}
            fill={fill}
            width={iconWidth ? iconWidth : '20px'}
            height={iconHeight ? iconHeight : '20px'}
            viewBox={iconViewBox}
          />
        </div>
      </div>
    );
  }
}

RadioToggle.propTypes = {
  disabled: PropTypes.bool,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
  iconViewBox: PropTypes.string,
  radioId: PropTypes.string,
  onClickRadio: PropTypes.func,
  onHandleToggle: PropTypes.func,
  parentControl: PropTypes.bool,
  selected: PropTypes.bool,
  title: PropTypes.any,
  value: PropTypes.any,
};

RadioToggle.defaultProps = {
  disabled: false,
  iconWidth: '20px',
  iconHeight: '20px',
  iconViewBox: '2 2 20 20',
  parentControl: false,
  selected: false,
  title: 'Marcar',
};

export default RadioToggle;