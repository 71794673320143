import React from 'react'

const patientExceptions = {
  'professional_unavailability': {
    tooltipContent: <>O profissional não está disponível. <br /> Nesta data, você deve agendar em <br /> qualquer outro horário disponível.</>
  },
  'cancelled_by_patient': {
    tooltipContent: <>Você cancelou a reserva deste dia.</>
  },
  'cancelled_by_professional': {
    tooltipContent: <>O psicólogo cancelou a reserva deste dia.</>
  }
}

const adminExceptions = {
  ...patientExceptions,
  'professional_unavailability': {
    tooltipContent: <>O profissional não estará disponível. <br /> Excepcionalmente nesta semana, você <br/> deve agendar em </>
  },
  'cancelled_by_patient': {
    tooltipContent: <>O Paciente cancelou a reserva deste dia.</>
  }
}

export {
  patientExceptions,
  adminExceptions
}