import axios from '../settings/axios'
import { apiEndpoints } from '../settings/_apiSettings'

interface ValidityDetail {
  enableSolicitation: boolean
  enableDetails: boolean
  maxAppointment: number
  availableCredits: number
  dateCreated: Date
  startDate?: Date
  endDate?: Date
  status: string
}

interface Response {
  data: {
    enable_solicitation: boolean
    enable_details: boolean
    max_appointment: number
    date_created: string
    available_credits: number
    start_date?: string
    end_date?: string
    status: string
  }
}

interface Error {
  response: {
    status: number
  }
}

export const getLastValidityDetail = (username: string, planCode: string): Promise<ValidityDetail> => {
  return new Promise<ValidityDetail>((resolve, reject) => {
    axios.get(apiEndpoints.PERSON_VALIDITIES(username, planCode))
      .then((response: Response) => {
        resolve({
          enableSolicitation: response.data.enable_solicitation,
          enableDetails: response.data.enable_details,
          maxAppointment: response.data.max_appointment,
          availableCredits: response.data.available_credits,
          dateCreated: new Date(response.data.date_created),
          startDate: response.data?.start_date ? new Date(response.data.start_date) : null,
          endDate: response.data?.end_date ? new Date(response.data.end_date) : null,
          status: response.data.status,
        } as ValidityDetail)
      })
      .catch((error: Error) => {
        console.error(error)
        reject(error.response.status)
      })
  })
}
