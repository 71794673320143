import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface GetTreatmentStatusProps {
  userName: string
  consultationType: string
  checkEligibility?: boolean
  planCode?: string
}

interface PatientTreatmentStatusData {
  cycleStatus: string
  cycleDate: string | null
}

export const getTreatmentStatus = async({
  userName,
  consultationType = 'PSIQ',
  planCode
}: GetTreatmentStatusProps): Promise<PatientTreatmentStatusData> => {
  const endpoint = apiEndpoints.PATIENT_TREATMENT_STATUS(userName)

  try {
    const response = await axios.get(endpoint, {
      params: {
        consultation_type: consultationType,
        ...(planCode && { plan_code: planCode }),
      }
    })

    return {
      cycleStatus: response.data.status,
      cycleDate: response.data.date
    }
  } catch (err) {
    return {
      cycleStatus: null,
      cycleDate: null
    }
  }
}