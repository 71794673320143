export enum PlanGroupFrequency {
  WEEKLY = 1,
  BIWEEKLY = 2
}

export interface IMonthData {
  month: string
  average: number
}

export interface ISchedulesAverage {
  monthly: IMonthData[]
}

export interface IFetchSchedulesAverageParams {
  selectedDate: {
    startDate: Date
    endDate: Date
  }
  planGroupFrequency: PlanGroupFrequency
}

export interface ISchedulesAverageData {
  month: string
  year: string
  average: number
  date: Date
}

export interface IUseSchedulesAverage {
  fetchSchedulesAverage: (params: IFetchSchedulesAverageParams) => Promise<void>
  getLastMonthData: () => {
    average: string,
    month: string
  }
  setScheduleData: (data: ISchedulesAverageData[]) => void
  data: ISchedulesAverageData[]
  loading: boolean
}