import React from 'react';
import { PropTypes } from 'prop-types';

import MenuDashboardItem from '../MenuDashboardItem';

const MenuLogout = ({ onHandleLogout }) => {
  return (
    <div className='MenuLogout' onClick={onHandleLogout}>
      <MenuDashboardItem
        icon={{
          fill: '#1F35B5',
          height: '24',
          name: 'ExitToApp',
          viewBox: '0 0 24 24',
          width: '24',
        }}
        title='Sair'
      />
    </div>
  );
};

MenuLogout.propTypes = { onHandleLogout: PropTypes.func };

export default MenuLogout;
