import { CLEAN_SKILLS, GET_SKILLS, SELECT_SKILLS } from './_actionTypes'

import { apiEndpoints } from './../../settings/_apiSettings'
import axios from '../../settings/axios'
import { asyncError } from './'
import * as skillType from '../../settings/_skillsSettings'
import { getSkillType } from '../../utils/skillsHandlers'

/** GET SKILLS */
export const getSkills = (profileRole) => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.SKILLS(profileRole))
    const skills = response.data.skills
    const subspecialties = response.data.subspecialties || []
    let parentSkills = []
    let childSkills = []

    skills.map(skill => {
      let indentifySkill = getSkillType(skill.code)
      if (indentifySkill === skillType.SKILL_PARENT) parentSkills.push(skill)
      if (indentifySkill === skillType.SKILL_CHILD) childSkills.push(skill)
      return (parentSkills, childSkills)
    })

    dispatch({
      type: GET_SKILLS,
      childSkills,
      parentSkills,
      subspecialties
    })
  } catch (err) {
    console.log(err)
    dispatch(asyncError(err))
  }
}

export const selectSkills = (skills) => dispatch => {
  dispatch({
    type: SELECT_SKILLS,
    count: skills ? skills.length : 0,
    skills,
  })
}

/** CLEAN SKILLS */
export const cleanSkills = () => { return { type: CLEAN_SKILLS } }