import styled from 'styled-components'

interface LoadingProps {
  isLoading: boolean
}

interface EmptyProps {
  isEmpty: boolean
}

interface ArrowsProps extends LoadingProps, EmptyProps {}

interface GraphicWrapperProps extends LoadingProps, EmptyProps {}

export const GraphicContainer = styled.div`
  position: relative;
`

export const GraphicWrapper = styled.div<GraphicWrapperProps>`
  display: flex;
  opacity: ${({ isLoading, isEmpty }) => (isLoading || isEmpty) && 0};
`

export const Loading = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export const Arrows = styled.div<ArrowsProps>`
  padding: 20px 0;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  opacity: ${({ isLoading, isEmpty }) => (isLoading || isEmpty) && 0};

  > button {
    background-color: initial;
    border: none;
    padding: 0;

    &:disabled {
      opacity: 0.2;
    }
  }
`

export const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  border-right: 1px solid #e2e2e2;
  padding-right: 10px;

  > div {
    margin-top: 10px;
  }
`

export const EmptyMessage = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
`