import { endOfDay } from 'date-fns'

interface FormatCalendarDateForRequest {
  startDate: string
  endDate: string
}

export function formatCalendarDateForRequest(dates: Date[]): FormatCalendarDateForRequest {
  if (!dates.length) return

  if (!dates[1]) {
    return {
      startDate: dates[0].toISOString(),
      endDate: endOfDay(dates[0]).toISOString()
    }
  }

  return {
    startDate: dates[0].toISOString(),
    endDate: dates[1].toISOString()
  }
}

export function formatPlanGroupForRequest(planGroup: { label: string, id: string | number }): string {
  if (planGroup.id === 'all') return

  return String(planGroup.id)
}

export function formatStatusCodeForRequest(statusCode: string): string {
  if (statusCode === 'all') return

  return statusCode
}