import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import * as routes from '../settings/_routesSettings'

import HeaderMenu from '../containers/HeaderMenu/HeaderMenu'

const PrivateRoute = ({
  isAuth,
  hasHeader = true,
  component: Component,
  ...rest
}) => (
  <Route {...rest} component={(props) => (
    isAuth ? (
      <div>
        {hasHeader && <HeaderMenu />}
        <Component {...props} />
      </div>
    ) : (
      <Redirect to={routes.LOGIN} />
    )
  )}
  />
)

PrivateRoute.propTypes = {
  isAuth: PropTypes.bool,
  hasHeader: PropTypes.bool,
  component: PropTypes.any
}

const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth
})

export default connect(mapStateToProps)(PrivateRoute)
