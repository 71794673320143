import React from "react";

const ArrowTop = ({
  fill,
  height,
  viewBox = "-16 -10 45 45",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill} transform="translate(-384 -261.021)">
      <path d="M36.9,13.986a.631.631,0,0,0-.394.218l-5.363,6.309a.632.632,0,0,0,.965.817h0l4.249-5V42.068a.631.631,0,0,0,1.262.019V16.333l4.249,5a.632.632,0,1,0,.965-.817h0L37.473,14.2A.631.631,0,0,0,36.9,13.986Z"
        transform="translate(353.005 247.04)"
      />
    </g>
  </svg>
);

export default ArrowTop;