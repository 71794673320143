import React from "react";

const UnavailabilitySchedule = ({
  fill = "898989",
  height = "24px",
  viewBox = "0 0 24 24",
  width = "24px",
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <g id="Icon_Add_Filled" data-name="Icon / Add / Filled" transform="translate(-88 -580)">
      <rect id="Box" width="24" height="24" transform="translate(88 580)" fill="none"/>
      <g id="Grupo_227" data-name="Grupo 227" transform="translate(88 580)">
        <path id="Caminho_2171" data-name="Caminho 2171" d="M0,0H24V24H0Z" fill="none"/>
        <g id="Grupo_5027" data-name="Grupo 5027">
          <path id="Caminho_4234" data-name="Caminho 4234" d="M0,0H24V24H0Z" fill="none"/>
          <path id="Caminho_4235" data-name="Caminho 4235" d="M19,3H18V2a1,1,0,0,0-2,0V3H8V2A1,1,0,0,0,6,2V3H5A1.991,1.991,0,0,0,3.01,5L3,19a2,2,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19,3ZM18,19H6a1,1,0,0,1-1-1V8H19V18A1,1,0,0,1,18,19Z" transform="translate(0 1)" fill="#4b4b4b"/>
          <path id="Caminho_4236" data-name="Caminho 4236" d="M9.84,16.47l1.91-1.91,1.91,1.91a.75.75,0,0,0,1.06-1.06L12.81,13.5l1.91-1.91a.75.75,0,0,0-1.06-1.06l-1.91,1.91L9.84,10.53a.75.75,0,0,0-1.06,1.06l1.91,1.91L8.78,15.41a.75.75,0,0,0,1.06,1.06Z" transform="translate(0.438 1)" fill="#4b4b4b"/>
        </g>
      </g>
    </g>
  </svg>
);

export default UnavailabilitySchedule;