import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';

const infoTextStyle = { lineHeight: '1.5' };

function FloatActionButtonDesktop({
  buttonId,
  disabled,
  hasIcon,
  iconName,
  iconViewBox,
  infoText,
  onClickFab,
  showInfo,
  title,
  type,
  value,
}) {

  let className = 'FloatActionButtonDesktop__button';

  if (type) className = `FloatActionButtonDesktop__button--${type}`;

  return ( 
    <div className="FloatActionButtonDesktop">
      {
        showInfo ? (
          <div className="FloatActionButtonDesktop__info">
            <div className='FloatActionBtn__info__icon'>
              <CoreIcons name='ArrowFab' width='49px' />
            </div>
            <div className='FloatActionBtn__info__text'>
              <Typography align='right' style={infoTextStyle} type='subheading2'>
                {infoText}
              </Typography>
            </div>
          </div>
        ) : null
      }
      <button id={buttonId}
        disabled={disabled}
        className={className}
        onClick={() => onClickFab(value)}> 
        {
          hasIcon ?
            <div className="FloatActionButtonDesktop__icon">
              <CoreIcons name={iconName}
                height="24px"
                fill="#fff"
                width="24px"
                viewBox={iconViewBox}
              /> 
            </div> : null
        }
        <Typography>
          {title} 
        </Typography>
      </button>
    </div>
  );
}

FloatActionButtonDesktop.propTypes = {
  disabled: PropTypes.bool,
  hasIcon: PropTypes.bool,
  iconName: PropTypes.string,
  title: PropTypes.any,
  type: PropTypes.oneOf([
    'primary',
    'reverse',
  ]),
};

export default FloatActionButtonDesktop;