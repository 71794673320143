/*eslint no-useless-escape: "off"*/
import React, { useState, useEffect, useRef } from 'react'

// Libs
import { useDispatch, useSelector } from 'react-redux'
import { format, isToday, getDay, isTomorrow, isBefore, roundToNearestMinutes, isPast, formatISO, set } from 'date-fns'
import clsx from 'clsx'
import InfiniteScroll from 'react-infinite-scroll-component'
import pt from 'date-fns/locale/pt-BR'

// Components
import {
  Typography,
  useDimensions,
  Button,
  List,
  Avatar,
  DatePickerInput
} from '@telavita-core/react-design-kit'
import { CoreIcons, TextInput, ClearSearch, PageTop, Column } from 'components'
import { ReactComponent as SearchIcon } from 'static/svg/search_black_24dp.svg'
import { ReactComponent as NoSchedule } from 'static/svg/no-schedule.svg'
import { ReactComponent as IconEdit } from 'static/svg/icon_edit.svg'
import { ReactComponent as ClearIcon } from 'static/svg/clear.svg'
import FloatActionButtonDropdown from 'components/FloatActionButtonDropdown/FloatActionButtonDropdown'
import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import Skeleton from 'components/Skeleton/Skeleton'
import Row from 'components/Row'
import { EventItem } from 'components/EventItem'
import { CalendarFilter } from 'components/CalendarFilter'

// Redux
import * as actions from '../../../redux/actions'
import { setTextFilter } from '../../../redux/actions/filtersActions'

// Utils
import { modalTypes } from '../../../containers/ModalManager/ModalManager'
import InputValidation from '../../../utils/InputValidation'
import {
  dateToIso,
  toString,
  toDatetime
} from '../../../utils/dateTimeHandlers'
import ToggleButton from 'components/ToggleButton'
import { filterOptions } from '../../../settings/filterOptions'
import { handleProfessionalCode } from 'utils'
import { useProfessionalAgenda } from 'hooks'
import { PatientDetailProfessionalModal } from 'containers/ModalManager/Modals/PatientDetailProfessionalModal'
import { RemoveSingleAvailabilityModal } from 'containers/ModalManager/Modals/RemoveSingleAvailabilityModal'

const weekDayName = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado'
]

const AdminAgenda = ({ match }) => {
  const dispatch = useDispatch()
  const { isMobile } = useDimensions()
  const { schedules, pagination, loading, loaded } = useProfessionalAgenda()
  const firstUpdate = useRef(true)

  // Redux
  const professional = useSelector((state) => state.persons.person)
  const professionalDocument = useSelector((state) => state.persons.personDocument)

  const filters = useSelector((state) => state.filters)
  const reduxSchedules = useSelector(state => state.schedules.schedules)
  const slots = useSelector(state => state.slots.slots)

  const startDateInitialState = {
    value: filters.startDate ? format(new Date(filters.startDate), 'dd/MM/yyyy') : '',
    isValid: true,
    message: '',
  }

  const endDateInitialState = {
    value: filters.endDate ? format(new Date(filters.endDate), 'dd/MM/yyyy') : '',
    isValid: true,
    message: '',
  }
  
  // State
  const [searchQuery, setSearchQuery] = useState('')
  const [openFiltersModal, setOpenFiltersModal] = useState(false)
  const [openPatientDetailModal, setOpenPatientDetailModal] = useState(false)
  const [openRemoveSingleSlotModal, setOpenRemoveSingleSlotModal] = useState(false)
  const [selectedSingleSlot, setSelectedSingleSlot] = useState()
  const [selectedStatus, setSelectedStatus] = useState(filters.status ? filters.status : 'ALL')
  const [patientData, setPatientData] = useState({
    name: '',
    phone: '',
    email: ''
  })
  const [startDate, setStartDate] = useState(startDateInitialState)
  const [endDate, setEndDate] = useState(endDateInitialState)
  const [isDateChecked, setIsDateChecked] = useState(true)
  const [isFloating, setIsFloating] = useState(false)
  const [calendarDate, setCalendarDate] = useState([])
  const [showSelectedDateOnPlaceholder, setShowSelectedDateOnPlaceholder] = useState(false)

  const hasFilterActive = Boolean(
    filters.startDate || filters.endDate || filters.status || filters.text
  )

  const userName = match.params.slug

  const handleScrollTop = () => window.scrollTo(0, 0)

  const onSubmitSearch = () => {
    if (loading || !searchQuery) return
    dispatch(setTextFilter(searchQuery))
    onChangeFilters()
  }

  const updateStartDate = (value) => {
    let validation = new InputValidation(value, 'date').validate()
    setStartDate((oldState) => ({
      ...oldState,
      value: value,
      isValid: value === '' ? true : validation.success,
      message: validation.message,
    }))
  }

  const updateEndDate = (value) => {
    let validation = new InputValidation(value, 'date').validate()
    setEndDate((oldState) => ({
      ...oldState,
      value: value,
      isValid: value === '' ? true : validation.success,
      message: validation.message,
    }))
  }

  const handleDateCheck = () => {
    let isValid = true

    if (!startDate.isValid) isValid = false
    if (!endDate.isValid) isValid = false

    if (startDate.isValid || endDate.isValid) {
      if (isBefore(new Date(dateToIso(endDate.value)), new Date(dateToIso(startDate.value)))) isValid = false
    }
    setIsDateChecked(isValid)
  }

  const handleSetDateFilter = () => {
    const newStartDate = startDate.value ? formatISO(set(new Date(dateToIso(startDate.value).replace(/-/g, '\/')), { hours: 0, minutes: 0 })) : null
    const newEndDate = endDate.value ? formatISO(set(new Date(dateToIso(endDate.value).replace(/-/g, '\/')), { hours: 23, minutes: 59, seconds: 59 })) : null
    
    dispatch(actions.setDateFilter(newStartDate, newEndDate))
  }

  const handleSubmitFilters = () => {
    if (
      (startDate.value === '' || !startDate.value) &&
      (endDate.value === '' || !endDate.value) &&
      (selectedStatus === '' || !selectedStatus)
    ) {
      handleClearFilter()
    } else {
      handleSetDateFilter()
      dispatch(actions.setStatusFilter(selectedStatus))
    }
    setOpenFiltersModal(false)
  }

  const handleClearFilter = async () => {
    dispatch(actions.clearFilter())
    dispatch(actions.cleanSchedules())
    dispatch(setTextFilter(null))
    setSearchQuery(null)
    setStartDate({
      value: '',
      isValid: true,
      message: '',
    })
    setEndDate({
      value: '',
      isValid: true,
      message: '',
    })
    setSelectedStatus('ALL')
    setCalendarDate([])
    setShowSelectedDateOnPlaceholder(false)
  }

  const onChangeFilters = () => {
    dispatch(actions.cleanSchedules())
    handleScrollTop()
    loadEvents()
  }

  const handleChangeCalendarDate = (date) => {
    setCalendarDate(date)
  }

  const loadEvents = (page = 1, perPage = 10) => {
    if (!loaded) fetchSlots()
  
    dispatch(
      actions.getProfessionalEvents({
        username: userName,
        start_date: startDate.value !== '' ? filters.startDate : (!hasFilterActive || (page > 1 && !hasFilterActive)) ? formatISO(set(new Date(), { hours: 0, minutes: 0, seconds: 0 })) : null,
        end_date: endDate.value !== '' ? filters.endDate : null,
        query: searchQuery ? searchQuery : null,
        status_code: filters.status === 'ALL' ? null : filters.status,
        page: page,
        per_page: perPage
      })
    )
  }

  const fetchSlots = () => {
    dispatch(actions.getSlot(userName))
  }

  const fetchPerson = () => {
    const type = handleProfessionalCode(match.params.type)
    dispatch(actions.getPerson(userName, type))
  }

  let oldScrollValue = 0
  let newScrollValue = 0

  function onScroll() {
    newScrollValue = window.pageYOffset
    if (oldScrollValue < newScrollValue) {
      setIsFloating(false)
    } else if (oldScrollValue > newScrollValue) {
      setIsFloating(true)
    }
    oldScrollValue = newScrollValue
  }

  const handleCloseModal = () => {
    setOpenFiltersModal(false)
    setStartDate(startDateInitialState)
    setEndDate(endDateInitialState)
  }

  const renderRecurrentSlot = (scheduleDay) => {
    
    if ((!isToday(new Date(scheduleDay)) && isPast(new Date(scheduleDay)))|| filters.text) return

    const weekDay = new Date(scheduleDay).getDay()

    const currentSlot = slots[weekDay === 0 ? 6 : weekDay - 1].filter(slot => slot.repeat_interval)

    return (
      <>
        {currentSlot?.length > 0 && (
          <List.Item
            content={
              <div className='ProfessionalAgenda__List__Item__ContentWrapper'>
                <div className='ProfessionalAgenda__List__Item__SlotData'>
                  <Typography variant='content3' weight='bold'>
                  Disponibilidade recorrente
                  </Typography>
                  <Typography variant='content3' customClassName='ProfessionalAgenda__List__Item__SlotData__RecurrentRange'>
                    {currentSlot.map((range, index) => (
                    <>
                      {format(new Date(range.start_date), 'HH', { locale: pt })}h às {format(roundToNearestMinutes(new Date(range.end_date)), 'HH', { locale: pt })}h&nbsp;&nbsp;<span className='ProfessionalAgenda__List__Item__SlotData__Separator'>{index + 1 < currentSlot.length ? '|' : ''}</span>&nbsp;&nbsp;
                    </>
                    ))}
                  </Typography>
                </div>
                <div>
                  <button
                    onClick={() => dispatch(
                      actions.showModal(modalTypes.NEW_SCHEDULE, {
                        user: professional,
                      })
                    )}
                    className='ProfessionalAgenda__List__Item__ActionButton'
                  >
                    <IconEdit />
                  </button>
                </div>
              </div>
            }
          />
        )}
      </>
    )
  }

  const renderEvents = () => {
    const floatingTop = isMobile ? '78px' : '83px'
    const floatingTopFilterActive = isMobile ? '122px' : '127px'

    if (!loaded) return null

    return Object.values(schedules).length > 0 && Object.keys(schedules).map((schedule, index) => {
      const headerTitle = filters.status && filters.status !== 'ALL' ? 
        filterOptions.find(filter => filter.code === filters.status).name : 
        isToday(new Date(schedule)) ? 'HOJE' : isTomorrow(new Date(schedule)) ? 'AMANHÃ' : weekDayName[getDay(new Date(schedule))] 

      return (
        <List.Container key={index} >
          <List.Header 
            variant={hasFilterActive ? 'default' : 'warning'} 
            style={{ top: isFloating ? hasFilterActive ? floatingTopFilterActive : floatingTop : 0 }}
          >
            <div className='ProfessionalAgenda__List__Header'>
              <Typography variant='content2' weight='bold'>
                {headerTitle}
              </Typography>
              <Typography variant='content2'>
                {format(new Date(schedule), 'dd/MM/yyyy', { locale: pt })}
              </Typography>
            </div>
          </List.Header>
          {renderRecurrentSlot(schedule)}
          {schedules[schedule].map(event => (
            <EventItem
              actions={[
                {
                  label: 'Ver detalhes do paciente',
                  icon: 'OptionsHorizontal',
                  onClick: () => {
                    setOpenPatientDetailModal(true)
                    setPatientData({
                      name: event.schedule.patient.full_name,
                      email: event.schedule.patient.email,
                      phone: event.schedule.patient.cellphone_number
                    })
                  }
                }
              ]}
              event={event}
              handleRemoveSingleSlot={() => {
                dispatch(actions.clearSlotStatus())
                setOpenRemoveSingleSlotModal(true)
                setSelectedSingleSlot(event)
              }}
              handleRemoveUnavailability={() => {
                dispatch(
                  actions.showModal(modalTypes.REMOVE_UNAVAILABILITY, {
                    event: {
                      id: event.id,
                      date: format(new Date(event.start_date), 'dd/MM/yyyy'),
                      time: {
                        start: format(new Date(event.start_date), 'HH:mm'),
                        end: format(
                          roundToNearestMinutes(new Date(event.end_date), {
                            nearestTo: 5,
                          }),
                          'HH:mm'
                        ),
                      },
                      username: userName,
                    },
                  })
                )
              }}
            />
          ))}
        </List.Container>
      )
    })
  }
  
  
  const renderSkeletonItem = (amount = 0) => {
    return (
      <>
        {new Array(amount).fill(0).map((_) => (
          <div className='ProfessionalAgenda__List__SkeletonWrapper__Item'>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}> 
              <Skeleton 
                height={93}
                width={75}
                borderRadius={10}
              /> 
              <div 
                className='ProfessionalAgenda__List__SkeletonWrapper__Item__Content'
              >
                <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
                  <Skeleton 
                    height={15}
                    width={108}
                    borderRadius={5}
                  />
                  <Skeleton 
                    height={15}
                    width={isMobile ? 130 : 282}
                    borderRadius={5}
                  />
                </div>
                <div style={{ display: 'flex', gap: 10 }}>
                  <Skeleton 
                    height={15}
                    width={42}
                    borderRadius={5}
                  />
                  <Skeleton 
                    height={15}
                    width={98}
                    borderRadius={5}
                  />
                </div>
              </div>
            </div>
            <Skeleton 
              height={24}
              width={24}
              borderRadius={24}
            />
          </div>
        ))}
      </>
    )
  }

  useEffect(() => {
    handleDateCheck()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  useEffect(() => {  
    if (firstUpdate.current) {
      loadEvents(pagination.page + 1)
      firstUpdate.current = false
      return
    }
    onChangeFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.startDate, filters.endDate, filters.status, filters.text])
  
  useEffect(() => {
    fetchPerson()

    return () => dispatch(actions.cleanSchedules())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Row>
        <Column colWidth={12} className='ProfessionalAgenda__Header'>
          <PageTop
            id='agenda-view-header'
            customClassName='ProfessionalAgenda__Header__CustomWrapper'
            bottomComponent={
            <>
              {hasFilterActive && (
                <div className='ProfessionalAgenda__Header__ClearFilters'>
                  <ClearSearch
                    fn={handleClearFilter}
                    titleName='Limpar filtros'
                    titleCustomClassName='ProfessionalAgenda__Header__ClearFilters__Text'
                  />
                </div>
              )}
            </>
            }
            toHideOnMobile={
              <Row align='center'>
                <div className="ProfessionalAgenda__Title__Avatar">
                  <Avatar
                    alt={professional.full_name}
                    src={professional.picture_url}
                    size="regular"
                  />
                </div>
                <div className="ProfessionalAgenda__Title__Text">
                  <Typography variant="content3">
                Agenda de
                  </Typography>
                  <Typography variant="content3" weight='bold'>
                    {professional.full_name}
                  </Typography>
                  <Typography variant="content3">
                    {professionalDocument}
                  </Typography>
                </div>
              </Row>
            }
            toShowOnMobile={
              <div className='ProfessionalAgenda__Header__Wrapper'>
                <div className='ProfessionalAgenda__Header__Filters'>
                  <div className='ProfessionalAgenda__Header__Filters__Input'>
                    <TextInput
                      addIcon
                      customClassName='ProfessionalAgenda__Header__Filters__Input__Custom'
                      iconProps={{
                        fill: '#1F35B5',
                        height: '30px',
                        name: 'Search',
                        viewBox: '3 2 30 30',
                        width: '30px',
                      }}
                      name='search_client'
                      inputHeight='4.8rem'
                      onInputChange={(e) => setSearchQuery(e.target.value)}
                      onClickIcon={onSubmitSearch}
                      onKeyPress={(e) => e.which === 13 && onSubmitSearch()}
                      placeholder={
                        isMobile ? 'Buscar paciente' : 'Buscar por nome ou e-mail'
                      }
                      value={searchQuery}
                    />
                  </div>
                  <button
                    className='ProfessionalAgenda__Header__Filters__Button'
                    onClick={() => setOpenFiltersModal(true)}
                  >
                    <CoreIcons
                      fill='#1F35B5'
                      height='24px'
                      name='Filters'
                      width='34px'
                      viewBox='0 0 24 24'
                    />
                  </button>
                  <FloatActionButtonDropdown
                    icon='Positive'
                    title='Adicionar'
                    type='primary'
                    position='right'
                    options={[
                      {
                        iconName: 'ArrowClock',
                        id: 'online-schedule',
                        func: () =>
                          dispatch(
                            actions.showModal(modalTypes.NEW_SCHEDULE, {
                              user: professional,
                            })
                          ),
                        title: 'Disponibilidade online',
                      },
                      {
                        iconName: 'UnavailabilitySchedule',
                        id: 'disable-schedule',
                        func: () => {
                          dispatch(
                            actions.showModal(
                              modalTypes.PROFESSIONAL_UNAVAILABILITY,
                              {
                                userData: {
                                  username: userName,
                                },
                                startDate: toString(
                                  toDatetime(
                                    dateToIso(
                                      startDate.value
                                        ? startDate.value
                                        : new Date().toISOString()
                                    ),
                                    '00:00'
                                  )
                                ),
                                endDate: toString(
                                  toDatetime(
                                    dateToIso(
                                      startDate.value
                                        ? startDate.value
                                        : new Date().toISOString()
                                    ),
                                    '23:59'
                                  )
                                ),
                              }
                            )
                          )
                        },
                        title: 'Indisponibilidade',
                      },
                    ]}
                    renderOption={(option) => {
                      return (
                      <>
                        <div className='FloatActionButtonDropdown__Modal__OptionsList__Option__Text FloatActionButtonDropdown__Modal__OptionsList__Option__Background'>
                          <Typography variant='content2'>{option.title}</Typography>
                        </div>
                        <div className='FloatActionButtonDropdown__Modal__OptionsList__Option__Icon FloatActionButtonDropdown__Modal__OptionsList__Option__Background'>
                          <CoreIcons
                            name={option.iconName}
                            height='24px'
                            width='24px'
                            viewBox='0 0 24 24'
                            fill='#4b4b4b'
                          />
                        </div>
                      </>
                      )
                    }}
                    onSelectOption={(option) => option.func()}
                  />
                </div>
              </div>
            }
          />
        </Column>
      </Row>
      <Row
        className='ProfessionalAgenda__List__Row'
      >
        <Column
          colWidth={12}
          className={clsx({
            'ProfessionalAgenda__List': true,
            'ProfessionalAgenda__List--isFilterActive': hasFilterActive
          })}
        >
          <InfiniteScroll 
            dataLength={reduxSchedules.length}
            style={{
              overflow: 'visible'
            }}
            hasMore={pagination?.pages > pagination.page}
            next={() => loadEvents(pagination.page + 1)}
            loader={
              <div className='ProfessionalAgenda__List__SkeletonWrapper'>
                <div className='ProfessionalAgenda__List__SkeletonWrapper__Header'>
                  <Skeleton 
                    height={15}
                    width={46}
                    borderRadius={5}
                  />
                  <Skeleton 
                    height={15}
                    width={82}
                    borderRadius={5}
                  />
                </div>
                <div className='ProfessionalAgenda__List__SkeletonWrapper__Item'>
                  <div style={{ display: 'flex', justifyContent: 'space-between', gap: 20 }}> 
                    <Skeleton 
                      height={15}
                      width={153}
                      borderRadius={5}
                    /> 
                    <Skeleton 
                      height={15}
                      width={286}
                      borderRadius={5}
                    />
                  </div>
                  <Skeleton 
                    height={24}
                    width={24}
                    borderRadius={24}
                  />
                </div>
                {renderSkeletonItem(8)}  
              </div>
            }
          >
            {renderEvents()}
          </InfiniteScroll>
          {!loading && hasFilterActive && pagination?.total === 0 && (
            <div className='ProfessionalAgenda__List__EmptyWrapper'>
              <SearchIcon />
              <Typography weight='bold' variant='content2' inheritColor>Nenhum resultado</Typography>
              <Typography variant='content2' inheritColor>Refaça sua busca e seus filtros</Typography>
            </div>
          )}
          {!loading && !hasFilterActive && Object.values(schedules).length === 0 && pagination?.total === 0 && (
            <div className='ProfessionalAgenda__List__EmptyWrapper'>
              <NoSchedule />
              <Typography weight='bold' variant='content2' inheritColor>O profissional ainda não possui nenhuma consulta agendada</Typography>
            </div>    
          )}
        </Column>
      </Row>

      {openPatientDetailModal && (
        <PatientDetailProfessionalModal 
          name={patientData.name}
          email={patientData.email}
          phone={patientData.phone}
          cpf={patientData.cpf}
          onClose={() => setOpenPatientDetailModal(false)}
        />
      )}
      {openRemoveSingleSlotModal && (
        <RemoveSingleAvailabilityModal 
          onClose={() => setOpenRemoveSingleSlotModal(false)}
          selectedSingleSlot={selectedSingleSlot}
        />
      )}
      {openFiltersModal && (
        <ModalGeneric
          modalTitle='Filtros'
          onCloseModal={handleCloseModal}
        >
          <div className='ProfessionalAgenda__FiltersModal'>
            <CalendarFilter
              calendarDate={calendarDate}
              handleChangeCalendarDate={handleChangeCalendarDate}
              onSelectedDate={date => {
                updateStartDate(date.initialDate)
                updateEndDate(date.endDate)
              }}
              showSelectedDateOnPlaceholder={showSelectedDateOnPlaceholder}
              setShowSelectedDateOnPlaceholder={setShowSelectedDateOnPlaceholder}
            />
            <div className='ProfessionalAgenda__FiltersModal__Content'>
              <Typography variant='content2'>Mostrar: </Typography>
              <div className='ProfessionalAgenda__FiltersModal__Content__FiltersWrapper'>
                <ToggleButton
                  isActive={selectedStatus === 'ALL'}
                  itemName={'Todas'}
                  itemTitle={'Todas'}
                  handleClick={() => setSelectedStatus('ALL')}
                  isFilterAll={true}
                  iconName={'AllSchedules'}
                />
                {filterOptions.map((filter) => (
                  <ToggleButton
                    isActive={selectedStatus === filter.code}
                    handleClick={() => setSelectedStatus(filter.code)}
                    itemTitle={filter.name}
                    iconName={filter.icon_name}
                    isFilterAll
                  />
                ))}
              </div>
              <button
                className='ProfessionalAgenda__FiltersModal__ClearFilter'
                onClick={() => {
                  if (hasFilterActive) handleClearFilter()
                }}
              >
                <ClearIcon />
                <Typography variant='content3' color='primary' weight='bold'>
                  LIMPAR FILTROS
                </Typography>
              </button>
              <Button
                disabled={!isDateChecked}
                customClassName='ProfessionalAgenda__FiltersModal__Button'
                variant='contained'
                onClick={handleSubmitFilters}
              >
                APLICAR MODIFICAÇÕES
              </Button>
            </div>
          </div>
        </ModalGeneric>
      )}
    </>
  )
}

export default AdminAgenda