import React from "react";

const Hourglass = ({
  fill,
  height,
  viewBox="0 0 24 24",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <path id="Caminho_2519" data-name="Caminho 2519" d="M6,2V8h.01L6,8.01,10,12,6,16l.01.01H6V22H18V16.01h-.01L18,16l-4-4,4-3.99L17.99,8H18V2ZM16,16.5V20H8V16.5l4-4Zm-4-5-4-4V4h8V7.5Z" fill={fill}/>
    <path id="Caminho_2520" data-name="Caminho 2520" d="M0,0H24V24H0Z" fill="none"/>
  </svg>
);

export default Hourglass;