import React from 'react'

const NoShow = ({ fill, height = 35, viewBox = '0 0 42 42', width = 35 }) => (
  <svg xmlns="http://www.w3.org/2000/svg"  width={width} height={height} viewBox={viewBox}>
    <g id="filtro-realizadas-off" clip-path="url(#clip-path)">
      <g id="Grupo_559" data-name="Grupo 559" transform="translate(-256.376 -33.575)">
        <path id="Caminho_2259" data-name="Caminho 2259" d="M274.526,33.575a18.151,18.151,0,1,0,18.152,18.151,18.028,18.028,0,0,0-18.152-18.151h0Zm.006,33.97h0a15.818,15.818,0,1,1,11.188-4.635A15.838,15.838,0,0,1,274.528,67.545Z" fill={fill}/>
      </g>
      <path id="Caminho_2260" data-name="Caminho 2260" d="M268.459,50.328l-1.7,1.966,6.419,5.544L282.3,47.316l-1.964-1.7-7.424,8.558-4.454-3.842Z" transform="translate(-256.376 -33.575)" fill={fill}/>
    </g>
  </svg>

)

export default NoShow
