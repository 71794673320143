import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 5rem;

  .subtitle {
    margin-top: 5px;
    margin-bottom: 20px;
    color: #898989;
  }
`

export const WrapperAges = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
`