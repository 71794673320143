import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

export type RelationshipDegree = 'FATHER' | 'MOTHER' | 'SPOUSE' | 'SIBLING' | 'OTHER'

interface PatientPersonalDataService {
  username: string
  full_name: string
  email: string
  cpf: string
  cellphone_number: string
  birth_date: string
  address_data: {
    cep: string
    address: string
    address_number: string
    complement: string
    neighborhood: string
    city: string
    state: string
  }
  relative_data: {
    relative_full_name: string
    relative_phone_number: string
    relationship_degree: RelationshipDegree
  }
}

export async function getPatientPersonalData(patientEmail: string): Promise<PatientPersonalDataService> {
  const { data } = await axios.get<PatientPersonalDataService>(`${apiEndpoints.PATIENT_PERSONAL_DATA(patientEmail)}`)
  return data
}