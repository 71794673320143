import {
  ADD_BIOGRAPHY_DATA,
  ADD_DOCUMENT_DATA,
  ADD_EDUCATION_DATA,
  ADD_GENERAL_DATA,
  ADD_MEDIA_DATA,
  ADD_PRODUCTS_DATA,
  ADD_SKILLS_DATA,
  CLEAR_BLOCKS_DATA,
  CLEAN_BLOCKS_EDIT,
  EDIT_BLOCKS_DATA,
  ADD_SUBSPECIALTIES_DATA,
  ADD_LANGUAGES_DATA,
  ADD_TREATMENT_BY_AGE_DATA
} from '../actions/_actionTypes'
import { updateState } from '../helpers/utilityHelpers'

export const initialState = {
  readyToSubmit: false,
  isEdit: false,
  blocksData: {
    biographyData: { biography: '' },
    documentsData: { documents: [] },
    educationData: { education: [] },
    generalData: {
      cpf: '',
      cellphone_number: '',
      date_birth: '',
      email: '',
      full_name: '',
      gender: '',
      phone_number: '',
    },
    mediaData: { video_url: '' },
    productsData: { products: [] },
    skillsData: { skills: [] },
    subspecialtiesData: { subspecialties: [] },
    languagesData: { languages: [] },
    treatmentByAgeData: { treatmentByAge: [] },
  },
  generalData: {
    canSave: false,
    isBlockChecked: false,
    data: {},
  },
  subspecialtiesData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  biographyData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  documentsData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  educationData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  mediaData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  productsData: {
    canSave: false,
    isBlockChecked: false,
    data: { products: [] },
  },
  skillsData: {
    canSave: true,
    isBlockChecked: false,
    data: {},
  },
  languagesData: {
    canSave: false,
    isBlockChecked: false,
    data: {},
  },
  treatmentByAgeData: {
    canSave: false,
    isBlockChecked: false,
    data: {},
  }

}

const addSubspecialtiesData = (state, { payload }) => {
  return updateState(state, {
    subspecialtiesData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.subspecialtiesData.data,
        ...payload.data,
      },
    },
  })
}


const addBiographyData = (state, { payload }) => {
  const biographyPayload = {
    isBlockChecked: payload.isBlockChecked,
    canSave: payload.canSave,
    data: {
      ...state.biographyData.data,
      ...payload.data,
    },
  }
  return updateState(state, { biographyData: biographyPayload })
}

const addGeneralData = (state, { payload }) => {
  const generalPayload = {
    isBlockChecked: payload.isBlockChecked,
    canSave: payload.canSave,
    data: {
      ...state.generalData.data,
      ...payload.data,
    },
  }
  return updateState(state, { generalData: generalPayload })
}

const addDocumentsData = (state, { payload }) => {
  const documentsPayload = {
    isBlockChecked: payload.isBlockChecked,
    canSave: payload.canSave,
    data: {
      ...state.documentsData.data,
      ...payload.data,
    },
  }
  return updateState(state, { documentsData: documentsPayload })
}

const addEducationData = (state, { payload }) => {
  const educationPayload = {
    isBlockChecked: payload.isBlockChecked,
    canSave: payload.canSave,
    data: {
      ...state.educationData.data,
      ...payload.data,
    },
  }
  return updateState(state, { educationData: educationPayload })
}

const addMediaData = (state, { payload }) => {
  return updateState(state, {
    mediaData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.mediaData.data,
        ...payload.data,
      },
    },
  })
}

const addSkillsData = (state, { payload }) => {
  return updateState(state, {
    skillsData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.skillsData.data,
        ...payload.data,
      },
    },
  })
}

const addTreatmentByAgeData = (state, { payload }) => {
  return updateState(state, {
    treatmentByAgeData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.treatmentByAgeData.data,
        ...payload.data,
      },
    },
  })
}

const addLanguagesData = (state, { payload }) => {
  return updateState(state, {
    languagesData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.languagesData.data,
        ...payload.data,
      },
    },
  })
}

const addProductsData = (state, { payload }) => {
  return updateState(state, {
    productsData: {
      canSave: payload.canSave,
      isBlockChecked: payload.isBlockChecked,
      data: {
        ...state.productsData.data,
        ...payload.data,
      },
    },
    subspecialtiesData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
  })
}

const cleanBlocksData = (state) => {
  return updateState(state, {
    readyToSubmit: false,
    generalData: {
      canSave: false,
      isBlockChecked: false,
      data: {},
    },
    biographyData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
    documentsData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
    educationData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
    mediaData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
    productsData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
    skillsData: {
      canSave: true,
      isBlockChecked: false,
      data: {},
    },
  })
}

const cleanBlocksEdition = (state) => {
  return updateState(state, {
    isEdit: false,
    blocksData: { ...initialState.blocksData },
  })
}

const editBlocksData = (state, { payload }) => {
  return updateState(state, {
    isEdit: true,
    blocksData: payload.blocksData,
  })
}

const registerBlocksReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_GENERAL_DATA: return addGeneralData(state, action)
  case ADD_BIOGRAPHY_DATA: return addBiographyData(state, action)
  case ADD_EDUCATION_DATA: return addEducationData(state, action)
  case ADD_DOCUMENT_DATA: return addDocumentsData(state, action)
  case ADD_MEDIA_DATA: return addMediaData(state, action)
  case ADD_PRODUCTS_DATA: return addProductsData(state, action)
  case ADD_SKILLS_DATA: return addSkillsData(state, action)
  case ADD_LANGUAGES_DATA: return addLanguagesData(state, action)
  case ADD_SUBSPECIALTIES_DATA: return addSubspecialtiesData(state, action)
  case ADD_TREATMENT_BY_AGE_DATA: return addTreatmentByAgeData(state, action)
  case CLEAR_BLOCKS_DATA: return cleanBlocksData(state)
  case CLEAN_BLOCKS_EDIT: return cleanBlocksEdition(state)
  case EDIT_BLOCKS_DATA: return editBlocksData(state, action)
  default: return state
  }
}

export default registerBlocksReducer