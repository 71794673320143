import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const TotalLabelWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const TotalLabel = styled.p`
  font-size: 34px;
  color: #4B4B4B;
  font-weight: bold;
`

export const TotalLabelSubtitle = styled.p`
  font-size: 12px;
  font-weight: normal;
  margin-top: 5px;
  color: #4B4B4B;
`