import {
  AGENDA_AVAILABILITY,
  CLEAR_AVAILABILITY,
  DAYS_OCCUPIED,
  PROFESSIONALS_AVAILABLE,
  GET_ALL_AVAILABILITY,
  CLEAR_PROFESSIONALS_AVAILABLE,
  SHOW_NOTIFICATION_APPOINTMENT,
} from './_actionTypes'

import axios from '../../settings/axios'
import { apiEndpoints } from '../../settings/_apiSettings'
import { NOT_SCHEDULED } from '../../settings/_scheduleStatusSettings'
import { getDay, toJsDate } from '../../utils/dateTimeHandlers'
import { asyncError } from './'

/** Get professional availability */
export const getAvailability = (
  slug, {
    is_rescheduling,
    start_date,
    end_date,
    plan_code,
    product_code,
    username,
    schedule_id,
    profile_role
  }) => async dispatch => {

  let fetchUrl = apiEndpoints.AGENDA_AVAILABILITY(slug)
  const params = {
    ...(is_rescheduling && { is_rescheduling }),
    ...(start_date && { start_date }),
    ...(end_date && { end_date }),
    ...(plan_code && { plan_code }),
    ...(product_code && { product_code }),
    ...(schedule_id && { schedule_id }),
    ...(profile_role && { profile_role }),
    username,
    group_by: 'date'
  }

  let errorCode = null
  let availableHours = []
  let unavailableDates = []
  let patientSchedules = []
  let availableCreditsPatient = 0

  try {
    const response = await axios.get(fetchUrl, { params })
    const availabilities = response.data.availabilities
    availableCreditsPatient = response.data.available_credits
    patientSchedules = response.data.patient_schedules !== undefined ? response.data.patient_schedules : []

    if (response.status === 'OK') {
      Object.entries(availabilities).forEach(([key, value]) => {
        if (value.length === 0) {
          unavailableDates.push(toJsDate(key))
        } else {
          const newDate = { [key]: value }
          availableHours.push(newDate)
        }
      })
    }

  } catch (err) {
    unavailableDates = [{ after: new Date(1900, 1, 1) }]
    errorCode = err.response.status
    dispatch(asyncError(err))
  }

  dispatch({
    type: AGENDA_AVAILABILITY,
    payload: {
      errorCode,
      availableHours,
      availableCreditsPatient,
      unavailableDates,
      patientSchedules,
    }
  })
}

/** Get dates with schedules */
export const getScheduledDays = (username, startDate, startDateLte, endDate, endDateGte, statusCode, sort, profileRole) => async dispatch => {
  try {
    const response = await axios.get(apiEndpoints.SCHEDULES, {
      params: {
        profile_role: profileRole,
        username: username,
        start_date: startDate,
        start_date__lte: startDateLte,
        end_date: endDate,
        end_date__gte: endDateGte,
        status_code: statusCode,
        sort: sort,
      },
    })

    const agenda = response.data.schedules
    let unavailableDays = []
    let payload = {}

    if (agenda.length > 0)
      agenda.map(schedule => {
        if (schedule.status_code !== NOT_SCHEDULED)
          unavailableDays.push(parseInt(getDay(schedule.start_date)))
        return unavailableDays
      })

    payload = { unavailableDays }

    dispatch({
      type: DAYS_OCCUPIED,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/** Clear availability state */
export const clearAvailability = () => {
  return { type: CLEAR_AVAILABILITY }
}

/** Get professionals availables for specific date */
export const professionalsAvailable = (getParams) => async dispatch => {
  const fetchUrl = apiEndpoints.PERSONS

  try {
    const response = await axios.get(fetchUrl, { params: { ...getParams } })
    const payload = { professionals: response.data.persons }

    dispatch({
      type: PROFESSIONALS_AVAILABLE,
      payload,
    })
  } catch (err) {
    dispatch(asyncError(err))
  }
}

/** Clear prefessional available */
export const clearProfessionals = () => {
  return { type: CLEAR_PROFESSIONALS_AVAILABLE }
}

/** Get all professionals availability */
export const getAllAvailability = ({
  start_date,
  end_date,
  product_code,
  appointment_code,
  group_by,
}) => async dispatch => {

  const fetchUrl = apiEndpoints.ALL_AVAILABILITY
  const params = {
    ...(start_date && { start_date }),
    ...(end_date && { end_date }),
    ...(product_code && { product_code }),
    ...(appointment_code && { appointment_code }),
    ...(group_by && { group_by }),
  }
  try {
    const response = await axios.get(fetchUrl, { params })
    // Format Object to a more useful array
    const dates = Object.keys(response.data.agendas)
    const formatedAgenda = dates.map(date => {
      const formatedObject = {}
      formatedObject.date = date
      formatedObject.slots = response.data.agendas[date]
      return formatedObject
    })
    // Spread data into reducer states
    let unavailableDates = []
    let availableHours = []

    formatedAgenda.map(date => {
      if (date.slots.length === 0) {
        unavailableDates.push(date.date)
      } else if (date.slots.length !== 0) {
        availableHours.push(date)
      }
      return null
    })

    let payload = {
      unavailableDates,
      availableHours,
    }

    return dispatch({
      type: GET_ALL_AVAILABILITY,
      payload,
    })

  } catch (err) {
    return dispatch(asyncError(err))
  }
}

/* Set current schedule info in notification */
export const showNotificationAppointment = (payload) => async dispatch => {
  try {
    dispatch({
      type: SHOW_NOTIFICATION_APPOINTMENT,
      payload,
    })
  } catch (err) {
    console.log(err)
  }
}
