import React from "react";

const Plans = ({
  fill = "#1f35b5",
  height,
  viewBox = "0 0 24 24",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} d="M17,15h2v2H17Zm0-4h2v2H17Zm0-4h2V9H17ZM13.74,7,15,7.84V7Z"/>
    <path fill={fill} d="M10,3V4.51l2,1.33V5h9V19H17v2h6V3Z"/>
    <path fill={fill} d="M8.17,5.7,15,10.25V21H1V10.48ZM10,19h3V11.16L8.17,8.09,3,11.38V19H6V13h4Z"/>
  </svg>
);
  
export default Plans;