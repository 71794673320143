import React from 'react';

const FileUsers = ({
    fill = "#1f35b5",
    height = "57.995",
    width = "46",
    viewBox = "0 0 46 57.995",
}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
            <g id="Grupo_5092" data-name="Grupo 5092" transform="translate(53.237 12.572)">
                <path id="Caminho_4241" data-name="Caminho 4241" d="M-7.538-.273l-12-12a.93.93,0,0,0-.7-.3H-49.376a3.877,3.877,0,0,0-3.861,3.86V37.734a1,1,0,0,0,1,1,1,1,0,0,0,1-1V-8.712a1.859,1.859,0,0,1,1.859-1.86h28.14v11a1,1,0,0,0,1,1h11V41.567a1.859,1.859,0,0,1-1.855,1.863H-49.376a1.859,1.859,0,0,1-1.861-1.858v0h0v-5.14a1,1,0,0,0-1-1,1,1,0,0,0-1,1v5.14a3.877,3.877,0,0,0,3.861,3.856H-11.1a3.877,3.877,0,0,0,3.86-3.86V.428A.937.937,0,0,0-7.538-.273Z" fill={fill} />
                <g id="Grupo_5097" data-name="Grupo 5097" transform="translate(-57.282 -11.896)">
                    <g id="Grupo_5096" data-name="Grupo 5096" transform="translate(16.745 20.244)">
                        <circle id="Elipse_57" data-name="Elipse 57" cx="4.367" cy="4.367" r="4.367" transform="translate(13.353 2.161)" fill={fill} />
                        <path id="Caminho_4241-2" data-name="Caminho 4241" d="M44.064,39.409H41.88a5.98,5.98,0,0,0-3.819,1.371,8.723,8.723,0,0,1,2.166,5.777v4.316h8.751a1.092,1.092,0,0,0,1.092-1.092V45.415A6.005,6.005,0,0,0,44.064,39.409Z" transform="translate(-25.252 -26.875)" fill={fill} />
                        <circle id="Elipse_58" data-name="Elipse 58" cx="5" cy="5" r="5" transform="translate(0.3 0.08)" fill={fill} />
                    </g>
                    <path id="Caminho_4242" data-name="Caminho 4242" d="M20.669,39.244h2.616a7.194,7.194,0,0,1,7.194,7.194V51.67a1.308,1.308,0,0,1-1.308,1.308H14.783a1.308,1.308,0,0,1-1.308-1.308V46.438A7.194,7.194,0,0,1,20.669,39.244Z" transform="translate(0 -6.574)" fill={fill} />
                </g>
            </g>
        </svg>
    );
};

export default FileUsers;