import React from "react";
import PropTypes from "prop-types";
import PaymentStatus from "../PaymentStatus";
import { Typography } from 'components';
import ToggleFloatbutton from "../ToggleFloatButton";
import { Trans } from "@lingui/macro";
function PaymentListItem({
  patientName,
  sessionId,
  id,
  price,
  paymentMethod,
  paymentStatus,
  menuList,
  paymentStatusName,
}) {
  return (
    <div className="PaymentsList">
      <div className="PaymentsList__data">
        <div className="PaymentsList__data__name">
          <Typography bold type="subheading2">
            <Trans>PACIENTE</Trans>
          </Typography>
          <Typography type="paragraph">{patientName}</Typography>
        </div>

        <div className="PaymentsList__data__paymentStatus">
          <div className="PaymentsList__data__paymentStatus__price">
            <div className="PaymentsList__data__paymentStatus__price__width">
              <Typography
                bold
                className="PaymentsList__data__paymentStatus__price__typography"
              >
                <span>
                  <Trans>R$</Trans>
                </span>{" "}
                {price}
              </Typography>
            </div>
            <div className="PaymentsList__data__paymentStatus__price__typography">
              <PaymentStatus
                className="component"
                paymentStatus={paymentStatus}
                paymentStatusName={paymentStatusName}
              />
            </div>
          </div>

          <div className="PaymentsList__data__paymentFooter">
            <Typography
              type="subheading2"
              className="PaymentsList__data__paymentFooter__tags"
            >
              {sessionId} {sessionId > 1 ? "itens" : "item"}
            </Typography>

            <Typography
              type="subheading2"
              className="PaymentsList__data__paymentFooter__tags"
            >
              ID {id}
            </Typography>

            <Typography
              type="subheading2"
              className="PaymentsList__data__paymentFooter__tags"
            >
              {paymentMethod}
            </Typography>
          </div>
        </div>
      </div>

      <div className="PaymentsList__icon">
        <ToggleFloatbutton menuList={menuList} iconName="MoreOptions" />
      </div>
    </div>
  );
}

PaymentListItem.propTypes = {
  psychologistName: PropTypes.string,
  patientName: PropTypes.string,
  sessionId: PropTypes.number,
  id: PropTypes.number,
  price: PropTypes.string,
  paymentMethod: PropTypes.string,
  paymentStatusName: PropTypes.string,
  paymentStatus: PropTypes.any,
  // paymentStatus: PropTypes.oneOf([

  //   'paid',
  //   'cancelled',
  //   'notPaid',
  //   'pending',
  //   'notApproved',

  // ]),
  menuList: PropTypes.any
};

export default PaymentListItem;
