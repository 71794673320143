import styled from 'styled-components'

interface LoadingProps {
  isLoading: boolean
}

interface EmptyProps {
  isEmpty: boolean
}

interface TableProps extends EmptyProps, LoadingProps {}

export const Table = styled.table<TableProps>`
  width: 100%;
  opacity: ${({ isEmpty, isLoading }) => (isEmpty || isLoading) && 0};
`

export const TableHead = styled.thead<EmptyProps>`
  display: ${({ isEmpty }) => isEmpty && 'none'};

  th:nth-child(1){
    width: 20%;
  }

  th:nth-child(2) {
    text-align: left;
    width: 50%;
  }

  th:nth-child(3) {
    width: 10%;
  }
`

export const TableBody = styled.tbody`
  tr:nth-child(odd) {
    background-color: #F5F5F5;
  }
`