import React, { useState } from 'react'
import {
  subDays,
  format,
  endOfDay,
  startOfDay,
} from 'date-fns'

import { OptionItem, Popover, Typography, useDimensions } from '@telavita-core/react-design-kit' 

import { rangeDateFilterOptions, IRangeDateFilterOptions } from 'settings/_filterSettings'

import ArrowDownIcon from 'components/CoreIcons/Icons/ArrowDown'
import ArrowUpIcon from 'components/CoreIcons/Icons/ArrowUp'
import { PopUpCalendar } from 'components/PopUpCalendar'

import {
  Header,
  Button,
  Label,
  OptionsContainer,
  ButtonText
} from './styles'

export interface SelectedDate {
  initialDate: string
  endDate: string
}

interface GraphicsDropdownProps {
  onSelectedDate: ({initialDate, endDate}: SelectedDate) => void
  initialOption: IRangeDateFilterOptions
}

export const lastDay = endOfDay(subDays(new Date(), 1))
export const getRangeDays = (quantity: number): Date => startOfDay(subDays(new Date(), quantity))

const formattedRange = (initialDate: Date, endDate: Date) => `${format(initialDate, 'dd/MM/yy')} - ${format(endDate, 'dd/MM/yy')}`
  
export function GraphicsDropdown({
  onSelectedDate,
  initialOption,
}: GraphicsDropdownProps): JSX.Element {
  const [showPopover, setShowPopover] = useState(false)
  const [selectedOption, setSelectedOption] = useState<IRangeDateFilterOptions>(initialOption)
  const [calendarDate, setCalendarDate] = useState<Date[]>([])
  const [showPopUpCalendar, setShowPopUpCalendar] = useState(false)

  const { isMobile } = useDimensions()
  const closePopOver = () => setShowPopover(false)
  const togglePopOver = () => setShowPopover(prevState => !prevState)

  const closePopUpCalendar = () => setShowPopUpCalendar(false)
  const openPopUpCalendar = () => {
    setShowPopover(false)
    setShowPopUpCalendar(true)
  }

  function handleSelectOption(option: IRangeDateFilterOptions) {
    setSelectedOption({
      label: option.label,
      quantity: option.quantity,
    })

    onSelectedDate({
      initialDate: getRangeDays(option.quantity).toString(),
      endDate: lastDay.toString(),
    })
    setCalendarDate([])
    closePopOver()
  }

  function handleChangeCalendar(date: Date[]) {
    setCalendarDate(date)
  }
  
  function handleApplyCalendar() {
    const initialDate = calendarDate[0]
    const endDate = calendarDate.length === 1 
      ? calendarDate[0]
      : calendarDate[1]
    
    onSelectedDate({
      initialDate: initialDate?.toString(),
      endDate: endDate?.toString(),
    })

    setSelectedOption({
      label: formattedRange(initialDate, endDate),
      quantity: undefined,
    })

    closePopUpCalendar()
  }

  const hasCustomCalendarDate = calendarDate.length

  const textWithSelectedCustomDate = () => {
    if (calendarDate.length === 1) {
      return formattedRange(calendarDate[0], calendarDate[0])
    }
  
    return calendarDate.length
      ? formattedRange(calendarDate[0], calendarDate[1])
      : formattedRange(getRangeDays(selectedOption.quantity), lastDay)
  } 

  const Content = () => (
    <>
      <Header>
        <Label>
          { 
            hasCustomCalendarDate 
              ? 'Personalizado'
              : selectedOption.label
          }
        </Label>
        <Typography variant='content2'>
          {textWithSelectedCustomDate()}
        </Typography>
      </Header>
      <OptionsContainer>
        {rangeDateFilterOptions.map((option) => (
          <OptionItem
            key={option.quantity}
            handleClick={() => handleSelectOption(option)}
            size='large'
          >
            <Typography
              variant='content2'
            >
              {option.label}
            </Typography>
          </OptionItem>
        ))}
        <OptionItem handleClick={openPopUpCalendar} hasDivider size='large'>
          <Typography variant="content2">Personalizado...</Typography>
        </OptionItem>
      </OptionsContainer>
    </>
  )
    
  return (
    <div>
      <Popover
        content={<Content />}
        onClickOutside={closePopOver}
        visible={showPopover}
      >
        <Button onClick={togglePopOver}>
          <ButtonText isMobile={isMobile}>
            {selectedOption.label}
          </ButtonText>
          {showPopover
            ? (
              <ArrowUpIcon 
                fill='#4b4b4b'
                height="24px"
                width="24px"
                viewBox="0 0 24 24"
              />
            )
            : (
              <ArrowDownIcon 
                fill='#4b4b4b'
                height="24px"
                width="24px"
                viewBox="0 0 24 24"
              />
            )
          }
        </Button>
      </Popover>

      {showPopUpCalendar && (
        <PopUpCalendar
          onClickOutside={closePopUpCalendar}
          handleClose={closePopUpCalendar}
          visible={showPopUpCalendar}
          handleChangeDate={handleChangeCalendar}
          selectedDate={calendarDate}
          onApplyDate={handleApplyCalendar}
          placement='bottom-end'
        />
      )}
    </div>
  )
}