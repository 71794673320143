// import * as githubActions from './actions'

const INITIAL_STATE = {
    data: [],
    messages: [],
    creditsRequested: false,
    enableSolicitation: false,
    error: false,
    loading: false,
    errorButton: false,
    loadingButton: false,
};
  
function reducer (state = INITIAL_STATE, action) {
    switch (action.type) {
        case 'LOAD_CREDITS':
            return {
                ...state,
                loading: true,
                error: false
            };

        case 'LOAD_CREDITS_SUCCESS':
            return {
                ...state,
                data: action.data,
                enableSolicitation: action.data.enable_solicitation,
                messages: action.messages,
                loading: false,
            };

        case 'LOAD_CREDITS_FAILURE':
            return {
                ...state,
                loading: false,
                error: true
            };

        case 'SELECT_ENTITY':
            return {
                ...state,
                [action.entity]: action.code
            };

        case 'REQUEST_CREDITS':
            return {
                ...state,
                creditsRequested: true,
                loadingButton: true,
            };

        case 'REQUEST_CREDITS_SUCCESS':
            return {
                ...state,
                messages: action.payload.messages,
                enableSolicitation: false,
                loadingButton: false,
            };

        case 'REQUEST_CREDITS_FAILURE':
            return {
                ...state,
                messages: action.payload.messages,
                errorButton: true,
                loadingButton: false,
            };
            
        default:
            return state;
    }
}

export { reducer };