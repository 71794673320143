import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

import { ReviewStatus } from './review.types'

interface Error {
  response: {
    status: number
  }
}

interface ChangeReviewStatusProps {
  reviewId: number
  newStatus: ReviewStatus
}

export const changeReviewStatus = ({
  reviewId,
  newStatus,
}: ChangeReviewStatusProps): Promise<void> => {

  //eslint-disable-next-line
  const endpoint = apiEndpoints.ME_REVIEW(reviewId)

  const params = {
    new_status: newStatus,
  }

  return new Promise<void> ((resolve, reject) => {
    axios.patch(endpoint, params)
      .then(() => resolve())
      .catch((error: Error) => reject(error.response.status))
  })
}