import styled from 'styled-components'

import { LabelOptions } from './'
 
interface LabelProps {
  label: LabelOptions
}

interface ResponsiveProps {
  isMobile: boolean
}

interface ArchiveButtonContentProps {
  loading: boolean
}

interface ShapeTextProps {
  maxWidth: number
}

export const Container = styled.li<ResponsiveProps>`
  display: flex;
  gap: 15px;
  padding: 25px 0;
  flex-flow: ${({ isMobile }) => (isMobile ? 'wrap' : 'nowrap')};
`

export const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const Content = styled.div`
  width: 100%;
`

export const Title = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
`

export const ArchiveButtonContent = styled.div<ArchiveButtonContentProps>`
  height: 36px;
  width: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ loading }) => loading && '#f4f5fb'};
  border-radius: 5px;

  :hover {
    background: #f4f5fb;
  }
`

export const Label = styled.span<LabelProps>`
  background-color: ${({ label }): string => {
    switch (label) {
    case LabelOptions.VIEWED: return '#FD2928'
    case LabelOptions.ARCHIVED: return '#000000'
    case LabelOptions.PUBLISHED: return '#4AC326'
    }
  }};
  color: #fff;
  font-size: 9px;
  line-height: 9px;
  padding: 3px 5px;
  border-radius: 6px;
  font-weight: bold;
  margin-top: 1px;
`
export const Actions = styled.div<ResponsiveProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '20px')};
`
export const Loading = styled.div<ResponsiveProps>`
  width: 100%;
  padding: 35px 0 30px;
  display: flex;
  gap: 15px;
  position: relative;
  flex-flow: ${({ isMobile }) => (isMobile ? 'wrap' : 'nowrap')};
`

export const ShapeWrapper = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const ShapeAvatar = styled.div`
  width: 50px;
  height: 50px;
`

export const ShapeContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
`

export const ShapeText = styled.div<ShapeTextProps>`
  max-width: ${({ maxWidth }) => (maxWidth)}px;
`

export const ShapeActions = styled.div<ResponsiveProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: ${({ isMobile }) => (isMobile ? '100%' : 'auto')};
`

export const ShapeSeparator = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
`

export const ListContainer = styled.ul`
  li {
    border-bottom: 1px solid #E2E2E2;
  }
  li:last-child {
    border-bottom: none;
  }
`