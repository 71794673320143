import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import { Trans } from '@lingui/macro';

function CardPlan({
  onClickProfessionals,
  planId, 
  planImage, 
  planName, 
  planStatusWeight,
  planStatusName,
}) {
  return (
    <div className="CardPlan"
      id={`plan-${planId}`}>
      <div className="CardPlan__Logo">
        <img className="CardPlan__Logo__Image" 
          src={planImage} 
          alt={planName}
        />
      </div>
      <div className="CardPlan__Center">
        <div className="CardPlan__Center__PlanData">
          <div className="CardPlan__Center__PlanData__Name">
            <Typography bold>
              {planName}
            </Typography>
          </div>
          <div className={`CardPlan__Center__PlanData__Status--${planStatusWeight}`}>
            <CoreIcons height='13px' name="StatusIcon" width='13px' />
            <Typography bold>
              {planStatusName}
            </Typography>
          </div>
        </div>
        <div className="CardPlan__Center__Professionals"
          id={`go-to-home-${planId}`}>
          {
            planStatusWeight === 'positive' &&(
              <Typography bold
                color="primary"
                onTextClick={onClickProfessionals}>
                <Trans>
                Ver profissionais
                </Trans>
              </Typography>
            )
          }
        </div>
      </div>
      <div className="CardPlan__Menu">
        {/**
          * FUTURE IMPLEMENTATION
          */}
      </div>
    </div>
  );
}

CardPlan.propTypes = {
  onClickProfessionals: PropTypes.func,
  planId: PropTypes.any, 
  planImage: PropTypes.string, 
  planName: PropTypes.string, 
  planStatusWeight: PropTypes.string,
  planStatusName: PropTypes.string,
};

export { CardPlan };
