import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleScheduleProfileCode } from 'utils'

// STORE
import { showModal } from '../../redux/actions'

// COMPONENTS
import { CoreIcons } from 'components'
import ToggleFloatButton from '../ToggleFloatButton'
import * as scheduleStatus from 'settings/_scheduleStatusSettings'
import { DATE_DISPLAYER_STATUS } from 'settings/_dateDisplayerStatusSettings'

import { modalTypes } from '../../containers/ModalManager/ModalManager'
import { Badge, DateDisplayer, Typography } from '@telavita-core/react-design-kit'

function ConsultationLists({
  psychologistName,
  patientName,
  sessionId,
  id,
  status,
  menuList,
  planFullName,
  consultationEvaluation,
  onShowModal,
  virtualRoomHash,
  professionalType,
  showMedicalReturnBadge = false,
  startDate
}) {
  function openLogModal() {
    onShowModal(
      modalTypes.LOGS_VIRTUAL_ROOM,
      { virtualRoomHash }
    )
  }

  function openCanceledDetailModal() {
    onShowModal(
      modalTypes.CANCELED_DETAIL_MODAL,
      { id }
    )
  }

  function renderExtraButtonByStatus(status) {
    switch (status) {

    case scheduleStatus.SCHEDULED:
    case scheduleStatus.ATTENDED:
    case scheduleStatus.UNATTENDED:
    case scheduleStatus.PROFESSIONAL_NO_SHOW:
    case scheduleStatus.PATIENT_NO_SHOW:
      return menuList && menuList.length && menuList.length > 0 ?
        <div className='menuButton'>
          <ToggleFloatButton menuList={menuList}
            fill='#4B4B4B'
            iconName='MoreOptions'
          />
        </div>
        :
        <div className='menuButton__NoIcon'></div>

    case scheduleStatus.ATTENDING:
    case scheduleStatus.RETURNED:
      return <div className='menuButton' onClick={openLogModal}>
        <CoreIcons
          name="Info"
          fill="#4B4B4B"
          height="24px"
          width="24px"
          viewBox="2 2 20 20"
        />
      </div>

    case scheduleStatus.CANCELED:
      return <div className='menuButton' onClick={openCanceledDetailModal}>
        <CoreIcons
          name="Info"
          fill="#4B4B4B"
          height="24px"
          width="24px"
          viewBox="2 2 20 20"
        />
      </div>

    case scheduleStatus.SCHEDULE_NO_PARTICIPANTS:
      return menuList && menuList.length && menuList.length > 0 ?
        <div className='menuButton'>
          <ToggleFloatButton menuList={menuList}
            fill='#4B4B4B'
            iconName='MoreOptions'
          />
        </div>
        :
        <div className='menuButton__NoIcon'></div>
    default:
      return <div className='menuButton__NoIcon'></div>
    }
  }

  return (
    <div className='ConsultationLists'>
      {/*StatusBox Section*/}

      <div className='ConsultationLists__DateDisplayerWrapper'>
        <DateDisplayer
          date={new Date(startDate)}
          label={DATE_DISPLAYER_STATUS[status]?.label}
          variant={DATE_DISPLAYER_STATUS[status]?.variant}
          labelTextColor={DATE_DISPLAYER_STATUS[status]?.labelTextColor}
          hourTextColor={DATE_DISPLAYER_STATUS[status]?.hourTextColor}
          weekdayColor={DATE_DISPLAYER_STATUS[status]?.hourTextColor}
          showDay={false}
          icon={DATE_DISPLAYER_STATUS[status]?.icon}
          iconColor={DATE_DISPLAYER_STATUS[status]?.iconColor}
          customClassName='ConsultationLists__DateDisplayer'
        />
      </div>

      {/* Content Section */}
      <div className='ConsultationLists__content'>
        <div className='ConsultationLists__content__participants'>
          <div className='content__psychologist'>
            {
              consultationEvaluation ?
                <Typography variant='content3' weight='bolder' color='success'>
                  Consulta de avaliação de
                </Typography>
                :
                sessionId ?
                  <Typography variant='content3' weight='bolder'>
                    <span>{sessionId}ª</span> consulta do paciente
                  </Typography>
                  :
                  <Typography variant='content3' weight='bolder'>
                    Consulta do paciente
                  </Typography>
            }
            <Typography variant='content2'>
              {patientName}
            </Typography>
          </div>

          <div className='content__patient'>
            <Typography variant='content3' weight='bolder'>
              Com o {handleScheduleProfileCode(professionalType)}
            </Typography>

            <Typography variant='content2'>
              {psychologistName}
            </Typography>
          </div>
        </div>

        <div className='ConsultationLists__info'>
          {showMedicalReturnBadge && (
            <div className='ConsultationLists__BadgeWrapper'>
              <Badge colorScheme='grey'>
                <Typography variant='content3' weight='bold' inheritColor>
                  CONSULTA DE RETORNO
                </Typography>
              </Badge>
            </div>
          )}
          <div className="ConsultationLists__subInfo">
            <div className='ConsultationLists__info__id'>
              <Typography variant='content3'>ID {id}</Typography>
            </div>

            <div className='ConsultationLists__info__session'>
              <Typography variant='content3'>{planFullName}</Typography>
            </div>
          </div>
        </div>
      </div>

      {renderExtraButtonByStatus(status)}
    </div>
  )
}

ConsultationLists.propTypes = {
  psychologistName: PropTypes.string,
  patientName: PropTypes.string,
  sessionId: PropTypes.any,
  id: PropTypes.any,
  time: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  menuList: PropTypes.any,
  List: PropTypes.any,
  planFullName: PropTypes.string,
  consultationEvaluation: PropTypes.bool,
}

const mapDispatchToProps = dispatch => {
  return { onShowModal: (modal, data) => dispatch(showModal(modal, data)) }
}

const ConsultationListsConnected = connect(null, mapDispatchToProps)(ConsultationLists)

export { ConsultationListsConnected as ConsultationLists }
