import React from 'react'
import { 
  Button, 
  Calendar, 
  Popover, 
  Typography, 
} from '@telavita-core/react-design-kit'

import { PopUpCalendarProps } from './types'
import { Container, Footer } from './styles'

export const PopUpCalendar = ({ 
  visible,
  onClickOutside,
  handleChangeDate,
  selectedDate,
  onApplyDate,
  handleClose,
  placement,
  allowRange = true,
  allowPartialRange = true
}: PopUpCalendarProps): JSX.Element => {
  return (
    <Popover
      visible={visible}
      onClickOutside={onClickOutside}
      placement={placement}
      content={
        <Container>
          <Typography
            customClassName='title'
            variant="content2"
          >
            Personalize a data
          </Typography>

          <Calendar 
            allowRange={allowRange}
            allowPartialRange={allowPartialRange}
            handleChangeDate={handleChangeDate} 
            selectedDate={
              selectedDate.length === 1
                ? [selectedDate[0], selectedDate[0]]
                : [selectedDate[0], selectedDate[1]]
            }
          />

          <Footer>  
            <Button 
              onClick={handleClose}
              customClassName='action-btn'
              variant='text'
              uppercase={false}
            >
              Cancelar
            </Button>

            <Button 
              onClick={onApplyDate}
              customClassName='action-btn'
              variant='contained'
              uppercase={false}
              disabled={!selectedDate.length}
            >
              Aplicar
            </Button>
          </Footer>

        </Container>
      }
    >
      <div />
    </Popover>
  )
}
