import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';
import { connect } from 'react-redux';
import { format } from 'date-fns'

import Modal from '../Modal';
import NotificationStep from '../../../../components/NotificationStep';
import Row from '../../../../components/Row';
import { Typography, Button, Column, DateInput, Spinner } from 'components';
import {
  FreeTextInput,
  IntegerInput,
} from '../../../../components/TextInput/Inputs';

import * as actions from '../../../../redux/actions';
import { dateToIso, toDatetime, toString } from '../../../../utils/dateTimeHandlers';
import { PERSON_STATUS } from '../../../../settings/_personSettings';

class ModalApproveValidity extends Component {

  static propTypes = {
    cardNumber: PropTypes.string,
    username: PropTypes.string,
    validityId: PropTypes.number,
  };

  static defaultProps = {
    username: "",
    cardNumber: "",
  };

  state = {
    isLoading: false,
    isSubmitButtonEnabled: false,
    showReview: false,
    success: false,
    fields: {
      start_date: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      start_hour: {
        value: new Date(),
        hasError: false,
        errorMessage: "",
      },
      password: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      max_appointment: {
        value: "",
        hasError: false,
        errorMessage: "",
      },
      validity_expiry_days: {
        value: "",
        hasError: false,
        errorMessage: "",
      }
    },
  }

  componentDidUpdate(_, prevState) {
    if (prevState.fields !== this.state.fields) {
      this.checkRequiredFields();
    }
  }

  clearMedicalRequestReducer = () => {
    this.props.onClearMedicalRequest();
  }

  formatHour = () => 
    format(this.state.fields.start_hour.value, 'HH:mm')
    
  createDateTime = () => {
    const formattedDate = dateToIso(this.state.fields.start_date.value)
    const formattedHour = this.formatHour()
    const formattedDateTime = toDatetime(formattedDate, formattedHour)
    
    return toString(formattedDateTime)
  }

  checkRequiredFields = () => {

    let fieldsAreValid = true;

    const requiredFields = [
      "start_date",
      "start_hour",
      "password",
      "max_appointment",
      "validity_expiry_days"
    ];

    requiredFields.map(fieldKey => {
      if (this.state.fields[fieldKey].value === "" || this.state.fields[fieldKey].hasError) {
        fieldsAreValid = false;
      }
      return fieldKey
    });

    this.setState({ isSubmitButtonEnabled: fieldsAreValid });
  }

  updateFieldState = async (fieldKey, value) => {
    this.setState({
      fields: {
        ...this.state.fields,
        [fieldKey]: value,
      },
    });
  }

  handleCloseButton = () => {
    this.props.onSuccessAction ? this.props.onSuccessAction() : this.clearMedicalRequestReducer();
    this.props.onHideModal();
  }

  handleSubmit = async () => {
    this.setState({ isLoading: true })
    let payload = {
      status_code: PERSON_STATUS.VALID.code,
      start_date: this.createDateTime(),
      plan_password: this.state.fields.password.value,
      max_appointment: parseInt(this.state.fields.max_appointment.value),
      validity_expiry_days: parseInt(this.state.fields.validity_expiry_days.value)
    };

    await this.props.onChangeMedicalRequest(
      this.props.validityId, payload)
    if (this.props.medicalRequestSuccess) {
      this.setState({ isLoading: false, success: true })
    }
  }

  renderContentBasedOnSuccess = () => {
    return this.state.success ?
      this.renderUpdateSuccess() :
      this.renderValidityForm()
  }

  renderSpinner = () => (<Spinner height="250px" />)

  renderUpdateSuccess = () => {

    const notificationStepContent = (
      <Row justify="center">
        <Typography bold
          align="center"
          style={{ maxWidth: "33rem" }}
          type="heading2">
          <Trans>Validação efetuada com sucesso!</Trans>
        </Typography>
      </Row>
    );

    return (
      <div className="ModalApproveValidity__Data">
        <NotificationStep
          buttonId="close-modal"
          btnText="Fechar"
          mainText={notificationStepContent}
          onSuccessClick={this.handleCloseButton}
          iconColor="#4ac326"
          iconName="Check"
          hasButton
        />
      </div>
    );
  }

  renderForm = () => {
    return <Column>
      <div className="ModalApproveValidity__Data__Fields">
        <div className="ModalApproveValidity__Data__Fields__Input">
          <DateInput
            id="start-date"
            placeholder="Data de aprovação"
            onComponentUpdate={(value) => this.updateFieldState("start_date", value)}
            value={this.state.fields.start_date.value}
          />
        </div>
      </div>
      <div className="ModalApproveValidity__Data__Fields">
        <div className="ModalApproveValidity__Data__Fields__Input">
          <FreeTextInput
            id="password"
            placeholder="Senha"
            onComponentUpdate={(value) => this.updateFieldState("password", value)}
            value={this.state.fields.password.value}
          />
        </div>
      </div>
      <div className="ModalApproveValidity__Data__Fields--block">
        <Typography align="center">Quantos créditos?</Typography>
        <div className="ModalApproveValidity__Data__Fields__Input">
          <IntegerInput
            id="max_appointment"
            placeholder="Digite a quantidade"
            onComponentUpdate={(value) => this.updateFieldState("max_appointment", value)}
            value={this.state.fields.max_appointment.value}
          />
        </div>
      </div>
      <div className="ModalApproveValidity__Data__Fields--block">
        <Typography align="center">Válidos por quantos dias?</Typography>
        <div className="ModalApproveValidity__Data__Fields__Input">
          <IntegerInput
            id="validity_expiry_days"
            placeholder="Digite o número de dias"
            onComponentUpdate={(value) => this.updateFieldState("validity_expiry_days", value)}
            value={this.state.fields.validity_expiry_days.value}
          />
        </div>
      </div>
    </Column>
  }

  renderReview = () => {
    return <Column>
      <div className="ModalApproveValidity__Data__Review__BoxInfo">
        <Typography align='center'>
          <Trans>Data de aprovação:</Trans>
        </Typography>
        <Typography align='center' bold>
          {this.state.fields.start_date.value}
        </Typography>
      </div>
      <div className="ModalApproveValidity__Data__Review__BoxInfo">
        <Typography align='center'>
          <Trans>Hora da aprovação:</Trans>
        </Typography>
        <Typography align='center' bold>
          {this.formatHour()}
        </Typography>
      </div>
      <div className="ModalApproveValidity__Data__Review__BoxInfo">
        <Typography align='center'>
          <Trans>Senha:</Trans>
        </Typography>
        <Typography align='center' bold>
          {this.state.fields.password.value}
        </Typography>
      </div>
      <div className="ModalApproveValidity__Data__Review__BoxInfo">
        <Typography align='center'>
          <Trans>Créditos liberados:</Trans>
        </Typography>
        <Typography align='center' bold>
          {this.state.fields.max_appointment.value} créditos
        </Typography>
      </div>
      <div className="ModalApproveValidity__Data__Review__BoxInfo">
        <Typography align='center'>
          <Trans>Validade dos créditos:</Trans>
        </Typography>
        <Typography align='center' bold>
          {this.state.fields.validity_expiry_days.value} dias
        </Typography>
      </div>
    </Column>
  }

  renderModalTitle = () => {
    return this.state.success ? null : (
      this.state.showReview ?
        <Trans>Confira os dados da validação</Trans> :
        <Trans>Validar Solicitação</Trans>
    );
  }

  renderReviewButtons = () => {
    return <Row>
      <Column colWidth={6}>
        <Button
          text={(<Trans>Editar</Trans>)}
          id="edit-validity-medical-request"
          classModifier="reverse"
          onButtonClick={() => this.setState({ showReview: false })}
        />
      </Column>
      <Column colWidth={6}>
        <Button
          text={(<Trans>Confirmar</Trans>)}
          id="send-medical-request"
          onButtonClick={this.handleSubmit}
        />
      </Column>
    </Row>
  }

  renderFormButtons = () => {
    return <Row>
      <Column>
        <Button
          disabled={!this.state.isSubmitButtonEnabled}
          text={(<Trans>Enviar</Trans>)}
          id="next-step-medical-request"
          onButtonClick={() => this.setState({ showReview: true })}
        />
      </Column>
    </Row>
  }

  renderValidityForm = () => {
    const { username, cardNumber } = this.props;
    const { showReview } = this.state;

    return (
      <Fragment>
        <div className="ModalApproveValidity__Data">
          <Row margin="0.5rem 0 1.5rem 0">
            <Row>
              <Column>
                <Typography align='center' color="grey-ten">
                  <Trans>Paciente:</Trans>{' '}
                  <Typography Tag="span" bold>{username}</Typography>
                </Typography>
              </Column>
            </Row>
            <Row>
              <Column>
                <Typography align='center' color="grey-ten">
                  <Trans>Carteirinha:</Trans>{' '}
                  <Typography Tag="span" bold>{cardNumber}</Typography>
                </Typography>
              </Column>
            </Row>
            <Row margin="20px 0 0">
              {showReview ? this.renderReview() : this.renderForm()}
            </Row>
          </Row>
        </div>
        {showReview ? this.renderReviewButtons() : this.renderFormButtons()}
      </Fragment>
    );
  }

  render() {
    return (
      <div className="ModalApproveValidity">
        <Modal
          btnId="submit-approval"
          modalTitle={this.renderModalTitle()}
          hasButton={false}
          closeModal={this.handleCloseButton}
          extraClassName>
          {
            this.state.isLoading ?
              this.renderSpinner() :
              this.renderContentBasedOnSuccess()
          }
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuth: state.auth.isAuth,
  medicalRequestSuccess: state.medicalRequests.success,
});

const mapDispatchToProps = dispatch => ({
  onHideModal: () => dispatch(actions.hideModal()),
  onChangeMedicalRequest: (id, params) => dispatch(actions.changeMedicalRequest(id, params)),
  onClearMedicalRequest: () => dispatch(actions.clearMedicalRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalApproveValidity);