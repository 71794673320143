import clsx from 'clsx'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import React, { useEffect, useState } from 'react'
import { useDimensions, Typography as Typo, Card } from '@telavita-core/react-design-kit'

import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'
import { showIntercom } from '../../../../utils/intercomHandlers'

import { NextScheduleCard } from './NextScheduleCard'
import { ReservationCard } from './ReservationCard'
import { CoreIcons, Typography } from 'components'
import ProfessionalChip from 'components/ProfessionalChip'
import FloatActionButtonDropdown from 'components/FloatActionButtonDropdown/FloatActionButtonDropdown'
import { CreditsCard } from 'components/CreditsCard/CreditsCard'
import * as routes from 'settings/_routesSettings'
import Row from 'components/Row'
import Column from 'components/Column'
import * as actions from '../../../../redux/actions'
import * as filterHomepageActions from '../../../../redux/modules/filterHomepage/actions'
import { usePatientEligibility } from 'hooks/usePatientEligibility'

const { REACT_APP_MEET_TEST_URL } = process.env

const PatientHome = () => {
  const dispatch = useDispatch()
  const routerHistory = useHistory()
  const { isMobile } = useDimensions()
  const [professionalHistory, setProfessionalHistory] = useState([])
  const [loadingHistory, setLoadingHistory] = useState(false)
  const { person_general: { first_name: firstName } } = useSelector(s => s.auth.userData)

  const {
    isEnterprise,
    isCorporate,
    hasButton,
    setHasButton,
    planGroupName,
    creditsStatus,
    availableCredits,
    maxCredits,
    enableDetails,
    validDate,
    expired,
    errorButton,
    loadingButton,
    solicitationDate,
    isUnlimited,
    startDate,
    corporateEligibility,
    loading,
    forceUpdate,
    onClickRequestCreditsButton
  } = usePatientEligibility()

  useEffect(() => {
    showIntercom()
    setLoadingHistory(true)

    axios.get(apiEndpoints.PROFESSIONAL_HISTORY)
      .then((resp) => {
        const history = resp.data.professionals
        setProfessionalHistory(history)
        setLoadingHistory(false)
      })
      .catch((err) => {
        console.log(err)
        setProfessionalHistory([])
        setLoadingHistory(false)
      })
  }, [])

  const containerStyle = clsx({
    'PatientHome': true,
    'PatientHome__mobile': isMobile
  })

  const renderCreditsCard = () =>
    <CreditsCard 
      isEnterprise={isEnterprise}
      isCorporate={isCorporate}
      hasButton={hasButton}
      setHasButton={setHasButton}
      planGroupName={planGroupName}
      creditsStatus={creditsStatus}
      availableCredits={availableCredits}
      maxCredits={maxCredits}
      enableDetails={enableDetails}
      validDate={validDate}
      expired={expired}
      errorButton={errorButton}
      loadingButton={loadingButton}
      solicitationDate={solicitationDate}
      isUnlimited={isUnlimited}
      startDate={startDate}
      corporateEligibility={corporateEligibility}
      loading={loading}
      onClickRequestCreditsButton={onClickRequestCreditsButton}
      forceUpdate={forceUpdate}
    />
  

  const renderMediaTestCard = () => {
    return (
      <Card customClassName="PatientHome__Card">
        <Typo variant='content1'>Teste sua câmera e microfone</Typo>
        <Typo variant='content3' color='light' style={{marginTop: 5}} >
          Assegure-se de que vai funcionar no seu dispositivo
        </Typo>
        <Row margin="30px 0">
          <CoreIcons
            name="MediaTest"
            fill="#dde1f4"
            width="100%"
            height="50"
            viewBox="0 0 190 50"
          />
        </Row>
        <Typography Tag='a' onTextClick={() => window.open(REACT_APP_MEET_TEST_URL, '_self')}>
          Faça uma consulta de simulação
        </Typography>
      </Card>
    )
  }

  const renderContent = () => {
    if (!isMobile)
      return (
        <>
          <Column colWidth={6}>
            <NextScheduleCard />
            {renderMediaTestCard()}
          </Column>
          <Column colWidth={6}>
            {renderCreditsCard()}
            <ReservationCard />
          </Column>
        </>
      )

    return (
      <Column colWidth={12}>
        <NextScheduleCard />
        {renderCreditsCard()}
        <ReservationCard />
        {renderMediaTestCard()}
      </Column>
    )
  }

  const handleProfessionalProfileRoute = (professional) => {
    const routeByProfileCode = {
      undefined: routes.HOME_PSIC,
      PSIC: routes.PROFESSIONAL_PSIC_PROFILE(professional.friendly_url),
      PSIQ: routes.PROFESSIONAL_PSIQ_PROFILE(professional.friendly_url),
    }
    dispatch(filterHomepageActions.clearPersons())
    dispatch(actions.clearPersons())
    routerHistory.push(routeByProfileCode[professional.profile_role])
  }

  return (
    <div className={containerStyle}>
      <Row className="Dashboard__Header">
        <Typo variant={!isMobile ? 'header2' : 'header4'}>
          Bem vindo(a), {firstName}
        </Typo>
        <FloatActionButtonDropdown
          icon="PencilEdit"
          title={'Marcar consulta'}
          type="primary"
          loading={loadingHistory}
          options={[
            ...professionalHistory,
            { full_name: 'outro profissional' },
          ]}
          renderOption={(professional) => {
            return (
              <ProfessionalChip
                name={professional.full_name}
                photo={professional.picture_url}
              />
            )
          }}
          onSelectOption={(professional) => {
            handleProfessionalProfileRoute(professional)
          }}
        />
      </Row>
      {renderContent()}
    </div>
  )

}

export { PatientHome }
