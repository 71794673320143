import React, { useEffect } from 'react'
import { Button } from '@telavita-core/react-design-kit'

import { useFilter } from '../../context/FilterContext'

import ModalGeneric from 'containers/ModalManager/Modals/ModalGeneric'
import { Calendar, Plans, Status } from './components'

import * as S from './styles'
import { useQueryClient } from '@tanstack/react-query'
import { ADMIN_SCHEDULES_QUERY_KEY } from 'settings/_queryKeySettings'

interface FilterScheduleModalProps {
  closeModal: () => void
}

export function FilterScheduleModal({ closeModal }: FilterScheduleModalProps): JSX.Element {
  const queryClient = useQueryClient()
  const { state, dispatch } = useFilter()
  
  useEffect(() => {
    if (state.shouldFetch) {
      dispatch({ type: 'SET_SHOULD_FETCH', payload: false })
    }
  }, [])

  function handleSubmitFilters() {
    queryClient.resetQueries([ADMIN_SCHEDULES_QUERY_KEY])
    dispatch({ type: 'SET_SHOULD_FETCH', payload: true })
    closeModal()
  }

  return (
    <ModalGeneric
      hasButton={false}
      modalTitle={'Filtros'}
      onCloseModal={closeModal}
    >
      {/* Filtros do modal */}
      <Calendar />
      <Plans />
      <Status />

      <S.ButtonContainer>
        <Button variant='outlined' onClick={() => dispatch({ type: 'CLEAR_FILTERS' })}>
          Limpar filtros
        </Button>
      </S.ButtonContainer>
      
      <S.ButtonContainer>
        <Button variant='contained' onClick={handleSubmitFilters}>
          Aplicar filtros
        </Button>
      </S.ButtonContainer>

    </ModalGeneric>
  )
}