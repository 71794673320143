import styled from 'styled-components'

interface SideProps {
  isMobile: boolean
}

export const Container = styled.div`
  position: relative;
  height: 100%;
  margin-bottom: 40px;
`

export const Header = styled.header`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`

export const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`

export const SectionSide = styled.section<SideProps>`
  max-width: ${({ isMobile }) => !isMobile && 'calc(50% - 10px)'};
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`
