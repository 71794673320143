import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// import Dropdown from '../../../../components/Dropdown';
import Modal from '../Modal';
import TextDetail from '../../../../components/TextDetail';
import Textarea from '../../../../components/Textarea';
import { Typography } from 'components';

import viewportHoc from '../../../../hoc/viewportHoc';
import { setScheduleStatus, hideModal } from '../../../../redux/actions';
import { getSchedules } from 'redux/actions/schedulesActions';
import { CANCELED, CANCEL_REQUESTED } from '../../../../settings/_scheduleStatusSettings';
import { PATIENT } from '../../../../settings/_profileSettings';

class ModelCancel extends Component {

  state = {
    visibility: true,
    detail: '',
  }

  dropdownProps = { placeholder: 'Selecione um motivo' };

  handleChange = (event) => {
    this.setState({ detail: event.target.value });
  }

  cancelAppointment = () => {
    this.props.closeModal();
    window.location.reload();
  }

  handleCancelButton = () => {
    const { detail } = this.state
    const userRole = this.props.userData.session.profile_role_active;
    let cancelStatus;

    switch (userRole) {
      case PATIENT:
        cancelStatus = CANCELED;
        break;

      default:
        cancelStatus = CANCEL_REQUESTED;
        break;
    }

    const scheduleData = {
      statusCode: cancelStatus,
      details: detail,
    }

    this.props.onCancel(
      this.props.scheduleId,
      scheduleData
    )
      .then(() => this.cancelAppointment())
      .catch((err) => console.log(err));
  }

  render() {

    const {
      btnDisabled,
      btnId,
      btnText,
      hasButton,
      modalTitle,
      titleColor,
      classModifier,
      notificationText,
    } = this.props;

    return (
      <Modal
        btnId={btnId}
        btnText={btnText}
        handleClick={() => this.handleCancelButton()}
        hasButton={hasButton}
        btnDisabled={btnDisabled}
        modalTitle={modalTitle}
        classModifier={classModifier}
        titleColor={titleColor}>
        <div className='Container'>
          <div className='Container__warning'>
            <Typography color='false' align='center'>
              Atenção, o cancelamento é um processo irreversível
            </Typography>
          </div>
          <div className='Container_textbox'>
            <Textarea
              placeholder='Detalhes do cancelamento'
              value={this.state.detail}
              onChange={this.handleChange}
            />
          </div>
          <TextDetail message={notificationText} />
        </div>
      </Modal>
    );
  }
}

ModelCancel.propTypes = {
  classModifier: PropTypes.oneOf([
    'default',
    'false',
    'online',
    'reverse',
    'reverse--grey',
    'reverse--grey--padded',
    'reverse--grey--lighter',
  ]),
  btnDisabled: PropTypes.bool,
  btnId: PropTypes.string,
  btnText: PropTypes.any,
  children: PropTypes.any,
  handleClick: PropTypes.func,
  hasButton: PropTypes.bool,
  modalActive: PropTypes.bool,
  modalTitle: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  notificationText: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    userData: state.auth.userData,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onCancel: (scheduleId, scheduleData) => dispatch(setScheduleStatus(scheduleId, scheduleData)),
    closeModal: () => dispatch(hideModal()),
    onGetSchedules: (getParams) => dispatch(getSchedules(getParams)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(ModelCancel));
