import React from 'react'
import ReactInfiniteScroll from 'react-infinite-scroll-component'
import { Spinner } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface InfiniteScrollProps {
  children: React.ReactNode,
  currentPage?: number,
  dataLength: number,
  totalPages: number,
  requestMethod: (perPage: number, page: number) => void,
  perPage: number,
  emptyRender?: React.ReactNode
}

export function InfiniteScroll({ 
  children, 
  currentPage,
  dataLength,
  totalPages,
  requestMethod,
  perPage,
  emptyRender
}: InfiniteScrollProps): JSX.Element {

  const nextRequest = () => {
    if (currentPage !== undefined) {
      requestMethod(perPage, (currentPage + 1))
    }
  }

  return (
    <ReactInfiniteScroll 
      dataLength={dataLength}
      next={nextRequest}
      loader={<S.Loader><Spinner /></S.Loader>}
      hasMore={!(currentPage >= totalPages)}
      style={{ overflow: 'visible', paddingBottom: '1rem' }}
      endMessage={ dataLength <= 0 && emptyRender }>
      {children}
    </ReactInfiniteScroll>
  )
}