import React from 'react';
import { Bar } from 'react-chartjs-2';

// COMPONENTS
import { ReactComponent as Graph} from 'static/svg/graph.svg';

const ChartBar = ({ data, labels, colors, empty, total }) => {
  function renderChart () {
    return (
      <Bar height={118} data={{
        labels: labels,
        datasets: [{
          data: data,
          backgroundColor: colors,
          borderWidth: 0,
        }]
      }}
        options={{
          tooltips: {
            callbacks: {
              title: function(tooltipItem, data) {
                return data['datasets'][0]['data'][tooltipItem[0]['index']] + ' pessoa(s)';
              },
              label: function(tooltipItem, data) {
                return 'Nota ' + data['labels'][tooltipItem['index']];
              }
            },
            enabled: true,
            backgroundColor: '#FFFFFF',
            titleFontColor: '#898989',
            bodyFontColor: '#898989'
          },
          legend: false,
          scales: {
            xAxes: [{
              ticks: {
                fontColor: '#ACACAC',
              },
              gridLines: {
                drawBorder: true,
                drawOnChartArea: false
              }
            }],
            yAxes: [{
              ticks: {
                
                fontColor: '#ACACAC',
                min: 0,
                max: total,
                stepSize: total/2
              },
              gridLines: {
                drawBorder: true,
                drawOnChartArea: false
              }
            }],
          }
    }}/>)
  }

  return empty ? <Graph style={{'color':'#ACACAC'}} /> : renderChart();
}

export {
  ChartBar
}