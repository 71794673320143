export const getFullPlanName = planSelected => {
    if (planSelected) {
        if (planSelected.plan_group.name) {
            return `${planSelected.plan_group.name} ${planSelected.partner_plan_name}`
        }
        return planSelected.partner_plan_name
    }
    return null
}

export const getFullGroupPlanName = (groupPlan, plan) => {
    return `${groupPlan.name} ${plan.name}`
}

export const getFullPlansName = planSelected => {
    if (planSelected) {
        if (planSelected.plan_group.name) {
            return `${planSelected.plan_group.name} ${planSelected.name}`
        }
        return planSelected.name
    }
    return null
}