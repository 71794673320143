import styled from 'styled-components'

export const Container = styled.div`
  display: flex; 
  width: 100%; 
  margin-top: 30px;
  justify-content: center;

  > button {
    display: flex;
    align-items: center;
    gap: 14px;
    justify-content: center;
  }
`