import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/macro'

import Row from './../../components/Row'
import MenuDashboardItem from './../../components/MenuDashboardItem'
import PermissionValidator from '../PermissionValidator'

import * as permissions from './../../settings/_permissionSettings'
import * as route from '../../settings/_routesSettings'
import { PSYCHOLOGIST } from '../../settings/_profileSettings'

const MenuDashboardContainer = () => {

  return (
    <Row>
      <PermissionValidator permission={permissions.ADMIN_GENERAL_VIEW}>
        <Link to={route.DASHBOARD_GENERAL_VIEW}>
          <MenuDashboardItem
            title={<Trans>Visão Geral</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24',
              name: 'GeneralView',
              viewBox: '0 0 28 22',
              width: '24',
            }}
            id={`${permissions.ADMIN_GENERAL_VIEW}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.USER_VIEW_MYAPPOINTMENTS}>
        <Link to={route.DASHBOARD_APPOINTMENTS}>
          <MenuDashboardItem
            title={<Trans>Minhas consultas</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24px',
              name: 'ListAlt',
              viewBox: '0 0 22 22',
              width: '24',
            }}
            id={`${permissions.USER_VIEW_MYAPPOINTMENTS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.USER_VIEW_AGENDA}>
        <Link to={route.DASHBOARD_AGENDA}>
          <MenuDashboardItem
            title={<Trans>Minha agenda</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24px',
              name: 'Calendar',
              viewBox: '0 0 24 24',
              width: '24',
            }}
            id={`${permissions.USER_VIEW_AGENDA}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.USER_VIEW_PATIENTS}>
        <Link to={route.DASHBOARD_PATIENTS}>
          <MenuDashboardItem
            title={<Trans>Meus pacientes</Trans>}
            icon={{
              fill: '#1F35B5',
              width: '24',
              height: '24px',
              name: 'MyPatients',
            }}
            id={`${permissions.USER_VIEW_PATIENTS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_APPOINTMENTS}>
        <Link to={route.DASHBOARD_ADMIN_SCHEDULES}>
          <MenuDashboardItem
            title={<Trans>Lista de Consultas</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24',
              name: 'ListAlt',
              viewBox: '0 0 24 24',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_APPOINTMENTS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_PATIENTS}>
        <Link to={route.DASHBOARD_CLIENT_LIST}>
          <MenuDashboardItem
            title={<Trans>Pacientes</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'Group',
              viewBox: '3 0 20 20',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_PATIENTS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_PROFESSIONALS}>
        <Link to={route.DASHBOARD_PROFESSIONAL_LIST(PSYCHOLOGIST)}>
          <MenuDashboardItem
            title={<Trans>Profissionais</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'ProfessionalFull',
              viewBox: '3 1 20 20',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_PROFESSIONALS}`}
          />
        </Link>
      </PermissionValidator>

      <Link to={route.DASHBOARD_PLAN_GROUP_LIST_VIEW}>
        <MenuDashboardItem
          title={<Trans>Convênios</Trans>}
          icon={{
            fill: '#1F35B5',
            name: 'GroupPlanBuilding',
            width: '24',
            height: '24',
            viewBox: '0 0 24 24'
          }}
          id='plan-groups'
        />
      </Link>

      <PermissionValidator permission={permissions.ADMIN_VIEW_PAYMENT}>
        <Link to={route.DASHBOARD_PAYMENT_LIST}>
          <MenuDashboardItem
            title={<Trans>Pedidos</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24px',
              name: 'CreditCard',
              viewBox: '1 1 23 23',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_PAYMENT}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_PERMISSIONS}>
        <Link to={route.DASHBOARD_PERMISSIONS}>
          <MenuDashboardItem
            title={<Trans>Permissões</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '24px',
              name: 'PersonCheck',
              viewBox: '0 0 24 24',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_PERMISSIONS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_REPORTS}>
        <Link to={route.DASHBORAD_REPORTS}>
          <MenuDashboardItem
            title={<Trans>Relatório</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'Graphs',
              viewBox: '3 2 19 19',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_REPORTS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.USER_VIEW_PLANS}>
        <Link to={route.DASHBOARD_PLANS}>
          <MenuDashboardItem
            title={<Trans>Convênios</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'Plans',
              viewBox: '3 2 19 19',
              width: '24',
            }}
            id={`${permissions.USER_VIEW_PLANS}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.ADMIN_VIEW_MEDICAL_REQUEST}>
        <Link to={route.DASHBORAD_ADMIN_MEDICAL_REQUESTS}>
          <MenuDashboardItem
            title={<Trans>Solicitações</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'Hourglass',
              viewBox: '0 2 26 19.8',
              width: '24',
            }}
            id={`${permissions.ADMIN_VIEW_MEDICAL_REQUEST}`}
          />
        </Link>
      </PermissionValidator>

      <PermissionValidator permission={permissions.USER_VIEW_MEDICAL_REQUEST}>
        <Link to={route.DASHBORAD_MEDICAL_REQUESTS}>
          <MenuDashboardItem
            title={<Trans>Solicitações</Trans>}
            icon={{
              fill: '#1F35B5',
              height: '20px',
              name: 'Hourglass',
              viewBox: '0 2 26 19.8',
              width: '24',
            }}
            id={`${permissions.USER_VIEW_MEDICAL_REQUEST}`}
          />
        </Link>
      </PermissionValidator>
    </Row>
  )
}

MenuDashboardContainer.propTypes = {
  userPermissions: PropTypes.array,
  userProfile: PropTypes.string,
}

export default MenuDashboardContainer
