import styled from 'styled-components'
import { Typography } from '@telavita-core/react-design-kit'

export const Container = styled.section`
  display: flex;
  padding: 10px 30px 20px;
  justify-content: space-between;
  border-bottom: 1px solid #E2E2E2;
`

export const Icon = styled.span`
  display: flex;
  align-items: center;
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    max-width: 55%;
  }

  svg {
    width: 24px;
    height: 24px;
  }
`

export const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  margin-top: 5px;
`

export const StatisticTitle = styled(Typography)`
  font-size: 35px;
  line-height: 35px;
  letter-spacing: -1.7px;
  font-weight: 700;
`