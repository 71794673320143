import React from 'react'
import { motion } from 'framer-motion'
import { Select, Typography } from '@telavita-core/react-design-kit'

import * as S from './styles'

interface SelectAgeRangeProps {
  handleSelectAgeRange: (id: number | string, label: string) => void
  hasErrorOnAgeRange: boolean
  selectOptions: Array<{id: number | string, label: string, startAge: number, endAge: number}>
  startAge: number
  endAge: number
}

const sliderUpToDownAnimation = {
  initial: { opacity: 0, height: 0, overflow: 'hidden' },
  animate: { opacity: 1, height: 'auto', overflow: 'visible' },
  exit: { opacity: 0, height: 0, overflow: 'hidden' },
  transition: { duration: 0.2 }
}

export const SelectAgeRange: React.FC<SelectAgeRangeProps> = ({ 
  handleSelectAgeRange,
  hasErrorOnAgeRange,
  selectOptions,
  startAge,
  endAge
}) => {
  return (
    <motion.div {...sliderUpToDownAnimation}>
      <S.Container hasError={hasErrorOnAgeRange}>
        <Select
          customClassName='selectAgeRange'
          placeholder='Selecione a faixa de idade *'
          options={selectOptions}
          label=""
          onSelect={({id, label}) => handleSelectAgeRange(id, label)}
          selected={selectOptions.find(option => option.startAge === startAge && option.endAge === endAge)}
        />

        {hasErrorOnAgeRange && (
          <Typography variant='content3' italic color='danger'>
          Defina a faixa
          </Typography>
        )}
      
      </S.Container>
    </motion.div>
  )
}