//Relative path from root folder (src) ../../../

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'components';

class MessageWithDate extends Component {
  render() {
    const {
      message,
      date,
      time,
      align,
      color,
      bold,
    } = this.props;

    return (
      <div className="MessageWithDate">
        <div className='MessageWithDate_date'>  
          <Typography align={align}>
            <Typography Tag='span' color={color}>{message} </Typography>
            <Typography Tag='span' color={color} bold={bold}> {date}</Typography> 
            <Typography Tag='span' color={color}>, às </Typography> 
            <Typography Tag='span' color= {color} bold={bold}> {time}</Typography>   
          </Typography> 
        </div>
      </div>
    );
  }
}

MessageWithDate.propTypes = {
  message: PropTypes.any,
  date: PropTypes.string,
  time: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string, 
  bold: PropTypes.bool, 
};

MessageWithDate.defaultProps = {
  date: '11/03/2019',
  time: '17',
  align: 'center',
  color: '', 
  bold: false, 
};

export default MessageWithDate;
