import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 20px;

  .attentionText {
    margin-bottom: 20px;
    text-align: center;
  }

  .reasonDisclaimerText {
    line-height: 16px;
    width: 320px;
    margin-bottom: 10px;
  }

  .warningPaper {
    margin-top: 20px;
    max-width: 100%;

    > p {
      width: 270px;
    }
  }

  .requiredInfoText {
    color: #898989;
    margin-top: 20px;
  }

  .actionButton {
    width: 100%;
    margin-top: 20px;
  }

  .successTitle {
    line-height: 25px;
    margin-bottom: 5px;
    margin-top: 10px;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px 0;
`