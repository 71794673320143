import React from 'react'

const Add = ({
  fill,
  height,
  viewBox = '0 0 14 14',
  width,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox}>
    <path id="Caminho_2423" data-name="Caminho 2423" d="M19,13H13v6H11V13H5V11h6V5h2v6h6Z" fill={fill} />
  </svg>
)


export default Add
