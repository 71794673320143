import { useSelector } from 'react-redux'
import { format, isBefore, addYears, isAfter, startOfToday } from 'date-fns'

const useProfessionalAgenda = () => {
  const reduxSchedules = useSelector(state => state.schedules.schedules)
  const pagination = useSelector(state => state.schedules.pagination) || {
    pages: 0,
    page: 0,
    per_page: 0,
    total: 0
  }
  const loadingSchedules = useSelector(state => state.schedules.loading)
  const schedulesSuccess = useSelector(state => state.schedules.success)
  const slots = useSelector(state => state.slots.slots)
  const slotsSuccess = useSelector(state => state.slots.success)
  const filters = useSelector((state) => state.filters)

  const hasSchedules = reduxSchedules.length > 0

  const hasFilterActive = Boolean(
    filters.startDate || filters.endDate || filters.status || filters.text
  )

  const schedulesByDate = hasSchedules ? reduxSchedules.reduce((acc, curr) => {
    const formatedDate = format(new Date(curr.start_date), 'MM/dd/yyyy')
    if (acc[formatedDate]) {
      acc[formatedDate] = [...acc[formatedDate], curr]
    } else {
      acc[formatedDate] = [curr]
    }
    return acc
  },{}) : {}

  const lastSchedule = hasSchedules ? new Date(reduxSchedules[reduxSchedules.length - 1].start_date) : addYears(new Date(), 1)

  const schedulesWithSlots = !(!hasSchedules && hasFilterActive) && Object.values(slots).length > 0 ? Object.values(slots).reduce((acc, curr) => {
    const filterBySingleSlots = curr.filter(slot => {

      const isSingleSlot = !slot.repeat_interval
      const isAfterToday = isAfter(new Date(slot.start_date), startOfToday())
      const isBeforeLastSchedule = isBefore(new Date(slot.start_date), lastSchedule)
      const isLastPage = pagination.pages === pagination.page

      return isSingleSlot && isAfterToday && (isBeforeLastSchedule || isLastPage)
    })
      
    for (let i = 0; i < filterBySingleSlots.length; i++) {
      const currentSlot = filterBySingleSlots[i]
      const formatedDate = format(new Date(currentSlot.start_date), 'MM/dd/yyyy')
      
      if (acc[formatedDate]) {
        acc[formatedDate] = [currentSlot, ...acc[formatedDate]]
      } else {
        acc[formatedDate] = [currentSlot]
      }

    }
    return acc
  },schedulesByDate) : {}

  const schedules = orderSchedulesByDate(schedulesWithSlots)

  function orderSchedulesByDate(schedules) {
    let key = Object.keys(schedules)
      .sort(function order(key1, key2) {
        return filters.status && filters.status !== 'ALL' ? new Date(key2) - new Date(key1) : new Date(key1) - new Date(key2)
      }) 
      
    let temp = {}
      
    for (let i = 0; i < key.length; i++) {
      temp[key[i]] = schedules[key[i]]
      delete schedules[key[i]]
    } 

    for (let i = 0; i < key.length; i++) {
      schedules[key[i]] = temp[key[i]]
    } 
    return schedules
  }

  return {
    schedules,
    pagination,
    loading: loadingSchedules || !slotsSuccess,
    loaded: schedulesSuccess && slotsSuccess
  }
}

export { useProfessionalAgenda }