import React from "react";

const MagnifyingGlass = ({
  fill,
  width,
  height,
  viewBox,
}) => (
  <svg
    height={height}
    fill={fill}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg" >
    <g>
      <path fill='#898989' d="M365.325,396.234l-21.087-21.126.121-.142A21.782,21.782,0,0,0,327.865,339q-.844,0-1.694.065a21.781,21.781,0,1,0,15.843,38.248l.143-.123L363.268,398.3Zm-37.457-16.159h0a19.282,19.282,0,1,1,.028,0Z" transform="translate(-306.047 -339)"/>

    </g>
  </svg>
);

export default MagnifyingGlass;