import React from 'react'
import SearchPlaceholder from 'components/SearchPlaceholder'

import * as S from './styles'

interface EmptyMessageProps {
  showZeroResultsMessage: boolean
}

export function EmptyMessage({ showZeroResultsMessage }: EmptyMessageProps): JSX.Element {
  return (
    <S.Container>
      {showZeroResultsMessage
        ? (
          <SearchPlaceholder
            titleText='Nenhum resultado encontrado'
            text='Refaça sua busca ou utilize outros filtros.'
          />
        )
        : (
          <SearchPlaceholder
            titleText='Faça uma busca por nome ou e-mail'
            text='Utilize os filtros para refinar sua busca.'
          />
        )
      }
    </S.Container>
  )
}
