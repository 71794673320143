import React from 'react'

const ChatBubble = ({
  fill,
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox={viewBox}
  >
    <path
      d="M0,0H24V24H0Z"
      fill="none"
    />
    <path
      d="M20,2H4A2.006,2.006,0,0,0,2,4V22l4-4H20a2.006,2.006,0,0,0,2-2V4A2.006,2.006,0,0,0,20,2Zm0,14H6L4,18V4H20Z"
      fill={fill}
    />
  </svg>
)

export default ChatBubble
