import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, Column, CoreIcons } from 'components';
import Row from '../Row';

const ResultNotFound = ({
  iconName,
  title,
  text,
}) => {
  return (
    <div className='ResultNotFound'>
      <Row direction='row' justify='center' margin='4.62rem 0 1.15rem 0'>
        <CoreIcons
          fill={'#4b4b4b'}
          height={'64px'}
          name={iconName}
          width={'64px'}
        />
      </Row>

      <Row direction='row' justify='center'>
        <Column>
          <Typography align='center' type='paragraph-medium'>{title}</Typography>
        </Column>
        <Column>
          <Typography align='center' type='paragraph'>{text}</Typography>
        </Column>
      </Row>
    </div>
  );
};

ResultNotFound.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

ResultNotFound.defaultProps = {
  iconName: 'Search',
  title: '',
  text: '',
};

export default ResultNotFound;
