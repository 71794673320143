/*
 * React Day Picker settings
 */

export const FORMAT = 'DD MMM'

export const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
]

export const WEEKDAYS_SHORT = [
  'Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'
]

export const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
]

export const WEEKDAYS_NAMES = {
  0: 'Domingos',
  1: 'Segundas-feiras',
  2: 'Terças-feiras',
  3: 'Quartas-feiras',
  4: 'Quintas-feiras',
  5: 'Sextas-feiras',
  6: 'Sábados',
}
