// import * as githubActions from './actions'

const INITIAL_STATE = {
  professionals: [],
  pagination: {},
  professionalsTotal: 0,
  renderedPersons: [],
  skills: [],
  themes: [],
  subjects: [],
  approaches: [],
  actualTheme: '',
  actualSubject: '',
  actualApproach: '',
  genderFilter: null,
  previousTheme: '',
  previousSubject: '',
  previousApproach: '',
  previousGender: '',
  page: 1,
  loading: false,
  error: false,
  loadingSkills: false,
  errorSkills: false
}
  
function reducer (state = INITIAL_STATE, action) {
  switch (action.type) {
  case 'RUN_LOADER':
    return {
      ...state,
      loading: true,
    }

  case 'STOP_LOADER':
    return {
      ...state,
      loading: false,
    }

  case 'SET_PAGINATION':
    return {
      ...state,
      pagination: { ...action.payload },
    }

  case 'UPDATE_PAGE':
    return {
      ...state,
      page: state.page + 1,
    }

  case 'ADD_IDS':
    return {
      ...state,
      renderedPersons: [...state.renderedPersons, action.payload],
    }

  case 'CLEAR_IDS':
    return {
      ...state,
      renderedPersons: [],
    }

  case 'GET_SKILLS':
    return {
      ...state,
      loadingSkills: true
    }

  case 'GET_SKILLS_SUCCESS':
    return {
      ...state,
      skills: action.payload.grouped_skills,
      approaches: action.payload.approaches,
      loadingSkills: false,
      errorSkills: false
    }

  case 'GET_SKILLS_FAILURE':
    return {
      ...state,
      loadingSkills: false,
      errorSkills: true
    }

  case 'SET_ACTUAL_THEME':
    return {
      ...state,
      actualTheme: action.payload,
      subjects: state.skills.filter(subject => subject.parent_code === action.payload),
    }

  case 'SET_ACTUAL_FILTER':
    return {
      ...state,
      [action.payload.property]: action.payload.value,
    }

  case 'CLEAR_FILTERS':
    return {
      ...state,
      actualTheme: '',
      actualSubject: '',
      actualApproach: '',
    }

  case 'GET_PERSONS':
    return {
      ...state,
      professionals: [],
      pagination: {},
      professionalsTotal: 0,
      renderedPersons: [],
      loading: true,
    }

  case 'GET_PERSONS_SUCCESS':
    return {
      ...state,
      professionals: action.payload.persons,
      pagination: action.payload.pagination,
      loading: false
    }

  case 'GET_PROFESSIONALS':
    return {
      ...state,
      loading: true,
    }

  case 'CLEAR_PROFESSIONALS':
    return {
      ...state,
      professionals: [],
      pagination: {},
      renderedPersons: [],
      professionalsTotal: 0,
    }

  case 'GET_PROFESSIONALS_SUCCESS':
    return {
      ...state,
      professionals: [...state.professionals, ...action.payload.persons],
      pagination: action.payload.pagination,
      renderedPersons: [...state.renderedPersons, ...action.payload.persons.map(item => item.id)],
      professionalsTotal: action.payload.pagination.total,
      loading: false
    }

  case 'GET_PROFESSIONALS_FAILURE':
    return {
      ...state,
      // professionals: action.payload.persons,
      loading: false,
      error: true
    }

  case 'CLEAR_PERSONS':
    return {
      ...state,
      professionals: []
    }

  default:
    return state
  }
}

export { reducer }