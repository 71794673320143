import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ classModifier, styleModfier, ...props }) => {
  return (
    <div style={styleModfier}
      className={classModifier ? `Card--${classModifier}` : 'Card'}>
      {props.children}
    </div>
  );
};

Card.propTypes = {
  classModifier: PropTypes.oneOf([
    'default',
    'darkenBackground',
    'dashboardMenu',
    'yellowBackground',
  ]),
  children: PropTypes.node,
  styleModfier: PropTypes.object,
};

export { Card };