import styled from 'styled-components'

export const ConsultationListsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 0px;
  max-width: initial;
  position: initial;
`