import React from "react";

const UserAvatar = ({
  fill,
  height,
  viewBox = "0 0 40 40",
  width,
}) => (

    <svg
      xmlns="http://www.w3.org/2000/svg" 
      width={width}
      height={height}
      viewBox={viewBox}
    >
      <path id="Caminho_2166" data-name="Caminho 2166" d="M22,2A20,20,0,1,0,42,22,20.007,20.007,0,0,0,22,2Zm0,6a6,6,0,1,1-6,6A5.992,5.992,0,0,1,22,8Zm0,28.4a14.4,14.4,0,0,1-12-6.44c.06-3.98,8-6.16,12-6.16,3.98,0,11.94,2.18,12,6.16A14.4,14.4,0,0,1,22,36.4Z" transform="translate(-2 -2)" fill={fill} />
    </svg>

);


export default UserAvatar;