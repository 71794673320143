import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';

const infoTextStyle = { lineHeight: '1.5' };

function FloatActionButtonMobile({
  buttonId,
  hasTitle,
  iconName, 
  infoText,
  onClickFab, 
  showInfo,
  type,
  title,
  value,
}) {
  return (
    <div className={`FloatActionButtonMobile${hasTitle ? "-hasTitle" : ""}`}>
      {
        showInfo && !hasTitle ? 
          (
            <div className="FloatActionButtonMobile__info">
              <div className='FloatActionBtn__info__icon'>
                <CoreIcons name='ArrowFab' width='49px' />
              </div>
              <div className='FloatActionBtn__info__text'>
                <Typography align='right' style={infoTextStyle} type='subheading2'>
                  {infoText}
                </Typography>
              </div>
            </div>
          ) : null
      }
      <button
        className={`FloatActionButtonMobile__button${type ? `--${type}` : ''}`}
        onClick={() => onClickFab(value)}
        id={buttonId}
        value={value}>
        <div className="FloatActionButtonMobile__button__icon">
          <CoreIcons name={iconName} 
            height="24px"
            width="24px"
            fill="#FFF"
          />
        </div>
        {
          hasTitle ? (
            <div className="FloatActionButtonMobile__button__text">
              <Typography>
                {title} 
              </Typography>
            </div>
          ) : null
        }
      </button>
    </div>
  );
}

FloatActionButtonMobile.propTypes = {
  buttonId: PropTypes.any,
  iconName: PropTypes.string, 
  infoText: PropTypes.any, 
  onClickFab: PropTypes.func, 
  showInfo: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
  ]),
  value: PropTypes.any,
};

export default FloatActionButtonMobile;

