import styled from 'styled-components'

interface ContainerProps {
  dayOfWeek: number;
}

export const Container = styled.div<ContainerProps>`
  &&& {
    .CancelReservationDayModal__Paper {
      max-width: 100%; 
      margin-top: 20px;
    }

    .CancelReservationDayModal__WarningMessage {
      margin-top: 20px;
    }

    .DayPicker-Weekday {
      color: #DEDEDE;
      &:nth-child(${({ dayOfWeek }) => dayOfWeek}) {
        color: #FF2929;
      }
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--outside) {
      background: #FF2929 !important;
    }
  }
`

export const ReservationDay = styled.b`
  color: #FF2929;
`

export const LabelWrapper = styled.div`
  margin: 30px 0 10px 0;
`

export const SuccessWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const SuccessTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0;
  text-align: center;

  &&& {
    .CancelReservationDayModal__Success__Disclaimer {
      text-align: center;
    }
  }
`