import React, { Component } from 'react'
import { connect } from 'react-redux'

// COMPONENTS
import { Typography, CoreIcons } from 'components'
import Row from '../Row'
import { Trans } from '@lingui/macro'

import { clearFilter } from '../../redux/actions'
import { clearConvenios } from 'redux/modules/plans'

class ClearFilters extends Component {

  hasFilterSet = () => {
    const { filterData, plansFilter } = this.props
    return (
      filterData.text !== null ||
      filterData.status !== null ||
      filterData.startDate !== null ||
      filterData.endDate !== null ||
      plansFilter.actualConvenio !== '' || 
      plansFilter.actualPlan !== ''
    )
  }

  render() {
    const { onClearFilters  } = this.props

    return this.hasFilterSet() ? (
      <Row margin='0 0 12px 0'>
        <div className="ClearFilters">
          <div className="ClearFilters__SideLine"></div>
          <div className="ClearFilters__TextArea"
            onClick={() => onClearFilters()}>
            <CoreIcons name="ArrowBack"
              fill="#1F35B5"
              height="16px"
              width="16px"
            />
            <Typography bold
              color="primary">
              <Trans>
                  limpar resultados
              </Trans>
            </Typography>
          </div>
          <div className="ClearFilters__SideLine"></div>
        </div>
      </Row>
    ) : null
    
  }
}

const mapStateToProps = state => {
  return {
    filterData: state.filters,
    filterStatus: state.filterStatus.filterStatus,
    plansFilter: state.plansFilter,
  }
}

const mapDispatchToProps = dispatch => {
  return { onClearFilters: () =>  {
    dispatch(clearFilter())
    dispatch(clearConvenios())
  }}
}

const ClearFiltersConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClearFilters)

export {
  ClearFiltersConnected as ClearFilters
}