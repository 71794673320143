import React, { Component } from 'react'
import Modal from '../Modal'
import PropTypes from 'prop-types'
import { Typography, Spinner } from 'components'
import Dropdown from '../../../../components/Dropdown'
import Textarea from '../../../../components/Textarea'
import { REPROVED } from '../../../../settings/_scheduleStatusSettings'
import viewportHoc from '../../../../hoc/viewportHoc'
import { connect } from 'react-redux'
import {
  getCancelReason,
  hideModal,
} from '../../../../redux/actions'
import * as reasonsSettings from '../../../../settings/_reasonsSettings'
import * as actions from '../../../../redux/actions'

class ModalRepproveRequest extends Component {
  state = {
    visibility: true,
    selectReason: '',
    detail: '',
    success: false,
    isLoading: false,
    selectPerson: {
      username: '',
      profile: '',
    },
    isSubmitButtonDisabled: true
  };

  dropdownProps = { placeholder: 'Selecione um motivo' };

  handleSelectReason = code => {
    let selectedReason = this.props.reasons.find(reason => reason.code === code)
    this.setState({ selectReason: selectedReason.code })
    this.checkRequiredFields()
  };

  componentDidMount() {
    this.getCancelReason()
  }
  getCancelReason = () => {
    this.props.onGetReasons()
  };

  handleChange = event => {
    this.setState({ detail: event.target.value })
    this.checkRequiredFields()
  };

  handleCloseButton = () => {
    this.props.onSuccessAction ? this.props.onSuccessAction() : this.clearMedicalRequestReducer()
    this.props.onHideModal()
  }

  handleRepproveButton = async () => {
    await this.setState({ isLoading: true })
    await this.props.onChangeMedicalRequest(
      this.props.validityId, {
        status_code: REPROVED,
        reason_code: this.state.selectReason,
        details: this.state.detail
      })
    if (this.props.medicalRequestSuccess) {
      await this.setState({ isLoading: false, success: true })
    }
  };

  checkRequiredFields = () => {
    return !(this.state.selectReason !== '' && this.state.detail.length > 0)
  }

  clearMedicalRequestReducer = () => {
    this.props.onClearMedicalRequest()
  }

  render() {
    const {
      username,
      cardNumber,
      btnId,
      reasons,
    } = this.props

    const orderedReasons = reasons.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    const { isLoading, success } = this.state

    return (
      <Modal
        modalTitle={success ? 'Reprovação efetuada\ncom sucesso!' : 'Reprovar Solicitação'}
        closeModal={() => this.handleCloseButton()}
        btnId={btnId}
        btnText={success ? 'Fechar' : 'Confirmar Reprovação'}
        handleClick={() => success ? this.handleCloseButton() : this.handleRepproveButton()}
        btnDisabled={this.checkRequiredFields()}
        hasButton={isLoading ? false : true}
        classModifier={success ? 'default' : 'false'}
        extraClassName>
        {
          isLoading ?
            <Spinner height="250px" />
            :
            !success &&
            <div className="Container">
              <div className="Container__warning">
                <Typography align="center" color="grey-ten">
                  de <b>{username}</b>
                </Typography>
                <Typography align="center" color="grey-ten">
                  {cardNumber}
                </Typography>
              </div>

              <div className="Container_dropdown">
                <Dropdown
                  onSelect={this.handleSelectReason}
                  options={orderedReasons}
                  placeholder={this.dropdownProps.placeholder}
                />
              </div>

              <div className="Container_textbox">
                <Textarea
                  placeholder="Detalhes da reprovação"
                  value={this.state.detail}
                  onChange={this.handleChange}
                />
              </div>
            </div>
        }
      </Modal>
    )
  }
}
ModalRepproveRequest.propTypes = {
  cardNumber: PropTypes.string,
  validityId: PropTypes.number,
  username: PropTypes.string
}
const mapStateToProps = state => {
  return {
    reasons: state.reasons.reasons,
    userData: state.auth.userData,
    medicalRequestSuccess: state.medicalRequests.success,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    onChangeMedicalRequest: (id, params) => dispatch(actions.changeMedicalRequest(id, params)),
    onClearMedicalRequest: () => dispatch(actions.clearMedicalRequest()),
    onGetReasons: () => dispatch(getCancelReason(reasonsSettings.GRE26000)),
    onHideModal: () => dispatch(hideModal()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(viewportHoc(ModalRepproveRequest))
