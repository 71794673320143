import React from "react";

const ClientCircle = ({
  fill,
  height,
  viewBox = "0 0 50 50",
  width,
}) => (
  <svg
    height={height}
    viewBox={viewBox}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g fill={fill}>
      <path d="M27.738 25.721h-4.912A9.826 9.826 0 0 0 13 35.547v2.948a.983.983 0 0 0 1.965 0v-2.948a7.86 7.86 0 0 1 7.86-7.86h4.913a7.86 7.86 0 0 1 7.86 7.86v2.948a.983.983 0 0 0 1.965 0v-2.948a9.826 9.826 0 0 0-9.825-9.826z" />
      <path d="M25.282 23.756a6.878 6.878 0 1 0-6.878-6.878 6.878 6.878 0 0 0 6.878 6.878zm0-11.791a4.913 4.913 0 1 1-4.913 4.913 4.913 4.913 0 0 1 4.913-4.913z" />
    </g>
    <circle
      fill="none"
      stroke={fill}
      strokeWidth="2"
      cx="25"
      cy="25"
      r="24"
    />
  </svg>
);

export default ClientCircle;