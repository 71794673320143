/*
 * Date and time handlers
*/
import React from 'react';
import { Trans } from '@lingui/macro';
import moment from 'moment';

import { languageCode } from '../settings/_apiSettings';

let getLanguage = languageCode().toString();

/** 
 * Main configuration for Moment
 * Configure moment locale and return date as a moment object.
 * ======> ALWAYS CREATE A MOMENT OBJECT WITH THIS FUNCION.
*/
export const setMoment = (date = new Date()) => moment(date).locale(getLanguage).second(0);

export const setMomentWithSeconds = (date = new Date()) => moment(date).locale(getLanguage);

/** Return as Javascript Date */
export const toJsDate = (date) => setMoment(date).toDate();

/** Verify if date is Today */
export const isToday = (date) => setMoment().isSame(setMoment(date), 'day');
export const isTodayBeforeTime = (date) => moment().isSame(moment(date), 'day') && moment().isBefore(moment(date));
export const tomorrow = (date) => setMoment(new Date()).add(1, 'days').isSame(setMoment(date), 'day');

/** Return the time as "hh:mm" */
export const getTime = (date) => setMoment(date).format('HH:mm');

/** Return the time with H as "HHhMM" */
export const getTimeH = (date) => setMoment(date).format('HH[h]mm');

/** Return the seconds as "ss" */
export const getSeconds = (date) => setMomentWithSeconds(date).format('ss');

/** Return the time as "MMMM" (Month name) */
export const getMonthName = (date) => setMoment(date).format('MMMM');

/** Return the time as "MMMM" (Month name) */
export const getMonth = (date) => setMoment(date).format('MM');

/** Return the time as "YYYY" (Year) */
export const getYear = (date) => setMoment(date).format('YYYY');

/** Return the time as "DD" (Year) */
export const getDay = (date) => setMoment(date).format('DD');
export const getDayName = (startDate) => setMoment(startDate).format('dddd');
/** Return date as "DD/MM/YYYY" */
export const toDayMonthYear = (date) => setMoment(date).format('L');

/** Return date as "YYYY-MM-DD" */
export const isoDayMonthYear = (date) => setMoment(date).format('YYYY-MM-DD');

export const setTime = (date, time) => {
  let formatedDate = isoDayMonthYear(date);
  return toDatetime(formatedDate, time);
};

export const addMinutes = (date, minutes) => {
  let formatedDate = setMoment(date);
  return formatedDate.add(minutes, 'minutes').format('LT');
};

export const addHours = (date, hours) => {
  let formatedDate = setMoment(date);
  return formatedDate.add(hours, 'hours').format('LT');
};

/** Return date as "DD/MM/YY" */
export const toSimpleDayMonthYear = (date) => setMoment(date).format('DD/MM/YY');

/** Return date as "DD/MM" */
export const toDayMonth = (date) =>
  setMoment(date).format('L').replace(new RegExp('[^.]?' + moment(date).format('YYYY') + '.?'), '');

/** Return date as full ISO String (with zero second) - for API's */
export const toString = (date) => {
  const dateIsoString = setMoment(date).format();
  return dateIsoString;
};

/** Return date as full ISO String (with custom seconds) - for API's */
export const toStringWithSeconds = (date) => {
  const dateIsoString = setMomentWithSeconds(date).format();
  return dateIsoString;
}

/** Return datetime from date "YYYY-MM-DD" and time "HH:mm" */
export const toDatetime = (date, time) => {
  const getDate = date.split('-');

  const year = parseInt(getDate[0]);
  const month = (parseInt(getDate[1]) - 1);

  const getTime = time.split(':');
  const day = parseInt(getDate[2]);
  const hour = parseInt(getTime[0]);

  const minute = parseInt(getTime[1]);

  return moment().year(year).month(month).date(day).hour(hour).minute(minute).second(0);
};

/** Return weekdays names */
export const getWeekdays = () => {
  const weekdays = [
    {
      index: 0,
      weekday: <Trans>Segunda-feira</Trans>,
    },
    {
      index: 1,
      weekday: <Trans>Terça-feira</Trans>,
    },
    {
      index: 2,
      weekday: <Trans>Quarta-feira</Trans>,
    },
    {
      index: 3,
      weekday: <Trans>Quinta-feira</Trans>,
    },
    {
      index: 4,
      weekday: <Trans>Sexta-feira</Trans>,
    },
    {
      index: 5,
      weekday: <Trans>Sábado</Trans>,
    },
    {
      index: 6,
      weekday: <Trans>Domingo</Trans>,
    },
  ];

  return weekdays;
};

/** Return next weekday */
export const getNextWeekday = (isWeekday) => {
  isWeekday = isWeekday + 1;
  const todayWeekday = moment().locale('pt-br').weekday();
  let nextWeekday = undefined;

  if (todayWeekday <= isWeekday) nextWeekday = moment().locale('pt-br').weekday(isWeekday);

  else nextWeekday = moment().locale('pt-br').add(1, 'weeks').weekday(isWeekday);

  nextWeekday.startOf('day');

  return nextWeekday;
};

/** Return weekdays starting on Monday */
export const weekOnMonday = () => {
  let getWeekOnMonday = getWeekdays();

  getWeekOnMonday.push(getWeekOnMonday.shift());
  return getWeekOnMonday;
};

/** Return the first and last dates from a month */
export const getFirstLastDay = (date) => {
  const firstDay = setMoment(date).startOf('month');
  const lastDay = setMoment(date).endOf('month');

  return { firstDay, lastDay };
};

/** Return datetime as object */
export const datetimeObject = (datetime) => {
  const dateMoment = setMoment(datetime);
  const weekdayIndex = getSystemWeekday(moment(datetime).locale('pt-br'));
  const getWeekday = getWeekdays();

  return {
    date: toDayMonthYear(dateMoment),
    time: getTime(dateMoment),
    weekday: getWeekday[weekdayIndex].weekday,
  };
};

/** Return range hours for specific day */
export const getHoursFromRange = (dateRanges) => {
  let newRanges = [];

  dateRanges.map(range => {
    const newRange = {
      start: getTime(range.start_date),
      end: getTime(range.end_date),
    };

    return newRanges.push(newRange);
  });

  return newRanges;
};

/** Return the duration between two moments in millisencods */
export const getDuration = (firstMoment, secMoment) => {
  const getFirst = firstMoment ? setMoment(firstMoment) : setMoment();
  const getSecond = secMoment ? setMoment(secMoment) : setMoment();

  return getFirst.diff(getSecond);
};

export const getSystemWeekday = (date) => {
  const weekday = date.weekday();
  let systemWeekday;

  if (weekday === 0) {
    systemWeekday = 6;
  } else {
    systemWeekday = weekday - 1;
  }

  return systemWeekday;
};

/**
 * Format "DD/MM/YYYY" to "YYYY/MM/DD"
 * Accept diferent separators
 * e.g: dateToIso(date,'-')
 */
export const dateToIso = (date, separator = '/', joiner = '-') => {
  let getDate = date.split(separator).reverse().join(joiner);
  return getDate;
};

export function setLimitTime(time) {
  let hour = time.slice(0, 2);
  let min = time.slice(3, 5);

  if (min === '00') {
    // HANDLE HOUR
    hour = Number(hour) - 1;
    return `${hour}:59:59`;
  }

  // HANDLE MINUTES
  min = Number(min) - 1;

  return `${hour}:${min}:59`;
}