import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
`

export const Header = styled.header`
  padding: 20px 0;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E2E2E2;
`

export const Content = styled.div``