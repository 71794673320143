import React, { useEffect, useState, Fragment } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'


// STYLE
import './PageTop.sass'

// STORE
import { updateInteractions } from 'redux/modules/mypatients'

const PageTop = ({ toShowOnMobile, toHideOnMobile, bottomComponent, id, customClassName }) => {
  const dispatch = useDispatch()
  // STORE
  const mypatients = useSelector(state => state.mypatients)
  const top = mypatients.interactions.top
  // STATE
  const [yPosition, setYPosition] = useState(window.pageYOffset)
  const [hidden, handleHidden] = useState(true)
  const [initialY, setInitialY] = useState(0)

  function onScroll () {
    if (window.pageYOffset >= 0 && window.pageYOffset < document.body.scrollHeight) {
      if (window.pageYOffset > yPosition || window.pageYOffset<= 0) {
        setYPosition(window.pageYOffset)
        handleHidden(true)
        dispatch(updateInteractions('top', 0))
      }

      if (window.pageYOffset <= yPosition) {
        handleHidden(false)
        setYPosition(window.pageYOffset)
        dispatch(updateInteractions('top', document.getElementById(`${id}-floating`).offsetHeight))
      }
    }
  };

  useEffect(() => {
    setInitialY(document.getElementById(id).offsetHeight)
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [yPosition, top])

  return (
    <Fragment>
      <div id={id}
        className="PageTop">
        <div className="PageTop__Wrapper">
          { toHideOnMobile && <div className="PageTop__title">{ toHideOnMobile || toHideOnMobile.map(item => item) }</div>}
          <div className="PageTop__content">{ toShowOnMobile || toShowOnMobile.map(item => item) }</div>
        </div>
        {bottomComponent && bottomComponent}
      </div>
      <div id={`${id}-floating`} className={classNames('PageTop__floating', {
        'PageTop__floating--visible': !hidden && window.pageYOffset >= initialY * 2,
        [customClassName]: Boolean(customClassName)
      })}>
        <div className='PageTop__Wrapper'>
          { toHideOnMobile &&
          <div className={classNames('PageTop__title', {
            'PageTop__title--hidden': window.pageYOffset !== 0
          })}>{ toHideOnMobile || toHideOnMobile.map(item => item) }</div>}
          <div className="PageTop__content">{ toShowOnMobile || toShowOnMobile.map(item => item) }</div>
        </div>
        {bottomComponent && bottomComponent}
      </div>
    </Fragment>
  )
}

export {
  PageTop
}