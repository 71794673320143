import React, { Component } from 'react'
import { connect } from 'react-redux'
import Sticky from 'react-sticky-el'

import { Card } from 'components'
import LineSeparator from '../LineSeparator'
import MenuDashboardContainer from '../../containers/MenuDashboardContainer'
import MenuLogout from '../MenuLogout'
import MenuProfileSwap from '../MenuProfileSwap'
import MenuUserInfo from '../MenuUserInfo'
import Row from '../Row'

import * as routes from '../../settings/_routesSettings'
import * as actions from '../../redux/actions'

class MenuSidebar extends Component {
  handleRedirect = () => {
    this.props.history.push(routes.HOME_PSIC)
  };

  handleSelectProfile = async (profileCode) => {
    await this.props.onSwap(profileCode)
  }

  handleLogout = async () => {
    await this.props.onLogout()
  }

  render() {
    let userProps = {
      avatar: '',
      email: '',
      firstName: '',
      friendlyUrl: '',
      permissions: [],
      profiles: [],
      actualProfile: {},
    }

    if (this.props.isAuth) {
      userProps = {
        avatar: this.props.person.person_general.picture_url,
        email: this.props.person.person_general.email,
        firstName: this.props.person.person_general.first_name,
        friendlyUrl: this.props.person.person_general.friendly_url,
        permissions: this.props.permissions,
        profiles: this.props.person.person_profile_swap,
        actualProfile: this.props.actualProfile,
      }
    }

    return (
      <Sticky stickyStyle={{ 'marginTop': '30px' }}>
        <Card classModifier='scrollable'>
          <MenuUserInfo {...userProps} />
          <MenuDashboardContainer userPermissions={userProps.permissions} />
          <LineSeparator />
          <MenuLogout onHandleLogout={this.handleLogout} />
        </Card>
      </Sticky>
    )
  }
}

const mapStateToProps = state => {
  return {
    isAuth: state.auth.isAuth,
    isLogged: state.async.isLogged,
    permissions: state.auth.profilePermissions,
    actualProfile: state.auth.profileSelected,
    person: state.auth.userData,
    userToken: state.auth.userToken,
    test: state.auth,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onLogout: () => {
      dispatch(actions.authLogout())
    },
    onMe: (meData) => dispatch(actions.authUser(meData)),
    onSwap: (profileCode) => dispatch(actions.authSwap(profileCode)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuSidebar)
