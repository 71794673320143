import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  padding-top: 0;
  margin-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  width: 100%;
`

export const UserList = styled.ul`
  margin-top: 15px;

  li + li {
    padding-top: 5px;
  }
`

export const UserItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`