import React, { Component } from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import Row from '../Row';

class MenuProfileSwap extends Component {
  state = {
    actualProfile: '',
    isOpen: false,
  }

  componentDidMount = () => {
    this.setState({ actualProfile: this.props.actualProfile });
  }

  handleDropdow = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { actualProfile, personProfiles } = this.props;
    const { isOpen } = this.state;

    return (
      <div className="profile-swap">
        <div className="profile-swap--select"
          onClick={this.handleDropdow}>
          <Row align="center">
            <div className="profile-swap--text">
              <Typography type="subheading2">
                {actualProfile.profile_name}
              </Typography>
            </div>
            <div className="profile-swap--arrow">
              {
                isOpen ?
                  <CoreIcons name="ArrowUp" fill="#acacac" width="24px" height="24px" /> :
                  <CoreIcons name="ArrowDown" fill="#acacac" width="24px" height="24px" />
              }
            </div>
          </Row>
        </div>
        {
          isOpen ? (
            <div className="profile-swap--option">
              {
                personProfiles.map(profile => {
                  return (
                    <div key={profile.profile_code}
                      id={`profile-swap--${profile.profile_code}`}
                      className="profile-swap--option--item"
                      onClick={() => this.props.onClickSelect(profile.profile_code)}>
                      <Typography type="subheading2">
                        {profile.profile_name}
                      </Typography>
                    </div>
                  );
                })
              }
            </div>
          ) : null
        }
      </div>
    );
  }
}

MenuProfileSwap.propTypes = {
  actualProfile: PropTypes.object,
  isOpen: PropTypes.bool,
  onClickOpen: PropTypes.func,
  onClickSelect: PropTypes.func,
  personProfiles: PropTypes.array,
};

export default MenuProfileSwap;