import React from 'react'
import { format, getDay } from 'date-fns'
import { useState } from 'react'
import PropTypes from 'prop-types'
import { parsePhoneNumber } from 'libphonenumber-js'
import { Typography } from '@telavita-core/react-design-kit'

import ToggleFloatButton from 'components/ToggleFloatButton'
import { CancelReservation } from '../PatientHome/CancelReservation'
import { CancelReservationDayModal, CancelReservationQuestionModal, CancelNonRecurrentReservationModal } from 'components'
import { WEEKDAYS_NAMES } from 'settings/dayPicker'
import { Tile } from '../AdminReservations/components/Tile/Tile'
import clsx from 'clsx'

const Recurrence = {
  WEEKLY: 'Semanal',
  NON_RECURRENT: 'Avulsa',
}

const ReservationListItem = ({
  reservation,
  onRefresh,
  allowActions = true,
  hideBorderTop = false
}) => {
  const {
    uuid,
    start_date,
    creation_date,
    patient_username,
    patient_full_name,
    patient_cellphone,
    professional_username,
    professional_full_name,
    recurrency_exceptions,
    recurrence
  } = reservation
  const [cancelOpen, setCancelOpen] = useState(false)
  const [questionCancelOpen, setQuestionCancelOpen] = useState(false)
  const [cancelReservationDayOpen, setCancelReservationDayOpen] = useState(false)
  const [nonRecurrentReservationCancelOpen, setNonRecurrentReservationCancelOpen] = useState(false)

  const handleClickCancelOption = () => {
    if (recurrence === Recurrence.WEEKLY) {
      return setCancelOpen(true)
    }

    if (recurrence === Recurrence.NON_RECURRENT) {
      return setNonRecurrentReservationCancelOpen(true)
    }
  }

  const options = [{ code: 1, name: 'Cancelar', func: () => handleClickCancelOption()}]
  
  const weekday = WEEKDAYS_NAMES[getDay(start_date)]
  const hour = format(start_date, "HH'h'mm") // eslint-disable-line

  function handlePatientCellphone() {
    const isPatientCellphoneValid = Number(patient_cellphone)

    if (!isPatientCellphoneValid) {
      return ''
    }

    return parsePhoneNumber(`+${patient_cellphone}`)?.formatNational()
  }

  return (
    <div className={
      clsx(
        'ProfessionalReservations__listItem',
        hideBorderTop && 'ProfessionalReservations__listItem--hideBorderTop'
      )
    }>
      <Tile date={start_date} isRecurrent={recurrence === 'Semanal'}/>
      <div className='ProfessionalReservations__listItemProfessional'>
        <Typography variant='content2' weight='bolder'>
          {patient_full_name}
        </Typography>
        <Typography variant='content2'>
          {patient_username}
        </Typography>
        <Typography variant='content2'>
          {handlePatientCellphone()}
        </Typography>
        <div className='ProfessionalReservations__listItemPlan'>
          <Typography variant='content3' weight='bold'>
            Criada em {format(new Date(creation_date), 'dd/MM/yyyy')}
          </Typography>
        </div>
      </div>
      <div className='ProfessionalReservations__grow' />
      {
        allowActions &&
        <>
          <div className='ProfessionalReservations__listItemActions'>
            <ToggleFloatButton menuList={options} iconName='MoreOptions' />
          </div>
          {questionCancelOpen && (
            <CancelReservationQuestionModal 
              onClose={() => setQuestionCancelOpen(false)}
              onSelectSingleDay={() => setCancelReservationDayOpen(true)}
              onSelectAllReservation={() => setCancelOpen(true)}
              patientFullName={patient_full_name} 
              reservationHour={hour}
              reservationWeekday={weekday}
              isProfessional
            />
          )}
          {cancelReservationDayOpen && (
            <CancelReservationDayModal 
              onClose={() => setCancelReservationDayOpen(false)}
              patientFullName={patient_full_name} 
              patientUsername={patient_username}
              professionalFullName={professional_full_name}
              professionalUsername={professional_username}
              reservationStartdate={new Date(start_date)}
              reservationUUID={uuid}
              isProfessional
              cancelledDates={recurrency_exceptions}
              onRefresh={onRefresh}
            />
          )}
          <CancelReservation
            open={cancelOpen}
            setOpen={setCancelOpen}
            onRefresh={onRefresh}
            isProfessional={true}
            reservationUUID={uuid}
            reservationHour={hour}
            reservationWeekday={weekday}
            patientUsername={patient_username}
            patientFullName={patient_full_name}
            professionalUsername={professional_username}
            professionalFullName={professional_full_name}
          />

          <CancelNonRecurrentReservationModal 
            open={nonRecurrentReservationCancelOpen}
            setOpen={setNonRecurrentReservationCancelOpen}
            onRefresh={onRefresh}
            reservationUUID={uuid}
            reservationDate={start_date}
            patientUsername={patient_username}
            patientFullName={patient_full_name}
            professionalUsername={professional_username}
          />
        </>
      }
    </div>
  )
}

ReservationListItem.propTypes = {
  reservation: PropTypes.shape({
    uuid: PropTypes.string,
    start_date: PropTypes.instanceOf(Date),
    patient_full_name: PropTypes.string,
    patient_username: PropTypes.string,
    patient_cellphone: PropTypes.string,
    professional_username: PropTypes.string,
    professional_full_name: PropTypes.string,
    plan_group_name: PropTypes.string,
    creation_date: PropTypes.string,
    recurrence: PropTypes.string,
  }),
  onRefresh: PropTypes.func,
  allowActions: PropTypes.bool,
}

export { ReservationListItem }
