import styled from 'styled-components'

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  > p {
    text-align: center;
    margin-top: 10px;
  }

  > button {
    width: 100%;
    margin: 20px 0 10px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > button {
    width: 100%;
    margin: 20px 0 10px;
  }
`

export const ContentDateInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  align-items: center;
`

export const ContentAlertWrapper = styled.div`
  display: flex;
  padding: 0 20px;

  > p {
    width: fit-content;
  }
`

export const BlockedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;

  & > p:first-child {
    margin-bottom: 30px;
  }

  & > button {
    width: 100%;
    margin: 40px 0 10px;
  }

  .infoBlockedText {
    margin-bottom: 30px;
  }
`

export const BlockedDateTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin: 10px 0 5px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20rem;
`

export const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  padding: 20px;

  > p {
    text-align: center;
    margin-top: 10px;
  }
`