import React, { useRef } from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import { useGetStatuses } from './hooks/useGetStatuses'

import ToggleButton from 'components/ToggleButton'

import * as S from '../styles'
import { useFilter } from 'views/Dashboard/Sections/AdminScheduleList/context/FilterContext'

export const Status = (): JSX.Element => {
  const { state, dispatch } = useFilter()
  const { statuses, isLoading } = useGetStatuses()
  const wrapperRef = useRef(null)
  
  return (
    <S.FilterContainer>
      <Typography variant="content2">Status:</Typography>
      
      <S.GridStatuses>
        {/* Opção de Todos os status */}
        <ToggleButton
          itemId='all'
          imgAlt='Todos os status'
          wrapperRef={wrapperRef}
          iconName='AllSchedules'
          isActive={state.statusCode === 'all'}
          itemName='AllSchedules'
          itemTitle='Todos'
          handleClick={() => dispatch({ type: 'SET_STATUS_CODE', payload: 'all' })}
          isFilterAll={true}
        />

        {!isLoading && statuses.map((status) => (
          <ToggleButton
            key={status.id}
            wrapperRef={wrapperRef}
            iconName={status.icon_url}
            isActive={state.statusCode === status.code}
            itemName={status.icon_url}
            itemTitle={status.name}
            handleClick={() => dispatch({ type: 'SET_STATUS_CODE', payload: status.code })}
            isFilterAll={false}
            itemId={String(status.id)}
            imgAlt={status.name}
          />
        ))}
      </S.GridStatuses>
    </S.FilterContainer>  
  )
}