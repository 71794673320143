import { useSelector } from 'react-redux'
import { TVTVADMN, TVTVPSIC, TVTVPSIQ, TVTVPACI, TVTVSECR } from 'settings/_profileSettings'

function useProfile() {
  const code = useSelector((state) => state.auth.profileSelected.profile_code)
  const isProfessional = (code === TVTVPSIC || code === TVTVPSIQ)
  const isPatient = code === TVTVPACI
  const isAdmin = code === TVTVADMN
  const isSecretary = code === TVTVSECR

  return {
    code,
    isProfessional,
    isPatient,
    isAdmin,
    isSecretary
  }
}

export { useProfile }
