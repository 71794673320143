import React from 'react';
import PropTypes from 'prop-types';

// COMPONENTS
import { Typography, CoreIcons } from 'components';
import { Trans } from '@lingui/macro';

const ProfessionalChip = ({ name, photo }) => {
  return (
    <div className="ProfessionalChip">
      <div className="ProfessionalChip__info">
        <Typography>
          <Trans>Marcar com</Trans>
        </Typography>
        <Typography bold>
          {name}
        </Typography>
      </div>
      {
        photo ? 
          <div
            className="ProfessionalChip__image"
            style={{ backgroundImage: `url('${photo}')` }}
          ></div>
        :
          <CoreIcons
            name="UserAvatar"
            height="40px"
            width="40px"
            fill="#4B4B4B"
          />
      }
      
  
    </div>
  );
};

ProfessionalChip.propTypes = {
  name: PropTypes.string,
  photo: PropTypes.string
};

export default ProfessionalChip;