import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Trans } from '@lingui/macro';

import TimerContainer from '../../containers/TimerContainer';
import { Typography, Button } from 'components';

import { redirectEndpoints } from '../../settings/_apiSettings';

class NotificationInline extends Component {
  state = { timeUp: this.props.isTimeUp }

  handleBtnClick = (meetingHash) => {
    window.location.assign(`${redirectEndpoints}/${meetingHash}`);
  };

  onTimeUp = () => {
    this.setState({ timeUp: true });
  };

  render() {
    const { initialTime, meetingHash } = this.props;
    const { timeUp } = this.state;

    return (
      <div className="NotificationInline">
        {
          timeUp ? (
            <Typography type="subheading2">
              <Trans>Já começou!</Trans>
            </Typography>
          ) : (
            <Fragment>
              <Typography type="subheading2">
                <Trans>Começa em</Trans>
              </Typography>

              <TimerContainer
                initialTime={initialTime}
                onTimerStop={() => this.onTimeUp()}
              />
            </Fragment>
          )
        }
        <Button
          id="start-consultation"
          onButtonClick={() => this.handleBtnClick(meetingHash)}
          text="ENTRAR"
        />
      </div>
    );
  }
}

NotificationInline.propTypes = {
  initialTime: PropTypes.number,
  isTimeUp: PropTypes.bool,
  meetingHash: PropTypes.string,
};

export default NotificationInline;
