import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';

// STYLE
import './GeneralViewFilters.sass'

// STORE
import * as actions from '../../../../redux/actions';
import { applyFilters } from '../../../../redux/modules/generalView/actions';

// COMPONENTS
import { Button, RadioButton, DateFilter } from 'components';
import { Trans } from '@lingui/macro';
import ModalGeneric from '../ModalGeneric';

const GeneralViewFilters = () => {
  // LOCAL
  const [diffDays, handleDiffDays] = useState(null);
  const [startDate, handleStartDate] = useState('');
  const [finalDate, handleFinalDate] = useState('');
  const [startError, startErrorHandler] = useState(false);
  const [finalError, finalErrorHandler] = useState(false);
  const [open, handleOpen] = useState(false);
  const dispatch = useDispatch();
  const daysFilters = [
    { value: 7, label: 'Últimos 7 dias' },
    { value: 30, label: 'Últimos 30 dias' },
    { value: 60, label: 'Últimos 60 dias' },
    { value: 90, label: 'Últimos 90 dias' },
  ];

  // STORE
  const generalView = useSelector((state) => state.generalView);
  const prevInitial = generalView.filters.prevInitialDate;
  const prevFinal = generalView.filters.prevFinalDate;
  const filteredDiffDays = generalView.filters.filteredDiffDays;

  // UPDATE STORE STATE AND APPLY CUSTOM PERIOD FILTERING
  function handleDates () {
    let start = startDate.split('/').reverse().join('-');
    let final = finalDate.split('/').reverse().join('-');

    let testStart = Number(startDate.split('/').join(''));
    let testFinal = Number(finalDate.split('/').join(''));

    if (testStart && !testFinal) dispatch(applyFilters({ initialDate: moment(start).startOf('day').toJSON(), finalDate: moment(start).endOf('day').toJSON(), filteredDiffDays: 'custom', prevInitialDate: startDate.split('/').join('') }))
    else if (!testStart && testFinal) dispatch(applyFilters({ initialDate: moment(final).startOf('day').toJSON(), finalDate: moment(final).endOf('day').toJSON(), filteredDiffDays: 'custom', prevFinalDate: finalDate.split('/').join('') }))
    else if (testStart && testFinal) dispatch(applyFilters({ initialDate: moment(start).toJSON(), finalDate: moment(final).toJSON(), filteredDiffDays: 'custom', prevInitialDate: startDate.split('/').join(''), prevFinalDate: finalDate.split('/').join('') }))
    else dispatch(applyFilters({ initialDate: moment().toJSON(), finalDate: moment().toJSON(), filteredDiffDays: 'custom' }))
  }

  function applyDateFilters () {
    dispatch(actions.hideModal())

    return diffDays === 'custom'
      ? handleDates()
      : dispatch(applyFilters({ initialDate: moment().startOf().subtract(diffDays, 'days').toJSON(), finalDate: moment().endOf().toJSON(), filteredDiffDays: diffDays }))
  }

  function validateDate () {
    let start = startDate.split('/').reverse().join('-');
    let final = finalDate.split('/').reverse().join('-');

    let testStart = Number(startDate.split('/').join(''));
    let testFinal = Number(finalDate.split('/').join(''));

    if (diffDays === 'custom') {
      if (start.length > 0) {
        if (!moment(start).isValid() || moment(start).isAfter(final) || isNaN(testStart)) {
          startErrorHandler(true)
        }
        else {
          startErrorHandler(false)
        }
      } else startErrorHandler(false)
    }

    if (diffDays === 'custom') {
      if (final.length > 0) {
        if (!moment(final).isValid() || moment(final).isBefore(start) || isNaN(testFinal)) {
          finalErrorHandler(true)
        }
        else {
          finalErrorHandler(false)
        }
      } else finalErrorHandler(false)
    }
  }

  useEffect(() => {
    let start = startDate.split('/').reverse().join('-');
    let final = finalDate.split('/').reverse().join('-');

    // DID MOUNT
    if (diffDays === null) handleDiffDays(filteredDiffDays);
    if (prevInitial.length > 0 && prevInitial !== start && !open) handleStartDate(`${prevInitial.slice(0, 2)}/${prevInitial.slice(2, 4)}/${prevInitial.slice(4)}`);
    if (prevFinal.length > 0 && prevFinal !== final && !open) handleFinalDate(`${prevFinal.slice(0, 2)}/${prevFinal.slice(2, 4)}/${prevFinal.slice(4)}`);
    handleOpen(true);

    // DID UPDATE
    validateDate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finalDate]);

  return (
    <ModalGeneric
      hasButton={false}
      modalTitle="Filtros"
      closeModal={() => dispatch(actions.hideModal())}
      btnId="generalviewFiltersBtn"
      >
      <div className="GeneralViewFilters">
        <section className="GeneralViewFilters__section">
          <p className="GeneralViewFilters__sectionTitle">Escolha um período:</p>
          {
            daysFilters.map(day => {
              return (
                <div className="GeneralViewFilters__radio" key={day.value}>
                  <RadioButton
                    title={day.label}
                    onClickRadioButton={() => handleDiffDays(day.value)}
                    radioId={`last-${day.value}`}
                    selected={diffDays === day.value}
                    value={day.value}
                  />
                </div>
              )
            })
          }
          <RadioButton
            key={0}
            title={<Trans>Personalizado</Trans>}
            onClickRadioButton={() => handleDiffDays('custom')}
            radioId="last-0"
            selected={diffDays !== 7 && diffDays !== 30 && diffDays !== 60 && diffDays !== 90}
            value={0}
          />

          {
            (diffDays !== 7 && diffDays !== 30 && diffDays !== 60 && diffDays !== 90)
              ? (
                <DateFilter
                  start={{
                    id: 'test-start',
                    placeholder: 'Digite',
                    defaultValue: prevInitial,
                    error: startError,
                    onChange: (value) => handleStartDate(value)
                  }}
                  final={{
                    id: 'test-final',
                    placeholder: 'Digite',
                    defaultValue: prevFinal,
                    error: finalError,
                    onChange: (value) => handleFinalDate(value)
                  }}
                  error={{
                    condition: startError || finalError,
                    message:'Data Inválida'
                  }} />
              ) : null
          }
        </section> 
      </div>

      {/* FILTER BUTTON */}
      <Button
        disabled={diffDays === 'custom' ? ((startError || finalError) || (startDate.length === 0 && finalDate.length === 0)) : false}
        id={"generalFilters-btn"}
        onButtonClick={() => applyDateFilters()}
        text={'APLICAR MODIFICAÇÕES'}/>
    </ModalGeneric>
  )
}

export {
  GeneralViewFilters
}