import axios from 'settings/axios'
import { apiEndpoints } from 'settings/_apiSettings'

interface Error {
  response: {
    status: number
  }
}

export const markAllReviewsAsViewed = (): Promise<void> => {
  const endpoint = apiEndpoints.ME_REVIEWS as string
  
  return new Promise((resolve, reject) => {
    axios.patch(endpoint)
      .then(() => resolve())
      .catch((error: Error) => reject(error.response.status))
  })
}