import React from 'react'
import { Button, Select, TextBox, Typography } from '@telavita-core/react-design-kit'

import ModalGeneric from '../ModalGeneric'

import { useCancelationSchedule } from './hooks/useCancelationSchedule'
import { CANCELATION_SCHEDULE_REASONS_OPTIONS } from 'settings/_cancelationScheduleReasonsOptions'

import { Schedule } from './types'

import * as S from './styles'
import { TVTVPSIC } from 'settings/_profileSettings'

const INVALID_TOKEN_REASON = 'RES01011'

export const CancelationScheduleModal: React.FC<Schedule> = ({ scheduleData }) => {
  const { 
    cancelationFields, 
    handleCancelSchedule,
    handleChangeDetails,
    handleChangeReason,
    handleChangeRequester,
    validateRequiredFields,
    isCancelationLoading,
    isCancelationSuccess,
    isCancelationError
  } = useCancelationSchedule(scheduleData)

  const { client, professional } = scheduleData

  const professionalRole = professional.person_profile.profile_code

  const isPsychologist = professionalRole === TVTVPSIC

  const hiddenInvalidTokenReason = isPsychologist

  const FILTERED_CANCELATION_SCHEDULE_REASONS_OPTIONS = CANCELATION_SCHEDULE_REASONS_OPTIONS.filter(reason => {
    if (hiddenInvalidTokenReason && reason.code === INVALID_TOKEN_REASON) return null
    return reason
  })
  
  const requesterOptions = [
    {
      label: `Paciente (${client?.person_profile.person.person_general.full_name})`,
      id: client?.id,
      code: client?.person_profile.person.username
    },
    {
      label: `Profissional (${professional?.person_profile.person.person_general.full_name})`,
      id: professional?.id,
      code: professional?.person_profile.person.username
    }
  ]

  const feedbackOptions = {
    feedbackShow: isCancelationError || isCancelationSuccess,
    feedbackType: isCancelationError ? 'error' : 'success',
    feedbackMessage: isCancelationError ? 'Não foi possível cancelar a consulta' : 'Consulta cancelada \n com sucesso!',
    feedbackContent: isCancelationError ? 'Tente novamente em alguns instantes!' : '',
  }

  return (
    <ModalGeneric 
      modalTitle=""
      {...feedbackOptions}
    >
      <S.Container>
        <S.HeaderWrapper>
          <Typography variant="header4">Cancelar consulta</Typography>
          <Typography variant="content2" color='danger'>Atenção, o cancelamento é irreversível.</Typography>
        </S.HeaderWrapper>

        <S.SelectWrapper>
          <Typography variant="content2" weight='bold'>
            Quem está cancelando?*
          </Typography>
          <Select 
            label=''
            onSelect={(data) => handleChangeRequester(data)}
            options={requesterOptions} 
            selected={requesterOptions.find(option => option.id === cancelationFields.cancelationRequester.id)}
            customClassName="customSelect"
          />
        </S.SelectWrapper>

        <S.SelectWrapper>
          <Typography variant="content2" weight='bold'>
            Motivo do cancelamento*
          </Typography>
          <Select 
            label=''
            onSelect={(data) => handleChangeReason(data)} 
            options={FILTERED_CANCELATION_SCHEDULE_REASONS_OPTIONS} 
            selected={FILTERED_CANCELATION_SCHEDULE_REASONS_OPTIONS.find(option => option.code === cancelationFields.cancelationReason)}
            customClassName="customSelect"
          />
          {cancelationFields.cancelationReason === INVALID_TOKEN_REASON && (
            <S.CreditDisclaimer>
              <Typography variant="content3">
                <strong>Atenção</strong>: Este motivo é válido apenas para Omint e Cuidar Omint e torna o paciente inelegível automaticamente.
              </Typography>
            </S.CreditDisclaimer>
          )}
        </S.SelectWrapper>

        <S.TextBoxWrapper>
          <Typography variant="content2" weight='bold'>
            Detalhes do cancelamento
          </Typography>
          <Typography variant="content3" italic>
            Caso preenchido, profissional e paciente receberão por e-mail.
          </Typography>
          <TextBox 
            placeholder='Digite o motivo'
            name='cancelationDetails'
            value={cancelationFields.cancelationDetails}
            onChange={(event) => handleChangeDetails(event.target.value)}
          />
        </S.TextBoxWrapper>

        <S.CreditDisclaimer>
          <Typography variant="content3">
            <strong>No convênio</strong> o crédito será estornado e um novo agendamento poderá ser feito no prazo de validade.
          </Typography>
          <Typography variant="content3">
            <strong>No particular</strong> o valor será estornado integralmente até a próxima fatura do cartão de crédito.
          </Typography>
        </S.CreditDisclaimer>

        <Typography 
          variant="content2" 
          italic 
          inlineStyles={{ color: '#898989', margin: '20px 0' }}
        >
          * Preenchimento obrigatório
        </Typography>

        <Button 
          inlineStyles={{ width: '100%' }} 
          variant="contained" 
          color="danger" 
          disabled={!validateRequiredFields() || isCancelationLoading}
          onClick={handleCancelSchedule}
        >
          {isCancelationLoading 
            ? 'Cancelando...'
            : 'Confirmar cancelamento'
          }
        </Button>
      </S.Container>
    </ModalGeneric>
  )
}