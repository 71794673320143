import React from 'react'
import { Typography } from '@telavita-core/react-design-kit'

import { 
  TVTVPACI,
  TVTVADMN,
  TVTVSECR
} from 'settings/_profileSettings'

import * as S from './styles'

interface CanceledByInfoProps {
  canceledBy: string
  cancelationDate: string
  canceledPerformerProfileCode: string
}

function CanceledByText(text: string, strongText: string) {
  return (
    <Typography variant="content2" color="danger">
      {text}
      <strong>{strongText}</strong>
    </Typography>
  )
}

function getCancelationTextByPerformerProfileCode(
  canceledBy: string, 
  canceledPerformerProfileCode: string
) {  
  const profiles: {[key: string]: JSX.Element} = {
    [TVTVPACI]: CanceledByText('Cancelada por ', canceledBy),
    [TVTVADMN]: CanceledByText('Cancelada por ', `${canceledBy}, da Telavita`),
    [TVTVSECR]: CanceledByText('Cancelada por ', `${canceledBy}, Secretário`)
  }

  return profiles[canceledPerformerProfileCode] || CanceledByText('Cancelada por ', canceledBy)
}

export const CanceledByInfo: React.FC<CanceledByInfoProps> = (cancelationData) => {
  const { canceledBy, canceledPerformerProfileCode } = cancelationData

  return (
    <S.Container>
      {getCancelationTextByPerformerProfileCode(canceledBy, canceledPerformerProfileCode)}
      <Typography variant="content2" color="danger">em {cancelationData?.cancelationDate}</Typography>
    </S.Container>
  )
}