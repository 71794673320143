import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";

import PropTypes from "prop-types";
import Row from "../../../../components/Row";
import TextToggleFilter from "../../../../components/TextToggleFilter";
import { Typography, Spinner } from 'components';

import { ADMINISTRATOR } from "../../../../settings/_profileSettings";

import { ACTIVE, INACTIVE } from '../../../../settings/_personSettings'

import {
  getPersonProfileRole,
  setPersonProfile,
  changePersonProfile,
} from "../../../../redux/actions/personsActions";
import {
  getPartnerProfiles,
  clearPartnerProfiles,
  hideModal,
  getAllPlansGroups
} from "../../../../redux/actions";
import Dropdown from "components/Dropdown";
import NotificationStep from "components/NotificationStep";
import ModalGeneric from "../ModalGeneric";

/**
 * This view is called "EditPermissions" but is related
 * to the atribution and status change of persons's profiles.
 */
class EditPermissions extends Component {

  state = {
    selectedRoles: [],
    plan_group_code: null,
    loading: false,
    success: false
  };

  /**
   * The roleGroup const is an workaround
   * while we don't have a complet flux to
   * add profiles that requires aditional
   * information
   */
  roleGroup = ADMINISTRATOR

  componentDidMount() {
    this.props.onGetProfileRoles(this.props.slug, { role_group_code: this.roleGroup });
    this.props.onGetPartnerProfiles({ role_group_code: this.roleGroup });
    this.props.onGetPlanGroups();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.profileRoles !== this.props.profileRoles && this.props.partnerRoles) {
      this.setInitialProfileRoles();
    }
  }

  componentWillUnmount() {
    this.props.onClearPartnerProfiles();
  }

  /**
   * Returns an object of with roles to
   * be removed via status change or roles
   * to be added
   */
  handleRolesAttribution = async() => {

    this.setState({ loading: true });

    const { profileRoles } = this.props;
    const { selectedRoles, plan_group_code } = this.state;

    const personProfileRoles = profileRoles.map(profile => profile.role.code);
    let statusCode = ACTIVE;

    selectedRoles.map(role => {
      if (!personProfileRoles.includes(role)) {
        /**
         * If selected profile is not on user's
         * profiles list, POST a new profile
         */
        this.props.onSetPersonProfile(
          this.props.slug, {
            profile_role: role,
            status_code: statusCode,
            plan_group_code
          }
        )
        .then(data => this.setState({ loading: false, success: true }))
          .catch(err => this.setState({ loading: false, success: false }));

      } else {
        const profile = profileRoles.find(profileRole => profileRole.role.code === role);
        const profileCode = profile.profile_code;
        const isActive = profile.isActive;

        /**
         * If selected profile is on user's
         * profile list, but is not Active,
         * activate it
         */
        if (!isActive || this.checkSelectedRole('SECR')) {
          this.props.onChangePersonProfile(
            this.props.slug,
            profileCode,
            {
              status_code: statusCode,
              plan_group_code
            }
          )
          .then(data => this.setState({ loading: false, success: true }))
          .catch(err => this.setState({ loading: false, success: false }));
        }
      }

      return null;
    });

    personProfileRoles.map(profileRole => {
      if (!selectedRoles.includes(profileRole)) {
        const profile = profileRoles.find(profile => profile.role.code === profileRole);
        const profileCode = profile.profile_code;
        const isActive = profile.isActive;
        statusCode = INACTIVE;

        /**
         * If profile role on user's profile list
         * is not on selected profiles list and
         * is active, deactivate profile
         */
        if (isActive) {
          this.props.onChangePersonProfile(
            this.props.slug,
            profileCode,
            {
              status_code: statusCode,
              plan_group_code
            }
          )
          .then(data => this.setState({ loading: false, success: true }))
          .catch(err => this.setState({ loading: false, success: false }));
        }
      }

      return null;
    });
  }

  /**
   * Select or revoke permission
   * (won't send any request to the
   * back end)
   */
  handleSelectPermission = role => {
    const { selectedRoles } = this.state;
    let updatedRoles = [...selectedRoles];

    if (selectedRoles.includes(role)) {
      updatedRoles = updatedRoles.filter(selectedRole => selectedRole !== role);
    } else {
      updatedRoles = [...updatedRoles, role];
    }

    updatedRoles = updatedRoles.filter(role => role !== undefined);

    this.setState({ selectedRoles: updatedRoles });
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  /**
   * Compares if the role param is
   * already in selectedRoles state
   */
  checkSelectedRole = (role) => {
    const { selectedRoles } = this.state;
    let isSelected = false;

    selectedRoles.map(selectedRole => {
      if (selectedRole === role) isSelected = true;
      return null;
    });

    return isSelected;
  }

  /**
   * Set user's profileRoles to state
   */
  setInitialProfileRoles = () => {
    const { profileRoles } = this.props;

    let initialProfileRoles = profileRoles.map(profile => {
      if (profile.isActive) {
        return profile.role.code;
      } else {
        return null;
      }
    }).filter(profile => profile !== null);

    this.setState({ selectedRoles: [...initialProfileRoles] });
  }

  /**
   * Helper for rendering TextToggleFilter
   */
  renderToggleList = (roles) => (
    roles ?
      roles.map((item, index) => (
        <Row margin="0 0 10px 0" align="center" justify="center" key={index}>
          <TextToggleFilter
            itemName="Check"
            itemTitle={item.role.name}
            isSelected={this.checkSelectedRole(item.role.code)}
            onSelect={() => this.handleSelectPermission(item.role.code)}
          />
        </Row>

      )) : null
  );

  availableGroups = () => {
    if(this.props.planGroups){
      return this.props.planGroups.map(group => {
        return {
          code: group.code,
          name: group.name
        };
      });
    }else{
      return [];
    }
  }

  readToSubmit = () => {
    let ready = true;
    if (this.checkSelectedRole('SECR')) {
      ready = this.state.plan_group_code !== null;
    }
    return ready;
  }

  getCurrentPlansGroup = () => {
    const { profileRoles, planGroups } = this.props;
    if (profileRoles && planGroups) {
      const lastProfileIndex = profileRoles.length - 1;
      if (profileRoles[lastProfileIndex]) {
        let profilePlans = profileRoles[lastProfileIndex].profile_plan;
        if (profilePlans) {
          let planDefault = profilePlans.find(plan => plan.default === true);
          return planDefault?.plan?.plan_group?.code || null
        }
      }
    }
    return null;
  }

  renderUpdateSuccess = () => {
    return (
        <NotificationStep
          colWidth={12}
          buttonId="close-modal"
          btnText="Fechar"
          mainText={
            <Row justify="center">
              <Typography bold
                align="center"
                type="heading2">
                <Trans>Permissões modificadas com sucesso!</Trans>
              </Typography>
            </Row>
          }
          onSuccessClick={this.props.onCloseModal}
          iconColor="#4ac326"
          iconName="Check"
          hasButton
        />
    );
  }

  render() {
    const { btnText, titleColor, classModifier, modalTitle, name } = this.props;
    const { loading, success } = this.state;

    return (
      <ModalGeneric
        hasButton={loading || success ? false : true}
        btnDisabled={!this.readToSubmit()}
        btnText={btnText}
        modalTitle={success ? '' : modalTitle}
        modalSubtitle={
          <span>
            <Trans>Para </Trans>{" "}
            <Typography color="grey-ten" Tag="span" bold>{name}</Typography>
          </span>
        }
        btnOnClick={this.handleRolesAttribution}
        classModifier={classModifier}
        titleColor={titleColor}
        extraClassName>
        {loading ?
          <Spinner height="150px" />
        :
          success ?
            this.renderUpdateSuccess()
          :
            <>
              <div>
                {this.renderToggleList(this.props.partnerRoles)}
              </div>
              {
                this.checkSelectedRole('SECR') &&
                <div>
                  <Dropdown
                    options={this.availableGroups()}
                    onSelect={(plan_group_code) => this.setState({ plan_group_code })}
                    placeholder="Selecione o convênio"
                    name="permission_partner_group_plan"
                    preSelectedItem={this.getCurrentPlansGroup()}
                  />
                </div>
              }
            </>
        }
      </ModalGeneric>
    );
  }
}

EditPermissions.propTypes = {
  classModifier: PropTypes.oneOf([
    "default",
    "false",
    "online",
    "reverse",
    "reverse--grey",
    "reverse--grey--padded",
    "reverse--grey--lighter",
  ]),
  btnDisabled: PropTypes.bool,
  btnId: PropTypes.string,
  btnText: PropTypes.any,
  children: PropTypes.any,
  handleClick: PropTypes.func,
  hasButton: PropTypes.bool,
  modalActive: PropTypes.bool,
  modalTitle: PropTypes.any,
  closeModal: PropTypes.func,
  titleColor: PropTypes.string,
  notificationText: PropTypes.string,
  name: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    profileRoles: state.persons.profiles,
    partnerRoles: state.profiles.profiles,
    planGroups: state.plans.allPlansGroups.plan_groups
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onClearPartnerProfiles: () => dispatch(clearPartnerProfiles()),
    onChangePersonProfile: (slug, profile, params) => dispatch(changePersonProfile(slug, profile, params)),
    onGetProfileRoles: (slug, params) => dispatch(getPersonProfileRole(slug, params)),
    onGetPartnerProfiles: params => dispatch(getPartnerProfiles(params)),
    onGetPlanGroups: params => dispatch(getAllPlansGroups()),
    onSetPersonProfile: (slug, params) => dispatch(setPersonProfile(slug, params)),
    onCloseModal: () => dispatch(hideModal()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPermissions);
