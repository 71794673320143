// import * as githubActions from './actions'

const INITIAL_STATE = {
  data: [],
  actualConvenio: '',
  previousConvenio: '',
  actualPlan: '',
  previousPlan: '',
  error: false,
  loading: false,
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOAD_CONVENIOS':
      return {
        ...state,
        loading: true,
        error: false
      };
    case 'LOAD_CONVENIOS_SUCCESS':
      return {
        ...state,
        data: action.payload.plan_groups,
        loading: false,
      };
    case 'LOAD_CONVENIOS_FAILURE':
      return {
        ...state,
        loading: false,
        error: true
      };
    case 'SELECT_ENTITY':
      return {
        ...state,
        [action.entity]: action.code
      };
    case 'CHANGE_CONVENIO':
      return {
        ...state,
        actualConvenio: action.payload,
        actualPlan: ''
      };
    case 'CLEAR_CONVENIOS':
      return {
        ...state,
        actualConvenio: '',
        actualPlan: '',
        previousPlan: null,
        previousConvenio: null
      };
    default:
      return state;
  }
}

export { reducer };