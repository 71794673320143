import React from 'react';
import { Trans } from '@lingui/macro';

import { Typography } from 'components';

function CardEmptyPlan() {
  return (
    <div className="CardEmptyPlan">
      <Typography color="grey-dark">
        <Trans>
          Nenhum convênio vinculado
        </Trans>
      </Typography>
    </div>
  );
}

export { CardEmptyPlan }