import axios from '../settings/axios'
import { apiEndpoints } from '../settings/_apiSettings'

interface Eligibility {
  isEligible: boolean
  frequency: string
  maxCredits?: number
  availableCredits?: number
  expirationDate?: Date
}

interface Response {
  data: {
    eligible: boolean
    frequency: string
    max_schedules: number
    schedules_attended: number
    expiration_date: string
  }
}

interface GetCorporateEligibilityProps {
  username: string
  planCode?: string
}

export const getCorporateEligibility = ({
  username,
  planCode
}: GetCorporateEligibilityProps): Promise<Eligibility> => {

  const endpoint = apiEndpoints.CORPORATE_ELIGIBILITY(username)

  const params = {
    ...(planCode && { plan_code: planCode }),
  }

  return new Promise <Eligibility> (resolve => {
    axios.get(endpoint, { params })
      .then((response: Response) => {
        resolve({
          isEligible: response.data.eligible,
          frequency: response.data.frequency,
          maxCredits: response.data.max_schedules,
          availableCredits: response.data.max_schedules - response.data.schedules_attended,
          expirationDate: response.data.expiration_date ? new Date(response.data.expiration_date) : null
        } as Eligibility)
      })
      .catch(err => {
        console.error(err)
      })
  })
}