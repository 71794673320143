import React, { Component } from "react";
import { connect } from "react-redux";
import { hideModal } from "../../../../redux/actions";
import ModalGeneric from "../ModalGeneric";

class ModalTerms extends Component {
  render() {
    return (
        <ModalGeneric
            hasButton={true}
            btnText="Fechar"
            modalTitle={`Termos de Uso e\nCondições do Serviço`}
            btnOnClick={this.props.onCloseModal}
        >
            <ModalGeneric.Group>
                Para os fins deste documento, as palavras grifadas em negrito terão os seguintes significados:
                <br />
                Usuário: Aquele que utiliza e acessa o site disponibilizado em https://telavita.com.br
                <br/>
                TelaVita: TelaVita Comércio Eletrônico Ltda - ME que possui o CNPJ sob nº 27.460.001/0001-75, com endereço comercial na Cidade de São Paulo, Estado de São Paulo, Largo do Arouche n.º337, conjunto 7o andar, República, CEP: 01219-011.
                <br/>
                "Site" e "plataforma": O site e serviço online disponibilizado em https://telavita.com.br
                <br/>
                Os Termos e Condições aqui descritos regulamentam os direitos e deveres do usuário em relação a utilização do serviço oferecido pela plataforma TelaVita e são de fundamental importância para adesão ao serviço. Ao acessar a plataforma e clicar em concordar no momento do cadastro, o usuário declara sua plena, integral, e irrestrita concordância com as condições previstas neste regulamento.
                <br/>
                Definição do serviço: TelaVita Comércio Eletrônico Ltda - ME que possui o CNPJ sob nº 27.460.001/0001-75 é uma plataforma virtual que intermedia a conexão entre psicólogos e usuários, onde é oferecido um ambiente seguro com ferramentas tecnológicas que permitem a realização do atendimento psicológico online.
                <br/>
                Na plataforma TelaVita apenas são cadastrados profissionais registrados junto ao CRP, com o cadastro no e-psi válido e que estão regulares em suas questões éticas e financeiras relacionadas ao Conselho Federal e Regional de Psicologia. Observação: Na ocorrência de má conduta ou falta grave pelo psicólogo cadastrado na TelaVita junto ao usuário, disponibilizamos um endereço de contato para que seja comunicada a falta e para que as providências cabíveis sejam tomadas. E-mail: contato@telavita.com.br.
                <br/>
                As condutas profissionais dos psicólogos que utilizam esta plataforma atende o cumprimento da resolução 11/2018 do Conselho Federal de Psicologia e são de total responsabilidade dos mesmos, sendo que o não cumprimento das determinações pode acarretar o imediato desligamento do profissional, oportunidade em que será restituído o valor pago ao usuário que foi prejudicado.
                <br/>
                <br/>
                1.CONDIÇÕES GERAIS
                <br/>
                1.1 ADESÃO E UTILIZAÇÃO DO SERVIÇO 
                <br/>
                A adesão ao serviço da plataforma TelaVita começa com a ativação dos dados de acesso ao site, onde serão solicitados os dados cadastrais do cliente interessado e a aceitação dos Termos e Condições de Uso.
                <br/>
                É importante ressaltar que apenas maiores de 18 anos poderão contratar o serviço de atendimento psicológico online. Para menores de 18 anos somente será aceito com a autorização expressa por escrito, dos pais e/ou responsável. Encaminhar autorização com firma reconhecida para contato@telavita.com.br.
                <br/>
                1.2 DADOS DOS USUÁRIOS 
                <br/>
                Os usuários precisam fornecer o nome, CPF, endereço de e-mail válido e um telefone para a necessidade de um eventual contato e notificações das consultas. Ressaltamos que utilizaremos estas informações para única e exclusivamente comunicações transacionais do processo de sua consulta.
                <br/>
                O usuário garante a veracidade e a exatidão dos dados pessoais que fornecer neste site, assumindo a responsabilidade caso não sejam exatos e o compromisso de atualizar os dados sempre que for necessário.
                <br/>
                1.3 SENHA 
                <br/>
                Ao se cadastrar, o usuário precisará cadastrar uma senha pessoal e intransferível que será a chave de segurança que permite o acesso à plataforma. Desta forma, o usuário será o único responsável pelas operações efetuadas em sua conta, que apenas poderá ser acessada através de login e senha pessoal e intransferível. Somente através desse login e senha o usuário poderá usar os serviços da TelaVita.
                <br/>
                <br/>
                2. PAGAMENTO
                <br/>
                2.1 PAGAMENTO AVULSO 
                <br/>
                O pagamento pela compra de sessões avulsas para atendimento psicológico é feito antes do agendamento da sessão e a efetivação do pagamento pelo serviço ao psicólogo é intermediada pela plataforma TelaVita, através de ferramentas financeiras eletrônicas de pagamento.
                <br/>
                2.2 PAGAMENTO POR TERCEIROS 
                <br/>
                No caso do seu convênio, plano de saúde ou empregador oferecer sessões na plataforma TelaVita, identificaremos isso no seu cadastro e você terá acesso às sessões equivalentes as condições oferecidas pelo seu convênio.
                <br/>
                2.3 CANCELAMENTO DO PAGAMENTO
                <br/>
                No caso de desistência ou problemas, a consulta pode ser cancelada desde que esteja fora do prazo das 24h que antecedem o seu atendimento. Para receber o estorno do seu pagamento, o usuário deverá realizar o cancelamento da sessão direto em sua conta TelaVita. A devolução do valor retornará em sua fatura em até 5 dias úteis no caso de cartão de crédito. No caso de boleto ou transferência, você deverá entrar em contato com o SAC TelaVita dentro do período e informar os detalhes de sua conta para estorno integral ou parcial do valor. Para as sessões já realizadas não será possível a devolução do valor pois o mesmo é imediatamente repassado para o profissional que realizou a sessão.
                <br/>
                <br/>
                3. REGRAS DE AGENDAMENTO
                <br/>
                O sistema TelaVita permite que o usuário escolha o profissional e agende as sessões online com psicólogos licenciados e qualificados que desenvolvem um trabalho de maneira segura e confidencial respeitando o Código de Ética Profissional do Psicólogo.
                <br/>
                O atendimento psicológico online é equiparada a qualquer outra relação profissional com um psicólogo, e por este motivo pedimos muita atenção com o seu compromisso em comparecer nas sessões agendadas.
                <br/>
                Política de agendamento: 
                <br/>
                Serão permitidos agendamentos horas antes do horário disponível para pagamentos com cartão de crédito.
                Só serão disponibilizadas as opções de pagamento por transferência bancária ou boleto, para agendamento com datas de consultas de pelo menos 3 dias úteis. Caso o cliente não compareça no horário marcado, o psicólogo ficará durante todo o período da sessão disponível e online, podendo o usuário se juntar o psicólogo a qualquer momento a partir do horário agendado. No caso de não comparecimento por parte do usuário, o valor será cobrado da mesma forma, visto o despedimento de agenda e tempo por parte do psicólogo. Espere o total compromisso da presença dos psicólogos no horário da sessão, pois caso ocorra alguma emergência ou impossibilidade de comparecimento do profissional, faremos o possível para avisar o cliente com antecedência. Entretanto, caso o psicólogo tenha que remarcar ou cancelar por algum motivo a sessão, às seguintes regras se aplicarão: Quando o psicólogo cancelar o agendamento com até 24 hs de antecedência do horário da sessão nenhuma taxa é cobrada. Em caso de não comparecimento por parte do psicólogo, será oferecido uma sessão gratuita adicional para o usuário. No caso de não comparecimento do psicólogo, entre em contato com o SAC para esclarecimentos do agendamento da sua próxima sessão. IMPORTANTE: Caso ocorra algum problema técnico dentro da plataforma TelaVita que prejudique a qualidade ou até mesmo a impossibilite a realização do atendimento, a sessão será reagendada sem nenhum custo adicional para o usuário.
                <br/>
                <br/>
                4.INTERAÇÃO COM O PSICÓLOGO
                <br/>
                Os atendimentos ocorrem através de chat dentro da sala virtual da TelaVita com suportes para vídeo, áudio e texto.
                <br/>
                <br/>
                5.TECNOLOGIA
                <br/>
                5.1 CONEXÃO DA INTERNET E EQUIPAMENTOS 
                <br/>
                O acesso à Internet é de responsabilidade exclusiva do usuário. Para garantir uma consulta de qualidade é recomendável uma conexão estável e de banda larga. Caso o atendimento não possa ser iniciado por problemas técnicos, o mesmo poderá ser agendado novamente, sem custo adicional para o usuário. Caso o atendimento seja interrompido por problemas técnicos, desde que o usuário comunique o ocorrido dentro do mesmo dia util, entraremos em contato com usuário e profissional para analisar o caso individualmente. Os serviços prestados pela plataforma exigem o uso de um computador ou smartphone conectado com uma internet estável e de banda larga (recomendamos uma conexão de pelo menos 2Mbs para o atendimento), webcam, fone de ouvido e microfone para que ambas as partes sejam protegidas pelo sigilo necessário a uma sessão de atendimento psicológico.
                <br/>
                5.2 MANUTENÇÃO DO SISTEMA 
                <br/>
                Os usuários concordam que todo e qualquer sistema, plataforma ou serviço tecnológico, precisa estar sempre em constante atualização e aperfeiçoamento, o que não pode ser considerado em si como falha ou defeito. Eventualmente, o sistema poderá não estar disponível por motivos técnicos ou falhas da internet, ou por qualquer outra circunstância que podem estar alheios ao nosso controle, pois apesar de buscarmos a máxima eficiência da plataforma, não temos como garantir o acesso e uso contínuo ou sem interrupções da mesma, visto estar sujeita à falhas de terceiros. Possíveis erros no funcionamento da plataforma serão corrigidos o mais breve possível e, caso seja preciso, uma prévia manutenção, nos comprometemos a avisar todos os nossos clientes de forma mais antecipada possível.
                <br/>
                <br/>
                6. SIGILO NAS INFORMAÇÕES 
                <br/>
                Todas as informações trocadas no atendimento psicológico online são de responsabilidade do psicólogo que deve manter o sigilo das informações e seguir o Código de Ética do Psicólogo vigente no Brasil. A TelaVita não mantém nenhum registro das informações trocadas dentro das sessões realizadas na plataforma.
                <br/>
                Entretanto, apesar de todos os cuidados do psicólogo com o sigilo das informações trocadas durante o atendimento, os serviços mediados por computador não podem ser considerados como totalmente sigilosos e seguros, pois são vulneráveis a ameaças digitais. Por este motivo, aconselhamos o usuário a não usar computadores públicos e apagar os históricos de conversações sempre após as sessões. É também importante proteger o seu computador com um programa de antivírus e firewall, além de evitar realizar a consulta em locais públicos (faculdade, cyber-cafés, trabalho), na presença de outras pessoas. Utilize computadores de sua propriedade ou de pessoas de confiança.
                <br/>
                <br/>
                7.CANCELAMENTO CADASTRO 
                <br/>
                O usuário poderá, a qualquer tempo, cancelar seu cadastro. Para isso, o usuário precisará enviar um email para nosso atendimento solicitando a remoção do cadastro através do endereço: contato@telavita.com.br.
                <br/>
                <br/>
                8. PROPRIEDADE INTELECTUAL 
                <br/>
                O uso comercial da expressão “TelaVita” como marca, nome empresarial ou nome de domínio, o logotipo, bem como os conteúdos das telas e documentos relativos à plataforma são propriedades exclusivas da TelaVita e estão protegidos pelas leis de direito autoral, marcas e patentes.
                <br/>
                Não é permitido reproduzir, duplicar, copiar, vender, revender, visitar, ou de qualquer outra forma explorar para fins comerciais, sem o prévio consentimento escrito da TelaVita Comércio Eletrônico Ltda - ME O cliente também fica proibido de gravar qualquer atendimento ou sessão, seja por meio de gravação onde aparecem imagens, sons, textos, ou outra linguagem criptografada ou não, ficando expressamente proibido de vincular quaisquer gravações das sessões, incorrendo em crime de uso indevido da imagem e voz do psicólogo.
                <br/>
                <br/>
                9. CANCELAMENTO DA CONTA 
                <br/>
                A violação de quaisquer das disposições constantes neste instrumento poderá acarretar ao usuário o imediato cancelamento de seu acesso ao PORTAL pela TelaVita independentemente de qualquer aviso ou notificação, podendo o USUÁRIO responder pelos eventuais danos causados, além de sanções criminais, de acordo com sua conduta, nos termos da legislação vigente no Brasil.
            </ModalGeneric.Group>
        </ModalGeneric>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onCloseModal: () => dispatch(hideModal()),
  };
};

export default connect(
  null,
  mapDispatchToProps
)(ModalTerms);
