import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";

import PaymentStatus from "../PaymentStatus";
import { Typography } from 'components';

function ConsultationListPro({
  date,
  id,
  name,
  // onButtonClick,
  paymentStatusName,
  // paymentMethod,
  price,
  paymentStatus,
  time,
  specialty
}) {
  return (
    <div className="ConsultationList">
      <div className="ConsultationList__data">
        <div className="ConsultationList__data__name">
          <Typography>
          Sessão de {specialty === 'TVTVPSIC' ? 'psicoterapia' : 'psiquiatria'} com
          </Typography>
          <Typography type="paragraph" bold>
            {name}
          </Typography>
        </div>

        <div className="ConsultationList__data__paymentStatus">
          <div className="ConsultationList__data__paymentStatus__price">
            <div className="ConsultationList__data__paymentStatus__price__width">
              <Typography
                bold
                className="ConsultationList__data__paymentStatus__price__typography">
                R$ {price}
              </Typography>
            </div>
            <div className="ConsultationList__data__paymentStatus__price__typography">
              <PaymentStatus
                className="component"
                paymentStatus={paymentStatus}
                paymentStatusName={paymentStatusName}
              />
            </div>
          </div>

          <div className="ConsultationList__data__paymentFooter">
            <Typography
              type="subheading2"
              className="ConsultationList__data__paymentFooter__tags">
              {date}
              <Trans>, às </Trans> {time}
            </Typography>

            <Typography
              type="subheading2"
              className="ConsultationList__data__paymentFooter__id">
              ID {id}
            </Typography>
          </div>
        </div>
      </div>
      {/*
        paymentStatus === paymentStatusList.STS20001 ||
        paymentStatus === paymentStatusList.STS20002 ? (
            <div className="ConsultationList__icon">
              <Button
                classModifier="reverse"
                text="CANCELAR"
                disabled={false}
                onButtonClick={onButtonClick}
              />
            </div>
          ) : null
      */}
    </div>
  );
}

ConsultationListPro.propTypes = {
  paymentStatus: PropTypes.string,
  id: PropTypes.any,
  name: PropTypes.string,
  price: PropTypes.any,
  time: PropTypes.string,
  date: PropTypes.string,
  menuList: PropTypes.any,
  onButtonClick: PropTypes.func,
  paymentStatusName: PropTypes.any,
  paymentMethod: PropTypes.any,
  specialty: PropTypes.string,
};

export { ConsultationListPro };
