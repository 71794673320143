import { GET_DOCUMENTS  } from './_actionTypes';
import { apiEndpoints } from './../../settings/_apiSettings';
import axios from '../../settings/axios';

/* GET PLANS */
export const successGetDocuments = (documentsPayload) => {
  return {
    type: GET_DOCUMENTS,
    payload: documentsPayload,
  };
};

export const getDocuments = (profileRole) => async dispatch => {
  const response = await axios.get(apiEndpoints.DOCUMENTS, { params: { "profile_role": profileRole } });
  const documentsPayload = { documents: response.data.documents };

  dispatch(successGetDocuments(documentsPayload));
};