import styled from 'styled-components'

export const WrapperBottomSeparatorBlocks = styled.div`
  width: 100%;
  margin: 20px 0;

  .requiredFieldsText {
    color: #898989;
  }
`

export const BottomSeparator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #E2E2E2;
  margin-bottom: 40px;
`
