import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";

import PaymentStatus from "../PaymentStatus";
import { Typography } from 'components';

function PaymentDetails({
  patientName,
  sessionId,
  id,
  price,
  onButtonClick,
  paymentMethod,
  paymentStatus,
  paymentStatusName,
}) {
  return (
    <div className="PaymentDetails">
      <div className="PaymentDetails__content">
        <div className="PaymentDetails__data">
          <div className="PaymentDetails__name">
            <Typography bold type="subheading2">
              <Trans>PACIENTE</Trans>
            </Typography>

            <Typography type="paragraph" align="mcenter-dleft">
              {patientName}
            </Typography>
          </div>

          <div className="PaymentDetails__paymentStatus">
            <Typography
              bold
              className="PaymentDetails__paymentStatus__typography">
              <span>
                <Trans>R$</Trans>
              </span>{" "}
              {price}
            </Typography>

            <PaymentStatus
              paymentStatus={paymentStatus}
              paymentStatusName={paymentStatusName}
            />
          </div>

          <div className="PaymentDetails__paymentFooter">
            <Typography
              type="subheading2"
              className="PaymentDetails__paymentFooter__tags">
              <span>{sessionId}</span> {sessionId > 1 ? "itens" : "item  "}
            </Typography>

            <Typography
              type="subheading2"
              className="PaymentDetails__paymentFooter__tags">
              <span>
                <Trans>ID</Trans>
              </span>{" "}
              {id}
            </Typography>

            <Typography
              type="subheading2"
              className="PaymentDetails__paymentFooter__tags">
              {paymentMethod}
            </Typography>
          </div>
        </div>
        {/*
           <div className='PaymentDetails__buttons'>
          <Button className='PaymentDetails__buttons__item' classModifier='reverse--grey--lighter'
            text='CONFIRMAR PAGAMENTO'
            disabled={true}
            onButtonClick={onButtonClick}
          />

          <Button classModifier='reverse--grey--lighter'
            text='CANCELAR PEDIDO'
            disabled={true}
            onButtonClick={onButtonClick}
          />
        </div>
        */}
      </div>
    </div>
  );
}

PaymentDetails.propTypes = {
  psychologistName: PropTypes.string,
  patientName: PropTypes.string,
  sessionId: PropTypes.any,
  id: PropTypes.number,
  price: PropTypes.string,
  time: PropTypes.string,
  onButtonClick: PropTypes.func,
  paymentStatusName: PropTypes.string,
  paymentStatus: PropTypes.any,
  // paymentStatus: PropTypes.oneOf([
  //   'paid',
  //   'cancelled',
  //   'notPaid',
  //   'pending',
  //   'notApproved',

  // ]),
  menuList: PropTypes.any,
};

export default PaymentDetails;
