import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Trans } from '@lingui/macro'
import Sticky from 'react-sticky-el'

import DashboardFilters from '../../../components/DashboardFilters'
import FloatActionButtonDesktop from '../../../components/FloatActionButtonDesktop'
import FloatActionButtonMobile from '../../../components/FloatActionButtonMobile'
import Row from '../../../components/Row'
import SearchPlaceholder from '../../../components/SearchPlaceholder'
import { Typography, CardMedicalRequest, ClearFilters, Column, Spinner, InfiniteScroll } from 'components'
import { modalTypes } from '../../../containers/ModalManager/ModalManager'

import viewportHoc from '../../../hoc/viewportHoc'

import * as actions from '../../../redux/actions'
import * as profile from '../../../settings/_profileSettings'
import * as plansFilterActions from '../../../redux/modules/plans/actions'
import { PERSON_STATUS } from '../../../settings/_personSettings'

const defaultPagination = {
  page: 1,
  perPage: 10
}

const MedicalRequests = (props) => {
  const { filterData, isDesktop, plansFilter, userData, validities, paginationValidities, onGetMedicalRequest, onClearMedicalRequest, onClearConvenios, onShowModal } = props
  const viewPermissions = {
    showFilterModal: userData.session.profile_role_active !== profile.PSYCHOLOGIST,
    addMedicalRequest: userData.session.profile_role_active !== profile.PSYCHOLOGIST
  }

  let [loading, setLoading] = useState(false)
  let [cleaned, setCleaned] = useState(true)
  const [query, setQuery] = useState('')

  const getResult = useCallback(async (perPage = defaultPagination.perPage, page = defaultPagination.page, query = filterData?.text) => {
    let paramsGroup = plansFilter.actualConvenio.length ? { plan_group_code: plansFilter.actualConvenio } : {}
    let paramsPlan = plansFilter.actualConvenio.length && plansFilter.actualPlan.length ? { plan_code: plansFilter.actualPlan } : {}
    let planParams = { ...paramsGroup, ...paramsPlan }

    await onGetMedicalRequest({
      per_page: perPage,
      page,
      query,
      sort: filterData.isFiltering ? 'desc' : 'asc',
      status_code: filterData?.status,
      start_date__gte: filterData.startDate,
      start_date__lte: filterData.endDate,
      planParams,
    })

    setLoading(false)
    setCleaned(false)
  }, [filterData, onGetMedicalRequest, plansFilter])

  useEffect(() => {
    setLoading(true)
    getResult()
    return () => onClearMedicalRequest()
  }, [getResult, onClearMedicalRequest])

  useEffect(() => {
    return () => onClearConvenios()
  },[])

  const buildMenuList = (id, username, cardNumber, statusCode) => {
    const menuList = []
    const modalProps = {
      cardNumber: cardNumber,
      validityId: id,
      username: username,
      onSuccessAction: () => {
        onClearMedicalRequest()
        getResult()
      }
    }

    const menuOptions = {
      APPROVE_VALIDITY: {
        id: 1,
        code: 'approve-validity',
        name: 'Validar',
        func: () => onShowModal(modalTypes.APPROVE_VALIDITY, modalProps),
      },
      DISAPPROVE_REQUEST: {
        id: 2,
        code: 'disapprove-request',
        name: 'Reprovar',
        func: () => onShowModal(modalTypes.DISAPPROVE_REQUEST, modalProps),
      },
      UPLOAD_MEDICAL_REQUEST: {
        id: 3,
        code: 'attach-medical-request',
        name: 'Anexar avaliação técnica',
        func: () => onShowModal(modalTypes.UPLOAD_MEDICAL_REQUEST, modalProps),
      },
      INVALIDATE: {
        id: 4,
        code: 'invalid-request',
        name: 'Invalidar',
        func: () => onShowModal(modalTypes.INVALIDATE_REQUEST, modalProps),
      }
    }

    if (userData.session.profile_role_active === profile.PSYCHOLOGIST) menuList.push(menuOptions.UPLOAD_MEDICAL_REQUEST)

    if (userData.session.profile_role_active === profile.ADMINISTRATOR) {
      if ([PERSON_STATUS.VALID.code, PERSON_STATUS.VALID_WITHOUT_MEDICAL_REQUEST.code].includes(statusCode)){
        menuList.push(menuOptions.INVALIDATE)
      } else {
        menuList.push(menuOptions.APPROVE_VALIDITY, menuOptions.DISAPPROVE_REQUEST)
        if (statusCode === PERSON_STATUS.WAITING_CONSULTATION_REQUEST.code) menuList.push(menuOptions.UPLOAD_MEDICAL_REQUEST)
      }
    }

    return menuList
  }

  const buildExtraInfo = (medicalRequestId, statusCode) => ({
    iconName: 'Info',
    onClick: () => onShowModal(modalTypes.DISAPPROVE_DETAILS, {
      requestId: medicalRequestId,
      statusCode
    })
  })

  const buttonClickNewRequest = () => onShowModal(modalTypes.NEW_MEDICAL_REQUEST, {
    onSuccessAction: () => {
      onClearMedicalRequest()
      getResult()
    }
  })

  const renderMedicalRequestList = data => {
    return data.map((medicalRequest, index) => {
      const statusCode = medicalRequest.status_code
      const personStatus = Object.values(PERSON_STATUS).find(status => status.code === statusCode)

      const showMenu = [
        PERSON_STATUS.AWAITING_VALIDITY.code,
        PERSON_STATUS.WAITING_CONSULTATION_REQUEST.code,
        PERSON_STATUS.VALID_WITHOUT_MEDICAL_REQUEST.code,
        PERSON_STATUS.VALID.code
      ].includes(personStatus.code)
      const showExtraInfo = [PERSON_STATUS.REPROVED.code, PERSON_STATUS.INVALIDATED.code].includes(personStatus.code)

      return (
        <div key={index} className="MedicalRequest__List__CardMedicalRequest">
          <CardMedicalRequest
            cardNumber={medicalRequest.person_profile_plan_treatment.person_profile_plan.card_number}
            lastProfessional={medicalRequest.last_schedule.professional_name}
            menuList={showMenu ? buildMenuList(medicalRequest.id,
              medicalRequest.person_profile_plan_treatment.person_profile_plan.person_profile.person.person_general.full_name,
              medicalRequest.person_profile_plan_treatment.person_profile_plan.card_number, personStatus.code) : []}
            extraInfo={showExtraInfo ? buildExtraInfo(medicalRequest.id, personStatus.code) : null}
            medicalRequestLink={medicalRequest.person_profile_plan_treatment.medical_request_url}
            medicalRequestId={medicalRequest.id}
            cid={medicalRequest.person_profile_plan_treatment.cid}
            professionalCrm={medicalRequest.person_profile_plan_treatment.professional_crm_code}
            professionalUf={medicalRequest.person_profile_plan_treatment.professional_crm_uf}
            planGroupName={medicalRequest.person_profile_plan_treatment?.person_profile_plan?.plan.group?.name || ''}
            planGroupCategory={medicalRequest.person_profile_plan_treatment?.person_profile_plan?.plan.group?.category || ''}
            planName={medicalRequest.person_profile_plan_treatment?.person_profile_plan?.plan?.name || ''}
            statusName={medicalRequest.status_name}
            maxCredits={medicalRequest.max_appointment}
            availableCredits={medicalRequest.available_credits}
            startDate={medicalRequest.start_date}
            endDate={medicalRequest.end_date}
            statusWeight={personStatus.weight}
            ambCode={medicalRequest.amb_code}
            fullName={
              medicalRequest.person_profile_plan_treatment.person_profile_plan.cardholder_name ?? 
              medicalRequest.person_profile_plan_treatment.person_profile_plan.person_profile.person.person_general.full_name}
            username={medicalRequest.person_profile_plan_treatment.person_profile_plan.person_profile.person.username}
            planPassword={medicalRequest.plan_password}
          />
        </div>
      )
    })
  }

  return (
    <div className="MedicalRequests">
      {
        !isDesktop && viewPermissions.addMedicalRequest &&
        <FloatActionButtonMobile
          buttonId="new-medical-request"
          iconName="Positive"
          onClickFab={() => buttonClickNewRequest()}
          type="primary"
          title={<Trans>NOVA SOLICITAÇÃO</Trans>}
        />
      }

      <Sticky stickyStyle={{
        backgroundColor: '#fff',
        padding: '2.5rem 0px 2rem',
        zIndex: '2'
      }}>
        <Row align="center"
          justify={isDesktop ? 'space-between' : 'center'}
          margin={isDesktop ? '3.9rem 0 2.1rem' : '2.5rem 0 1.5rem'}>
          <Column align="center" justify="center" colWidth={5}>
            <Typography align="mcenter-dleft" Tag="h1" type="title">
              <Trans>Solicitações</Trans>
            </Typography>
          </Column>
        </Row>

        <Row align='center' justify={isDesktop ? 'space-between' : 'center'}
          margin='2rem 0 1.5rem'>
          <div className='Dashboard-filter'>
            <DashboardFilters
              hasModal={viewPermissions.showFilterModal}
              inputPlaceholder="Buscar nome, e-mail ou carteirinha"
              groupCode="MEDICAL_REQUEST"
              iconForAll="AllMedicalRequests"
              query={query}
              onQueryChange={query => setQuery(query)}
            />
          </div>
          {
            isDesktop && viewPermissions.addMedicalRequest &&
            <FloatActionButtonDesktop hasIcon
              buttonId="new-medical-request"
              iconName="Positive"
              iconViewBox="-5 -5 30 30"
              onClickFab={() => buttonClickNewRequest()}
              title={<Trans>NOVA SOLICITAÇÃO</Trans>}
              type="primary"
            />
          }
        </Row>
        <ClearFilters />
      </Sticky>
      {
        loading ? (<Spinner height="50vh" />) : cleaned ?
          (<div className="dashboard-person-list--empty">
            <SearchPlaceholder
              titleText='Use a busca acima para'
              phrase='encontrar o paciente'
            />
          </div>) :
          (<InfiniteScroll
            dataLength={validities.length}
            requestMethod={(perPage, currentPage) => getResult(perPage, currentPage, props.filterData.text)}
            currentPage={paginationValidities.page}
            perPage={defaultPagination.perPage}
            totalPages={paginationValidities.pages}
            style={{ height: '100%' }}
            emptyRender={
              <div className="dashboard-person-list--empty">
                <SearchPlaceholder
                  titleText='Nenhum resultado'
                  text='Refaça sua busca e seus filtros'
                />
              </div>
            }>
            {renderMedicalRequestList(validities)}
          </InfiniteScroll>)
      }
    </div>
  )
}

const mapStateToProps = state => ({
  filterData: state.filters,
  plansFilter: state.plansFilter,
  paginationValidities: state.medicalRequests.pagination,
  userData: state.auth.userData,
  validities: state.medicalRequests.validities
})

const mapDispatchToProps = dispatch => ({
  onClearMedicalRequest: () => dispatch(actions.clearMedicalRequest()),
  onClearConvenios: () => dispatch(plansFilterActions.clearConvenios()),
  onGetMedicalRequest: (params) => dispatch(actions.getMedicalRequest(params)),
  onShowModal: (modalType, modalData) => dispatch(actions.showModal(modalType, modalData))
})

export default connect(mapStateToProps, mapDispatchToProps)(viewportHoc(MedicalRequests))
