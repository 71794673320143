import { 
  CLEAR_REPORT, 
  GENERATE_REPORT, 
} from "./_actionTypes";

import { apiEndpoints } from './../../settings/_apiSettings';
import axios from '../../settings/axios';
import download  from 'downloadjs';
import { dateToIso, toDayMonthYear } from "../../utils/dateTimeHandlers";
import { asyncError } from "./asyncActions";

export const generateReport = ({ type, start_date, start_date__gte, end_date, start_date__lte, profile_role }) => async dispatch => {
  
  const params = {
    ...(end_date && { end_date }),
    ...(start_date && { start_date }),
    ...(start_date__gte && { start_date__gte }),
    ...(start_date__lte && { start_date__lte }),
    ...(profile_role && { profile_role })
  };

  try {
    const response = await axios.get(apiEndpoints.REPORTS(type), { params, responseType: 'blob' });
    const blob = new Blob([ response ]);

    const todayDate = dateToIso(toDayMonthYear(new Date()));
    const filename = `${type}-${todayDate}.xlsx`;

    download(blob, filename);

    return dispatch({
      type: GENERATE_REPORT,
      payload: { downloaded: true },
    });

  } catch (err) {
    dispatch(asyncError(err));
  }
};

export const clearReport = () => async dispatch => {
  return dispatch({ type: CLEAR_REPORT });
};